// @flow

import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SlackChannelIsDeletedInfo = ({
  toggleModal,
  orguser,
  sendToSlackStyle,
  sendToSlackDisabledStyle,
}) => {
  const canDoAnythingAboutIt = orguser.capabilities.canManagePartnerships;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span className="flex-fill pr-3 font-weight-bold mb-2">
        <FontAwesomeIcon
          icon={['far', 'exclamation-triangle']}
          className="mr-2"
          style={{ height: 16, width: 16, color: '#FF833D' }}
        />
        Channel Deleted
      </span>
      <div
        href="#"
        data-toggle="tooltip"
        onClick={() => canDoAnythingAboutIt && toggleModal()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            canDoAnythingAboutIt && toggleModal();
          }
        }}
        className="nav-link p-0"
        style={canDoAnythingAboutIt ? sendToSlackStyle : sendToSlackDisabledStyle}
        disabled={!canDoAnythingAboutIt}
        id="deleted-slack-channel-update-link"
        role="presentation"
      >
        Update
      </div>
      {!canDoAnythingAboutIt && (
        <Tooltip
          isOpen={tooltipOpen}
          target="deleted-slack-channel-update-link"
          toggle={toggleTooltip}
          placement="left"
        >
          Ask an admin to update Slack Channel for this partner.
        </Tooltip>
      )}
    </>
  );
};

export default SlackChannelIsDeletedInfo;
