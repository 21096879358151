// @flow
// eslint-disable-next-line max-classes-per-file
import queryString from 'qs';

import { CoSellingSlackConfig, SlackChannel } from 'data/entities';
import { skipNull } from 'utils/nodash';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

export const getSlackChannels = (
  externalOnly: ?Boolean,
  query: ?String
): Promise<PaginatedResponse<SlackChannel>> => {
  const params = { limit: query ? 5 : 200, external_only: externalOnly, query };
  const qs = queryString.stringify(skipNull(params));
  const url = `${orgUrlBase()}/notify/slack-channels?${qs}`;
  return authFetch(url).then(mapPaginatedResponse(SlackChannel.fromApi));
};

export const refreshSlackChannels = () => {
  const url = `${orgUrlBase()}/notify/slack-channels`;
  const options = { method: 'PATCH' };
  return authFetch(url, options);
};

type SlackChannelUpdate = {
  partnerId: string,
  channelId: string | null,
};
export const updateSlackChannels = (updates: Array<SlackChannelUpdate>) => {
  const url = `${orgUrlBase()}/partners/bulk-update-slack-channels`;
  const body = updates.map((update) => ({
    partner_id: update.partnerId,
    channel_id: update.channelId,
  }));
  const options = { method: 'POST', body };

  return authFetch(url, options);
};

export const revokeSlackAccess = () => {
  const url = `${orgUrlBase()}/notify/slack-integration`;
  const options = { method: 'DELETE' };
  return authFetch(url, options);
};

export const getCoSellingSlackConfig = (): Promise<CoSellingSlackConfig> => {
  const url = `${orgUrlBase()}/co-selling-slack-config`;
  return authFetch(url).then(CoSellingSlackConfig.fromApi);
};

export const setCoSellingSlackConfig = (
  slackChannelId: string | null,
  requestsWeeklyGoal: number,
  excludedOrgusersIds: string[]
): Promise<CoSellingSlackConfig> => {
  const url = `${orgUrlBase()}/co-selling-slack-config`;
  const body = {
    slack_channel_id: slackChannelId,
    requests_weekly_goal: requestsWeeklyGoal,
    excluded_orgusers_ids: excludedOrgusersIds,
  };
  const options = { method: 'POST', body };
  return authFetch(url, options).then(CoSellingSlackConfig.fromApi);
};
