/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import { initializeTrackingLibraries } from 'utils/tracking';

import App from './App';
import { unregister } from './registerServiceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

initializeTrackingLibraries();

ReactDOM.render(
  <BrowserRouter>
    <div id="routes-root">
      <Route path="/" component={App} />
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);
unregister();
