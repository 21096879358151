// @flow
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import type { BdRequestQuestion } from 'data/entities/bdrequestQuestion';

type Props = {
  questions: Array<BdRequestQuestion>,
  selectedQuestions: BdRequestQuestion[],
  onSelect: (questions: BdRequestQuestion[]) => void,
  loading?: boolean,
};

const QuestionSelector = ({ loading, questions, selectedQuestions, onSelect }: Props) => (
  <div className="w-100" style={{ minWidth: 250 }}>
    <Typeahead
      labelKey={(option: BdRequestQuestion) => option.text}
      id="question-selector"
      options={questions ?? []}
      placeholder="Search for questions to add..."
      onChange={(selected: BdRequestQuestion[]) => onSelect(selected)}
      selected={selectedQuestions ?? []}
      isLoading={loading}
      emptyLabel="No questions found"
      render
      clearButton
      ignoreDiacritics
      multiple
    />
  </div>
);

QuestionSelector.defaultProps = {
  loading: false,
};

export default QuestionSelector;
