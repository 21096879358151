// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SlackChannel } from 'data/entities';

import SlackLogo from 'components/SlackLogo';

type Props = {
  slackChannel: SlackChannel,
  slackChannelNameStyle: Object,
  sendToSlackStyle: Object,
  toggleModal: () => void,
};

const SlackChannelNeedsSendingInfo = ({
  slackChannel,
  slackChannelNameStyle,
  sendToSlackStyle,
  toggleModal,
}: Props) => (
  <>
    <SlackLogo height={16} width={16} className="pr" />
    <span className="flex-fill pl-2 pr-3" style={slackChannelNameStyle}>
      #{slackChannel.name}
    </span>
    <>
      <a href="#" onClick={toggleModal} className="nav-link p-0" style={sendToSlackStyle}>
        <FontAwesomeIcon icon="paper-plane" className="mr-2" />
        Send to Slack
      </a>
    </>
  </>
);

export default SlackChannelNeedsSendingInfo;
