// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import setTitle, { useTitleEffect } from 'utils/setTitle';
import type { LocationT } from 'sharedTypes/reactRouter';

import ResetPasswordForm from 'views/Auth/ResetPasswordForm';
import Logo from 'components/Logo';

import Footer from './Footer';

type Props = {
  isAuthenticated: boolean,
  location: LocationT,
};

export default function ResetPassword(props: Props) {
  const {
    isAuthenticated,
    location: { state: locationState },
  } = props;
  useTitleEffect('Reset password');
  const redirect = locationState ? locationState.redirectFrom : null;
  let redirectFrom = null;
  if (redirect && redirect.pathname) {
    const { search, pathname } = redirect;
    redirectFrom = `${pathname}${search || ''}`;
  }
  if (isAuthenticated) {
    setTitle();
    return redirectFrom ? <Redirect to={redirectFrom} /> : <Redirect to="/" />;
  }

  return (
    <div className="text-center" style={{ marginTop: '10em' }}>
      <Logo height={30} className="my-5 mx-auto d-block" />
      <br />
      <ResetPasswordForm {...props} />
      <hr />
      <Footer />
    </div>
  );
}
