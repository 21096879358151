// @flow

import { isNil } from 'lodash';

import List, { ListAccountPartner } from 'data/entities/lists';
import stringifyOptions from 'utils/stringifyOptions';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

type ListOptions = {
  containsMappingId?: string,
  isFavorite?: boolean,
  isOwner?: boolean,
  limit?: number,
  offset?: number,
  search?: string,
};

const fetchLists = (options: ?ListOptions): Promise<PaginatedResponse<List>> => {
  const { search, isFavorite, isOwner, containsMappingId, offset, limit } = options || {};
  const params = [
    ['contains_mapping_id', containsMappingId],
    ['is_favorite', isFavorite],
    ['is_owner', isOwner],
    ['q', search],
  ];
  if (!isNil(offset) && !isNil(limit)) {
    params.push(['offset', offset]);
    params.push(['limit', limit]);
  }
  const url = `${orgUrlBase()}/mappings-lists${stringifyOptions(params)}`;
  return authFetch(url).then(mapPaginatedResponse(List.fromApi));
};

const fetchList = (id: string): Promise<List> => {
  const url = `${orgUrlBase()}/mappings-lists/${id}`;
  return authFetch(url).then(List.fromApi);
};

const fetchListAccountPartners = (id: string): Promise<PaginatedResponse<ListAccountPartner>> => {
  const url = `${orgUrlBase()}/mappings-lists/${id}/account-partners/?limit=150`;
  return authFetch(url).then(mapPaginatedResponse(ListAccountPartner.fromApi));
};

const toggleListFavorite = (id: string, favorite: boolean): Promise<List> => {
  const body = {
    is_favorite: favorite,
  };
  const url = `${orgUrlBase()}/mappings-lists/${id}/mark-favorite/`;
  return authFetch(url, { method: 'POST', body }).then(List.fromApi);
};

const addToList = (listId: string, accountPartnerId: string) => {
  const body = {
    account_partner_id: accountPartnerId,
  };
  const url = `${orgUrlBase()}/mappings-lists/${listId}/account-partners/`;
  return authFetch(url, { method: 'POST', body });
};

const deleteFromList = (listId: string, accountPartnerId: string) => {
  const url = `${orgUrlBase()}/mappings-lists/${listId}/account-partners/${accountPartnerId}`;
  return authFetch(url, { method: 'DELETE' });
};

const deleteList = (id: string) => {
  const url = `${orgUrlBase()}/mappings-lists/${id}/`;
  return authFetch(url, { method: 'DELETE' });
};

const createList = (name: string, description: string): Promise<List> => {
  const body = {
    name,
    description: description || null,
  };
  const url = `${orgUrlBase()}/mappings-lists/`;
  return authFetch(url, { method: 'POST', body });
};

const editList = (id: string, name: string, description: string): Promise<List> => {
  const body = {
    name,
    description: description || null,
  };
  const url = `${orgUrlBase()}/mappings-lists/${id}/`;
  return authFetch(url, { method: 'PATCH', body });
};

export {
  addToList,
  createList,
  deleteFromList,
  deleteList,
  editList,
  fetchList,
  fetchListAccountPartners,
  fetchLists,
  toggleListFavorite,
};
