// @flow

import React from 'react';
import { Link } from 'react-router-dom';

type Props = { to: string, text: string };

const Mention = ({ to, text }: Props) => (
  <Link to={to} className="px-1 bg-light-blue rounded normal-text semi-bold primary">
    @{text}
  </Link>
);

export default Mention;
