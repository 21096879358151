// @flow

import React from 'react';

import CrossbeamButton from 'views/Auth/CrossbeamButton';

// TODO: Remove with partner invites.
const AuthMethods = () => (
  <div className="LoginMethods container" style={{ marginBottom: '10em' }}>
    <div className="row mx-auto text-center d-flex flex-column align-items-center">
      <CrossbeamButton />
      <span className="normal-text bold gray-500 mt-2">
        Don&apos;t have a Crossbeam account?
        <a className="ml-2 primary" href="https://app.crossbeam.com/register">
          Sign Up
        </a>
      </span>
    </div>
  </div>
);

export default AuthMethods;
