// @flow

import React from 'react';
import classNames from 'classnames';

import { StepElements } from './constants';

type Props = {
  activeStep: number,
  setActiveStep: (step: number) => void,
};

const Steps = ({ activeStep, setActiveStep }: Props) => (
  <div className="card card-body flex-grow-0 p-2 m-0 d-flex flex-row align-items-center justify-content-center gap-20">
    {StepElements.map(({ step, number, title }) => (
      <div
        key={step}
        className={classNames(
          'navigation-item py-2 d-flex flex-row align-items-center justify-content-center flex-fill',
          activeStep === step && 'bg-light-blue blue'
        )}
        onKeyDown={() => setActiveStep(step)}
        onClick={() => setActiveStep(step)}
        role="button"
        tabIndex={0}
      >
        <div
          className={classNames(
            'rounded-circle d-flex align-items-center justify-content-center small-text',
            activeStep === step ? 'text-white bg-blue ' : 'bg-gray-200 gray-500'
          )}
          style={{ width: 20, height: 20 }}
        >
          {number}
        </div>
        <span>{title}</span>
      </div>
    ))}
  </div>
);

export default Steps;
