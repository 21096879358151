// @flow

// View that invite links to.
// Validate that the invite is valid, then send through salesforce with an invite link.

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';

import { PartnerInvite } from 'data/entities';
import { InviteKind } from 'data/entities/invites';
import { validateInvite } from 'data/repositories/invites';
import setTitle from 'utils/setTitle';

import LoginMethods from 'views/Auth/AuthMethods';
import CompanyLogo from 'components/CompanyLogo';
import Logo from 'components/Logo';
import PersonAvatar from 'components/PersonAvatar';

function saveInviteCode(inviteId) {
  localStorage.setItem('shareableLink', inviteId);
}

function LandingFooter() {
  function HowToCard({
    image,
    title,
    description,
  }: {
    image: string,
    title: string,
    description: string,
  }) {
    return (
      <div className="d-flex flex-column flex-even" style={{ maxWidth: '300px' }}>
        <img className="howitworks-img" alt="" src={`/assets/img/icons/${image}.svg`} />
        <p className="bolder larger-text gray-700 mt-3">{title}</p>
        <p className="bold large-text gray-700">{description}</p>
      </div>
    );
  }

  return (
    <div className="landing-container align-items-center d-flex flex-column">
      <h4 className="mb-5">How it works?</h4>
      <div className="d-flex flex-md-row flex-column justify-content-center mt-4 gap-30">
        <HowToCard
          image="handshake"
          title="Join the Network"
          description="Get your sales team set up by easily & securely connecting your CRM."
        />
        <HowToCard
          image="overlap"
          title="Find Overlap"
          description="Instantly see all your overlapping customers and prospects in just a few clicks."
        />
        <HowToCard
          image="notification"
          title="Take Action"
          description="Make your sales team smarter and more effective with actionable alerts where they work."
        />
        <HowToCard
          image="growth"
          title="Grow Revenue"
          description="Measurably increase your ecosystem revenue by up to 20x."
        />
      </div>
    </div>
  );
}

function inviteDetail(invite: PartnerInvite, showLanding: boolean, hideLanding: () => void) {
  const {
    fromOrg: { name: orgName, domain },
    fromOrguser,
    code,
  } = invite;

  return showLanding ? (
    <div className="align-items-center d-flex flex-column">
      {!!domain && !!domain.length && !!fromOrguser ? (
        <PersonAvatar
          firstName={fromOrguser.firstName}
          lastName={fromOrguser.lastName}
          avatar={fromOrguser.avatarImage}
          size={80}
          org={{ name: orgName, domain }}
        />
      ) : (
        !!domain && !!domain.length && <CompanyLogo domain={domain} name={orgName} size="92px" />
      )}
      {!!fromOrguser && (
        <>
          <span className="big-text mt-3">{fromOrguser.fullName}</span>
          <span className="mt-0">({fromOrguser.email})</span>
        </>
      )}
      <h3
        className={classnames({
          'text-center': 'text-center',
          'w-75': !invite.isKind(InviteKind.SHAREABLE_LINK),
        })}
      >
        {invite.isKind(InviteKind.ORGUSER_INVITE) && `Join ${orgName} on Crossbeam for Sales!`}
        {invite.isKind(InviteKind.SHAREABLE_LINK) &&
          `Connect with ${fromOrguser?.fullName} from ${orgName}`}
        {!invite.isKind(InviteKind.ORGUSER_INVITE) &&
          !invite.isKind(InviteKind.SHAREABLE_LINK) &&
          `${fromOrguser?.fullName} from ${orgName} is inviting you to partner with them`}
      </h3>
      <button
        type="button"
        onClick={hideLanding}
        className="btn btn-lg text-bold text-center btn-primary mt-5"
      >
        Continue to Crossbeam for Sales
      </button>
    </div>
  ) : (
    <div className="align-items-center d-flex flex-column">
      <h3 className="text-center">First, choose your login method</h3>
      <p className="text-center bold large-text gray-500">
        Let’s get setup to work with the {orgName} team.
      </p>
      <div className="my-4 mt-3">
        <LoginMethods acceptedInvite={code} />
      </div>
    </div>
  );
}

function noPartnerJustJoin(invite: PartnerInvite) {
  // For invites that aren't for partners, but are just to join Partnered
  const { code } = invite;
  return (
    <div className="align-items-center d-flex flex-column">
      <h3 className="text-center">Join Crossbeam for Sales!</h3>
      <div className="my-4">
        <LoginMethods acceptedInvite={code} />
      </div>
    </div>
  );
}

function alreadyAccepted() {
  return (
    <div className="align-items-center d-flex flex-column">
      <h4 className="text-center">Invite already accepted. Login to continue</h4>
      <div className="my-4">
        <LoginMethods />
      </div>
    </div>
  );
}

function crossbeamWaitlist() {
  return (
    <div className="align-items-center d-flex flex-column">
      <h3 className="bold gray-700">Crossbeam Has Acquired Partnered.</h3>
      <span className="large-text bold gray-500 mb-4">
        You’ll soon be able to get the power of Partnered, supercharged with the Crossbeam network.
      </span>
      <video
        src="https://partnered-prod-media.s3.amazonaws.com/public-media/Partnered+%2B+Crossbeam.mp4"
        className="invite-waitlist-video"
        autoPlay
        muted
        loop
      />
      <div className="d-flex flex-column mt-4" style={{ width: 300 }}>
        <a href="https://www.crossbeam.com/blog/partnered-crossbeam-acquired" target="_blank">
          <button type="button" className="btn btn-secondary w-100">
            Read More About It
          </button>
        </a>
      </div>
    </div>
  );
}

type Props = {
  match: { params: { inviteId: string } },
  orguser: OrgUser,
};

type State = {
  loading: boolean,
  showLanding: boolean,
  errMsg: ?string,
  invite: ?PartnerInvite,
  invalidated: boolean,
};

class HandleInvite extends React.Component<Props, State> {
  state: State = {
    loading: true,
    showLanding: true,
    errMsg: null,
    invite: null,
    invalidated: false,
  };

  componentDidMount() {
    // Fetch the invite details
    this.loadInvite();
  }

  componentWillUnmount() {
    setTitle();
  }

  hideLanding = () => {
    const { invite } = this.state;
    if (invite.isKind(InviteKind.SHAREABLE_LINK)) {
      saveInviteCode(invite.code);
    }
    this.setState({ showLanding: false });
  };

  loadInvite() {
    const {
      match: {
        params: { inviteId },
      },
    } = this.props;
    return validateInvite(inviteId)
      .then((invite) => {
        if (invite && invite.fromOrg) {
          setTitle(`Invited to work with ${invite.fromOrg.name}`);
        }
        this.setState({
          loading: false,
          invite,
          invalidated:
            invite.isKind(InviteKind.PARTNER_INVITE) || invite.isKind(InviteKind.SHAREABLE_LINK),
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.setState({ errMsg: 'Invalid Partner invite.' });
        } else {
          this.setState({ errMsg: 'Something went wrong! Please try again or contact support.' });
        }
      });
  }

  render() {
    const { errMsg, loading, showLanding, invite, invalidated } = this.state;
    const { orguser } = this.props;
    if (!!orguser && invite?.isKind(InviteKind.SHAREABLE_LINK)) {
      saveInviteCode(invite.code);
      return <Redirect to="/onboarding" />;
    }
    let main: React.Node;

    if (loading) {
      main = <h3>Loading...</h3>;
    }
    if (errMsg) {
      main = <h4>{errMsg}</h4>;
    }
    if (invite) {
      if (invalidated) {
        main = crossbeamWaitlist();
      } else if (invite.isAccepted) {
        main = alreadyAccepted();
      } else if (invite.isKind(InviteKind.WAITLIST_INVITE)) {
        // If there isn't a fromOrg then it's just an invite to Partnered
        main = noPartnerJustJoin(invite);
      } else {
        main = inviteDetail(invite, showLanding, this.hideLanding);
      }
    }

    return (
      <div className="landing-wrapper d-flex flex-column">
        <div className="d-flex justify-content-center align-items-center">
          <div className="brand inline no-border d-sm-inline-block pt-5">
            <Logo height={30} />
          </div>
        </div>
        <div className="container align-items-center d-flex flex-column flex-fill mt-5 mb-5">
          {main}
        </div>
        {showLanding && !invalidated && <LandingFooter />}
      </div>
    );
  }
}

export default HandleInvite;
