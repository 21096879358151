// @flow

import { PartnerBasic, PartnerInvite, PartnerInviteSent } from 'data/entities';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

import type { PartnerApiSettings } from './partners';

export function validateInvite(inviteId: string): Promise<PartnerInvite> {
  return authFetch(`validate-invite/${inviteId}`).then(PartnerInvite.fromApi);
}

export function sentInvites(): Promise<PaginatedResponse<PartnerInviteSent>> {
  // Get sent invites
  return authFetch(`${orgUrlBase()}/partner-invites`).then(
    mapPaginatedResponse(PartnerInviteSent.fromApi)
  );
}

export function updateInvite(
  inviteId: string,
  email: string,
  firstName: string,
  lastName: string
): Promise<PartnerInviteSent> {
  const body = { first_name: firstName, last_name: lastName, email };
  return authFetch(`${orgUrlBase()}/partner-invites/${inviteId}`, { method: 'PATCH', body }).then(
    PartnerInviteSent.fromApi
  );
}

export function sendPartnerInvite(inviteId: string): Promise<PartnerInviteSent> {
  return authFetch(`${orgUrlBase()}/partner-invites/${inviteId}/send`, { method: 'POST' }).then(
    PartnerInviteSent.fromApi
  );
}

export function deleteInvite(inviteId: string): Promise<null> {
  const url = `${orgUrlBase()}/partner-invites/${inviteId}`;
  return authFetch(url, { method: 'DELETE' });
}

export function acceptPartnerInvite(
  code: string,
  bdManager: string,
  settings: PartnerApiSettings
): Promise<PartnerBasic> {
  const url = `${orgUrlBase()}/accept-invite/${code}`;
  const partnerSettings = {
    overlap_sharing_setting: settings.overlapSharingSetting,
    visibility_settings: {
      account_status_visible: settings.visibilitySettings.accountStatusVisible,
      account_owner_visible: settings.visibilitySettings.accountOwnerVisible,
    },
    conversation_settings: {
      enabled_direct_conversations: settings.conversationSettings.enabledDirectConversations,
    },
  };
  return authFetch(url, {
    method: 'POST',
    body: { bd_manager: bdManager, settings: partnerSettings },
  }).then(PartnerBasic.fromApi);
}

export function fetchInvitesReceived(shareableLink): Promise<PaginatedResponse<PartnerInvite>> {
  const url = `${orgUrlBase()}/invites-received/${
    shareableLink ? `?shareableLink=${shareableLink}` : ''
  }`;
  return authFetch(url).then(mapPaginatedResponse(PartnerInvite.fromApi));
}

export function resendInvite(inviteId: string): Promise<PartnerInviteSent> {
  return authFetch(`${orgUrlBase()}/partner-invites/${inviteId}/resend`, { method: 'POST' }).then(
    PartnerInviteSent.fromApi
  );
}
