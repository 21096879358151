// @flow

import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { PartnerOrg } from 'data/entities';
import { approveAllAccountMappingPartnerRequests } from 'data/repositories/accountMapping';

type Props = {
  count: number,
  partnerOrg: PartnerOrg,
};

type State = {
  isOpen: boolean,
  count: number,
  loading: boolean,
  complete: boolean,
};

const blankState = { isOpen: false, count: 0, loading: false, complete: false };

export default class ConfirmApproveAll extends React.Component<Props, State> {
  state = blankState;

  toggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  closeAndReset = () => {
    this.setState(blankState);
  };

  onApproveAll = () => {
    const { partnerOrg } = this.props;
    this.setState({ loading: true });
    approveAllAccountMappingPartnerRequests(partnerOrg.id)
      .then(() => {
        this.setState({ complete: true, loading: false });
        setTimeout(() => {
          this.closeAndReset();
        }, 5000);
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  // countup = () => {
  //   // Increment count of processed
  //   this.setState(({ count: pcount }) => ({ count: (pcount +1) }));
  // };

  // approveOne = (pam: PartnerApprovedMap) => {
  //   // Approve a single mapping, increment the counter
  //   return approveAccountMapping(pam.account.slug, pam.partnerAccount.id, false)
  //     .then(() => {
  //       this.countup();
  //       return 1;
  //     })
  //     .catch(() => {
  //       // Do nothing, if one approval fails, we don't want it destroying the whole queue.
  //       return 0;
  //     });
  // };
  //
  // approveRequestsPage(pageUrl) {
  //   // Used to paginate through, approving an entire page.
  //   const { partnerOrg } = this.props;
  //   return fetchPartnerApprovedMapping(partnerOrg.slug, null, pageUrl)
  //     .then(async (data) => {
  //       // Do approvals
  //       const promises = data.results.map(this.approveOne);
  //       // Wait for all approvals to finish resolving.
  //       await Promise.all(promises);
  //       // We always wind up making an extra call. Can't rely on paging through because we're always knocking
  //       // out the current page on approval, so we look at count
  //       return data.count;
  //     })
  //     // Either recurse and process the next page, or resolve the promise
  //     .then(count => (count ? this.approveRequestsPage() : Promise.resolve()));
  // }

  render() {
    const { count } = this.props;
    const { isOpen, count: countApproved, loading, complete } = this.state;
    let modalBody = (
      <span>
        Continuing will approve all pending mapping requests that your partner has already reviewed
        and approved.
      </span>
    );
    let modalHeader = <span>Approve all {count}?</span>;
    if (complete) {
      modalHeader = <span>Accepting Mapping Requests</span>;
      modalBody = (
        <span>
          <span role="img" aria-label="celebrate">
            🎉
          </span>{' '}
          Approvals are being processed, and will soon be removed from your queue.
        </span>
      );
    } else if (loading) {
      modalBody = (
        <span>
          Approved <strong>{countApproved}</strong> of <strong>{count}</strong> requests
          <br />
          <strong>Note:</strong> Reloading page will interrupt approvals
        </span>
      );
      modalHeader = <span>Processing Approvals</span>;
    }
    return (
      <div className="mt-3">
        {Boolean(count) && (
          <button type="button" className="btn btn-sm" onClick={this.toggle}>
            Approve All ({count})
          </button>
        )}
        <Modal isOpen={isOpen} toggle={this.toggle} centered>
          <ModalHeader toggle={this.toggle}>{modalHeader}</ModalHeader>
          <ModalBody>
            <div className="mt-3">{modalBody}</div>
          </ModalBody>
          {!loading && !complete && (
            <ModalFooter>
              {complete ? (
                <button type="button" className="btn btn-default" onClick={this.closeAndReset}>
                  Done
                </button>
              ) : (
                <button type="button" className="btn btn-primary" onClick={this.onApproveAll}>
                  Continue, Approve All
                </button>
              )}{' '}
              <button type="button" className="btn btn-default" onClick={this.toggle}>
                Cancel
              </button>
            </ModalFooter>
          )}
        </Modal>
      </div>
    );
  }
}
