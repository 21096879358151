// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { DiscoveredOrg } from 'data/entities/org';
import { OrgUserContext } from 'contexts/OrgUserContext';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import PartnerRow from 'views/Onboarding/InvitePartners/PartnerRow';

type Props = {
  history: RouterHistoryT,
  partner: DiscoveredOrg,
  modalOpen: boolean,
  toggle: () => void,
};

function InviteDiscoveredPartnerModal(props: Props) {
  const { history, partner, modalOpen, toggle } = props;
  if (!partner) {
    return null;
  }
  const { name } = partner;

  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader toggle={toggle} style={{ padding: '5px 25px 10px 25px' }}>
        Partner with {name}
      </ModalHeader>
      <ModalBody>
        <OrgUserContext.Consumer>
          {({ orguser, org }) => (
            <PartnerRow
              org={org}
              orguser={orguser}
              partner={partner}
              onInviteSent={(createdPartner) =>
                setTimeout(() => toggle(() => history.push(`/p/${createdPartner.slug}`)), 1500)
              }
            />
          )}
        </OrgUserContext.Consumer>
      </ModalBody>
    </Modal>
  );
}

export default withRouter(InviteDiscoveredPartnerModal);
