// @flow

import * as React from 'react';

import { Account, Opportunity } from 'data/entities';
import { updateBdRequestOpportunity } from 'data/repositories/bdrequests';

import DisplayEdit from 'components/DisplayEdit';

import OpportunitySearch from './OpportunitySearch';

type Props = {
  opportunity?: Opportunity,
  requestId?: string,
  onUpdate: () => void,
  onErrors?: (errors: any) => void,
  lockMessage?: ?string,
  account: ?Account,
};

const DisplayEditOpportunity = ({
  opportunity,
  requestId,
  onUpdate,
  onErrors,
  lockMessage,
  account,
  ...props
}: Props) => {
  const doUpdateOpportunity = (newOpportunity: Opportunity): Promise<any> => {
    if (opportunity && opportunity.id === newOpportunity?.id) {
      // There was no update, don't call the onSave prop
      return Promise.resolve();
    }
    return updateBdRequestOpportunity(requestId, newOpportunity?.id || null)
      .then(() => {
        onUpdate();
        onErrors && onErrors({ opportunityId: null });
      })
      .catch((errors) => onErrors && onErrors(errors.json));
  };
  const text = opportunity ? opportunity.label : 'No opportunity';
  if (!account) {
    return null;
  }
  return (
    <DisplayEdit
      searchComponent={OpportunitySearch}
      existingValue={opportunity}
      lockMessage={lockMessage}
      onSave={doUpdateOpportunity}
      extraSearchProps={{ ...props, requestId, account }}
      focusOnEdit
    >
      <span>{text}</span>
    </DisplayEdit>
  );
};

DisplayEditOpportunity.defaultProps = {
  onErrors: () => {},
  lockMessage: null,
  opportunity: null,
  requestId: null,
};

export default DisplayEditOpportunity;
