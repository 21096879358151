// @flow

import React from 'react';

type Props = {
  children: any,
  showIndicator?: boolean,
  title?: string,
};

const Loading = ({ title, showIndicator, children }: Props) => (
  <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
    <div
      className="d-flex flex-column align-items-center "
      style={{ position: 'absolute', zIndex: 2, marginTop: 300 }}
    >
      {showIndicator && (
        <>
          <svg viewBox="0 0 378 6" className="mb-2" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <mask id="mask">
                <rect id="progress" width="100%" height="100%" x="0%" y="0" fill="#fff" />
              </mask>
            </defs>
            <animate
              xlinkHref="#progress"
              attributeName="x"
              from="0%"
              to="100%"
              dur="8s"
              repeatCount="indefinite"
            />
            <g fill="#042C59">
              <rect width="58" height="6" rx="3" />
              <rect x="64" width="58" height="6" rx="3" />
              <rect x="128" width="58" height="6" rx="3" />
              <rect x="192" width="58" height="6" rx="3" />
              <rect x="256" width="58" height="6" rx="3" />
              <rect x="320" width="58" height="6" rx="3" />
            </g>
            <g
              mask="url(#mask)"
              fill="#E5E7EB"
              strokeWidth="1px"
              stroke="#E5E7EB"
              strokeLinecap="round"
            >
              <rect width="58" height="6" rx="3" />
              <rect x="64" width="58" height="6" rx="3" />
              <rect x="128" width="58" height="6" rx="3" />
              <rect x="192" width="58" height="6" rx="3" />
              <rect x="256" width="58" height="6" rx="3" />
              <rect x="320" width="58" height="6" rx="3" />
            </g>
          </svg>
          <span className="large-text bold gray-600">{title}</span>
          <span className="large-text bold gray-600">Hang tight...</span>
        </>
      )}
    </div>
    <div
      className="w-100 h-100 overlay-gradient"
      style={{
        position: 'absolute',
        zIndex: 1,
      }}
    />
    <div className="w-100 d-flex flex-column gap-20">{children}</div>
  </div>
);

Loading.defaultProps = {
  showIndicator: true,
  title: 'Crunching the data just for you.',
};

export default Loading;
