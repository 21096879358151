// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchStandardCrossbeamPopulations } from 'data/repositories/crossbeam';

import BareLayout from 'layouts/BareLayout';
import LoadingRing from 'components/LoadingRing';
import Pill from 'components/Pill';

type Props = { onboarding?: () => void };

const StandardPopulations = ({ onboarding }: Props) => {
  const history = useHistory();
  const [standardPopulations, setStandardPopulations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const verifyPopulations = useCallback(() => {
    setLoading(true);
    fetchStandardCrossbeamPopulations()
      .then((sp) => {
        setLoading(false);
        setStandardPopulations(sp);

        // Check if all populations are available, and skip this step.
        if (sp.complete) {
          if (onboarding) {
            onboarding();
          } else {
            history.replace('/crossbeam');
          }
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [history, onboarding]);

  useEffect(() => {
    verifyPopulations();
  }, [verifyPopulations]);

  return (
    <BareLayout centered>
      <h3 className="text-black bold">Set up standard populations</h3>
      <span className="large-text bold gray-500 mb-5 text-center" style={{ width: '600px' }}>
        To get value from Crossbeam for Sales your standard populations need to be created and set
        up in Crossbeam.
      </span>
      <div className="d-flex flex-column align-items-center gap-20" style={{ width: '600px' }}>
        {error && (
          <div className="d-flex flex-column gap-10">
            <span className="large-text semi-bold red align-self-start">
              Not all populations are set up.
            </span>
            <span className="normal-text semi-bold gray-500">
              * It might take a couple of minutes to sync once you create a new population. To
              refresh, click on the “I set them up” button.
            </span>
          </div>
        )}
        {loading ? (
          <LoadingRing maxWidth="50px" />
        ) : (
          standardPopulations &&
          standardPopulations.expected.map((population) => (
            <div
              key={population.name}
              className="disable-selection card card-body m-0 d-flex flex-row align-items-center gap-15 p-1 pr-3"
            >
              <div className="bg-cyan rounded" style={{ width: 4, height: 60 }} />
              <div className="d-flex flex-column flex-fill">
                <div className="d-flex flex-row align-items-center gap-5">
                  <span className="large-text bold gray-700">{population.name}</span>
                  <Pill color="cyan" extra="Standard" />
                </div>
                <span className="normal-text semi-bold gray-500">
                  Add a standard population to better collaborate with your partner.
                </span>
              </div>
              {standardPopulations &&
              standardPopulations.actual
                .map((p) => (p.isStandard ? p.name : null))
                .includes(population.name) ? (
                <div className="d-flex flex-row align-items-center gap-5">
                  <FontAwesomeIcon icon="check" className="green" size="lg" />
                  <span className="normal-text bold green">Created</span>
                </div>
              ) : (
                <a
                  href={`${
                    process.env.REACT_APP_CROSSBEAM_URL || ''
                  }/populations/create?standardType=${population.name
                    .toLowerCase()
                    .replace(' ', '_')}`}
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Create
                </a>
              )}
            </div>
          ))
        )}
        {!loading && (
          <button
            type="button"
            onClick={() => verifyPopulations()}
            className="btn btn-primary w-50 mt-3"
          >
            I set them up
          </button>
        )}
      </div>
    </BareLayout>
  );
};

StandardPopulations.defaultProps = {
  onboarding: null,
};

export default StandardPopulations;
