import React from 'react';

import BareLayout from 'layouts/BareLayout';
import Logo from 'components/Logo';

const CompleteSignupLayout = (props) => (
  <BareLayout centered>
    <Logo className="mb-5" height={30} />
    <div style={{ width: 600 }} className="d-flex flex-column align-items-center">
      <div className="d-flex flex-column mb-2 text-center">
        <h3 className="bold text-black">Welcome to Crossbeam for Sales!</h3>
        <span className="large-text bold gray-500">
          First, add a description for your organization so other teams will better know how to work
          with you as a partner.
        </span>
      </div>
      {props.children}
    </div>
  </BareLayout>
);

export default CompleteSignupLayout;
