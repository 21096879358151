// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  STATUS_ALL,
  statusChoiceList,
  statusChoices,
  statusDisplayValueMap,
  statusToUrlValue,
} from 'data/entities/bdrequest';

import LoadingRing from 'components/LoadingRing';

type Props = {
  currentStatus: string,
  currentSearch: ?string,
  counts: { [typeof statusChoices.REQUESTED]: number },
};

const StatusSelector = ({ currentStatus, currentSearch, counts }: Props) => {
  // Used to select status in BDQueue / BDRequestList view
  const params = currentSearch || '';
  return (
    <div className="d-flex flex-row align-items-center gap-5">
      {[...statusChoiceList, STATUS_ALL].map((status) => {
        const urlStatus = statusToUrlValue(status);
        const url = `/requests/${urlStatus}?${params}`;
        const isActive =
          currentStatus === status || (currentStatus === null && status === STATUS_ALL);
        const count = counts ? counts[status] : null;
        const countStr =
          count != null ? <span className="small-text lh-normal">({count || 0})</span> : '';
        return (
          <Link
            key={status}
            to={url}
            className={classNames('navigation-item', isActive && 'bg-light-blue blue')}
          >
            {statusDisplayValueMap[status]}
            {!counts ? <LoadingRing maxWidth="10px" /> : countStr}
          </Link>
        );
      })}
    </div>
  );
};

export default StatusSelector;
