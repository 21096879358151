// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { Org, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

type Props = {
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
};

const FinishSetup = ({ loggedInOrguser, loggedInOrg }: Props) => {
  const { capabilities } = loggedInOrguser;

  return capabilities.canManageBilling ? (
    <Link to="/complete-signup" className="btn btn-secondary text-white bg-orange">
      Finish Setup
    </Link>
  ) : (
    <span className="normal-text font-italic semi-bold gray-400">
      You must be an admin to finish setup
    </span>
  );
};

export default withOrguser(FinishSetup);
