// @flow

import * as React from 'react';
import { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { refreshSlackChannels } from 'data/repositories/slack';

type Props = {
  onRefresh: () => void,
  text: string,
  height: number,
  width: number,
  disabled: boolean,
  style: Object,
};

const SlackChannelRefreshButton = ({ onRefresh, text, height, width, disabled, style }: Props) => {
  const [loading, setLoading] = useState(false);
  const [refetchError, setRefetchError] = useState(null);
  const [localDisabled, setDisabled] = useState(false);
  const [tooltipIsOpen, setOpen] = useState(false);

  const refresh = () => {
    setDisabled(true);
    setLoading(true);
    refreshSlackChannels()
      .then(() => {
        setLoading(false);
        onRefresh();
        setDisabled(false);
        setRefetchError(false);
      })
      .catch(() => {
        setRefetchError(true);
        setOpen(true);
        setTimeout(() => setOpen(false), 8000);
      });
  };

  const color = refetchError ? 'red' : 'gray-700';
  const loadingClass = color + (loading ? ' fa-spin' : '');
  const combinedStyle = {
    ...(height ? { height } : {}),
    ...(width ? { width } : {}),
    ...style,
  };

  return (
    <>
      <Button
        onClick={refresh}
        disabled={localDisabled || disabled}
        className="px-2 py-1"
        style={combinedStyle}
      >
        <span id="slack-refresh-icon">
          <FontAwesomeIcon icon="refresh" className={loadingClass} /> {text || ''}
        </span>
      </Button>
      {refetchError ? (
        <Tooltip placement="top" target="slack-refresh-icon" isOpen={tooltipIsOpen}>
          Fetching channels from slack is taking a while, we&apos;ll work on this in the background
          until it is complete (feel free to navigate away from this page).
        </Tooltip>
      ) : null}
    </>
  );
};

export default SlackChannelRefreshButton;
