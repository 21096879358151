import React, {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { fetchCrossbeamPartners } from 'data/repositories/crossbeam';
import { getSlackChannels, updateSlackChannels } from 'data/repositories/slack';

import SlackChannelRefreshButton from 'components/SlackChannelRefreshButton';
import SlackChannelSelector from 'components/SlackChannelSelector';

const SlackChannelContext = createContext();

export const useSlackChannel = () => {
  const context = useContext(SlackChannelContext);
  if (!context) {
    throw new Error('useSlackChannel must be used within a SlackChannelProvider');
  }
  return context;
};

export const SlackChannelProvider = ({ orguser, children }) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [partners, setPartners] = useState([]);
  const [slackChannels, setSlackChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  const fetchChannels = useCallback(() => {
    // If we're not logged in, do nothing.
    if (!orguser) {
      return;
    }

    setLoading(true);
    Promise.all([fetchCrossbeamPartners(), getSlackChannels(true)])
      .then(([cp, c]) => {
        setLoading(false);

        // Sort and filter the partners
        cp.sort((a, b) => a.name.localeCompare(b.name));
        const partnersFiltered = cp.filter((p) => p.syncEnabled);

        // Update the state variables
        setPartners(partnersFiltered);
        setSlackChannels(c.results);

        // Initialize selectedChannels based on the fetched partners
        setSelectedChannels(
          partnersFiltered.map((p) => ({
            partnerId: p.partner.id,
            slackChannel: p.partner?.slackChannel,
          }))
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }, [orguser]);

  useEffect(fetchChannels, [fetchChannels]); // TODO: could use cleanup function

  const onSave = useCallback(
    (localSelectedChannels) =>
      new Promise((resolve, reject) => {
        setSubmitting(true);
        updateSlackChannels(
          localSelectedChannels.map((sc) => ({
            partnerId: sc.partnerId,
            channelId: sc.slackChannel?.id ?? null,
          }))
        )
          .then(() => {
            setSubmitting(false);
            resolve();
          })
          .catch((error) => {
            setSubmitting(false);
            reject(error);
          });
      }),
    []
  ); // TODO: Could use a cleanup function

  const value = {
    loading,
    submitting,
    partners,
    slackChannels,
    selectedChannels,
    setSelectedChannels,
    fetchChannels,
    onSave,
  };

  return <SlackChannelContext.Provider value={value}>{children}</SlackChannelContext.Provider>;
};

export const WithSlackChannelRefreshButton = ({ text, height, disabled, style }) => {
  const { fetchChannels } = useSlackChannel();
  return (
    <SlackChannelRefreshButton
      onRefresh={fetchChannels}
      text={text}
      height={height}
      disabled={disabled}
      style={style}
    />
  );
};

export const WithSlackChannelSelector = ({ partnerId, autoSave, disabled }) => {
  const { slackChannels, selectedChannels, setSelectedChannels, onSave } = useSlackChannel();
  const selectedChannel = selectedChannels.find((sc) => sc.partnerId === partnerId)?.slackChannel;
  const ref = createRef();
  const onChannelSelect = (localPartnerId, slackChannel) => {
    const updatedChannels = selectedChannels.map((s) =>
      s.partnerId === localPartnerId ? { partnerId: localPartnerId, slackChannel } : s
    );
    setSelectedChannels(updatedChannels);
    if (autoSave) {
      onSave(updatedChannels);
    }
    ref.current?.blur();
  };

  return (
    <SlackChannelSelector
      disabled={disabled}
      channels={slackChannels}
      selectedChannel={selectedChannel}
      onSelect={(sc) => onChannelSelect(partnerId, sc)}
      ref={ref}
    />
  );
};
