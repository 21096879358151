// @flow

import * as React from 'react';
import classNames from 'classnames';

import { PartnerAccount } from 'data/entities';

import InfoTip from 'components/InfoTip';

type Props = {
  account: PartnerAccount,
  withExpires?: boolean,
  skipRightPad?: boolean,
};

function AccountStatusAlertTooltip(props: Props) {
  const { account, withExpires, skipRightPad } = props;
  if (!account.statusAlertText) {
    return null;
  }
  const className = classNames('StatusAlertInfoTip', {
    'StatusAlertInfoTip--skipRightPad': skipRightPad,
  });
  return (
    <InfoTip targetId={account.id} className={className}>
      {account.statusAlertText}
      {withExpires && (
        <>
          <br />
          <em>
            <strong> Until: {account.statusAlertExpiresAt}</strong>
          </em>
        </>
      )}
    </InfoTip>
  );
}

AccountStatusAlertTooltip.defaultProps = {
  skipRightPad: false,
  withExpires: false,
};

export default AccountStatusAlertTooltip;
