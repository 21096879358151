// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from 'contexts';
import logout from 'authFetch/logout';

type Props = {
  onLogout: () => void,
};

function LogoutInner({ onLogout, user }: Props) {
  const [isLoading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (user) {
      logout()
        .then(onLogout)
        .finally(() => {
          window.location.reload();
        });
    } else {
      setLoading(false);
    }
  }, [user, onLogout]);
  if (isLoading) {
    return null;
  }
  return <Redirect to="/login" from="/" />;
}

export default function Logout({ onLogout }: Props) {
  return (
    <UserContext.Consumer>
      {({ user }) => <LogoutInner onLogout={onLogout} user={user} />}
    </UserContext.Consumer>
  );
}
