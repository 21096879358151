// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import { BentoLoaderContext } from 'contexts/BentoLoaderContext';

function GetStarted() {
  return (
    <div className="page-top-padding">
      <Card>
        <CardBody>
          <bento-embed />
        </CardBody>
      </Card>
    </div>
  );
}

export default function GetStartedContainer() {
  return (
    <BentoLoaderContext.Consumer>
      {({ showGettingStarted }) => (showGettingStarted ? <GetStarted /> : <Redirect to="/" />)}
    </BentoLoaderContext.Consumer>
  );
}
