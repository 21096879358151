// @flow

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import type { Org, OrgUser } from 'data/entities';
import type NetworkProfile from 'data/entities/networkprofile';
import { fetchNetworkProfile } from 'data/repositories/networkprofile';
import withOrguser from 'contexts/withOrguser';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import LoadingRing from 'components/LoadingRing';

import Accounts from './Accounts';
import OtherMembers from './OtherMembers';
import ProfileInfo from './ProfileInfo';

type Props = {
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
  history: RouterHistoryT,
  match: { params: { orgId: string, id: string } },
};

const NetworkProfileProfile = (props: Props) => {
  const {
    loggedInOrguser,
    loggedInOrg: { name: orgName, id: ownOrgId },
    history,
    match: {
      params: { id },
    },
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [networkProfile, setNetworkProfile] = useState<NetworkProfile>(null);
  const isOwnProfile = networkProfile && networkProfile.id === loggedInOrguser.id;
  const isOwnOrg = networkProfile && ownOrgId === networkProfile.org.id;

  useEffect(() => {
    setLoading(true);
    setError(false);
    setNetworkProfile(null);
    fetchNetworkProfile(id)
      .then((sp) => {
        setLoading(false);
        setError(false);
        setNetworkProfile(sp);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [id]);

  if (error) {
    return (
      <div className="m-5 text-center">
        <h6>This profile is not available</h6>
        <p className="mb-3 normal-text semi-bold gray-500">
          The link may be broken, or the profile may have been removed.
        </p>
        <button className="btn btn-primary btn-sm" type="button" onClick={history.goBack}>
          Go back
        </button>
      </div>
    );
  }

  if (loading || !networkProfile) {
    return <LoadingRing className="m-5" />;
  }

  return (
    <div className="d-flex flex-md-row flex-column mb-4 gap-10">
      <ProfileInfo
        networkProfile={networkProfile}
        orgName={orgName}
        isOwnOrg={isOwnOrg}
        isOwnProfile={isOwnProfile}
      />
      <Accounts networkProfile={networkProfile} isOwnOrg={isOwnOrg} />
      <OtherMembers networkProfile={networkProfile} isOwnOrg={isOwnOrg} />
    </div>
  );
};

export default withRouter(withOrguser(NetworkProfileProfile));
