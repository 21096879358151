// @flow

import { uniq } from 'lodash';

import type { CrossbeamSource } from 'data/entities/crossbeam';

const accountMapping = {
  type: 'account_mapping',
  friendly_name: 'Account Map',
  description:
    'Compare your data with partners to identify overlapping prospects, opportunities, and customers',
  summary: 'Unlocks Account Mapping',
  salesforce: {
    Account: [
      'Id',
      'OwnerId',
      'Name',
      'Type',
      'Website',
      'BillingCountry',
      'Industry',
      'NumberOfEmployees',
      'CreatedDate',
    ],
    Opportunity: ['Id', 'AccountId', 'Name', 'StageName'],
  },
  hubspot: {
    companies: [
      'companyId',
      'hubspot_owner_id__value',
      'name__value',
      'type__value',
      'website__value',
      'country__value',
      'closedate__value',
      'numberofemployees__value',
      'industry__value',
      'createdate__value',
    ],
    deals: [
      'dealId',
      'dealstage__value',
      'dealname__value',
      'pipeline__value',
      'dealstage_label',
      'pipeline_label',
    ],
  },
  hubspot_v3: {
    companies: [
      'id',
      'hubspot_owner_id',
      'name',
      'type',
      'website',
      'country',
      'closedate',
      'numberofemployees',
      'industry',
      'createdate',
    ],
    deals: ['id', 'dealstage', 'dealname', 'pipeline', 'dealstage_label', 'pipeline_label'],
  },
  microsoft_dynamics: {
    accounts: [
      'accountid',
      'ownerid',
      'name',
      'customertypecode',
      'websiteurl',
      'address1_country',
      'industrycodename',
      'numberofemployees',
      'createdon',
    ],
    opportunities: ['opportunityid', 'parentaccountid', 'name', 'salesstage'],
  },
};

const coMarket = {
  type: 'co_market',
  friendly_name: 'Co-Market',
  description:
    'Target overlapping prospects, opportunities, and customers for your better together stories and integration outreach',
  summary: 'Unlocks Opportunity Reporting',
  salesforce: {
    Account: [...accountMapping.salesforce.Account],
    Opportunity: [
      ...accountMapping.salesforce.Opportunity,
      'Amount',
      'IsClosed',
      'IsWon',
      'CreatedDate',
      'CloseDate',
    ],
  },
  hubspot: {
    companies: [...accountMapping.hubspot.companies],
    deals: [
      ...accountMapping.hubspot.deals,
      'amount__value',
      'days_to_close__value',
      'hs_is_closed_won__value',
      'hs_date_entered_closedwon__value',
      'hs_date_exited_closedwon__value',
      'hs_is_closed__value',
      'createdate__value',
      'closedate__value',
    ],
  },
  hubspot_v3: {
    companies: [...accountMapping.hubspot_v3.companies],
    deals: [
      ...accountMapping.hubspot_v3.deals,
      'amount',
      'days_to_close',
      'hs_is_closed_won',
      'hs_date_entered_closedwon',
      'hs_date_exited_closedwon',
      'hs_is_closed',
      'createdate',
      'closedate',
    ],
  },
  microsoft_dynamics: {
    accounts: [...accountMapping.microsoft_dynamics.accounts],
    opportunities: [
      ...accountMapping.microsoft_dynamics.opportunities,
      'budgetamount',
      'createdon',
      'actualclosedate',
    ],
  },
};

const pipelineMapping = {
  type: 'pipeline_mapping',
  friendly_name: 'Pipeline Map',
  description:
    'Accelerate opportunities and forecast revenue more accurately with more visibility into your partner’s pipeline',
  summary: 'Unlocks Potential Revenue',
  salesforce: {
    Account: uniq([...accountMapping.salesforce.Account]),
    Opportunity: [...coMarket.salesforce.Opportunity],
    User: ['Id', 'Name', 'Email'],
  },
  hubspot: {
    companies: uniq([...accountMapping.hubspot.companies]),
    deals: [...coMarket.hubspot.deals],
    owners: ['ownerId', 'email', 'firstName', 'lastName'],
  },
  hubspot_v3: {
    companies: uniq([...accountMapping.hubspot_v3.companies]),
    deals: [...coMarket.hubspot_v3.deals],
    owners: ['id', 'email', 'firstName', 'lastName'],
  },
  microsoft_dynamics: {
    accounts: uniq([...accountMapping.microsoft_dynamics.accounts]),
    opportunities: [...coMarket.microsoft_dynamics.opportunities],
    systemusers: ['systemuserid', 'internalemailaddress', 'fullname'],
  },
};

const coSelling = {
  type: 'co_selling',
  friendly_name: 'Co-Sell',
  description:
    'Use your partner ecosystem to surface pre-vetted overlapping accounts that can shorten sales cycles and increase opportunity sizes',
  summary: 'Unlocks Crossbeam for Sales, Potential Revenue, Opportunity Reporting, and Attribution',
  salesforce: {
    Account: [...pipelineMapping.salesforce.Account],
    Opportunity: [...coMarket.salesforce.Opportunity],
    User: [...pipelineMapping.salesforce.User],
    Contact: ['Id', 'AccountId', 'Name', 'Email'],
  },
  hubspot: {
    companies: [...pipelineMapping.hubspot.companies],
    deals: [...coMarket.hubspot.deals],
    owners: [...pipelineMapping.hubspot.owners],
    contacts: [
      'vid',
      'associatedcompanyid__value',
      'firstname__value',
      'lastname__value',
      'email__value',
    ],
  },
  hubspot_v3: {
    companies: [...pipelineMapping.hubspot_v3.companies],
    deals: [...coMarket.hubspot_v3.deals],
    owners: [...pipelineMapping.hubspot_v3.owners],
    contacts: ['id', 'associatedcompanyid', 'firstname', 'lastname', 'email'],
  },
  microsoft_dynamics: {
    accounts: [...pipelineMapping.microsoft_dynamics.accounts],
    opportunities: [...coMarket.microsoft_dynamics.opportunities],
    systemusers: [...pipelineMapping.microsoft_dynamics.systemusers],
    contacts: ['contactid', 'parentcustomerid', 'fullname', 'emailaddress1'],
  },
};

function fieldPresentAndSyncing(column, existingObject) {
  return !!existingObject.fields.find((field) => field.column === column);
}

function getMissingTemplateInfo(sources = [], crm: string) {
  const t = coSelling[crm];
  return Object.entries(t).reduce((acc, [object, columns]) => {
    const existingObject = sources.find((source) => source.table === object);
    if (!existingObject) {
      acc[object] = [];
      return acc;
    }

    const missingColumns = columns.filter(
      (column) => !fieldPresentAndSyncing(column, existingObject)
    );
    if (missingColumns.length > 0) {
      acc[object] = missingColumns;
      return acc;
    }
    return acc;
  }, {});
}

/* Does any CRM source have all the fields needed for the preset? */
export const isUsingPreset = (sources: CrossbeamSource[]) => {
  const crms = Object.keys(coSelling);
  return crms.some((crm) => {
    const missing = getMissingTemplateInfo(sources, crm);
    return Object.keys(missing).length === 0;
  });
};

export default coSelling;
