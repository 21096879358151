// @flow

import * as React from 'react';

import { Org, OrgUser } from 'data/entities';
import { fetchUserOrg } from 'data/repositories/orgs';
import { fetchOrgUser } from 'data/repositories/orgusers';

type ContextShape = { org: ?Org, orguser: ?OrgUser };
const defaultContext: ContextShape = {
  org: null,
  orguser: null,
  refreshOrg: () => {},
};

export const OrgUserContext = React.createContext<ContextShape>(defaultContext);

type OrgUserContextProviderShape = {
  children: any,
  org: ?Org,
  orguser: ?OrgUser,
};

export function OrgUserContextProvider({ children, org, orguser }: OrgUserContextProviderShape) {
  // Eventually we should also have refreshOrguser: () => {},
  const [stateOrg, setOrg] = React.useState<?Org>(org);
  const [stateOrgUser, setOrgUser] = React.useState<?OrgUser>(orguser);

  React.useEffect(() => {
    setOrg(org);
  }, [org]);
  React.useEffect(() => {
    setOrgUser(orguser);
  }, [orguser]);

  function refreshOrg(): Promise<?Org> {
    return fetchUserOrg().then(setOrg);
  }

  function refreshOrgUser(): Promise<?OrgUser> {
    return fetchOrgUser(orguser.id).then(setOrgUser);
  }

  return (
    <OrgUserContext.Provider
      value={{
        refreshOrg,
        org: stateOrg,
        orguser: stateOrgUser,
        refreshOrgUser,
        setOrgUser,
        setOrg,
        lastIntegration: stateOrg && stateOrg.lastIntegration,
      }}
    >
      {children}
    </OrgUserContext.Provider>
  );
}
