// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, merge, reduce } from 'lodash';

import { ConfigFieldMultiValue, Integration } from 'data/entities/chug';
import { fetchCurrentAccountIsTargetFilters } from 'data/repositories/chug';
import withOrguser from 'contexts/withOrguser';
import { ErrorResponse } from 'authFetch/entities';

import LoadingRing from 'components/LoadingRing';
import Target from 'assets/img/target';

const SELECT_TARGET = 'SELECT_TARGET';
export const ALL_TARGET_ACCOUNTS = 'ALL_TARGET_ACCOUNTS';

type Props = {
  selected: ?string,
  onChange: (value: ?string) => void,
  showAll?: ?boolean,
  lastIntegration: ?Integration,
};

type State = {
  isLoading: boolean,
  currentConfig: ?ConfigFieldMultiValue,
  options: any[],
};

class AccountIsTargetField extends React.Component<Props, State> {
  state = { isLoading: true, currentConfig: null, options: [], targetDisabled: false };

  componentDidMount() {
    this.loadIsTargetConfig();
  }

  get capableOfSetTargetAccounts(): boolean {
    const { lastIntegration } = this.props;
    return lastIntegration && lastIntegration.capabilities.canSetTargetAccounts;
  }

  loadIsTargetConfig = () => {
    const { selected, onChange, showAll } = this.props;
    const { capableOfSetTargetAccounts } = this;
    if (!capableOfSetTargetAccounts) {
      return;
    }
    fetchCurrentAccountIsTargetFilters()
      .then((currentConfig) => {
        const options = merge(
          {
            [ALL_TARGET_ACCOUNTS]: '(All Target Accounts)',
          },
          reduce(
            currentConfig && currentConfig.values,
            (acc, { label, value }) => ({ ...acc, [value]: label }),
            {}
          )
        );
        if (selected && !this.findValue(options, selected)) {
          onChange(null);
        } else if (showAll && !currentConfig.name) {
          onChange(null);
        }

        this.setState({ currentConfig, isLoading: false, options });
      })
      .catch((error: ErrorResponse) => {
        if (error.response.status === 404) {
          this.setState({ targetDisabled: true });
        }
        this.setState({ isLoading: false });
      });
  };

  onChange = (event: Event) => {
    const { onChange } = this.props;
    const value = encodeURIComponent(event.target.value);
    onChange(value === SELECT_TARGET ? null : value);
  };

  parseSelected = (selected: string) => {
    try {
      return JSON.parse(selected);
    } catch (error) {
      return null;
    }
  };

  findValue = (options: any[], value: string) =>
    options[this.parseSelected(value)] || options[value];

  render() {
    const { isLoading, currentConfig, options, targetDisabled } = this.state;
    const { selected, showAll, loggedInOrguser, onChange } = this.props;
    const { capableOfSetTargetAccounts } = this;
    if (!capableOfSetTargetAccounts) {
      return null;
    }
    if (isLoading) {
      return <LoadingRing maxWidth="25px" />;
    }
    if (!currentConfig || !currentConfig.name) {
      if (!loggedInOrguser.canManage || targetDisabled) {
        return null;
      }
      return (
        <Link
          to="/admin/settings/crm-integration/is-target-accounts-configuration"
          className="btn btn-outline btn-md mr-3 clearfix"
        >
          <FontAwesomeIcon icon="exclamation-circle" /> Configure Target Accounts
        </Link>
      );
    }
    const currentSelected = showAll ? ALL_TARGET_ACCOUNTS : selected || SELECT_TARGET;
    const unselected = currentSelected === SELECT_TARGET;
    const selectLabel = unselected ? 'Select Target' : this.findValue(options, currentSelected);
    return (
      <ButtonGroup>
        <UncontrolledButtonDropdown group={!unselected} className="flex-fill">
          <DropdownToggle outline={unselected} className="w-100">
            <Target width="20" disabled={currentSelected === SELECT_TARGET} className="mr-2" />{' '}
            {selectLabel} <FontAwesomeIcon icon="chevron-circle-down" className="ml-1" size="sm" />
          </DropdownToggle>
          <DropdownMenu>
            {map(options, (label, value) => (
              <DropdownItem onClick={this.onChange} value={value} key={value}>
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
        {!unselected && (
          <Button
            onClick={() => {
              onChange(null);
            }}
            className="px-2"
            outline
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        )}
      </ButtonGroup>
    );
  }
}
AccountIsTargetField.defaultProps = {
  showAll: false,
};

export default withOrguser(AccountIsTargetField);
