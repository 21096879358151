/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */
// @flow

export class SalesCollateralLink {
  id: string;

  title: string;

  url: string;

  static fromApi(d: any): SalesCollateralLink {
    const scl = new SalesCollateralLink();
    scl.id = d.id;
    scl.title = d.title;
    scl.url = d.url;

    return scl;
  }
}
