// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import queryString from 'qs';

import { Org } from 'data/entities';
import { NEW_MESSAGE_BUTTON_CLICK } from 'data/entities/tracking';
import { trackAction } from 'data/repositories/tracking';
import withOrguser from 'contexts/withOrguser';
import EventBus from 'utils/EventBus';

type Props = {
  newActivity?: boolean,
  extraClasses?: string,
  style?: any,
  accountSlug?: string,
  partnerSlug?: ?string,
  btnSize?: string,
  partnerAccountId?: ?string,
  toOrgUser?: ?string,
  compact?: boolean,
  apiSource?: string,
  text?: string,
  disabled?: boolean,
  loggedInOrg: Org,
};

const defaultProps = {
  newActivity: false,
  extraClasses: '',
  style: {},
  accountSlug: null,
  btnSize: 'sm',
  partnerAccountId: null,
  partnerSlug: null,
  toOrgUser: null,
  compact: false,
  apiSource: null,
  text: 'Message',
  disabled: false,
};

const NewBdRequestButton = ({
  newActivity,
  extraClasses,
  style,
  accountSlug: account,
  partnerSlug,
  btnSize = defaultProps.btnSize,
  partnerAccountId,
  toOrgUser,
  compact,
  apiSource,
  text,
  disabled,
  loggedInOrg,
}: Props) => {
  const classes = classNames(
    'btn',
    'btn-primary',
    extraClasses,
    `btn-${btnSize}`,
    compact && 'px-2',
    disabled && 'seatless-overlay'
  );
  const query = queryString.stringify(
    { account, partner: partnerSlug, partnerAccountId, to: toOrgUser },
    { skipNulls: true }
  );
  const queryStr = query ? `?${query}` : '';
  let btnText = text;
  if (newActivity) {
    btnText = (
      <>
        <FontAwesomeIcon icon="plus" className="mr-1" /> Internal request
      </>
    );
  }

  if (compact) {
    btnText = <FontAwesomeIcon icon="comment-dots" size="lg" />;
  }

  const newMessage = () => {
    const message = {
      accountSlug: account,
      partnerSlug,
      partnerAccountId,
      toOrgUserId: toOrgUser,
      apiSource,
    };

    trackAction(NEW_MESSAGE_BUTTON_CLICK, message);

    return EventBus.dispatch('newMessage', message);
  };

  const state = { canGoBack: !newActivity };
  let pathname = loggedInOrg.settings.requestsWorkflow ? '/requests/new' : '/requests/log-activity';
  if (newActivity) {
    pathname = '/requests/log-activity';
  }
  return newActivity || loggedInOrg.settings.requestsWorkflow ? (
    <Link
      to={{
        pathname,
        state,
        search: queryStr,
      }}
      className={classes}
      style={style}
    >
      {btnText}
    </Link>
  ) : (
    <button
      type="button"
      onClick={newMessage}
      className={classes}
      style={style}
      disabled={disabled}
    >
      {btnText}
    </button>
  );
};

NewBdRequestButton.defaultProps = defaultProps;

export default withOrguser(NewBdRequestButton);
