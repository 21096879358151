// @flow

import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser, Partner } from 'data/entities';
import { DiscoveredOrg } from 'data/entities/org';
import { OverlapSharingSettings } from 'data/entities/partner';
import { createPartner, defaultAllOnPartnerApiSettings } from 'data/repositories/partners';

import PartnerMappingSettingsModal from 'views/AccountMapping/PartnerMappingSettingsModal';
import CompanyLogo from 'components/CompanyLogo';
import FieldErrors from 'components/FieldErrors';
import Logo from 'components/Logo';

type Types = {
  org: Org,
  orguser: OrgUser,
  partner: DiscoveredOrg,
  onInviteSent: (partner: Partner) => void,
};

const PartnerRow = ({ org, orguser, partner, onInviteSent }: Types) => {
  const [email, setEmail] = useState('');
  const [invited, setInvited] = useState(false);
  const [errors, setErrors] = useState(false);
  const isOnCrossbeamForSales = partner.id !== null;

  const [isOpen, setIsOpen] = useState(false);
  const [settings, setSettings] = useState(defaultAllOnPartnerApiSettings());
  const toggle = () => setIsOpen((o) => !o);

  const invite = () => {
    const selectedManager = partner.partnerManagers.find((e) => e.email === email);
    const invitedOrgUserId = selectedManager?.id;
    const passSettings = isOnCrossbeamForSales ? settings : undefined;

    createPartner(
      orguser.id,
      partner.name,
      partner.domain,
      true,
      '',
      '',
      email,
      partner.id,
      invitedOrgUserId,
      passSettings
    )
      .then((createdPartner) => {
        setInvited(true);
        setErrors(false);
        onInviteSent(createdPartner);
      })
      .catch(({ json: err }) => setErrors(err));
  };
  return (
    <div className="d-flex flex-column gap-10">
      <div className="d-flex flex-row gap-10 align-items-center">
        <CompanyLogo name={partner.name} domain={partner.domain} size={28} className="" />
        <div className="d-flex flex-column align-items-start">
          <span className="normal-text semi-bold gray-600">
            <a href={`https://${partner.domain}`} target="_blank" className="gray-600">
              {partner.name}
            </a>
            {isOnCrossbeamForSales && <Logo className="ml-2" height={12} text={false} />}
          </span>
          {isOnCrossbeamForSales && (
            <span className="normal-text bold gray-500">
              <span className="gray-600">{partner.headcount} new connections</span> for your sales
              team’s network
            </span>
          )}
        </div>
      </div>
      <FieldErrors errors={errors && errors?.name} />
      <FieldErrors errors={errors && errors?.domain} />
      <div className="d-flex flex-row gap-20">
        {isOnCrossbeamForSales ? (
          <Typeahead
            placeholder={`e.g. mark@${partner.domain}`}
            value={email}
            options={partner.partnerManagers.map((e) => e.email)}
            onInputChange={(e) => setEmail(e)}
            onChange={(e) => setEmail(e[0])}
            disabled={invited}
            className="w-100"
            clearButton
          />
        ) : (
          <input
            placeholder={`e.g. mark@${partner.domain}`}
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={invited}
          />
        )}

        <Button
          disabled={invited}
          color={invited ? 'success' : 'primary'}
          className="px-1"
          style={{ width: '130px' }}
          onClick={invite}
        >
          {invited ? (
            <FontAwesomeIcon icon="check" />
          ) : (
            <>{isOnCrossbeamForSales ? 'Connect' : 'Send Invite'}</>
          )}
        </Button>
      </div>
      <FieldErrors errors={errors && errors?.invite?.email} />
      <Modal isOpen={isOpen} toggle={toggle} size="xl">
        <PartnerMappingSettingsModal
          partner={{
            ...partner,
            overlapSharingSegments: false,
            settings,
          }}
          toggleState={toggle}
          onUpdate={setSettings}
          orgVisibilitySettings={org.visibilitySettings}
          refreshPartner={() => {}}
          onboarding
        />
      </Modal>
      {isOnCrossbeamForSales && (
        <button
          type="submit"
          className="p-0 btn btn-link align-self-start"
          disabled={invited}
          onClick={toggle}
        >
          <span className="small-text semi-bold gray-400">
            {settings.overlapSharingSetting === OverlapSharingSettings.ALL
              ? 'Share profiles and overlapping accounts.'
              : 'Don’t share any profiles or accounts.'}
            <FontAwesomeIcon icon="pencil-alt" className="ml-2" />
          </span>
        </button>
      )}
    </div>
  );
};

export default PartnerRow;
