/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CoSellingSlackConfig, Org, OrgUser, SlackChannel } from 'data/entities';
import { OrgUserNameOnly } from 'data/entities/orguser';
import {
  getCoSellingSlackConfig,
  getSlackChannels,
  setCoSellingSlackConfig,
} from 'data/repositories/slack';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';
import SlackChannelRefreshButton from 'components/SlackChannelRefreshButton';
import SlackChannelSelector from 'components/SlackChannelSelector';
import SlackLogo from 'components/SlackLogo';
import UsersSelector from 'components/UsersSelector';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const CoSellingSlackConfigModal = ({ loggedInOrg, loggedInOrguser }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  /* State */
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedSlackChannel, setSelectedSlackChannel] = useState<SlackChannel | null>(null);
  const [weeklyGoal, setWeeklyGoal] = useState<string>('');
  const [excludedOrgusers, setExcludedOrgusers] = useState<OrgUserNameOnly[]>([]);

  /* Load config */
  const [configuration, setConfiguration] = useState<CoSellingSlackConfig | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getCoSellingSlackConfig()
      .then((c) => {
        setConfiguration(c);
        setSelectedSlackChannel(c.slackChannel ?? null);
        setWeeklyGoal(c.requestsWeeklyGoal.toString());
        setExcludedOrgusers(c.excludedOrgusers);
        setLoading(false);
      })
      .catch(() => {
        setConfiguration(null);
        setLoading(false);
      });
  }, []);

  /* Load slack channels */
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const [loadingSlackChannels, setLoadingSlackChannels] = useState(false);
  const getChannels = useCallback(() => {
    setLoadingSlackChannels(true);
    getSlackChannels()
      .then((c) => {
        setSlackChannels(c.results);
        setLoadingSlackChannels(false);
      })
      .catch(() => {
        setLoadingSlackChannels(false);
      });
  }, []);
  useEffect(() => {
    if (open) {
      getChannels();
    }
  }, [open, getChannels]);

  const save = () => {
    setSubmitting(true);
    setErrors({});
    setCoSellingSlackConfig(
      selectedSlackChannel?.id ?? null,
      +weeklyGoal,
      Array.from(new Set(excludedOrgusers.map((o) => o.id)))
    )
      .then((c) => {
        setConfiguration(c);
        setSubmitting(false);
        toggle();
      })
      .catch((e) => {
        setSubmitting(false);
        setErrors(e.json || {});
      });
  };

  return (
    <>
      <div className="bg-background-body p-3 rounded d-flex flex-row mt-3 align-items-center gap-15">
        <div
          className="rounded bg-white border-light p-2 d-flex align-items-center justify-content-center"
          style={{ width: 28, height: 28 }}
        >
          <FontAwesomeIcon icon="bell" size="1x" className="gray-600" />
        </div>
        {loading ? (
          <LoadingRing maxWidth="40px" />
        ) : (
          <>
            <div className="d-flex flex-column flex-fill">
              <span className="large-text bold gray-700">
                Slack Weekly Performance Notification
              </span>
              {configuration?.slackChannel && (
                <div className="d-flex flex-row gap-5 align-items-center">
                  <SlackLogo height={14} width={14} />
                  <span className="normal-text semibold gray-500">
                    {`#${configuration.slackChannel.name}`}
                  </span>
                </div>
              )}
            </div>

            <Button color={configuration ? 'secondary' : 'primary'} onClick={toggle}>
              {configuration ? 'Edit' : 'Select a Channel'}
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={open}
        toggle={toggle}
        title="Slack Weekly Performance Notification"
        footer={
          <>
            <Button disabled={submitting} color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" disabled={submitting} onClick={save}>
              Save
            </Button>
          </>
        }
      >
        <div className="d-flex flex-column gap-10">
          <div className="d-flex flex-column gap-5">
            <span className="normal-text semibold gray-700">Sales Team Slack Channel</span>
            <div className="d-flex flex-row align-items-center gap-10">
              <div className="flex-fill">
                <SlackChannelSelector
                  loadingChannels={loadingSlackChannels}
                  channels={slackChannels}
                  selectedChannel={selectedSlackChannel}
                  onSelect={setSelectedSlackChannel}
                  showSubText
                />
              </div>
              <SlackChannelRefreshButton onRefresh={() => getChannels()} showText />
            </div>
          </div>
          <div className="d-flex flex-column gap-5">
            <span className="normal-text semibold gray-700">
              Goal (no. of requests sent per week)
            </span>
            <Input
              type="number"
              name="goal"
              placeholder="10"
              value={weeklyGoal}
              min={0}
              onChange={(e) => setWeeklyGoal(e.target.value)}
            />
          </div>
          <FieldErrors errors={errors && errors.requestsWeeklyGoal} />
          <div className="d-flex flex-column gap-5">
            <span className="normal-text semibold gray-700">Exclude from the leaderboard</span>
            <UsersSelector
              selected={excludedOrgusers}
              onSelect={setExcludedOrgusers}
              initalSelected={[]}
              disabled={submitting}
              onlyAccountOwners
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withOrguser(CoSellingSlackConfigModal);
