export const MAX_TEXT_LENGTH = 110;
export const MAX_QUESTIONS = 20;
export const MAX_TALKING_POINTS = 5;

export const Step = {
  QUESTIONS: 0,
  TALKING_POINTS: 1,
};

export const StepElements = [
  {
    step: Step.QUESTIONS,
    number: 1,
    title: 'Add questions to ask partners',
  },
  {
    step: Step.TALKING_POINTS,
    number: 2,
    title: 'Add context to provide to partners',
  },
];
