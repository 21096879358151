// @flow

import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import { isProduction } from 'utils/environment';

type DropdownItemProps = {
  title: string,
  icon: string,
  onClick?: () => void,
};

const DropdownItem = ({ title, icon, onClick }: DropdownItemProps) => (
  <div
    tabIndex={0}
    role="button"
    className="header-menu-item d-flex flex-row gap-10 align-items-center my-1"
    onClick={onClick}
    onKeyPress={onClick}
  >
    <FontAwesomeIcon icon={icon} width={16} className="gray-600" />
    <span className="normal-text bold gray-700 text-nowrap">{title}</span>
  </div>
);

DropdownItem.defaultProps = {
  onClick: () => {},
};

type Props = {
  loggedInOrguser: OrgUser,
};

const HelpMenu = ({ loggedInOrguser }: Props) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((o) => !o);
  };

  const openIntercom = () => {
    window.Intercom('boot', {
      app_id: isProduction ? 'ugh1893t' : 'g42wpi5b',
      user_id: loggedInOrguser.id,
      user_hash: loggedInOrguser.intercomHash,
      hide_default_launcher: true,
    });
    window.Intercom('showNewMessage');
  };

  return (
    <Dropdown className="pull-right" isOpen={open} toggle={toggle}>
      <DropdownToggle className="profile-dropdown-toggle p-0" color="link">
        <FontAwesomeIcon width={20} icon="question-circle" className="gray-600" />
      </DropdownToggle>
      <DropdownMenu className="profile-dropdown py-1" right>
        <a
          href="https://help.crossbeam.com/en/articles/6699011-sales-edge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DropdownItem title="Documentation" icon="book" />
        </a>
        <DropdownItem title="Contact Us" icon="comment-dots" onClick={openIntercom} />
        <DropdownItem title="Suggest an Improvement" icon="wrench" onClick={openIntercom} />
      </DropdownMenu>
    </Dropdown>
  );
};

export default withOrguser(HelpMenu);
