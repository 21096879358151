// @flow

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  text: string,
  title: ?string,
  showTitle?: boolean,
  onCopied?: () => void,
  secondary?: boolean,
};

const Copy = ({ text, title, showTitle, onCopied, secondary }: Props) => {
  const [copied, setCopied] = useState(false);

  const copy = (e) => {
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        onCopied && onCopied();
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  return (
    <button
      className={`btn ${secondary ? 'btn-secondary' : 'btn-link p-0'}`}
      onClick={copy}
      style={{ cursor: 'pointer', width: 'fit-content', whiteSpace: 'nowrap' }}
      type="button"
    >
      <FontAwesomeIcon title={title} icon={copied ? 'check' : 'copy'} className="mr-2 gray-700" />
      {showTitle && <span className="bold normal-text gray-700">{copied ? 'Copied' : title}</span>}
    </button>
  );
};

Copy.defaultProps = {
  showTitle: true,
  secondary: false,
  onCopied: () => {},
};

export default Copy;
