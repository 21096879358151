// @flow

import * as React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account, Org, Partner, SuggestedAccountMap } from 'data/entities';
import { VisibilitySettings } from 'data/entities/org';

import DeclineAccountMapping from 'components/DeclineAccountMapping';
import InfoTip from 'components/InfoTip/InfoTip';
import LogoAvatar from 'components/LogoAvatar';
import Target from 'assets/img/target';

type StatusProps = {
  partner: Partner,
  orgVisibilitySettings: VisibilitySettings,
  account: Account,
};

const StatusWithVisibility = (props: StatusProps) => {
  const { orgVisibilitySettings, account, partner } = props;
  // Component returning the status with Partner visiblity info
  const orgHideAccountStatus = orgVisibilitySettings && orgVisibilitySettings.hideAccountStatus;
  const partnerAccountStatusVisible =
    partner && partner.settings.visibilitySettings.accountStatusVisible;
  const hideAccountStatus = orgHideAccountStatus || !partnerAccountStatusVisible;
  const { status } = account;
  const targetId = `AccountStatusVisibility_${account.slug}`;
  // const visibilityIcon = hideAccountStatus ? <InfoTip targetId={targetId} icon="eye-slash">Not visible to partners</InfoTip> : null;
  if (hideAccountStatus) {
    return (
      <span style={{ opacity: 0.3 }}>
        <span id={targetId} className="InfoTip">
          {status} <FontAwesomeIcon icon="eye-slash" />
        </span>
        <UncontrolledTooltip placement="right" target={targetId} delay={{ show: 200 }}>
          Not visible to partners
        </UncontrolledTooltip>
      </span>
    );
  }
  return <span>{status}</span>;
};

const PartnerAccountName = ({ single, partnerAccounts, accountSelectDisabled, onSelect }) =>
  single ? (
    <span>
      {' '}
      {partnerAccounts[0].isTarget && <Target />} {partnerAccounts[0].name}
    </span>
  ) : (
    <select
      onChange={onSelect}
      className="custom-select custom-select-md form-control"
      disabled={accountSelectDisabled}
    >
      {partnerAccounts.map((a) => (
        <option key={a.id} value={a.id}>
          {a.name}
        </option>
      ))}
    </select>
  );

type Props = {
  prediction: SuggestedAccountMap,
  onApprove: (map: SuggestedAccountMap, accountId: number, asTarget: boolean) => void,
  approved: boolean,
  isPending: boolean,
  onUpdate: () => void,
  org: Org,
  onDecline: (SuggestedAccountMap) => void,
  partner: Partner,
};

type State = {
  selectValue: ?number,
  approved: boolean,
};

export default class PredictionRow extends React.Component<Props, State> {
  state = {
    selectValue: null,
    approved: false,
  };

  componentDidMount() {
    const { prediction } = this.props;
    const { partnerAccounts } = prediction;
    this.setState({ selectValue: partnerAccounts[0].id });
  }

  onApprove = () => {
    const { onApprove: onApproveCb, prediction } = this.props;
    const { selectValue } = this.state;
    if (!selectValue) {
      return;
    }
    onApproveCb(prediction, selectValue, false).then(() =>
      this.setState({
        approved: true,
      })
    );
  };

  onDecline = () => {
    const { onDecline, prediction, onUpdate } = this.props;
    // For now this also calls on update, but a specific handler would be better - however, all upstream need to support
    onUpdate();
    if (onDecline) {
      onDecline(prediction);
    }
  };

  onSelect = (event) => {
    this.setState({ selectValue: event.target.value });
  };

  BaseButton = ({ className, ...restProps }) => {
    const { approved } = this.state;
    return (
      <button
        className={`btn fs-13 btn-xs py-1 ${className || ''}`}
        type="button"
        disabled={approved}
        onClick={this.onApprove}
        {...restProps}
      />
    );
  };

  render() {
    const { approved, isDeclineModalOpen } = this.state;
    const { prediction, isPending, onUpdate, org, partner } = this.props;
    const {
      account,
      partnerOrg: partnerOrgFromPred,
      partnerAccounts,
      partnerApproved,
      accountOwners,
    } = prediction;
    const partnerOrgFromProps = partner ? partner.partnerOrg : null;
    const accountSelectDisabled = approved || isPending;
    // Prefer partner org from props, it is considered most complete, but not availabe for all.
    const partnerOrg = partnerOrgFromProps || partnerOrgFromPred;
    const singlePartnerAccount = partnerApproved || isPending;
    return (
      <tr className="PredictionRow">
        <td>
          <div style={{ marginBottom: '5px' }}>
            <LogoAvatar org={org} />{' '}
            <strong style={{ paddingLeft: singlePartnerAccount ? '0px' : '8px' }}>
              {account.name}
            </strong>{' '}
            is
          </div>
          <LogoAvatar org={partnerOrg} partner />
          <PartnerAccountName
            single={singlePartnerAccount}
            partnerAccounts={partnerAccounts}
            onSelect={this.onSelect}
            accountSelectDisabled={accountSelectDisabled}
          />
        </td>
        <td className="PredictionRow__approveCol" style={{ width: '92px' }}>
          {isPending ? (
            <></>
          ) : (
            <this.BaseButton className="PredictionRow__btn-approve btn-outline-success">
              {approved ? 'Approved!' : 'Approve'}
            </this.BaseButton>
          )}
          {partnerApproved && (
            <>
              {' '}
              <DeclineAccountMapping
                accountPartnerId={prediction.id}
                isOpen={isDeclineModalOpen}
                toggle={this.toggleDeclineModal}
                account={account}
                partner={partnerOrg}
                partnerAccount={partnerAccounts[0]}
                onUpdate={onUpdate}
                onDecline={this.onDecline}
              />
            </>
          )}
        </td>
        <td
          style={{
            textAlign: 'center',
            width: '280px',
          }}
        >
          <ul className="list-unstyled">
            <li>
              <StatusWithVisibility
                orgVisibilitySettings={org && org.visibilitySettings}
                account={account}
                partner={partner}
              />
            </li>
            {accountOwners &&
              accountOwners.map((ao) => (
                <li key={ao.id}>
                  {ao.fullName}{' '}
                  <sup className="text-muted">
                    <InfoTip targetId={`accountowner-visbility-tip-${ao.id}`}>
                      Visit Team to change user visibility.
                    </InfoTip>
                  </sup>
                </li>
              ))}
          </ul>
        </td>
      </tr>
    );
  }
}
