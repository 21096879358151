// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import UpgradeButton from './UpgradeButton';

type Props = {
  loggedInOrguser: OrgUser,
};

const UpgradeBanner = ({ loggedInOrguser: { capabilities } }: Props) =>
  !capabilities.canAccessSeatedExperience && (
    <div className="px-3 py-2 bg-light-orange rounded d-flex flex-row align-items-center gap-15">
      <FontAwesomeIcon icon="stars" className="orange" size="2x" />
      <div className="flex-fill d-flex flex-column">
        <span className="large-text bold gray-700">
          Get Crossbeam for Sales to unlock Deal Navigator, Full Messaging, Lists and more.
        </span>
        <span className="normal-text semi-bold gray-600">
          Discover exactly where partners can help you and collaborate to close more deals faster.
        </span>
      </div>
      <UpgradeButton />
    </div>
  );

export default withOrguser(UpgradeBanner);
