// @flow

import * as React from 'react';

import { Org, OrgUser } from 'data/entities';
import { OrgUserContext, UserContext } from 'contexts';
import { BentoLoaderContext } from 'contexts/BentoLoaderContext';
import setTitle from 'utils/setTitle';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import BdRequestQuestions from 'views/BdRequestQuestions';
import ConnectCrossbeam from 'views/Crossbeam/ConnectCrossbeam';
import ImportPartners from 'views/Crossbeam/ImportPartners';
import StandardPopulations from 'views/Crossbeam/StandardPopulations';

import PartnerSlackChannels from '../SlackIntegration/PartnerSlackChannels';

import SetupSlack from './SetupSlack';

const States = {
  connectCrossbeam: 'connectCrossbeam',
  standardPopulations: 'standardPopulations',
  crossbeamImport: 'crossbeamImport',
  setupSlack: 'setupSlack',
  setupRequestWorkflow: 'setupRequestWorkflow',
  slackChannels: 'slackChannels',
  finishOnboarding: 'finishOnboarding',
  complete: 'complete',
};

type State = {
  errorMsg: ?string,
  current: ?string,
  partnerSlug: ?string,
  invitesSent: boolean,
  hubspotFlow: boolean,
};

type Props = {
  history: RouterHistoryT,
  org: Org,
  orguser: OrgUser,
  showGettingStarted: boolean,
  doLogin: (doOnboarding: boolean, historyPushArgs: string, asOrguser: string) => void,
};

//   Signup flow
//   0. Connect to Crossbeam
//   1. Standard populations
//   2. Crossbeam import partners
//   3. Slack (if doing signups)

// When onboarding is done, redirect to bento

class Onboarding extends React.Component<Props, State> {
  state: State = {
    errorMsg: null,
    current: States.connectCrossbeam,
    partnerSlug: null,
    invitesSent: false,
    hubspotFlow: false,
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const hubspotFlow = query.get('workflow') === 'hubspot';
    this.setState({ hubspotFlow });
  }

  componentWillUnmount() {
    setTitle();
  }

  crossbeamConnected = () => {
    this.setState({ current: States.standardPopulations });
  };

  standardPopulationsSetup = () => {
    this.setState({ current: States.crossbeamImport });
  };

  crossbeamPartnersDone = () => {
    this.setState({ current: States.setupSlack });
  };

  setupSlackDone = () => {
    if (this.props.org.settings.conversationsOnSharedSlackChannels) {
      this.setState({ current: States.slackChannels });
    } else if (this.props.org.settings.requestsWorkflow) {
      this.setState({ current: States.setupRequestWorkflow });
    } else {
      this.setState({ current: States.finishOnboarding });
    }
  };

  slackChannelsDone = () => {
    this.setState({
      current: this.props.org.settings.requestsWorkflow
        ? States.setupRequestWorkflow
        : States.finishOnboarding,
    });
  };

  setupRequestWorkflowDone = () => {
    this.setState({ current: States.finishOnboarding });
  };

  finishedOnboarding = () => {
    this.setState({ current: States.complete });
  };

  onboardingDone = () => {
    const { history, showGettingStarted, doLogin } = this.props;
    const { partnerSlug, invitesSent } = this.state;
    if (invitesSent) {
      history.push('/network/p');
    } else if (partnerSlug) {
      // This is needed in certain cases, like where a user's role changes to BD after accepting an invite.
      doLogin().then(() => {
        history.push(`/p/${partnerSlug}`);
      });
    } else if (showGettingStarted) {
      history.push('/getting-started');
    } else {
      history.push(invitesSent ? '/network/p' : '/');
    }
  };

  render() {
    setTitle('Getting Started');
    const { org, orguser } = this.props;
    const { errorMsg, current, hubspotFlow } = this.state;
    if (!org || !orguser) {
      return <h5>Error resolving logged in user and organization.</h5>;
    }
    if (
      org.hasIntegration &&
      org.lastIntegration &&
      !org.lastIntegration.requiresOnboarding &&
      org.hasPartner
    ) {
      // All parts of onboarding already complete!
      // NOTE: We purposely don't check for slack -- if that's the only thing missing, then proceed.
      this.onboardingDone();
      return null;
    }
    if (errorMsg) {
      return <h5>{errorMsg}</h5>;
    }

    // First state should check for org details
    switch (current) {
      case States.connectCrossbeam:
        return <ConnectCrossbeam onboarding={this.crossbeamConnected} hubspotFlow={hubspotFlow} />;
      case States.standardPopulations:
        return <StandardPopulations onboarding={this.standardPopulationsSetup} />;
      case States.crossbeamImport:
        return <ImportPartners onboarding={this.crossbeamPartnersDone} />;
      case States.setupSlack:
        return <SetupSlack org={org} orguser={orguser} done={this.setupSlackDone} />;
      case States.slackChannels:
        return <PartnerSlackChannels onboarding={this.slackChannelsDone} />;
      case States.setupRequestWorkflow:
        return <BdRequestQuestions onboarding={this.setupRequestWorkflowDone} />;
      case States.finishOnboarding:
        // If not setting up slack, go to next step
        this.finishedOnboarding();
        return null;
      default:
        this.onboardingDone();
        return null;
    }
  }
}

type ContainerProps = {
  history: RouterHistoryT,
};

export default function OnboardingContainer(props: ContainerProps) {
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <BentoLoaderContext.Consumer>
          {({ showGettingStarted }) => (
            <OrgUserContext.Consumer>
              {({ orguser, org }) => (
                <Onboarding
                  {...props}
                  org={org}
                  orguser={orguser}
                  showGettingStarted={showGettingStarted}
                  user={user}
                />
              )}
            </OrgUserContext.Consumer>
          )}
        </BentoLoaderContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}
