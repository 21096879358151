// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { Org, OrgUser } from 'data/entities';
import User from 'data/entities/user';
import { OrgUserContext, UserContext } from 'contexts';
import { xbChromeExtensionURL } from 'utils/environment';

import CompanyLogo from 'components/CompanyLogo';
import PersonAvatar from 'components/PersonAvatar';

type DropdownItemProps = {
  title: string,
  description?: ?string,
  icon?: ?string,
  rawIcon?: any,
  extraIcon?: ?string,
};

const DropdownItem = ({ title, description, icon, rawIcon, extraIcon }: DropdownItemProps) => (
  <div className="header-menu-item d-flex flex-row gap-10 align-items-center my-1">
    {icon && <FontAwesomeIcon icon={icon} width={16} className="gray-600" />}
    {rawIcon && rawIcon}
    <div className="flex-fill d-flex flex-column gap-5">
      <span className="normal-text bold gray-700">{title}</span>
      {description && <span className="normal-text bold gray-500 text-nowrap">{description}</span>}
    </div>
    {extraIcon && <FontAwesomeIcon icon={extraIcon} width={16} className="gray-600" />}
  </div>
);

DropdownItem.defaultProps = {
  description: null,
  icon: null,
  rawIcon: null,
  extraIcon: null,
};

type Props = {
  user: User,
  org: Org,
  orguser: OrgUser,
};

type State = {
  dropdownOpen: boolean,
};

class UserMenuInner extends React.Component<Props, State> {
  state: State = { dropdownOpen: false };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  avatarUrl = (user: ?User, orguser: ?OrgUser): string => {
    let avatar;

    if (user && user.avatarImage) {
      avatar = user.avatarImage;
    } else if (orguser && orguser.avatarImage) {
      avatar = orguser.avatarImage;
    } else {
      avatar = '';
    }
    return avatar;
  };

  render() {
    const { orguser, org, user } = this.props;
    const { dropdownOpen } = this.state;
    const avatarImage = this.avatarUrl(user, orguser);

    return (
      <Dropdown className="pull-right" isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle className="profile-dropdown-toggle p-0" color="link">
          <PersonAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            org={org ? { name: org.name, domain: org.url, logoUrl: org.logoUrl } : undefined}
            size={35}
            avatar={avatarImage}
            noMargin
          />
        </DropdownToggle>
        <DropdownMenu className="profile-dropdown" right onClick={this.toggle}>
          <div className="d-flex flex-row gap-10 align-items-center">
            <PersonAvatar
              firstName={user.firstName}
              lastName={user.lastName}
              size={48}
              avatar={avatarImage}
              noMargin
            />
            <div className="d-flex flex-column gap-5">
              <span className="large-text bold black">{user.fullName}</span>
              <span className="normal-text bold gray-500">{user.email}</span>
            </div>
          </div>
          {!!orguser && (
            <Link
              className="btn btn-secondary py-1 px-2 text-nowrap"
              to={`/network/oid/${orguser.id}/edit-profile`}
              style={{ marginLeft: '58px' }}
            >
              View or Edit Profile
            </Link>
          )}
          <hr className="my-2" />
          {!!org && (
            <div className="d-flex flex-row gap-10 align-items-center">
              <Link to="/my-orgs">
                <DropdownItem
                  title={org.name}
                  description="Switch Organizations"
                  rawIcon={
                    <CompanyLogo
                      name={org.name}
                      logoUrl={org.logoUrl}
                      domain={org.url}
                      size={36}
                      className=""
                    />
                  }
                  extraIcon="rotate"
                />
              </Link>
            </div>
          )}
          <Link to="/settings/">
            <DropdownItem title="Settings" icon="cog" />
          </Link>
          {org.settings.requestsWorkflow &&
            org.isIntegrationSetup &&
            orguser.capabilities.canManagePartnerships && (
              <Link to="/co-selling-templates">
                <DropdownItem title="Co-Selling Templates" icon="book" />
              </Link>
            )}
          {orguser.capabilities.canAccessSeatedExperience && (
            <>
              <Link to="/lists">
                <DropdownItem title="My Saved Lists" icon="list" />
              </Link>
              {!!orguser && orguser.canManage && (
                <>
                  <Link to="/admin/users">
                    <DropdownItem title="Team" icon="user-friends" />
                  </Link>
                </>
              )}
            </>
          )}
          {!!orguser &&
            !orguser.hasChromeExtension &&
            orguser.canManage &&
            orguser.capabilities.canAccessSeatedExperience && (
              <>
                <hr className="my-2" />
                <a href={xbChromeExtensionURL} target="_blank" rel="noopener noreferrer">
                  <DropdownItem title="Get Chrome Extension" icon="arrow-down-from-line" />
                </a>
              </>
            )}
          <hr className="my-2" />
          <Link to="/logout">
            <DropdownItem title="Logout" icon="sign-out-alt" />
          </Link>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const UserMenu = () => (
  <UserContext.Consumer>
    {({ user }) => (
      <OrgUserContext.Consumer>
        {({ orguser, org }) =>
          user ? <UserMenuInner user={user} org={org} orguser={orguser} /> : null
        }
      </OrgUserContext.Consumer>
    )}
  </UserContext.Consumer>
);

export default UserMenu;
