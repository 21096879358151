// @flow

import React from 'react';

import LogoCrossbeam from 'components/LogoCrossbeam';

const Footer = () => (
  <div className="d-flex flex-column align-items-center gap-15">
    <LogoCrossbeam style={{ width: 120 }} />
    <span className="large-text semi-bold gray-400">Your Ecosystem Revenue Engine</span>
  </div>
);

export default Footer;
