// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { invert } from 'lodash';

import Pill from 'components/Pill';

// Raw version of status comes over from backend.
export const wonRaw = 'CLOSED:WON';
export const activeOppRaw = 'PROSPECT';
export const coldRaw = 'COLD';

export const rawStatuses = [coldRaw, activeOppRaw, wonRaw];
export const partnerRawStatuses = [activeOppRaw, wonRaw];
// String constants of statuses

const mkWon = (plural: boolean = false) => `Customer${plural ? 's' : ''}`;
const mkActiveOpp = (plural: boolean = false) => `Open Opportunit${plural ? 'ies' : 'y'}`;
const mkCold = (plural: boolean = false) => `Prospect${plural ? 's' : ''}`;

export const won = mkWon();
export const activeOpp = mkActiveOpp();
export const cold = mkCold();

// From Raw value to url value
export const rawUrlValueMap = {
  [wonRaw]: 'customer',
  [activeOppRaw]: 'activeOpp',
  [coldRaw]: 'coldProspect',
};

// From URL value to raw value
export const urlRawValueMap = invert(rawUrlValueMap);

export const PROSPECT = [activeOppRaw, coldRaw];
export const CUSTOMER = [wonRaw];

export type Status = {
  key: string,
  label: string,
  color: string,
  colorRaw: string,
  icon: string,
};

export const statusDefinitions: { [key: string]: Status } = {
  [wonRaw]: {
    key: wonRaw,
    label: won,
    color: 'yellow',
    colorRaw: '#f6d612',
    icon: 'star',
  },
  [activeOppRaw]: {
    key: activeOppRaw,
    label: activeOpp,
    color: 'green',
    colorRaw: '#29A244',
    icon: 'wave-pulse',
  },
  [coldRaw]: {
    key: coldRaw,
    label: cold,
    color: 'blue',
    colorRaw: '#4D85F0',
    icon: 'snowflake',
  },
};

export const getStatusDefinition = (key: $Keys<typeof statusDefinitions>): Status =>
  statusDefinitions[key];

function AccountStatus({
  text,
  name,
  pastCustomer,
}: {
  text: string,
  name: string,
  pastCustomer: boolean,
}) {
  const status = getStatusDefinition(name);
  return (
    <Pill
      extra={Boolean(pastCustomer) && '(Past Customer)'}
      icon={<FontAwesomeIcon icon={status.icon} width="14" height="14" />}
      color={status.color}
      label={text}
      className="fs-mask"
    />
  );
}

export function getStatusStr(
  statusRaw: string,
  pastCustomer: boolean = false,
  plural: boolean = false
): string {
  let status = '';
  switch (statusRaw) {
    case wonRaw:
      // This does not get combined with "Past Customer"
      return mkWon(plural);
    case activeOppRaw:
      status = mkActiveOpp(plural);
      break;
    case coldRaw:
      status = mkCold(plural);
      break;
    default:
      status = '';
  }
  return pastCustomer ? `${status} (Past Customer)` : status;
}

export function getStatusComponent(
  statusRaw: string,
  pastCustomer?: boolean = false,
  plural?: boolean = false
) {
  const status = getStatusStr(statusRaw, false, plural); // Leave off pastCustomer here
  const accountStatus = (name: string) => (
    <AccountStatus text={status} name={name} pastCustomer={pastCustomer} />
  );
  switch (statusRaw) {
    case wonRaw:
      return <AccountStatus text={status} name={wonRaw} pastCustomer={false} />;
    case activeOppRaw:
      return accountStatus(activeOppRaw);
    case coldRaw:
      return accountStatus(coldRaw);
    default:
      return <span>-</span>;
  }
}
