import emojiList from 'data/entities/emojiList.json';

export default function filterEmoji(searchText, maxResults = 5) {
  return emojiList
    .filter(
      (emoji) =>
        emoji.title.toLowerCase().includes(searchText.toLowerCase()) ||
        emoji.keywords.includes(searchText)
    )
    .slice(0, maxResults);
}
