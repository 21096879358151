// @flow

/*
Component with OrgUser typeahead that can be used to display a user and then edit them.
 */

import * as React from 'react';
import { Link } from 'react-router-dom';

import { OrgUser, PartnerOrgUser } from 'data/entities';

import DisplayEdit from 'components/DisplayEdit';
import InfoTip from 'components/InfoTip';
import PersonAvatar from 'components/PersonAvatar';
import UserSearch from 'components/UserSearch';

const DefaultSpan = ({ children }) => <span>{children}</span>;

type Props = {
  existingUser: PartnerOrgUser | null,
  onSave: (newOrguser: OrgUser) => Promise<any>,
  lockMessage?: string,
  placeholder?: string,
  emptyLabel?: string,
  style?: any,
  userRole?: ?string,
  canManagePartnerships?: boolean,
  emptyExisting?: any,
  allowNull?: boolean,
  bsSize?: string,
  displayTextComponent?: React.ComponentType,
  defaultEditing?: boolean,
};

function UserEditSearch({
  existingUser,
  onSave: onSaveProp,
  emptyExisting,
  userRole,
  canManagePartnerships,
  allowNull,
  lockMessage,
  displayTextComponent,
  defaultEditing,
  ...rest
}: Props) {
  const displayName = existingUser ? `${existingUser.fullName}` : emptyExisting;

  const onSave = (orgUser: PartnerOrgUser | null): Promise<any> => {
    if (!orgUser && !allowNull) {
      return Promise.resolve();
    }
    if (existingUser && existingUser.id === orgUser && orgUser?.id) {
      // There was no update, don't call the onSave prop
      return Promise.resolve();
    }
    return onSaveProp(orgUser);
  };

  if (lockMessage) {
    return (
      <div>
        {displayName}{' '}
        <InfoTip targetId="userEdit" icon="lock" className="fs-11 text-muted">
          {lockMessage}
        </InfoTip>
      </div>
    );
  }

  const DisplayTextComponent = displayTextComponent;

  return (
    <DisplayEdit
      searchComponent={UserSearch}
      existingValue={existingUser}
      onSave={onSave}
      extraSearchProps={{ userRole, canManagePartnerships }}
      focusOnEdit
      defaultEditing={defaultEditing}
      saveOnSelect
      {...rest}
    >
      <DisplayTextComponent>
        {existingUser ? (
          <>
            <PersonAvatar
              lastName={existingUser.lastName}
              firstName={existingUser.firstName}
              avatar={existingUser.avatarImage}
              size={20}
            />
            <Link to={`/network/oid/${existingUser.id}`} className="ml-2">
              {displayName}
            </Link>
          </>
        ) : (
          displayName
        )}
      </DisplayTextComponent>
    </DisplayEdit>
  );
}

UserEditSearch.defaultProps = {
  placeholder: 'Search for user...',
  emptyLabel: 'No matching users found',
  style: {},
  userRole: null,
  canManagePartnerships: undefined,
  emptyExisting: '',
  lockMessage: '',
  allowNull: false,
  bsSize: 'small',
  displayTextComponent: DefaultSpan,
  defaultEditing: false,
};

export default UserEditSearch;
