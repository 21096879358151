// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BaseContainer from 'layouts/BaseLayout/BaseContainer';
import PrimaryLayoutFooter from 'layouts/PrimaryLayout/PrimaryLayoutFooter';
import PrimaryLayoutHeader from 'layouts/PrimaryLayout/PrimaryLayoutHeader';
import { UpgradeButton } from 'components/SeatlessExperience';

const SeatlessBlacklist = () => (
  <BaseContainer
    className="Platform"
    header={<PrimaryLayoutHeader />}
    footer={<PrimaryLayoutFooter />}
  >
    <div className="container p-5">
      <div className="d-flex flex-column gap-15 align-items-center text-center p-5">
        <FontAwesomeIcon icon="lock" className="orange" size="2x" />
        <div className="d-flex flex-column align-items-celnter gap-5" style={{ maxWidth: 400 }}>
          <h5 className="m-0 bold-text black">
            Get Crossbeam Crossbeam for Sales to unlock Deal Navigator, Full Messaging, Lists and
            more.
          </h5>
          <span className="normal-text semi-bold gray-500">
            Discover exactly where partners can help you and collaborate to close more deals faster.
          </span>
        </div>
        <UpgradeButton />
      </div>
    </div>
  </BaseContainer>
);

export default SeatlessBlacklist;
