// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash';

import { BdRequest } from 'data/entities';

type Props = {
  request: BdRequest,
};

const SlackThreadBanner = ({ request }: Props) => {
  const creatingRequest = isNil(request.slackThreadUrl);
  const title = creatingRequest
    ? 'This request will be managed on Slack'
    : 'This request is managed on Slack';

  let content;
  let linkOut;
  if (request?.slackChannel?.isDeleted) {
    content = (
      <span className="normal-text semi-bold gray-600">
        The Shared Slack channel associated with this request has been deleted.
      </span>
    );
  } else if (request.slackThreadUrl) {
    content = (
      <span className="normal-text semi-bold gray-600 mr-2">
        View messages and respond in
        <a href={request.slackThreadUrl} target="_blank" className="bold ml-1 blue">
          {request?.slackChannel?.name
            ? `#${request?.slackChannel?.name}`
            : "this request's Slack thread"}
        </a>
        .
      </span>
    );
    linkOut = (
      <a
        href={request.slackThreadUrl}
        target="_blank"
        className="btn btn-primary text-nowrap"
        style={{ borderRadius: 8 }}
      >
        View on Slack
      </a>
    );
  }

  return (
    <div className="py-3 rounded px-3 bg-light-orange d-flex flex-row align-items-center gap-10">
      <FontAwesomeIcon icon="info-circle" className="orange" size="2x" />
      <div className="flex-fill d-flex flex-column gap-5">
        <span className="large-text bold gray-700">{title}</span>
        {content}
      </div>
      {linkOut}
    </div>
  );
};

export default SlackThreadBanner;
