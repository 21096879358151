// @flow

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { findIndex, sortBy } from 'lodash';
import moment from 'moment';

import { BdRequest, RequestComment } from 'data/entities';
import { SharedConversation } from 'data/entities/bdrequest';
import { BdRequestCommentEvent } from 'data/entities/notificationEvents';
import { fetchRequestComments } from 'data/repositories/bdrequests';
import markNotificationSeen from 'data/repositories/notify';

import AddBdRequestComment from '../AddBdRequestComment';
import Comment, { Mention, Section } from '../Comment';

import CommentsLoading from './CommentsLoading';

type Props = {
  request: BdRequest,
  onUpdate: () => void,
  sharedConversation?: SharedConversation | null,
  mini?: boolean,
};

const Comments = ({ request, sharedConversation, onUpdate, mini }: Props) => {
  const { hash } = useLocation();
  const ref = useRef();

  const {
    id: requestId,
    account,
    createdAt,
    isShared,
    partner,
    slackThreadUrl,
    isOffsite,
  } = request;

  const isSlackThread = useMemo(() => slackThreadUrl, [slackThreadUrl]);

  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState<RequestComment[] | null>(null);

  const fetchComments = useCallback(() => {
    setLoadingComments(true);
    fetchRequestComments(requestId)
      .then((c) => {
        setLoadingComments(false);
        setComments(sortBy(c.results, 'createdAt'));
      })
      .catch((error) => {
        setLoadingComments(false);
      });
  }, [requestId]);

  // Listen for notifications.
  useEffect(() => {
    const onNewCommentAlert = (e: any) => {
      const { detail } = e;
      // Comes from dispatchBdRequestCommentEvent
      const { bdrequestId, markAsRead, notificationId } = detail || {};
      if (bdrequestId === requestId) {
        fetchComments();
        markAsRead();
        markNotificationSeen(notificationId);
      }
    };
    document.addEventListener(BdRequestCommentEvent, onNewCommentAlert);

    return () => document.removeEventListener(BdRequestCommentEvent, onNewCommentAlert);
  }, [fetchComments, requestId]);

  // Fetch comments initially.
  useEffect(() => fetchComments(), [request, fetchComments]);

  // Scroll to bottom.
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [comments]);

  return (
    <>
      <div
        ref={ref}
        className="flex-fill BdRequestMessages d-grid align-items-end"
        style={{ display: 'grid', gridTemplateRows: '1fr' }}
      >
        {!loadingComments ? (
          <>
            {/* Initial conversation description */}
            <Section text="Conversation Start" />
            {account && (
              <Comment
                request={request}
                text={
                  <>
                    New request about <Mention to={`/a/${account.slug}`} text={account.name} />
                    {isShared && partner ? (
                      <>
                        &nbsp;with&nbsp;
                        <Mention to={`/p/${partner.slug}`} text={partner.name} />.
                      </>
                    ) : (
                      '.'
                    )}
                  </>
                }
                isUpdate
                createdAt={createdAt}
                controls={false}
              />
            )}

            {/* Comments */}
            {comments?.map((comment, index) => {
              const commentDay = moment(comment.createdAt).startOf('day');

              // Show section if the day of the comment is appearing for the first time.
              const showSection =
                index ===
                findIndex(comments, (c) => moment(c.createdAt).startOf('day').isSame(commentDay));

              const isToday = commentDay.isSame(moment(new Date()).startOf('day'));

              // Skip all but the first shared comment in an slack/offsite thread.
              if (comment.isShared && (isSlackThread || isOffsite) && index !== 0) {
                return null;
              }

              return (
                <React.Fragment key={comment.id}>
                  {showSection && (
                    <Section text={isToday ? 'Today' : commentDay.format('dddd, MMMM Do YYYY')} />
                  )}
                  <Comment
                    request={request}
                    isUpdate={comment.isUpdate}
                    id={comment.id}
                    isInternal={comment.isInternal}
                    shortInternalIndicator={mini || (index !== 0 && comments[index - 1].isInternal)}
                    orguser={comment.orguser}
                    createdAt={comment.createdAt}
                    text={comment.text}
                    controls
                    onDelete={(id) => setComments((cs) => cs && cs.filter((c) => c.id !== id))}
                    isHiglighted={comment.id === hash.replace('#', '')}
                  />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <CommentsLoading />
        )}
      </div>
      <AddBdRequestComment
        mini={mini}
        bdRequest={request}
        sharedConversation={sharedConversation}
        onUpdate={onUpdate}
        onNewComment={(_, comment, refreshRequest) => {
          if (refreshRequest) {
            onUpdate();
          } else {
            // Refresh request on new mention.
            if (comment.text.includes('@[')) {
              onUpdate();
            }
            setComments((cs) => cs && cs.concat([comment]));
          }
        }}
      />
    </>
  );
};

Comments.defaultProps = {
  sharedConversation: null,
  mini: false,
};

export default Comments;
