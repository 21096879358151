// @flow

import * as React from 'react';

import Logo from 'components/Logo';

import authFetch from '../authFetch/authFetch';
import { ErrorResponse } from '../authFetch/entities';
import { setAuthToken } from '../authFetch/utils';

import Footer from './Footer';

type Props = {
  doLogin: Function,
  match: {
    params: {
      token: string,
    },
  },
};

type State = {
  doLogin: Function,
  verification_token: string,
  error_message: string,
  VerifyToken: () => void,
};

export default class EmailVerification extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      verification_token: this.props.match.params.token,
      error_message: '',
    };
    this.doLogin = props.doLogin;
    this.VerifyToken = this.VerifyToken.bind(this);
    this.VerifyToken();
  }

  VerifyToken() {
    authFetch(`auth/email/verify/${this.state.verification_token}/`, { method: 'PATCH' })
      .then(({ token }) => {
        setAuthToken(token);
        this.doLogin(true);
        return { token };
      })
      .catch(({ json }: ErrorResponse) => {
        this.setState({
          error_message: 'Email verification session is not valid',
        });
      });
  }

  render() {
    return (
      <div className="text-center" style={{ marginTop: '10em' }}>
        <Logo height={30} className="my-5 mx-auto d-block" />
        <br />
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4">
            {this.state.error_message ? (
              <div className="alert alert-danger font-weight-bolder" role="alert">
                {this.state.error_message}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-md-4" />
        </div>
        <hr />
        <Footer />
      </div>
    );
  }
}
