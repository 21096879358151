// @flow

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, trim } from 'lodash';

import { List, Org, OrgUser } from 'data/entities';
import { fetchLists } from 'data/repositories/lists';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

import { PremiumBadge, UpgradeBanner, UpgradeOverlay } from 'components/SeatlessExperience';
import FinishSetupOverlay from 'components/SeatlessExperience/FinishSetupOverlay';
import ViewerOverlay from 'components/SeatlessExperience/ViewerOverlay';

import CreateListModal from './CreateListModal';
import ListCard from './ListCard';
import Loading from './Loading';

const STARRED = 'starred';
const MY_LISTS = 'own';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const Lists = ({
  loggedInOrg: { isIntegrationSetup },
  loggedInOrguser: { capabilities },
}: Props) => {
  const [{ search, type }, setParams] = useParams({});

  // Keep inner search state, so we can reset it if the search parameter changes.
  const searchRef = useRef(null);
  useEffect(() => {
    if (searchRef.current) searchRef.current.value = search || '';
  }, [search]);

  const onSearch = debounce((value: string) => {
    const searchValue = trim(value, ',&?');
    setParams({ search: searchValue }, true);
  }, 500);

  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLists = useCallback(() => {
    setLoading(true);
    fetchLists({
      search,
      isFavorite: type === STARRED ? true : undefined,
      isOwner: type === MY_LISTS ? true : undefined,
    })
      .then(({ results }) => {
        setLoading(false);
        setLists(results);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [search, type]);

  useEffect(() => {
    getLists();
  }, [getLists]);

  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((o) => !o);

  let title;
  switch (type) {
    case STARRED:
      title = 'Starred Lists';
      break;
    case MY_LISTS:
      title = 'My Lists';
      break;
    default:
      title = 'All Lists';
      break;
  }

  const hasAccess = useMemo(
    () => capabilities.canAccessSeatedExperience && isIntegrationSetup && !capabilities.viewOnly,
    [isIntegrationSetup, capabilities]
  );

  return (
    <div className="w-100 d-flex flex-column gap-30">
      <div
        className="w-100 sticky overflow-hidden bg-background-body d-flex flex-md-row flex-column justify-content-center gap-10 page-top-padding"
        style={{ top: '0px' }}
      >
        <div className="d-flex flex-row flex-fill justify-content-center gap-10">
          <div className="d-flex flex-row align-items-center gap-10 flex-fill">
            <h3 className="m-0">{title}</h3>
            <PremiumBadge />
          </div>
          {hasAccess && (
            <Input
              innerRef={searchRef}
              style={{ maxWidth: '350px' }}
              type="text"
              name="search"
              placeholder="🔎  Search lists by name"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          <CreateListModal
            open={isOpen}
            toggle={toggle}
            onUpdate={() => {
              setParams({ type: MY_LISTS });
              getLists();
            }}
          />
          {hasAccess && (
            <button type="button" onClick={toggle} className="btn btn-primary text-center">
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Create new list
            </button>
          )}
        </div>
      </div>
      <UpgradeBanner />
      {!hasAccess ? (
        <FinishSetupOverlay title="Add required information to access Lists">
          <UpgradeOverlay
            title="Start collaborating with your sales team"
            explanation="You have a free seat account. Upgrade to start creating and sharing lists."
          >
            <ViewerOverlay
              title="Lists are not included in your current plan"
              explanation="Upgrade to Co-Seller seat to access Lists."
            >
              <ListCard
                list={List.fromApi({
                  name: 'Useful leads',
                  description: 'Companies that are interested into buying our software.',
                  created_by: {
                    first_name: 'Adam',
                    last_name: 'Michalski',
                  },
                  assigned_to: {
                    first_name: 'Adam',
                    last_name: 'Michalski',
                  },
                  created_at: '2022-05-18',
                  is_favorite: true,
                })}
                onUpdate={() => {}}
              />
            </ViewerOverlay>
          </UpgradeOverlay>
        </FinishSetupOverlay>
      ) : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              {!lists.length ? (
                <div className="text-center">
                  <h6>No lists found.</h6>
                  <p className="mb-3 normal-text semi-bold gray-500">
                    Try creating one or adjusting filters.
                  </p>
                </div>
              ) : (
                <div className="d-flex flex-column gap-20">
                  {lists.map((list) => (
                    <ListCard
                      key={list.id}
                      list={list}
                      onUpdate={(newList) =>
                        setLists((ls) => ls.map((l) => (l.id === newList.id ? newList : l)))
                      }
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withOrguser(Lists);
