// @flow

import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { groupBy, isEqual, keyBy } from 'lodash';

import { Partner, PartnerOrg, SuggestedAccountMap } from 'data/entities';
import {
  approveAccountMapping,
  fetchPartnerApprovedMapping,
} from 'data/repositories/accountMapping';
import { FadeInTransition } from 'utils/animate';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';

import AccountMappingTable from 'views/AccountMapping/AccountMappingTable';
import ConfirmApproveAll from 'views/AccountMapping/components/ConfirmApproveAll';

import AllRequestsNullState from './AllRequestsNullState';

type PartnerApprovedResults = {
  results: SuggestedAccountMap[],
  partnerOrg: PartnerOrg,
};

function buildPartnerApproved(
  apiResults: PaginatedResponse<SuggestedAccountMap>,
  partners: Partner[]
): PartnerApprovedResults[] {
  const resultsByPartner = groupBy(apiResults, (o) => o.partnerOrg.id);
  const partnersById = partners ? keyBy(partners, ({ partnerOrg: { id } }) => id) : {};
  return Object.keys(resultsByPartner).reduce((prev, partner) => {
    prev[partner] = {
      results: resultsByPartner[partner],
      partnerOrg: resultsByPartner[partner][0].partnerOrg,
      partner: partnersById[partner],
    };
    return prev;
  }, {});
}

type Props = {
  partnerOrg: PartnerOrg,
  results: SuggestedAccountMap[],
  nextPartnerApproved: () => Promise,
  onApprove: () => Promise,
  onUpdate: () => () => void,
  next: ?string,
  onDecline: () => void,
};

function PartnerMappingRequestsComponent(props: Props) {
  const {
    results,
    partnerOrg,
    nextPartnerApproved,
    onApprove,
    onUpdate,
    next,
    onDecline,
    count,
    partner,
  } = props;
  return (
    <div className="PartnerMappingRequests card card-condensed">
      <div className="card-header px-4" style={{ backgroundColor: '#feffbd' }}>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="semi-bold mb-0" style={{ color: '#70705c' }}>
              Approval Request Inbox
            </h5>
            <div className="mb-3" style={{ color: '#8c8c73' }}>
              Accounts <strong>{partnerOrg ? partnerOrg.name : 'Partners'}</strong> Approved to Map
            </div>
          </div>
          {partnerOrg && <ConfirmApproveAll count={count} partnerOrg={partnerOrg} />}
        </div>
      </div>
      <AccountMappingTable
        predictions={results}
        hasNext={Boolean(next) && Boolean(nextPartnerApproved)}
        nextAction={nextPartnerApproved}
        onApprove={onApprove}
        onUpdate={onUpdate}
        onDecline={onDecline}
        isRequests
        partner={partner}
      />
    </div>
  );
}

type ContainerProps = {
  partnerSlug: string,
  accountSlug: string,
  allRequests?: boolean,
  partners: Partner[],
  onApprove: (partnerId: string) => void,
  onDecline: (partnerId: string) => void,
  partner: Partner,
};

type ContainerState = {
  partnerApproved: PartnerApprovedResults[],
  // apiResponse is a source of truth as we paginate and rebuild data structures
  apiResponse: ?PaginatedResponse<SuggestedAccountMap>,
  loading: boolean,
  count: ?number,
};

class PartnerMappingRequests extends React.Component<ContainerProps, ContainerState> {
  state: State = {
    partnerApproved: null,
    loading: true,
    apiResponse: null,
    count: null,
  };

  componentDidMount() {
    this.loadPartnerApproved();
  }

  componentDidUpdate(prevProps: ContainerProps) {
    const { partnerSlug, accountSlug, partner } = this.props;
    const filteringUpdated =
      prevProps.partnerSlug !== partnerSlug || prevProps.accountSlug !== accountSlug;
    const visibilityUpdated =
      prevProps.partner && partner && !isEqual(prevProps.partner.settings, partner.settings);
    if (filteringUpdated || visibilityUpdated) {
      this.loadPartnerApproved();
    }
  }

  onApprove = (
    { account: { slug: acctSlug }, partnerOrg: { id: partnerId } }: SuggestedAccountMap,
    partnerAccountId: number,
    asTarget: boolean = false
  ) =>
    approveAccountMapping(acctSlug, partnerAccountId, asTarget).then(() => {
      // Filter from results
      this.setState(
        ({
          count,
          partnerApproved: {
            [partnerId]: { results, ...partnerData },
            ...paRes
          },
          ...res
        }) => ({
          ...res,
          count: count - 1,
          partnerApproved: {
            ...paRes,
            [partnerId]: {
              ...partnerData,
              results: results.filter((r) => r.account.slug !== acctSlug),
            },
          },
        })
      );
      const { onApprove } = this.props;
      if (onApprove) {
        onApprove(partnerId);
      }
    });

  onDecline = ({ partnerOrg: { id: partnerId } }: SuggestedAccountMap) => {
    const { onDecline } = this.props;
    this.setState(({ count }) => ({
      count: count - 1,
    }));
    if (onDecline) {
      onDecline(partnerId);
    }
  };

  nextPartnerApproved = (): Promise => {
    const { apiResponse } = this.state;
    const next = apiResponse ? apiResponse.next : null;
    if (!next) {
      return Promise.resolve(null);
    }
    return this.loadPartnerApproved(next);
  };

  onUpdate = () =>
    // FIXME This is trash. We should be handling the specific action - like deleting.
    this.loadPartnerApproved();

  loadPartnerApproved(nextUrl: ?string) {
    const { partnerSlug, accountSlug, allRequests, partners } = this.props;
    if (!allRequests && !partnerSlug) {
      return Promise.resolve(null);
    }
    return fetchPartnerApprovedMapping(partnerSlug, accountSlug, nextUrl).then((data) => {
      // Transform the results into Paginated SuggestAccountMap[]
      const newResults = data.results.map(SuggestedAccountMap.fromPartnerApprovedMap);
      const { apiResponse: prevApiResponse } = this.state;
      const prevResults = prevApiResponse && prevApiResponse.results;
      // If this call was to fetch next result set, then we append.
      const apiResults = nextUrl && prevResults ? prevResults.concat(newResults) : newResults;
      const apiResponse = {
        next: data.next,
        previous: data.previous,
        results: apiResults,
        count: data.count,
      };
      const partnerApproved = buildPartnerApproved(apiResults, partners);
      this.setState({ partnerApproved, apiResponse, loading: false, count: data.count });
      return partnerApproved;
    });
  }

  render() {
    const { allRequests, partners } = this.props;
    const { partnerApproved, loading, apiResponse, count } = this.state;
    const hasResults = Boolean(partnerApproved && Object.keys(partnerApproved).length);
    return (
      <TransitionGroup exit={false}>
        {loading && (
          <FadeInTransition>
            <div />
          </FadeInTransition>
        )}
        {!loading && !hasResults && !allRequests && (
          <FadeInTransition>
            <div />
          </FadeInTransition>
        )}
        {!loading && !hasResults && allRequests && (
          <FadeInTransition>
            <AllRequestsNullState partners={partners} />
          </FadeInTransition>
        )}
        {!loading && hasResults && (
          <FadeInTransition>
            <div>
              {Object.values(partnerApproved).map(({ results, partnerOrg, partner }) => (
                <div key={partnerOrg.id}>
                  {allRequests ? <h4>{partnerOrg.name}</h4> : null}
                  <PartnerMappingRequestsComponent
                    partnerOrg={partnerOrg}
                    results={results}
                    count={count}
                    next={apiResponse.next}
                    nextPartnerApproved={this.nextPartnerApproved}
                    onApprove={this.onApprove}
                    onUpdate={this.onUpdate}
                    onDecline={this.onDecline}
                    partner={partner}
                  />
                </div>
              ))}
            </div>
          </FadeInTransition>
        )}
      </TransitionGroup>
    );
  }
}

PartnerMappingRequests.defaultProps = {
  allRequests: false,
};

export default PartnerMappingRequests;
