// @flow

import React from 'react';

import { getCrossbeamLoginUrl } from 'utils/urlUtils';

type Props = {
  next?: string | null,
};

const CrossbeamButton = ({ next }: Props) => (
  <div className="col-md-10" style={{ width: 400 }}>
    <a
      href={getCrossbeamLoginUrl(next)}
      className="btn btn-primary text-white bg-crossbeam-blue border-0 py-2 w-100"
    >
      <span className="large-text bold text-white">Log In</span>
    </a>
  </div>
);

CrossbeamButton.defaultProps = {
  next: null,
};

export default CrossbeamButton;
