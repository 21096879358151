import * as React from 'react';

const ActIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="32" height="32" viewBox="0 0 32 32">
    <g fill="#10D83C" clipPath="url(#a)">
      <path d="m22.065 10.475-6 7a1.272 1.272 0 0 1-1.081.469h-.057c-.396 0-.78-.158-1.06-.44l-3-3a1.5 1.5 0 1 1 2.12-2.121l1.857 1.856 4.945-5.772a1.502 1.502 0 0 1 2.115-.162c.63.595.698 1.539.16 2.17Z" />
      <path
        d="M28.277 0h-24c-2.203 0-4 1.797-4 3.944v17.943c0 2.204 1.797 3.944 4 3.944h6v5.194c0 .61.704.966 1.195.606l7.807-5.856h9c2.203 0 4-1.797 4-3.944V3.944C32.277 1.797 30.534 0 28.277 0Zm-6.212 10.475-6 7a1.272 1.272 0 0 1-1.081.469h-.057c-.396 0-.779-.158-1.06-.44l-3-3a1.5 1.5 0 1 1 2.121-2.12l1.856 1.855 4.945-5.772a1.502 1.502 0 0 1 2.115-.162c.63.595.698 1.539.16 2.17Z"
        opacity=".3"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.333 0h32v32h-32z" />
      </clipPath>
    </defs>
  </svg>
);

export default ActIcon;
