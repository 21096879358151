/* eslint-disable import/prefer-default-export */
// @flow

import * as React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

export function FadeInTransition({ children, ...props }: any) {
  return (
    <CSSTransition timeout={300} classNames="fade" mountOnEnter unmountOnExit {...props}>
      {children}
    </CSSTransition>
  );
}
