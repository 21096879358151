// @flow

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Org, OrgUser } from 'data/entities';
import User from 'data/entities/user';
import { OrgUserContext, UserContext } from 'contexts';

import CompleteSignupLayout from 'layouts/CompleteSignupLayout';

import OrgSetupDetails from './OrgSetupDetails';
import SignupForm from './SignupForm';

type Props = {
  user: User,
  org: Org,
  orguser: OrgUser,
  updateOrg: (org: Org) => void,
};

function existingOrgMustCompleteSignup(orguser: OrgUser, org: Org) {
  // A shell of an org exists, but it must complete signup
  const incompleteOrgInfo = !(org && org.name && org.adminId);
  return Boolean(
    incompleteOrgInfo &&
      orguser.capabilities.canManagePartnerships &&
      orguser.capabilities.canAccessSeatedExperience
  );
}

export function mustCompleteSignup(orguser, org, user) {
  return existingOrgMustCompleteSignup(orguser, org) || !user.isOrgMember;
}

function CompleteSignup(props: Props) {
  const { org, orguser, updateOrg, user } = props;

  return (
    <CompleteSignupLayout firstName={user.firstName}>
      <Switch>
        <Route
          path="/complete-signup"
          exact
          render={() => (
            <SignupForm
              updateOrg={updateOrg}
              existingOrgMustCompleteSignup={existingOrgMustCompleteSignup}
              orguser={orguser}
              org={org}
            />
          )}
        />
        <Route
          path="/complete-signup/details"
          exact
          render={() => <OrgSetupDetails org={org} orguser={orguser} />}
        />
      </Switch>
    </CompleteSignupLayout>
  );
}

export default function CompleteSignupContainer({ updateOrg }) {
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <OrgUserContext.Consumer>
          {({ orguser, org }) => (
            <CompleteSignup org={org} orguser={orguser} updateOrg={updateOrg} user={user} />
          )}
        </OrgUserContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}
