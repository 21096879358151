// @flow

import React from 'react';

import OrgUser from 'data/entities/orguser';

import NewBdRequestButton from 'components/NewBdRequestButton';
import { UpgradeButton } from 'components/SeatlessExperience';

type Props = {
  orguser: OrgUser,
  userRequestsOnly: boolean,
};

const EmptyState = ({ orguser, userRequestsOnly }: Props) => {
  if (!userRequestsOnly) {
    return (
      <div className="text-center my-5">
        <h4>No matching requests</h4>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-center my-5">
      {orguser.capabilities.canAccessSeatedExperience ? (
        <>
          <div>
            <img src="/assets/img/icons/bellempty-1.png" width="120" alt="" className="my-3 mr-5" />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h6>Your queue is empty</h6>
            {orguser.capabilities.canManagePartnerships && false && (
              <NewBdRequestButton newActivity btnSize="lg" />
            )}
          </div>
        </>
      ) : (
        <div className="d-flex flex-column align-items-center gap-5 px-2">
          <span className="large-text bold gray-700">
            You will see open messages from your partner.
          </span>
          <span className="normal-text semi-bold gray-600 mb-2">
            Upgrade Crossbeam for Sales so your sales team can request introductions and information
            from your partners.
          </span>
          <UpgradeButton />
        </div>
      )}
    </div>
  );
};

export default EmptyState;
