// @flow

import React, { useEffect, useState } from 'react';
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead';
import { Link, useHistory, useParams as useRouterParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil, trim } from 'lodash';

import { Account } from 'data/entities';
import { dealStatuses } from 'data/entities/contact';
import { getSignal, signalDefinitions } from 'data/entities/signal';
import { fetchPartnerBySlug } from 'data/repositories/partners';
import type { SearchIndexItem } from 'data/repositories/search';
import useParams from 'utils/useParams';

import AccountDisplayEdit from 'components/AccountDisplayEdit';
import Filter from 'components/Filter';
import Unisearchal from 'components/Unisearchal';

type Props = {
  account: Account,
  loadingAccount: boolean,
};

const Sidebar = ({ account, loadingAccount }: Props) => {
  const history = useHistory();
  const { accountSlug }: { accountSlug: string } = useRouterParams();
  const [
    { inContacts, keyword: keywordRaw, partner: partnerRaw, signal: signalRaw, dealStatus },
    setParams,
  ] = useParams();

  const keyword = keywordRaw ? [].concat(keywordRaw) : [];
  const signal = signalRaw ? [].concat(signalRaw) : [];

  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const partner = partnerRaw ? [].concat(partnerRaw) : [];
  const partnerDependency = JSON.stringify(partner);
  const onPartnerSelect = (newPartners: ?(SearchIndexItem[])) => {
    setPartners(newPartners);
    setParams({ partner: newPartners.map((p) => p.slug) }, false);
  };

  // Load initial partners.
  useEffect(() => {
    setPartners([]);
    const promises = [];
    partner.forEach((slug) => promises.push(fetchPartnerBySlug(slug)));
    setLoadingPartners(true);
    Promise.all(promises)
      .then((newPartners: Partner[]) => {
        setLoadingPartners(false);
        setPartners(
          newPartners.map((partnerObj: Partner): SearchIndexItem => ({
            id: partnerObj.id,
            kind: 'partner',
            name: partnerObj.name,
            slug: partnerObj.slug,
          }))
        );
      })
      .catch(() => setLoadingPartners(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On query clear, clear partners.
  useEffect(() => {
    if (partner.length === 0) {
      setPartners([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerDependency]);

  return (
    <div className="d-flex flex-column gap-40" style={{ width: 300 }}>
      <div className="d-flex flex-column gap-15">
        <p className="mb-1 bold large-text gray-700">Account</p>
        {!loadingAccount && account && (
          <AccountDisplayEdit
            currentAccount={account}
            onUpdate={(newAccount: Account) =>
              history.push(`/a/${newAccount.slug}/contacts${history.location.search}`)
            }
          />
        )}
      </div>
      <div className="d-flex flex-column gap-15">
        <div className="mb-1 d-flex flex-row">
          <span className="bold large-text gray-700 flex-fill">Filters</span>
          <Link to={`/a/${accountSlug}/contacts`}>Clear All</Link>
        </div>
        <div className="d-flex flex-column stacked-cards">
          <Filter title="Keywords">
            <Typeahead
              id="keyword-filter"
              allowNew
              newSelectionPrefix="Search for: "
              selected={keyword.map((v) => ({ label: v }))}
              renderMenu={(results, menuProps) =>
                results.length ? <TypeaheadMenu {...menuProps} options={results} /> : null
              }
              options={[]}
              onChange={(selected: { label: string }[]) =>
                setParams({ keyword: selected.map(({ label }) => trim(label, ',&?')) }, false)
              }
              placeholder="Search for keywords..."
              bsSize="sm"
              multiple
              clearButton
              changeOnClear
            />
          </Filter>
          <Filter title="Partner Deal Signals">
            {Object.keys(signalDefinitions).map((key) => {
              const { key: signalKey, label, colorRaw, icon, accountsExclusive } = getSignal(key);
              const checked = signal.includes(signalKey);
              return (
                !accountsExclusive && (
                  <button
                    onClick={() =>
                      setParams(
                        {
                          signal: checked
                            ? signal.filter((v) => v !== signalKey)
                            : signal.concat(signalKey),
                        },
                        false
                      )
                    }
                    key={signalKey}
                    className="btn btn-link p-0 d-flex flex-row align-items-center gap-10"
                    style={{ cursor: 'pointer' }}
                    type="button"
                  >
                    <input type="checkbox" checked={checked} readOnly />
                    <FontAwesomeIcon style={{ flex: '0 0 20px' }} color={colorRaw} icon={icon} />
                    <span className="normal-text bold gray-700">{label}</span>
                  </button>
                )
              );
            })}
          </Filter>
          <Filter title="Partners">
            <Unisearchal
              placeholder="Search for partner..."
              entityKind="partner"
              selected={partners}
              isLoading={loadingPartners}
              onChange={onPartnerSelect}
              onClear={onPartnerSelect}
              stripUnisearchalClassName
              loadInitial
              multiple
            />
          </Filter>
          {/* Hiding this for now, as it doesn't relay accurate results */}
          {false && (
            <Filter title="Show">
              <select
                value={isNil(inContacts) ? 'all' : inContacts}
                className="full-width form-control uppercase form-control-md"
                onChange={(e) => {
                  const { value } = e.target;
                  setParams({ inContacts: value === 'all' ? null : value }, false);
                }}
              >
                <option value="all">All Contacts</option>
                <option value>In My Contacts</option>
                <option value={false}>Not In Contacts</option>
              </select>
            </Filter>
          )}
          <Filter title="Partner Deal Status">
            <select
              value={isNil(dealStatus) ? 'all' : dealStatus}
              className="full-width form-control uppercase form-control-md"
              onChange={(e) => {
                const { value } = e.target;
                setParams({ dealStatus: value === 'all' ? null : value }, false);
              }}
            >
              <option value="all">Show All Statuses</option>
              {Object.keys(dealStatuses).map((status) => (
                <option key={status} value={status}>
                  {dealStatuses[status]}
                </option>
              ))}
            </select>
          </Filter>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
