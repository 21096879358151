// @flow

import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest } from 'data/entities';
import { SharedConversation } from 'data/entities/bdrequest';

import FieldErrors from 'components/FieldErrors';

import InviteUsers from '../../Users/InviteUsers';

type Props = {
  request: BdRequest,
  sharedConversation: SharedConversation | null,
  onUpdate: () => void,
  mini?: boolean,
};

const IntendedParticipantBanner = ({ request, sharedConversation, onUpdate, mini }: Props) => {
  const { intendedRecipients } = sharedConversation || {};
  const uninvitedRecipients = useMemo(() => (intendedRecipients || []).filter((r) => !r.id), [
    intendedRecipients,
  ]);

  const title = useMemo(
    () => `${uninvitedRecipients.map((i) => i.fullName).join(', ')} can't see this yet.`,
    [uninvitedRecipients]
  );

  return uninvitedRecipients.length ? (
    <>
      <FieldErrors errors={{}} />
      <div
        className={`${
          mini ? 'py-1 m-1' : 'py-3'
        } rounded px-3 bg-light-blue d-flex flex-row align-items-center gap-10`}
      >
        <FontAwesomeIcon icon="info-circle" className="blue" size={mini ? 'lg' : '2x'} />
        <div className="flex-fill d-flex flex-column gap-5">
          {mini ? (
            <span className="small-text bold gray-700">{title}</span>
          ) : (
            <>
              <span className="large-text bold gray-700">{title}</span>
              <span className="normal-text semi-bold gray-600">
                {`${request.createdBy.firstName} wants to connect with ${uninvitedRecipients
                  .map((i) => i.fullName)
                  .join(', ')} who is not on Crossbeam for Sales.`}
              </span>
            </>
          )}
        </div>
        <InviteUsers defaultEmails={uninvitedRecipients.map((ui) => ui.email)}>
          <Button color="primary" size={mini ? 'xs' : 'md'} className="text-nowrap">
            <FontAwesomeIcon icon="plus" className="mr-2" />
            Invite {uninvitedRecipients.map((i) => i.firstName).join(', ')}
          </Button>
        </InviteUsers>
      </div>
    </>
  ) : null;
};

IntendedParticipantBanner.defaultProps = {
  mini: false,
};

export default IntendedParticipantBanner;
