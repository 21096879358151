// @flow

import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { OrgUser } from 'data/entities';
import type NetworkProfile from 'data/entities/networkprofile';
import { fetchNetworkProfiles } from 'data/repositories/networkprofile';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';
import PersonAvatar from 'components/PersonAvatar';

const Member = ({ networkProfile }: { networkProfile: NetworkProfile }) => (
  <Link to={`/network/oid/${networkProfile.id}`} disabled>
    <div className="d-flex flex-row align-items-center gap-15">
      <div style={{ width: '50px' }}>
        <PersonAvatar
          firstName={networkProfile.firstName}
          lastName={networkProfile.lastName}
          size={50}
          org={networkProfile.org}
          avatar={networkProfile.avatarImage}
        />
      </div>
      <div className="d-flex flex-column justify-content-center">
        <p className="m-0 bold large-text gray-700">{networkProfile.fullName}</p>
        {networkProfile.title && (
          <p className="m-0 semi-bold normal-text gray-500">{networkProfile.title}</p>
        )}
      </div>
    </div>
  </Link>
);

type Props = {
  loggedInOrguser: OrgUser,
  networkProfile: NetworkProfile,
  isOwnOrg: boolean,
};

const OtherMembers = ({ loggedInOrguser: { id: loggedInID }, networkProfile, isOwnOrg }: Props) => {
  const [loading, setLoading] = useState(true);
  const [otherMembers, setOtherMembers] = useState<NetworkProfile[]>(null);

  useEffect(() => {
    setLoading(true);
    fetchNetworkProfiles({
      orgId: networkProfile.org.id,
      includeOwnOrg: isOwnOrg,
      page: 0,
      limit: 8,
      excludeOrgUserId: networkProfile.id,
    })
      .then((sp) => {
        setLoading(false);
        setOtherMembers(sp.results);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [loggedInID, networkProfile.org.id, networkProfile.id, isOwnOrg]);

  return (
    <>
      <div className="col d-flex flex-column sticky gap-20 page-top-margin">
        <p className="m-0 bold large-text gray-600">Other {networkProfile.org.name} Members</p>
        {!loading ? (
          <>
            {otherMembers && otherMembers.length !== 0 ? (
              otherMembers.map((member: NetworkProfile) => (
                <Member key={member.id} networkProfile={member} />
              ))
            ) : (
              <p className="m-0 semi-bold normal-text gray-500">
                There are no other members of this organization.
              </p>
            )}
          </>
        ) : (
          <LoadingRing maxWidth="50px" className="m-5" />
        )}
      </div>
    </>
  );
};

export default withRouter(withOrguser(OtherMembers));
