// @flow

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash';

import { Org } from 'data/entities';
import { createCrossbeamIntegration } from 'data/repositories/crossbeam';
import withOrguser from 'contexts/withOrguser';
import setTitle from 'utils/setTitle';
import useParams from 'utils/useParams';
import { compareAuthStateParam } from 'authFetch/utils';

import BareLayout from 'layouts/BareLayout';
import LoadingRing from 'components/LoadingRing';

import Orguser from '../../data/entities/orguser';
import { getHubspotIntegrationUrl } from '../../utils/urlUtils';

import Header from './Header';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: Orguser,
  doLogin: (onboarding: boolean, historyPushArgs: any) => void,
};

const CrossbeamData = ({ loggedInOrg, loggedInOrguser, doLogin }: Props) => {
  const history = useHistory();
  const [{ code, state: stateQueryParam }] = useParams({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle('Connecting with Crossbeam');
    setLoading(true);
    const { matches, hubspotFlow } = compareAuthStateParam(stateQueryParam);

    if (isNil(stateQueryParam) || !matches) {
      setLoading(false);
    } else {
      createCrossbeamIntegration(code)
        .then((ci) => {
          setLoading(false);
          if (hubspotFlow) {
            window.location.href = getHubspotIntegrationUrl();
          }
          if (!loggedInOrg.hasIntegration) {
            doLogin(false, { pathname: '/onboarding', state: { isInitial: true } });
          } else {
            history.push('/crossbeam');
          }
        })
        .catch((e) => {
          setLoading(false);
          setError(e.json.detail);
        });
    }

    return () => setTitle();
  }, [doLogin, loggedInOrg, history, code, stateQueryParam, loggedInOrguser]);

  return (
    <BareLayout
      centered
      afterPageContainer={<Header title="Securely Connecting to Crossbeam" subtitle="" />}
    >
      {loading ? (
        <div className="mt-5">
          <LoadingRing text="Connecting..." />
        </div>
      ) : (
        <div className="mt-5">
          <h5>
            {error ||
              'Failed to authenticate with Crossbeam. Please try again. If this error persists contact us.'}
          </h5>
        </div>
      )}
    </BareLayout>
  );
};

export default withOrguser(CrossbeamData);
