// @flow

import * as React from 'react';
import { Alert, Col } from 'reactstrap';
import { get } from 'lodash';
import queryString from 'qs';

import { useTitleEffect } from 'utils/setTitle';
import { b64ParamsToObj } from 'utils/urlUtils';
import { setAuthToken } from 'authFetch/utils';
import type { LocationT } from 'sharedTypes/reactRouter';

import BareLayout from 'layouts/BareLayout';
import FullPageLoadingRing from 'components/FullPageLoadingRing';

const genericErrorStr = 'Failed to login. Please try again. If this error persists contact us.';

type AuthCodeResponse = {
  token: string,
};

type Props = {
  location: LocationT,
  doLogin: (doOnboarding: ?boolean) => {},
  validationCall: (code: string, inviteCode: string) => Promise<AuthCodeResponse>,
};

export default function OauthCallbackValidation(props: Props) {
  const { validationCall, location, doLogin } = props;
  const { search } = location || {};

  const [loading, setLoading] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  // Used push to closed beta (NOT an authentication error -- permission)
  const [, setPermissionsError] = React.useState(false);
  useTitleEffect('Logging in...');

  React.useEffect(() => {
    function validateCode() {
      // Validate salesforce oauth code
      const qs = queryString.parse(search, { ignoreQueryPrefix: true });
      const { code, state: stateStr } = qs;
      let inviteCode = null;
      let redirectFrom = null;
      if (stateStr && stateStr !== 'undefined') {
        ({ acceptedInvite: inviteCode, redirectFrom } = b64ParamsToObj(stateStr));
      }
      return validationCall(code, inviteCode)
        .then(({ token }) => {
          setAuthToken(token);
          doLogin(true, redirectFrom);
          setLoggedIn(true);
        })
        .catch((err) => {
          if (get(err, 'response.status') === 403) {
            setPermissionsError(true);
          } else {
            setErrorMsg(
              err.json && err.json.nonFieldErrors ? err.json.nonFieldErrors[0] : genericErrorStr
            );
          }
          setLoading(false);
        });
    }
    validateCode();
  }, [validationCall, search, doLogin]);

  let main: React.Node;
  const errorAlert = (message) => (
    <Col className="mx-auto" sm={6}>
      <Alert color="warning">
        <h5>{message}</h5>
      </Alert>
    </Col>
  );
  if (errorMsg) {
    main = errorAlert(errorMsg);
  } else if (loading) {
    return <FullPageLoadingRing text="Logging in..." />;
  }
  if (loggedIn) {
    return null;
  }
  // Nothing should drop to here. Return a generic error if it does.
  main = main || errorAlert(genericErrorStr);
  return (
    <BareLayout centered>
      <div className="align-self-center">{main}</div>
    </BareLayout>
  );
}
