// @flow

import * as React from 'react';
import classNames from 'classnames';

type Props = {
  color?: string,
  width?: ?string,
  height?: ?string,
  className?: string,
  alt?: ?string,
  disabled?: boolean,
};

function Target({ color, width, height, className, alt, disabled }: Props) {
  const boxwidth = width || height || '16';
  const boxheight = height || width || '16';

  const classes = classNames('target-icon', className, { 'target-icon__disabled': disabled });
  const altStr = alt || disabled ? 'Not a target account' : 'Target account';
  const useColor = color || 'currentColor';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={boxwidth}
      height={boxheight}
      className={classes}
      viewBox="0 0 23 22"
    >
      <title>{altStr}</title>
      <path
        d="M22.662 20.385L14.396 7.161c-.028-.042-.063-.079-.104-.108V1.466a.28.28 0 0 0-.28-.272.28.28 0 0 0-.28.272v.281h-2.739c-.346 0-.626.28-.626.626v.15c0 .036-.029.065-.065.065H7.283a.28.28 0 0 0-.198.479l1.484 1.484-1.484 1.484a.28.28 0 0 0 .198.478h3.019c.346 0 .626-.28.626-.626v-.215h2.804v1.38c-.041.029-.077.066-.104.108l-3.822 6.115-1.719-2.75a.47.47 0 0 0-.769 0l-6.162 9.86a.28.28 0 0 0 .238.429h21.03a.28.28 0 0 0 .238-.429zM10.366 5.888c0 .036-.029.065-.065.065H7.96l1.205-1.205a.28.28 0 0 0 0-.396L7.96 3.148h2.341a.63.63 0 0 0 .065-.003v2.743zm.561-.776V2.373c0-.017.007-.034.019-.046s.029-.019.046-.019h2.739v2.804h-2.805zm3.085 2.492l2.448 3.917-1.045 1.045-1.205-1.205a.28.28 0 0 0-.396 0l-1.205 1.205-1.045-1.045 2.448-3.917zm-6.309 3.365l1.747 2.795-.695.695-.853-.853a.28.28 0 0 0-.396 0l-.853.853-.695-.695 1.747-2.796zM1.9 20.253l3.751-6.002.803.802a.28.28 0 0 0 .396 0l.853-.853.853.853a.28.28 0 0 0 .099.063l-1.19 1.904a.28.28 0 0 0 .224.44.28.28 0 0 0 .25-.142l3.318-5.309 1.153 1.153a.28.28 0 0 0 .396 0l1.205-1.205 1.205 1.205a.28.28 0 0 0 .396 0l1.153-1.153 5.152 8.244H1.9z"
        stroke={useColor}
        strokeWidth=".5"
        fill={useColor}
      />
    </svg>
  );
}

Target.defaultProps = {
  color: '',
  height: null,
  width: null,
  className: '',
  alt: null,
  disabled: false,
};

export default Target;
