// @flow

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import authFetch from 'authFetch/authFetch';

import FullPageLoadingRing from '../components/FullPageLoadingRing';
import BareLayout from '../layouts/BareLayout';

function fetchMasterRecordDetails(masterRecordUUID: string) {
  return authFetch(`/crossbeam-core/account/${masterRecordUUID}`);
}

type Props = {
  match: { params: { masterRecordUUID: string } },
};

export default function MasterRecordRedirect(props: Props) {
  // const { masterRecordUUID }: { [key: string]: string } = useRouterParams();
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const {
    match: {
      params: { masterRecordUUID },
    },
  } = props;
  useEffect(() => {
    fetchMasterRecordDetails(masterRecordUUID)
      .then((data) => {
        const accountAddress = `/a/${data.slug}?login-org=${data.org_uuid}`;
        setUrl(accountAddress);
      })
      .catch((respErr) => {
        setError(respErr);
      });
  }, [masterRecordUUID]);

  useEffect(() => {
    if (url !== '') {
      window.location.href = url;
    }
  }, [url]);

  const style = {
    textAlign: 'center',
    justifyItems: 'center',
    marginTop: '200px',
  };

  return (
    <>
      {error === '' ? (
        <FullPageLoadingRing text="Logging in..." />
      ) : (
        <BareLayout>
          <div style={style}>
            <h1>404</h1>
            <div>
              This record was not found in Crossbeam for Sales. Confirm Crossbeam for Sales is set
              up correctly and your Co-Selling partners are enabled in{' '}
              <Link to="/crossbeam">Partner Settings</Link>
            </div>
          </div>
        </BareLayout>
      )}
    </>
  );
}
