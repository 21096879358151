// @flow

import * as React from 'react';
import { useMemo } from 'react';

import { AccountOwner, Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import SuggestionCard from 'views/Users/SuggestionCard';
import HorizontalScroll from 'components/HorizontalScroll';
import LoadingRing from 'components/LoadingRing';

type Props = {
  accountOwners: AccountOwner[] | null,
  loggedInOrg: Org,
};

const Suggestions = ({ accountOwners, loggedInOrg }: Props) => {
  const inviteableAOs = useMemo(() => (accountOwners || []).filter((ao) => ao.inviteable), [
    accountOwners,
  ]);

  const loading = !accountOwners;

  return (
    (inviteableAOs.length || loading) && (
      <div className="mb-4 d-flex flex-column gap-15">
        <h5 className="m-0 bold gray-700">{loggedInOrg.name} account owners you should invite:</h5>
        {loading ? (
          <LoadingRing maxWidth="50px" />
        ) : (
          <HorizontalScroll>
            {inviteableAOs.map((ao, index) => (
              <SuggestionCard
                itemId={index}
                key={ao.email}
                firstName={ao.firstName}
                lastName={ao.lastName}
                avatarImage={ao.avatarImage}
                email={ao.email}
              />
            ))}
          </HorizontalScroll>
        )}
      </div>
    )
  );
};

export default withOrguser(Suggestions);
