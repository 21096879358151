// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Notification } from '@magicbell/magicbell-react';
import MagicBell, { FloatingNotificationInbox } from '@magicbell/magicbell-react';

import { OrgUser } from 'data/entities';
import {
  BdRequestCommentMessageType,
  handleMagicBellNotificationEvent,
  MagicBellApiKey,
} from 'data/entities/notificationEvents';
import markNotificationSeen from 'data/repositories/notify';
import withOrguser from 'contexts/withOrguser';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

type Props = {
  loggedInOrguser: OrgUser,
  history: RouterHistoryT,
};

function MagicBellTray({ loggedInOrguser, history }: Props) {
  const theme = {
    icon: {
      borderColor: '#ffffff',
      width: '17px',
    },
    unseenBadge: { backgroundColor: '#EC2F2F' },
    header: {
      backgroundColor: '#042C59',
      textColor: '#ffffff',
      borderRadius: '16px',
    },
    footer: {
      backgroundColor: '#042C59',
      textColor: '#ffffff',
      borderRadius: '16px',
    },
    notification: {
      default: {
        textColor: '#15091F',
        borderRadius: '8px',
        backgroundColor: '#042C59',
      },
      unseen: {
        backgroundColor: '#042C59',
        textColor: '#15091F',
        borderRadius: '8px',
      },
      unread: {
        backgroundColor: '#042C59',
        textColor: '#15091F',
        borderRadius: '8px',
      },
    },
  };
  const { magicbellHash, id } = loggedInOrguser;

  const onNotificationClick = (notification: Notification) => {
    const { customAttributes, actionUrl } = notification || {};
    if (customAttributes) {
      // Server sends snake case, but the library gives us camel case
      const { messageKind, notificationId } = customAttributes;
      if (messageKind === BdRequestCommentMessageType) {
        // If the notification was clicked, we can also mark it as seen.
        markNotificationSeen(notificationId);
      }
    }
    if (actionUrl) {
      const url = new URL(actionUrl);
      history.push(url.pathname);
    }
  };

  return (
    <div className="MagicBellInbox">
      <MagicBell
        apiKey={MagicBellApiKey}
        userExternalId={id}
        userKey={magicbellHash}
        theme={theme}
        onNewNotification={handleMagicBellNotificationEvent}
        BellIcon={
          <FontAwesomeIcon icon="bell" fontSize={17} className="NotificationBell gray-600" />
        }
      >
        {(props) => (
          <FloatingNotificationInbox
            width={500}
            height={600}
            {...props}
            onNotificationClick={onNotificationClick}
          />
        )}
      </MagicBell>
    </div>
  );
}

export default withRouter(withOrguser(MagicBellTray));
