/*
Signup flow

0. If they have an invite and it's unaccepted, prompt to accept it
1. Org Details
2. Data integration
3. Invite partners
4. Add users
5. Map dem account bruh

*/

import Onboarding from './Onboarding';

export default Onboarding;
