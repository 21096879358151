// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SlackChannelNeedsFullSetupInfo = ({
  slackNoChannelStyle,
  sendToSlackStyle,
  toggleModal,
  addAChannelStyle,
}) => (
  <>
    <span className="flex-fill gray-400" style={slackNoChannelStyle}>
      No channel
    </span>
    <span
      style={addAChannelStyle}
      onClick={toggleModal}
      role="presentation"
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          toggleModal();
        }
      }}
    >
      <a href="#" onClick={toggleModal} className="nav-link p-0" style={sendToSlackStyle}>
        <FontAwesomeIcon icon="paper-plane" className="mr-2" />
        Send to Slack
      </a>
    </span>
  </>
);

export default SlackChannelNeedsFullSetupInfo;
