// @flow

import { camelCase, sampleSize } from 'lodash';

import type { FetchResponse } from 'authFetch/entities';
import { ErrorResponse } from 'authFetch/entities';

const ORG_ID_KEY = 'orgId';
const XB_ORG_ID_KEY = 'xbOrgId';
const TOKEN_KEY = 'authToken';

const AUTH_STATE_PARAM_KEY = 'authStateParam';

export const checkHttpStatusPromise = (response: FetchResponse) => {
  const nonJsonError = new ErrorResponse(response);

  if (!response.ok) {
    let isBadAuth = false;
    if (
      response.status === 401 &&
      response.url.indexOf('auth/') < 0 &&
      response.url.indexOf('notify/slack-integration') < 0 &&
      response.url.indexOf('notify/slack-channels') < 0
    ) {
      // Deleting the auth token keeps it from trying to call auth and getting 401's later.
      isBadAuth = true;
      deleteAuthToken();
      // Reload the window to trigger attempting to authenticate, booting to login
      window.location.reload();
    }
    if ('json' in response) {
      return response
        .json()
        .catch(() => {
          throw nonJsonError;
        })
        .then((errorJson) => {
          // Convert top level keys to camelCase
          const errors = Object.keys(errorJson).reduce(
            (err, k) => ({ ...err, [camelCase(k)]: errorJson[k] }),
            {}
          );
          const yeet = () => {
            throw new ErrorResponse(response, errors);
          };
          if (isBadAuth) {
            // For bad auth return a promise with a delayed throw in order to give the page reload a chance to happen
            // Otherwise we will see a lot of uncaught errors and confusion in the app.
            const delayedYeet = () => new Promise(() => setTimeout(yeet, 4000));
            return delayedYeet();
          }
          return yeet();
        });
    }
    throw nonJsonError;
  }
  return response;
};

export const parseJSON = (response: FetchResponse) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }
  return response.text();
};

export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || null;
}

export function setAuthToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function deleteAuthToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function getOrg(): ?string {
  return localStorage.getItem(ORG_ID_KEY);
}

export function setOrg(orgId: string) {
  localStorage.setItem(ORG_ID_KEY, orgId);
}

export function deleteOrg() {
  localStorage.removeItem(ORG_ID_KEY);
}

export function getXBOrg(): ?string {
  return localStorage.getItem(XB_ORG_ID_KEY);
}

export function setXBOrg(orgId: string) {
  localStorage.setItem(XB_ORG_ID_KEY, orgId);
}

export function deleteXBOrg() {
  localStorage.removeItem(XB_ORG_ID_KEY);
}

export function orgUrlBase() {
  const orgId = getOrg();
  if (!orgId) {
    // FIXME We need to barf in some way that we can show
    throw Error('User Org is missing.');
  }
  return `orgs/${orgId}`;
}
const { AbortController } = window;

export class AbortFetch {
  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  get signal(): AbortSignal {
    return this.controller.signal;
  }

  abort() {
    this.controller.abort();
    // Restart controller
    this.controller = new AbortController();
  }
}

export const generateAuthStateParam = (userId: string, flow: boolean): string => {
  const stateParamChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const randomString = sampleSize(stateParamChars, 64).join('');
  localStorage.setItem(AUTH_STATE_PARAM_KEY, randomString);
  const hubflowStateValue = flow ? 'T' : 'F';
  return `${randomString}${hubflowStateValue}`;
};

export const compareAuthStateParam = (encodedString) => {
  const testValue = localStorage.getItem(AUTH_STATE_PARAM_KEY);
  const randomString = encodedString.substring(0, encodedString.length - 1);
  const booleanChar = encodedString.charAt(encodedString.length - 1);

  const matches = randomString === testValue;

  const hubspotFlow = booleanChar === 'T';

  return { matches, hubspotFlow };
};

export const isAbortError = (exception: Error) => exception.name === 'AbortError';
