// @flow

import * as React from 'react';

import { UserContext } from 'contexts';
import { OrgUserContext } from 'contexts/OrgUserContext';

import ProfileForm from '../ProfileForm';

type Props = {
  onUpdated: () => void,
};

class ProfileFormSettings extends React.Component<Props> {
  render() {
    return (
      <OrgUserContext.Consumer>
        {({ org, setOrgUser }) => (
          <UserContext.Consumer>
            {({ updateUser }) => (
              <ProfileForm
                updateUser={updateUser}
                org={org}
                setOrgUser={setOrgUser}
                onUpdated={this.props.onUpdated}
              />
            )}
          </UserContext.Consumer>
        )}
      </OrgUserContext.Consumer>
    );
  }
}

export default ProfileFormSettings;
