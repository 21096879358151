// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import UpgradeButton from './UpgradeButton';

type Props = {
  loggedInOrguser: OrgUser,
};

const ReplyOnlyBanner = ({ loggedInOrguser: { capabilities } }: Props) => (
  <div className="px-3 py-3 bg-light-violet">
    <div
      className="d-flex flex-row align-items-center flex-1 gap-15 mx-auto"
      style={{ maxWidth: 1230 }}
    >
      <FontAwesomeIcon icon="stars" className="violet" size="2x" />
      <div className="flex-fill d-flex flex-column">
        <span className="large-text bold gray-700">
          Get Crossbeam for Sales to unlock Deal Navigator, Full Messaging, Lists and more.
        </span>
        <span className="normal-text semi-bold gray-600">
          Discover exactly where partners can help you and collaborate to close more deals faster.
        </span>
      </div>
      <UpgradeButton />
    </div>
  </div>
);

export default withOrguser(ReplyOnlyBanner);
