// @flow

import React from 'react';

type Props = {
  title: string,
  isPrimary?: boolean,
  children: React.ReactNode,
};

const Filter = ({ title, isPrimary, children }: Props) => (
  <div className={`d-flex flex-column ${isPrimary ? 'card card-body m-0 gap-15' : 'gap-10'}`}>
    <span className={isPrimary ? 'normal-text bold gray-700' : 'normal-text semi-bold gray-600'}>
      {title}
    </span>
    {children}
  </div>
);

Filter.defaultProps = {
  isPrimary: true,
};

export default Filter;
