// @flow

import { isNil } from 'lodash';
import QueryString from 'qs';

import { Account } from 'data/entities';
import NetworkProfile from 'data/entities/networkprofile';
import authFetch from 'authFetch/authFetch';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

type Options = {
  orgId?: ?string,
  includeOwnOrg?: ?boolean,
  page?: ?number,
  limit?: ?number,
  excludeOrgUserId?: ?string,
  onlyOverlaps?: ?boolean,
  name?: ?string,
  pageUrl?: ?string,
};

const fetchNetworkProfiles = (options: ?Options): Promise<PaginatedResponse<NetworkProfile>> => {
  const { orgId, includeOwnOrg, page, limit, excludeOrgUserId, onlyOverlaps, name, pageUrl } =
    options || {};
  const params = {};
  if (!isNil(name)) {
    params.name = name;
  }
  if (!isNil(orgId)) {
    params.partner_org_id = orgId;
  }
  if (!isNil(page) && !isNil(limit)) {
    params.page = page;
    params.limit = limit;
  }
  if (!isNil(excludeOrgUserId)) {
    params['exclude_org_user_id[]'] = excludeOrgUserId;
  }

  if (!isNil(onlyOverlaps)) {
    params.only_overlaps = onlyOverlaps;
  }
  params.include_own_org = includeOwnOrg;
  const url = `network/orgusers?${QueryString.stringify(params)}`;
  return authFetch(!isNil(pageUrl) ? pageUrl : url).then(
    mapPaginatedResponse(NetworkProfile.fromApi)
  );
};

const fetchNetworkConnectionCount = (id: string): Promise<number> => {
  const url = `network/orgusers/${id}/connection-count?include_own_org=true`;
  return authFetch(url).then((data) => data.connection_count);
};

const fetchNetworkProfile = (id: string): Promise<NetworkProfile> => {
  const url = `network/orgusers/${id}`;
  return authFetch(url).then(NetworkProfile.fromApi);
};

const fetchNetworkProfileAccountsCount = (
  id: string
): Promise<{ [key: string]: string | number }> => {
  const url = `network/orgusers/${id}/accounts-status-count?include_own_org=true`;
  return authFetch(url);
};

const fetchNetworkProfileAccounts = (
  id: string,
  status: ?string,
  offset: ?number,
  limit: ?number
): Promise<PaginatedResponse<Account>> => {
  const params = {};
  if (!isNil(offset) && !isNil(limit)) {
    params.offset = offset;
    params.limit = limit;
  }
  if (!isNil(status)) {
    params.status = status;
  }
  const url = `network/orgusers/${id}/accounts?${QueryString.stringify(params)}`;
  return authFetch(url).then(mapPaginatedResponse(Account.fromApi));
};

export {
  fetchNetworkConnectionCount,
  fetchNetworkProfile,
  fetchNetworkProfileAccounts,
  fetchNetworkProfileAccountsCount,
  fetchNetworkProfiles,
};
