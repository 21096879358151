// @flow

import * as React from 'react';

const ShareSegments = () => (
  <svg width="25" height="20" viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 15C21.9375 15 21.4219 15.1953 21.0039 15.5078L17.2539 13.2578C17.4023 12.8203 17.5 12.3594 17.5 11.8711C17.5 9.45312 15.543 7.49609 13.125 7.49609C12.7969 7.49609 12.4766 7.53906 12.1719 7.60938L10.5781 4.19531C10.9922 3.75 11.25 3.15625 11.25 2.5C11.25 1.12109 10.1289 0 8.75 0C7.37109 0 6.25 1.12109 6.25 2.5C6.25 3.87891 7.37109 5 8.75 5C8.79297 5 8.83203 4.98828 8.875 4.98828L10.4766 8.41797C9.43359 9.21484 8.75 10.4609 8.75 11.875C8.75 14.293 10.707 16.25 13.125 16.25C14.3789 16.25 15.5 15.7148 16.2969 14.8711L20.0391 17.1172C20.0195 17.2422 20 17.3711 20 17.5C20 18.8789 21.1211 20 22.5 20C23.8789 20 25 18.8789 25 17.5C25 16.1211 23.8789 15 22.5 15ZM13.125 13.75C12.0898 13.75 11.25 12.9102 11.25 11.875C11.25 10.8398 12.0898 10 13.125 10C14.1602 10 15 10.8398 15 11.875C15 12.9102 14.1602 13.75 13.125 13.75Z" />
    <path
      d="M25 5C25 6.37891 23.8789 7.5 22.5 7.5C21.1211 7.5 20 6.37891 20 5C20 3.62109 21.1211 2.5 22.5 2.5C23.8789 2.5 25 3.62109 25 5Z"
      fillOpacity="0.4"
    />
    <path
      d="M0 11.875C0 10.4961 1.12109 9.375 2.5 9.375C3.87891 9.375 5 10.4961 5 11.875C5 13.2539 3.87891 14.375 2.5 14.375C1.12109 14.375 0 13.2539 0 11.875Z"
      fillOpacity="0.4"
    />
  </svg>
);

export default ShareSegments;
