// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import Pill from 'components/Pill';

type Props = {
  loggedInOrguser: OrgUser,
};

const PremiumBadge = ({ loggedInOrguser: { capabilities } }: Props) =>
  !capabilities.canAccessSeatedExperience && (
    <Pill icon={<FontAwesomeIcon icon="stars" className="mr-2" />} color="orange" extra="Premium" />
  );

export default withOrguser(PremiumBadge);
