// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, Org, PartnerOrgUser } from 'data/entities';
import OrgUser, { OrgUserNameOnly } from 'data/entities/orguser';
import {
  addBdRequestParticipant,
  followBdRequest,
  stopFollowingBdRequest,
  updateBdRequestAssignee,
} from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import Modal from 'components/Modal';
import PersonAvatar from 'components/PersonAvatar';
import UserEditSearch from 'components/UserEditSearch/UserEditSearch';
import UserSearch from 'components/UserSearch';

type PersonProps = {
  request: BdRequest,
  user: OrgUserNameOnly | PartnerOrgUser,
  loggedInOrguser?: OrgUser,
  onLeave?: () => void,
};

const Person = ({ request, loggedInOrguser, onLeave, user }: PersonProps) => {
  const isSalesAssignee =
    loggedInOrguser && loggedInOrguser.id === (request.salesAssignee && request.salesAssignee.id);
  const isBdAssignee =
    loggedInOrguser && loggedInOrguser.id === (request.bdAssignee && request.bdAssignee.id);
  const canLeave =
    loggedInOrguser && loggedInOrguser.id === user.id && !isSalesAssignee && !isBdAssignee;

  return (
    <div className="flex-fill d-flex flex-row align-items-center gap-15">
      <PersonAvatar
        firstName={user.firstName}
        lastName={user.lastName}
        org={
          user.orgName && { name: user.orgName, domain: user.orgDomain, logoUrl: user.orgLogoUrl }
        }
        size={48}
        avatar={user.avatarImage}
        noMargin
      />
      <div className="d-flex flex-column flex-fill">
        <Link
          to={`/network/oid/${user.id}`}
          className="large-text bold gray-700 text-truncate fs-mask"
        >
          {user.fullName}
        </Link>
        <span className="normal-text semi-bold gray-500 fs-mask">
          {user.title ? `${user.title} at ${user.orgName}` : user.orgName}
        </span>
      </div>
      {canLeave && (
        <button title="Leave" onClick={onLeave} type="button" className="btn btn-link">
          <FontAwesomeIcon icon="close" />
        </button>
      )}
    </div>
  );
};

Person.defaultProps = {
  loggedInOrguser: undefined,
  onLeave: () => {},
};

type Props = {
  request: BdRequest,
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
  onUpdate: () => void,
};

const Participants = ({ request, loggedInOrguser, loggedInOrg, onUpdate }: Props) => {
  const { participants, assignee: bdManager, id } = request;
  const [open, setOpen] = useState(false);
  const [changeManager, setChangeManager] = useState(false);
  const [addParticipant, setAddParticipant] = useState();

  const toggle = () => setOpen((o) => !o);

  const add = () => {
    if (addParticipant) {
      addBdRequestParticipant(request.id, addParticipant).then(() => onUpdate());
    }
  };

  const canJoin = loggedInOrguser && !participants.some((p) => p.id === loggedInOrguser.id);
  const onJoin = () => {
    followBdRequest(request.id).then(() => onUpdate());
  };

  const onLeave = () => {
    stopFollowingBdRequest(request.id).then(() => onUpdate());
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary rounded-lg p-1 px-2 d-flex flex-row align-items-center justify-content-center gap-5"
        onClick={toggle}
      >
        <div className="d-flex flex-row align-items-center">
          {participants.slice(0, 3).map((user, index) => (
            <div key={user.id} style={{ marginLeft: index ? index + 1 * -10 : 0 }}>
              <PersonAvatar
                firstName={user.firstName}
                lastName={user.lastName}
                size={22}
                avatar={user.avatarImage}
                noMargin
              />
            </div>
          ))}
        </div>
        <span className="normal-text semi-bold gray-500">{participants.length}</span>
      </button>
      <Modal isOpen={open} toggle={toggle} title="Conversation Members">
        <div className="d-flex flex-column gap-25">
          <div className="d-flex flex-column">
            <span className="large-text bold gray-700 mb-3">Partner Manager</span>
            {changeManager ? (
              <UserEditSearch
                existingUser={bdManager}
                onSave={(newAssignee) =>
                  updateBdRequestAssignee(id, newAssignee.id).then(() => onUpdate())
                }
                emptyExisting={<span className="unassigned-warning">(unassigned)</span>}
                canManagePartnerships
                defaultEditing
              />
            ) : (
              <div className="d-flex flex-row align-items-center gap-10">
                {bdManager ? (
                  <Person request={request} user={bdManager} />
                ) : (
                  <div className="flex-fill gray-400">
                    <FontAwesomeIcon icon="user" size="xl" />
                    <span className="ml-2">Not Assigned</span>
                  </div>
                )}
                <button
                  type="button"
                  className="btn btn-secondary text-primary"
                  onClick={() => setChangeManager(true)}
                >
                  Change
                </button>
              </div>
            )}
            {loggedInOrguser.canManage && loggedInOrguser.isCrossbeamAdmin && request.isUnassigned && (
              <div className="mt-3 py-2 rounded px-3 bg-light-blue d-flex flex-row align-items-center gap-5">
                <div className="flex-fill d-flex flex-column gap-5">
                  <span className="large-text bold gray-700">
                    No partner manager assigned to this partner
                  </span>
                  <span className="normal-text semi-bold gray-600">
                    Go to partner&apos;s page and assign a partner manager.
                  </span>
                </div>
                <Link
                  to={`/p/${request.partner.slug}`}
                  className="normal-text bold blue text-nowrap"
                >
                  Assign
                  <FontAwesomeIcon icon="external-link" className="ml-2" />
                </Link>
              </div>
            )}
          </div>
          <div className="d-flex flex-column">
            <span className="large-text bold gray-700 mb-3">Members</span>
            <div className="d-flex flex-row align-items-center gap-10 w-100">
              <UserSearch
                onChange={(item) => setAddParticipant(item ? item.id : null)}
                placeholder="🔎  Add a participant..."
                changeOnClear
                className="flex-fill"
              />
              <button
                disabled={!addParticipant || addParticipant === ''}
                className="btn btn-primary btn-sm"
                onClick={add}
                type="button"
              >
                Add
              </button>
            </div>
            {canJoin && (
              <button
                type="button"
                onClick={onJoin}
                className="mt-2 btn btn-link d-flex flex-row align-items-center gap-10 normal-text bold blue"
              >
                <FontAwesomeIcon icon="plus" />
                Join the Conversation
              </button>
            )}
            <div className="mt-3 d-flex flex-column gap-20">
              {participants.map((user) => (
                <Person
                  request={request}
                  user={user}
                  onLeave={onLeave}
                  loggedInOrguser={loggedInOrguser}
                  key={user.id}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withOrguser(Participants);
