// @flow

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Org, OrgUser, User } from 'data/entities';

import PartnerList from 'views/PartnerList';

import NetworkList from './NetworkList';
import Sidebar from './Sidebar';

type Props = {
  orguser: ?OrgUser,
  org: ?Org,
  user: ?User,
};

const Network = (props: Props) => (
  <div className="w-100 d-flex flex-row page-top-padding mb-5 gap-30">
    <Sidebar />
    <div className="flex-1">
      <Switch>
        <Route path={['/network', '/network/']} exact render={() => <NetworkList {...props} />} />
        <Route path="/p" component={() => <Redirect to="/network/p" />} />
        <Route path="/network/p" render={() => <PartnerList {...props} />} />
      </Switch>
    </div>
  </div>
);

export default Network;
