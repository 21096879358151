// @flow

import React, { useEffect, useState } from 'react';
import { Link, useParams as useRouterParams } from 'react-router-dom';
import { UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContactConnection, getDealStatusComponent } from 'data/entities/contact';
import { fetchContactConnections } from 'data/repositories/contact';

import LastActivity from 'components/LastActivity';
import LoadingRing from 'components/LoadingRing';
import Messaged from 'components/Messaged';
import NewBdRequestButton from 'components/NewBdRequestButton';
import PersonAvatar from 'components/PersonAvatar';
import Pill from 'components/Pill';
import Signals from 'components/Signals';

const Connections = () => {
  const { accountSlug, email }: { [key: string]: string } = useRouterParams();

  const [loadingConnections, setLoadingConnections] = useState(true);
  const [connections, setConnections] = useState<ContactConnection[]>([]);
  useEffect(() => {
    setConnections([]);
    setLoadingConnections(true);
    fetchContactConnections(accountSlug, email)
      .then((c) => {
        setLoadingConnections(false);
        setConnections(c.results);
      })
      .catch((err) => {
        setLoadingConnections(false);
      });
  }, [accountSlug, email]);

  return (
    <div className="card card-body p-0">
      <table className="custom-table">
        <thead className="custom-table-header small-text bold gray-600">
          <tr>
            <td className="custom-table-cell">DEAL OWNER</td>
            <td className="custom-table-cell">DEAL STATUS</td>
            <td className="custom-table-cell">LAST ACTIVITY</td>
            <td className="custom-table-cell">DEAL TYPES</td>
            <td className="custom-table-cell">DEAL ROLES</td>
            <td className="custom-table-cell">ACCOUNT NAME</td>
          </tr>
        </thead>
        <tbody>
          {!loadingConnections ? (
            connections &&
            connections.map((connection: ContactConnection) => {
              const {
                isPrimary,
                roles,
                lastActivity,
                opportunityTypes,
                dealStatus,
                owner,
                accounts,
                signals,
              } = connection;
              const tooltipTarget = `message-connection-${owner.id}`;

              return (
                <tr key={owner.id} className="custom-table-row">
                  <td className="custom-table-cell d-flex flex-row align-items-center gap-20">
                    {accounts.length === 1 ? (
                      <NewBdRequestButton
                        accountSlug={accountSlug}
                        partnerAccountId={accounts[0].id}
                        partnerSlug={accounts[0].partnerSlug}
                        toOrgUser={owner.id}
                        compact
                      />
                    ) : (
                      <>
                        <button
                          id={tooltipTarget}
                          type="submit"
                          className="btn btn-primary btn-sm px-2"
                        >
                          <FontAwesomeIcon icon="comment-dots" size="lg" />
                        </button>
                        <UncontrolledPopover
                          className="PopoverCustom"
                          innerClassName="card-body px-3"
                          placement="bottom"
                          target={tooltipTarget}
                          trigger="click"
                          delay={{ show: 100, hide: 200 }}
                        >
                          <div className="d-flex flex-column gap-20">
                            <span className="large-text bold gray-700">
                              Choose a partner account:
                            </span>
                            {accounts.map((account) => (
                              <div className="d-flex flex-row align-items-center gap-10">
                                <NewBdRequestButton
                                  accountSlug={accountSlug}
                                  partnerAccountId={account.id}
                                  partnerSlug={account.partnerSlug}
                                  toOrgUser={owner.id}
                                  compact
                                />
                                <span className="larget-text bold gray-700 text-truncate">
                                  {account.name}
                                </span>
                                <Messaged
                                  messaged={account.messaged}
                                  accountSlug={accountSlug}
                                  partnerSlug={account.partnerSlug}
                                />
                              </div>
                            ))}
                          </div>
                        </UncontrolledPopover>
                      </>
                    )}
                    <div className="d-flex flex-row align-items-center gap-10">
                      <PersonAvatar
                        firstName={owner.firstName}
                        lastName={owner.lastName}
                        avatar={owner.avatarImage}
                        org={owner.partnerOrg}
                        size={48}
                        noMargin
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row align-items-center gap-5">
                          <Link
                            to={`/network/oid/${owner.id}`}
                            className="bold large-text text-black"
                          >
                            {owner.name}
                          </Link>
                          <Messaged
                            messaged={accounts.length === 1 && accounts[0].messaged}
                            accountSlug={accountSlug}
                            partnerSlug={accounts[0].partnerSlug}
                          />
                        </div>
                        <span className="normal-text semi-bold gray-500">{owner.title}</span>
                        <Signals signals={signals} compact />
                      </div>
                    </div>
                  </td>
                  <td className="custom-table-cell">{getDealStatusComponent(dealStatus)}</td>
                  <td className="custom-table-cell">
                    <LastActivity lastActiveAt={lastActivity} />
                  </td>
                  <td className="custom-table-cell gray-500">{opportunityTypes.join(', ')}</td>
                  <td
                    className={`custom-table-cell ${isPrimary ? 'd-inline-flex flex-column' : ''}`}
                  >
                    {isPrimary && (
                      <Pill
                        label="Primary Contact"
                        icon={<FontAwesomeIcon icon="user-friends" />}
                        color="pink"
                      />
                    )}
                    <span className="gray-500">{roles.join(', ')}</span>
                  </td>
                  <td className="custom-table-cell gray-500">
                    {accounts.map((a) => a.name).join(', ')}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">
                <LoadingRing className="m-4" maxWidth="50px" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Connections;
