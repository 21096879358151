/* eslint-disable max-classes-per-file */
// @flow

export type Signal = {
  key: string,
  label: string,
  color: string,
  colorRaw: string,
  icon: string,
  contactExclusive?: ?boolean,
  accountsExclusive?: ?boolean,
};

export const signalDefinitions: { [key: string]: Signal } = {
  recentlyWon: {
    key: 'recently_won',
    label: 'Recently Won',
    color: 'blue',
    colorRaw: '#4D85F0',
    icon: 'crown',
  },
  closedDeal: {
    key: 'closed_deal',
    label: 'Closed Deal',
    color: 'yellow',
    colorRaw: '#f6d612',
    icon: 'star',
    contactExclusive: true,
  },
  primary: {
    key: 'primary',
    label: 'Primary Contact',
    color: 'pink',
    colorRaw: '#de51b7',
    icon: 'user-group',
    contactExclusive: true,
  },
  recentStageMovement: {
    key: 'recent_stage_movement',
    label: 'Deal Movement',
    color: 'green',
    colorRaw: '#29a244',
    icon: 'arrow-up',
  },
  newDeals: {
    key: 'new_deals',
    label: 'New Deals',
    color: 'orange',
    colorRaw: '#ff981f',
    icon: 'dollar-sign',
  },
  recentActivity: {
    key: 'recent_activity',
    label: 'Recent Activity',
    color: 'cyan',
    colorRaw: '#56bac8',
    icon: 'bolt',
  },
  newContacts: {
    key: 'new_contacts',
    label: 'New Contacts',
    color: 'purple',
    colorRaw: '#7348E9',
    icon: 'plus',
  },
};

export const getSignal = (key: $Keys<typeof signalDefinitions>): Signal => signalDefinitions[key];

class SharedSignals {
  recentlyWon: number | boolean;

  recentStageMovement: number | boolean;

  newDeals: number | boolean;

  recentActivity: number | boolean;

  newContacts: number | boolean;
}

export class AccountSignals extends SharedSignals {
  static fromApi = (d: any): AccountSignals => {
    const s = new AccountSignals();
    s.recentlyWon = d.recently_won;
    s.recentStageMovement = d.recent_stage_movement;
    s.newDeals = d.new_deals;
    s.recentActivity = d.recent_activity;
    s.newContacts = d.new_contacts;
    return s;
  };
}

export class ContactSignals extends SharedSignals {
  closedDeal: number;

  primary: number;

  static fromApi = (d: any): ContactSignals => {
    const s = new ContactSignals();
    s.recentlyWon = d.recently_won;
    s.closedDeal = d.closed_deal;
    s.primary = d.primary;
    s.recentStageMovement = d.recent_stage_movement;
    s.newDeals = d.new_deals;
    s.recentActivity = d.recent_activity;
    s.newContacts = d.new_contacts;
    return s;
  };
}
