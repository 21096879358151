// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

type FilterProps = {
  label: string,
  icon: string,
  active: boolean,
  onClick: () => void,
};

const Filter = ({ label, icon, active, onClick }: FilterProps) => (
  <div
    className={`partners-filter d-flex flex-row align-items-center gap-10 ${
      active ? 'bg-primary-lighter' : ''
    }`}
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex="0"
    role="button"
  >
    <FontAwesomeIcon icon={icon} className={active ? 'primary' : 'gray-400'} />
    <p className={`mb-0 bold normal-text flex-fill ${active ? 'text-primary' : 'gray-500'}`}>
      {label}
    </p>
  </div>
);

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const ALL = 'all';
const STARRED = 'starred';
const MY_LISTS = 'own';

const Sidebar = ({ loggedInOrg, loggedInOrguser: { canManage, role, capabilities } }: Props) => {
  const history = useHistory();
  const [{ type }, setParams] = useParams({ type: ALL });

  const setType = (t: string) => {
    if (history.location.pathname === '/lists') {
      setParams({ type: t });
    } else {
      history.push(`/lists?type=${t}`);
    }
  };

  return (
    <div className="col-3 page-top-margin">
      <StickyBox offsetTop={40} offsetBottom={20}>
        <div
          className={`d-flex flex-column gap-15 ${
            !capabilities.canAccessSeatedExperience ||
            !loggedInOrg.isIntegrationSetup ||
            capabilities.viewOnly
              ? 'seatless-overlay'
              : ''
          }`}
        >
          <div className="mb-1 d-flex flex-row gap-10">
            <span className="bold large-text gray-700 flex-fill">Lists</span>
          </div>
          <Filter
            icon="list"
            label="All lists"
            onClick={() => setType(ALL)}
            active={type === ALL}
          />
          <Filter
            icon="user-friends"
            label="My lists"
            onClick={() => setType(MY_LISTS)}
            active={type === MY_LISTS}
          />
          <Filter
            icon="star"
            label="Starred"
            onClick={() => setType(STARRED)}
            active={type === STARRED}
          />
        </div>
      </StickyBox>
    </div>
  );
};

export default withOrguser(Sidebar);
