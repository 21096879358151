// @flow

import React, { useState } from 'react';
import { Button, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, OrgUser } from 'data/entities';
import { approveBdRequest } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';

type Props = {
  loggedInOrguser: OrgUser,
  request: BdRequest,
  onUpdate: (approved: boolean) => void,
  mini?: boolean,
};

const ApproveConversation = ({
  loggedInOrguser: { capabilities },
  request,
  onUpdate,
  mini,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [open, setOpen] = useState(false);
  const [denyReason, setDenyReason] = useState('');

  const toggle = () => setOpen((o) => !o);

  const onApprove = (approved: boolean): void => {
    setSubmitting(true);
    setErrors(null);
    approveBdRequest(request.id, approved, denyReason)
      .then(() => {
        setSubmitting(false);
        setErrors(null);
        onUpdate(approved);
        setOpen(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors(error.json);
        setOpen(false);
      });
  };

  const id = `approve-conversation-${request.id}`;

  return (
    <>
      <FieldErrors errors={errors && errors.nonFieldErrors} />
      <div
        className={`${
          mini ? 'py-1 m-1' : 'py-3'
        } rounded px-3 bg-light-blue d-flex flex-row align-items-center gap-10`}
      >
        <FontAwesomeIcon icon="info-circle" className="blue" size={mini ? 'lg' : '2x'} />
        <div className="flex-fill d-flex flex-column gap-5">
          {mini ? (
            <span className="small-text bold gray-700">
              {capabilities.canManagePartnerships ? (
                <>
                  <b>APPROVE</b>
                  {` this conversation if you want ${request.createdBy.fullName} to directly contact ${request.partner?.name}.`}
                </>
              ) : (
                <>
                  <b>WAITING APPROVAL</b>
                  {' from your partner manager.'}
                </>
              )}
            </span>
          ) : (
            <>
              <span className="large-text bold gray-700">
                This conversation is pending partner manager approval.
              </span>
              <span className="normal-text semi-bold gray-600">
                Once approved, it will be shared and {request.partner.name} will be added to this
                conversation.
              </span>
            </>
          )}
        </div>
        {capabilities.canManagePartnerships &&
          (submitting ? (
            <LoadingRing maxWidth="20px" />
          ) : (
            <>
              <Button
                id={id}
                color="primary"
                size={mini ? 'xs' : 'md'}
                onClick={() => !mini && onApprove(true)}
              >
                Approve
              </Button>
              {mini && (
                <UncontrolledPopover
                  className="PopoverCustom previous-conversations-popover"
                  innerClassName="card-body px-3"
                  target={id}
                  trigger="click"
                  delay={{ show: 100, hide: 200 }}
                >
                  <div className="d-flex flex-column gap-15" style={{ maxWidth: 250 }}>
                    <span className="normal-text bold gray-700">
                      Share this conversation with your partner. They will see existing public
                      messages.
                    </span>
                    <div>
                      <Button color="primary" onClick={() => onApprove(true)}>
                        Confirm
                      </Button>
                    </div>
                  </div>
                </UncontrolledPopover>
              )}
              <Button color="secondary" size={mini ? 'xs' : 'md'} onClick={toggle}>
                Reject
              </Button>
            </>
          ))}
      </div>
      <Modal
        isOpen={open}
        toggle={toggle}
        title="Reject direct shared conversation"
        footer={
          submitting ? (
            <LoadingRing maxWidth="30px" />
          ) : (
            <>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
              <Button color="primary" onClick={() => onApprove(false)}>
                Reject
              </Button>
            </>
          )
        }
        centered
      >
        <span className="normal-text semi-bold gray-700 mb-2">Reason</span>
        <textarea
          value={denyReason}
          onChange={(e) => {
            setDenyReason(e.target.value);
          }}
          rows="3"
          placeholder="Type your message here..."
          className="form-control"
          name="reason"
        />
      </Modal>
    </>
  );
};

ApproveConversation.defaultProps = {
  mini: false,
};

export default withOrguser(ApproveConversation);
