// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingScreen from 'components/LoadingScreen';
import PersonAvatar from 'components/PersonAvatar';

const classes = 'rounded bg-gray-100';

const FakeListCard = () => (
  <div className="card m-0">
    <div className="loading-animation card-body py-2 px-3 pr-4 d-flex flex-row align-items-center gap-40">
      <div className="flex-fill d-flex flex-column gap-10">
        <div className={classes} style={{ width: 150, height: 22 }} />
        <div className={classes} style={{ width: 300, height: 18 }} />
      </div>
      <div className="d-flex flex-row align-items-center gap-10">
        <PersonAvatar size={28} className="align-self-center" />
        <div className={classes} style={{ width: 100, height: 16 }} />
      </div>
      <FontAwesomeIcon icon="star" className="gray-300" />
    </div>
  </div>
);

const Loading = () => (
  <LoadingScreen showIndicator={false}>
    {Array.from({ length: 10 }, (x, i) => i).map((index) => (
      <FakeListCard key={index} />
    ))}
  </LoadingScreen>
);
export default Loading;
