// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash';

import { Account } from 'data/entities';
import { AccountPartnerList } from 'data/entities/account';
import { getStatusStr } from 'data/entities/status';
import {
  AccountDetailAccountPartner,
  fetchAccountPartnersByAccountSlug,
} from 'data/repositories/accounts';

import { PartnerListDetail } from 'views/AccountList/AccountRow';
import AccountPartnerCard from 'views/RequestAccountDetail/AccountPartnerCard';
import AccountStatusAlertTooltip from 'components/AccountStatusAlertTooltip';
import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';
import Pill from 'components/Pill';
import UnlockBanner from 'components/SeatlessExperience/UnlockBanner';

type Props = {
  statusKey: string,
  sortedPartners: AccountPartnerList[],
  account: Account,
  isOpen: boolean,
  toggle: () => void,
};

const PartnersModal = ({ statusKey, sortedPartners, account, isOpen, toggle }: Props) => {
  const [accountPartners, setAccountPartners] = useState<AccountDetailAccountPartner[]>([]);
  const groupedPartners = useMemo(() => {
    const grouped = {};
    accountPartners
      .sort((a, b) => a.partnerAccount.name.localeCompare(b.partnerAccount.name))
      .forEach((ap) => {
        const { partner } = ap;
        if (!grouped[partner.slug]) {
          grouped[partner.slug] = [ap];
        } else {
          grouped[partner.slug].push(ap);
        }
      });
    return Object.values(grouped);
  }, [accountPartners]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setAccountPartners([]);
      setLoading(true);
      setHasMore(false);
      fetchAccountPartnersByAccountSlug(account.slug, {
        offset: 0,
        limit: 50,
        partnerAccountStatus: statusKey,
      })
        .then((results) => {
          setAccountPartners(results.results);
          setLoading(false);
          setHasMore(!!results.next);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [isOpen, statusKey, account.slug]);

  const byPartner = groupBy(sortedPartners, 'partner.slug');
  const partners = Object.keys(byPartner);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      width={800}
      title={
        <div className="d-flex flex-row align-items-center gap-15">
          <CompanyLogo className="" size={60} name={account.name} domain={account.website} />
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center gap-5">
              <Link
                to={`/a/${account.slug}`}
                className="large-text bold text-black text-truncate btn-link"
              >
                {account.name}
              </Link>
              <AccountStatusAlertTooltip account={account} withExpires />
              <div>{account.status}</div>
              {account.isTarget && (
                <Pill
                  icon={<FontAwesomeIcon icon="bullseye" width="14" height="14" />}
                  color="gray"
                  label={account.isTargetValue}
                />
              )}
            </div>
            <div className="d-flex flex-row align-items-center gap-5">
              <div className="d-flex flex-row align-items-center">
                {partners.slice(0, 3).map((partnerSlug, index) => (
                  <PartnerListDetail
                    key={partnerSlug}
                    style={index !== 0 ? { marginLeft: '-15px' } : {}}
                    accountPartners={byPartner[partnerSlug]}
                    accountSlug={account.slug}
                    disablePopover
                  />
                ))}
              </div>
              <span className="small-text semi-bold gray-500 ">{`${
                partners.length
              } Partner ${getStatusStr(statusKey, false, partners.length !== 1)}`}</span>
            </div>
          </div>
        </div>
      }
    >
      <div className="d-flex flex-column gap-20">
        <UnlockBanner />
        {loading ? (
          <LoadingRing className="m-5" maxWidth="50px" />
        ) : (
          <div className="d-flex flex-column gap-20 align-items-center">
            {groupedPartners.map((data) => (
              <AccountPartnerCard
                key={data[0].id}
                isLoaded
                noShadow
                account={account}
                accountPartners={data}
                style={{ width: '100%' }}
                partnerDetailCard
              />
            ))}
            {hasMore && (
              <Link to={`/a/${account.slug}`} className="large-text bold btn btn-link text-primary">
                View more
              </Link>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PartnersModal;
