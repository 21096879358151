// @flow

import React, { useEffect, useState } from 'react';
import { Link, useParams as useRouterParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account } from 'data/entities';
import { Contact } from 'data/entities/contact';
import { CONTACT_DETAIL, COPY_CONTACT_EMAIL } from 'data/entities/tracking';
import { fetchAccountBySlug } from 'data/repositories/accounts';
import { fetchContact } from 'data/repositories/contact';
import { trackAction } from 'data/repositories/tracking';

import CompanyLogo from 'components/CompanyLogo';
import Copy from 'components/Copy';
import LoadingRing from 'components/LoadingRing';

import Connections from './Connections';

const ContactProfile = () => {
  const { accountSlug, email }: { [key: string]: string } = useRouterParams();

  // Load contact.
  const [loading, setLoading] = useState<boolean>(true);
  const [contact, setContact] = useState<Contact>(null);
  useEffect(() => {
    setContact(null);
    setLoading(true);
    fetchContact(accountSlug, email)
      .then((c) => {
        setLoading(false);
        setContact(c);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [accountSlug, email]);

  // Load account.
  const [loadingAccount, setLoadingAccount] = useState<boolean>(true);
  const [account, setAccount] = useState<Account>(null);
  useEffect(() => {
    setAccount(null);
    setLoadingAccount(true);
    fetchAccountBySlug(accountSlug)
      .then((a) => {
        setLoadingAccount(false);
        setAccount(a);
      })
      .catch((err) => {
        setLoadingAccount(false);
      });
  }, [accountSlug]);

  return (
    <div className="w-100 d-flex flex-column mt-4 gap-30" style={{ padding: '0px 15px' }}>
      <Link to={`/a/${accountSlug}/contacts`}>
        <div className="d-flex flex-row align-items-center gap-10">
          <FontAwesomeIcon className="gray-600" icon="arrow-left" />
          {loadingAccount || !account ? (
            <LoadingRing maxWidth="20px" />
          ) : (
            <CompanyLogo className="m-0" size={20} name={account.name} domain={account.website} />
          )}
          {account && (
            <span className="bold normal-text gray-700">{`${account.name} Contacts`}</span>
          )}
        </div>
      </Link>
      {loading || loadingAccount ? (
        <LoadingRing className="m-5" />
      ) : (
        <>
          {contact && account ? (
            <>
              <div className="d-flex flex-row align-items-center gap-20">
                <CompanyLogo size={60} name={account.name} domain={account.website} className="" />
                <div className="d-flex flex-column flex-fill">
                  <div className="d-flex flex-row align-items-end gap-10">
                    <h4 className="m-0 bold gray-700">{contact.fullNames[0]}</h4>
                    {/* Hiding this for now, as it doesn't relay accurate results */}
                    {false && (
                      <span className="semi-bold normal-text gray-400">
                        {contact.inContacts ? 'In your contacts' : 'Not in your contacts'}
                      </span>
                    )}
                    <a
                      href={`https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
                        `${contact.fullNames[0]} ${account.name}`
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="m-1"
                    >
                      <FontAwesomeIcon icon={['fab', 'linkedin']} color="#2867B2" />
                    </a>
                  </div>
                  <span className="normal-text semi-bold gray-500">{contact.titles[0]}</span>
                  <Copy
                    text={contact.email}
                    title={contact.email}
                    onCopied={() =>
                      trackAction(COPY_CONTACT_EMAIL, {
                        accountDomain: account.website,
                        apiSource: CONTACT_DETAIL,
                      })
                    }
                  />
                </div>
              </div>
              <div className="d-flex flex-column gap-20">
                <h5 className="m-0 bold gray-700">Your connections to {contact.fullNames[0]}:</h5>
                <Connections />
              </div>
            </>
          ) : (
            <div className="m-5 text-center">
              <h6>This contact is not available</h6>
              <p className="mb-3 normal-text semi-bold gray-500">
                The link may be broken, or the contact may have been removed.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContactProfile;
