// @flow
import * as React from 'react';
import { get, isEqual, map } from 'lodash';

import { BdRequest } from 'data/entities';
import { attributionChoices, attributionDisplay } from 'data/entities/bdrequest';
import { fetchAttributionOptions } from 'data/repositories/bdrequests';

type Props = {
  request: BdRequest,
  onChange: (value?: string) => {},
  disabled?: boolean,
};

type State = {
  loading: boolean,
  currentValue: string,
  options: string[],
};

class RequestAttributionSelection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      options: [],
      currentValue: '',
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(this.props.request, prevProps.request) ||
      this.props.disabled !== prevProps.disabled
    ) {
      this.loadOptions();
    }
  }

  loadOptions = () => {
    const { request, onChange, disabled } = this.props;
    if (disabled) {
      return;
    }
    if (request && request.opportunity && request.opportunity.id) {
      this.setState({ loading: true });
      fetchAttributionOptions(request.opportunity.id).then((response) => {
        const currentValue = get(request, 'attribution.attributeAs');
        if (currentValue && !response.includes(currentValue)) {
          response.push(currentValue);
        }
        onChange(currentValue);
        this.setState({
          options: response,
          loading: false,
          currentValue: currentValue || attributionChoices.NO_ATTRIBUTION,
        });
      });
    } else {
      this.setState({ options: [], currentValue: attributionChoices.NO_ATTRIBUTION });
    }
  };

  onChange = (event: Event) => {
    const { onChange } = this.props;
    let { value } = event.target;
    this.setState({ currentValue: value });
    if (value === attributionChoices.NO_ATTRIBUTION) {
      value = null;
    }
    onChange(value);
  };

  render() {
    const { loading, options, currentValue } = this.state;
    const { disabled } = this.props;
    if (loading) {
      return <p> Loading options...</p>;
    }
    if (disabled) {
      return (
        <select
          className="custom-select custom-select-md form-control full-width"
          name="attribution"
          disabled
        >
          <option>Select account, opportunity, and partner first</option>
        </select>
      );
    }
    return (
      <select
        className="custom-select custom-select-md form-control full-width"
        name="attribution"
        value={currentValue}
        onChange={this.onChange}
      >
        <option value={attributionChoices.NO_ATTRIBUTION}>
          {attributionDisplay[attributionChoices.NO_ATTRIBUTION]}
        </option>
        {map(options, (a) => (
          <option key={a} value={a}>
            {attributionDisplay[a]}
          </option>
        ))}
      </select>
    );
  }
}
RequestAttributionSelection.defaultProps = {
  disabled: false,
};

function RequestAttributionSelectionContainer(props) {
  return (
    <div className="d-flex flex-wrap" style={{ flex: '0 0 100%' }}>
      <div style={{ flex: '1 1 0%' }}>
        <RequestAttributionSelection {...props} />
      </div>
    </div>
  );
}

export default RequestAttributionSelectionContainer;
