// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';

import { BdRequest } from 'data/entities/bdrequest';
import OrgUser from 'data/entities/orguser';
import { BdRequestFilters, fetchBdRequests } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';

import RequestItem from './RequestItem';

type Props = {
  loggedInOrguser: OrgUser,
  onUpdate: () => void,
};

const getSearchParams = (search) =>
  search.length ? queryString.parse(search.slice(1, search.length)) : {};

const ActionRequiredSection = ({ loggedInOrguser, onUpdate }: Props) => {
  const location = useLocation();
  const [filters, setFilters] = useState<BdRequestFilters | null>(null);
  const [requests, setRequests] = useState<BdRequest[] | null>(null);
  const [loading, setLoading] = useState(true);

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll((prev) => !prev);

  const { pathname, search } = location;
  useEffect(() => {
    const status = pathname.split('/').pop();
    const searchParams = getSearchParams(search);
    if (loggedInOrguser && pathname && search) {
      setFilters(
        BdRequestFilters.fromUrlParams(loggedInOrguser, status, {
          ...searchParams,
          needsAction: true,
          offset: 0,
          limit: 100,
        })
      );
    }
  }, [pathname, search, loggedInOrguser]);

  const fetchRequests = useCallback(() => {
    if (!filters) return;

    setLoading(true);
    setRequests(null);
    fetchBdRequests(filters)
      .then((data) => {
        setRequests(data.results);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [filters]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const slicedRequests = useMemo(() => (requests ? requests.slice(0, 3) : []), [requests]);

  return (
    <div className="w-100 d-flex flex-column page-top-margin">
      <div className="d-flex flex-row align-items-center gap-20">
        <h4
          className={`m-0 ${
            showAll ? 'mb-3' : 'mb-1'
          } flex-fill bold gray-700 text-truncate disable-selection`}
        >
          Action Required
        </h4>
      </div>
      <div className="d-flex flex-column">
        {loading ? (
          <LoadingRing maxWidth="50px" className="m-5" />
        ) : (
          <>
            {requests && requests.length > 0 ? (
              <>
                {showAll ? (
                  requests.map((bdr, index) => (
                    <RequestItem
                      actionRequired
                      request={bdr}
                      key={`action-required-${bdr.id}`}
                      onUpdate={() => {
                        fetchRequests();
                        onUpdate();
                      }}
                    />
                  ))
                ) : (
                  <div
                    className="d-flex flex-col align-items-center justify-content-center"
                    style={{ position: 'relative', marginTop: 20 * slicedRequests.length }}
                  >
                    {slicedRequests.map((bdr, index) => (
                      <RequestItem
                        actionRequired
                        request={bdr}
                        key={`action-required-${bdr.id}`}
                        style={
                          index === 0
                            ? {
                                zIndex: 4,
                              }
                            : {
                                position: 'absolute',
                                bottom: 20 * index,
                                pointerEvents: 'none',
                                userSelect: 'none',
                                zIndex: slicedRequests.length + 1 - index,
                                width: `${100 - 3 * index}%`,
                              }
                        }
                        onUpdate={() => {
                          fetchRequests();
                          onUpdate();
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center gap-5 p-2">
                <span className="large-text bold gray-700">Great job!</span>
                <span className="normal-text semi-bold gray-600">
                  No messages require immediate action for now.
                </span>
              </div>
            )}
          </>
        )}
      </div>
      {requests && requests.length > 1 && (
        <div className="d-flex justify-content-center mt-2">
          <button className="btn btn-secondary" type="button" onClick={() => toggleShowAll()}>
            {showAll ? 'Show less' : `Show all (${requests.length})`}
          </button>
        </div>
      )}
    </div>
  );
};

export default withOrguser(ActionRequiredSection);
