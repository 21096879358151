/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// @flow

import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';

import { BdRequest, OrgUser } from 'data/entities';
import { attributionChoices, attributionDisplay, statusChoices } from 'data/entities/bdrequest';
import {
  fetchAttributionOptions,
  setBdRequestAttribution,
  updateBdRequestStatus,
} from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';

import Opportunity from './Opportunity';

type Props = {
  request: BdRequest,
  open: boolean,
  toggle: () => void,
  onUpdate: () => void,
  loggedInOrguser: OrgUser,
};

const CompletionModal = ({ request, open, toggle, onUpdate, loggedInOrguser }: Props) => {
  const { opportunity } = request;
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const lockMessage = request.attribution ? 'Cannot update this field on attributed request' : null;

  const [attribution, setAttribution] = useState(
    request.attribution?.attributeAs || attributionChoices.NO_ATTRIBUTION
  );
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open && opportunity && opportunity.id) {
      setLoading(true);
      fetchAttributionOptions(opportunity.id)
        .then((result) => {
          setLoading(false);
          if (request.attribution && request.attribution.attributeAs) {
            setOptions([...new Set([...result, request.attribution.attributeAs])]);
          } else {
            setOptions(result);
          }
          setAttribution(request.attribution?.attributeAs || result[0]);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setOptions([]);
      setAttribution(attributionChoices.NO_ATTRIBUTION);
    }
  }, [open, request.attribution, opportunity]);

  const canSetAttribution =
    (!request.attribution && (request.orguser && request.orguser.id) === loggedInOrguser.id) ||
    loggedInOrguser.canManage;

  const onMarkCompleted = (): void => {
    if (statusChoices.COMPLETED === request.status) {
      toggle();
    } else {
      setSubmitting(true);
      setErrors({});
      updateBdRequestStatus(request.id, statusChoices.COMPLETED)
        .then(() => {
          // Update attribution.
          if (canSetAttribution && attribution !== request.attribution?.attributeAs) {
            setBdRequestAttribution(
              request.id,
              attribution === attributionChoices.NO_ATTRIBUTION ? null : attribution
            )
              .then(() => {
                setSubmitting(false);
                setErrors({});
                toggle();
                onUpdate();
              })
              .catch((error) => {
                setSubmitting(false);
                setErrors(error.json);
              });
          } else {
            setSubmitting(false);
            setErrors({});
            toggle();
            onUpdate();
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors(error.json);
        });
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      title="Mark conversation as complete"
      footer={
        <>
          <Button disabled={submitting} color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button disabled={submitting} color="primary" onClick={onMarkCompleted}>
            Mark as complete
          </Button>
        </>
      }
      centered
    >
      <div className="d-flex flex-column gap-10">
        <span className="normal-text semi-bold gray-700">
          Which opportunity is connected to this conversation?
        </span>
        <Opportunity
          lockMessage={lockMessage}
          opportunity={request.opportunity}
          account={request.account}
          requestId={request.id}
          onUpdate={onUpdate}
        />
        <FieldErrors errors={errors && errors.opportunity} />
        <span className="mt-2 normal-text semi-bold gray-700">
          What partner deal attribution can be applied?
        </span>
        {loading ? (
          <LoadingRing maxWidth="20px" />
        ) : (
          <ButtonGroup>
            {Object.values(attributionChoices).map((choice) => {
              const enabled =
                options.includes(choice) || choice === attributionChoices.NO_ATTRIBUTION;
              const id = `attribution-${choice}`;
              return (
                <React.Fragment key={id}>
                  <div tabIndex={0} id={id} className="flex-fill">
                    <Button
                      color={choice === attribution ? 'primary' : 'secondary'}
                      outline
                      block
                      disabled={!enabled}
                      style={{ pointerEvents: !enabled && 'none' }}
                      onClick={() => setAttribution(choice)}
                    >
                      {attributionDisplay[choice]}
                    </Button>
                  </div>
                  {!enabled && (
                    <UncontrolledTooltip
                      placement="bottom"
                      target={id}
                      delay={{ show: 50, hide: 50 }}
                    >
                      {!opportunity
                        ? 'Request needs an opportunity.'
                        : 'This opportunity has already been sourced.'}
                    </UncontrolledTooltip>
                  )}
                </React.Fragment>
              );
            })}
          </ButtonGroup>
        )}
        <FieldErrors errors={errors && errors.attributeAs} />
      </div>
    </Modal>
  );
};

export default withOrguser(CompletionModal);
