// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Partner } from 'data/entities';

import LogoAvatar from 'components/LogoAvatar';
import RightArrow from 'assets/img/RightArrow';

type Props = {
  partners: Partner[],
  partnerSlug: string,
};

export default function PartnerSelector(props: Props) {
  const { partners, partnerSlug } = props;
  const getClassName = (partnerItemSlug, allRequests) => {
    if (allRequests) {
      return !partnerSlug ? 'PartnerSelector__btn PartnerSelector__active' : 'PartnerSelector__btn';
    }
    return partnerSlug && partnerItemSlug === partnerSlug
      ? 'PartnerSelector__btn PartnerSelector__active'
      : 'PartnerSelector__btn';
  };

  return (
    <div className="PartnerSelector">
      <div className="mb-4 text-center fs-13 mt-1">
        <Link to="/admin/account-mapping/exclude-accounts">
          <FontAwesomeIcon icon="eye-slash" /> Account Mapping Exclusions{' '}
        </Link>
      </div>
      <ul className="no-style PartnerSelector__menu">
        <li className={getClassName(null, true)}>
          <Link to="/admin/account-mapping">
            <img
              src="/assets/img/icons/icon-inbox-download-blue.svg"
              alt=""
              height="12"
              style={{ marginTop: '-3px', marginRight: '5px' }}
            />{' '}
            All Requests <RightArrow />
          </Link>
        </li>
        <hr />
        {partners.map(
          ({ pendingRequestsCount, slug, partnerOrg, mappingUrl, settings }): Partner => (
            <li key={slug} className={getClassName(slug)}>
              <Link to={mappingUrl}>
                {Boolean(settings && !settings.enableAccountMapping) && (
                  <FontAwesomeIcon icon="ban" />
                )}{' '}
                {partnerOrg.name} <LogoAvatar org={partnerOrg} partner />{' '}
                {Boolean(pendingRequestsCount) && (
                  <span className="badge badge-important pendingRequestCount">
                    {pendingRequestsCount}
                  </span>
                )}
                <RightArrow hasRequestCount={Boolean(pendingRequestsCount)} />
              </Link>
            </li>
          )
        )}
      </ul>
    </div>
  );
}
