// @flow

import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { debounce, isNil, trim } from 'lodash';

import { Account, Org, OrgUser } from 'data/entities';
import { RELEVANCE_ALL, RELEVANCE_SALES_PERSON } from 'data/entities/account';
import { CUSTOMER, PROSPECT } from 'data/entities/status';
import { fetchAccounts } from 'data/repositories/accounts';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

import InfiniteList from 'components/InfiniteList';
import { PremiumBadge, UpgradeBanner, UpgradeOverlay } from 'components/SeatlessExperience';
import FinishSetupOverlay from 'components/SeatlessExperience/FinishSetupOverlay';

import AccountCard from './AccountCard';
import Filters from './Filters';
import Loading from './Loading';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const DealNavigator = ({
  loggedInOrguser: { capabilities },
  loggedInOrg: { isIntegrationSetup },
}: Props) => {
  const [currentParams, setParams] = useParams({});
  const {
    search,
    relevance,
    status: statusRaw,
    owner,
    segment: segmentRaw,
    partnerStatus: partnerStatusRaw,
    signal: signalRaw,
    partner: partnerRaw,
    noPartnerActivityIn,
    hasContacts,
    isTarget,
    isTargetValue,
  } = currentParams;
  // Keep inner search state, so we can reset it if the search parameter changes.
  const searchRef = useRef(null);
  const [lastFilters, setLastFilters] = useState(currentParams);
  const [globalInvitedAOs, setGlobalInvitedAOs] = useState([]);

  useEffect(() => {
    if (searchRef.current) searchRef.current.value = search || '';
  }, [search]);
  useEffect(() => {
    if (currentParams.search) {
      setLastFilters({
        partnerStatus: CUSTOMER,
        status: PROSPECT,
        relevance: RELEVANCE_SALES_PERSON,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSearch = debounce((value: string) => {
    const searchValue = trim(value, ',&?');
    if (searchValue) {
      if (!currentParams.search) {
        setLastFilters(currentParams);
      }
      setParams({ search: searchValue, relevance: RELEVANCE_ALL }, true);
    } else {
      setParams(lastFilters, true);
    }
  }, 500);

  const [accounts, setAccounts] = useState([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [loading, setLoading] = useState(
    capabilities.canAccessSeatedExperience && isIntegrationSetup
  );

  // Arrays don't work as dependencies for useEffect, so we stringify it.
  const status = statusRaw ? [].concat(statusRaw) : [];
  const statusDependency = JSON.stringify(status);
  const segment = segmentRaw ? [].concat(segmentRaw) : [];
  const segmentDependency = JSON.stringify(segment);
  const partnerStatus = partnerStatusRaw ? [].concat(partnerStatusRaw) : [];
  const partnerStatusDependency = JSON.stringify(partnerStatus);
  const signal = signalRaw ? [].concat(signalRaw) : [];
  const signalDependency = JSON.stringify(signal);
  const partner = partnerRaw ? [].concat(partnerRaw) : [];
  const partnerDependency = JSON.stringify(partner);
  useEffect(() => {
    if (!capabilities.canAccessSeatedExperience || !isIntegrationSetup) {
      return;
    }
    setAccounts([]);
    setLoading(true);
    setLoadedAll(false);
    fetchAccounts({
      search,
      relevance,
      status,
      owner,
      segment,
      partnerStatus,
      partner,
      noPartnerActivityIn,
      signal,
      isTarget,
      isTargetValue,
      hasContacts,
      offset: 0,
      limit: 50,
    })
      .then((results) => {
        setAccounts(results.results);
        if (isNil(results.next)) {
          setLoadedAll(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    relevance,
    statusDependency,
    owner,
    segmentDependency,
    partnerStatusDependency,
    signalDependency,
    partnerDependency,
    noPartnerActivityIn,
    hasContacts,
    isTarget,
    isTargetValue,
  ]);

  const loadMoreRows = (offset, limit) => {
    if (offset === 0 || loadedAll) {
      return null;
    }
    return fetchAccounts({
      search,
      relevance,
      status,
      owner,
      segment,
      partnerStatus,
      partner,
      noPartnerActivityIn,
      signal,
      offset,
      limit,
    }).then((sp) => {
      setAccounts((s) => s.concat(sp.results));
      if (isNil(sp.next)) {
        setLoadedAll(true);
      }
    });
  };

  return (
    <div className="d-flex flex-row mb-5 gap-30">
      <Filters />
      <div className="flex-1">
        <div className="w-100 d-flex flex-column gap-30">
          <div
            className="w-100 sticky overflow-hidden bg-background-body d-flex flex-md-row flex-column justify-content-center gap-10 page-top-padding"
            style={{ top: '0px' }}
          >
            <div className="d-flex flex-row flex-fill justify-content-center gap-10">
              <div className="d-flex flex-row align-items-center gap-10 flex-fill">
                <h3 className="m-0">Deal Navigator</h3>
                <PremiumBadge />
              </div>
              {capabilities.canAccessSeatedExperience && isIntegrationSetup && (
                <Input
                  innerRef={searchRef}
                  style={{ maxWidth: '350px' }}
                  type="text"
                  name="search"
                  placeholder="🔎  Search accounts by name"
                  onChange={(e) => onSearch(e.target.value)}
                />
              )}
            </div>
          </div>
          <UpgradeBanner />
          {!capabilities.canAccessSeatedExperience || !isIntegrationSetup ? (
            <FinishSetupOverlay title="Map your partner populations to see overlaps">
              <UpgradeOverlay
                title="Get Crossbeam for Sales to unlock Deal Navigator"
                explanation="You have a free seat account. Upgrade to start working with your partners."
              >
                <AccountCard
                  isLoaded
                  style={{}}
                  account={Account.fromApi({
                    name: 'Dunder Mifflin',
                    display_deal_amount: '$200,000',
                    status: 'COLD',
                    accountowners: [{ first_name: 'Michael', last_name: 'Scott' }],
                    contacts_count: 13,
                  })}
                />
              </UpgradeOverlay>
            </FinishSetupOverlay>
          ) : (
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {!accounts.length ? (
                    <div className="text-center">
                      <h6>No overlapping accounts found.</h6>
                      {relevance !== RELEVANCE_ALL ? (
                        <p className="mb-3 normal-text semi-bold gray-500 d-flex align-items-center justify-content-center">
                          Currently filtered by “
                          {relevance === 'sales_person' ? 'My Accounts' : 'My Partners'}”.
                          <button
                            onClick={() => setParams({ relevance: RELEVANCE_ALL }, false)}
                            type="button"
                            className="btn btn-link m-0 mx-2 p-0 text-decoration-underline"
                          >
                            See All Accounts.
                          </button>
                        </p>
                      ) : (
                        <p className="mb-3 normal-text semi-bold gray-500">
                          Try adjusting the filters.
                        </p>
                      )}
                    </div>
                  ) : (
                    <InfiniteList
                      list={accounts}
                      loadMoreRows={loadMoreRows}
                      minHeight={78}
                      loadedAll={loadedAll}
                      render={({ key, index, style, data, isLoaded }) => (
                        <AccountCard
                          isLoaded={isLoaded}
                          style={style}
                          key={key}
                          account={data}
                          globalInvitedAOs={globalInvitedAOs}
                          setGlobalInvitedAOs={setGlobalInvitedAOs}
                        />
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withOrguser(DealNavigator);
