// @flow

import { isNil } from 'lodash';

type Types = number | boolean | string | null | typeof undefined;

type Option = [string, Types | Types[]];

/** Stringify parameters.
 *
 * Flatten arrays and stringify parameters.
 * @param {Option} options Parameters
 * @returns {string} Query parameters
 */
const stringifyOptions = (options: Option[]) => {
  // Filter out nil values, and if array filter out empty ones.
  const filteredOptions = options.filter(
    (option) => !isNil(option[1]) && (Array.isArray(option[1]) ? option[1].length : true)
  );
  if (filteredOptions.length === 0) return '';

  const mappedOptions = filteredOptions.map((option) => {
    if (Array.isArray(option[1])) {
      return option[1]
        .filter((o) => !isNil(o))
        .map((v) => `${option[0]}[]=${v}`)
        .join('&');
    }
    return `${option[0]}=${option[1]}`;
  });

  return `?${mappedOptions.join('&')}`;
};

export default stringifyOptions;
