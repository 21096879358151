// @flow
import * as React from 'react';

import ProfileForm from '../../components/ProfileForm';
import User from '../../data/entities/user';

type Props = {
  updateUser: (user: User) => void,
};

class CompleteProfileForm extends React.Component<Props> {
  render() {
    const { updateUser, org, setOrgUser } = this.props;
    return (
      <div>
        <ProfileForm updateUser={updateUser} org={org} setOrgUser={setOrgUser} />
      </div>
    );
  }
}

export default CompleteProfileForm;
