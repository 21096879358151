// @flow

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type NetworkProfile from 'data/entities/networkprofile';
import { fetchNetworkConnectionCount } from 'data/repositories/networkprofile';

import ProfileUserSettings from 'views/ProfileUserSettings';
import CompanyLogo from 'components/CompanyLogo';
import ConnectedPartnerBadge from 'components/ConnectedPartnerBadge';
import LoadingRing from 'components/LoadingRing';
import PersonAvatar from 'components/PersonAvatar';

type Props = {
  networkProfile: NetworkProfile,
  orgName: string,
  isOwnOrg: boolean,
  isOwnProfile: boolean,
};

const ProfileInfo = ({ networkProfile, orgName, isOwnOrg, isOwnProfile }: Props) => {
  const [loadingCount, setLoadingCount] = useState(true);
  const [connectionCount, setConnectionCount] = useState<number>(0);

  useEffect(() => {
    setLoadingCount(true);
    fetchNetworkConnectionCount(networkProfile.id).then((count) => {
      setLoadingCount(false);
      setConnectionCount(count);
    });
  }, [networkProfile.id]);

  return (
    <div className="col d-flex flex-column sticky gap-20 page-top-margin">
      <PersonAvatar
        firstName={networkProfile.firstName}
        lastName={networkProfile.lastName}
        size={240}
        avatar={networkProfile.avatarImage}
      />
      <div>
        <h4 className="m-0 bold gray-700">{networkProfile.fullName}</h4>
        {networkProfile.title && (
          <p className="mb-1 semi-bold large-text gray-600">{networkProfile.title}</p>
        )}
      </div>
      {isOwnProfile && <ProfileUserSettings />}
      <div className="d-flex flex-row align-items-center gap-10">
        <FontAwesomeIcon height="15px" icon="user-friends" color="#A0A6B1" />
        {loadingCount ? (
          <LoadingRing maxWidth="15px" />
        ) : (
          <p className="m-0 semi-bold large-text gray-700">{`${connectionCount} connections`}</p>
        )}
      </div>
      <p className="m-0 bold large-text gray-600">Organization</p>
      <table style={{ borderCollapse: 'separate', borderSpacing: '0px 5px' }}>
        <tbody>
          <tr className="v-align-top">
            <td className="pr-2">
              <CompanyLogo
                size={20}
                name={networkProfile.org.name}
                logoUrl={networkProfile.org.logoUrl}
                domain={networkProfile.org.domain}
                className=""
              />
            </td>
            <td width="100%">
              {isOwnOrg ? (
                <p className="mb-0 bold large-text gray-700">{networkProfile.org.name}</p>
              ) : (
                <Link to={`/p/${networkProfile.org.partnerSlug}`}>
                  <p className="mb-0 bold large-text gray-700">{networkProfile.org.name}</p>
                </Link>
              )}
            </td>
          </tr>
          {networkProfile.org.description && (
            <>
              <tr />
              <tr className="v-align-top">
                <td className="pr-2 text-center">
                  <FontAwesomeIcon height="15px" icon="align-left" color="#A0A6B1" />
                </td>
                <td>
                  <p className="mb-0 semi-bold normal-text gray-500">
                    {networkProfile.org.description}
                  </p>
                </td>
              </tr>
            </>
          )}
          {!isOwnOrg && (
            <>
              <tr />
              <tr className="v-align-top">
                <td className="pr-2">
                  {networkProfile.org.isConnected && <ConnectedPartnerBadge iconOnly />}
                </td>
                <td>
                  <p className="mb-0 semi-bold normal-text text-black">{`${
                    networkProfile.org.name
                  } and ${orgName} are ${
                    networkProfile.org.isConnected ? '' : 'not '
                  }connected.`}</p>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProfileInfo;
