// @flow

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { Org, OrgUser } from 'data/entities';
import { RequestWorkflowSummary } from 'data/entities/org';
import { getRequestWorkflowSummary } from 'data/repositories/orgs';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';
import UnlockBanner from 'components/SeatlessExperience/UnlockBanner';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const BdRequestQuestions = ({
  loggedInOrg: {
    hasSlackIntegration,
    isIntegrationSetup,
    settings: { requestsWorkflowSettings },
  },
  loggedInOrguser: { capabilities },
}: Props) => {
  const [summary, setSummary] = useState<RequestWorkflowSummary | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getRequestWorkflowSummary()
      .then((s) => {
        setSummary(s);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="card card-body py-4 d-flex flex-column gap-10">
      <div className="d-flex flex-row align-items-center gap-50">
        <div className="flex-fill d-flex flex-row align-items-center gap-15">
          <div
            className="rounded bg-white border-light p-2 d-flex align-items-center justify-content-center"
            style={{ width: 48, height: 48 }}
          >
            <FontAwesomeIcon icon="book" size="2x" className="gray-600" />
          </div>
          {loading || !summary ? (
            <LoadingRing maxWidth="30px" />
          ) : (
            <div className="d-flex flex-column">
              <span className="large-text bold gray-700">
                {`${summary.requestQuestionsCount} request question${
                  summary.requestQuestionsCount === 1 ? '' : 's'
                } and ${summary.talkingPointsCount} context question${
                  summary.talkingPointsCount === 1 ? '' : 's'
                } added`}
              </span>
              <span className="normal-text semi-bold gray-500">
                <FontAwesomeIcon icon="pencil" width="14" height="14" className="mr-2" />
                {isIntegrationSetup
                  ? `Last edited on ${moment(summary.lastChangeAt).format(
                      'MM/DD/YYYY [at] h:mm A'
                    )}`
                  : 'Not setup'}
              </span>
            </div>
          )}
        </div>
        {capabilities.viewOnly && (
          <UncontrolledTooltip target="link-to-edit-templates">
            Co-selling templates are only available with a Co-Seller seat.
          </UncontrolledTooltip>
        )}
        <div id="link-to-edit-templates">
          <Link
            to="/co-selling-templates"
            className={`btn btn-secondary${isIntegrationSetup ? '' : ' seatless-overlay'}`}
            style={{
              pointerEvents: capabilities.viewOnly ? 'none' : 'auto',
              opacity: capabilities.viewOnly ? 0.5 : 1,
            }}
          >
            Edit Templates
          </Link>
        </div>
      </div>
      {capabilities.viewOnly && <UnlockBanner mini />}
    </div>
  );
};

export default withOrguser(BdRequestQuestions);
