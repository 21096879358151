// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import { isProduction } from 'utils/environment';

type Props = {
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
  link?: boolean,
};

const UpgradeButton = ({ loggedInOrguser, loggedInOrg, link }: Props) => {
  const { capabilities } = loggedInOrguser;

  return capabilities.canManageBilling ? (
    <a
      href={isProduction ? 'https://app.crossbeam.com/billing' : 'https://app.cb.c7m.io/billing'}
      className={`btn btn-${link ? 'link orange' : 'primary'} px-3 py-1 text-nowrap`}
      type="button"
      style={{ zIndex: 1 }}
      target="_blank"
    >
      <FontAwesomeIcon icon={['fad', 'wand-magic-sparkles']} className="mr-2" />
      Upgrade
    </a>
  ) : (
    <span className="normal-text font-italic semi-bold gray-400">
      You must be an admin to upgrade
    </span>
  );
};

UpgradeButton.defaultProps = {
  link: false,
};

export default withOrguser(UpgradeButton);
