import * as React from 'react';

import { UserContext } from 'contexts';
import useInIframe from 'utils/useInIframe';

import BaseContainer from 'layouts/BaseLayout/BaseContainer';
import HeaderContainer from 'layouts/BaseLayout/HeaderContainer';
import HeaderLogo from 'layouts/BaseLayout/HeaderLogo';
import PrimaryLayoutFooter from 'layouts/PrimaryLayout/PrimaryLayoutFooter';
import UserMenu from 'layouts/PrimaryLayout/UserMenu';

import AppSwitcher from './PrimaryLayout/AppSwitcher';

const BaseHeader = () => {
  const isInIframe = useInIframe();

  if (isInIframe) {
    return null;
  }
  return (
    <HeaderContainer>
      <div className="d-flex align-items-center">
        <HeaderLogo />
      </div>
      <div className="d-flex align-items-center gap-20">
        <UserMenu />
        <UserContext.Consumer>
          {({ user }) => user && <div className="bg-gray-200" style={{ width: 1, height: 36 }} />}
        </UserContext.Consumer>
        <AppSwitcher />
      </div>
    </HeaderContainer>
  );
};

export default function BareLayout({ centered, afterPageContainer, children, style }) {
  const extraClasses = centered ? 'align-items-center d-flex flex-column my-5' : '';
  return (
    <BaseContainer
      header={<BaseHeader />}
      footer={<PrimaryLayoutFooter />}
      afterPageContainer={afterPageContainer}
    >
      <div className={`container ${extraClasses}`} style={style}>
        {children}
      </div>
    </BaseContainer>
  );
}
