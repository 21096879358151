// @flow

import * as React from 'react';
import { Collapse } from 'reactstrap';

import { Partner } from 'data/entities';

import PartnerAccountMapping from 'views/AccountMapping/PartnerAccountMapping';
import PartnerMappingRequests from 'views/AccountMapping/PartnerMappingRequests';
import UnmappedAccounts from 'views/AccountMapping/UnmappedAccounts';
import Unisearchal from 'components/Unisearchal';

type Props = {
  partner: Partner,
};

function AccountMappingResults(props: Props) {
  const {
    onChange,
    hasFilters,
    partners,
    filters,
    allRequestsView,
    partner,
    onApprove,
    onDecline,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen((s) => !s);
  const { partnerSlug, accountSlug } = filters || {};
  if (filters && filters.partnerSlug && !partner) {
    return <h3 className="text-center">Partner Not Found</h3>;
  }
  const PartnerAccountMappingComponent =
    partner && !partner.settings.isManualMapping ? UnmappedAccounts : PartnerAccountMapping;
  return (
    <div className="AccountMappingResults">
      {hasFilters && !partner.settings.isManualMapping && (
        <button type="button" onClick={toggle} className="btn btn-link">
          &raquo; Show lower confidence potential account mappings
        </button>
      )}
      <Collapse isOpen={isOpen || !hasFilters || partner.settings.isManualMapping}>
        {hasFilters && (
          <Unisearchal
            placeholder="Search for an account..."
            onChange={onChange}
            bsSize="lg"
            onClear={onChange}
            entityKind="account"
            className="my-3"
          />
        )}
        <PartnerMappingRequests
          partnerSlug={partnerSlug}
          accountSlug={accountSlug}
          allRequests={allRequestsView}
          partners={partners}
          partner={partner}
          onApprove={onApprove}
          onDecline={onDecline}
        />
        {hasFilters && (
          <PartnerAccountMappingComponent
            partnerSlug={partnerSlug}
            accountSlug={accountSlug}
            partner={partner}
          />
        )}
      </Collapse>
    </div>
  );
}

export default AccountMappingResults;
