// @flow

import React, { useEffect, useRef, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
} from 'reactstrap';

import { BdRequest, OrgUser } from 'data/entities';
import { attributionChoices, attributionDisplay } from 'data/entities/bdrequest';
import { fetchAttributionOptions, setBdRequestAttribution } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';

type Props = {
  request: BdRequest,
  onUpdate: () => void,
  loggedInOrguser: OrgUser,
};

const AttributionSwitch = ({ loggedInOrguser, request, onUpdate }: Props) => {
  const { attribution, opportunity } = request;
  const ref = useRef();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (opportunity && opportunity.id) {
      setLoading(true);
      fetchAttributionOptions(opportunity.id)
        .then((result) => {
          setLoading(false);
          if (attribution && attribution.attributeAs) {
            setOptions([
              ...new Set([...result, attribution.attributeAs, attributionChoices.NO_ATTRIBUTION]),
            ]);
          } else {
            setOptions(result);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [opportunity, attribution]);

  const setAttribution = (selectedAttribution: string) => {
    if (
      (attribution && attribution.attributeAs === selectedAttribution) ||
      (!attribution && !selectedAttribution)
    ) {
      return;
    }
    setBdRequestAttribution(
      request.id,
      selectedAttribution === attributionChoices.NO_ATTRIBUTION ? null : selectedAttribution
    ).then(() => onUpdate());
  };

  const canSetAttribution =
    (!attribution && (request.orguser && request.orguser.id) === loggedInOrguser.id) ||
    loggedInOrguser.canManage;

  let lockMessage = null;
  if (!canSetAttribution) {
    lockMessage = "Attribution can't be set";
  } else if (!opportunity) {
    lockMessage = 'Request needs an opportunity';
  } else if (!request.isCompleted) {
    lockMessage = 'Completed requests only';
  }

  if (!request.partner) {
    return null;
  }

  return loading ? (
    <LoadingRing maxWidth="20px" />
  ) : (
    <>
      <div ref={ref}>
        {options.length ? (
          <UncontrolledButtonDropdown a11y={false}>
            <DropdownToggle
              disabled={!!lockMessage}
              className="p-0 btn-link semi-bold gray-700"
              caret
            >
              {request.attribution?.attributeAsDisplay ||
                attributionDisplay[attributionChoices.NO_ATTRIBUTION]}
            </DropdownToggle>
            <DropdownMenu>
              {Object.values(attributionChoices).map((choice) => {
                const enabled =
                  options.includes(choice) || choice === attributionChoices.NO_ATTRIBUTION;
                const id = `attribution-${choice}`;
                return (
                  <DropdownItem
                    disabled={!enabled}
                    onClick={() => setAttribution(choice)}
                    value={choice}
                    key={id}
                  >
                    {attributionDisplay[choice]}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : (
          <span className="normal-text semi-bold gray-500">{lockMessage}</span>
        )}
      </div>
      {lockMessage && (
        <UncontrolledTooltip placement="bottom" target={ref} delay={{ show: 50, hide: 50 }}>
          {lockMessage}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default withOrguser(AttributionSwitch);
