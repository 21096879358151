// @flow

import * as React from 'react';

type Props = {
  header: React.Node,
  footer: React.Node,
  children: React.Node,
  className: string,
  afterPageContainer?: React.Node,
};

export default function BaseContainer({
  header,
  footer,
  afterPageContainer,
  children,
  className,
}: Props) {
  return (
    <div
      className={`BaseContainer fixed-header horizontal-menu horizontal-app-menu ${
        className || ''
      }`}
    >
      {header}
      <div className="page-container " id="page-container">
        {afterPageContainer}
        <div className="page-content-wrapper ">
          <div className="content ">{children}</div>
          {footer}
        </div>
      </div>
    </div>
  );
}

BaseContainer.defaultProps = {
  afterPageContainer: null,
};
