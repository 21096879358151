// @flow

import * as React from 'react';

import type { LocationT } from 'sharedTypes/reactRouter';

import BareLayout from 'layouts/BareLayout';

import SlackCodeValidation from './SlackCodeValidation';

type Props = {
  location: LocationT,
};

export default function SlackLogin({ location }: Props) {
  return (
    <BareLayout centered>
      <SlackCodeValidation location={location} />
    </BareLayout>
  );
}
