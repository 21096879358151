// @flow

import * as React from 'react';

import useInIframe from 'utils/useInIframe';

import BareLayout from 'layouts/BareLayout';
import LoadingRing from 'components/LoadingRing';

import Logo from './Logo';

type Props = {
  text: string,
};

const FullPageLoadingRing = ({ text }: Props) => {
  const isInIframe = useInIframe();

  return (
    <BareLayout centered>
      <div className="d-flex align-items-center" style={{ marginTop: '20vh' }}>
        <div>
          {!isInIframe && <Logo height={30} className="mx-auto d-block" />}
          <LoadingRing text={isInIframe ? '' : text} className="mt-3" />
        </div>
      </div>
    </BareLayout>
  );
};

export default FullPageLoadingRing;
