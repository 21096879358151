// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import ReactTimeago from 'react-timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, Opportunity, Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import { skipNullArr } from 'utils/nodash';

import PartnerDetailHoverCard from 'views/PartnerDetail/PartnerDetailHoverCard';
import CompanyLogo from 'components/CompanyLogo';
import PersonAvatar from 'components/PersonAvatar';

import ApproveConversation from '../BdRequestDetail/ApproveConversation';
import SetupCrossbeamConversation from '../BdRequestDetail/SetupCrossbeamConversation';
import AttributionBadge from '../components/AttributionBadge';
import { formatMentions } from '../utils';

import { COLUMN_WIDTH } from './constants';

const dealInfo = (opportunity: Opportunity) => {
  if (!opportunity) {
    return '-';
  }
  const closeDate = opportunity.closeDate
    ? `Close Date: ${opportunity.closeDate.toLocaleDateString()}`
    : '';
  return skipNullArr([closeDate, opportunity.displayAmount], true).join(' • ');
};

type Props = {
  request: BdRequest,
  loggedInOrg: Org,
  onUpdate: () => void,
  actionRequired?: boolean,
  style?: Object,
};

const RequestItem = ({ request, loggedInOrg, onUpdate, actionRequired, style }: Props) => {
  const {
    account,
    assignee,
    attribution,
    createdAt,
    createdBy,
    id,
    isCompleted,
    isShared,
    latestMessage,
    missingReply,
    opportunity,
    partner,
    partnerNameStr,
    pendingApproval,
    slackThreadUrl,
    title,
    isOffsite,
  } = request;

  const direction = createdBy.orgDomain === loggedInOrg.url ? 'To' : 'From';
  return (
    <div
      style={style}
      className={`${
        actionRequired ? 'card card-body no-padding ActionRequiredItem' : 'BdRequestListItem'
      } d-flex flex-column`}
    >
      {partner && !partner.isConnected && !loggedInOrg.settings.frictionlessMessaging && (
        <SetupCrossbeamConversation mini request={request} onUpdate={onUpdate} />
      )}
      {partner &&
        pendingApproval &&
        (partner.isConnected || loggedInOrg.settings.frictionlessMessaging) && (
          <ApproveConversation mini request={request} onUpdate={onUpdate} />
        )}
      {partner && !pendingApproval && missingReply && !isOffsite && (
        <div className="py-1 m-1 rounded px-3 bg-light-orange d-flex flex-row align-items-center gap-10">
          <FontAwesomeIcon icon="info-circle" className="orange" size="lg" />
          <span className="small-text bold gray-700 flex-fill">
            <b>PARTNER WAITING</b> on your response.
          </span>
        </div>
      )}
      <Link to={`/requests/${id}`}>
        <div className="w-100 d-flex flex-row pt-3 px-3">
          <div className="w-100 d-flex" style={{ maxWidth: COLUMN_WIDTH.icon }}>
            {isCompleted ? (
              <FontAwesomeIcon icon="check" className="green" />
            ) : (
              <FontAwesomeIcon icon={isShared ? 'comments' : 'lock'} className="blue" />
            )}
          </div>
          <div
            className="w-100 d-flex flex-column gap-5"
            style={{ maxWidth: COLUMN_WIDTH.request }}
          >
            <div className="d-flex flex-row align-items-center gap-5">
              <PartnerDetailHoverCard partner={partner}>
                <CompanyLogo
                  name={partner?.name || createdBy.orgName}
                  logoUrl={partner?.logoUrl || createdBy.orgLogoUrl}
                  domain={partner?.domain || createdBy.orgDomain}
                  size={20}
                  className=""
                />
              </PartnerDetailHoverCard>
              <span className="large-text bold gray-700 text-truncate fs-mask">
                {direction} {partner ? partner.name : partnerNameStr}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center gap-5">
              <span className="normal-text semi-bold gray-400">by:</span>
              <PersonAvatar
                firstName={createdBy.firstName}
                lastName={createdBy.lastName}
                size={20}
                avatar={createdBy.avatarImage}
                noMargin
              />
              <span className="normal-text bold blue text-truncate">{createdBy.fullName}</span>
            </div>
          </div>
          <div
            className="w-100 d-flex flex-row align-items-center gap-10"
            style={{ maxWidth: COLUMN_WIDTH.account }}
          >
            {account && (
              <CompanyLogo name={account.name} domain={account.website} size={20} className="" />
            )}
            <span className="normal-text semi-bold 700 text-truncate fs-mask">
              {account ? account.name : '(none)'}
            </span>
          </div>
          <div
            className="w-100 d-flex flex-row align-items-center gap-10"
            style={{ maxWidth: COLUMN_WIDTH.partnerManager }}
          >
            {assignee && (
              <PersonAvatar
                firstName={assignee.firstName}
                lastName={assignee.lastName}
                size={20}
                avatar={assignee.avatarImage}
                noMargin
              />
            )}
            {assignee ? (
              <span className="normal-text semi-bold gray-700 text-truncate">
                {assignee.fullName}
              </span>
            ) : (
              <span className="orange">(unassigned)</span>
            )}
          </div>
          <div
            className="w-100 d-flex align-items-center normal-text semi-bold gray-700"
            style={{ maxWidth: COLUMN_WIDTH.created }}
          >
            {createdAt.toLocaleDateString()}
          </div>
          <div className="w-100" style={{ maxWidth: COLUMN_WIDTH.actions }} />
        </div>
        <div className="w-100 d-flex flex-row align-items-center my-3 px-3">
          <div className="w-100" style={{ maxWidth: COLUMN_WIDTH.icon }} />
          <div
            className="w-100 d-flex flex-row align-items-center gap-5 bg-gray-100 rounded py-1 px-2 mr-5"
            style={{ maxWidth: '50%' }}
          >
            {latestMessage && !slackThreadUrl ? (
              <>
                <span className="normal-text semi-bold gray-700 flex-fill text-truncate fs-mask">
                  {latestMessage.isUpdate
                    ? 'Crossbeam for Sales Bot: '
                    : `${latestMessage.orguser.firstName}: `}
                  {formatMentions(latestMessage.text, (part) => `@${part}`)}
                </span>
                <span className="normal-text semi-bold gray-400 text-nowrap">
                  <ReactTimeago date={latestMessage.updatedAt} live={false} />
                </span>
              </>
            ) : (
              title
            )}
          </div>
          {loggedInOrg.settings.attributionEnabled && (
            <div className="flex-fill mr-2 d-flex flex-row align-items-center">
              <span className="normal-text semi-bold gray-400">Attribution:</span>
              <AttributionBadge attribution={attribution} className="ml-2" />
            </div>
          )}
          <div className="d-flex flex-fill align-items-center gap-5">
            <span className="normal-text semi-bold gray-400">Opportunity:</span>
            <span className="normal-text semi-bold gray-700 fs-mask">{dealInfo(opportunity)}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

RequestItem.defaultProps = {
  actionRequired: false,
  style: {},
};

export default withOrguser(RequestItem);
