// @flow

import * as React from 'react';

const titleBase = 'Crossbeam for Sales';
const titleSep = '-';

export default function setTitle(title: ?string = null) {
  // Set the the page title. With no arg it returns 'Crossbeam for Sales'
  // With string 'aabb' the result is 'aabb - Crossbeam for Sales'
  let parts = [titleBase];
  if (title) {
    parts = [title, titleSep, ...parts];
  }
  document.title = parts.join(' ');
}

export function useTitleEffect(title: string) {
  // On mount, set the title
  React.useEffect(() => {
    setTitle(title);
    // On un-mount, unset the title
    return () => setTitle();
  }, [title]);
}
