// @flow

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTimeago from 'react-timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, Org } from 'data/entities';
import { BdRequestFilters, fetchPreviousConversations } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import Pill from 'components/Pill';

type Props = {
  request: BdRequest,
  loggedInOrg: Org,
};

const PreviousConversations = ({ request, loggedInOrg }: Props) => {
  const [loading, setLoading] = useState(true);
  const [previousConversations, setPreviousConversations] = useState<BdRequest[]>([]);

  const { id, account, partner } = request;

  useEffect(() => {
    const filters = new BdRequestFilters(null);
    if (account) {
      filters.account = account.slug;
    }
    filters.partner = partner?.slug;
    filters.sortBy = '-created_at';

    setLoading(true);
    fetchPreviousConversations(filters)
      .then((r) => {
        setLoading(false);
        setPreviousConversations(r.results.filter((req) => req.id !== id));
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [account, partner, id]);

  return (
    <div className="pt-4 px-3 d-flex flex-column gap-10 flex-fill">
      <div className="d-flex flex-row align-items-center gap-15">
        <span className="large-text bold gray-700">Previous Conversations</span>
        {previousConversations && <Pill label={previousConversations.length} />}
      </div>
      <div className="BdRequestPreviousConversations">
        {!loading ? (
          <>
            {previousConversations && previousConversations.length ? (
              previousConversations.map((conversation) => (
                <div key={conversation.id} className="py-3 d-flex flex-column">
                  <div className="d-flex flex-row align-items-center gap-10">
                    <FontAwesomeIcon icon="hashtag" className="primary" />
                    <Link
                      to={`/requests/${conversation.id}`}
                      className="flex-fill large-text bold gray-700 text-truncate fs-mask"
                    >
                      {conversation.partner
                        ? `${loggedInOrg.name} and ${conversation.partner.name}${
                            conversation.account ? ` - ${conversation.account.name}` : ''
                          }`
                        : `Internal for ${conversation.account.name}`}
                    </Link>
                    <span className="small-text bold gray-500 text-nowrap">
                      <ReactTimeago date={conversation.updatedAt} live={false} />
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-5">
                    {conversation.account && (
                      <div className="flex-fill d-flex flex-row align-items-center gap-10">
                        <CompanyLogo
                          size={20}
                          name={conversation.account.name}
                          domain={conversation.account.website}
                          className=""
                        />
                        <Link
                          to={`/a/${conversation.account.slug}`}
                          className="normal-text semi-bold gray-600 fs-mask"
                        >
                          {conversation.account.name}
                        </Link>
                      </div>
                    )}
                    <Pill label={conversation.statusDisplay} />
                  </div>
                </div>
              ))
            ) : (
              <div className="mt-5 text-center">
                <span className="normal-text bold gray-500">No previous conversations found.</span>
              </div>
            )}
          </>
        ) : (
          <LoadingRing maxWidth="30px" className="m-5" />
        )}
      </div>
    </div>
  );
};

export default withOrguser(PreviousConversations);
