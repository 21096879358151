// @flow

import * as React from 'react';
import {
  AsyncTypeahead,
  Highlighter,
  Menu,
  MenuItem,
  WrappedTypeahead,
} from 'react-bootstrap-typeahead';
import classNames from 'classnames';
import { groupBy, map } from 'lodash';

import { OrgUser } from 'data/entities';
import NetworkProfile from 'data/entities/networkprofile';
import type { SearchIndexItem } from 'data/repositories/search';
import fetchUnisearchalResults from 'data/repositories/search';
import { discoverOrgs } from 'data/repositories/signup';
import { OrgUserContext } from 'contexts/OrgUserContext';
import { isProduction } from 'utils/environment';

import InviteDiscoveredPartnerModal from 'components/AddPartnerModal/AddDiscoveredPartnerModal';
import CompanyLogo from 'components/CompanyLogo';
import ConnectedPartnerBadge from 'components/ConnectedPartnerBadge';
import PersonAvatar from 'components/PersonAvatar';

import Logo from './Logo';

const PARTNER = 'partner';
const ACCOUNT = 'account';
const ORGUSER = 'orguser';
const DISCORG = 'discorg';

type Props = {
  bsSize: string,
  placeholder: string,
  onClear: () => void,
  onChange: (SearchIndexItem) => void,
  style?: string,
  entityKind?: string, // Optional param that restricts search to a specific entity kind
  id?: string,
  inputRef?: ?(WrappedTypeahead) => void, // Function that receives the WrappedTypeahead ref
  defaultSelected?: SearchIndexItem[],
  stripUnisearchalClassName?: boolean,
  className?: ?string,
  selected?: WrappedTypeahead[],
  hideClearButton?: boolean,
  loadInitial?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
  multiple?: boolean,
  typeAheadProps?: any,
};

type State = {
  searchProps: {
    isLoading: boolean,
    options: SearchIndexItem[],
  },
  initialOptions: SearchIndexItem[],
  partnerToConnectWith: SearchIndexItem,
  modalOpen: boolean,
};

const PARTNER_KIND = 'partner';

const unisearchToNetworkProfile = (data: SearchIndexItem): NetworkProfile =>
  NetworkProfile.fromApi({
    id: data.id,
    first_name: data.orgUserFirstName,
    last_name: data.orgUserLastName,
    avatar_image: data.avatarUrlShow,
    title: data.titleShow,
    org: {
      name: data.name,
      domain: data.domain,
      logo_url: data.logoUrl,
    },
  });

const OrgUserUniSearch = ({ option, menuProps }: { option: SearchIndexItem, menuProps: any }) => {
  const networkResponse = unisearchToNetworkProfile(option);
  return (
    <>
      <PersonAvatar
        firstName={networkResponse.firstName}
        lastName={networkResponse.lastName}
        avatar={networkResponse.avatarImage}
        org={networkResponse.org}
        size={40}
        className="mr-2"
      />
      <div style={{ lineHeight: '1.35rem', minWidth: '250px' }}>
        <div className="text-truncate">
          <Highlighter search={menuProps.text}>{networkResponse.fullName}</Highlighter>
        </div>
        <div
          className="normal--text gray-700"
          style={{ position: 'relative', top: '-2px', color: '#0b0f17', size: '12px' }}
        >
          {networkResponse.title
            ? `${networkResponse.title} at ${networkResponse.org.name}`
            : networkResponse.org.name}
        </div>
      </div>
    </>
  );
};

class Unisearchal extends React.Component<Props, State> {
  typeaheadElm: ?WrappedTypeahead = null;

  state: State = {
    searchProps: { isLoading: false, options: [] },
    initialOptions: [],
    modalOpen: false,
  };

  componentDidMount() {
    this.loadInitial();
  }

  onSearch = (query: string) => {
    this.setState(
      (state) => ({ searchProps: { ...state.searchProps, isLoading: true } }),
      () => this.updateFromQuery(query)
    );
  };

  onChange = (val: [SearchIndexItem]) => {
    if (this.typeaheadElm) {
      if (val) {
        const instance = this.typeaheadElm.getInstance();
        instance.blur();
      }
      const { onChange, multiple } = this.props;
      const item = multiple ? val : val[0];
      onChange(item, () => this.populateAndTriggerModal(item.id));
    }
  };

  onInputChange = (text: string) => {
    const { loadInitial, entityKind } = this.props;
    if (text === '' && loadInitial && entityKind === PARTNER_KIND) {
      this.setState((state) => ({
        searchProps: { ...state.searchProps, options: state.initialOptions },
      }));
    }
  };

  doClear = () => {
    if (this.typeaheadElm) {
      const instance = this.typeaheadElm.getInstance();
      instance.clear();
      this.props.onClear();
    }
  };

  setRef = (ref) => {
    this.typeaheadElm = ref;
    // inputRef a prop function that receives the ref for the WrappedTypeahead
    const { inputRef } = this.props;
    if (inputRef) {
      inputRef(ref);
    }
  };

  handleFocus = (event: Event) => {
    event.target.select();
  };

  focus = () => {
    this.typeaheadElm.getInstance().focus();
  };

  resetComponent = () => {
    this.typeaheadElm.getInstance().clear();
    this.setState(({ searchProps }) => ({ searchProps: { ...searchProps, options: [] } }));
  };

  toggleInviteModal = (callback) =>
    this.setState(
      (prevState) => ({
        modalOpen: !prevState.modalOpen,
      }),
      () => {
        if (typeof callback == 'function') {
          return callback();
        }
        this.resetComponent();
        return null;
      }
    );

  populateAndTriggerModal = (id) =>
    discoverOrgs([{ id }])
      .then(({ results }) =>
        this.setState({ partnerToConnectWith: results[0] }, this.toggleInviteModal)
      )
      .catch((error) => {
        // If posting to the API fails, nothing the user could have done. Report to home base.
        this.resetComponent();
      });

  async updateFromQuery(query: string) {
    try {
      const results = await fetchUnisearchalResults(query, this.props.entityKind);
      // $FlowIgnore
      this.setState({ searchProps: { isLoading: false, options: results } });
    } catch (error) {
      this.setState({ searchProps: { isLoading: false, options: [] } });
    }
  }

  loadInitial() {
    const { loadInitial, entityKind, selected } = this.props;
    if (loadInitial && entityKind === PARTNER_KIND && (!selected || selected.length === 0)) {
      this.setState(
        (state) => ({ searchProps: { ...state.searchProps, isLoading: true } }),
        () => {
          fetchUnisearchalResults('', entityKind).then((results: SearchIndexItem[]) => {
            this.setState({
              searchProps: { isLoading: false, options: results },
              initialOptions: results,
            });
          });
        }
      );
    }
  }

  renderMenuItem = (
    option: SearchIndexItem,
    menuProps: any,
    itemNum: number,
    multiEntity: boolean,
    orguser: OrgUser
  ) => {
    const {
      orgUserFirstName,
      orgUserLastName,
      status,
      name,
      domain,
      logoUrl,
      kind,
      isConnected,
    } = option;
    const classes = classNames('d-flex align-items-center mb-1 fs-mask');
    const connectedPartner =
      kind === PARTNER && isConnected ? (
        <ConnectedPartnerBadge className="ml-2 inline" iconOnly />
      ) : (
        kind === DISCORG && <Logo height={12} text={false} className="ml-2" />
      );
    if (kind === ORGUSER) {
      return (
        <div className={classes}>
          <OrgUserUniSearch option={option} menuProps={menuProps} />
        </div>
      );
    }
    const cta = kind === DISCORG && (
      <button className="btn btn-primary" type="button" style={{ margin: '3px' }}>
        Connect
      </button>
    );
    const subtitle =
      kind === DISCORG ? domain : `${orgUserFirstName || ''} ${orgUserLastName || ''}`;

    return (
      <div className={classes}>
        <CompanyLogo name={name} domain={domain} logoUrl={logoUrl} className="mr-2" />
        <div style={{ lineHeight: '1.35rem', minWidth: '250px' }}>
          <div
            className="d-flex align-items-baseline gap-5 text-truncate"
            style={{ size: '14px', marginTop: '1px', color: '#000000' }}
          >
            <Highlighter search={menuProps.text}>{name}</Highlighter>
            {connectedPartner}
            {status && <div className="d-inline-block">{status}</div>}
          </div>
          <div
            className="normal-text gray-700"
            style={{ position: 'relative', top: '-2px', color: '#0b0f17', size: '12px' }}
          >
            {subtitle}
          </div>
        </div>
        <div>{cta}</div>
      </div>
    );
  };

  renderMenu = (results: SearchIndexItem[], menuProps: any) => {
    const buildMenuItems = () => {
      const byKind = groupBy(results, 'kind');
      const partners = byKind[PARTNER];
      const discorgs = byKind[DISCORG];
      const partnersList = partners && discorgs ? partners.concat(discorgs) : partners || discorgs;
      const menu = [
        {
          title: 'Partners',
          data: partnersList,
        },
        {
          title: 'Accounts',
          data: byKind[ACCOUNT],
        },
      ];
      if (!isProduction) {
        menu.push({
          title: 'Network',
          data: byKind[ORGUSER],
        });
      }
      let index = 0;
      return (
        <div className="d-flex my-2">
          {map(menu, ({ title, data }) => {
            if (data && data.length > 0) {
              return (
                <div key={title} className="unisearchal-menu">
                  <Menu.Header
                    style={{
                      padding: '0',
                      marginLeft: '16px',
                      size: '12px',
                      color: 'rgb(24, 30, 45)',
                    }}
                  >
                    {title}
                  </Menu.Header>
                  {map(data, (option) => {
                    if (!option || option.kind === DISCORG) {
                      return null;
                    }
                    const item = (
                      <MenuItem
                        key={`${option.id}-${option.kind}`}
                        option={option}
                        position={index}
                        style={{ paddingLeft: '16px', minWidth: '320px' }}
                        className="Unisearchal-menu-item"
                      >
                        {this.renderMenuItem(option, menuProps, null, true)}
                      </MenuItem>
                    );
                    index += 1;
                    return item;
                  })}
                  <OrgUserContext.Consumer>
                    {({ orguser }) => (
                      <>
                        {map(data, (option) => {
                          if (!option || option.kind !== DISCORG) {
                            return null;
                          }
                          const item = (
                            <MenuItem
                              key={`${option.id}-${option.kind}`}
                              option={option}
                              position={index}
                            >
                              {this.renderMenuItem(option, menuProps, null, true, orguser)}
                            </MenuItem>
                          );
                          index += 1;
                          return item;
                        })}
                      </>
                    )}
                  </OrgUserContext.Consumer>
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    };
    const items = results && results.length ? buildMenuItems() : null;
    return <Menu {...menuProps}>{items}</Menu>;
  };

  render() {
    const { searchProps, partnerToConnectWith, modalOpen } = this.state;
    const {
      className: propClassName,
      bsSize,
      placeholder,
      style,
      id,
      defaultSelected,
      stripUnisearchalClassName,
      entityKind,
      selected,
      hideClearButton,
      loadInitial,
      disabled,
      isLoading,
      multiple,
      typeAheadProps,
    } = this.props;
    const baseClassName = stripUnisearchalClassName ? '' : 'Unisearchal';
    const className = `${baseClassName} ${propClassName || ''}`;
    const menuProps = { maxHeight: '400px' };
    if (!entityKind) {
      menuProps.renderMenu = this.renderMenu;
    }
    return (
      <>
        <InviteDiscoveredPartnerModal
          partner={partnerToConnectWith}
          modalOpen={modalOpen}
          toggle={this.toggleInviteModal}
        />
        <div style={style} className={className}>
          <AsyncTypeahead
            isLoading={searchProps.isLoading || isLoading}
            {...searchProps}
            {...menuProps}
            disabled={disabled || isLoading}
            id={id}
            allowNew={false}
            selected={selected}
            filterBy={
              multiple
                ? (option, props) => !props.selected.some((o) => o.slug === option.slug)
                : () => true
            }
            minLength={0}
            onSearch={this.onSearch}
            labelKey="name"
            placeholder={placeholder || '🔎  Search for an Account or Partner...'}
            onChange={this.onChange}
            onFocus={this.handleFocus}
            onInputChange={this.onInputChange}
            renderMenuItemChildren={this.renderMenuItem}
            ref={this.setRef}
            bsSize={bsSize || 'sm'}
            defaultSelected={defaultSelected}
            selectHintOnEnter
            clearButton={!hideClearButton}
            useCache={!loadInitial}
            align="left"
            multiple={multiple}
            {...typeAheadProps}
          />
        </div>
      </>
    );
  }
}

Unisearchal.defaultProps = {
  entityKind: null,
  style: {},
  id: 'Unisearchal',
  inputRef: null,
  defaultSelected: [],
  stripUnisearchalClassName: false,
  selected: null,
  hideClearButton: false,
  loadInitial: false,
  isLoading: false,
  disabled: false,
  multiple: false,
  typeAheadProps: {},
  className: null,
};

export default Unisearchal;
