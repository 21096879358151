// @flow

import React from 'react';
import classNames from 'classnames';

import { RequestAttribution } from 'data/entities/bdrequest';

type Props = {
  attribution: RequestAttribution,
  className?: string,
};

function AttributionBadge(props: Props) {
  const { attribution, className } = props;
  const classes = classNames('badge badge-success fs-mask', className);
  return attribution ? (
    <span className={classes}>{attribution.attributeAsDisplay}</span>
  ) : (
    <span className="mx-2">-</span>
  );
}

AttributionBadge.defaultProps = {
  className: '',
};

export default AttributionBadge;
