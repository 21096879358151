// @flow

import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { List, Org, OrgUser } from 'data/entities';
import { editList } from 'data/repositories/lists';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import Modal from 'components/Modal';

type Props = {
  open: boolean,
  toggle: () => void,
  list: List,
  onUpdate: () => void,
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const EditListModal = ({ open, toggle, list, loggedInOrg, loggedInOrguser, onUpdate }: Props) => {
  const [formState, setFormState] = useState({
    name: '',
    description: '',
  });
  const [errors, setErrors] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  const onEdit = () => {
    setSubmitting(true);
    setErrors(undefined);
    editList(list.id, formState.name, formState.description)
      .then(() => {
        onUpdate();
        setSubmitting(false);
        toggle();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.json) {
          Object.keys(error.json).forEach((field) => {
            if (['name', 'description', 'nonFieldErrors'].includes(field)) {
              setErrors((s) => ({ ...s, [field]: error.json[field] }));
            }
          });
        }
      });
  };

  useEffect(() => {
    setFormState({
      name: list.name,
      description: list.description,
    });
  }, [list, open]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      title="Edit list"
      footer={
        <button
          type="button"
          onClick={onEdit}
          disabled={submitting}
          className="btn btn-primary text-center"
        >
          <FontAwesomeIcon icon="pencil" className="mr-2" />
          Edit
        </button>
      }
    >
      <FieldErrors errors={errors && errors.nonFieldErrors} />
      <div className="form-group mb-4">
        <Label>Name</Label>
        <input
          value={formState.name}
          onChange={(e) => {
            const { value } = e.target;
            setFormState((s) => ({ ...s, name: value }));
          }}
          placeholder="Name"
          className="form-control"
          name="name"
          required
        />
        <FieldErrors errors={errors && errors.name} />
      </div>
      <div className="form-group">
        <Label>Description</Label>
        <textarea
          value={formState.description}
          onChange={(e) => {
            const { value } = e.target;
            setFormState((s) => ({ ...s, description: value }));
          }}
          rows="3"
          placeholder="Description"
          className="form-control"
          name="description"
        />
        <FieldErrors errors={errors && errors.description} />
      </div>
    </Modal>
  );
};

export default withOrguser(EditListModal);
