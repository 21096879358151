// @flow

import * as React from 'react';

import type { LocationT } from 'sharedTypes/reactRouter';

import CRMLoginCallback from 'views/Oauth/CRMLoginCallback';

type Props = {
  location: LocationT,
  doLogin: (doOnboarding: ?boolean) => {},
};

export default function SalesforceLogin(props: Props) {
  const { location, doLogin } = props;
  return <CRMLoginCallback location={location} doLogin={doLogin} provider="SALESFORCE" />;
}
