// @flow

import * as React from 'react';

import { Partner, SuggestedAccountMap } from 'data/entities';
import {
  approveAccountMapping,
  fetchAccountMappingPredictions,
} from 'data/repositories/accountMapping';
import { FadeInTransition } from 'utils/animate';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';

import LoadingRing from 'components/LoadingRing';

import MappingTableHeader from './components/MappingTableHeader';
import AccountMappingTable from './AccountMappingTable';

type Props = {
  predictions: ?PaginatedResponse<SuggestedAccountMap>,
  nextPredictions: () => void,
  onApprove: () => void,
  approvedMap: any,
  onChangeView: (viewName: string) => void,
  partner: Partner,
  loading: boolean,
};

function UnmappedAccounts(props: Props) {
  const {
    predictions,
    nextPredictions,
    approvedMap,
    onApprove,
    onChangeView,
    partner,
    loading,
  } = props;
  if (!partner) {
    return null;
  }
  const title = 'Potential Account Mappings';
  const subtitle = (
    <>
      These are potential mappings. We either had low confidence in them or they were previously
      mapped and deleted.
    </>
  );
  const hasResults = predictions && predictions.results && predictions.results.length;
  return (
    <div className="card card-condensed PartnerAccountMapping">
      <MappingTableHeader title={title} subtitle={subtitle} onChangeView={onChangeView} />
      <AccountMappingTable
        predictions={predictions ? predictions.results : []}
        hasNext={Boolean(predictions ? predictions.next : null)}
        nextAction={nextPredictions}
        onApprove={onApprove}
        approvedMap={approvedMap}
        partner={partner}
      />
      <FadeInTransition in={loading}>
        <LoadingRing maxWidth="50px" className="m-3" />
      </FadeInTransition>
      <FadeInTransition in={!loading && !hasResults}>
        <h5 className="text-center">Nothing left to approve</h5>
      </FadeInTransition>
    </div>
  );
}

type ContainerProps = {
  partnerSlug: string,
  onChangeView: () => void,
  accountSlug: string,
  partner: Partner,
};

type ContainerState = {
  predictions: ?PaginatedResponse<SuggestedAccountMap>,
  loading: boolean,
};

class PartnerUnmappedAccountsContainer extends React.Component<ContainerProps, ContainerState> {
  state: ContainerState = {
    predictions: null,
    loading: true,
  };

  componentDidMount() {
    this.loadPredictions();
  }

  componentDidUpdate(prevProps) {
    const { partnerSlug, accountSlug } = this.props;
    if (prevProps.partnerSlug !== partnerSlug || prevProps.accountSlug !== accountSlug) {
      this.loadPredictions();
    }
  }

  nextPredictions = () => {
    const { predictions } = this.state;
    const next = predictions ? predictions.next : null;
    if (!next) {
      return;
    }
    this.loadPredictions(next);
  };

  onApprove = (map: SuggestedAccountMap, partnerAccountId: number, asTarget: boolean = false) => {
    const key = `${map.account.slug}${map.partnerOrg.id}`;
    this.setState((state) => ({
      approvedMap: { ...state.approvedMap, [key]: true },
    }));
    return approveAccountMapping(map.account.slug, partnerAccountId, asTarget).then(() => {
      this.setState(({ predictions: { results, ...res } }) => ({
        loading: false,
        predictions: {
          ...res,
          results: results.filter((r) => map.account.slug !== r.account.slug),
        },
      }));
    });
  };

  loadPredictions(nextUrl: ?string) {
    const { predictions } = this.state;
    const { partnerSlug, accountSlug } = this.props;
    if (!partnerSlug) {
      this.setState({ loading: false, predictions: { ...predictions, results: [] } });
      return;
    }
    this.setState({ loading: true }, () => {
      fetchAccountMappingPredictions(partnerSlug, accountSlug, nextUrl)
        .then((res) => {
          const prevResults = predictions && predictions.results;
          const results = nextUrl && prevResults ? prevResults.concat(res.results) : res.results;
          this.setState({ loading: false, predictions: { ...res, results } });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const { onChangeView, partner } = this.props;
    const { predictions, loading } = this.state;
    return (
      <UnmappedAccounts
        predictions={predictions}
        nextPredictions={this.nextPredictions}
        onApprove={this.onApprove}
        onChangeView={onChangeView}
        partner={partner}
        loading={loading}
      />
    );
  }
}

export default PartnerUnmappedAccountsContainer;
