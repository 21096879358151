// @flow

import * as React from 'react';
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { camelCase, isEmpty, isEqual, map, pick, reduce } from 'lodash';
import queryString from 'qs';

import { OrgUser, Partner } from 'data/entities';
import type { Option } from 'data/repositories/accounts';
import {
  fetchOrgUser,
  fetchPartnerAccountOwner,
  searchPartnerAccountOwners,
} from 'data/repositories/orgusers';
import type { AccountPartnerQueryParams } from 'data/repositories/partners';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import AccountStatusSelector, { parseStatus } from 'components/AccountStatusSelector';
import UserSearch from 'components/UserSearch';

type Props = {
  own?: boolean,
  query: AccountPartnerQueryParams,
  url: string,
  history: RouterHistoryT,
  partner: Partner,
};

type State = {
  isOpen: boolean,
  status: Option[],
  segment: Option[],
  accountOwner: OrgUser,
  accountSearch: string,
  loadingAccountOwner: boolean,
};

const STATUS = 'status';
const SEGMENT = 'segment';
const NAME = 'accountSearch';
const OWNER = 'accountOwner';

const FILTER_KEYS_ARRAY = [STATUS, SEGMENT];
const FILTER_KEYS_TRIVIAL = [NAME, OWNER];
const FILTER_KEYS = FILTER_KEYS_ARRAY.concat(FILTER_KEYS_TRIVIAL);

class AccountMappingFilters extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loadingAccountOwner: false,
      ...reduce(
        FILTER_KEYS_ARRAY,
        (state, key) => {
          state[key] = [];
          return state;
        },
        {}
      ),
      ...reduce(
        FILTER_KEYS_TRIVIAL,
        (state, key) => {
          state[key] = null;
          return state;
        },
        {}
      ),
    };
  }

  componentDidMount() {
    this.loadFromQuery();
  }

  componentDidUpdate(prevProps) {
    const current = pick(this.props.query, FILTER_KEYS);
    const previous = pick(prevProps.query, FILTER_KEYS);
    if (!isEqual(current, previous)) {
      this.loadFromQuery();
    }
  }

  get prefix() {
    const { own } = this.props;
    return own ? '' : 'partner_';
  }

  get currentFilters() {
    const { accountSearch, accountOwner, status, segment } = this.state;
    const { prefix } = this;
    return {
      [camelCase(`${prefix}${STATUS}`)]: isEmpty(status) ? null : map(status, 'value'),
      [camelCase(`${prefix}${SEGMENT}`)]: isEmpty(segment) ? null : map(segment, 'value'),
      [camelCase(`${prefix}${OWNER}`)]: (accountOwner && accountOwner.id) || null,
      [camelCase(`${prefix}${NAME}`)]: accountSearch || null,
    };
  }

  loadFromQuery = () => {
    const { query } = this.props;
    const { prefix } = this;
    const newState = {};
    FILTER_KEYS.forEach((field) => {
      const key = camelCase(`${prefix}${field}`);
      const value = query[key];
      newState[field] = null;
      if (FILTER_KEYS_ARRAY.includes(field)) {
        newState[field] = [];
      }
      if (value) {
        if (field === OWNER) {
          const { accountOwner } = this.state;
          if (accountOwner && accountOwner.id === value) {
            newState[field] = accountOwner;
          } else {
            this.loadAccountOwner(value);
          }
        } else if (field === STATUS) {
          newState[field] = parseStatus(value);
        } else {
          newState[field] = value;
        }
      }
    });
    this.setState(newState);
  };

  loadAccountOwner = (ownerId: string) => {
    const { partner, own } = this.props;
    const { canFilterPartnerAccountStatus } = partner;
    if (!own && !canFilterPartnerAccountStatus) {
      return;
    }
    this.setState({ loadingAccountOwner: true }, () => {
      const toCall = own
        ? fetchOrgUser
        : (pantnerOwnerId) => fetchPartnerAccountOwner(partner.slug, pantnerOwnerId);
      if (ownerId !== 'all') {
        toCall(ownerId)
          .then((owner) => this.setState({ [OWNER]: owner, loadingAccountOwner: false }))
          .catch(() => this.setState({ loadingAccountOwner: false }));
      } else {
        this.setState({ loadingAccountOwner: false });
      }
    });
  };

  onToggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  clearFilters = () => {
    this.setState(
      {
        ...reduce(
          FILTER_KEYS_ARRAY,
          (state, key) => {
            state[key] = [];
            return state;
          },
          {}
        ),
        ...reduce(
          FILTER_KEYS_TRIVIAL,
          (state, key) => {
            state[key] = null;
            return state;
          },
          {}
        ),
      },
      () => {
        this.doSearch();
      }
    );
  };

  doSearch = () => {
    const { history, query, url } = this.props;
    const queryStr = queryString.stringify(
      { ...query, ...this.currentFilters },
      { skipNulls: true, encode: false, arrayFormat: 'brackets' }
    );
    const goto = queryStr ? `${url}?${queryStr}` : url;
    history.push(goto);
    this.setState({ isOpen: false });
  };

  onInputChange = (field: string) => (event: ?string) => {
    const val = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({ [field]: val || null });
  };

  onSegmentChange = (result: [Option]) => {
    this.setState({ [SEGMENT]: result || [] });
  };

  onStatusChange = (result: [Option]) => {
    this.setState({ [STATUS]: result || [] });
  };

  onOwnerChange = (result?: OrgUser) => {
    this.setState({ [OWNER]: result || null });
  };

  partnerAccountOwnerSearch = (query: string) => {
    const { partner } = this.props;
    return searchPartnerAccountOwners(partner.slug, query);
  };

  totalFilters = () => {
    const { query } = this.props;
    const { prefix } = this;
    return reduce(
      FILTER_KEYS,
      (total, field) => {
        const key = camelCase(`${prefix}${field}`);
        if (query[key]) {
          return total + 1;
        }
        return total;
      },
      0
    );
  };

  render() {
    const { isOpen, status, accountOwner, accountSearch, loadingAccountOwner } = this.state;
    const { own, partner } = this.props;
    const {
      accountStatusVisible,
      accountOwnerVisible,
    } = partner.settingsByPartner.visibilitySettings;
    const disableFilters = !own && !accountStatusVisible && !accountOwnerVisible;
    const showStatusFilter = own || accountStatusVisible;
    const showOwnerFilter = !own && accountOwnerVisible;
    const showNameFilter = own;
    const currentOwner = isEmpty(accountOwner) ? [] : [accountOwner];
    const totalFilters = this.totalFilters();
    const tooltipId = `account-filters-${own ? 'own' : 'partner'}`;
    return (
      <>
        {disableFilters && (
          <UncontrolledTooltip placement="top" target={tooltipId} delay={{ show: 0, hide: 100 }}>
            Your partner is not sharing any filters.
          </UncontrolledTooltip>
        )}
        <Dropdown toggle={this.onToggle} isOpen={isOpen}>
          <span id={tooltipId}>
            <DropdownToggle className="btn btn-link" disabled={disableFilters}>
              Filters{' '}
              <span className={`badge ${totalFilters > 0 ? 'badge-success' : ''}`}>
                {' '}
                {totalFilters}{' '}
              </span>{' '}
              <FontAwesomeIcon icon="chevron-circle-down" size="sm" className="mr-1" />
            </DropdownToggle>
          </span>

          <DropdownMenu style={{ width: '300%' }}>
            <div className="card-body">
              <Form>
                {/* {own && (
                  <FormGroup>
                    <Label>Segment</Label>
                    <SegmentSelector
                      segment={segment.map((x) => x.value || x)}
                      onChange={this.onSegmentChange}
                    />
                  </FormGroup>
                )} */}
                {showStatusFilter && (
                  <FormGroup>
                    <Label>Account status</Label>
                    <AccountStatusSelector
                      status={status}
                      onChange={this.onStatusChange}
                      own={own}
                    />
                  </FormGroup>
                )}
                {showOwnerFilter && (
                  <FormGroup>
                    <Label>Account owner</Label>
                    {loadingAccountOwner ? (
                      <input
                        type="text"
                        className="form-control form-control-sm input-sm mr-2"
                        style={{ maxWidth: '145px' }}
                        placeholder="Loading..."
                        disabled
                      />
                    ) : (
                      <UserSearch
                        onChange={this.onOwnerChange}
                        placeholder="Account Owner"
                        selected={currentOwner}
                        className="mr-2"
                        changeOnClear
                        searchMethod={own ? null : this.partnerAccountOwnerSearch}
                      />
                    )}
                  </FormGroup>
                )}
                {showNameFilter && (
                  <FormGroup>
                    <Label>Account name</Label>
                    <input
                      type="text"
                      placeholder="Account Name"
                      onChange={this.onInputChange(NAME)}
                      className="form-control"
                      value={accountSearch || ''}
                    />
                  </FormGroup>
                )}
              </Form>
              <Row className="justify-content-between">
                <Col xs="auto">
                  <Button color="link" className="pl-1" onClick={this.clearFilters}>
                    <FontAwesomeIcon icon="times-circle" /> Clear Filters
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button color="success" className="pl-1" onClick={this.doSearch}>
                    <FontAwesomeIcon icon="search" /> Search
                  </Button>
                </Col>
              </Row>
            </div>
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }
}

AccountMappingFilters.defaultProps = {
  own: false,
};

export default AccountMappingFilters;
