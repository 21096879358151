/* eslint-disable import/prefer-default-export */

export const COLUMN_WIDTH = {
  icon: '3%',
  request: '27%',
  account: '20%',
  partnerManager: '20%',
  created: '15%',
  actions: '15%',
};
