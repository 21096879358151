// @flow

import { Org } from 'data/entities';
import { CoSellingHealth, RequestWorkflowSummary } from 'data/entities/org';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';

const fetchUserOrg = (): Promise<Org> => {
  const url = `${orgUrlBase()}/`;
  return authFetch(url).then(Org.fromApi);
};

const updateOrgVisibilitySettings = (hideAccountStatus: boolean): Promise<Org> => {
  const body = {
    visibility_settings: { hide_account_status: hideAccountStatus },
  };
  const url = `${orgUrlBase()}/`;
  return authFetch(url, { method: 'PATCH', body }).then(Org.fromApi);
};

const updateOrgDetails = (description: string): Promise<Org> => {
  const body = {
    description,
  };
  const url = `${orgUrlBase()}/`;
  return authFetch(url, { method: 'PATCH', body }).then(Org.fromApi);
};

const getRequestWorkflowSummary = (): Promise<RequestWorkflowSummary> => {
  const url = `${orgUrlBase()}/request-workflow-summary`;
  return authFetch(url).then(RequestWorkflowSummary.fromApi);
};

const updateRequestWorkflowSettings = (
  allowCustomQuestions: boolean,
  talkingPoints: string[]
): Promise<Org> => {
  const body = {
    requests_workflow_settings: {
      allow_custom_questions: allowCustomQuestions,
      talking_points: talkingPoints,
    },
  };
  const url = `${orgUrlBase()}/`;
  return authFetch(url, { method: 'PATCH', body }).then(Org.fromApi);
};

const updateInviteSlackUsersSetting = (enabled: boolean) => {
  const body = {
    settings_update: {
      invite_users_to_slack_channel: enabled,
    },
  };
  const url = `${orgUrlBase()}/`;
  return authFetch(url, { method: 'PATCH', body }).then(Org.fromApi);
};

const getCoSellingHealth = (): Promise<CoSellingHealth> => {
  const url = `${orgUrlBase()}/co-selling-health`;
  return authFetch(url).then(CoSellingHealth.fromApi);
};

export {
  fetchUserOrg,
  getCoSellingHealth,
  getRequestWorkflowSummary,
  updateInviteSlackUsersSetting,
  updateOrgDetails,
  updateOrgVisibilitySettings,
  updateRequestWorkflowSettings,
};
