// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest } from 'data/entities';

import SlackLogo from 'components/SlackLogo';

type Props = {
  request: BdRequest,
  slackChannelNameStyle: Object,
  sendToSlackStyle: Object,
};

const RequestIsAlreadyOnSlackInfo = ({
  request,
  slackChannelNameStyle,
  sendToSlackStyle,
}: Props) => {
  const handleMouseEnter = (event) => {
    event.currentTarget.style.overflow = 'visible';
    event.currentTarget.style.zIndex = '999';
    event.currentTarget.style.backgroundColor = 'white';
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.overflow = 'hidden';
    event.currentTarget.style.zIndex = 'auto';
  };
  return (
    <>
      <SlackLogo height={16} width={16} className="pr" />
      <div
        className="flex-fill pl-2 pr-3"
        style={slackChannelNameStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        #{request.slackChannel?.name || request.partnerSlackChannel?.name || 'partner-channel'}
      </div>
      {request.slackThreadUrl && (
        <a href={request.slackThreadUrl} className="nav-link p-0" style={sendToSlackStyle}>
          <FontAwesomeIcon icon="external-link-alt" className="mr-2" />
          See Replies
        </a>
      )}
    </>
  );
};

export default RequestIsAlreadyOnSlackInfo;
