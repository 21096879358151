// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CompanyLogo from './CompanyLogo';

export default function PartnershipIcons(props) {
  const { partnerOrgName, partnerOrgDomain, userOrgName, userOrgDomain } = props;
  return (
    <div className="d-flex flex-row align-items-center justify-content-center gap-15">
      <CompanyLogo size={80} name={partnerOrgName} domain={partnerOrgDomain} className="" />
      <FontAwesomeIcon fontSize="20px" icon="handshake" className="gray-400" />
      <CompanyLogo size={80} name={userOrgName} domain={userOrgDomain} className="" />
    </div>
  );
}
