// @flow

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { CrossbeamPartner } from 'data/entities/crossbeam';
import { getStatusDefinition, rawStatuses } from 'data/entities/status';
import { setCrossbeamPartners } from 'data/repositories/crossbeam';
import useParams from 'utils/useParams';

import CompanyLogo from 'components/CompanyLogo';
import FieldErrors from 'components/FieldErrors';

type Props = {
  selectedPartners: CrossbeamPartner[],
  back: () => void,
  onboarding?: () => void,
};

const ConfirmImport = ({ selectedPartners, back, onboarding }: Props) => {
  const [{ partner: managePartnerId }] = useParams({});
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const submit = () => {
    const syncPartners = selectedPartners.map((partner) => ({
      id: partner.id,
      populations: partner.populations.map(({ id, status }) => ({
        id,
        status: status || 'UNKNOWN',
      })),
    }));
    setSubmitting(true);
    setErrors(null);
    setCrossbeamPartners(syncPartners)
      .then(() => {
        setSubmitting(false);
        if (onboarding) {
          onboarding();
        } else {
          history.push('/settings/');
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors(error.json);
      });
  };

  return (
    <>
      <FieldErrors errors={errors && errors.nonFieldErrors} color="danger" />
      <div
        className="d-flex flex-column align-items-center gap-30 flex-1"
        style={{ width: '650px' }}
      >
        {(managePartnerId
          ? selectedPartners.filter(
              (p) => p.partner?.partnerOrg.pseudoOrgForPartnerId === managePartnerId
            )
          : selectedPartners
        ).map((partner) => (
          <div key={partner.id} className="card m-0 h-auto w-100">
            <div className="card-body d-flex flex-column gap-30">
              <div className="d-flex flex-row align-items-center gap-15">
                <CompanyLogo
                  name={partner.name}
                  logoUrl={partner.logoUrl}
                  domain={partner.clearbitDomain}
                  size={48}
                  className=""
                />
                <div className="d-flex flex-column">
                  <span className="large-text bold gray-700">{partner.name}</span>
                  <a
                    href={`https://${partner.domain}`}
                    className="large-text semi-bold gray-500"
                    target="_blank"
                  >
                    {partner.domain}
                  </a>
                </div>
              </div>
              <div className="d-flex flex-column gap-20">
                {partner.populations
                  .sort((a, b) => rawStatuses.indexOf(b.status) - rawStatuses.indexOf(a.status))
                  .map((population, index) => {
                    const status = getStatusDefinition(population.status || '');
                    return (
                      <div
                        key={population.id}
                        className="d-flex flex-row align-items-center gap-15"
                      >
                        <span className="large-text bold gray-700">{population.name}</span>
                        <span className="large-text semi-bold gray-400">=</span>
                        <span className="large-text bold gray-700">
                          {status ? (
                            <>
                              <FontAwesomeIcon
                                icon={status.icon}
                                color={status.colorRaw}
                                className="mr-2"
                              />
                              {status.label}
                            </>
                          ) : (
                            'Ignore'
                          )}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="crossbeam-footer text-center">
        <div
          className="d-flex flex-row align-items-center justify-content-end gap-20"
          style={{ width: '650px' }}
        >
          <div className="flex-fill d-flex">
            <button type="button" className="btn btn-secondary" onClick={back}>
              Go Back
            </button>
          </div>
          <button type="button" className="btn btn-primary" onClick={submit} disabled={submitting}>
            Confirm and Import Data
          </button>
        </div>
      </div>
    </>
  );
};

ConfirmImport.defaultProps = {
  onboarding: null,
};

export default ConfirmImport;
