// @flow

import * as React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, map } from 'lodash';
import queryString from 'qs';

import type { AccountPartnerQueryParams } from 'data/repositories/partners';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

const DEAL_SIZE = 'amount';
const RECOMMENDED = 'recommended';
const SORT_OPTIONS = {
  [RECOMMENDED]: 'Recommended',
  [DEAL_SIZE]: 'Deal size',
};

type Props = {
  query: AccountPartnerQueryParams,
  url: string,
  history: RouterHistoryT,
};

function AccountMappingSorting({ query, url, history }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentSort, setCurrentSort] = React.useState(RECOMMENDED);

  React.useEffect(() => {
    const { sort } = query;
    if (sort) {
      if (sort in SORT_OPTIONS) {
        setCurrentSort(sort);
      }
    }
  }, [query]);

  const setSort = (e) => {
    const newSort = e.target.value;
    const newSortVal = newSort === RECOMMENDED ? null : newSort;
    if (!isEqual(newSortVal, currentSort)) {
      setCurrentSort(newSortVal || RECOMMENDED);
      const queryStr = queryString.stringify(
        { ...query, sort: newSortVal },
        { skipNulls: true, encode: false, arrayFormat: 'brackets' }
      );
      const goto = queryStr ? `${url}?${queryStr}` : url;
      history.push(goto);
    }

    setIsOpen(false);
  };
  const showTooltip = currentSort === RECOMMENDED;
  const tooltipId = 'tooltip-smart-rank';

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle>
        <span className="mr-1">Sort by:</span>
        {SORT_OPTIONS[currentSort]} <FontAwesomeIcon icon="sort" className="mr-1" />
        {showTooltip && <FontAwesomeIcon icon="info-circle" className="mr-1" id={tooltipId} />}
      </DropdownToggle>
      <DropdownMenu>
        {map(SORT_OPTIONS, (label, value) => (
          <DropdownItem value={value} key={label} onClick={setSort}>
            {label}
          </DropdownItem>
        ))}
      </DropdownMenu>
      {showTooltip && (
        <UncontrolledTooltip target={tooltipId}>
          Prioritized list of revenue opportunities. Organized as your prospect accounts that match
          with partner customer accounts, ordered by your deal size.
        </UncontrolledTooltip>
      )}
    </Dropdown>
  );
}

export default AccountMappingSorting;
