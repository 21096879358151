// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

import { PartnerInvite } from 'data/entities';
import { validateInvite } from 'data/repositories/invites';

function inviteDetail(invite: PartnerInvite) {
  const {
    fromOrg: { name: orgName },
  } = invite;
  return (
    <div className="align-items-center d-flex flex-column">
      <h3 className="text-center">You are now partners with {orgName}!</h3>
      <h5>
        We&apos;re currently building account mappings to help your organizations work together.
      </h5>
      <p className="fs-14">This process can take a little while.</p>
      <p className="fs-14">
        Visit{' '}
        <Link to={`/p/${invite.fromOrg.slug}`}>their partner page to manage mapping settings</Link>
      </p>
    </div>
  );
}

type Props = {
  match: { params: { inviteId: string } },
};

type State = {
  loading: boolean,
  errMsg: ?string,
  invite: ?PartnerInvite,
};

class InviteAccepted extends React.Component<Props, State> {
  state: State = { loading: true, errMsg: null, invite: null };

  componentDidMount() {
    // Fetch the invite details
    this.loadInvite();
  }

  loadInvite() {
    const {
      match: {
        params: { inviteId },
      },
    } = this.props;
    validateInvite(inviteId)
      .then((res) => this.setState({ loading: false, invite: res }))
      .catch((err) => {
        if (err.response.status === 404) {
          this.setState({ errMsg: 'Invalid Partner invite.' });
        } else {
          this.setState({ errMsg: 'Something went wrong! Please try again or contact support.' });
        }
      });
  }

  render() {
    const { errMsg, loading, invite } = this.state;
    let main: React.Node;

    if (loading) {
      main = <h3>Loading...</h3>;
    }
    if (errMsg) {
      main = <h4>{errMsg}</h4>;
    }
    if (invite) {
      main = inviteDetail(invite);
    }

    return <div className="mt-5">{main}</div>;
  }
}

export default InviteAccepted;
