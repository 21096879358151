// @flow

import * as React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createBdRequestQuestion } from 'data/repositories/bdrequestQuestion';

import FieldErrors from 'components/FieldErrors';
import Modal from 'components/Modal';

import { MAX_TEXT_LENGTH } from './constants';
import PartnersSelector from './PartnersSelector';

type Props = {
  open: boolean,
  toggle: () => void,
  onCreate: () => void,
};

const AddQuestionModal = ({ open, toggle, onCreate }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string } | null>(null);

  const [text, setText] = useState('');
  const [selectedPartners, setSelectedPartners] = useState([]);
  const defaultQuestion = selectedPartners.length === 0;

  const reset = () => {
    setText('');
    setSelectedPartners([]);
    setErrors(null);
  };

  const create = () => {
    setSubmitting(true);
    createBdRequestQuestion(
      text,
      defaultQuestion,
      selectedPartners.map((p) => p.id)
    )
      .then((res) => {
        setSubmitting(false);
        toggle();
        reset();
        onCreate();
      })
      .catch((err) => {
        setSubmitting(false);
        setErrors(err.json);
      });
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      title="Add a new question"
      footer={
        <>
          <div className="flex-fill">
            <button
              type="button"
              disabled={submitting}
              onClick={reset}
              className="btn btn-secondary"
            >
              Reset
            </button>
          </div>
          <button
            type="button"
            onClick={create}
            disabled={submitting}
            className="btn btn-primary text-center"
          >
            Add
          </button>
        </>
      }
    >
      <div className="d-flex flex-column gap-15">
        {/* Text */}
        <div className="d-flex flex-column gap-5">
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="normal-text semi-bold gray-700">Write the question</span>
            <span className="small-text semi-bold gray-400">
              {MAX_TEXT_LENGTH - text.length} characters left
            </span>
          </div>
          <input
            value={text}
            disabled={submitting}
            onChange={(e) => {
              const { value } = e.target;
              setText(value);
              setErrors(null);
            }}
            maxLength={MAX_TEXT_LENGTH}
            placeholder="Start typing..."
            className="form-control"
            name="text"
            required
          />
          <FieldErrors errors={errors && errors.text} />
        </div>

        {/* For partners */}
        <div className="d-flex flex-column gap-5">
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="normal-text semi-bold gray-700">Limit to specific partners</span>
            <span className="small-text semi-bold gray-400">Optional</span>
          </div>
          <PartnersSelector
            selected={selectedPartners}
            onSelect={setSelectedPartners}
            initalSelected={[]}
            disabled={submitting}
          />
          <div className="d-flex flex-row align-items-center gap-5 small-text semi-bold blue">
            <FontAwesomeIcon icon="info-circle" />
            No partners selected means the questions will be shown to your sales team for all
            partners.
          </div>
        </div>

        <FieldErrors errors={errors && errors.detail} />
      </div>
    </Modal>
  );
};

export default AddQuestionModal;
