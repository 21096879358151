import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';

const HeaderLogo = () => (
  <div className="brand inline no-border d-sm-inline-block">
    <Link to="/">
      <Logo />
    </Link>
  </div>
);

export default HeaderLogo;
