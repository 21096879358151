/* eslint-disable max-classes-per-file */
// @flow

import { find } from 'lodash';

import Account from 'data/entities/account';
import { Partner, PartnerOrgUser } from 'data/entities/partner';
import RequestComment from 'data/entities/requestcomment';
import { SlackChannel } from 'data/entities/slack';

import Opportunity from './opportunity';
import { OrgUserNameOnly } from './orguser';
import { getStatusComponent } from './status';

const urgencyChoices = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
};

const urgencyDisplayValue = {
  [urgencyChoices.HIGH]: 'High (<1 day)',
  [urgencyChoices.MEDIUM]: 'Medium (< 5 days)',
  [urgencyChoices.LOW]: 'Low',
};

const urgencyColors = {
  [urgencyChoices.HIGH]: 'danger',
  [urgencyChoices.MEDIUM]: 'warning',
  [urgencyChoices.LOW]: 'success',
};

const STATUS_ALL = 'ALL';

const statusChoices = {
  REQUESTED: 'REQUESTED',
  COMPLETED: 'COMPLETED',
};

const attributionChoices = {
  SOURCED: 'SOURCED',
  INFLUENCED: 'INFLUENCED',
  NO_ATTRIBUTION: 'NO_ATTRIBUTION',
};

const attributionDisplay = {
  [attributionChoices.SOURCED]: 'Sourced',
  [attributionChoices.INFLUENCED]: 'Influenced',
  [attributionChoices.NO_ATTRIBUTION]: 'Neither',
};

const INTEGRATION_HELP = ['integration_help', '✅ Integration Help'];
const outcomeMap = [
  ['recommended_to_account', '✅ Recommended to Account'],
  ['got_account_introduction', '✅ Got Account Introduction'],
  ['got_helpful_information', '✅ Got Helpful Information'],
  INTEGRATION_HELP,
  ['got_lead_new_outcome', '✅ Got Lead / New Contact'],
  ['partner_support_enablement', '✅ Partner Support and Enablement'],
];

const unhelpfulMap = [
  ['could_not_connect', '❌ Could Not Connect'],
  ['partner_unable_to_help', '❌ Partner Unable To Help'],
  ['partner_information_unhelpful', '❌ Got Unhelpful Information'],
];

const helpedPartnerMap = [
  ['gave_partner_info', '🌟 Gave Partner Information'],
  ['introduced_partner_to_client', '🌟 Introduced Partner to Client'],
  ['recommended_partner_to_client', '🌟 Recommended Partner to Client'],
];

const other = ['other', '☑️ Other'];

const deprecatedOutcomeMap = [INTEGRATION_HELP];

const DEPRECATED_OUTCOME_VALUES = deprecatedOutcomeMap.map<string>((v: Array<string>) => v[0]);

const allOutcomes = [...outcomeMap, ...unhelpfulMap, ...helpedPartnerMap, other];
const helpfulOutcomes = [...outcomeMap, ...helpedPartnerMap, other];

// Flow type for valid status values
type StatusType = $Keys<typeof statusChoices>;

type urgencyType = $Keys<typeof urgencyChoices>;

const statusDisplayValueMap = {
  REQUESTED: 'Open',
  COMPLETED: 'Completed',
  [STATUS_ALL]: 'All',
};

// e.g. [REQUESTED, COMPLETED]
const statusChoiceList = Object.values(statusChoices); // List of status values from choices obj

const statusToUrlValue = (status: string | StatusType): string =>
  // From IN_PROGRESS -> in-progress
  status.replace(/_/g, '-').toLowerCase();
const statusInUrlToBackendValue = (status: string): ?string => {
  // From in-progress -> IN_PROGRESS
  const backendValue: ?StatusType = status ? status.replace(/-/g, '_').toUpperCase() : null;
  if (backendValue === STATUS_ALL) {
    return null;
  }
  return backendValue;
};

class RequestAttribution {
  constructor(attributeAs: string, attributeAsDisplay: string) {
    this.attributeAs = attributeAs;
    this.attributeAsDisplay = attributeAsDisplay;
  }

  attributeAs: string;

  attributeAsDisplay: string;

  static fromApi = ({
    attribute_as: attributeAs,
    attribute_as_display: attributeAsDisplay,
  }: {
    attribute_as: string,
    attribute_as_display: string,
  }) => new RequestAttribution(attributeAs, attributeAsDisplay);
}

class BdRequestRedirect {
  ownBdRequestId: string;

  orgUuid: string;

  static fromApi = (d: any): BdRequestRedirect => {
    const b = new BdRequestRedirect();
    b.ownBdRequestId = d.own_bd_request_id;
    b.orgUuid = d.org_uuid;
    return b;
  };
}

class BdRequest {
  id: string;

  title: string;

  orguser: PartnerOrgUser;

  assignee: PartnerOrgUser;

  createdBy: PartnerOrgUser;

  completedBy: ?PartnerOrgUser;

  account: Account;

  partner: Partner;

  isPartnerSyncEnabled: ?boolean;

  ask: string;

  summary: string;

  createdAt: Date;

  completedAt: Date;

  updatedAt: Date;

  partnerNameStr: string;

  status: string;

  opportunity: Opportunity;

  outcome: string;

  outcomeLabel: string;

  urgency: urgencyType;

  attribution: RequestAttribution;

  taskSalesforceUrl: string;

  isShared: boolean;

  latestMessage: RequestComment;

  sharedPartnerAccountId: ?string;

  participants: OrgUserNameOnly[];

  pendingApproval: boolean;

  approvedAt: ?Date;

  missingReply: boolean;

  isUnassigned: boolean;

  slackThreadUrl: ?string;

  slackChannel: ?SlackChannel;

  partnerSlackChannel: ?SlackChannel;

  isOffsite: boolean;

  get isCompleted(): boolean {
    return this.status === statusChoices.COMPLETED;
  }

  get statusDisplay(): string {
    return statusDisplayValueMap[this.status];
  }

  get hasHelpfulOutcome(): boolean {
    const { outcome } = this;
    return outcome ? find(helpfulOutcomes, (e) => e[0] === outcome) : false;
  }

  get salesAssignee(): PartnerOrgUser {
    return this.orguser;
  }

  get bdAssignee(): PartnerOrgUser {
    return this.assignee;
  }

  static fromApi: (any) => BdRequest;
}

BdRequest.fromApi = (d: any) => {
  const bdr = new BdRequest();
  bdr.id = d.id;
  bdr.title = d.title;
  bdr.orguser = d.orguser && PartnerOrgUser.fromApi(d.orguser);
  bdr.assignee = d.assignee && PartnerOrgUser.fromApi(d.assignee);
  bdr.createdBy = d.created_by && PartnerOrgUser.fromApi(d.created_by);
  bdr.completedBy = d.completed_by && PartnerOrgUser.fromApi(d.completed_by);
  bdr.account = d.account && Account.fromApi(d.account);
  bdr.partner = d.partner && Partner.fromApi(d.partner);
  bdr.isPartnerSyncEnabled = d.is_partner_sync_enabled;
  bdr.ask = d.ask;
  bdr.summary = d.summary;
  bdr.createdAt = d.created_at && new Date(d.created_at);
  bdr.completedAt = d.completed_at && new Date(d.completed_at);
  bdr.updatedAt = d.updated_at && new Date(d.updated_at);
  bdr.partnerNameStr = d.partner_name_str || '(none)';
  bdr.status = d.status;
  bdr.opportunity = d.opportunity && Opportunity.fromApi(d.opportunity);
  bdr.outcome = d.outcome;
  bdr.outcomeLabel = d.outcome_label;
  bdr.urgency = d.urgency;
  bdr.attribution = d.attribution && RequestAttribution.fromApi(d.attribution);
  bdr.taskSalesforceUrl = d.task_salesforce_url;
  bdr.isShared = d.is_shared;
  bdr.latestMessage = d.latest_message && RequestComment.fromApi(d.latest_message);
  bdr.sharedPartnerAccountId = d.shared_partner_account_id;
  bdr.participants = d.participants && d.participants.map(OrgUserNameOnly.fromApi);
  bdr.slackThreadUrl = d.slack_thread_url;
  bdr.slackChannel = d.slack_channel && SlackChannel.fromApi(d.slack_channel);
  bdr.partnerSlackChannel =
    d.partner_slack_channel && SlackChannel.fromApi(d.partner_slack_channel);
  bdr.isOffsite = d.is_offsite;

  bdr.pendingApproval = d.pending_approval;
  bdr.approvedAt = d.approved_at && new Date(d.approved_at);

  bdr.missingReply = d.missing_reply;
  bdr.isUnassigned = d.is_unassigned;

  return bdr;
};

class SharedConversationPartnerAccount {
  id: string;

  name: string;

  website: string;

  statusRaw: string;

  status: any;

  static fromApi = (d: any): SharedConversationPartnerAccount => {
    const { id, name, website, status } = d;
    const a = new SharedConversationPartnerAccount();
    a.id = id;
    a.name = name;
    a.website = website;
    a.statusRaw = status;
    a.status = getStatusComponent(status, false);
    return a;
  };
}

class IntendedRecipient {
  id: ?string;

  email: string;

  firstName: string;

  lastName: string;

  get fullName() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  isCrm: boolean;

  sourceId: string;

  static fromApi = (d: any): IntendedRecipient => {
    const i = new IntendedRecipient();
    i.id = d.id;
    i.email = d.email;
    i.firstName = d.first_name;
    i.lastName = d.last_name;
    i.isCrm = d.is_crm;
    i.sourceId = d.source_id;
    return i;
  };
}

class SharedConversation {
  intendedRecipients: IntendedRecipient[];

  partnerAccount: SharedConversationPartnerAccount;

  message: RequestComment;

  static fromApi = (d: any): SharedConversation => {
    const { partner_account: partnerAccount, message } = d;
    const s = new SharedConversation();
    s.intendedRecipients =
      d.intended_recipients && d.intended_recipients.map(IntendedRecipient.fromApi);
    s.partnerAccount = partnerAccount && SharedConversationPartnerAccount.fromApi(partnerAccount);
    s.message = message && RequestComment.fromApi(message);
    return s;
  };
}

class CrossbeamRecipient {
  id: number;

  firstName: string;

  lastName: string;

  email: string;

  pictureUrl: string;

  get fullName() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  static fromApi = (d: any): CrossbeamRecipient => {
    const r = new CrossbeamRecipient();
    r.id = d.id;
    r.firstName = d.first_name;
    r.lastName = d.last_name;
    r.email = d.email;
    r.pictureUrl = d.picture_url;
    return r;
  };
}

export {
  allOutcomes,
  attributionChoices,
  attributionDisplay,
  BdRequest,
  BdRequestRedirect,
  CrossbeamRecipient,
  DEPRECATED_OUTCOME_VALUES,
  helpfulOutcomes,
  RequestAttribution,
  SharedConversation,
  SharedConversationPartnerAccount,
  STATUS_ALL,
  statusChoiceList,
  statusChoices,
  statusDisplayValueMap,
  statusInUrlToBackendValue,
  statusToUrlValue,
  urgencyChoices,
  urgencyColors,
  urgencyDisplayValue,
};
export type { StatusType, urgencyType };
