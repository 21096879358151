// @flow
import React from 'react';

type Props = {
  partnerName: string,
  toggleModal: () => void,
};

const ChannelIsOffsiteInfoText = ({ partnerName, toggleModal }: Props) => (
  <div className="flex d-flex flex-row">
    <span className="gray-400 fs-12 mt-3 semi-bold">
      {partnerName} connected a Slack channel to talk to you.{' '}
      <a href="#" className="blue bold" onClick={toggleModal}>
        Add this channel
      </a>{' '}
      to communicate with {partnerName}.
    </span>
  </div>
);

export default ChannelIsOffsiteInfoText;
