// @flow

import React from 'react';

import CompanyLogo from 'components/CompanyLogo';
import LoadingScreen from 'components/LoadingScreen';

const classes = 'rounded bg-gray-100';

const FakeAccountCard = () => (
  <div className="card m-0">
    <div className="loading-animation d-flex flex-column card-body gap-20">
      <div className="d-flex flex-row align-items-top gap-10">
        <CompanyLogo size={48} name="" className="align-self-center" />
        <div className="d-flex flex-column flex-fill no-overflow gap-5">
          <div className={classes} style={{ width: 250, height: 16 }} />
          <div className={classes} style={{ width: 150, height: 16 }} />
        </div>

        <div className="d-flex flex-column align-items-end gap-5">
          <div className="d-flex gap-10 align-items-center">
            <div className={classes} style={{ width: 100, height: 16 }} />
            <div className={classes} style={{ width: 150, height: 20 }} />
          </div>
          <div className="d-flex align-items-center gap-5">
            {Array.from({ length: 3 }, (x, i) => i).map((index) => (
              <div key={index} className={classes} style={{ width: 100, height: 16 }} />
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-5">
        {Array.from({ length: 5 }, (x, i) => i).map((index) => (
          <div key={index} className={classes} style={{ width: 80, height: 16 }} />
        ))}
      </div>
      <div className="d-flex flex-row align-items-center gap-10">
        {Array.from({ length: 3 }, (x, i) => i).map((index) => (
          <div key={index} className={`${classes} flex-even`} style={{ height: 40 }} />
        ))}
      </div>
    </div>
  </div>
);

const Loading = () => (
  <LoadingScreen>
    {Array.from({ length: 5 }, (x, i) => i).map((index) => (
      <FakeAccountCard key={index} />
    ))}
  </LoadingScreen>
);
export default Loading;
