// @flow

import { useContext } from 'react';

import { Org } from 'data/entities';
import { CROSSBEAM } from 'data/entities/integrationProviders';
import { OrgUserContext } from 'contexts';

const useOrgProvider = () => {
  const { org }: { org: Org } = useContext(OrgUserContext);

  return {
    provider: org.lastIntegration ? org.lastIntegration.provider : null,
    isProviderCrossbeam: org.lastIntegration && org.lastIntegration.provider === CROSSBEAM,
  };
};

export default useOrgProvider;
