// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

type Props = {
  loggedInOrg: Org,
  request: BdRequest,
};

const CRMActivityLink = ({ loggedInOrg, request }: Props) => {
  const { taskSalesforceUrl, isCompleted, partner } = request;
  const canSyncTask =
    !!loggedInOrg &&
    !!loggedInOrg.settings &&
    loggedInOrg.settings.logSalesforceTasks &&
    partner &&
    (isCompleted || loggedInOrg.settings.unfilteredTaskLogging);

  if (canSyncTask) {
    return taskSalesforceUrl ? (
      <a href={taskSalesforceUrl} target="_blank" className="normal-text semi-bold gray-700">
        Open Activity in CRM <FontAwesomeIcon icon="external-link-alt" size="xs" />
      </a>
    ) : (
      <span className="normal-text semi-bold gray-700">
        <em>Creating Activity in CRM in {'<'}10 mins.</em>
      </span>
    );
  }

  return null;
};

export default withOrguser(CRMActivityLink);
