// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';

import { Org } from 'data/entities';
import { updateOrgDetails } from 'data/repositories/orgs';

import CompanyLogo from 'components/CompanyLogo';
import CompanyDescription from 'components/SignUp/CompanyDescription';

type Props = {
  org: Org,
};

type State = {
  complete: boolean,
  description: string,
  submitting: boolean,
  error: ?boolean,
  errors: any,
};

class OrgSetupDetails extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    const { org } = this.props;
    this.state = {
      complete: false,
      description: org.description,
      error: false,
      errors: null,
      submitting: false,
    };
  }

  onChange = (event) => {
    const description = event.target.value;
    this.setState({ description });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { description } = this.state;
    this.setState({ errors: null, error: false }, () => {
      updateOrgDetails(description)
        .then(() => {
          this.setState({ complete: true });
        })
        .catch((error) => {
          this.setState({ submitting: false });
          if (error.json && error.json.description) {
            this.setState({
              errors: { description: error.json.description, ...error.json },
            });
          } else {
            this.setState({ error: true });
          }
        });
    });
  };

  render() {
    const { description, complete, error, errors, submitting } = this.state;
    const { org } = this.props;
    const { name, url, logoUrl } = org;

    if (complete) {
      return <Redirect to="/onboarding" />;
    }

    if (error === true) {
      // Only renders if there is no response.json.description for more detailed msg
      return (
        <div>
          <h4>Failed to save company description.</h4>
        </div>
      );
    }

    return (
      <Form onSubmit={this.onSubmit} style={{ width: 400 }}>
        <FormGroup>
          <br />
          <span className="normal-text semi-bold gray-600" style={{ lineHeight: 2 }}>
            Company
          </span>
          <div className="d-flex flex-row align-items-center gap-10">
            <CompanyLogo name={name} domain={url} logoUrl={logoUrl} size={28} className="" />
            <span className="normal-text semi-bold gray-600">{name}</span>
          </div>
          <br />
          <CompanyDescription onChange={this.onChange} description={description} errors={errors} />
          <Button
            type="submit"
            name="save"
            className="btn btn-primary btn-block mt-3"
            id="save"
            disabled={submitting}
          >
            Continue
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default OrgSetupDetails;
