// @flow

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from 'data/entities/lists';
import { deleteFromList } from 'data/repositories/lists';

type Props = {
  list: List,
  accountPartnerId: string,
  onDelete: (listId: string) => void,
};

const classes = 'disable-selection small-text bold red';

const DeleteFromList = ({ list, accountPartnerId, onDelete }: Props) => {
  const { id } = list || {};
  const [prompt, setPrompt] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onConfirmDelete = (event) => {
    event.preventDefault();
    setSubmitting(true);
    deleteFromList(id, accountPartnerId)
      .then((l) => {
        onDelete(accountPartnerId);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const onCancelDelete = (event) => {
    event.preventDefault();
    setPrompt(false);
  };

  if (submitting) {
    return <span className={classes}>Deleting...</span>;
  }

  if (prompt) {
    return (
      <span className={classes}>
        Confirm delete:{' '}
        <a href="#" onClick={onConfirmDelete}>
          Yes
        </a>
        {' • '}
        <a href="#" onClick={onCancelDelete}>
          No
        </a>
      </span>
    );
  }
  return (
    <FontAwesomeIcon
      className="gray-700"
      cursor="pointer"
      icon="times"
      size="lg"
      onClick={(event) => {
        event.preventDefault();
        setPrompt(true);
      }}
    />
  );
};

export default DeleteFromList;
