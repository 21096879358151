// @flow
import * as React from 'react';

import { Partner } from 'data/entities';
import { updateBdRequestPartner } from 'data/repositories/bdrequests';
import type { SearchIndexItem } from 'data/repositories/search';

import DisplayEdit from 'components/DisplayEdit';
import InfoTip from 'components/InfoTip';
import Unisearchal from 'components/Unisearchal';

type Props = {
  requestId: string,
  currentPartner: Partner,
  onUpdate: () => void,
};

function PartnerEditSearch({ requestId, currentPartner, onUpdate, lockMessage }: Props) {
  const onSave = (newPartner: Partner): Promise<any> => {
    if (!newPartner || (currentPartner && currentPartner.slug === newPartner && newPartner.slug)) {
      return Promise.resolve();
    }
    return updateBdRequestPartner(requestId, newPartner.slug).then(onUpdate);
  };

  let existingValue: ?SearchIndexItem = null;
  if (currentPartner) {
    existingValue = { slug: currentPartner.slug, kind: 'partner', name: currentPartner.name };
  }
  const displayValue = currentPartner ? currentPartner.name : '(none)';

  if (lockMessage) {
    return (
      <div>
        {displayValue}{' '}
        <InfoTip targetId="partnerEdit" icon="lock" className="fs-11 text-muted">
          {lockMessage}
        </InfoTip>
      </div>
    );
  }
  return (
    <DisplayEdit
      searchComponent={Unisearchal}
      existingValue={existingValue}
      onSave={onSave}
      extraSearchProps={{ entityKind: 'partner', stripUnisearchalClassName: true }}
      placeholder="Search for partner..."
      emptyLabel="No matching partners found"
      focusOnEdit
    >
      {displayValue}
    </DisplayEdit>
  );
}

export default PartnerEditSearch;
