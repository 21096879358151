// @flow

// All-in-one user management view
// This is a sub-view of the views/User.jsx

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';
import { fetchCountWithUser, fetchNextOrgusers, fetchOrgusers } from 'data/repositories/orgusers';
import type { SearchIndexItem } from 'data/repositories/search';
import { OrgUserContext } from 'contexts';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';

import LoadingRing from 'components/LoadingRing';
import UserSearch from 'components/UserSearch/UserSearch';

import InviteUsers from './InviteUsers';
import ManageUserTableRow from './ManageUserTableRow';
import Suggestions from './Suggestions';

type Props = {
  users: OrgUser[],
  onModify: () => void,
  loading: boolean,
  hasNext: boolean,
  getNext: () => void,
  onUserSearch: (SearchIndexItem) => void,
  countWithUser: number,
  orguser: OrgUser,
  org: Org,
};

const UserManagementComponent = ({
  users,
  onModify,
  loading,
  getNext,
  hasNext,
  onUserSearch,
  countWithUser,
  orguser,
  org,
}: Props) => (
  <div className="d-flex flex-row gap-30">
    <div className="flex-fill d-flex flex-column gap-20">
      <div className="d-flex flex-row align-items-center gap-20">
        <h5 className="m-0 flex-fill bold gray-700">
          Team Members {countWithUser !== null ? `(${countWithUser} Seats)` : ''}
        </h5>
        <UserSearch onChange={onUserSearch} className="col-sm-4 p-0" changeOnClear />
        <InviteUsers>
          <div className="btn btn-primary">
            <FontAwesomeIcon icon="plus" /> Invite User
          </div>
        </InviteUsers>
      </div>
      <div>
        <div className="card card-body p-0 m-0">
          <table className="custom-table">
            <thead className="custom-table-header small-text bold gray-600">
              <tr>
                <th className="custom-table-cell text-uppercase">Name</th>
                <th className="custom-table-cell">
                  <div className="d-flex flex-row align-items-center gap-5 text-uppercase">
                    Last 90 Days
                  </div>
                </th>
                <th className="custom-table-cell">
                  <div className="d-flex flex-row align-items-center gap-5 text-uppercase">
                    Chrome Extension
                  </div>
                </th>
                <th className="custom-table-cell">
                  <div className="d-flex flex-row align-items-center gap-5 text-uppercase">
                    Slack Identity
                  </div>
                </th>
                <th className="custom-table-cell" aria-label="Settings" />
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                users.map((u) => (
                  <ManageUserTableRow key={u.id} user={u} org={org} onModify={onModify} />
                ))
              ) : (
                <tr>
                  <td colSpan="3">
                    <LoadingRing className="m-4" maxWidth="50px" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {hasNext && (
        <div>
          <button className="btn btn-link btn-block text-primary" type="button" onClick={getNext}>
            Load More
          </button>
        </div>
      )}
    </div>
    {orguser.canManage && <Suggestions />}
  </div>
);

type State = {
  users: OrgUser[],
  loading: boolean,
  next: ?string,
  countWithUser: number,
};

class UserManagement extends React.Component<null, State> {
  state: State = { users: [], loading: true, next: null, countWithUser: 0 };

  componentDidMount() {
    this.getUsers();
    this.getCountWithUser();
  }

  getQueryString = () => {
    const query = new URLSearchParams(this.props.location.search);
    const email = query.get('email');
    if (email) {
      this.props.history.push(this.props.location.pathname);
    }
    return email;
  };

  onModify = () => {
    this.getUsers();
  };

  onUserSearch = (results: OrgUser) => {
    if (results) {
      this.setState({ users: [results], loading: false });
    } else {
      this.getUsers();
    }
  };

  setStateFromUserRes = (res: PaginatedResponse<OrgUser>) => {
    const email = this.getQueryString();
    let { results } = res;
    if (email) {
      const testResults = res.results.filter((orguser) => orguser.email === email);
      if (testResults.length) {
        results = testResults;
      }
    }
    this.setState({ users: results, next: res.next, loading: false });
  };

  getUsers() {
    fetchOrgusers({ needInvite: false })
      .then(this.setStateFromUserRes)
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  getCountWithUser() {
    fetchCountWithUser().then((count) => {
      this.setState({ countWithUser: count });
    });
  }

  getNext = () => {
    const { next } = this.state;
    if (!next) {
      return;
    }
    fetchNextOrgusers(next).then((res) => {
      this.setState((prev) => {
        const { users: prevOrgusers } = prev;
        const { results: newOrgusers } = res;
        const hasExisting = prevOrgusers && prevOrgusers.length;
        const users = hasExisting ? prevOrgusers.concat(newOrgusers) : newOrgusers;
        return { users, loading: false, next: res.next };
      });
    });
  };

  render() {
    const { users, loading, next, countWithUser } = this.state;
    return (
      <OrgUserContext.Consumer>
        {({ orguser, org }) => (
          <UserManagementComponent
            orguser={orguser}
            org={org}
            loading={loading}
            users={users}
            countWithUser={countWithUser}
            onModify={this.onModify}
            getNext={this.getNext}
            hasNext={Boolean(next)}
            onUserSearch={this.onUserSearch}
          />
        )}
      </OrgUserContext.Consumer>
    );
  }
}

export default withRouter(UserManagement);
