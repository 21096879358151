import React from 'react';

import useInIframe from 'utils/useInIframe';

import LogoCrossbeam from 'components/LogoCrossbeam';

const PrimaryLayoutFooter = () => {
  const isInIframe = useInIframe();

  if (isInIframe) {
    return null;
  }

  return (
    <div className="container disable-selection footer copyright d-flex flex-column align-items-center gap-5">
      <div className="d-flex flex-row align-items-center justify-content-center gap-10">
        <LogoCrossbeam width={80} />
        <div className="mr-3 d-flex flex-row align-items-center justify-content-center gap-5">
          <span className="small-text semi-bold gray-500">&copy;</span>
          <a href="https://crossbeam.com" target="_blank" className="small-text bold primary">
            Crossbeam
          </a>
          <span className="small-text semi-bold gray-500">{new Date().getFullYear()}</span>
        </div>
        <a
          href="https://help.crossbeam.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="small-text semi-bold gray-500"
        >
          Support
        </a>
        <span className="gray-300">|</span>
        <a
          href="https://help.crossbeam.com/en/articles/6699011-sales-edge"
          target="_blank"
          rel="noopener noreferrer"
          className="small-text semi-bold gray-500"
        >
          Documentation
        </a>
      </div>
      <div className="small-text semi-bold gray-400">
        <span>All logos are provided by</span>{' '}
        <a href="https://clearbit.com/" className="blue">
          Clearbit
        </a>
      </div>
    </div>
  );
};

export default PrimaryLayoutFooter;
