// @flow

import React, { useEffect, useState } from 'react';
import { findIndex, isNil } from 'lodash';

import { Account, Org, OrgUser } from 'data/entities';
import type NetworkProfile from 'data/entities/networkprofile';
import { activeOppRaw, coldRaw, wonRaw } from 'data/entities/status';
import {
  fetchNetworkProfileAccounts,
  fetchNetworkProfileAccountsCount,
} from 'data/repositories/networkprofile';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

import CompanyLogo from 'components/CompanyLogo';
import InfiniteList from 'components/InfiniteList';
import LoadingRing from 'components/LoadingRing';

import MessageModal from './MessageModal';

const statuses = {
  [wonRaw]: 'Won Deals',
  [activeOppRaw]: 'Deals',
  [coldRaw]: 'Cold Prospects',
};

const statusFilters = [undefined, wonRaw, activeOppRaw, coldRaw];

type AccountCardProps = {
  account: Account,
  isLoaded: boolean,
  isOwnOrg: boolean,
  loggedInOrg: Org,
  showSection: boolean,
  accountOwnerId: string,
};

const AccountCard = ({
  account,
  isLoaded,
  isOwnOrg,
  loggedInOrg,
  showSection,
  accountOwnerId,
  ...rest
}: AccountCardProps) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((s) => !s);

  return (
    <div {...rest}>
      {showSection && (
        <div className="bold large-text gray-400 mb-2">{statuses[account.statusRaw]}</div>
      )}
      <div className="h-100">
        {isLoaded ? (
          <a
            target="_blank"
            href={isOwnOrg ? `/requests/log-activity?account=${account.slug}` : undefined}
            className="m-0 card card-hoverable"
            role="button"
            onClick={!isOwnOrg ? toggle : undefined}
          >
            <div className="d-flex flex-row align-items-center card-body">
              <CompanyLogo name={account.name} domain={account.websiteNormalized} size={45} />
              <div className="d-flex flex-column flex-fill">
                <div className="bold large-text gray-700">{account.name}</div>
                {account.overlapsCount !== null && (
                  <div className="bold normal-text gray-500">{`${account.overlapsCount} similar mutual accounts`}</div>
                )}
              </div>
              <button className="btn btn-secondary" type="button">
                Message
              </button>
              <MessageModal
                accountOwnerId={accountOwnerId}
                account={account}
                accountPartners={account.accountPartners}
                isOpen={open}
                toggle={toggle}
              />
            </div>
          </a>
        ) : (
          <div className="m-0 card h-100 d-flex justify-content-center">
            <LoadingRing maxWidth="50px" />
          </div>
        )}
      </div>
    </div>
  );
};

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  networkProfile: NetworkProfile,
  isOwnOrg: boolean,
};

const paginationLimit = 50;

const Accounts = ({
  loggedInOrg,
  loggedInOrguser: { id: loggedInID },
  networkProfile,
  isOwnOrg,
}: Props) => {
  const [{ status }, setParams] = useParams();

  const [loading, setLoading] = useState(true);
  const [loadedAll, setLoadedAll] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const [loadingCounts, setLoadingCounts] = useState(true);
  const [counts, setCounts] = useState<{ [key: string]: string | number }>({});
  const allCount = Object.values(counts).reduce((total, count) => total + count, 0);

  useEffect(() => {
    setLoadingCounts(true);
    fetchNetworkProfileAccountsCount(networkProfile.id).then((result) => {
      setCounts(result);
      setLoadingCounts(false);
    });
  }, [networkProfile.id]);

  const loadMoreRows = (offset, limit) => {
    if (offset === 0 || loadedAll) {
      return null;
    }
    return fetchNetworkProfileAccounts(networkProfile.id, status, offset, limit).then((sp) => {
      setAccounts((s) => s.concat(sp.results));
      if (isNil(sp.next)) {
        setLoadedAll(true);
      }
    });
  };

  // Initial load.
  useEffect(() => {
    setLoading(true);
    setLoadedAll(false);
    setAccounts([]);
    fetchNetworkProfileAccounts(networkProfile.id, status, 0, paginationLimit)
      .then((sp) => {
        setLoading(false);
        setAccounts(sp.results);
        if (isNil(sp.next)) {
          setLoadedAll(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [networkProfile.id, status]);

  return (
    <>
      <div className="col-md-6 d-flex flex-column gap-10">
        <div className="d-flex flex-row align-items-center sticky network-profile-filters overlay-bg gap-15">
          {statusFilters.map((key) => {
            const label = key ? statuses[key] : 'All';
            const active = status === key;
            const count = key ? counts[key] : allCount;
            return (
              <div
                tabIndex={0}
                role="button"
                onClick={() => setParams({ status: key })}
                onKeyPress={() => setParams({ status: key })}
                key={key || 'all'}
                className={`partners-filter d-flex flex-row align-items-center ${
                  active ? 'bg-primary-lighter' : ''
                }`}
              >
                <span
                  className={`bold normal-text flex-fill ${active ? 'text-primary' : 'gray-500'}`}
                >
                  {label}
                </span>
                <div
                  className={`ml-2 partners-filter-count bold small-text ${
                    active ? 'bg-primary text-white' : 'bg-primary-lighter text-primary'
                  }`}
                >
                  {loadingCounts ? <LoadingRing maxWidth="15px" /> : count}
                </div>
              </div>
            );
          })}
        </div>
        <div>
          {!loading ? (
            <>
              {accounts.length !== 0 ? (
                <InfiniteList
                  list={accounts}
                  loadMoreRows={loadMoreRows}
                  count={status ? counts[status] : allCount}
                  minHeight={85}
                  loadedAll={loadedAll}
                  render={({ key, index, style, data, isLoaded }) => (
                    <AccountCard
                      key={key}
                      account={data}
                      style={style}
                      isOwnOrg={isOwnOrg}
                      loggedInOrg={loggedInOrg}
                      isLoaded={isLoaded}
                      accountOwnerId={networkProfile.id}
                      showSection={
                        data && findIndex(accounts, { statusRaw: data.statusRaw }) === index
                      }
                    />
                  )}
                />
              ) : (
                <div className="m-5 text-center">
                  <p className="m-0 bold large-text gray-700">No mutual accounts found</p>
                  <p className="m-0 semi-bold normal-text gray-500">
                    {loggedInID === networkProfile.id
                      ? 'You do not have any matching accounts.'
                      : `${networkProfile.firstName} and you do not have any matching accounts.`}
                  </p>
                </div>
              )}
            </>
          ) : (
            <LoadingRing maxWidth="50px" className="m-5" />
          )}
        </div>
      </div>
    </>
  );
};

export default withOrguser(Accounts);
