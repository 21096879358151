// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Partner } from 'data/entities';

import CompanyLogo from 'components/CompanyLogo';

type Props = {
  isShared: boolean,
  pendingApproval: boolean,
  partner: Partner,
};

const Visibility = ({ isShared, pendingApproval, partner }: Props) => {
  const PartnerLink = () =>
    partner && (
      <Link className="gray-700" to={`/p/${partner.slug}`}>
        {partner.name}
      </Link>
    );
  const text =
    isShared && partner ? (
      <>
        <CompanyLogo
          name={partner.name}
          domain={partner.domain}
          logoUrl={partner.logoUrl}
          size={15}
          className=""
        />
        {pendingApproval ? (
          <span className="text-nowrap">
            Once they join, <PartnerLink /> will see all public messages posted.
          </span>
        ) : (
          <>
            <PartnerLink /> is in this conversation.
          </>
        )}
      </>
    ) : (
      !isShared && (
        <>
          <FontAwesomeIcon icon="lock" />
          This message will not be visible to your partner.
        </>
      )
    );

  return text ? (
    <div className="d-flex flex-row align-items-center gap-5 small-text bold gray-700 fs-mask">
      {text}
    </div>
  ) : null;
};

export default Visibility;
