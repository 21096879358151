/* eslint-disable max-classes-per-file */
// @flow

import { camelCase, forOwn, get } from 'lodash';

import type { IMinimalUser } from 'data/entities/minimalUser';
import MinimalUser from 'data/entities/minimalUser';
import { skipNullArr } from 'utils/nodash';

const OrgUserRoles = { SALES: 'SALES', BD: 'BD', REPLY: 'REPLY', VIEWER: 'VIEWER' };

class EmailSettings {
  weeklyFocusListEmail: boolean;

  newOverlapsEmail: boolean;

  recentlyAssignedOverlapsEmail: boolean;

  newPartnerOverlapsEmail: boolean;

  actionRequiredReminderEmail: boolean;

  constructor(values: any) {
    forOwn(values, (value, key) => {
      this[camelCase(key)] = value;
    });
  }
}

class NotificationSettings {
  emailEnabled: boolean;

  slackEnabled: boolean;

  constructor(values: any) {
    forOwn(values, (value, key) => {
      this[camelCase(key)] = value;
    });
  }
}

class OrgUserSettings {
  notificationChannelSettings: NotificationSettings;

  emailSettings: EmailSettings;

  constructor(values: any) {
    this.notificationChannelSettings = new NotificationSettings(
      get(values, 'notification_channel_settings')
    );
    this.emailSettings = new EmailSettings(get(values, 'email_settings'));
  }
}

class OrgUser extends MinimalUser {
  id: string;

  crossbeamId: string;

  firstName: string;

  lastName: string;

  sourceFirstName: ?string;

  sourceLastName: ?string;

  title: ?string;

  email: string;

  role: $Keys<typeof OrgUserRoles>;

  roleDisplay: string;

  hasSlackIdentity: boolean;

  hasChromeExtension: boolean;

  avatarImage: string;

  isActive: boolean;

  isAdmin: boolean;

  isCrossbeamAdmin: boolean;

  canManage: boolean;

  hidden: boolean;

  hasLoggedIn: boolean;

  emailNotification: ?string;

  magicbellHash: ?string;

  intercomHash: ?string;

  settings: OrgUserSettings;

  sourceProvider: string;

  shareableInviteLink: ?string;

  stateCode: ?string;

  isGhost: ?boolean;

  recentRequestCount: number;

  overlapsCount: ?number;

  orgLogoUrl: ?string;

  capabilities: {
    canManageUsers: boolean,
    canManageBilling: boolean,
    canAccessSeatedExperience: boolean,
    canManagePartnerships: boolean,
    viewOnly: boolean,
  };

  static fromApi: (data: any, magicbellHash?: string) => OrgUser;

  get sourceFullName(): string {
    // Only exists when fetching list of orgusers that a user can assume
    return skipNullArr([this.sourceFirstName, this.sourceLastName], true).join(' ');
  }
}

OrgUser.fromApi = (data: any, magicbellHash?: string) => {
  const o = new OrgUser();
  o.id = data.id;
  o.crossbeamId = data.crossbeam_id;
  o.firstName = data.first_name || '';
  o.lastName = data.last_name || '';
  o.email = data.email || '';
  o.title = data.title || '';
  o.role = data.role;
  o.roleDisplay = data.role_display;
  o.hasSlackIdentity = data.has_slack_identity;
  o.hasChromeExtension = data.has_chrome_extension;
  o.avatarImage = data.avatar_image;
  o.isActive = data.is_active;
  o.isAdmin = data.is_admin;
  o.isCrossbeamAdmin = data.is_crossbeam_admin;
  o.canManage = data.can_manage;
  o.hidden = data.hidden;
  o.hasLoggedIn = data.has_logged_in;
  o.emailNotification = data.email_notification;
  o.magicbellHash = magicbellHash;
  o.intercomHash = data.intercom_hash;
  o.settings = new OrgUserSettings(data.settings);
  o.sourceFirstName = data.source_first_name;
  o.sourceLastName = data.source_last_name;
  o.sourceProvider = data.source_provider;
  o.shareableInviteLink = data.shareable_invite_link;
  o.stateCode = data.state_code;
  o.isGhost = data.is_ghost;
  o.overlapsCount = data.overlaps_count;
  o.orgLogoUrl = data.org_logo_url;
  o.recentRequestCount = data.recent_request_count || 0;
  o.capabilities = data.capabilities
    ? {
        canManageUsers: data.capabilities.can_manage_users,
        canManageBilling: data.capabilities.can_manage_billing,
        canAccessSeatedExperience: data.capabilities.can_access_seated_experience,
        canManagePartnerships: data.capabilities.can_manage_partnerships,
        viewOnly: data.capabilities.view_only,
      }
    : {};
  return o;
};

interface INetworkProfile extends IMinimalUser {
  // Used to avoid circular imports
  org: {
    name: string,
    domain: string,
    logoUrl: ?string,
  };
}

class OrgUserNameOnly extends MinimalUser {
  id: string;

  orgId: string;

  firstName: string;

  lastName: string;

  orgName: string;

  orgDomain: string;

  orgLogoUrl: ?string;

  avatarImage: string;

  title: string;

  static fromApi = (d: any): OrgUserNameOnly => {
    const o = new OrgUserNameOnly();
    o.id = d.id;
    o.orgId = d.org_id;
    o.firstName = d.first_name;
    o.lastName = d.last_name;
    o.orgName = d.org_name;
    o.orgDomain = d.org_domain;
    o.orgLogoUrl = d.org_logo_url;
    o.avatarImage = d.avatar_image;
    o.title = d.title;
    return o;
  };

  static fromNetworkProfile = (n: INetworkProfile): OrgUserNameOnly =>
    OrgUserNameOnly.fromOrgUser(n, n.org.name, n.org.domain);

  static fromOrgUser = (n: IMinimalUser, orgName: string, orgDomain: string): OrgUserNameOnly => {
    const o = new OrgUserNameOnly();
    o.id = n.id;
    o.firstName = n.firstName;
    o.lastName = n.lastName;
    o.orgName = orgName;
    o.orgDomain = orgDomain;
    return o;
  };
}

class OrgUserAdminOnly extends OrgUserNameOnly {
  sourceUsername: string;

  email: string;

  static fromApi = (d: any): OrgUserAdminOnly => {
    const o = new OrgUserAdminOnly();
    o.firstName = d.first_name;
    o.lastName = d.last_name;
    o.email = d.email;
    o.sourceUsername = d.source_username;
    return o;
  };
}

export default OrgUser;

export { OrgUserAdminOnly, OrgUserNameOnly, OrgUserRoles };
