// @flow

import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';
import { createList } from 'data/repositories/lists';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import Modal from 'components/Modal';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  onUpdate: () => void,
  open: boolean,
  toggle: () => void,
};

const defaultFormState = {
  name: '',
  description: '',
};

const CreateListModal = ({ loggedInOrg, loggedInOrguser, onUpdate, open, toggle }: Props) => {
  const [formState, setFormState] = useState(defaultFormState);
  const [errors, setErrors] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  const onCreate = () => {
    setSubmitting(true);
    setErrors(undefined);
    createList(formState.name, formState.description)
      .then(() => {
        onUpdate();
        setSubmitting(false);
        setFormState(defaultFormState);
        toggle();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.json) {
          Object.keys(error.json).forEach((field) => {
            if (['name', 'description', 'nonFieldErrors'].includes(field)) {
              setErrors((s) => ({ ...s, [field]: error.json[field] }));
            }
          });
        }
      });
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      title="Create a new list"
      footer={
        <button
          type="button"
          onClick={onCreate}
          disabled={submitting}
          className="btn btn-primary text-center"
        >
          <FontAwesomeIcon icon="plus" className="mr-2" />
          Create
        </button>
      }
    >
      <FieldErrors errors={errors && errors.nonFieldErrors} />
      <div className="form-group mb-4">
        <Label>Name</Label>
        <input
          value={formState.name}
          onChange={(e) => {
            const { value } = e.target;
            setFormState((s) => ({ ...s, name: value }));
          }}
          placeholder="Name"
          className="form-control"
          name="name"
          required
        />
        <FieldErrors errors={errors && errors.name} />
      </div>
      <div className="form-group">
        <Label>Description</Label>
        <textarea
          value={formState.description}
          onChange={(e) => {
            const { value } = e.target;
            setFormState((s) => ({ ...s, description: value }));
          }}
          rows="3"
          placeholder="Description"
          className="form-control"
          name="description"
        />
        <FieldErrors errors={errors && errors.description} />
      </div>
    </Modal>
  );
};

export default withOrguser(CreateListModal);
