// @flow

import React from 'react';

import CompanyLogo from 'components/CompanyLogo';
import LoadingScreen from 'components/LoadingScreen';

const classes = 'rounded bg-gray-100';

const FakePartnerCard = () => (
  <div className="card mb-4">
    <div className="loading-animation d-flex flex-row align-items-center card-body gap-20">
      <div className={classes} style={{ width: 15, height: 15 }} />
      <CompanyLogo size={48} name="" className="align-self-center" />
      <div className="flex-fill d-flex flex-column gap-5">
        <div className={classes} style={{ width: 250, height: 20 }} />
        <div className={classes} style={{ width: 150, height: 16 }} />
      </div>
    </div>
  </div>
);

const LoadingPartners = () => (
  <LoadingScreen title="We are loading all your partners from Crossbeam.">
    <div className="w-100 row align-self-center">
      {Array.from({ length: 5 }, (x, i) => i).map((index) => (
        <FakePartnerCard key={index} />
      ))}
    </div>
  </LoadingScreen>
);

export default LoadingPartners;
