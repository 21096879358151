// @flow

import * as React from 'react';

import BareLayout from 'layouts/BareLayout';

export default function UserInactive() {
  return (
    <BareLayout centered>
      <h4>Account Inactive</h4>
      <span className="fs-13">
        Your account is inactive. Ask your organization&apos;s Crossbeam for Sales admin to enable
        it.
      </span>
    </BareLayout>
  );
}
