import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import useInIframe from './useInIframe';

/* Sync navigation if in iFrame */
const SyncIFrame = () => {
  const history = useHistory();
  const isInIframe = useInIframe();

  /* Alert parent of location change */
  const onLocationChange = debounce(
    (location) =>
      window.parent.postMessage(
        { type: 'locationChange', location: location.pathname + location.search },
        '*'
      ),
    50
  );
  useEffect(() => {
    if (!isInIframe) return () => null;

    const unlisten = history.listen((newLocation, action) => {
      onLocationChange(newLocation);
    });

    return () => unlisten();
  }, [onLocationChange, isInIframe, history]);

  /* Listen for location change from parent */
  useEffect(() => {
    if (!isInIframe) return () => null;

    const listener = (e) => {
      if (e.data.type !== 'changeLocation' || !e.data.to) return;
      history.push(e.data.to);
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [isInIframe, history]);

  return null;
};

export default SyncIFrame;
