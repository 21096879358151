// @flow

import Avatar from 'data/entities/avatar';

import authFetch from '../../authFetch/authFetch';
import { CrossbeamProfile } from '../entities/crossbeam';
import OrgUser from '../entities/orguser';
import User from '../entities/user';

export default async function completeProfile(firstName: string, lastName: string) {
  const body = { first_name: firstName, last_name: lastName };
  const method = 'PATCH';
  const fetchUrl = 'users/me';
  return authFetch(fetchUrl, { method, body }).then(User.fromApi);
}

export async function fetchUserAvatar(): Avatar {
  const getURL = 'users/me/avatar-file';
  return authFetch(getURL).then(Avatar.fromApi);
}

export async function submitUserAvatar(formData): Avatar {
  const method = 'PATCH';
  const fetchURL = 'users/me/avatar-file';
  return authFetch(fetchURL, { method, body: formData });
}

export async function fetchCrossbeamProfile(): CrossbeamProfile {
  const getURL = 'users/crossbeam-profile';
  return authFetch(getURL).then(CrossbeamProfile.fromApi);
}

export async function updateCrossbeamProfile(
  firstName: string,
  lastName: string,
  pronouns: string,
  department: string,
  jobTitle
): CrossbeamProfile {
  const getURL = 'users/crossbeam-profile';
  const body = {
    first_name: firstName,
    last_name: lastName,
    pronouns,
    department,
    job_title: jobTitle,
  };
  const method = 'POST';
  return authFetch(getURL, { method, body }).then((r) => ({
    user: User.fromApi(r.user),
    orguser: OrgUser.fromApi(r.orguser),
  }));
}
