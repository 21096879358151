// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import BaseOverlay from './BaseOverlay';

type Props = {
  loggedInOrg: Org,
  title: string,
  children: any,
};

const FinishSetupOverlay = ({ loggedInOrg: { isIntegrationSetup }, title, children }: Props) => (
  <BaseOverlay
    title={title}
    explanation="You are not getting the full value of Crossbeam for Sales because your organization is not set
    up."
    condition={!isIntegrationSetup}
    icon={<FontAwesomeIcon icon="lock" className="orange" size="2x" />}
  >
    {children}
  </BaseOverlay>
);

export default withOrguser(FinishSetupOverlay);
