// @flow

import * as React from 'react';

export default function RightArrow({ hasRequestCount }: { hasRequestCount: boolean }) {
  const classNamePlus = hasRequestCount ? 'hasRequestCount' : '';
  return (
    <svg
      className={`rightArrow ${classNamePlus}`}
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="100"
      viewBox="0 0 62 100"
    >
      <polygon points="11.83 100 61.83 50 11.83 0 .17 11.67 38.5 50 .17 88.33" />
    </svg>
  );
}
