import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserHiddenIcon = ({ user }) => {
  const ref = useRef(null);
  if (!user.hidden) {
    return null;
  }
  return (
    <>
      <FontAwesomeIcon forwardedRef={ref} icon="eye-slash" className="gray-500" />
      <UncontrolledTooltip placement="right" target={ref}>
        Hidden from Partners
      </UncontrolledTooltip>
    </>
  );
};

export default UserHiddenIcon;
