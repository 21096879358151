// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import ActIcon from './assets/ActIcon';
import AlertIcon from './assets/AlertIcon';
import AttributeIcon from './assets/AttributeIcon';
import BackgroundEffect from './assets/BackgroundEffect';
import UpgradeButton from './UpgradeButton';

type Props = {
  loggedInOrguser: OrgUser,
};

const ReplyOnlyBanner = ({ loggedInOrguser: { capabilities } }: Props) => (
  <div className="relative reply-only-banner d-flex flex-column gap-30">
    <div className="d-flex flex-row align-items-center">
      <div className="flex-fill d-flex flex-column">
        <div className="d-flex flex-row align-items-center gap-10">
          <span className="larger-text bolder gray-700">Unlock access to Co-Selling</span>
          <FontAwesomeIcon icon={['fad', 'wand-magic-sparkles']} className="purple" />
        </div>
        <span className="large-text semi-bold gray-600">
          Upgrade so you can start co-selling with your partners
        </span>
      </div>
      <UpgradeButton />
      {capabilities.canManageBilling && (
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <BackgroundEffect />
        </div>
      )}
    </div>
    <div className="d-flex flex-row gap-25" style={{ zIndex: 1 }}>
      <div className="py-3 px-4 d-flex flex-column gap-10 ro-alert">
        <AlertIcon />
        <span className="large-text bold gray-700">Alert</span>
        <span className="normal-text semi-bold gray-600">
          Instant partner overlap alerts to salespeople no matter where they are.
        </span>
      </div>
      <div className="py-3 px-4 d-flex flex-column gap-10 ro-action">
        <ActIcon />
        <span className="large-text bold gray-700">Act</span>
        <span className="normal-text semi-bold gray-600">
          Use co-selling templates and ask for intros and intel from partners in Slack Shared
          channels.
        </span>
      </div>
      <div className="py-3 px-4 d-flex flex-column gap-10 ro-attribute">
        <AttributeIcon />
        <span className="large-text bold gray-700">Attribute</span>
        <span className="normal-text semi-bold gray-600">
          Get credit for partnership influence and turn your attribution into actionable insights.
        </span>
      </div>
    </div>
  </div>
);

export default withOrguser(ReplyOnlyBanner);
