// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'qs';

import type { LocationT } from 'sharedTypes/reactRouter';

import BareLayout from 'layouts/BareLayout';

import withOrguser from '../../contexts/withOrguser';
import { Org } from '../../data/entities';
import { getHubspotIntegrationUrl } from '../../utils/urlUtils';

import HubspotCodeValidation from './HubspotCodeValidation';

type Props = {
  location: LocationT,
  loggedInOrg: Org,
};

function HubspotIntegration(props: Props) {
  const { location, loggedInOrg } = props;
  const { search } = location;
  const qs = queryString.parse(search, { ignoreQueryPrefix: true });
  const { code } = qs;

  if (!loggedInOrg.isIntegrationSetup) {
    return <Redirect to="/onboarding?workflow=hubspot" />;
  }

  if (!code) {
    window.location.href = getHubspotIntegrationUrl();
  }

  return (
    <BareLayout centered>
      <HubspotCodeValidation location={location} />
    </BareLayout>
  );
}

export default withOrguser(HubspotIntegration);
