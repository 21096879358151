// @flow

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account, Org, OrgUser } from 'data/entities';
import { AccountPartnerList } from 'data/entities/account';
import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import withOrguser from 'contexts/withOrguser';

import Questions from 'views/BdRequests/NewQuestionBdRequest/Questions';
import Summary from 'views/BdRequests/NewQuestionBdRequest/Summary';
import TalkingPoints from 'views/BdRequests/NewQuestionBdRequest/TalkingPoints';
import ViewerOverlay from 'components/SeatlessExperience/ViewerOverlay';

import AccountPartners from './AccountPartners';
import ChoosePartners from './ChoosePartners';

export const Step = {
  QUESTIONS: 0,
  SELECT_PARTNERS: 1,
  TALKING_POINTS: 2,
  PREVIEW: 3,
};

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  account: Account,
};

const Content = ({ loggedInOrg, loggedInOrguser, account }: Props) => {
  const [activeStep, setActiveStep] = useState(Step.QUESTIONS);
  const [selectedQuestions, setSelectedQuestions] = useState<BdRequestQuestion[]>([]);
  const [customQuestions, setCustomQuestions] = useState<string[]>([]);
  const [selectedPartners, setSelectedPartners] = useState<AccountPartnerList[]>([]);
  const defaultAnswers = loggedInOrg.settings.requestsWorkflowSettings.talkingPoints.map((_) => '');
  const [answers, setAnswers] = useState<string[]>(defaultAnswers);

  const reset = () => {
    setActiveStep(Step.QUESTIONS);
    setSelectedQuestions([]);
    setCustomQuestions([]);
    setSelectedPartners([]);
    setAnswers(defaultAnswers);
  };

  // Filter partners that can't ask all selected questions.
  const filterPartnersByQuestions = (
    questions: BdRequestQuestion[],
    partners: AccountPartnerList[]
  ) =>
    partners.filter((partner) => {
      const filtered = questions.filter(
        (q) => q.forPartners.some((v) => v.id === partner.partner.id) || q.isForAllPartners
      );
      return filtered.length === questions.length;
    });

  return (
    <div className="d-flex flex-row gap-25">
      <div className="d-flex flex-column gap-15" style={{ width: '45%' }}>
        <h5 className="m-0 bold gray-700 mb-2 pb-1">Get information from partner</h5>
        <ViewerOverlay
          title="Upgrade to Co-Seller to get intel from partners"
          explanation="Your current seat does not include this feature"
        >
          {loggedInOrguser.capabilities.viewOnly ? (
            <div className="card flex-grow-0 m-0 bg-gray-100 no-shadow p-3 d-flex flex-column gap-10">
              <div style={{ opacity: 0.5 }} className="d-flex flex-row align-items-center gap-10">
                <div
                  tabIndex={0}
                  role="button"
                  className="card w-max-content m-0 py-2 px-3 no-shadow d-flex flex-row align-items-center gap-10"
                >
                  <FontAwesomeIcon icon="message" className="blue" />
                  <span className="large-text bold gray-700 text-truncate">
                    Who are your main points of contact?
                  </span>
                  <span className="normal-text bold purple">Ask</span>
                </div>
              </div>
              <div style={{ opacity: 0.5 }} className="d-flex flex-row align-items-center gap-10">
                <div
                  tabIndex={0}
                  role="button"
                  className="card w-max-content m-0 py-2 px-3 no-shadow d-flex flex-row align-items-center gap-10"
                >
                  <FontAwesomeIcon icon="message" className="blue" />
                  <span className="large-text bold gray-700 text-truncate">
                    How far along are you in this deal?
                  </span>
                  <span className="normal-text bold purple">Ask</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {activeStep !== Step.PREVIEW ? (
                <>
                  <Questions
                    partners={[]}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={(qs) => {
                      setSelectedQuestions(qs);
                      if (qs.length === 0) {
                        setSelectedPartners([]);
                      }

                      setSelectedPartners(filterPartnersByQuestions(qs, selectedPartners));
                    }}
                    customQuestions={customQuestions}
                    setCustomQuestions={setCustomQuestions}
                    active={activeStep === Step.QUESTIONS}
                    next={() => setActiveStep(Step.SELECT_PARTNERS)}
                    inAccountDetail
                  />
                  <ChoosePartners
                    active={activeStep === Step.SELECT_PARTNERS}
                    selectedPartners={selectedPartners}
                    setSelectedPartners={(p: AccountPartnerList[]) =>
                      setSelectedPartners(filterPartnersByQuestions(selectedQuestions, p))
                    }
                    back={() => setActiveStep(Step.QUESTIONS)}
                    next={() => setActiveStep(Step.TALKING_POINTS)}
                    reset={reset}
                  />
                  <TalkingPoints
                    answers={answers}
                    setAnswers={setAnswers}
                    active={activeStep === Step.TALKING_POINTS}
                    back={() => setActiveStep(Step.SELECT_PARTNERS)}
                    next={() => setActiveStep(Step.PREVIEW)}
                    reset={reset}
                    inAccountDetail
                  />
                </>
              ) : (
                <Summary
                  partners={selectedPartners.map((p) => p.partner)}
                  account={account}
                  partnerAccounts={selectedPartners.map((p) => p.partnerAccount)}
                  selectedQuestions={selectedQuestions}
                  customQuestions={customQuestions}
                  answers={answers}
                  edit={() => setActiveStep(Step.TALKING_POINTS)}
                  reset={reset}
                  inAccountDetail
                />
              )}
            </>
          )}
        </ViewerOverlay>
      </div>
      <div className="d-flex flex-column gap-15" style={{ width: '55%' }}>
        <AccountPartners
          account={account}
          selectedPartners={selectedPartners}
          setSelectedPartners={setSelectedPartners}
          canSelect={activeStep !== Step.QUESTIONS}
          questions={selectedQuestions}
          hasCustomQuestions={customQuestions.length > 0}
        />
      </div>
    </div>
  );
};

export default withOrguser(Content);
