// @flow
// eslint-disable-next-line max-classes-per-file
import { get } from 'lodash';
import queryString from 'qs';

import {
  Account,
  AccountOwner,
  Partner,
  PartnerAccount,
  PartnerOrg,
  PartnerOrgUser,
  SalesCollateralLink,
} from 'data/entities';
import { CorrectBulkImport } from 'data/entities/chug';
import type { ExclusivePartnerFilter, OverlapSharingSetting } from 'data/entities/partner';
import { AccountOverlap, AssigneeCounts, OverlapSharingSettings } from 'data/entities/partner';
import { urlRawValueMap } from 'data/entities/status';
import authFetch from 'authFetch/authFetch';
import { AbortFetch, orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

export class PartnersCount {
  allPartners: number;

  mapping: number;

  isConnected: number;

  pendingInvites: number;

  notInvited: number;

  finishConnecting: number;

  syncEnabled: number;

  syncDisabled: number;

  static fromApi = (data: any): PartnersCount => {
    const pc = new PartnersCount();
    pc.allPartners = data.all_partners;
    pc.mapping = data.mapping;
    pc.isConnected = data.is_connected;
    pc.pendingInvites = data.pending_invites;
    pc.notInvited = data.not_invited;
    pc.finishConnecting = data.finish_connecting;
    pc.syncEnabled = data.sync_enabled;
    pc.syncDisabled = data.sync_disabled;
    return pc;
  };
}

export type PaginatedPartnerResponse = {
  results: PartnerOrg[],
  next: number,
  previous: number,
};

export class PartnerDetailAccountPartner {
  id: string;

  account: Account;

  partnerAccount: PartnerAccount;

  accountOwners: AccountOwner[];

  partnerAccountOwners: AccountOwner[];

  isPartnerTarget: boolean;

  isMyTarget: boolean;

  isMyTargetValue: ?string;

  isMyPinned: boolean;

  isPartnerPinned: boolean;

  requestCount: ?number;

  static fromApi: (data: any) => PartnerDetailAccountPartner;
}

export type AccountPartnerQueryParams = {
  accountOwner?: string,
  status?: Array,
  accountSearch?: string,
  partnerAccountOwner?: string,
  partnerStatus?: Array,
  partnerAccountSearch?: string,
  sort?: string,
  isTargetValue?: string,
  isTarget?: boolean,
  isPinned?: boolean,
};

PartnerDetailAccountPartner.fromApi = (data: any): PartnerDetailAccountPartner => {
  const {
    id,
    account,
    partner_account: partnerAccount,
    partner_accountowners: partnerAccountOwners,
    accountowners,
    is_my_target: isMyTarget,
    is_partner_target: isPartnerTarget,
    request_count: requestCount,
    is_my_target_value: isMyTargetValue,
    is_my_pinned: isMyPinned,
    is_partner_pinned: isPartnerPinned,
  } = data;
  const ap = new PartnerDetailAccountPartner();
  ap.id = id;
  ap.account = Account.fromApi(account);
  ap.partnerAccount = PartnerAccount.fromApi(partnerAccount);
  ap.accountOwners = accountowners ? AccountOwner.fromApiArray(accountowners) : [];
  ap.partnerAccountOwners = partnerAccountOwners
    ? AccountOwner.fromApiArray(partnerAccountOwners)
    : [];
  ap.isMyTarget = isMyTarget;
  ap.isPartnerTarget = isPartnerTarget;
  ap.requestCount = requestCount;
  ap.isMyTargetValue = isMyTargetValue;
  ap.isMyPinned = isMyPinned;
  ap.isPartnerPinned = isPartnerPinned;
  return ap;
};

export class PartnerDetailResponse extends Partner {
  // Extend Partner and inherits its fields

  accountPartners: PartnerDetailAccountPartner[];

  static fromApi: (data: any) => PartnerDetailResponse;
}

PartnerDetailResponse.fromApi = (data: any): PartnerDetailResponse => {
  const { account_partners: accountPartners } = data;
  const pr = new PartnerDetailResponse();
  const partner = Partner.fromApi(data);
  Object.assign(pr, partner);
  pr.accountPartners =
    accountPartners && accountPartners.map((ap) => PartnerDetailAccountPartner.fromApi(ap));
  return pr;
};

const abortPartnerFetch = new AbortFetch();

export function fetchPaginatedPartners(
  mutualOnly: boolean = false,
  status: ?ExclusivePartnerFilter,
  search: ?string = null,
  pageUrl: ?string = null
): Promise<PaginatedPartnerResponse> {
  const url = pageUrl || `${orgUrlBase()}/partners`;
  const queryParams = pageUrl
    ? null
    : { mutual_only: mutualOnly, exclusive_filter: status, partner_name: search };
  abortPartnerFetch.abort();
  return authFetch(url, { queryParams, signal: abortPartnerFetch.signal }).then(
    ({ results, ...res }) => ({
      ...res,
      results: results.map(Partner.fromApi),
    })
  );
}

export function fetchPartnersCount(): Promise<PartnersCount> {
  const url = `${orgUrlBase()}/partners/partner-counts`;
  return authFetch(url).then(PartnersCount.fromApi);
}

export function fetchPartnerBySlug(slug: string): Promise<Partner> {
  const url = `${orgUrlBase()}/partners/${slug}`;
  return authFetch(url).then(Partner.fromApi);
}

type PartnerIdResponse = {
  id: string,
  slug: string,
};

export function fetchPartnerIdsByXBRecordId(recordId: string): Promise<PartnerIdResponse> {
  const url = `${orgUrlBase()}/partners-from-xb-id/${recordId}`;
  return authFetch(url);
}

const cleanQueryPartnerAccounts = (query: AccountPartnerQueryParams): any => {
  const {
    status: queryStatus,
    segment,
    accountOwner,
    accountSearch,
    partnerStatus: queryPartnerStatus,
    partnerAccountOwner,
    sort,
    isTargetValue,
    isTarget,
    isPinned,
  } = query;
  const status = queryStatus ? queryStatus.map((s) => get(urlRawValueMap, s, s)) : [];
  const partnerStatus = queryPartnerStatus
    ? queryPartnerStatus.map((s) => get(urlRawValueMap, s, s))
    : [];
  return {
    status,
    segment,
    account_owner: accountOwner,
    account_search: accountSearch,
    partner_account_owner: partnerAccountOwner,
    partner_status: partnerStatus,
    sort,
    is_target_value: isTargetValue,
    is_target: isTarget,
    is_pinned: isPinned,
  };
};

const abortAccountFetch = new AbortFetch();

export function fetchPartnerAccountPartners(
  slug: string,
  limit: ?number = null,
  pageUrl: string,
  query: AccountPartnerQueryParams
): Promise<PaginatedResponse<PartnerDetailAccountPartner>> {
  const getUrl = () => {
    const params = cleanQueryPartnerAccounts(query);
    if (params.account_owner === 'all') {
      params.account_owner = undefined;
    }
    params.limit = limit;
    const qp = queryString.stringify(params, {
      skipNulls: true,
      encode: true,
      arrayFormat: 'brackets',
    });
    return `${orgUrlBase()}/partners/${slug}/account-partners?${qp}`;
  };
  const url = pageUrl || getUrl();
  abortAccountFetch.abort();
  const options = {
    signal: abortAccountFetch.signal,
  };
  return authFetch(url, options).then(mapPaginatedResponse(PartnerDetailAccountPartner.fromApi));
}

export function csvExportPartnerAccountPartners(
  slug: string,
  query: AccountPartnerQueryParams,
  CSVFields: string[]
): Promise<PaginatedResponse<PartnerDetailAccountPartner>> {
  const getUrl = () => {
    const params = cleanQueryPartnerAccounts(query);
    params.fields = CSVFields;
    const qp = queryString.stringify(params, {
      skipNulls: true,
      encode: true,
      arrayFormat: 'brackets',
    });
    return `${orgUrlBase()}/partners/${slug}/account-partners?${qp}`;
  };
  const url = getUrl();
  return authFetch(url, { headers: { Accept: 'text/csv' } }, true).then((response) =>
    response.blob()
  );
}

export function updatePartner(
  partnerSlug: string,
  { bdManagerId }: { bdManagerId: string }
): Promise<Partner> {
  const url = `${orgUrlBase()}/partners/${partnerSlug}`;
  const body = { bd_manager_id: bdManagerId };
  const options = { method: 'PATCH', body };
  return authFetch(url, options).then(Partner.fromApi);
}

export type PartnerApiSettings = {
  overlapSharingSetting: OverlapSharingSetting,
  overlapSharingSegments: ?(string[]),
  visibilitySettings: {
    accountStatusVisible: boolean,
    accountOwnerVisible: boolean,
    contactsVisible: boolean,
  },
  conversationSettings: { enabledDirectConversations: boolean },
  requestsWorkflowSettings: { allowCustomQuestions: boolean },
};

export function defaultAllOnPartnerApiSettings(): PartnerApiSettings {
  return {
    overlapSharingSetting: OverlapSharingSettings.ALL,
    overlapSharingSegments: undefined,
    visibilitySettings: {
      accountStatusVisible: true,
      accountOwnerVisible: true,
      contactsVisible: true,
    },
    conversationSettings: { enabledDirectConversations: true },
    requestsWorkflowSettings: { allowCustomQuestions: true },
  };
}

export function createPartner(
  bdManagerId: ?string,
  companyName: string,
  domain: ?string,
  sendInvite: ?boolean,
  firstName: ?string,
  lastName: ?string,
  email: ?string,
  invitedOrg: ?string,
  invitedOrgUserId: ?string,
  settings: ?PartnerApiSettings
): Promise<Partner> {
  const url = `${orgUrlBase()}/partners`;
  const body = { bd_manager_id: bdManagerId, name: companyName };
  if (sendInvite) {
    body.invite = {
      first_name: firstName,
      last_name: lastName,
      email,
      invited_org: invitedOrg,
      invited_user: invitedOrgUserId,
    };
  }
  if (domain) {
    body.domain = domain;
  }
  if (settings) {
    const {
      visibilitySettings,
      overlapSharingSetting,
      conversationSettings,
      requestsWorkflowSettings,
    } = settings;
    const { accountStatusVisible, accountOwnerVisible, contactsVisible } = visibilitySettings;
    const { enabledDirectConversations } = conversationSettings || {};
    const { allowCustomQuestions } = requestsWorkflowSettings || {};
    const apiSettings = {
      enable_account_mapping: !!overlapSharingSetting,
      share_overlap: overlapSharingSetting === 'ALL',
      overlap_sharing_setting: overlapSharingSetting,
      visibility_settings: {
        account_status_visible: accountStatusVisible,
        account_owner_visible: accountOwnerVisible,
        contacts_visible: contactsVisible,
      },
      conversation_settings: {
        enabled_direct_conversations: enabledDirectConversations,
      },
      requests_workflow_settings: {
        allow_custom_questions: allowCustomQuestions,
      },
    };
    body.settings = apiSettings;
  }
  const options = { method: 'POST', body };
  return authFetch(url, options).then(Partner.fromApi);
}

export function updatePartnerSettings(partnerSlug: string, settings: PartnerApiSettings) {
  const {
    visibilitySettings,
    overlapSharingSetting,
    overlapSharingSegments,
    conversationSettings,
    requestsWorkflowSettings,
  } = settings;
  const { accountStatusVisible, accountOwnerVisible, contactsVisible } = visibilitySettings;
  const { enabledDirectConversations } = conversationSettings || {};
  const { allowCustomQuestions } = requestsWorkflowSettings || {};
  const url = `${orgUrlBase()}/partners/${partnerSlug}`;
  const apiSettings = {
    enable_account_mapping: !!overlapSharingSetting,
    share_overlap: overlapSharingSetting === 'ALL',
    overlap_sharing_setting: overlapSharingSetting,
    visibility_settings: {
      account_status_visible: accountStatusVisible,
      account_owner_visible: accountOwnerVisible,
      contacts_visible: contactsVisible,
    },
    conversation_settings: {
      enabled_direct_conversations: enabledDirectConversations,
    },
    requests_workflow_settings: {
      allow_custom_questions: allowCustomQuestions,
    },
  };
  const body = { overlap_sharing_segments: overlapSharingSegments, settings: apiSettings };
  const options = { method: 'PATCH', body };
  return authFetch(url, options);
}

export function getPartnerOverlap(partnerSlug: string) {
  const url = `${orgUrlBase()}/partners/${partnerSlug}/account-overlap`;
  return authFetch(url).then(AccountOverlap.fromApi);
}

export function uploadAccountsToPartner(
  partnerSlug: string,
  isPartial: boolean,
  csvFile: File
): Promise<CorrectBulkImport> {
  const url = `${orgUrlBase()}/partners/${partnerSlug}/import-data`;
  const body = new FormData();
  body.append('csv_file', csvFile);
  const options = { method: isPartial ? 'PATCH' : 'POST', body };
  return authFetch(url, options).then(CorrectBulkImport.fromApi);
}

export function fetchPartnerLastSync(slug: string): Promise<any> {
  const url = `${orgUrlBase()}/partners/${slug}/last-sync`;
  return authFetch(url);
}

export function partnersBulkUpload(csvFile: File): Promise<Partner[]> {
  const url = `${orgUrlBase()}/bulk-upload-partners`;
  const body = new FormData();
  body.append('csv_file', csvFile);
  const options = { method: 'POST', body };
  return authFetch(url, options).then((results) => results.map(Partner.fromApi));
}

export function getPartnerContact(slug: string): Promise<PartnerOrgUser> {
  const url = `${orgUrlBase()}/partners/${slug}/bd-contact`;
  return authFetch(url).then(PartnerOrgUser.fromApi);
}

export function fetchOwnAccountOwners(
  slug: string,
  inviteable?: boolean
): Promise<PaginatedResponse<AccountOwner>> {
  const url = `${orgUrlBase()}/partners/${slug}/own-account-owners${
    inviteable ? '?inviteable=true' : ''
  }`;
  return authFetch(url).then(mapPaginatedResponse(AccountOwner.fromApi));
}

export function fetchPartnerAssigneeCounts(slug: string): Promise<AssigneeCounts> {
  const url = `${orgUrlBase()}/partners/${slug}/assignee-counts`;
  return authFetch(url).then(AssigneeCounts.fromApi);
}

export function setPartnerManager(
  partnerSlug: string,
  managerId: string | null,
  setToAssignedRequests: boolean,
  setToUnassignedRequests: boolean
): Promise<Partner> {
  const url = `${orgUrlBase()}/partners/${partnerSlug}/set-assignee`;
  const body = {
    bd_manager: managerId,
    set_to_assigned_requests: setToAssignedRequests,
    set_to_unassigned_requests: setToUnassignedRequests,
  };
  const options = { method: 'POST', body };
  return authFetch(url, options);
}

export function addSalesCollateralLink(
  partnerSlug: string,
  title: string,
  url: string
): Promise<SalesCollateralLink> {
  const endpointUrl = `${orgUrlBase()}/partners/${partnerSlug}/sales-collateral-links`;
  const body = { title, url };
  const options = { method: 'POST', body };
  return authFetch(endpointUrl, options);
}

export function removeSalesCollateralLink(
  partnerSlug: string,
  salesCollateralLinkId: string
): Promise<void> {
  const endpointUrl = `${orgUrlBase()}/partners/${partnerSlug}/sales-collateral-links/${salesCollateralLinkId}`;
  const options = { method: 'DELETE' };
  return authFetch(endpointUrl, options);
}

export function setPartnerDescription(
  partnerSlug: string,
  description: string
): Promise<PartnerOrg> {
  const endpointUrl = `${orgUrlBase()}/partners/${partnerSlug}`;
  const body = { description };
  const options = { method: 'PATCH', body };
  return authFetch(endpointUrl, options);
}
