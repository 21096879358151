// @flow

import * as React from 'react';

import type { LocationT } from 'sharedTypes/reactRouter';

import LoadingRing from 'components/LoadingRing';

import slackValidation from './slackValidation';

type Props = {
  location: LocationT,
  isLogin?: boolean,
};

type State = {
  errorMsg: ?string,
  loading: boolean,
  slackSetupRedirectTarget: string,
};

class SlackCodeValidation extends React.Component<Props, State> {
  state: State = { errorMsg: null, loading: true, slackSetupRedirectTarget: '/' };

  componentDidMount() {
    this.validateCode();
    const redirectTarget = localStorage.getItem('SlackSetupModal');
    if (redirectTarget) {
      this.setState({ slackSetupRedirectTarget: `/requests/${redirectTarget}?modal=open` });
      localStorage.removeItem('SlackSetupModal');
    } else {
      this.setState({ slackSetupRedirectTarget: '/' });
    }
  }

  async validateCode() {
    const { location, isLogin } = this.props;
    slackValidation(location, isLogin)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((errorMsg) => this.setState({ errorMsg, loading: false }));
  }

  render() {
    const { loading, errorMsg } = this.state;
    let main = (
      <div>
        <h4>Successfully connected your Slack account.</h4>
        <div className="text-right mt-3">
          <a href={this.state.slackSetupRedirectTarget} className="btn btn-primary">
            Done
          </a>
        </div>
      </div>
    );

    if (loading) {
      main = <LoadingRing text="Loading..." />;
    }
    if (errorMsg) {
      main = <h5>{errorMsg}</h5>;
    }

    return <div className="mt-5">{main}</div>;
  }
}

SlackCodeValidation.defaultProps = {
  isLogin: false,
};

export default SlackCodeValidation;
