// @flow

import React from 'react';
import { UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccountPartnerList } from 'data/entities/account';
import withOrguser from 'contexts/withOrguser';

import CompanyLogo from 'components/CompanyLogo';

type Props = {
  selectedPartners: AccountPartnerList[],
  setSelectedPartners: (partners: AccountPartnerList[]) => void,
  active: boolean,
  back: () => void,
  next: () => void,
  reset: () => void,
};

const ChoosePartner = ({
  selectedPartners,
  setSelectedPartners,
  active,
  back,
  next,
  reset,
}: Props) => {
  const deselectPartner = (partner: AccountPartnerList) => {
    setSelectedPartners(selectedPartners.filter((p) => p.id !== partner.id));
  };

  if (!active) {
    const names = selectedPartners.map((partner) => partner.partner.name).join(', ');
    return (
      <div className="card flex-grow-0 m-0 bg-gray-100 no-shadow p-3 d-flex flex-column gap-10">
        <div className="d-flex flex-row align-items-center gap-10">
          {selectedPartners.length === 0 ? (
            <>
              <div
                className="rounded-circle d-flex align-items-center justify-content-center small-text disable-selection bg-gray-200 gray-500"
                style={{ width: 28, height: 28 }}
              >
                2
              </div>
              <span className="large-text semi-bold gray-600">Choose partners to reach out to</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="circle-check" className="green" size="2x" />
              <span className="large-text bold gray-700 text-truncate">{names}</span>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="card flex-grow-0 no-shadow p-3 d-flex flex-column gap-10 m-0">
      <div className="d-flex flex-row align-items-center gap-10">
        <div
          className="rounded-circle d-flex align-items-center justify-content-center small-text disable-selection text-white bg-blue"
          style={{ width: 28, height: 28 }}
        >
          2
        </div>
        <span className="large-text bold gray-700">Choose partners to reach out to</span>
      </div>
      <div className="d-flex flex-row flex-wrap gap-15 py-2" style={{ minHeight: 150 }}>
        {selectedPartners.length === 0 ? (
          <span className="normal-text semi-bold gray-700">
            Choose partners from Partners list to the right 👉
          </span>
        ) : (
          selectedPartners.map((partner) => (
            <div
              className="card card-body no-shadow p-2 m-0 d-flex flex-row align-items-center gap-10 w-auto"
              style={{ flexGrow: 0, height: 'min-content' }}
              key={partner.id}
            >
              <CompanyLogo
                className="m-0"
                size={30}
                name={partner.partner.name}
                domain={partner.partner.domain}
                logoUrl={partner.partner.logoUrl}
              />
              <div className="d-flex flex-column flex-fill">
                <span className="large-text bold gray-700 text-nowrap">{partner.partner.name}</span>
                <span className="normal-text gray-500 text-nowrap">
                  {partner.partnerAccount.name}
                </span>
              </div>
              <button
                type="button"
                className="btn btn-link p-2"
                onClick={() => deselectPartner(partner)}
              >
                <FontAwesomeIcon icon="xmark" className="gray-400" />
              </button>
            </div>
          ))
        )}
      </div>
      <div className="d-flex flex-row align-items-center gap-15">
        <UncontrolledPopover
          className="PopoverCustom"
          innerClassName="card-body px-3"
          target="reset-form"
          trigger="click"
          delay={{ show: 100, hide: 200 }}
        >
          <div className="d-flex flex-column gap-15" style={{ maxWidth: 250 }}>
            <span className="normal-text bold gray-700">
              Are you sure that you want to cancel and reset this request?
            </span>
            <div>
              <button type="button" className="btn btn-danger" onClick={reset}>
                Confirm
              </button>
            </div>
          </div>
        </UncontrolledPopover>
        <button id="reset-form" type="button" className="btn btn-secondary">
          Cancel
        </button>
        <div className="flex-1" />
        <button type="button" className="btn btn-secondary" onClick={back}>
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={selectedPartners.length === 0}
          onClick={next}
        >
          Add context
        </button>
      </div>
    </div>
  );
};

export default withOrguser(ChoosePartner);
