// @flow

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteBdRequest } from 'data/repositories/bdrequests';

import Modal from 'components/Modal';

type Props = {
  requestId: string,
};

const DeleteBdRequest = ({ requestId }: Props) => {
  const history = useHistory();

  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((s) => !s);
  const doDelete = () =>
    deleteBdRequest(requestId).then(() => {
      history.push('/requests');
    });

  return (
    <>
      <div className="py-2 px-3 d-flex flex-column">
        <button type="button" className="btn btn-secondary normal-text bold" onClick={toggle}>
          <FontAwesomeIcon size="lg" className="cursor-pointer mr-2" icon="trash-alt" />
          <span className="gray-700">Delete Thread</span>
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title={<span>Delete Request</span>}
        footer={
          <>
            <Button className="gray-700" color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="danger" onClick={doDelete}>
              Delete
            </Button>
          </>
        }
        centered
      >
        <span className="large-text bold gray-700">
          Are you sure you want to delete this request?
        </span>
        <span className="normal-text semi-bold gray-600">
          The request will be deleted for you. Your partner will still be able to see the request if
          they replied. You cannot undo this action.
        </span>
      </Modal>
    </>
  );
};

export default DeleteBdRequest;
