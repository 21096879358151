// @flow

// For switching between the views.

import * as React from 'react';

import { Partner } from 'data/entities';

import PendingRequests from 'views/AccountMapping/PendingRequests';
import UnmappedAccountPartners from 'views/AccountMapping/UnmappedAccountPartners';
import UnmappedAccounts from 'views/AccountMapping/UnmappedAccounts';

import { cantMap, defaultView, pending, unmapped } from './constants';

type views = unmapped | pending;

type Props = {
  partnerSlug: string,
  partner: Partner,
  accountSlug: string,
};

type State = {
  view: views,
};

export default class PartnerAccountMapping extends React.Component<Props, State> {
  state: State = { view: defaultView };

  onChangeView = (nextView) => {
    this.setState({ view: nextView });
  };

  render() {
    const { partnerSlug, accountSlug, partner } = this.props;
    const { view } = this.state;
    switch (view) {
      case pending:
        return (
          <PendingRequests
            onChangeView={this.onChangeView}
            partnerSlug={partnerSlug}
            accountSlug={accountSlug}
            partner={partner}
          />
        );
      case cantMap:
        return (
          <UnmappedAccounts
            onChangeView={this.onChangeView}
            partnerSlug={partnerSlug}
            accountSlug={accountSlug}
            partner={partner}
          />
        );
      default:
        return (
          <UnmappedAccountPartners
            onChangeView={this.onChangeView}
            partnerSlug={partnerSlug}
            accountSlug={accountSlug}
            partner={partner}
          />
        );
    }
  }
}
