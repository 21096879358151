// @flow

import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { find, map } from 'lodash';

import { Partner, PartnerAccount, PartnerOrgUser } from 'data/entities/partner';
import {
  AccountDetailAccountPartner,
  listPartnerMappedAccountsByAccount,
} from 'data/repositories/accounts';
import { getPartnerContact } from 'data/repositories/partners';

type Props = {
  onHasResults: (hasResults: boolean) => void,
  onPartnerAccountChange: (partnerAccount: ?PartnerAccount) => void,
  partnerAccountId: ?string,
  partner: ?Partner,
  accountSlug: ?string,
  isEditable: ?boolean,
  isShared: ?boolean,
};

const StartSharedConversationForm = ({
  onHasResults,
  onPartnerAccountChange,
  partnerAccountId,
  partner,
  accountSlug,
  isEditable,
  isShared,
}: Props) => {
  const { bdManagerAtPartner, slug: partnerSlug } = partner || {};

  // Load partners.
  const [partners, setPartners] = useState<AccountDetailAccountPartner[]>([]);
  const [loadingPartners, setLoadingPartners] = useState(false);
  useEffect(() => {
    if (partnerSlug && accountSlug) {
      setLoadingPartners(true);
      listPartnerMappedAccountsByAccount(partnerSlug, accountSlug, partnerAccountId)
        .then((results) => {
          setLoadingPartners(false);
          setPartners(results);
          onHasResults(results.length > 0);

          // If only 1, set it.
          if (results.length === 1) {
            onPartnerAccountChange && onPartnerAccountChange(results[0].partnerAccount);
          }
        })
        .catch((error) => {
          setLoadingPartners(false);
          onHasResults(false);
        });
    } else {
      onHasResults(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSlug, accountSlug]);

  // Load manager.
  const [partnerBdContact, setPartnerBdContact] = useState<PartnerOrgUser | null>(null);
  const [loadingContact, setLoadingContact] = useState(false);
  useEffect(() => {
    if (!bdManagerAtPartner && partnerSlug) {
      setLoadingContact(true);
      getPartnerContact(partnerSlug)
        .then((manager) => {
          setLoadingContact(false);
          setPartnerBdContact(manager);
        })
        .catch((error) => {
          setLoadingContact(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSlug]);

  const showMissingSelect = !partnerSlug || !accountSlug || (partners && partners.length === 0);
  const optionMessage =
    partners && partners.length === 0
      ? 'No mappings found.'
      : 'Select a partner and account first.';
  const currentOption = find(map(partners, 'partnerAccount'), {
    id: partnerAccountId,
  });
  const contact = partnerBdContact || bdManagerAtPartner;

  if (!isShared) {
    return null;
  }

  return (
    <div className="py-1 bg-gray-100 rounded">
      <FormGroup className="d-flex align-items-center mb-0 mt-2">
        <Label className="col-form-label col-4 text-right">Partner Account</Label>
        {loadingPartners && <input placeholder="Loading..." className="form-control" disabled />}
        {showMissingSelect && (
          <select className="custom-select custom-select-md form-control full-width" disabled>
            <option>{optionMessage}</option>
          </select>
        )}
        {!showMissingSelect && isEditable ? (
          <select
            className="custom-select custom-select-md form-control full-width mr-2"
            name="partnerAccountId"
            value={partnerAccountId || ''}
            onChange={(event) =>
              onPartnerAccountChange(
                map(partners, 'partnerAccount').find((p) => p.id === event.target.value)
              )
            }
          >
            <option value="" disabled>
              Select one
            </option>
            {map(map(partners, 'partnerAccount'), (account) => (
              <option key={account.id} value={account.id}>
                {account.name} {account.statusRaw && `(${account.statusStr})`}
              </option>
            ))}
          </select>
        ) : (
          currentOption && (
            <span>
              {currentOption.name} {currentOption.statusRaw && currentOption.status}
            </span>
          )
        )}
      </FormGroup>
      <FormGroup className="d-flex align-items-center">
        <Label className="col-form-label col-4 text-right">Recipient</Label>
        {loadingContact ? (
          <input placeholder="Loading..." className="form-control" disabled />
        ) : (
          <span>
            {contact && contact.firstName} {contact && contact.lastName}
          </span>
        )}
      </FormGroup>
    </div>
  );
};

StartSharedConversationForm.defaultProps = {};

export default StartSharedConversationForm;
