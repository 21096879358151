/* eslint-disable max-classes-per-file */
// @flow

import Account from './account';
import { OrgUserNameOnly } from './orguser';
import { AccountOwner, PartnerAccount, PartnerBasic } from './partner';
import { AccountSignals } from './signal';

class List {
  id: string;

  name: string;

  description: string;

  createdAt: Date;

  createdBy: OrgUserNameOnly;

  assignedTo: OrgUserNameOnly;

  containsMappingId: boolean;

  isFavorite: boolean;

  isOwner: boolean;

  static fromApi = (d: any): List => {
    const l = new List();
    l.id = d.id;
    l.name = d.name;
    l.description = d.description;
    l.createdAt = d.created_at && new Date(d.created_at);
    l.createdBy = d.created_by && OrgUserNameOnly.fromApi(d.created_by);
    l.containsMappingId = d.contains_mapping_id;
    l.isFavorite = d.is_favorite;
    l.isOwner = d.is_owner;
    l.assignedTo = d.assigned_to && OrgUserNameOnly.fromApi(d.assigned_to);
    return l;
  };
}

export class ListAccountPartner {
  id: string;

  account: Account;

  accountOwners: AccountOwner[];

  partnerAccount: PartnerAccount;

  partnerAccountOwners: AccountOwner[];

  isPartnerTarget: boolean;

  isMyTarget: boolean;

  isMyTargetValue: ?string;

  isMyPinned: boolean;

  isPartnerPinned: boolean;

  partner: PartnerBasic;

  signals: AccountSignals;

  contactsCount: number;

  static fromApi = (data: any): ListAccountPartner => {
    const lap = new ListAccountPartner();
    lap.id = data.id;
    lap.account = data.account && Account.fromApi(data.account);
    lap.accountOwners = data.accountowners ? AccountOwner.fromApiArray(data.accountowners) : [];
    lap.partnerAccount = PartnerAccount.fromApi(data.partner_account);
    lap.partnerAccountOwners = data.partner_accountowners
      ? AccountOwner.fromApiArray(data.partner_accountowners)
      : [];
    lap.isMyTarget = data.is_my_target;
    lap.isPartnerTarget = data.is_partner_target;
    lap.isMyTargetValue = data.is_my_target_value;
    lap.isMyPinned = data.is_my_pinned;
    lap.isPartnerPinned = data.is_partner_pinned;
    lap.partner = data.partner && PartnerBasic.fromApi(data.partner);
    lap.signals = data.signals && AccountSignals.fromApi(data.signals);
    lap.contactsCount = data.contacts_count;
    return lap;
  };
}

export default List;
