// @flow
import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { getSlackChannels } from 'data/repositories/slack';

import { SlackChannel } from '../data/entities';

import SlackLogo from './SlackLogo';

type Props = {
  channels: Array<SlackChannel>,
  selectedChannel: SlackChannel | null | void,
  onSelect: (slackChannel: SlackChannel) => void,
  showSubText?: boolean,
  loadingChannels?: boolean,
  disabled?: boolean,
  ref: any,
  externalOnly?: boolean,
};

const SlackChannelSelector = ({
  loadingChannels,
  channels,
  selectedChannel,
  onSelect,
  showSubText,
  disabled,
  ref,
  externalOnly,
}: Props) => {
  const selectedChannelIsDeleted = selectedChannel?.isDeleted;
  const [query, setQuery] = useState(null);
  const [options, setOptions] = useState(channels);
  const [isLoading, setIsLoading] = useState(loadingChannels);

  const onSearch = debounce((searchQuery: string) => {
    setQuery(searchQuery || null);
    if (!searchQuery) return;
    setIsLoading(true);
    // Fetch channels from slack
    getSlackChannels(externalOnly, searchQuery)
      .then((results) => {
        setOptions(results.results.filter(({ isDeleted }) => !isDeleted));
      })
      .finally(() => setIsLoading(false));
  }, 300);

  return (
    <div className="position-relative" style={{ minWidth: 250 }}>
      <AsyncTypeahead
        labelKey={(option: SlackChannel) => `#${option.name}`}
        id="slack-channel-selector"
        options={(query ? options : channels) ?? []}
        placeholder="Search Slack channels..."
        onChange={(selected: Array<SlackChannel>) => onSelect(selected[0])}
        onSearch={onSearch}
        onInputChange={onSearch}
        selected={selectedChannel ? [selectedChannel] : []}
        isLoading={isLoading}
        emptyLabel="No channels found"
        inputProps={{ style: { paddingLeft: 35 } }}
        disabled={disabled}
        ref={ref}
        render
        clearButton
        ignoreDiacritics
        minLength={0}
      />
      {selectedChannelIsDeleted && showSubText && (
        <div className="orange small-text">
          Slack channel associated with this partner has been deleted
        </div>
      )}
      {selectedChannelIsDeleted ? (
        <FontAwesomeIcon
          icon="triangle-exclamation"
          width={16}
          height={16}
          className="pointer-events-none position-absolute orange mr-2"
          style={{ top: 10, left: 10 }}
        />
      ) : (
        <SlackLogo
          height={16}
          width={16}
          className="pointer-events-none position-absolute"
          style={{ top: 10, left: 10 }}
        />
      )}
    </div>
  );
};
SlackChannelSelector.defaultProps = {
  loadingChannels: false,
  showSubText: false,
  disabled: false,
  externalOnly: false,
};

export default SlackChannelSelector;
