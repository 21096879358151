// @flow

import * as React from 'react';

import { Org, OrgUser } from 'data/entities';
import { isProduction } from 'utils/environment';

import FullPageLoadingRing from 'components/FullPageLoadingRing';

const BENTO_TIMEOUT = 750;

type Props = {
  orguser: OrgUser,
  org: Org,
  children: React.Node,
};

type BentoContextPayload = { showGettingStarted: ?boolean };

type BentoEvent = CustomEvent & { detail: { isComplete: boolean } };

export const BentoLoaderContext = React.createContext<BentoContextPayload>({
  showGettingStarted: null,
});

function BentoLoaderProviderComponent(props: Props) {
  const [showGettingStarted, setGettingStarted] = React.useState(null);
  const { orguser, org, children } = props;
  const timeoutCheck = React.useRef(null);

  // Drop this into a component that will be rendered on every page
  React.useEffect(() => {
    if (typeof window === 'undefined') return () => {};
    if (typeof document === 'undefined') return () => {};
    const w: any = window;
    const d: any = document;

    const displayGettingStartedTab = (e: BentoEvent) => {
      timeoutCheck.current && clearTimeout(timeoutCheck.current);
      const shouldShowGettingStarted = !(e.detail && e.detail.isComplete);
      setGettingStarted(shouldShowGettingStarted);
    };

    const hideOnboardingTab = () => {
      timeoutCheck.current && clearTimeout(timeoutCheck.current);
      setGettingStarted(false);
    };

    const decideOnGuide = () => {
      if (showGettingStarted === null) {
        setGettingStarted(false);
      }
    };

    document.addEventListener('bento-noGuideFound', hideOnboardingTab);
    // $FlowIgnore
    document.addEventListener('bento-onGuideLoad', displayGettingStartedTab);
    timeoutCheck.current = setTimeout(decideOnGuide, BENTO_TIMEOUT);

    w.bentoSettings = {
      appId: '', // provided to Partnered by Bento
      account: {
        id: org.id, // set this to account or customer ID
        // id: 'partnered_test_acct_1',
        name: org.name, // the name of the customer (shown in Bento dashboard so CSMs can identify their accounts),
        createdAt: org.signUpAt,
        hasSlackIntegration: org.hasSlackIntegration,
      },
      accountUser: {
        id: orguser.id, // set this to the user ID
        // id: 'partnered_test_acct_usr_1',
        email: orguser.email, // optional, but recommended
        fullName: orguser.fullName, // optional, but recommended
        // avatarImage: '<USER AVATAR URL>', // optional, but recommended
        role: orguser.role,
      },
    };
    const s = d.createElement('script');
    s.src = 'https://assets.trybento.co/bento-embed.js';
    s.async = true;
    s.type = 'module';
    d.body.appendChild(s);

    return () => {
      d.body.removeChild(s);
      // $FlowIgnore
      document.removeEventListener('bento-onGuideLoad', displayGettingStartedTab);
      document.removeEventListener('bento-noGuideFound', hideOnboardingTab);
      clearTimeout(timeoutCheck.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    org.id,
    org.name,
    org.signUpAt,
    org.hasSlackIntegration,
    orguser.id,
    orguser.email,
    orguser.fullName,
    orguser.role,
  ]);

  return (
    <BentoLoaderContext.Provider value={{ showGettingStarted }}>
      {showGettingStarted === null ? <FullPageLoadingRing text="Logging in..." /> : children}
    </BentoLoaderContext.Provider>
  );
}

type ProviderProps = {
  children: React.Node,
  orguser: ?OrgUser,
  org: ?Org,
};

export function BentoLoaderProvider({ org, orguser, children }: ProviderProps) {
  if (isProduction) {
    // Hard disable bento for now
    return children;
  }
  if (!(orguser && org && org.name) || !isProduction) {
    return children;
  }

  return (
    <BentoLoaderProviderComponent org={org} orguser={orguser}>
      {children}
    </BentoLoaderProviderComponent>
  );
}
