// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

import setTitle from 'utils/setTitle';

import BareLayout from 'layouts/BareLayout';

import Header from './Header';

export default class SalesforceDataSuccess extends React.PureComponent {
  componentDidMount() {
    setTitle('Securely Connected to Salesforce');
  }

  componentWillUnmount() {
    setTitle();
  }

  render() {
    return (
      <BareLayout centered afterPageContainer={<Header title="Securely Connected to Salesforce" />}>
        <Link to="/onboarding" className="btn btn-primary btn-lg mt-3 clearfix">
          Continue &raquo;
        </Link>
      </BareLayout>
    );
  }
}
