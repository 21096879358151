// @flow

import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { debounce } from 'lodash';

import NetworkProfile from 'data/entities/networkprofile';
import { fetchNetworkProfiles } from 'data/repositories/networkprofile';
import useParams from 'utils/useParams';

import Loading from './Loading';
import NetworkCard from './NetworkCard';

const NetworkList = () => {
  const [{ search }, setParams] = useParams({
    search: null,
  });

  const onSearch = debounce((value: string) => setParams({ search: value }), 500);

  const [networkProfiles, setNetworkProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchNetworkProfiles({ name: search }).then((results) => {
      setNetworkProfiles(results.results);
      setLoading(false);
    });
  }, [search]);

  return (
    <div className="w-100 d-flex flex-column gap-30">
      <div className="d-flex flex-md-row flex-column justify-content-center gap-10">
        <div className="d-flex flex-row flex-fill justify-content-center gap-10">
          <h3 className="m-0 flex-fill">My Network</h3>
          <Input
            style={{ maxWidth: '350px' }}
            type="text"
            name="search"
            placeholder="🔎  Search connections by name or partner"
            defaultValue={search}
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!networkProfiles.length ? (
            <div className="text-center">
              <h6>No connections yet.</h6>
              <p className="mb-3 normal-text semi-bold gray-500">
                Once you connect with other pepople you will see them here.
              </p>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <div className="w-100 row">
                {networkProfiles &&
                  networkProfiles.map((networkProfile: NetworkProfile) => (
                    <div className="col-md-6" key={networkProfile.slug}>
                      <NetworkCard networkProfile={networkProfile} />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NetworkList;
