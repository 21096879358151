// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Org, OrgUser } from 'data/entities';
import { statusChoiceList, statusToUrlValue } from 'data/entities/bdrequest';
import withOrguser from 'contexts/withOrguser';
import { isProduction } from 'utils/environment';

import ReplyOnlyUpgradeBanner from 'components/SeatlessExperience/ReplyOnlyUpgradeBanner';

type Props = {
  children: any,
  afterHeaderInner: any,
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
};

const HeaderContainer = ({ children, afterHeaderInner, loggedInOrguser, loggedInOrg }: Props) => {
  const location = useLocation();
  const isReplyOnlyExperience =
    loggedInOrguser && !loggedInOrguser.capabilities.canAccessSeatedExperience;

  const showBannerInMessage = location.pathname.match(
    `/requests/(?!(all|${statusChoiceList.map(statusToUrlValue).join('|')}))`
  );

  return (
    <>
      {isProduction && loggedInOrguser?.isGhost && (
        <div className="bg-orange large-text semibold white d-flex align-items-center py-1 justify-content-center">
          You are logged into production ({loggedInOrg.name}) as an admin!
        </div>
      )}
      {isReplyOnlyExperience && showBannerInMessage && <ReplyOnlyUpgradeBanner />}
      <div
        className="header p-r-0"
        style={isReplyOnlyExperience ? { boxShadow: 'none', background: 'transparent' } : {}}
      >
        <div
          className={classNames(
            'header-inner header-md-height',
            isReplyOnlyExperience && 'container pt-3'
          )}
          style={isReplyOnlyExperience ? { maxWidth: 1290 } : {}}
        >
          {children}
        </div>
        {afterHeaderInner}
      </div>
    </>
  );
};

export default withOrguser(HeaderContainer);
