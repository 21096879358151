// @flow

import { OrgUserNameOnly } from 'data/entities/orguser';

interface NewComment {
  text: string;

  isShared: boolean;
}

class RequestComment {
  id: string;

  orguser: OrgUserNameOnly;

  text: string;

  dashboardUrl: string;

  createdAt: Date;

  updatedAt: Date;

  type: string;

  requestId: string;

  isShared: boolean;

  get isUpdate() {
    return this.type === 'UPDATE';
  }

  get isInternal() {
    return !this.isShared;
  }

  static fromApi: (data: any) => RequestComment;
}

RequestComment.fromApi = (d: any): RequestComment => {
  const c = new RequestComment();
  c.id = d.id;
  c.orguser = d.orguser && OrgUserNameOnly.fromApi(d.orguser);
  c.text = d.text;
  c.dashboardUrl = d.dashboard_url;
  c.createdAt = d.created_at && new Date(d.created_at);
  c.updatedAt = d.updated_at && new Date(d.updated_at);
  c.type = d.type;
  c.requestId = d.bdrequest_id;
  c.isShared = d.is_shared;
  return c;
};

export type { NewComment };

export default RequestComment;
