// @flow

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { uniqueId } from 'lodash';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import EventBus from 'utils/EventBus';
import useWindowSize from 'utils/useWindowSize';

import type { MessageUpdate } from 'views/BdRequests/NewBdRequest/NewBdRequestForm';

import Message from './Message';

type Props = {
  loggedInOrguser: OrgUser,
};

const Messaging = ({ loggedInOrguser: { id: orgUserId } }: Props) => {
  const messageCount = useRef(0);
  const key = `drafts-${orgUserId}`;
  const [messages, setMessages] = useState([]);

  const { width } = useWindowSize();
  const messagesGap = 15;
  // Calculate max visible messages depending on width of the screen.
  const maxVisibleMessages = useMemo(() => {
    // Start with the sum gaps between all columns.
    let widthOfMessages = (messages.length - 1) * messagesGap;
    // Calculate total width of messages.
    for (let i = messages.length - 1; i >= 0; i -= 1) {
      const message = messages[i];
      // BUGGY collapsed isn't updated because of bad state management
      // caused by saving messages to drafts instead of reactive state
      // FIXME in next update by refactoring the messaging form to be controllable
      widthOfMessages += message.collapsed ? 250 : 450;

      // If width of messages up to this point is bigger than screen width
      // Return maximum of columns that can fit ( excluding the last one ).
      if (widthOfMessages > width) {
        return Math.max(messages.length - (i + 1), 1);
      }
    }
    return messages.length;
  }, [width, messages]);

  // Scroll to end if a message was added.
  useEffect(() => {
    if (messageCount.current < messages.length) {
      const wrapper = document.getElementById('messaging-wrapper');
      if (wrapper) {
        wrapper.scrollTo({
          left: wrapper.scrollWidth + 10000,
          behavior: 'smooth',
        });
      }
    }
    messageCount.current = messages.length;
  }, [messages.length]);

  const getDrafts = useCallback(
    () => JSON.parse(localStorage.getItem(key) || '[]').sort((a, b) => a.id - b.id),
    [key]
  );
  // Remove sent messages.
  const setDrafts = useCallback(
    (drafts) =>
      localStorage.setItem(
        key,
        JSON.stringify(
          drafts
            .reverse()
            .filter((x) => x.createdRequestId === false || x.createdRequestId === null)
        )
      ),
    [key]
  );

  useEffect(() => {
    const drafts = getDrafts();
    if (drafts.length) {
      setMessages(drafts);
    }
    EventBus.on('newMessage', (message) => {
      const newMessage = { ...message, id: `${Date.now()}${uniqueId()}` };
      setMessages((m) => {
        setDrafts([...getDrafts(), newMessage]);
        return [...m, newMessage];
      });
    });
    return () => {
      EventBus.remove('newMessage');
    };
  }, [getDrafts, setDrafts]);

  return (
    <div className="MessagingWrapper" id="messaging-wrapper">
      <div className="flex-fill" />
      <div
        className="d-flex flex-row gap-15 justify-content-end align-items-end"
        style={{ pointerEvents: 'none' }}
      >
        {messages.map((message, index) => (
          <Message
            message={message}
            key={message.id}
            show={messages.length - index - 1 < maxVisibleMessages}
            discard={() => {
              setMessages((m) => m.filter((mess) => mess.id !== message.id));
              setDrafts(getDrafts().filter((mess) => mess.id !== message.id));
            }}
            onUpdate={(update: MessageUpdate) =>
              setDrafts(
                getDrafts()
                  .filter((mess) => mess.id !== message.id)
                  .concat([{ ...message, ...update }])
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

export default withOrguser(Messaging);
