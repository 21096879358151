// @flow

import authFetch from 'authFetch/authFetch';
import { deleteAuthToken, getToken } from 'authFetch/utils';

export default function logout() {
  return getToken()
    ? authFetch('auth/logout/', { method: 'POST', credentials: 'include' }).finally(deleteAuthToken)
    : Promise.resolve();
}
