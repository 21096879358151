// @flow

import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { OrgUser } from 'data/entities';
import { BentoLoaderContext } from 'contexts/BentoLoaderContext';
import withOrguser from 'contexts/withOrguser';

type NavbarItemProps = {
  title: string,
  link: string,
  currentPath: string,
  icon?: ?string,
};

const NavbarItem = ({ title, link, currentPath, icon }: NavbarItemProps) => {
  const active = currentPath.includes(link.split('?')[0]);
  return (
    <li>
      <Link to={link} title={title}>
        <span className={classNames('navigation-item', active && 'bg-light-blue blue')}>
          {icon && <FontAwesomeIcon icon={icon} />}
          <span className="d-xl-block d-none">{title}</span>
        </span>
      </Link>
    </li>
  );
};

NavbarItem.defaultProps = {
  icon: null,
};

type Props = {
  showGettingStarted: ?boolean,
  loggedInOrguser: OrgUser,
};

export function navigatorDefaultUrl(): string {
  // Include default query params in DN link for best Deal Navigator experience
  return `/navigator?relevance=sales_person&partnerStatus=CLOSED:WON&status=COLD,PROSPECT`;
}

const PrimaryNavbarInner = withOrguser(
  ({ showGettingStarted, loggedInOrguser: { canManage } }: Props) => {
    const {
      location: { pathname },
    } = useHistory();

    return (
      <div>
        <div className="main-menu-bar menu-bar header-sm-height" data-hide-extra-li="1">
          <ul>
            {showGettingStarted && (
              <NavbarItem title="Getting Started" link="/getting-started" currentPath={pathname} />
            )}
            <NavbarItem
              title="Navigator"
              icon="location-arrow"
              link={navigatorDefaultUrl()}
              currentPath={pathname}
            />
            <NavbarItem
              title="My Network"
              icon="user-friends"
              link="/network/p?status=sync_enabled"
              currentPath={pathname}
            />
            <NavbarItem
              title="Messaging"
              icon="comment-dots"
              link="/requests"
              currentPath={pathname}
            />
            <NavbarItem title="Lists" icon="list" link="/lists" currentPath={pathname} />
          </ul>
        </div>
      </div>
    );
  }
);

const PrimaryNavbar = () => (
  <BentoLoaderContext.Consumer>
    {({ showGettingStarted }) => <PrimaryNavbarInner showGettingStarted={showGettingStarted} />}
  </BentoLoaderContext.Consumer>
);

export default PrimaryNavbar;
