// @flow

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser, Partner } from 'data/entities';
import { fetchPartnerAssigneeCounts, setPartnerManager } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';
import PersonAvatar from 'components/PersonAvatar';
import UserEditSearch from 'components/UserEditSearch/UserEditSearch';

type Props = {
  bdManager: OrgUser | null,
  partner: Partner,
  onUpdate: () => void,
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const SetBdManager = ({ loggedInOrg, loggedInOrguser, bdManager, partner, onUpdate }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const [counts, setCounts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assignedChecked, setAssignedChecked] = useState(false);
  const [unassignedChecked, setUnassignedChecked] = useState(false);

  const [manager, setManager] = useState<OrgUser | null>(bdManager);
  const [errors, setErrors] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  const onSave = (newOrguser: OrgUser) => {
    setSubmitting(true);
    setErrors({});
    setPartnerManager(partner.slug, manager ? manager.id : null, assignedChecked, unassignedChecked)
      .then(() => {
        setSubmitting(false);
        setOpen(false);
        onUpdate();
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors(error);
      });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      setManager(bdManager);

      fetchPartnerAssigneeCounts(partner.slug)
        .then((c) => {
          setCounts(c);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors(error);
        });
    }
  }, [open, bdManager, partner]);

  if (!loggedInOrguser.capabilities.canManagePartnerships) {
    return (
      <span className="normal-text semi-bold gray-500">
        Managed By: <span className="gray-700">{bdManager?.fullName || 'Not Assigned'}</span>
      </span>
    );
  }

  return (
    <>
      <div className="d-flex align-items-baseline">
        <span className="normal-text semi-bold gray-500 mr-2">Managed By:</span>
        {bdManager ? (
          <button type="button" className="btn btn-link blue m-0 p-0" onClick={toggle}>
            <PersonAvatar
              avatar={bdManager.avatarImage}
              firstName={bdManager.firstName}
              lastName={bdManager.lastName}
              size={20}
            />
            <span className="px-1">{bdManager.fullName}</span>
            <FontAwesomeIcon icon="pencil" className="ml-2" size="sm" />
          </button>
        ) : (
          <button type="button" className="btn btn-xs btn-secondary" onClick={toggle}>
            <FontAwesomeIcon icon="user" className="mr-2" />
            Not Assigned
            <FontAwesomeIcon icon="pencil" className="ml-2" size="sm" />
          </button>
        )}
      </div>
      <Modal
        isOpen={open}
        toggle={toggle}
        title="Assigning a partner manager"
        footer={
          <>
            <button
              type="button"
              disabled={submitting}
              className="btn btn-secondary"
              onClick={toggle}
            >
              Close
            </button>
            <button
              type="button"
              disabled={submitting}
              onClick={onSave}
              className="btn btn-primary text-center"
            >
              Confirm
            </button>
          </>
        }
      >
        <div className="d-flex flex-column">
          <FieldErrors errors={errors && errors.nonFieldErrors} />
          <span className="large-text bold black pb-2">Partner Manager</span>
          <UserEditSearch
            style={{ flex: 1 }}
            existingUser={manager}
            emptyExisting={<span className="gray-700">Not assigned</span>}
            onSave={(ou) => Promise.resolve(setManager(ou))}
            placeholder="Search for Internal BD Manager"
            emptyLabel="No matching BD managers found"
            displayTextComponent={({ children }) => (
              <span className="normal-text semi-bold gray-700">{children}</span>
            )}
            canManagePartnerships
          />
          <span className="large-text bold black pt-3 pb-2">
            Assign to {partner.name} open conversations
          </span>
          {loading ? (
            <div>
              <LoadingRing maxWidth="20px" />
            </div>
          ) : (
            <>
              <button
                type="button"
                onClick={() => setUnassignedChecked((v) => !v)}
                className="btn-link d-flex flex-row align-items-center gap-10 normal-text gray-700"
              >
                <input type="checkbox" checked={unassignedChecked} readOnly />(
                {counts?.isUnassigned}) without a manager assigned
              </button>
              <button
                type="button"
                onClick={() => setAssignedChecked((v) => !v)}
                className="btn-link d-flex flex-row align-items-center gap-10 normal-text gray-700"
              >
                <input type="checkbox" checked={assignedChecked} readOnly />({counts?.isAssigned})
                with a manager assigned
              </button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default withOrguser(SetBdManager);
