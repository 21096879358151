// @flow

import * as React from 'react';

type Props = {
  marginTop?: string,
};
export default function FakeCard({ marginTop }: Props) {
  const fadedColor = '#f4f5f7';
  const imgStyle = { backgroundColor: fadedColor, height: '64px', width: '64px', margin: 'auto' };
  const grayed = { backgroundColor: fadedColor };
  return (
    <div className="card" style={{ marginTop, borderColor: fadedColor }}>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <div className="circular thumbnail-wrapper" style={imgStyle} />
            </div>
            <div className="col-11">
              <div className="row">
                <div className="col-10">
                  <div className="rounded m-1" style={grayed}>
                    &nbsp;
                  </div>
                </div>
                <div className="col-2">
                  <div className="rounded m-1" style={grayed}>
                    &nbsp;
                  </div>
                </div>
                <div className="w-100" />
                <div className="col-2">
                  <div className="rounded m-1" style={grayed}>
                    &nbsp;
                  </div>
                </div>
                <div className="col-8 rounded">
                  <div className="rounded m-1">&nbsp;</div>
                </div>
                <div className="col-2">
                  <div className="rounded m-1" style={grayed}>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FakeCard.defaultProps = {
  marginTop: '0px',
};
