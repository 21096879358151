// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { groupBy } from 'lodash';

import { Account, Org, OrgUser } from 'data/entities';
import type { AccountPartnerList } from 'data/entities/account';
import { activeOppRaw, coldRaw, getStatusStr, wonRaw } from 'data/entities/status';
import withOrguser from 'contexts/withOrguser';
import useOrgProvider from 'utils/useOrgProvider';

import PartnerDetailHoverCard from 'views/PartnerDetail/PartnerDetailHoverCard';
import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import PersonAvatar from 'components/PersonAvatar';
import Signals from 'components/Signals';

import InviteUsers from '../Users/InviteUsers';

import PartnersModal from './PartnersModal';

type Props = {
  account: Account,
  isLoaded: boolean,
  style: { [key: string]: string },
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
  globalInvitedAOs?: Array<string>,
  setGlobalInvitedAOs?: (value: string) => void,
};

const AccountCard = ({
  loggedInOrguser,
  loggedInOrg,
  account,
  isLoaded,
  style,
  globalInvitedAOs,
  setGlobalInvitedAOs,
}: Props) => {
  const {
    slug,
    name,
    websiteNormalized,
    accountOwners,
    displayDealAmount,
    status,
    accountPartners,
    contactsCount,
    signals,
  } = account || {};
  const { isProviderCrossbeam } = useOrgProvider();

  const inviteableAOs = useMemo(() => accountOwners?.filter((ao) => ao.inviteable), [
    accountOwners,
  ]);
  const [invitedAOs, setInvitedAOs] = useState(false);

  useEffect(() => {
    if (inviteableAOs?.some((ao) => globalInvitedAOs.includes(ao.email))) {
      setInvitedAOs(true);
    }
  }, [inviteableAOs, globalInvitedAOs]);

  const byStatus = groupBy(accountPartners, 'partnerAccount.statusRaw');
  const contactsTxt = `(${contactsCount}) partner contacts available`;
  return (
    <div style={style}>
      {isLoaded ? (
        <div className="card m-0">
          <div className="d-flex flex-column card-body gap-20">
            {/* Account information - left */}
            <div className="d-flex flex-row align-items-top gap-10">
              <CompanyLogo
                size={48}
                name={name}
                domain={websiteNormalized}
                className="align-self-center"
              />
              <div className="d-flex flex-column flex-fill no-overflow justify-content-center">
                <div className="d-flex flex-row align-items-center gap-10">
                  <Link
                    to={`/a/${slug}`}
                    className="bold large-text text-truncate btn-link gray-700 fs-mask"
                  >
                    {name}
                  </Link>
                </div>
                {!isProviderCrossbeam && (
                  <div
                    className={classNames('normal-text gray-500 gap-10', contactsCount && 'bold')}
                  >
                    {contactsCount ? (
                      <Link to={`/a/${slug}/contacts`}>{contactsTxt}</Link>
                    ) : (
                      contactsTxt
                    )}
                  </div>
                )}
              </div>

              {/* Account information - right */}
              <div className="d-flex  flex-column align-items-end">
                <div className="d-flex gap-10 align-items-center fs-mask">
                  {displayDealAmount && <span>{`My open deals: ${displayDealAmount}`}</span>}
                  {status}
                </div>
                <div className="d-flex align-items-center fs-mask">
                  {!!accountOwners &&
                    accountOwners.slice(0, 3).map((owner, index) => (
                      <React.Fragment key={owner.email}>
                        <span>{owner.fullName}</span>
                        {index !== accountOwners.length - 1 && (
                          <h3 className="mx-1 my-0 p-0 lh-10">·</h3>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>

            <Signals signals={signals} />

            {/* Partners */}
            <div className="d-flex flex-row align-items-center gap-10">
              {[wonRaw, activeOppRaw, coldRaw].map((statusKey: string) => (
                <PartnerCustomers
                  key={statusKey}
                  statusKey={statusKey}
                  sortedPartners={byStatus[statusKey] || []}
                  account={account}
                />
              ))}
            </div>

            {/* Inviteable account owners */}
            {loggedInOrguser.canManage && !!inviteableAOs?.length && (
              <div className="d-flex flex-row align-items-center gap-5">
                <div className="d-flex flex-row align-items-center">
                  {inviteableAOs.slice(0, 3).map((ao, index) => (
                    <div style={index !== 0 ? { marginLeft: '-8px' } : {}} key={ao.id}>
                      <PersonAvatar
                        firstName={ao.firstName}
                        lastName={ao.lastName}
                        avatar={ao.avatarImage}
                        noMargin
                        size={20}
                      />
                    </div>
                  ))}
                </div>
                <span className="small-text semi-bold gray-700">{`${inviteableAOs
                  .map((ao) => ao.firstName)
                  .slice(0, 2)
                  .join(', ')}${
                  inviteableAOs.length > 2 ? ` and ${inviteableAOs.length - 2} other` : ''
                } should see this account.`}</span>
                {invitedAOs ? (
                  <FontAwesomeIcon icon="check" size="md" className="green" />
                ) : (
                  <InviteUsers defaultEmails={inviteableAOs.map((ao) => ao.email)}>
                    <button className="btn btn-link small-text p-0 blue" type="button">
                      <FontAwesomeIcon icon="plus" className="mr-1" />
                      Invite them
                    </button>
                  </InviteUsers>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="m-0 card h-100 d-flex justify-content-center">
          <LoadingRing maxWidth="50px" />
        </div>
      )}
    </div>
  );
};

type PartnerCustomersTypes = {
  statusKey: string,
  sortedPartners: AccountPartnerList[],
  account: Account,
};

const PartnerCustomers = ({ statusKey, sortedPartners, account }: PartnerCustomersTypes) => {
  const [isOpen, setIsOpen] = useState(false);
  const byPartner = groupBy(sortedPartners, 'partner.slug');
  const partners = Object.keys(byPartner);

  if (!partners.length) {
    return null;
  }

  return (
    <>
      <PartnersModal
        statusKey={statusKey}
        account={account}
        sortedPartners={sortedPartners}
        isOpen={isOpen}
        toggle={() => setIsOpen((v) => !v)}
      />
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn d-flex flex-row align-items-center account-card-partners gap-5"
      >
        <div className="d-flex flex-row align-items-center">
          {sortedPartners.slice(0, 3).map(({ partner }, index) => (
            <div
              style={index !== 0 ? { marginLeft: '-15px' } : {}}
              key={`${statusKey}-${partner.slug}`}
            >
              <PartnerDetailHoverCard partner={partner} noClickables>
                <CompanyLogo
                  name={partner.name}
                  domain={partner.domain}
                  logoUrl={partner.logoUrl}
                  className=""
                  size={30}
                />
              </PartnerDetailHoverCard>
            </div>
          ))}
        </div>
        <span className="small-text semi-bold gray-500 ">{`${
          partners.length
        } Partner ${getStatusStr(statusKey, false, partners.length !== 1)}`}</span>
      </button>
    </>
  );
};

AccountCard.defaultProps = {
  globalInvitedAOs: [],
  setGlobalInvitedAOs: undefined,
};

export default withOrguser(AccountCard);
