// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import User from 'data/entities/user';
import { UserContext } from 'contexts';
import { OrgUserContext } from 'contexts/OrgUserContext';

import BareLayout from 'layouts/BareLayout';

import CompleteProfileForm from './CompleteProfileForm';

type Props = {
  user: User,
  updateUser: (user: User) => void,
};

function existingUserMustCompleteProfile(user: User) {
  return !user || !user.firstName || !user.lastName;
}

export function mustCompleteProfile(user) {
  return existingUserMustCompleteProfile(user);
}

function CompleteProfile(props: Props) {
  const { user } = props;
  if (user && !existingUserMustCompleteProfile(user)) {
    return <Redirect to="/onboarding" />;
  }
  return (
    <BareLayout centered>
      <h3>Complete Your Profile</h3>
      <div>
        <h6 className="text-center">
          <span className="text-success">
            <FontAwesomeIcon icon="check-circle" />
          </span>{' '}
          Successfully Logged In
        </h6>
        <CompleteProfileForm {...props} />
      </div>
    </BareLayout>
  );
}

export default function CompleteProfileContainer() {
  return (
    <UserContext.Consumer>
      {({ user, updateUser }) => (
        <OrgUserContext.Consumer>
          {({ org, orguser, setOrgUser }) => (
            <CompleteProfile
              user={user}
              updateUser={updateUser}
              org={org}
              orguser={orguser}
              setOrgUser={setOrgUser}
            />
          )}
        </OrgUserContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}
