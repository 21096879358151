/* eslint-disable no-unused-vars */
// @flow

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get, isNil } from 'lodash';

import { Org } from 'data/entities';
import type OrgUser from 'data/entities/orguser';
import { ExclusivePartnerFilters } from 'data/entities/partner';
import { fetchNetworkConnectionCount } from 'data/repositories/networkprofile';
import { fetchPartnersCount, PartnersCount } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';
import useOrgProvider from 'utils/useOrgProvider';
import useParams from 'utils/useParams';

import LoadingRing from 'components/LoadingRing';

type FilterProps = {
  label: string,
  active: boolean,
  count: ?number,
  loading: ?boolean,
};

const Filter = ({ label, count, loading, active, ...props }: FilterProps) => (
  <div
    className={`partners-filter d-flex flex-row align-items-center ${
      active ? 'bg-primary-lighter' : ''
    }`}
    {...props}
  >
    <p className={`mb-0 bold normal-text flex-fill ${active ? 'text-primary' : 'gray-500'}`}>
      {label}
    </p>
    {(!isNil(count) || loading) && (
      <div
        className={`partners-filter-count bold small-text ${
          active ? 'bg-primary text-white' : 'bg-primary-lighter text-primary'
        }`}
      >
        {loading ? <LoadingRing maxWidth="15px" /> : count}
      </div>
    )}
  </div>
);

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const Sidebar = ({ loggedInOrg, loggedInOrguser }: Props) => {
  const history = useHistory();
  const [{ status }, setParams] = useParams({ status: null });
  const { id: userID, capabilities } = loggedInOrguser;
  const { isProviderCrossbeam } = useOrgProvider();

  const [networkCount, setNetworkCount] = useState<number>(null);
  const [loadingNetworkCount, setLoadingNetworkCount] = useState(true);

  const [partnersCount, setPartnersCount] = useState<PartnersCount>(null);
  const [loadingCounts, setLoadingCounts] = useState(true);

  // Load partners and network counts.
  useEffect(() => {
    setLoadingCounts(true);
    fetchPartnersCount().then((counts) => {
      setPartnersCount(counts);
      setLoadingCounts(false);
    });

    setLoadingNetworkCount(true);
    fetchNetworkConnectionCount(userID).then((count) => {
      setNetworkCount(count);
      setLoadingNetworkCount(false);
    });
  }, [userID]);

  const updateStatus = (newStatus: string) => {
    const baseUrl = '/network/p';
    history.push(baseUrl);
    setParams({ status: newStatus });
  };

  const isNetworkActive = ['/network', '/network/'].includes(history.location.pathname);
  const isActive = (value: string) => !isNetworkActive && status === value;

  return (
    <div
      className={`d-flex flex-column gap-15 ${
        !capabilities.canAccessSeatedExperience || !loggedInOrg.isIntegrationSetup
          ? 'seatless-overlay'
          : ''
      }`}
      style={{ width: 300 }}
    >
      <p className="mb-1 mt-4 bold large-text gray-700">Network</p>
      <Filter
        label="Enabled Partners"
        count={get(partnersCount, 'syncEnabled')}
        active={isActive(ExclusivePartnerFilters.SYNC_ENABLED)}
        loading={loadingCounts}
        onClick={() => updateStatus(ExclusivePartnerFilters.SYNC_ENABLED)}
      />
      <Filter
        label="Not Enabled Partners"
        count={get(partnersCount, 'syncDisabled')}
        active={isActive(ExclusivePartnerFilters.SYNC_DISABLED)}
        loading={loadingCounts}
        onClick={() => updateStatus(ExclusivePartnerFilters.SYNC_DISABLED)}
      />
      {/* <p className="mb-1 bold large-text gray-700">Network</p>
      <Filter
        label={!isProviderCrossbeam ? 'All' : 'My Network'}
        active={isNetworkActive}
        count={networkCount}
        loading={loadingNetworkCount}
        onClick={() => history.push('/network')}
      /> */}
    </div>
  );
};

export default withOrguser(Sidebar);
