// @flow

import { Org, OrgUser } from 'data/entities';
import type { ClearbitResult } from 'data/entities/clearbit';
import { DiscoveredOrg } from 'data/entities/org';
import authFetch from 'authFetch/authFetch';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

export const discoverOrgs = (
  partners: (ClearbitResult & { id?: string })[]
): Promise<PaginatedResponse<DiscoveredOrg>> => {
  const querystring = [];
  partners.map((p) => querystring.push(p.id ? `id=${p.id}` : `domain=${p.domain}`));
  const pQueries = querystring.join('&');
  return authFetch(`discoverOrgs?${pQueries}`, { method: 'GET' }).then(
    mapPaginatedResponse(DiscoveredOrg.fromApi)
  );
};

export default function completeSignup(description: string) {
  const body = { description };
  const method = 'PATCH';
  const fetchUrl = 'complete-signup';
  return authFetch(fetchUrl, { method, body }).then(({ org, orguser }) => ({
    org: Org.fromApi(org),
    orguser: OrgUser.fromApi(orguser),
  }));
}
