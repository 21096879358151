// @flow

import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'qs';

import { STATUS_ALL } from 'data/entities/bdrequest';
import OrgUser from 'data/entities/orguser';
import {
  BdRequestFilters,
  fetchBdRequest,
  fetchBdRequests,
  fetchOpenBdRequestCounts,
} from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';
import useScrollRestore from 'utils/useScrollRestore';
import type { LocationT, RouterHistoryT } from 'sharedTypes/reactRouter';

import LoadingRing from 'components/LoadingRing';
import NewBdRequestButton from 'components/NewBdRequestButton';
import BasePaginationButtons from 'components/PaginationButtons';
import ReplyOnlyBanner from 'components/SeatlessExperience/ReplyOnlyBanner';

import ActionRequiredSection from './ActionRequiredSection';
import DotMenu from './DotMenu';
import EmptyState from './EmptyState';
import FilterDropdown from './FilterDropdown';
import Header from './Header';
import QuickFilters from './QuickFilters';
import RequestItem from './RequestItem';
import SortingDropdown from './SortingDropdown';
import StatusSelector from './StatusSelector';

type Props = {
  location: LocationT,
  history: RouterHistoryT,
  loggedInOrguser: OrgUser,
};

const getSearchParams = (search) =>
  search.length ? queryString.parse(search.slice(1, search.length)) : {};

const BdRequestList = ({ location, history, loggedInOrguser: orguser }: Props) => {
  const [filters, setFilters] = useState();
  const [counts, setCounts] = useState();
  const [requests, setRequests] = useState({ data: null, loading: true });
  const restoreScroll = useScrollRestore(`request-list-${filters?.status ?? STATUS_ALL}`);

  const { pathname, search } = location;
  useEffect(() => {
    const status = pathname.split('/').pop();
    const searchParams = getSearchParams(search);
    if (orguser && pathname && search) {
      setFilters(
        BdRequestFilters.fromUrlParams(orguser, status, {
          ...searchParams,
          needsAction: orguser.capabilities.canManagePartnerships ? false : undefined,
        })
      );
    }
  }, [pathname, search, orguser]);

  // Refresh request in the list.
  const updateRequest = useCallback((id: string) => {
    fetchBdRequest(id).then((bd) => {
      setRequests((req) => ({
        ...req,
        data: {
          ...req.data,
          results: req.data?.results?.map((r) => (r.id === id ? bd : r)),
        },
      }));
    });
  }, []);

  const fetchRequests = useCallback(
    (ok: boolean, showLoading?: boolean = true) => {
      // Get the active counts
      fetchOpenBdRequestCounts(filters).then((countsResp) => {
        if (ok) {
          setCounts({
            ...countsResp,
            [STATUS_ALL]: Object.values(countsResp).reduce((a, v) => v + a, 0),
          });
        }
      });

      setRequests((s) => ({ ...s, loading: showLoading }));
      // Get the Bd Requests
      fetchBdRequests(filters)
        .then((requestsResp) => {
          if (ok) {
            setRequests({ data: requestsResp, loading: false });
            restoreScroll(true);
          }
        })
        .catch((error) => {
          if (ok) {
            setRequests({ data: requests.data, loading: false });
          }
        });
    },
    [filters, restoreScroll, requests.data]
  );

  useEffect(() => {
    // Load bdrequests.
    let ok = true;

    if (!filters) {
      return () => {
        ok = false;
      };
    }

    fetchRequests(ok);

    // Called on dismount, so we don't set state from old useEffect call
    return () => {
      ok = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onUpdateFilters = (newFilters: BdRequestFilters, keepPagination = false) => {
    const { push } = history;
    if (!keepPagination) {
      newFilters.offset = 0;
    }
    const nextUrl = `${pathname}?${newFilters.appUrlParamStr()}`;
    push(nextUrl);
  };

  const onNext = () => {
    const { next } = requests.data || {};
    if (!next) {
      return null;
    }
    return () => {
      filters.incrementOffset();
      onUpdateFilters(filters, true);
    };
  };

  const onPrevious = () => {
    const { previous } = requests.data || {};
    if (!previous) {
      return null;
    }
    return () => {
      filters.decrementOffset();
      onUpdateFilters(filters, true);
    };
  };

  const currentFilters = filters || new BdRequestFilters();
  const { limit, offset, ...statusSelectorSearchParams } = getSearchParams(search);
  const statusSelectorSearchStr = queryString.stringify(statusSelectorSearchParams);

  const count = counts ? counts[currentFilters.status] || counts[STATUS_ALL] : 0;

  return (
    <div className="w-100 d-flex flex-column gap-30 page-top-margin">
      {!orguser.capabilities.canAccessSeatedExperience && <ReplyOnlyBanner />}
      {orguser.capabilities.canManagePartnerships && (
        <ActionRequiredSection onUpdate={() => fetchRequests(true, false)} />
      )}
      <div className="d-flex flex-row align-items-center gap-20">
        <h4 className="m-0 flex-fill bold gray-700 text-truncate disable-selection">Messaging</h4>
        {orguser.capabilities.canAccessSeatedExperience && <NewBdRequestButton newActivity />}
        {orguser.capabilities.canAccessSeatedExperience && <DotMenu filters={currentFilters} />}
      </div>
      <div className="card card-body no-padding">
        <div className="d-flex flex-row align-items-center gap-10 px-3 py-3">
          <QuickFilters filters={currentFilters} onUpdateFilters={onUpdateFilters} />
          <StatusSelector
            currentStatus={currentFilters.status}
            currentSearch={statusSelectorSearchStr}
            counts={counts}
          />
          <div className="flex-fill" />
          <FilterDropdown filters={currentFilters} onUpdateFilters={onUpdateFilters} />
          <SortingDropdown filters={currentFilters} onUpdateFilters={onUpdateFilters} />
        </div>
        <Header />
        {(() => {
          const { data } = requests;
          switch (true) {
            case requests.loading:
              return <LoadingRing maxWidth="50px" className="m-5" />;
            case data && data.results && data.results.length > 0:
              return data.results.map((bdr) => (
                <RequestItem request={bdr} key={bdr.id} onUpdate={() => updateRequest(bdr.id)} />
              ));
            default:
              return (
                <EmptyState orguser={orguser} userRequestsOnly={filters.isForOrguserRequestsOnly} />
              );
          }
        })()}
        <div className="d-flex flex-row align-items-center px-4 py-3">
          <div className="normal-text semi-bold gray-600 d-flex flex-fill">
            {requests.loading ? (
              <LoadingRing maxWidth="20px" />
            ) : (
              `Showing ${
                currentFilters.offsetInt + 1 !== 1 ? currentFilters.offsetInt + 1 : 0
              }-${Math.min(currentFilters.offsetInt + currentFilters.limitInt, count)} of ${count}`
            )}
          </div>
          <BasePaginationButtons
            onNext={onNext()}
            onPrevious={onPrevious()}
            page={
              currentFilters.offsetInt /
                (currentFilters.limitInt || currentFilters.offsetInt || 1) +
              1
            }
          />
        </div>
      </div>
    </div>
  );
};

export default withOrguser(BdRequestList);
