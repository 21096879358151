/* eslint-disable max-classes-per-file */
// @flow

import React from 'react';

import Pill from 'components/Pill';

import { ContactSignals } from './signal';
import { PartnerOrg } from '.';

export class DealOwner {
  id: string;

  avatarImage: ?string;

  firstName: string;

  lastName: string;

  title: ?string;

  get name() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  partnerOrg: PartnerOrg;

  static fromApi = (d: any): DealOwner => {
    const dlo = new DealOwner();
    dlo.id = d.id;
    dlo.avatarImage = d.avatar_image;
    dlo.firstName = d.first_name;
    dlo.lastName = d.last_name;
    dlo.title = d.title;
    dlo.partnerOrg = PartnerOrg.fromApi(d.partner_org);
    return dlo;
  };
}

const wonRaw = 'CLOSED:WON';
const activeRaw = 'PROSPECT';
const lostRaw = 'CLOSED:LOST';

export const dealStatuses = {
  [wonRaw]: 'Won',
  [activeRaw]: 'Active Deal',
  [lostRaw]: 'Lost',
};

export const getDealStatusComponent = (status) => {
  let color;
  switch (status) {
    case wonRaw:
      color = 'blue';
      break;
    case activeRaw:
      color = 'green';
      break;
    default:
      color = 'gray';
  }
  return React.createElement(Pill, { color, extra: dealStatuses[status] });
};

export class Contact {
  email: string;

  fullNames: string[];

  titles: string[];

  roles: string[];

  inContacts: boolean;

  sharedConnectionsCount: number;

  accounts: string[];

  signals: ContactSignals;

  dealOwners: DealOwner[];

  static fromApi = (d: any): Contact => {
    const c = new Contact();
    c.email = d.email;
    c.fullNames = d.full_names;
    c.titles = d.titles;
    c.roles = d.roles;
    c.inContacts = d.in_contacts;
    c.sharedConnectionsCount = d.shared_connections_count;
    c.accounts = d.accounts;
    c.signals = ContactSignals.fromApi(d.signals);
    c.dealOwners = d.deal_owners.map(DealOwner.fromApi);
    return c;
  };
}

export class ContactConnection {
  accounts: {
    id: string,
    messaged: boolean,
    name: string,
    partnerSlug: string,
  }[];

  dealStatus: string;

  lastActivity: ?Date;

  opportunityTypes: string[];

  isPrimary: boolean;

  owner: DealOwner;

  roles: string[];

  titles: string[];

  signals: ContactSignals;

  static fromApi = (d: any): ContactConnection => {
    const c = new ContactConnection();

    c.accounts = d.accounts.map((account) => ({
      id: account.id,
      messaged: account.messaged,
      name: account.name,
      partnerSlug: account.partner_slug,
    }));
    c.dealStatus = d.deal_status;
    c.lastActivity = d.last_activity && new Date(d.last_activity);
    c.opportunityTypes = d.opportunity_types;
    c.isPrimary = d.signals && d.signals.primary > 0;
    c.owner = DealOwner.fromApi(d.owner);
    c.roles = d.roles;
    c.titles = d.titles;
    c.signals = ContactSignals.fromApi(d.signals);
    return c;
  };
}
