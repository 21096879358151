// @flow

import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

const ROOT = 'settings';
const ROUTES = {
  ORGANIZATION: '',
  NOTIFICATIONS: 'notifications',
};

type ItemProps = {
  label: string,
  icon: string,
  route: string,
};

const Item = ({ label, icon, route }: ItemProps) => {
  const location = useLocation();

  const active = useMemo(() => {
    const lastPath = location.pathname.split('/').pop();
    return (lastPath === ROOT && route === ROUTES.ORGANIZATION) || lastPath === route;
  }, [location.pathname, route]);
  return (
    <Link
      to={`/${ROOT}/${route}`}
      className={`partners-filter d-flex flex-row align-items-center gap-10 ${
        active ? 'bg-primary-lighter' : ''
      }`}
      tabIndex="0"
      role="button"
    >
      <FontAwesomeIcon icon={icon} className={active ? 'primary' : 'gray-400'} />
      <p className={`mb-0 bold normal-text flex-fill ${active ? 'text-primary' : 'gray-500'}`}>
        {label}
      </p>
    </Link>
  );
};

type Props = {
  loggedInOrguser: OrgUser,
};

const Sidebar = ({ loggedInOrguser: { canManage } }: Props) => (
  <div className="col-3 page-top-margin">
    <StickyBox offsetTop={40} offsetBottom={20}>
      <div className="d-flex flex-column gap-15">
        <div className="mb-1 d-flex flex-row gap-10">
          <span className="bold large-text gray-700 flex-fill">Settings</span>
        </div>
        {canManage && <Item icon="building" label="Organization" route={ROUTES.ORGANIZATION} />}
        <Item icon="bell" label="Alerts" route={ROUTES.NOTIFICATIONS} />
      </div>
    </StickyBox>
  </div>
);

export default withOrguser(Sidebar);
