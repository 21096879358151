// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomInput, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { getBdRequestQuestions } from 'data/repositories/bdrequestQuestion';

import AddQuestionModal from './AddQuestionModal';
import { MAX_QUESTIONS } from './constants';
import DeleteQuestionModal from './DeleteQuestionModal';
import EditQuestionModal from './EditQuestionModal';
import LoadingQuestions from './LoadingQuestions';

type Props = {
  allowCustomQuestions: boolean,
  setAllowCustomQuestions: (boolean) => void,
  setHasQuestions: (boolean) => void,
};

const Question = ({ allowCustomQuestions, setAllowCustomQuestions, setHasQuestions }: Props) => {
  const [questions, setQuestions] = useState<BdRequestQuestion[]>([]);
  const [loading, setLoading] = useState(false);

  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
  const toggleAddQuestionModal = () => setAddQuestionModalOpen(!addQuestionModalOpen);

  const [editQuestion, setEditQuestion] = useState<BdRequestQuestion | null>(null);
  const toggleEditQuestionModal = () => setEditQuestion(null);

  const reachedQuestionsLimit = useMemo(() => questions.length >= MAX_QUESTIONS, [questions]);

  const getQuestions = useCallback(() => {
    setLoading(true);
    getBdRequestQuestions()
      .then((q) => {
        setQuestions(q.results);
        setHasQuestions(
          q.results.length > 0 && q.results.some((question) => question.isForAllPartners)
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [setHasQuestions]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return (
    <>
      <div className="w-100 d-flex flex-column align-items-center gap-10">
        <div className="w-100 d-flex flex-row align-items-center gap-20">
          <h5 className="m-0 bold gray-700 flex-fill">What should your sales team ask partners?</h5>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleAddQuestionModal}
            disabled={reachedQuestionsLimit}
          >
            <FontAwesomeIcon icon="plus" className="mr-2" />
            Add a question
          </button>
        </div>

        {/* Custom questions */}
        <div className="w-100 d-flex flex-row align-items-center gap-10">
          <div className="m-0 form-group complete" id="disableOwnerGroup">
            <CustomInput
              id="customQuestions"
              type="switch"
              onChange={() => setAllowCustomQuestions(!allowCustomQuestions)}
              checked={allowCustomQuestions}
              disabled={loading}
            />
          </div>
          <span className="large-text bold gray-700 disable-selection">Allow custom questions</span>
          <FontAwesomeIcon
            id="allow-custom-questions"
            icon={['far', 'question-circle']}
            className="gray-400 cursor-pointer"
          />
          <UncontrolledTooltip placement="right" target="allow-custom-questions">
            If allowed, your sales team will be able to send their own questions in addition to the
            questions you set here.
          </UncontrolledTooltip>
        </div>

        {/* Reached questions limit */}
        {reachedQuestionsLimit && (
          <div className="w-100 d-flex flex-row align-items-center gap-5 orange">
            <FontAwesomeIcon icon={['far', 'exclamation-circle']} />
            <span className="normal-text semi-bold">
              You reached the limit of {MAX_QUESTIONS} questions.
            </span>
          </div>
        )}

        {!loading ? (
          <>
            {questions.length ? (
              questions.map((question) => (
                <div
                  key={question.id}
                  className="card card-body m-0 pl-4 py-3 d-flex flex-row align-items-center gap-10"
                >
                  <div className="d-flex flex-column flex-fill">
                    <span className="large-text bold gray-700">{question.text}</span>
                    <span className="normal-text semi-bold gray-400">
                      {question.isForAllPartners
                        ? `${question.isDefault ? 'Default ' : ''}Question allowed for All Partners`
                        : `${question.forPartners.length} Partner${
                            question.forPartners.length === 1 ? '' : 's'
                          } selected`}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditQuestion(question)}
                  >
                    Edit
                  </button>
                  <DeleteQuestionModal question={question} onDelete={() => getQuestions()} />
                </div>
              ))
            ) : (
              <div className="d-flex flex-column align-items-center py-4">
                <span className="large-text bold gray-700">
                  Add questions or allow custom questions to proceed
                </span>
                <span className="normal-text semi-bold gray-500">
                  Your sales team has to have at least one of the two ways to reach out to your
                  partners.
                </span>
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={toggleAddQuestionModal}
                >
                  <FontAwesomeIcon icon="plus" className="mr-2" />
                  Add a question
                </button>
              </div>
            )}
          </>
        ) : (
          <LoadingQuestions />
        )}
      </div>
      <AddQuestionModal
        open={addQuestionModalOpen}
        toggle={toggleAddQuestionModal}
        onCreate={() => getQuestions()}
      />
      <EditQuestionModal
        toggle={toggleEditQuestionModal}
        onEdit={() => {
          setEditQuestion(null);
          getQuestions();
        }}
        question={editQuestion}
      />
    </>
  );
};

export default Question;
