// @flow

import * as React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { Account, Opportunity } from 'data/entities';
import { fetchAccountOpportunities } from 'data/repositories/accounts';

const NO_OPPORTUNITY = { id: 'NO_OPPORTUNITY', label: 'No Opportunity' };

type Props = {
  requestId?: string,
  account?: Account,
  currentOpportunity?: Opportunity,
  onChange: (SearchIndexItem) => void,
  defaultOptions?: Opportunity[],
  defaultSelected?: Opportunity,
  bsSize?: string,
  className?: string,
  style?: string,
  emptyLabel?: string,
  clearButton?: boolean,
  id?: string,
  changeOnClear?: boolean,
  selected?: Opportunity[],
  isLoading?: boolean,
  disabled?: boolean,
};

type State = {
  searchProps: {
    isLoading: boolean,
    options: Opportunity[],
  },
  totalCount: number,
};

class OpportunitySearch extends React.Component<Props, State> {
  state: State = {
    searchProps: { isLoading: false, options: [] },
    totalCount: 0,
  };

  componentDidMount() {
    const { account, selected } = this.props;
    if (account) {
      if (account.slug) {
        // Initial fetch when editing the opportunity.
        this.updateFromQuery('');
      }
      if (!selected) {
        // If there is an account and requestId on mount, but no opportunity, then it's a request without an opp.
        this.setState((state) => ({ ...state, selected: NO_OPPORTUNITY }));
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      // Initial fetch with total opportunity count.
      this.updateFromQuery('');
    }
  }

  onSearch = (query: string) => {
    this.setState(
      (state) => ({ ...state, searchProps: { ...state.searchProps, isLoading: true } }),
      () => this.updateFromQuery(query)
    );
  };

  onChange = ([val]: SearchIndexItem) => {
    if (this.typeaheadElm) {
      if (val) {
        const instance = this.typeaheadElm.getInstance();
        instance.blur();
      }
      const { onChange, changeOnClear } = this.props;
      if (val != null || changeOnClear) {
        onChange(val === NO_OPPORTUNITY ? null : val);

        // If input was cleared, reset search state and fetch first 50 ops and re-set total count.
        if (val === undefined) {
          this.onSearch('', true);
        }
      }
    }
  };

  labelKey = ({ label }: Opportunity) => label;

  handleFocus = (event: Event) => {
    event.target.select();
  };

  focus = () => {
    this.typeaheadElm.getInstance().focus();
  };

  async updateFromQuery(query: string) {
    let results = [];
    try {
      const resp = await fetchAccountOpportunities(this.props.account.slug, false, query).then(
        (result) => {
          if (query === '') {
            this.setState((state) => ({ ...state, totalCount: result.count }));
          }
          return result;
        }
      );
      ({ results } = resp);
    } catch (error) {
      // Leave `results` empty
    }
    this.setState((state) => ({ searchProps: { isLoading: false, options: results } }));
  }

  render() {
    const {
      totalCount,
      searchProps: { options: stateOptions, ...restSearchProps },
    } = this.state;
    const {
      className: propClassName,
      bsSize,
      style,
      emptyLabel,
      defaultSelected,
      defaultOptions,
      clearButton,
      id,
      selected,
      account,
      disabled,
      isLoading,
    } = this.props;
    const opportunityDisabled = !account || disabled;
    const options = stateOptions && stateOptions.length ? stateOptions : defaultOptions;
    let searchOpportunityText = 'Select an account first';
    if (account) {
      if (totalCount !== 0) {
        searchOpportunityText = 'Search for an opportunity';
      } else {
        searchOpportunityText = 'Account has no opportunities';
      }
    }

    const className = `OpportunitySearch fs-mask ${propClassName || ''}`;
    restSearchProps.isLoading = restSearchProps.isLoading ? restSearchProps.isLoading : isLoading;

    return (
      <div style={style} className={className}>
        <AsyncTypeahead
          {...restSearchProps}
          useCache={false}
          disabled={opportunityDisabled}
          id={id}
          defaultSelected={defaultSelected}
          selected={selected}
          onInputChange={(query) => query === '' && query.length === 0 && this.onSearch('', true)}
          options={[...options, NO_OPPORTUNITY]}
          allowNew={false}
          filterBy={() => true}
          minLength={0}
          onSearch={this.onSearch}
          labelKey={this.labelKey}
          placeholder={searchOpportunityText}
          onChange={this.onChange}
          bsSize={bsSize}
          ref={(ref) => {
            this.typeaheadElm = ref;
          }}
          selectHintOnEnter
          emptyLabel={emptyLabel}
          clearButton={clearButton}
          onFocus={this.handleFocus}
          align="right"
          positionFixed
        />
      </div>
    );
  }
}

OpportunitySearch.defaultProps = {
  account: null,
  currentOpportunity: null,
  requestId: null,
  bsSize: 'sm',
  className: '',
  style: {},
  id: 'OpportunitySearch',
  emptyLabel: 'No matching opportunities found',
  defaultSelected: [],
  defaultOptions: [],
  clearButton: true,
  changeOnClear: false,
  selected: null,
  isLoading: false,
  disabled: false,
};

export default OpportunitySearch;
