// @flow

import * as React from 'react';
import { Alert, Input } from 'reactstrap';

const CompanyDescription = ({ description, onChange, errors }) => (
  <>
    <span className="normal-text semi-bold gray-600" style={{ lineHeight: 2 }}>
      Description
    </span>
    <Input
      type="textarea"
      name="description"
      id="description"
      value={description}
      style={{ height: '127px' }}
      onChange={onChange}
    />
    <Alert color="danger" isOpen={!!errors} className="mt-2">
      {errors && errors.description ? errors.description : null}
    </Alert>
  </>
);

export default CompanyDescription;
