// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';

import type { SearchIndexItem } from 'data/repositories/search';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import Unisearchal from './Unisearchal';

type Props = {
  bsSize: string,
  placeholder: string,
  history: RouterHistoryT,
  className?: string,
  style?: string,
  entityKind?: ?string,
};

function UnisearchalNavigation(props: Props) {
  const unisearchalRef = React.useRef(null);
  function navigateOnChange(history, val: SearchIndexItem, callback) {
    if (!val) {
      return;
    }
    if (val.kind === 'discorg') {
      callback();
      return;
    }
    unisearchalRef.current.resetComponent();
    const eMap = { account: 'a', partner: 'p', orguser: 'network/oid' };
    const identifier = val.kind === 'orguser' ? 'id' : 'slug';
    const url = `/${eMap[val.kind]}/${val[identifier]}`;
    history.push(url);
  }

  const { className, bsSize, placeholder, style, history, entityKind } = props;
  const onChange = (val: SearchIndexItem, callback) => navigateOnChange(history, val, callback);
  return (
    <Unisearchal
      placeholder={placeholder}
      onChange={onChange}
      bsSize={bsSize}
      style={style}
      className={className}
      entityKind={entityKind}
      ref={unisearchalRef}
      typeAheadProps={{ useCache: false }}
    />
  );
}

UnisearchalNavigation.defaultProps = {
  entityKind: null,
  className: null,
  style: null,
};

export default withRouter(UnisearchalNavigation);
