// @flow

import * as React from 'react';

import { Account, PartnerAccount, PartnerOrg } from 'data/entities';

import BaseDeleteAccountPartner from 'components/BaseDeleteAccountPartner';

type Props = {
  account: Account,
  partner: PartnerOrg,
  partnerAccount: PartnerAccount,
};

type State = {
  message: string,
  isDeclineModalOpen: boolean,
};

class DeclineAccountMapping extends React.Component<Props, State> {
  state: State = { message: '', isDeclineModalOpen: false };

  setMessage = (event) => {
    const {
      target: { value: message },
    } = event;
    this.setState({ message });
  };

  toggleDeclineModal = () => {
    this.setState(({ isDeclineModalOpen }) => ({ isDeclineModalOpen: !isDeclineModalOpen }));
  };

  deleteFn = () => {
    // Delete function removed because endpoint was removed in APP-1506
  };

  render() {
    const { account, partner, partnerAccount } = this.props;
    const { message, isDeclineModalOpen } = this.state;
    const body = (
      <>
        <p className="fs-14">
          Are you sure you want to remove the account mapping of <strong>{account.name}</strong>{' '}
          with {partner.name}
          {"'"}s <strong>{partnerAccount.name}</strong>?
        </p>
        <p>
          <strong>Incorrect mapping?</strong> If this is a high importance account you can search{' '}
          &quot;Potential Account Mappings&quot; to see if there is a better mapping.
        </p>
      </>
    );

    return (
      <div className="DeclineAccountMapping" style={{ display: 'inline-block' }}>
        <button onClick={this.toggleDeclineModal} className="btn btn-xs py-1" type="button">
          X
        </button>
        <BaseDeleteAccountPartner
          onDelete={this.deleteFn}
          isOpen={isDeclineModalOpen}
          account={account}
          partnerAccount={partnerAccount}
          toggle={this.toggleDeclineModal}
          body={body}
          message={message}
        />
      </div>
    );
  }
}

export default DeclineAccountMapping;
