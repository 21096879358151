// @flow
import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { uniqBy } from 'lodash';

import { Org } from 'data/entities';
import { OrgUserNameOnly } from 'data/entities/orguser';
import { fetchOrgusers } from 'data/repositories/orgusers';
import withOrguser from 'contexts/withOrguser';

import PersonAvatar from './PersonAvatar';

type Props = {
  selected: OrgUserNameOnly[] | null | void,
  initalSelected: OrgUserNameOnly[] | null | void,
  onSelect: (users: OrgUserNameOnly[]) => void,
  disabled: boolean,
  loggedInOrg: Org,
  onlyAccountOwners?: boolean,
};

const UsersSelector = ({
  initalSelected,
  selected,
  onSelect,
  disabled,
  loggedInOrg,
  onlyAccountOwners,
}: Props) => {
  const [options, setOptions] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    setOptions([]);
  }, [initalSelected]);

  const onSearch = (query: string) => {
    setLoadingUsers(true);
    fetchOrgusers({
      search: query,
      isAccountOwner: onlyAccountOwners ? true : undefined,
    }).then(({ results }) => {
      setOptions((o) =>
        uniqBy(o.concat(results.map((u) => ({ ...u, name: `${u.firstName} ${u.lastName}` }))), 'id')
      );
      setLoadingUsers(false);
    });
  };

  const customMenuItem = (option, { text }) => (
    <div className="d-flex flex-row align-items-center gap-5">
      <PersonAvatar
        firstName={option.firstName}
        lastName={option.lastName}
        avatar={option.avatarImage}
        org={loggedInOrg}
        size={20}
        className=""
      />
      <Highlighter search={text}>{option.name}</Highlighter>
    </div>
  );

  return (
    <AsyncTypeahead
      labelKey={(option) => option.name || `${option.firstName} ${option.lastName}`}
      id="users-selector"
      options={options ?? []}
      placeholder="Select Users..."
      onChange={(s: OrgUserNameOnly[]) => onSelect(s)}
      selected={selected ?? []}
      isLoading={loadingUsers}
      onSearch={onSearch}
      emptyLabel="No users found."
      renderMenuItemChildren={customMenuItem}
      disabled={disabled}
      size="sm"
      minLength={1}
      render
      clearButton
      ignoreDiacritics
      multiple
      useCache
    />
  );
};

UsersSelector.defaultProps = {
  onlyAccountOwners: false,
};

export default withOrguser(UsersSelector);
