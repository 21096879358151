// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';

import setTitle, { useTitleEffect } from 'utils/setTitle';
import { getCrossbeamLoginUrl } from 'utils/urlUtils';
import type { LocationT } from 'sharedTypes/reactRouter';

type Props = {
  isAuthenticated: boolean,
  location: LocationT,
};

const Auth = ({ isAuthenticated, location: { state: locationState } }: Props) => {
  useTitleEffect('Login');
  const redirect = locationState ? locationState.redirectFrom : null;
  let redirectFrom = null;
  if (redirect && redirect.pathname) {
    const { search, pathname } = redirect;
    redirectFrom = `${pathname}${search || ''}`;
  }
  if (isAuthenticated) {
    setTitle();
    return redirectFrom ? <Redirect to={redirectFrom} /> : <Redirect to="/" />;
  }
  const goTo = getCrossbeamLoginUrl(redirectFrom || '/');
  window.location.replace(goTo);
  return null;
};

export default Auth;
