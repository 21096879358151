// @flow

import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { OrgUser } from 'data/entities';
import { Partner, PartnerAccount } from 'data/entities/partner';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';

import StartSharedConversationForm from './StartSharedConversationForm';
import TextArea from './TextArea';

type Props = {
  isShared: boolean,
  isEditable?: boolean,
  mini?: boolean,
  disableSharedSelector?: boolean,
  newRequest?: boolean,

  loggedInOrguser: OrgUser,
  submitButton: any,
  text: string,
  bdRequestShared: boolean,
  startSharedDisabled?: boolean,

  onChangeText: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChangeIsShared: (isShared: boolean) => void,
  onPartnerAccountChange: (partnerAccount: ?PartnerAccount) => void,

  partner: ?Partner,
  accountSlug: ?string,
  partnerAccountId: ?string,
  bdRequestId?: ?string,
  errors?: ?any,
};

const InputArea = ({
  accountSlug,
  bdRequestId,
  bdRequestShared,
  disableSharedSelector,
  errors,
  isEditable,
  isShared,
  loggedInOrguser,
  mini,
  newRequest,
  onChangeIsShared,
  onChangeText,
  onPartnerAccountChange,
  partner,
  partnerAccountId,
  startSharedDisabled,
  submitButton,
  text,
}: Props) => {
  const [sharingDisabled, setSharingDisabled] = useState(!bdRequestShared);

  const sharedCapable = !!partner;
  const canStartSharedConversation = !bdRequestShared && sharedCapable;

  const tooltipId = 'tooltip-start-shared-conversation-no-results';
  const sharedButtonStyles = classNames(
    'AddRequestSwitchButton',
    isShared && 'AddRequestSwitchButtonShared'
  );

  const SharedConvoButton = () => (
    <button
      onClick={() => onChangeIsShared(true)}
      className={sharedButtonStyles}
      disabled={sharingDisabled}
      type="button"
    >
      <FontAwesomeIcon icon="clone" className="mr-1" /> Start Shared conversation
    </button>
  );

  return (
    <div style={{ position: 'relative' }}>
      {!disableSharedSelector && (
        <div className="AddRequestSwitchGroup d-flex flex-row align-items-center gap-10">
          {bdRequestShared && (
            <button
              type="button"
              onClick={() => onChangeIsShared(true)}
              className={sharedButtonStyles}
            >
              <FontAwesomeIcon icon="comment-dots" className="mr-1" /> Public
            </button>
          )}
          {canStartSharedConversation &&
            !startSharedDisabled &&
            (sharingDisabled ? (
              <>
                <span id={tooltipId}>
                  <SharedConvoButton />
                </span>
                <UncontrolledTooltip target={tooltipId}>
                  Can’t start a shared conversation. No account mapping for this account with your
                  partner
                </UncontrolledTooltip>
              </>
            ) : (
              <SharedConvoButton />
            ))}
          <button
            type="button"
            onClick={() => onChangeIsShared(false)}
            className={classNames(
              'AddRequestSwitchButton',
              !isShared && 'AddRequestSwitchButtonInternal'
            )}
          >
            <FontAwesomeIcon icon="lock" className="mr-2" />
            Internal
          </button>
        </div>
      )}
      <div
        className={classNames(
          'AddRequestInputArea',
          !isShared && 'AddRequestInputAreaInternal',
          disableSharedSelector && 'pt-0'
        )}
      >
        <TextArea
          mini={mini}
          isShared={isShared}
          value={text}
          onChange={onChangeText}
          bdRequestId={bdRequestId}
          partnerAccountId={partnerAccountId}
          partnerId={partner && partner.id}
        />
        <div className="d-flex justify-content-end p-2">{submitButton}</div>
        {!newRequest && canStartSharedConversation && (
          <StartSharedConversationForm
            partner={partner}
            partnerAccountId={partnerAccountId}
            isEditable={!bdRequestShared}
            onHasResults={(hasResults: boolean) => {
              setSharingDisabled(!hasResults);
              if (!hasResults && isShared) {
                onChangeIsShared(false);
              }
            }}
            isShared={isShared}
            accountSlug={accountSlug}
            onPartnerAccountChange={onPartnerAccountChange}
          />
        )}
      </div>
      <FieldErrors errors={errors} />
    </div>
  );
};

InputArea.defaultProps = {
  mini: false,
  bdRequestId: null,
  isEditable: true,
  errors: {},
  disableSharedSelector: false,
  newRequest: false,
  startSharedDisabled: false,
};

export default withOrguser(InputArea);
