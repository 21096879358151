// @flow

import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

import { BdRequest, Org } from 'data/entities';
import { statusChoiceList, statusChoices, statusDisplayValueMap } from 'data/entities/bdrequest';
import { updateBdRequestStatus } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';

import CompletionModal from './CompletionModal';

type Props = {
  request: BdRequest,
  onUpdate: () => void,
  loggedInOrg: Org,
};

const StatusSwitch = ({ request, onUpdate, loggedInOrg }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const attributionEnabled = loggedInOrg && loggedInOrg.settings.attributionEnabled;

  const onUpdateStatus = (e) => {
    const newStatus = e.target.value;
    if (newStatus === statusChoices.COMPLETED && !request.partner) {
      setErrors({ errors: { status: 'Set partner first.' } });
    } else if (newStatus === request.status) {
      setShowModal(false);
    } else if (newStatus === statusChoices.COMPLETED && attributionEnabled) {
      setShowModal(true);
    } else {
      setSubmitting(true);
      setErrors({});
      updateBdRequestStatus(request.id, newStatus)
        .then(() => {
          setSubmitting(false);
          setShowModal(false);
          setErrors({});
          onUpdate();
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors(error.json);
        });
    }
  };

  const toggleModal = () => {
    setShowModal((o) => !o);
  };

  let color;
  switch (request.status) {
    case statusChoices.REQUESTED:
      color = 'BdRequestStatusButtonRequested';
      break;
    default:
      color = 'BdRequestStatusButtonCompleted';
  }

  return (
    <>
      <UncontrolledButtonDropdown a11y={false}>
        <CompletionModal
          request={request}
          onUpdate={onUpdate}
          open={showModal}
          toggle={toggleModal}
        />
        <DropdownToggle className={`BdRequestStatusButton ${color}`} caret>
          {request.statusDisplay}
        </DropdownToggle>
        <DropdownMenu>
          {statusChoiceList.map((status) => (
            <DropdownItem
              disabled={submitting}
              onClick={onUpdateStatus}
              value={status}
              key={status}
            >
              {statusDisplayValueMap[status]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <FieldErrors errors={errors && errors.status} />
    </>
  );
};

export default withOrguser(StatusSwitch);
