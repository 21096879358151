// @flow

import * as React from 'react';
import { AsyncTypeahead, WrappedTypeahead } from 'react-bootstrap-typeahead';

import { OrgUser } from 'data/entities';
import { fetchOrgusers } from 'data/repositories/orgusers';
import type { SearchIndexItem } from 'data/repositories/search';

type Props = {
  onChange: (SearchIndexItem) => void,
  defaultOptions?: OrgUser[],
  defaultSelected?: OrgUser,
  isActive?: ?boolean,
  bsSize?: ?string,
  placeholder?: string,
  className?: string,
  style?: string,
  emptyLabel?: string,
  clearButton?: boolean,
  id?: string,
  userRole?: string,
  canManagePartnerships?: boolean,
  changeOnClear?: boolean,
  selected?: OrgUser[],
  searchMethod?: (query: string) => Promise<{ results: SearchIndexItem[] }>,
  isLoading?: boolean,
  disabled?: boolean,
  allowNew?: boolean,
  multiple?: boolean,
  labelKey?: ?(orguser: OrgUser) => string,
  renderToken?: ?() => any,
};

type State = {
  searchProps: {
    isLoading: boolean,
    options: OrgUser[],
  },
};

class UserSearch extends React.Component<Props, State> {
  typeaheadElm: ?WrappedTypeahead = null;

  state: State = {
    searchProps: { isLoading: false, options: [] },
  };

  onSearch = (query: string) => {
    this.setState(
      (state) => ({ searchProps: { ...state.searchProps, isLoading: true } }),
      () => this.updateFromQuery(query)
    );
  };

  onChange = (results: SearchIndexItem[]) => {
    const { multiple } = this.props;
    let val = results;
    if (!multiple) {
      // If multiple aren't allowed then use the first and pass along a single object
      [val] = results;
    }
    if (this.typeaheadElm) {
      if (val) {
        const instance = this.typeaheadElm.getInstance();
        instance.blur();
      }
      const { onChange, changeOnClear } = this.props;
      if (val != null || changeOnClear) {
        onChange(val);
      }
    }
  };

  labelKey = (orguser: OrgUser) => {
    const { labelKey } = this.props;
    if (labelKey) {
      return labelKey(orguser);
    }
    const { fullName, email } = orguser;
    let response = fullName;
    if (email) {
      response = `${response} (${email})`;
    }
    return response;
  };

  handleFocus = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
      event.target.select();
    }
  };

  focus = () => {
    this.typeaheadElm.getInstance().focus();
  };

  async updateFromQuery(query: string) {
    const { isActive, userRole, canManagePartnerships, searchMethod } = this.props;
    let results = [];
    try {
      const resp = await (searchMethod
        ? searchMethod(query)
        : fetchOrgusers({ isActive, role: userRole, search: query, canManagePartnerships }));
      ({ results } = resp);
    } catch (error) {
      // Leave `results` empty
    }
    this.setState({ searchProps: { isLoading: false, options: results } });
  }

  render() {
    const {
      searchProps: { options: stateOptions, ...restSearchProps },
    } = this.state;
    const {
      className: propClassName,
      bsSize,
      placeholder,
      style,
      emptyLabel,
      defaultSelected,
      defaultOptions,
      clearButton,
      id,
      selected,
      disabled,
      isLoading,
      allowNew,
      multiple,
      renderToken,
    } = this.props;
    const className = `UserSearch ${propClassName || ''}`;
    const options = stateOptions && stateOptions.length ? stateOptions : defaultOptions;
    restSearchProps.isLoading = restSearchProps.isLoading ? restSearchProps.isLoading : isLoading;

    return (
      <div style={style} className={className}>
        <AsyncTypeahead
          {...restSearchProps}
          disabled={disabled}
          id={id}
          defaultSelected={defaultSelected}
          selected={selected}
          options={options}
          allowNew={allowNew}
          filterBy={() => true}
          minLength={2}
          onSearch={this.onSearch}
          labelKey={this.labelKey}
          placeholder={placeholder}
          onChange={this.onChange}
          bsSize={bsSize}
          ref={(ref) => {
            this.typeaheadElm = ref;
          }}
          selectHintOnEnter
          emptyLabel={emptyLabel}
          clearButton={clearButton}
          onFocus={this.handleFocus}
          newSelectionPrefix="New user: "
          multiple={multiple}
          renderToken={renderToken}
        />
      </div>
    );
  }
}

UserSearch.defaultProps = {
  isActive: null,
  bsSize: 'sm',
  placeholder: '🔎  Search for a user...',
  className: '',
  style: {},
  id: 'UserSearch',
  emptyLabel: 'No matching users found',
  defaultSelected: [],
  defaultOptions: [],
  clearButton: true,
  userRole: null,
  canManagePartnerships: undefined,
  changeOnClear: false,
  selected: null,
  searchMethod: null,
  isLoading: false,
  disabled: false,
  allowNew: false,
  multiple: false,
  labelKey: null,
  renderToken: undefined,
};

export default UserSearch;
