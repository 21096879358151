// @flow

import React from 'react';

import { Account, Partner, PartnerAccount } from 'data/entities';

import CompanyLogo from 'components/CompanyLogo';
import Pill from 'components/Pill';

type Props = {
  partner: Partner,
  account: Account,
  partnerAccount: PartnerAccount,
};

const Information = ({ partner, account, partnerAccount }: Props) => {
  const Section = ({ title, children }) => (
    <div
      className="w-100 d-flex flex-row gap-10 align-items-center p-2"
      style={{ borderBottom: '1px solid #DDE5F0' }}
    >
      <span className="normal-text semi-bold gray-400">{title}:</span>
      {children}
    </div>
  );

  return (
    <div className="d-flex flex-column">
      <Section title="Partner">
        <Pill
          icon={
            <CompanyLogo
              className=""
              domain={partner.domain}
              logoUrl={partner.logoUrl ?? partner.partnerOrg.logoUrl}
              name={partner.name}
              size={20}
            />
          }
          color="blue"
          label={partner.name}
        />
      </Section>
      <Section title="Partner Account">
        <Pill
          icon={
            <CompanyLogo
              className=""
              domain={partnerAccount.website}
              name={partnerAccount.name}
              size={20}
            />
          }
          color="blue"
          label={partnerAccount.name}
        />
      </Section>
      <Section title="Account">
        <Pill
          icon={<CompanyLogo className="" domain={account.website} name={account.name} size={20} />}
          color="blue"
          label={account.name}
        />
        {account.status}
      </Section>
    </div>
  );
};

export default Information;
