// @flow

import * as React from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference, isEmpty, map, reduce } from 'lodash';
import queryString from 'qs';

import { OrgUser, Partner } from 'data/entities';
import {
  FILTER_RELEVANCE_OPTIONS,
  RELEVANCE_ALL,
  RELEVANCE_SALES_PERSON,
} from 'data/entities/account';
import { CUSTOMER, PROSPECT } from 'data/entities/status';
import type { AccountListQuery, Option } from 'data/repositories/accounts';
import { fetchOrgUser } from 'data/repositories/orgusers';
import { fetchPartnerBySlug } from 'data/repositories/partners';
import { SearchIndexItem } from 'data/repositories/search';
import withOrguser from 'contexts/withOrguser';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import AccountIsTargetField, { ALL_TARGET_ACCOUNTS } from 'components/AccountIsTargetField';
import AccountStatusSelector, { parseStatus } from 'components/AccountStatusSelector';
import SegmentSelector from 'components/SegmentSelector';
import Unisearchal from 'components/Unisearchal';
import UserSearch from 'components/UserSearch';

const reverseStatus = (options: ?(Option[])) => map(options, 'value');

type DropDownRelevanceFilteringProps = {
  selected: ?string,
  orguser: OrgUser,
  onChange: (val: string) => void,
};

function DropDownRelevanceFiltering({
  selected,
  orguser,
  onChange,
}: DropDownRelevanceFilteringProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const defaultOption = RELEVANCE_SALES_PERSON;
  const currentVal = selected && selected in FILTER_RELEVANCE_OPTIONS ? selected : defaultOption;
  return (
    <Dropdown
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      isOpen={isOpen}
    >
      <DropdownToggle
        className="filter-sort-btn"
        color="info"
        outline={currentVal === RELEVANCE_ALL}
      >
        {FILTER_RELEVANCE_OPTIONS[currentVal]}
        <FontAwesomeIcon
          icon={isOpen ? 'chevron-circle-up' : 'chevron-circle-down'}
          className="ml-2"
        />
      </DropdownToggle>
      <DropdownMenu>
        {map(FILTER_RELEVANCE_OPTIONS, (label, value) => (
          <DropdownItem
            key={value}
            onClick={() => {
              onChange(value);
            }}
          >
            {label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

const SIX_MONTHS = '6';

const FILTER_ACTIVITY_OPTIONS = ['3', SIX_MONTHS, '12'];

type PartnerActivitySelectorProps = {
  selected: ?string,
  onChange: (val: ?string) => void,
};

function PartnerActivitySelector({ selected, onChange }: PartnerActivitySelectorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const currentVal = selected && FILTER_ACTIVITY_OPTIONS.includes(selected) ? selected : null;
  const nullLabel = '(none)';
  const label = currentVal ? `${currentVal} months` : nullLabel;
  return (
    <Dropdown
      toggle={() => {
        setIsOpen(!isOpen);
      }}
      isOpen={isOpen}
    >
      <DropdownToggle color="secondary">
        {label}
        <FontAwesomeIcon
          icon={isOpen ? 'chevron-circle-up' : 'chevron-circle-down'}
          className="ml-2"
        />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          key={null}
          onClick={() => {
            onChange(null);
          }}
        >
          {nullLabel}
        </DropdownItem>
        {map(FILTER_ACTIVITY_OPTIONS, (months) => (
          <DropdownItem
            key={months}
            onClick={() => {
              onChange(months);
            }}
          >
            {months} months
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

type ProspectQuickFilterProps = {
  isActive: boolean,
  onChange: (isActive: boolean) => void,
};

function ProspectQuickFilter({ isActive, onChange }: ProspectQuickFilterProps) {
  return (
    <Button
      outline={!isActive}
      color="info"
      onClick={() => onChange(!isActive)}
      className="tooltip-wrapper QuickFilter--btn"
      block
    >
      {isActive && <FontAwesomeIcon icon="check-circle" className="mr-1" />}
      Prospect <FontAwesomeIcon icon="exchange-alt" className="mx-2" /> Partner Customer
    </Button>
  );
}

type State = {
  accountOwner: ?OrgUser,
  loadingAccountOwner: ?boolean,
  partner: ?[Partner],
  loadingPartner: ?boolean,
  isOpenToggle: boolean,
};

type Props = {
  loggedInOrguser: OrgUser,
  query: AccountListQuery,
  url: string,
  history: RouterHistoryT,
};

const DEFAULT_FILTERS: AccountListQuery = {
  partner: null,
  relevance: RELEVANCE_SALES_PERSON,
  status: PROSPECT,
  segment: null,
  accountName: null,
  partnerStatus: CUSTOMER,
  isTarget: null,
  isTargetValue: null,
  largestDeals: null,
  noPartnerActivityIn: null,
  accountOwner: null,
};

const DROPDOWN_FILTERS_KEYS = [
  'partner',
  'accountName',
  'status',
  'segment',
  'partnerStatus',
  'accountOwner',
  'noPartnerActivityIn',
];

class AccountsListFilters extends React.Component<Props, State> {
  state = {
    isOpenToggle: false,
    accountOwner: null,
    loadingAccountOwner: false,
    partner: null,
    loadingPartner: false,
  };

  componentDidMount() {
    this.loadFromQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props?.query?.partner === undefined && prevProps.query?.partner !== undefined) {
      this.setState({ partner: null });
    }
    if (
      this.props?.query?.accountOwner === undefined &&
      prevProps.query?.accountOwner !== undefined
    ) {
      this.setState({ accountOwner: null });
    }
  }

  get totalDropdownFilters(): number {
    const { query } = this.props;
    return reduce(
      DROPDOWN_FILTERS_KEYS,
      (total, key) => {
        if (query[key]) {
          return total + 1;
        }
        return total;
      },
      0
    );
  }

  changeQuery = (newFilters: AccountListQuery, first: ?boolean) => {
    const { history, query, url } = this.props;
    const queryStr = queryString.stringify(
      { ...query, ...newFilters },
      { skipNulls: true, encode: false, arrayFormat: 'brackets' }
    );
    const goto = queryStr ? `${url}?${queryStr}` : url;
    if (first) {
      history.replace(goto);
    } else {
      history.push(goto);
    }
  };

  setFilter = (filters: any) => {
    this.changeQuery(filters);
  };

  onRelevanceChange = (relevance: ?string) => {
    this.setFilter({ relevance });
  };

  onStatusChange = (result: ?(Option[])) => {
    this.setFilter({ status: reverseStatus(result) });
  };

  onSegmentChange = (result: ?(Option[])) => {
    this.setFilter({ segment: result.map((x) => x.value) });
  };

  onPartnerStatusChange = (result: ?(Option[])) => {
    this.setFilter({ partnerStatus: reverseStatus(result) });
  };

  onOwnerChange = (result: ?OrgUser) => {
    const accountOwner = result || null;
    this.setState({ accountOwner });
    this.setFilter({ accountOwner: accountOwner && accountOwner.id });
  };

  onAccountNameChange = (event: Event) => {
    this.setFilter({ accountName: event.target.value || null });
  };

  clearFilters = () => {
    this.setFilter({
      ...DEFAULT_FILTERS,
    });
    this.setState({ partner: null, accountOwner: null });
  };

  selectTarget = (selected: ?string) => {
    const newFilters = {
      isTargetValue: selected !== ALL_TARGET_ACCOUNTS ? selected : null,
      isTarget: selected === ALL_TARGET_ACCOUNTS ? true : null,
    };
    this.setFilter(newFilters);
  };

  toggleIsProspect = (isActive: boolean) => {
    if (isActive) {
      this.setFilter({
        status: PROSPECT,
        partnerStatus: CUSTOMER,
      });
    } else {
      this.setFilter({
        status: [],
        partnerStatus: [],
      });
    }
  };

  onChangeNoActivity = (result: ?string) => {
    this.setFilter({ noPartnerActivityIn: result });
  };

  setLargestDeals = (largestDeals: boolean) => {
    this.setFilter({ largestDeals: largestDeals || null });
  };

  onUnisearchalChange = (val: [?SearchIndexItem]) => {
    if (val) {
      this.setFilter({ partner: val.map((x) => x.slug) });
      this.setState({ partner: val });
    } else {
      this.setFilter({ partner: [] });
      this.setState({ partner: [] });
    }
  };

  loadFromQuery = () => {
    const { query } = this.props;
    const {
      relevance,
      accountOwner,
      partner,
      status,
      segment,
      partnerStatus,
      noPartnerActivityIn,
    } = query;
    if (accountOwner) {
      this.loadAccountOwner(accountOwner);
    }
    if (partner) {
      this.loadPartner(partner);
    }
    if (!isEmpty(query)) {
      return;
    }

    const newState = {
      ...DEFAULT_FILTERS,
      ...query,
      accountOwner,
      relevance: relevance || RELEVANCE_SALES_PERSON,
    };

    if (noPartnerActivityIn) {
      newState.noPartnerActivityIn = noPartnerActivityIn;
    }
    if (status || partnerStatus) {
      newState.status = status;
      newState.partnerStatus = partnerStatus;
    }
    if (segment) {
      newState.segment = segment;
    }
    this.changeQuery(newState, true);
  };

  loadAccountOwner = (ownerId: string) => {
    this.setState({ loadingAccountOwner: true }, () => {
      fetchOrgUser(ownerId)
        .then((owner) => this.setState({ accountOwner: owner, loadingAccountOwner: false }))
        .catch(() => this.setState({ loadingAccountOwner: false }));
    });
  };

  loadPartner(partner) {
    if (partner && partner.length !== 0) {
      const promises = [];
      partner.forEach((partnerSlug) => {
        if (partnerSlug) {
          promises.push(fetchPartnerBySlug(partnerSlug));
        }
      });
      this.setState({ loadingPartner: true }, () => {
        Promise.all(promises)
          .then((partners: [Partner]) => {
            this.setState({
              loadingPartner: false,
              partner: partners.map((partnerObj: Partner): SearchIndexItem => ({
                id: partnerObj.id,
                kind: 'partner',
                name: partnerObj.name,
                slug: partnerObj.slug,
              })),
            });
          })
          .catch(() => this.setState({ loadingPartner: false }));
      });
    }
  }

  render() {
    const { query, loggedInOrguser } = this.props;
    const { accountOwner, loadingAccountOwner, isOpenToggle, partner, loadingPartner } = this.state;
    const {
      relevance,
      status: queryStatus,
      segment,
      partnerStatus: queryPartnerStatus,
      accountName,
      isTarget,
      isTargetValue,
      noPartnerActivityIn,
      largestDeals,
    } = query;
    const currentOwner = isEmpty(accountOwner) ? [] : [accountOwner];
    const currentPartner = isEmpty(partner) ? [] : partner;
    const partnerStatus = parseStatus(queryPartnerStatus);
    const status = parseStatus(queryStatus);
    const isProspectActive =
      isEmpty(difference(PROSPECT, queryStatus)) &&
      isEmpty(difference(CUSTOMER, queryPartnerStatus));
    return (
      <div className="d-flex align-items-baseline justify-content-center mt-2">
        <div className="mr-3">
          <DropDownRelevanceFiltering
            orguser={loggedInOrguser}
            onChange={this.onRelevanceChange}
            selected={relevance}
          />
        </div>
        <div>
          <AccountIsTargetField
            showAll={isTarget}
            selected={isTargetValue}
            onChange={this.selectTarget}
          />
        </div>
        <div className="ml-3 mr-3">
          <ProspectQuickFilter isActive={isProspectActive} onChange={this.toggleIsProspect} />
        </div>
        <div className="mr-3">
          <Button
            outline={!largestDeals}
            color="info"
            onClick={() => this.setLargestDeals(!largestDeals)}
            className="tooltip-wrapper QuickFilter--btn"
            block
          >
            {largestDeals && <FontAwesomeIcon icon="check-circle" className="mr-1" />}
            Largest deals
          </Button>
        </div>
        <div>
          <Dropdown
            toggle={() => this.setState({ isOpenToggle: !isOpenToggle })}
            isOpen={isOpenToggle}
          >
            <DropdownToggle className="filter-btn">
              More Filters...{' '}
              <span className={`badge ${this.totalDropdownFilters > 0 ? 'badge-success' : ''}`}>
                {' '}
                {this.totalDropdownFilters}{' '}
              </span>{' '}
              <FontAwesomeIcon
                icon={isOpenToggle ? 'chevron-circle-up' : 'chevron-circle-down'}
                className="ml-2"
              />
            </DropdownToggle>
            <DropdownMenu style={{ width: '300%' }}>
              <div className="card-body">
                <Form>
                  <FormGroup>
                    <Label>Segment</Label>
                    <SegmentSelector segment={segment} onChange={this.onSegmentChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Partners</Label>
                    <Unisearchal
                      placeholder="Search for a partner..."
                      onChange={this.onUnisearchalChange}
                      onClear={this.onUnisearchalChange}
                      entityKind="partner"
                      defaultSelected={currentPartner}
                      stripUnisearchalClassName
                      hideClearButton
                      loadInitial
                      multiple
                      isLoading={loadingPartner}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Account Status</Label>
                    <AccountStatusSelector status={status} onChange={this.onStatusChange} own />
                  </FormGroup>
                  <FormGroup>
                    <Label>Account Owner</Label>
                    <UserSearch
                      onChange={this.onOwnerChange}
                      placeholder="Account Owner"
                      selected={currentOwner}
                      className="mr-2"
                      changeOnClear
                      isLoading={loadingAccountOwner}
                      disabled={loadingAccountOwner}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Account Name</Label>
                    <input
                      type="text"
                      placeholder="Account Name"
                      onChange={this.onAccountNameChange}
                      className="form-control row"
                      value={accountName || ''}
                    />
                  </FormGroup>
                  <hr />
                  <h5>At least one overlapping partner with...</h5>
                  <FormGroup>
                    <Label>No Crossbeam for Sales activity in...</Label>
                    <PartnerActivitySelector
                      selected={noPartnerActivityIn}
                      onChange={this.onChangeNoActivity}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Partner account status</Label>
                    <AccountStatusSelector
                      status={partnerStatus}
                      onChange={this.onPartnerStatusChange}
                      placeholder="Partner status"
                      own={false}
                    />
                  </FormGroup>
                </Form>
                <Button color="link" className="pl-1" onClick={this.clearFilters}>
                  <FontAwesomeIcon icon="times-circle" /> Reset Filters
                </Button>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default withOrguser(AccountsListFilters);
