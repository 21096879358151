/* eslint-disable max-classes-per-file */
// @flow

import OrgUser from './orguser';
import PartnerOrg from './partnerOrg';

export const InviteKind = {
  PARTNER_INVITE: 'PARTNER_INVITE',
  WAITLIST_INVITE: 'WAITLIST_INVITE',
  ORGUSER_INVITE: 'ORGUSER_INVITE',
  SHAREABLE_LINK: 'SHAREABLE_LINK',
};

class PartnerInvite {
  // Used to POST new partner invites

  id: string;

  email: ?string;

  code: string;

  kind: string;

  status: ?string;

  fromOrg: ?PartnerOrg;

  fromOrguser: ?OrgUser;

  bdManager: ?OrgUser;

  createdAt: Date;

  sentAt: ?Date;

  static fromApi: (data: any) => PartnerInvite;

  isKind(inviteKind: string): boolean {
    return this.kind === inviteKind;
  }

  get isAccepted(): boolean {
    return this.status === 'ACCEPTED';
  }
}

PartnerInvite.fromApi = (data: any) => {
  const pi = new PartnerInvite();
  pi.id = data.id;
  pi.email = data.email;
  pi.code = data.code;
  pi.fromOrg = data.from_org && PartnerOrg.fromApi(data.from_org);
  pi.fromOrguser = data.from_orguser && OrgUser.fromApi(data.from_orguser);
  pi.bdManager = data.bd_manager && OrgUser.fromApi(data.bd_manager);
  pi.createdAt = data.created_at && new Date(data.created_at);
  pi.sentAt = data.email_sent_at && new Date(data.email_sent_at);
  pi.kind = data.kind;
  pi.status = data.status;
  return pi;
};

class PartnerInviteSent {
  // Used for list of PartnerInvites

  id: string;

  inviteLink: string;

  email: string;

  companyName: string;

  firstName: string;

  lastName: string;

  get fullName() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  createdAt: string;

  sentAt: Date;

  bdManagerId: string;

  canResend: boolean;

  status: ?string;

  static fromApi: (data: any) => PartnerInviteSent;

  get isPending(): boolean {
    return this.status === 'INVITED';
  }

  get isAccepted(): boolean {
    return this.status === 'ACCEPTED';
  }
}

PartnerInviteSent.fromApi = (data: any): PartnerInviteSent => {
  const pi = new PartnerInviteSent();
  pi.id = data.id;
  pi.inviteLink = data.invite_link;
  pi.companyName = data.company_name;
  pi.firstName = data.first_name;
  pi.lastName = data.last_name;
  pi.email = data.email;
  pi.createdAt = data.created_at;
  pi.sentAt = data.email_sent_at && new Date(data.email_sent_at);
  pi.bdManagerId = data.bd_manager;
  pi.canResend = data.can_resend;
  pi.status = data.status;
  return pi;
};

export { PartnerInvite, PartnerInviteSent };
