// Tracking Action Types
export const COPY_CONTACT_EMAIL = 'COPY_CONTACT_EMAIL';
export const NEW_MESSAGE_BUTTON_CLICK = 'NEW_MESSAGE_BUTTON_CLICK';

// Tracking API Sources
export const CONTACT_DETAIL = 'contact-detail';
export const CONTACT_LIST = 'contact-list';
export const GENERIC_FRONTEND_SOURCE = 'partnered-frontend';
export const ACCOUNT_OWNER_CARD = 'account-owner-card';
export const CONTACT_CARD_SOURCE = 'contact-card';

export const FRONTEND_REQUEST_SOURCE_API = 'Sales Edge API';

export const TRACKING_ACTIONS = [COPY_CONTACT_EMAIL];
