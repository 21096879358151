import React from 'react';

// Regex used to identify mentions. 2 Capture groups: 1st is the name, 2nd is the user id
export const mentionRegex = /@\[(.+?)]\((\w+?)\)/gi;

export const formatMentions = (text, formatFn) => {
  // Return message text with mentions formatted
  const defaultFormatFn = (part, i, id) => (
    <span key={id || i} className="RequestComment__mention">
      @{part}
    </span>
  );
  const doFormat = formatFn || defaultFormatFn;
  const parts = text.split(mentionRegex);

  for (let i = 1; i < parts.length; i += 3) {
    parts[i] = doFormat(parts[i], i, parts[i + 1]);
    // Remove id from array.
    parts[i + 1] = undefined;
  }

  // Remove undefined.
  return parts.filter((p) => p);
};
