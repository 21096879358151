// @flow

import * as React from 'react';

import type { LocationT } from 'sharedTypes/reactRouter';

import LoadingRing from 'components/LoadingRing';

import hubspotValidation from './hubspotValidation';

type Props = {
  location: LocationT,
};

type State = {
  errorMsg: ?string,
  loading: boolean,
  slackSetupRedirectTarget: string,
};

class HubspotCodeValidation extends React.Component<Props, State> {
  state: State = { errorMsg: null, loading: true };

  componentDidMount() {
    this.validateCode();
  }

  async validateCode() {
    const { location } = this.props;
    hubspotValidation(location)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((errorMsg) => this.setState({ errorMsg, loading: false }));
  }

  render() {
    const { loading, errorMsg } = this.state;
    let main = (
      <div>
        <h4>Successfully connected your Hubspot Portal.</h4>
      </div>
    );

    if (loading) {
      main = <LoadingRing text="Loading..." />;
    }
    if (errorMsg) {
      main = <h5>{errorMsg}</h5>;
    }

    return <div className="mt-5">{main}</div>;
  }
}

export default HubspotCodeValidation;
