// @flow

import React, { useCallback, useEffect, useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { debounce, get } from 'lodash';

import filterEmoji from 'data/repositories/filterEmoji';
import { bdRequestMentionableUsers } from 'data/repositories/orgusers';

import textAreaStyle from './textAreaStyle';

const neverMatchingRegex = /($a)/;

type Props = {
  isShared: boolean,
  value: string,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  bdRequestId: ?string,
  partnerId: ?string,
  partnerAccountId: ?string,
  mini?: boolean,
};

const TextArea = ({
  isShared,
  value,
  onChange,
  bdRequestId,
  partnerAccountId,
  partnerId,
  mini,
}: Props) => {
  // Focus input on isShared change.
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [isShared]);

  const queryEmojis = (query, callback) => {
    const emojis = filterEmoji(query);
    callback(
      emojis.map(({ title, symbol }) => ({
        id: `${symbol}`,
        display: `${symbol} :${title}:`,
      }))
    );
  };

  const queryUsers = (query, callback) => {
    const queryTooLong = query && query.length > 25;
    if (
      (!(isShared && (bdRequestId || partnerAccountId || partnerId)) && (!query || query === '')) ||
      queryTooLong
    ) {
      return;
    }
    let mentionArgs = [query];
    if (isShared) {
      const extra = bdRequestId ? [bdRequestId] : [bdRequestId, partnerAccountId, partnerId];
      mentionArgs = [...mentionArgs, ...extra];
    }
    bdRequestMentionableUsers(...mentionArgs)
      .then((users) => {
        callback(
          users.results.map(({ fullName, id, orgName }) => ({
            id,
            display: orgName ? `${fullName} (${orgName})` : fullName,
          }))
        );
      })
      .catch((e) => {
        const searchError = get(e, 'json.search');
        if (searchError) {
          // ignore these api errors for now
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQueryUsers = useCallback(debounce(queryUsers, 200), [
    isShared,
    bdRequestId,
    partnerAccountId,
    partnerId,
  ]);

  const placeholder = isShared
    ? 'Type a message to your partner...'
    : 'Type an internal message for your team...';

  return (
    <MentionsInput
      id="text"
      name="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ ...textAreaStyle, ...(mini ? { minHeight: '3em' } : {}) }}
      inputRef={ref}
      autoFocus
      allowSpaceInQuery
      allowSuggestionsAboveCursor
    >
      <Mention
        trigger="@"
        data={debouncedQueryUsers}
        displayTransform={(id, display) => `@${display}`}
      />
      <Mention trigger=":" markup="__id__" regex={neverMatchingRegex} data={queryEmojis} />
    </MentionsInput>
  );
};

TextArea.defaultProps = {
  mini: false,
};

export default TextArea;
