// @flow
import Org from 'data/entities/org';

import OrgUser from './orguser';

class OrgUserWithOrg extends OrgUser {
  org: Org;

  static fromApi = (d: any): OrgUserWithOrg => {
    const ou = OrgUser.fromApi(d);
    ou.org = Org.fromApi(d.org);
    return ou;
  };
}

export default OrgUserWithOrg;
