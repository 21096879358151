import * as React from 'react';

const BackgroundEffect = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={300}
    height={125}
    viewBox="0 0 265 116"
  >
    <g clipPath="url(#a)" opacity=".5">
      <path
        stroke="url(#b)"
        strokeOpacity=".4"
        d="M1.801 29.09h280.957M-18 49.32h294.407M19.735 69.924H285M36.921 89.78h214.454M-3.429 110.385h229.025M38.789-5v124M58.964-5v124M79.887-5v124M99.688-5v124m20.175 0V-5m20.549 0v124m20.175 0V-5m20.175 0v124m19.428 0V-5m20.549 0v124m20.175 0V-5m20.175 22.103v70.054M63.448 8.861h195.025"
      />
      <circle cx="80" cy="9" r="5" fill="url(#c)" />
      <circle cx="140.5" cy="29" r="5" fill="url(#d)" />
      <circle cx="99.5" cy="89.5" r="5" fill="url(#e)" />
      <circle cx="160.5" cy="110.5" r="5" fill="url(#f)" />
      <circle cx="241" cy="70" r="5" fill="url(#g)" />
      <circle cx="241" cy="9" r="5" fill="url(#h)" />
    </g>
    <defs>
      <radialGradient
        id="b"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 62 -151.5 0 133.5 57)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#3F41DB" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="c"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 5 -5 0 80 9)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#B2B3F1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="d"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 5 -5 0 140.5 29)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#B2B3F1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="e"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(90 5 94.5) scale(5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#B2B3F1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="f"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(90 25 135.5) scale(5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#B2B3F1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="g"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 5 -5 0 241 70)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#B2B3F1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="h"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 5 -5 0 241 9)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F41DB" />
        <stop offset="1" stopColor="#B2B3F1" stopOpacity="0" />
      </radialGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h265v116H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BackgroundEffect;
