// @flow

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

type Props = {
  targetId: string,
  children: React.Node,
  icon?: string,
  className?: string,
  size?: string,
};

const InfoTip = ({ targetId, children, icon, className, size }: Props) => {
  const target = `tip-${targetId}`;
  const tipClassName = classNames('InfoTip', className);
  return (
    <>
      <span id={target} className={tipClassName}>
        <FontAwesomeIcon icon={icon} size={size} />
      </span>
      <UncontrolledTooltip placement="right" target={target} delay={{ show: 150, hide: 50 }}>
        {children}
      </UncontrolledTooltip>
    </>
  );
};

InfoTip.defaultProps = {
  icon: 'info-circle',
  className: '',
  size: 'sm',
};

export default InfoTip;
