// @flow

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { Org } from 'data/entities';
import { INVALID_CREDENTIALS_STATUS, SCHEMA_SYNCED_STATUS } from 'data/entities/chug';
import { fetchLastIntegration } from 'data/repositories/chug';
import { fetchCrossbeamIntegration } from 'data/repositories/crossbeam';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';
import LogoCrossbeam from 'components/LogoCrossbeam';

type Props = {
  loggedInOrg: Org,
};

const Organization = ({ loggedInOrg: { lastIntegration } }: Props) => {
  const [crossbeam, setCrossbeam] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [loading, setLoading] = useState(false);

  const finishSetup = integration && integration.status === SCHEMA_SYNCED_STATUS;

  useEffect(() => {
    setCrossbeam(null);
    setIntegration(null);
    setLoading(true);

    const promises = [fetchLastIntegration(), fetchCrossbeamIntegration()];

    Promise.all(promises)
      .then(([i, cb]) => {
        setLoading(false);
        setIntegration(i);
        setCrossbeam(cb);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="card card-body d-flex flex-row align-items-center py-4 gap-30">
      <div className="flex-fill d-flex flex-row align-items-center gap-15">
        <div className="rounded bg-white border-light p-2">
          <LogoCrossbeam text={false} height={30} width={30} />
        </div>

        {!loading ? (
          <div className="d-flex flex-column">
            <span className="large-text bold gray-700">Crossbeam</span>
            {integration?.isActive ? (
              <span className="normal-text semi-bold gray-500">
                {crossbeam && crossbeam.partnersLastSyncAt ? (
                  <>
                    <FontAwesomeIcon icon="check" width="14" height="14" className="mr-2 green" />
                    {`Last synced on ${moment(crossbeam.partnersLastSyncAt).format(
                      'MM/DD/YYYY [at] h:mm A'
                    )}`}
                  </>
                ) : (
                  'Your organization has not imported any data from Crossbeam.'
                )}
              </span>
            ) : (
              <span className="normal-text semi-bold red">
                <FontAwesomeIcon
                  icon="exclamation-circle"
                  width="14"
                  height="14"
                  className="mr-2"
                />
                {integration?.status === INVALID_CREDENTIALS_STATUS
                  ? 'Error: Bad Credentials'
                  : 'Data Sync Inactive'}
              </span>
            )}
            <span className="normal-text semi-bold gray-500 mb-2">
              <FontAwesomeIcon icon="user" width="14" height="14" className="mr-2" />
              Authenticated User {integration?.orguser?.fullName}{' '}
              {integration?.orguser?.sourceUsername && `(${integration?.orguser?.sourceUsername})`}{' '}
              {integration?.orguser?.email}
            </span>
            {crossbeam &&
              !!crossbeam.partnersUnclassifiedPopulations &&
              crossbeam.partnersUnclassifiedPopulations !== 0 && (
                <Alert color="warning">
                  <FontAwesomeIcon
                    icon="triangle-exclamation"
                    width="14"
                    height="14"
                    className="orange mr-2"
                  />
                  <span className="normal-text bold gray-700">{`We found ${crossbeam.partnersUnclassifiedPopulations} new unclassified partner populations.`}</span>
                </Alert>
              )}
          </div>
        ) : (
          <LoadingRing maxWidth="30px" />
        )}
      </div>

      {!loading && (
        <div>
          {integration?.isActive ? (
            <>
              {finishSetup ? (
                <Link to="/complete-signup" className="btn btn-primary">
                  Finish Setup
                </Link>
              ) : (
                <>
                  {crossbeam ? (
                    <Link to="/crossbeam" className="btn btn-secondary">
                      Manage
                    </Link>
                  ) : (
                    <a
                      href={process.env.REACT_APP_CROSSBEAM_DATA_AUTH_URL}
                      className="btn btn-primary"
                    >
                      Setup Crossbeam Integration
                    </a>
                  )}
                </>
              )}
            </>
          ) : (
            <Link to="/onboarding" className="btn btn-primary">
              {integration?.status === INVALID_CREDENTIALS_STATUS ? 'Re-Enable' : 'Enable'}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default withOrguser(Organization);
