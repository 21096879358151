// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Account, OrgUser } from 'data/entities';
import type { AccountPartnerBase } from 'data/entities/account';
import List from 'data/entities/lists';
import { AccountDetailAccountPartner } from 'data/repositories/accounts';
import withOrguser from 'contexts/withOrguser';

import DeleteFromList from 'views/Lists/DeleteFromList';
import PartnerDetailHoverCard from 'views/PartnerDetail/PartnerDetailHoverCard';
import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import NewBdRequestButton from 'components/NewBdRequestButton';
import Signals from 'components/Signals';
import SlackChannelMissing from 'components/SlackChannelMissing';
import TargetLabel from 'components/TargetLabel';

import ListPopup from '../Lists/ListPopup';

type Props = {
  isLoaded: boolean,
  account: Account | AccountPartnerBase,
  accountPartners: AccountDetailAccountPartner[],
  style: { [key: string]: string },
  selectedPartners: AccountDetailAccountPartner[],
  onSelect: (selectedPartners: AccountDetailAccountPartner[]) => void,
  canSelect: boolean,
  refreshSize: () => void,
  disabled?: boolean,
  list?: List,
  onDelete?: (accountPartnerId: string) => void,
  noShadow?: boolean,
  inAccountDetail?: boolean,
  loggedInOrguser: OrgUser,
  partnerDetailCard?: boolean,
};

const AccountPartnerCard = ({
  account,
  isLoaded,
  accountPartners,
  style,
  selectedPartners,
  onSelect,
  canSelect,
  refreshSize,
  disabled,
  list,
  onDelete,
  noShadow,
  inAccountDetail,
  partnerDetailCard,
  loggedInOrguser,
}: Props) => {
  const { capabilities } = loggedInOrguser;
  const selected = (selectedPartners || []).some((p) =>
    accountPartners.some((ap) => ap.id === p.id)
  );
  const firstAccountPartner = (accountPartners || [])[0];
  const hasMultiple = accountPartners.length > 1;
  const { partner, partnerAccount, isMyTarget, isPartnerTarget, signals } =
    firstAccountPartner || {};

  const [expanded, setExpanded] = useState(false);

  const selectFirst = () =>
    canSelect &&
    onSelect(
      selected
        ? selectedPartners.filter((p) => p.id !== firstAccountPartner.id)
        : [...selectedPartners, firstAccountPartner]
    );

  return (
    <>
      <div style={style}>
        {isLoaded ? (
          <div
            tabIndex={0}
            role="button"
            onClick={selectFirst}
            onKeyDown={selectFirst}
            className={classNames(
              'card m-0 d-flex flex-row align-items-top card-body gap-15',
              noShadow && 'no-shadow',
              // eslint-disable-next-line no-nested-ternary
              canSelect ? (hasMultiple ? 'cursor-pointer' : 'card-hoverable') : 'cursor-default',
              selected && 'border border-blue'
            )}
            style={disabled ? { opacity: 0.7, pointerEvents: 'none' } : {}}
          >
            {canSelect && (
              <div>
                <input
                  type="checkbox"
                  checked={selected}
                  readOnly={!hasMultiple}
                  onChange={selectFirst}
                />
              </div>
            )}
            <div className="flex-fill d-flex flex-column gap-20">
              <div className="d-flex flex-row gap-15">
                {partnerDetailCard ? (
                  <PartnerDetailHoverCard partner={partner}>
                    <CompanyLogo
                      className="m-0"
                      size={48}
                      name={partner.name}
                      domain={partner.domain}
                      logoUrl={partner.logoUrl}
                    />
                  </PartnerDetailHoverCard>
                ) : (
                  <CompanyLogo
                    className="m-0"
                    size={48}
                    name={partner.name}
                    domain={partner.domain}
                    logoUrl={partner.logoUrl}
                  />
                )}
                <div className="flex-fill d-flex flex-column gap-10">
                  <div className="d-flex flex-row align-items-center gap-20">
                    <div className="d-flex flex-column flex-fill">
                      <div className="d-flex flex-row align-items-center gap-10">
                        <Link
                          to={`/p/${partner.slug}`}
                          className="large-text bold gray-700 btn-link"
                        >
                          {partner.name}
                        </Link>
                        {partnerAccount.status}
                      </div>
                      <div className="d-flex flex-row align-items-center gap-5">
                        <span className="normal-text semi-bold gray-400">Partner Account:</span>
                        <span className="normal-text semi-bold gray-700 fs-mask">
                          {partnerAccount.name}
                        </span>
                        {hasMultiple && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setExpanded(!expanded);
                              setTimeout(refreshSize, 0);
                            }}
                            type="button"
                            className="btn btn-link p-0 normal-text bold blue pl-2"
                          >
                            <span>+ {accountPartners.length - 1} more</span>
                            <FontAwesomeIcon
                              icon={expanded ? 'chevron-up' : 'chevron-down'}
                              className="pl-2"
                            />
                          </button>
                        )}
                      </div>
                      <div className="d-flex flex-row align-items-center gap-5">
                        <TargetLabel isMyTarget={isMyTarget} isPartnerTarget={isPartnerTarget} />
                      </div>
                    </div>
                    {capabilities.canAccessSeatedExperience && (
                      <>
                        {list && onDelete ? (
                          <DeleteFromList
                            onDelete={onDelete}
                            list={list}
                            accountPartnerId={firstAccountPartner.id}
                          />
                        ) : (
                          <>
                            {capabilities.viewOnly && (
                              <UncontrolledTooltip target={`list-popup-${firstAccountPartner.id}`}>
                                Lists are only available with a Co-Seller seat.
                              </UncontrolledTooltip>
                            )}
                            <ListPopup
                              accountPartnerId={firstAccountPartner.id}
                              disabled={capabilities.viewOnly}
                            />
                          </>
                        )}
                      </>
                    )}
                    {!inAccountDetail && (
                      <>
                        {capabilities.viewOnly && (
                          <UncontrolledTooltip
                            target={`request-${firstAccountPartner.partnerAccount.id}`}
                          >
                            Messaging partners is only available with a Co-Seller seat.
                          </UncontrolledTooltip>
                        )}
                        <div id={`request-${firstAccountPartner.partnerAccount.id}`}>
                          <NewBdRequestButton
                            accountSlug={account.slug}
                            partnerAccountId={firstAccountPartner.partnerAccount.id}
                            partnerSlug={firstAccountPartner.partner.slug}
                            disabled={capabilities.viewOnly}
                            compact
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <Signals signals={signals} />
                </div>
              </div>

              {partner.slackChannel?.isDeleted && (
                <SlackChannelMissing
                  toolTipIdSuffix={partner.id}
                  loggedInOrguser={loggedInOrguser}
                />
              )}

              {expanded && (
                <div
                  className="flex-fill d-flex flex-column gap-10"
                  style={{ overflow: 'auto', maxHeight: 400 }}
                >
                  <span className="normal-text bold black">
                    {inAccountDetail
                      ? 'Select the partner account:'
                      : `Select the account you want to message ${partner.name} about:`}
                  </span>
                  {accountPartners.slice(1, accountPartners.length).map((ap) => {
                    const selectedInner = (selectedPartners || []).some((p) => p.id === ap.id);
                    const selectInner = (e) => {
                      if (!canSelect) return;
                      e.stopPropagation();
                      onSelect(
                        selectedInner
                          ? selectedPartners.filter((p) => p.id !== ap.id)
                          : [
                              // Can only select one partner account from accountPartners
                              ...selectedPartners.filter((p) => p.partner.id !== ap.partner.id),
                              ap,
                            ]
                      );
                    };
                    return (
                      <div
                        key={ap.id}
                        tabIndex={0}
                        role="button"
                        className={classNames(
                          'card flex-shrink-0 card-body no-shadow m-0 p-3 d-flex flex-row align-items-center gap-10',
                          selectedInner && 'border border-blue',
                          canSelect ? 'card-hoverable' : 'cursor-default'
                        )}
                        onClick={selectInner}
                        onKeyDown={selectInner}
                      >
                        {canSelect && <input type="radio" checked={selectedInner} readOnly />}
                        <CompanyLogo
                          className=""
                          size={36}
                          name={ap.partnerAccount.name}
                          domain={ap.partnerAccount.website}
                        />
                        <span className="large-text bold gray-700">{ap.partnerAccount.name}</span>
                        {ap.partnerAccount.status}
                        <Signals signals={ap.signals} compact />
                        <div className="flex-fill" />
                        {capabilities.canAccessSeatedExperience && list && onDelete && (
                          <DeleteFromList
                            onDelete={onDelete}
                            list={list}
                            accountPartnerId={ap.id}
                          />
                        )}
                        {inAccountDetail ? (
                          capabilities.canAccessSeatedExperience && (
                            <>
                              {capabilities.viewOnly && (
                                <UncontrolledTooltip target={`list-popup-${ap.id}`}>
                                  Lists are only available with a Co-Seller seat.
                                </UncontrolledTooltip>
                              )}
                              <ListPopup
                                accountPartnerId={ap.id}
                                disabled={capabilities.viewOnly}
                              />
                            </>
                          )
                        ) : (
                          <>
                            {capabilities.viewOnly && (
                              <UncontrolledTooltip target={`request-${ap.partnerAccount.id}`}>
                                Messaging partners is only available with a Co-Seller seat.
                              </UncontrolledTooltip>
                            )}
                            <div id={`request-${ap.partnerAccount.id}`}>
                              <NewBdRequestButton
                                accountSlug={account.slug}
                                partnerAccountId={ap.partnerAccount.id}
                                partnerSlug={ap.partner.slug}
                                disabled={capabilities.viewOnly}
                                compact
                              />
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'm-0 card h-100 d-flex justify-content-center',
              noShadow && 'no-shadow'
            )}
          >
            <LoadingRing maxWidth="50px" />
          </div>
        )}
      </div>
    </>
  );
};

AccountPartnerCard.defaultProps = {
  list: null,
  onDelete: () => {},
  noShadow: false,
  disabled: false,
  inAccountDetail: false,
  partnerDetailCard: false,
};

export default withOrguser(AccountPartnerCard);
