// @flow

import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Account, PartnerAccount } from 'data/entities';

type Props = {
  isOpen: boolean,
  account: Account,
  partnerAccount: PartnerAccount,
  toggle: () => void,
  onDelete?: ?() => Promise<any> | ((string, string, string) => Promise<any>),
  body: React.Node,
  header?: React.Node,
  onUpdate?: () => void,
  message?: string,
  btnText?: string,
};

type State = {
  loading: boolean,
  errors: string[],
};

class BaseDeleteAccountPartner extends React.Component<Props, State> {
  state: State = { loading: false, errors: null };

  onDeleteAccountMapping = () => {
    this.setState(
      { loading: true },
      // Once state has updated, call function below.
      () => {
        const { account, partnerAccount, onUpdate, message, onDelete, toggle } = this.props;
        const deleteFn = onDelete;
        deleteFn(account.slug, partnerAccount.id, message)
          .then(() => {
            this.setState({ loading: false });
            onUpdate();
            toggle();
          })
          .catch((e) => {
            const errors = [e.json.detail];
            this.setState({ loading: false, errors });
          });
      }
    );
  };

  render() {
    const { isOpen, toggle, header, body, btnText } = this.props;
    const { loading, errors } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle} className="mb-3">
          {header}
        </ModalHeader>
        <ModalBody>
          {errors &&
            errors.map((error) => (
              <div className="alert alert-danger" key={error}>
                {error}
              </div>
            ))}
          {body}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.onDeleteAccountMapping} disabled={loading}>
            {btnText}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

BaseDeleteAccountPartner.defaultProps = {
  onUpdate: () => {},
  message: '',
  header: 'Delete Account Mapping',
  btnText: 'Delete',
  onDelete: null,
};

export default BaseDeleteAccountPartner;
