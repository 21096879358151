// @flow

import * as React from 'react';
import { Token } from 'react-bootstrap-typeahead';
import { filter } from 'lodash/collection';

import { Partner } from 'data/entities';
import { OrgUserNameOnly } from 'data/entities/orguser';
import { bdRequestMentionableUsers } from 'data/repositories/orgusers';
import { skipNull } from 'utils/nodash';

import UserItemToken from 'views/BdRequests/NewBdRequest/ToUserItemToken';
import UserSearch from 'components/UserSearch';

type Props = {
  partner: Partner,
  partnerAccountId: string,
  selectedParticipants: OrgUserNameOnly[],
  onChange: (OrgUserNameOnly[]) => void,
};

const labelKey = (orguser: OrgUserNameOnly): string => {
  const { fullName, orgName } = orguser;
  let response = fullName;
  if (orgName) {
    response = `${response} (${orgName})`;
  }
  return response;
};

const onAddParticipantSearch = (partnerAccountId: ?string, partnerId: ?string) => {
  const getResults = (query: string) => {
    if (!partnerId || !partnerAccountId) {
      return Promise.resolve([]);
    }
    return bdRequestMentionableUsers(query, null, partnerAccountId, partnerId);
  };
  return getResults;
};

const userIsPartnerManager = (
  partner: Partner,
  selectedParticipants: OrgUserNameOnly[],
  orguser: OrgUserNameOnly
) => {
  // If they're a required partner manager, then they cannot be removed
  // If they're the only person on there from a different org, they can be removed.
  const partnerManagerIds = [partner?.bdManager.id, partner?.bdManagerAtPartner?.id];
  const isPartnerManager = skipNull(partnerManagerIds).includes(orguser.id);
  // Determine if the partner manager at the partner can be removed
  let tokenProps = isPartnerManager ? { onRemove: null } : {};
  if (
    partner &&
    orguser.id === partner.bdManagerAtPartner?.id &&
    !filter(
      selectedParticipants,
      (o) => o.orgName === partner.name && o.id !== partner.bdManagerAtPartner?.id
    ).length
  ) {
    // There are no users other than the partner manager, so the partner's partner manager is required
    tokenProps = {};
  }
  return [isPartnerManager, tokenProps];
};

export default function ToParticipantUserSearch({
  partner,
  partnerAccountId,
  selectedParticipants,
  onChange,
}: Props) {
  return (
    <UserSearch
      searchMethod={onAddParticipantSearch(partnerAccountId, partner?.id)}
      onChange={onChange}
      selected={selectedParticipants}
      labelKey={labelKey}
      clearButton={false}
      bsSize="sm"
      renderToken={(orguser: OrgUserNameOnly, props) => {
        const [isPartnerManager, tokenProps] = userIsPartnerManager(
          partner,
          selectedParticipants,
          orguser
        );
        return (
          <Token {...props} option={orguser} {...tokenProps} key={orguser.id}>
            <UserItemToken orguser={orguser} isPartnerManager={isPartnerManager} />
          </Token>
        );
      }}
      multiple
    />
  );
}
