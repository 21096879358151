// @flow

import React from 'react';

type Props = {
  title: string,
  explanation: string,
  condition: boolean,
  icon: React$Node,
  children: React$Node,
  extra?: React$Node,
};

const BaseOverlay = ({ title, explanation, condition, icon, extra, children }: Props) =>
  condition ? (
    <div className="w-100 flex-fill" style={{ position: 'relative' }}>
      {children}

      {/* Overlay */}
      <div
        className="w-100 h-100 overlay-gradient"
        style={{ position: 'absolute', top: 0, transform: 'scale(1.10)' }}
      />

      {/* Content */}
      <div
        className="d-flex flex-column gap-15 align-items-center w-100 flex-fill p-5"
        style={{ position: 'absolute', top: '10%' }}
      >
        {icon}
        <div className="d-flex flex-column align-items-center gap-5">
          <h5 className="m-0 bold-text black text-center">{title}</h5>
          <span className="normal-text semi-bold gray-400 mb-3">{explanation}</span>
          {extra}
        </div>
      </div>
    </div>
  ) : (
    children || null
  );

BaseOverlay.defaultProps = {
  extra: null,
};

export default BaseOverlay;
