// @flow

class Avatar {
  avatarFile: File;

  sourceFile: File;

  scale: number;

  XOffset: number;

  YOffset: number;

  static fromApi = (d: any): Avatar => {
    const u = new Avatar();
    u.avatarFile = d.avatar_file;
    u.sourceFile = d.avatar_source_file;
    u.scale = d.avatar_scale;
    u.XOffset = d.avatar_x_offset;
    u.YOffset = d.avatar_y_offset;

    return u;
  };
}

export default Avatar;
