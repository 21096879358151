// @flow

import { camelizeKeys } from 'utils/nodash';

function newObjectWithApiValues<T>(ObjType: Class<T>, data: any): T {
  // Returns new object of specific type. Mapping camelcase keys from the api
  return Object.assign(new ObjType(), camelizeKeys(data));
}

type HasId = {
  id: string,
};

function updateObjectList<T: HasId>(
  objectList: T[], // A list of objects, that must at minimum contain an `id` field
  objectId: string, // Id of object to update
  data: ?{}, // Can either be an entire object to replace with or a subset of fields to update
  doDelete: ?boolean = false,
  appendMissing: ?boolean = false // Add the item if one isn't found
): T[] {
  /*
  e.g. updateObjectList(results, requestId, nextData, doDelete);
  */
  // From a list of objects, return list with updated or deleted object
  const length = !objectList ? 0 : objectList.length;
  if (!length) {
    // If append missing is true, return a list with that object.
    return appendMissing ? [data] : objectList;
  }
  // Check the `.id` of the objects in each list to find one matching object Id
  // NOTE: This can be updated later to accept custom key for lookup
  const idx = objectList.findIndex((obj) => obj.id === objectId);

  if (idx < 0) {
    // If there wasn't a match, just return initial object list
    if (appendMissing) {
      objectList.push(data);
    }
    return objectList;
  }
  if (doDelete) {
    // If this is a delete operation, remove one item at that index
    objectList.splice(idx, 1);
  } else if (data) {
    // Get the object from the list and update the fields on it with new data
    // `data` may either be an object of the same type or an object with some subset of fields
    const obj = objectList[idx];
    Object.entries(data).map(([key, val]) => {
      obj[key] = val;
      return null;
    });
    objectList[idx] = obj;
  }
  return objectList;
}

export { newObjectWithApiValues, updateObjectList };
