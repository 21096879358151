// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from 'data/entities/lists';
import { toggleListFavorite } from 'data/repositories/lists';

import PersonAvatar from 'components/PersonAvatar';

type Props = {
  list: List,
  onUpdate: (newList: List) => void,
};

const ListCard = ({ list, onUpdate }: Props) => {
  const { name, description, assignedTo, createdAt, isFavorite, id } = list || {};

  const [submitting, setSubmitting] = useState(false);
  const toggleFavorite = () => {
    if (!submitting) {
      setSubmitting(true);
      toggleListFavorite(id, !isFavorite)
        .then((l) => {
          setSubmitting(false);
          onUpdate(l);
        })
        .catch((error) => {
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="card m-0">
      <div className="card-body gap-20 py-2 px-3 pr-4 d-flex flex-row align-items-center gap-40">
        <div className="flex-fill d-flex flex-column gap-5" style={{ width: '0px' }}>
          <Link to={`/lists/${id}`} className="bold large-text text-truncate gray-700">
            {name}
          </Link>
          {description && <span className="normal-text semi-bold gray-500">{description}</span>}
        </div>
        <div className="d-flex flex-row align-items-center gap-10">
          <PersonAvatar
            firstName={assignedTo.firstName}
            lastName={assignedTo.lastName}
            avatar={assignedTo.avatarImage}
            size={28}
          />
          <div className="d-flex flex-column">
            <Link
              to={`/network/oid/${assignedTo.id}`}
              className="bold normal-text text-truncate gray-700"
            >
              {assignedTo.fullName}
            </Link>
            <span className="bold normal-text gray-500">
              {createdAt && createdAt.toLocaleDateString()}
            </span>
          </div>
        </div>
        <FontAwesomeIcon
          cursor="pointer"
          icon={{ prefix: isFavorite ? 'fas' : 'far', iconName: 'star' }}
          onClick={toggleFavorite}
          className={isFavorite ? 'yellow' : 'gray-600'}
          title="Toggle favorite"
        />
      </div>
    </div>
  );
};

export default ListCard;
