// @flow

import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { deleteBdRequestQuestion } from 'data/repositories/bdrequestQuestion';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';

type Props = {
  question: BdRequestQuestion,
  onDelete: () => void,
};

const DeleteQuestionModal = ({ question, onDelete }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((s) => !s);

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const doDelete = () => {
    setSubmitting(true);
    setErrors([]);
    deleteBdRequestQuestion(question.id)
      .then(() => {
        setSubmitting(false);
        onDelete();
      })
      .catch((e) => {
        setSubmitting(false);
        setErrors(e.json.detail);
      });
  };

  return (
    <>
      <button type="button" className="btn btn-link" onClick={toggle}>
        <FontAwesomeIcon icon="trash" className="red" />
      </button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title="Remove a question"
        footer={
          submitting ? (
            <LoadingRing maxWidth="30px" />
          ) : (
            <>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="danger" onClick={doDelete}>
                Remove
              </Button>
            </>
          )
        }
      >
        <span className="large-text bold gray-700">
          Are you sure that you want to remove this question?
        </span>
        <span className="normal-text semi-bold gray-600">
          Once you delete it, your sales team will not see it in the questions list to send to
          partners.
        </span>
        <FieldErrors errors={errors} />
      </Modal>
    </>
  );
};

export default DeleteQuestionModal;
