// @flow

export type PaginatedResponse<T> = {
  results: T[],
  next: ?string,
  previous: ?string,
  count: ?number,
};

export function mapPaginatedResponse<T>(
  mapFn: (i: any) => T
): (resp: PaginatedResponse<any>) => PaginatedResponse<T> {
  // Called with a the mapping fn to use on paginated.results.
  // Returns a function that can be used as a callback to .then() for authFetch().then() calls.
  // Ex. authFetch(url).then(mapPaginatedResponse(OrgUser.fromApi))
  return (resp: PaginatedResponse<any>): PaginatedResponse<T> => {
    const { results, ...rest } = resp;
    return {
      ...rest,
      results: (results || []).map(mapFn),
    };
  };
}
