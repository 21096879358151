// @flow

import React, { useEffect, useState } from 'react';
import {
  Button,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, OrgUser } from 'data/entities';
import {
  approveBdRequest,
  connectCrossbeamPartner,
  getCrossbeamPartnerManagers,
} from 'data/repositories/bdrequests';
import { updatePartnerSettings } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';
import PersonAvatar from 'components/PersonAvatar';

type Props = {
  loggedInOrguser: OrgUser,
  request: BdRequest,
  onUpdate: () => void,
  mini?: boolean,
};

const SetupCrossbeamConversation = ({
  loggedInOrguser: { capabilities },
  request,
  onUpdate,
  mini,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [enabledDirectConversations, setEnabledDirectConversations] = useState(
    request.partner.settings.conversationSettings.enabledDirectConversations
  );
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const toggle = () => setOpen((o) => !o);

  useEffect(() => {
    setLoading(true);
    if (!request.partner.isConnected && open)
      getCrossbeamPartnerManagers(request.partner.slug)
        .then((r) => {
          setRecipients(r.sort((a, b) => a.fullName.localeCompare(b.fullName)));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors(error.json);
        });
  }, [request, open]);

  const onMarkCompleted = (): void => {
    if (selectedRecipient && !request.partner.isConnected) {
      setSubmitting(true);
      setErrors({});
      connectCrossbeamPartner(request.partner.slug, selectedRecipient.id)
        .then(() => {
          // Auto-approve the request
          if (enabledDirectConversations) {
            approveBdRequest(request.id, true)
              .then(() => {
                setSubmitting(false);
                setErrors({});
                onUpdate();
                toggle();
              })
              .catch((error) => {
                setSubmitting(false);
                setErrors(error.json);
              });
          } else {
            setSubmitting(false);
            setErrors({});
            onUpdate();
            toggle();
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors(error.json);
        });

      // Update inbox settings.
      updatePartnerSettings(request.partner.slug, {
        ...request.partner.settings,
        conversationSettings: {
          ...request.partner.settings.conversationSettings,
          enabledDirectConversations,
        },
      });
    }
  };

  return (
    <>
      <div
        className={`${
          mini ? 'py-1 m-1' : 'py-3'
        } rounded px-3 bg-light-orange d-flex flex-row align-items-center gap-10`}
      >
        <FontAwesomeIcon icon="info-circle" className="orange" size={mini ? 'lg' : '2x'} />
        <div className="flex-fill d-flex flex-column gap-5">
          {mini ? (
            <span className="small-text bold gray-700">
              {capabilities.canManagePartnerships ? (
                <>
                  <b>SETUP</b>
                  {` ${request.partner?.name} as a partner your team can work with.`}
                </>
              ) : (
                <>
                  <b>WAITING SETUP</b>
                  {' from your partner manager.'}
                </>
              )}
            </span>
          ) : (
            <>
              <span className="large-text bold gray-700">
                Setup shared conversation with {request.partner.name}
              </span>
              <span className="normal-text semi-bold gray-600">
                {capabilities.canManagePartnerships
                  ? `You need to setup shared conversations with ${request.partner.name} to start working with
              them.`
                  : `Shared messages with ${request.partner.name} are not setup yet. Waiting for partner manager to set them up.`}
              </span>
            </>
          )}
        </div>
        {capabilities.canManagePartnerships && (
          <Button color="primary" size={mini ? 'xs' : 'md'} onClick={toggle}>
            Setup
          </Button>
        )}
      </div>
      <Modal
        isOpen={open}
        toggle={toggle}
        title={`Set Partner Manager from ${request.partner.name}`}
        footer={
          submitting ? (
            <LoadingRing maxWidth="30px" />
          ) : (
            <>
              <Button disabled={!selectedRecipient} color="primary" onClick={onMarkCompleted}>
                Confirm & Approve
              </Button>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </>
          )
        }
        centered
      >
        {loading ? (
          <LoadingRing maxWidth="40px" />
        ) : (
          <div className="d-flex flex-column gap-10">
            <div className="form-group">
              <UncontrolledButtonDropdown className="w-100">
                <DropdownToggle className="w-100 text-left">
                  {selectedRecipient ? (
                    <div className="d-flex flex-row align-items-center gap-5">
                      <PersonAvatar
                        avatar={selectedRecipient.pictureUrl}
                        firstName={selectedRecipient.firstName}
                        lastName={selectedRecipient.lastName}
                        size={20}
                      />
                      {selectedRecipient.fullName}
                    </div>
                  ) : (
                    'Select a partner manager'
                  )}
                </DropdownToggle>
                <DropdownMenu style={{ overflow: 'auto', maxHeight: 400 }}>
                  {recipients.map((recipient) => (
                    <DropdownItem
                      onClick={() => setSelectedRecipient(recipient)}
                      value={recipient}
                      key={recipient.id}
                      className="d-flex flex-row align-items-center gap-5"
                    >
                      <PersonAvatar
                        avatar={recipient.pictureUrl}
                        firstName={recipient.firstName}
                        lastName={recipient.lastName}
                        size={20}
                      />
                      {recipient.fullName}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <FieldErrors errors={errors && errors.email} />
            </div>
            <div className="form-group complete" id="disableOwnerGroup">
              <CustomInput
                id="enableShared"
                type="switch"
                label="Enable Direct Shared Conversations"
                onChange={(e) => setEnabledDirectConversations(e.target.checked)}
                checked={enabledDirectConversations}
              />
            </div>
            {enabledDirectConversations && (
              <div className="bg-light-blue normal-text bold gray-700 rounded px-3 py-2">
                {`The existing messages in this conversation will be shared with ${request.partner.name}.`}
              </div>
            )}
            <FieldErrors errors={errors && errors.nonFieldErrors} />
          </div>
        )}
      </Modal>
    </>
  );
};

SetupCrossbeamConversation.defaultProps = {
  mini: false,
};

export default withOrguser(SetupCrossbeamConversation);
