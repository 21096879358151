// @flow

import React from 'react';
import { isFinite, isNil } from 'lodash';

type Props = {
  label: string,
  extra: ?string | ?number,
  icon: ?React.Component,
  title: ?string,
  color?: string,
  className?: string,
};

/** Pill
 *
 * A small indicator.
 * @param {object} props Component props
 * @param {string} props.label - Pill label.
 * @param {string} [props.color] - Pill color.
 * @param {JSX.Element} [props.icon] - Icon to show.
 * @param {string|number} [props.extra] - Extra secondary text.
 * @param {string} [props.title] - Pill title.
 * @returns {JSX.Element} Pill component
 */
const Pill = ({ icon, label, extra, color, title, className }: Props) => (
  <div className={`pill pill-${color} ${className}`} title={title}>
    {!isNil(icon) && icon}
    {!isNil(label) && <span>{label}</span>}
    {/* If extra is a number, show circle around it. */}
    {!isNil(extra) && <span id={isFinite(extra) ? 'pill-circle' : 'pill-text'}>{extra}</span>}
  </div>
);

Pill.defaultProps = {
  color: 'gray',
  className: '',
};

export default Pill;
