// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import BaseOverlay from './BaseOverlay';

type Props = {
  loggedInOrguser: OrgUser,
  title: string,
  explanation: string,
  children: any,
};

const UpgradeOverlay = ({
  loggedInOrguser: { capabilities },
  title,
  explanation,
  children,
}: Props) => (
  <BaseOverlay
    title={title}
    explanation={explanation}
    condition={!capabilities.canAccessSeatedExperience}
    icon={<FontAwesomeIcon icon="lock" className="orange" size="2x" />}
  >
    {children}
  </BaseOverlay>
);

export default withOrguser(UpgradeOverlay);
