// @flow

import React from 'react';

import CompanyLogo from 'components/CompanyLogo';

const classes = 'rounded bg-gray-100';

const FakeComment = () => (
  <div className="loading-animation BdRequestComment  d-flex flex-row gap-10">
    <CompanyLogo size={36} name="" className="" />
    <div className="flex-fill d-flex flex-column gap-5">
      <div className="d-flex flex-row align-items-center gap-5">
        <div className={classes} style={{ width: 150, height: 16 }} />
        <div className={classes} style={{ width: 50, height: 14 }} />
      </div>
      <div className={classes} style={{ width: Math.max(Math.random() * 500, 300), height: 14 }} />
    </div>
  </div>
);

const CommentsLoading = () => (
  <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
    <div
      className="w-100 h-100"
      style={{
        position: 'absolute',
        zIndex: 1,
        background:
          'linear-gradient(360deg, #FFFFFF 2.14%, rgba(255, 255, 255, 0.497311) 85.37%, rgba(255, 255, 255, 0) 100%)',
      }}
    />
    <div className="w-100 d-flex flex-column gap-20">
      <div className="loading-animation p-2 d-flex flex-row justify-content-center">
        <div className={classes} style={{ width: 200, height: 16 }} />
      </div>
      {Array.from({ length: 5 }, (x, i) => i).map((index) => (
        <FakeComment key={index} />
      ))}
    </div>
  </div>
);
export default CommentsLoading;
