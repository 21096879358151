// @flow
import * as React from 'react';
import { map } from 'lodash';

import { attributionChoices, attributionDisplay } from 'data/entities/bdrequest';

const CHOICES = [
  attributionChoices.SOURCED,
  attributionChoices.INFLUENCED,
  attributionChoices.NO_ATTRIBUTION,
];

type Props = {
  value: ?string,
  onChange: (e: Event) => {},
};

function AttributionSelection({ value, onChange }: Props) {
  const currentValue = value || '';
  return (
    <select
      className="custom-select custom-select-md form-control full-width"
      name="attribution"
      value={currentValue}
      onChange={onChange}
    >
      <option value="">All</option>
      {map(CHOICES, (a) => (
        <option key={a} value={a}>
          {attributionDisplay[a]}
        </option>
      ))}
    </select>
  );
}

export default AttributionSelection;
