// @flow

import { Notification } from '@magicbell/magicbell-react';

export const MagicBellApiKey = process.env.REACT_APP_MAGICBELL_KEY;

export const BdRequestCommentMessageType = 'BD_REQUEST_COMMENT';

export const BdRequestCommentEvent = 'bdrequest_comment_notification';

export const dispatchBdRequestCommentEvent = (
  bdrequestId: string,
  commentId: string,
  notificationId: string,
  markAsRead: () => void
) => {
  const event = new CustomEvent(BdRequestCommentEvent, {
    detail: { bdrequestId, commentId, markAsRead, notificationId },
  });
  return document.dispatchEvent(event);
};

export const handleMagicBellNotificationEvent = (notification: Notification) => {
  const { customAttributes } = notification;
  if (customAttributes) {
    // Server sends snake case, but the library gives us camel case
    const { bdrequestId, commentId, messageKind, notificationId } = customAttributes;
    if (messageKind === BdRequestCommentMessageType) {
      return dispatchBdRequestCommentEvent(bdrequestId, commentId, notificationId, () =>
        notification.markAsRead()
      );
    }
  }
  return false;
};
