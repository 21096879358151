// @flow

import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { CrossbeamPartner, CrossbeamPopulation } from 'data/entities/crossbeam';
import { getStatusDefinition, statusDefinitions } from 'data/entities/status';
import useParams from 'utils/useParams';

import CompanyLogo from 'components/CompanyLogo';

type Props = {
  selectedPartners: CrossbeamPartner[],
  updatePartner: (partner: any) => void,
  back: () => void,
  next: () => void,
};

const FILTERED_POPULATIONS = ['Prospects', 'Open Opportunities', 'Customers'];

const DefinePopulations = ({ selectedPartners, updatePartner, back, next }: Props) => {
  const [{ partner: managePartnerId }] = useParams({});

  const canContinue = selectedPartners.every((partner) =>
    partner.populations.every((population) => !!population.status)
  );

  const updatePopulation = (
    partner: CrossbeamPartner,
    population: CrossbeamPopulation,
    newStatus: string
  ) =>
    updatePartner({
      ...partner,
      populations: partner.populations.map((oldPopulation) =>
        oldPopulation.id === population.id ? { ...population, status: newStatus } : oldPopulation
      ),
    });

  return (
    <>
      <div
        className="d-flex flex-column align-items-center gap-30 flex-1"
        style={{ width: '650px' }}
      >
        {(managePartnerId
          ? selectedPartners.filter(
              (p) => p.partner?.partnerOrg.pseudoOrgForPartnerId === managePartnerId
            )
          : selectedPartners
        ).map((partner) => {
          const filteredPopulations = partner.populations.filter(
            (population) => !FILTERED_POPULATIONS.includes(population.name)
          );
          return (
            <div key={partner.id} className="w-100 d-flex flex-column gap-15">
              <div className="d-flex flex-row align-items-center gap-10">
                <CompanyLogo
                  name={partner.name}
                  logoUrl={partner.logoUrl}
                  domain={partner.clearbitDomain}
                  size={28}
                  className=""
                />
                <a
                  href={`https://${partner.domain}`}
                  className="large-text bold gray-600 mr-2"
                  target="_blank"
                >
                  {partner.name}
                </a>
              </div>
              {filteredPopulations.length !== 0 ? (
                <div className="card card-body p-0">
                  <table className="custom-table">
                    <thead className="custom-table-header small-text bold gray-600">
                      <tr>
                        <td className="custom-table-cell">POPULATION NAME</td>
                        <td className="custom-table-cell text-nowrap" width="40%">
                          POPULATION ACCOUNTS STATUS
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPopulations.map((population, index) => {
                        const currentStatus = getStatusDefinition(population.status || '');
                        return (
                          <tr key={population.id} className="custom-table-row">
                            <td className="custom-table-cell large-text bold gray-700">
                              {population.name}
                            </td>
                            <td className="custom-table-cell">
                              <UncontrolledDropdown>
                                <DropdownToggle className="w-100 text-left">
                                  <div className="d-flex flex-row align-items-center">
                                    {currentStatus && (
                                      <FontAwesomeIcon
                                        className="mr-2"
                                        icon={currentStatus.icon}
                                        color={currentStatus.colorRaw}
                                      />
                                    )}
                                    <span className="flex-fill normal-text semi-bold gray-700">
                                      {currentStatus ? (
                                        currentStatus.label
                                      ) : (
                                        <>
                                          {population.status === 'UNKNOWN'
                                            ? 'Ignore'
                                            : 'Select a status'}
                                        </>
                                      )}
                                    </span>
                                    <FontAwesomeIcon icon="chevron-down" className="gray-500" />
                                  </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {Object.keys(statusDefinitions).map((key) => {
                                    const status = statusDefinitions[key];
                                    const active = status.key === population.status;
                                    return (
                                      <DropdownItem
                                        key={key}
                                        active={active}
                                        onClick={() =>
                                          updatePopulation(partner, population, status.key)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="mr-2"
                                          icon={status.icon}
                                          color={status.colorRaw}
                                        />
                                        {status.label}
                                      </DropdownItem>
                                    );
                                  })}
                                  <DropdownItem
                                    active={population.status === 'UNKNOWN'}
                                    onClick={() => updatePopulation(partner, population, 'UNKNOWN')}
                                  >
                                    Ignore
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="card card-body m-0 text-center normal-text semi-bold gray-500">
                  All population statuses have been configured for this partner.
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="crossbeam-footer text-center">
        <div
          className="d-flex flex-row align-items-center justify-content-end gap-20"
          style={{ width: '650px' }}
        >
          {!managePartnerId && (
            <div className="flex-fill d-flex">
              <button type="button" className="btn btn-secondary" onClick={back}>
                Go Back
              </button>
            </div>
          )}
          <button type="button" className="btn btn-primary" disabled={!canContinue} onClick={next}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default DefinePopulations;
