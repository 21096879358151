// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, Partner } from 'data/entities';

import CompanyLogo from 'components/CompanyLogo';

type Props = {
  org: Org,
  isShared: boolean,
  partner: Partner,
};

const ConversationVisibility = ({ org, isShared, partner }: Props) => {
  const response =
    !!org && isShared && partner ? (
      <>
        <CompanyLogo
          name={partner.name}
          domain={partner.domain}
          logoUrl={partner.partnerOrg.logoUrl}
          size={16}
          className="mr-1"
        />
        {partner.isConnected && partner.settings.conversationSettings.enabledDirectConversations ? (
          <>{partner?.name} is in this conversation.</>
        ) : (
          <>Once they join, {partner?.name} will see all messages posted.</>
        )}
      </>
    ) : (
      <>
        <FontAwesomeIcon icon="lock" className="mr-1" />
        This conversation is only visible to your organization.
      </>
    );
  return <div className="d-flex align-items-center mb-2 small-text gray-600">{response}</div>;
};

export default ConversationVisibility;
