// @flow

import React, { useState } from 'react';

import CompanyLogo from 'components/CompanyLogo';

const initialsToColor = (initials: string) => 'gray';
/*
const colors = ['gray', 'pink', 'yellow', 'cyan', 'blue', 'green', 'orange', 'red', 'purple'];
let hash = 0;
for (let i = 0; i < initials.length; i += 1) {
  hash += initials.charCodeAt(i);
}
const index = hash % colors.length;
return colors[index];
*/

type Props = {
  avatar: string,
  firstName: string,
  lastName: string,
  org: ?{
    name: string,
    domain: string,
    logoUrl: ?string,
  },
  size?: number,
  noMargin?: boolean,
};

const PersonAvatar = (props: Props) => {
  const { avatar, firstName, lastName, size, org, noMargin } = props;
  const [showDefault, setDefault] = useState(!avatar);
  const { domain, logoUrl, name } = org || { domain: '', logoUrl: null, name: '' };
  const initials =
    (firstName || '').charAt(0).toUpperCase() + (lastName || '').charAt(0).toUpperCase();

  const classes = `thumbnail-wrapper circular outline ${
    showDefault ? `avatar-${initialsToColor(initials)}` : 'b-white'
  }`;
  const style = { width: size, height: size };
  const minimumFontSize = 10;
  const fontSize = Math.max(Math.round(size * 0.4), minimumFontSize);

  const avatarComponent = showDefault ? (
    <div className={classes} style={style}>
      <div className="d-flex align-items-center justify-content-center">
        <span style={{ fontSize, lineHeight: 'normal', userSelect: 'none' }}>{initials}</span>
      </div>
    </div>
  ) : (
    <img
      alt=""
      src={avatar}
      height={size}
      width={size}
      className={classes}
      onError={() => setDefault(true)}
    />
  );

  const companyLogoSize = Math.round(size / 2.3);

  return org ? (
    <div
      className={noMargin ? '' : 'mr-3'}
      style={{
        minWidth: size,
        width: size,
        height: size,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div style={{ width: companyLogoSize, position: 'absolute', bottom: 0, right: 0 }}>
        <CompanyLogo
          name={name}
          logoUrl={logoUrl}
          domain={domain}
          size={companyLogoSize}
          className=""
        />
      </div>
      {avatarComponent}
    </div>
  ) : (
    avatarComponent
  );
};

PersonAvatar.defaultProps = {
  size: 32,
  noMargin: false,
};

export default PersonAvatar;
