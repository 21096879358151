// @flow

import * as React from 'react';

import { UserContext } from 'contexts';
import { OrgUserContext } from 'contexts/OrgUserContext';

import AvatarForm from '../AvatarForm';

const ProfileAvatarSettings = (props) => (
  <OrgUserContext.Consumer>
    {({ orguser }) => (
      <UserContext.Consumer>
        {({ user, updateUser }) => (
          <AvatarForm updateUser={updateUser} user={user} orguser={orguser} />
        )}
      </UserContext.Consumer>
    )}
  </OrgUserContext.Consumer>
);

export default ProfileAvatarSettings;
