// @flow

import * as React from 'react';

import { Partner, SuggestedAccountMap } from 'data/entities';
import {
  approveAccountMapping,
  fetchUnapprovedAccountPartners,
} from 'data/repositories/accountMapping';
import { FadeInTransition } from 'utils/animate';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';

import AccountMappingTable from 'views/AccountMapping/AccountMappingTable';
import MappingTableHeader from 'views/AccountMapping/components/MappingTableHeader';
import LoadingRing from 'components/LoadingRing';

type Props = {
  partner: Partner,
  results: SuggestedAccountMap[],
  nextPartnerApproved: () => Promise,
  onApprove: () => Promise,
  onUpdate: () => () => void,
  next: ?string,
  onDecline: () => void,
  onChangeView: (view: string) => void,
  loading: boolean,
};

function UnmappedAccountPartnersComponent(props: Props) {
  const {
    results,
    nextPartnerApproved,
    onApprove,
    onUpdate,
    next,
    onDecline,
    onChangeView,
    partner,
    loading,
  } = props;
  const title = 'Unapproved Accounts';
  const subtitle = (
    <>
      Accounts you still need to map with <strong>{partner.name}</strong>
    </>
  );
  const hasResults = Boolean(results && results.length);
  return (
    <div className="UnmappedAccountPartners card card-condensed">
      <MappingTableHeader title={title} subtitle={subtitle} onChangeView={onChangeView} />
      <AccountMappingTable
        predictions={results}
        hasNext={Boolean(next) && Boolean(nextPartnerApproved)}
        nextAction={nextPartnerApproved}
        onApprove={onApprove}
        onUpdate={onUpdate}
        onDecline={onDecline}
        partner={partner}
        isRequests
      />
      <FadeInTransition in={loading}>
        <LoadingRing maxWidth="50px" className="m-3" />
      </FadeInTransition>
      <FadeInTransition in={!loading && !hasResults}>
        <h5 className="text-center">Nothing left to approve</h5>
      </FadeInTransition>
    </div>
  );
}

type ContainerProps = {
  partnerSlug: string,
  accountSlug: string,
  partner: Partner,
  onChangeView: (view: string) => void,
};

type ContainerState = {
  // apiResponse is a source of truth as we paginate and rebuild data structures
  apiResponse: ?PaginatedResponse<SuggestedAccountMap>,
  loading: boolean,
  count: ?number,
};

class UnmappedAccountPartners extends React.Component<ContainerProps, ContainerState> {
  state: State = {
    loading: true,
    apiResponse: null,
    count: null,
  };

  componentDidMount() {
    this.loadUnapproved();
  }

  componentDidUpdate(prevProps) {
    const { partnerSlug, accountSlug } = this.props;
    if (prevProps.partnerSlug !== partnerSlug || prevProps.accountSlug !== accountSlug) {
      this.loadUnapproved();
    }
  }

  onApprove = (
    { account: { slug: acctSlug } }: SuggestedAccountMap,
    partnerAccountId: number,
    asTarget: boolean = false
  ) =>
    approveAccountMapping(acctSlug, partnerAccountId, asTarget).then(() =>
      // Filter from results
      this.removeFromApiResponse(acctSlug)
    );

  onDecline = (accountSlug: string) => this.removeFromApiResponse(accountSlug);

  nextPartnerApproved = (): Promise => {
    const { apiResponse } = this.state;
    const next = apiResponse ? apiResponse.next : null;
    if (!next) {
      return Promise.resolve(null);
    }
    return this.loadUnapproved(next);
  };

  onUpdate = () =>
    // FIXME This is trash. We should be handling the specific action - like deleting.
    this.loadUnapproved();

  removeFromApiResponse(accountSlug: string) {
    this.setState(({ count, apiResponse: { results, ...apiRest }, ...res }) => ({
      ...res,
      count: count - 1,
      apiResponse: {
        ...apiRest,
        results: results.filter((r) => r.account.slug !== accountSlug),
      },
    }));
  }

  loadUnapproved(nextUrl: ?string) {
    const { partnerSlug, accountSlug } = this.props;
    if (!partnerSlug) {
      return Promise.resolve(null);
    }
    return fetchUnapprovedAccountPartners(partnerSlug, accountSlug, nextUrl).then(
      ({ next, previous, results, count }) => {
        // Transform the results into Paginated SuggestAccountMap[]
        const newResults = results.map(SuggestedAccountMap.fromPartnerApprovedMap);
        const { apiResponse: prevApiResponse } = this.state;
        const prevResults = prevApiResponse && prevApiResponse.results;
        // If this call was to fetch next result set, then we append.
        const apiResults = nextUrl && prevResults ? prevResults.concat(newResults) : newResults;
        const apiResponse = { next, previous, results: apiResults, count };
        this.setState({ apiResponse, loading: false, count });
        return apiResults;
      }
    );
  }

  render() {
    const { onChangeView, partner } = this.props;
    const { loading, apiResponse, count } = this.state;
    const { results, next } = apiResponse || {};
    return (
      <div>
        <UnmappedAccountPartnersComponent
          results={results}
          count={count}
          next={next}
          nextPartnerApproved={this.nextPartnerApproved}
          onApprove={this.onApprove}
          onUpdate={this.onUpdate}
          onDecline={this.onDecline}
          onChangeView={onChangeView}
          partner={partner}
          loading={loading}
        />
      </div>
    );
  }
}

UnmappedAccountPartners.defaultProps = {};

export default UnmappedAccountPartners;
