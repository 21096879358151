// @flow

import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isNil } from 'lodash';
import queryString from 'qs';

import { Account, OrgUser, Partner } from 'data/entities';
import Org from 'data/entities/org';
import { OrgUserRoles } from 'data/entities/orguser';
import type { PartnerApiSettings } from 'data/repositories/partners';
import {
  defaultAllOnPartnerApiSettings,
  fetchPartnerBySlug,
  updatePartnerSettings,
} from 'data/repositories/partners';
import type { LocationT, RouterHistoryT } from 'sharedTypes/reactRouter';

import PartnerSettings from 'views/AccountMapping/PartnerSettings';
import AccountCard from 'views/DealNavigator/AccountCard';
import CompanyLogo from 'components/CompanyLogo';
import NotEnabledBadge from 'components/NotEnabledBadge';
import PageHeadText from 'components/PageHeadText';
import SalesCollateralLinkList from 'components/SalesCollateralLinkList/SalesCollateralLinkList';
import { UpgradeBanner, UpgradeOverlay } from 'components/SeatlessExperience';
import FinishSetupOverlay from 'components/SeatlessExperience/FinishSetupOverlay';

import PartnerAccounts from './PartnerAccounts';
import PartnerDescriptionAndModal from './PartnerDescriptionAndModal';
import PseudoModal from './PseudoModal';
import SetBdManager from './SetBdManager';
import Suggestions from './Suggestions';

type Props = {
  orguser: OrgUser,
  org: Org,
  partner: Partner,
  refreshPartner: () => void,
  location: LocationT,
  history: RouterHistoryT,
};

function PartnerDetail(props: Props) {
  const {
    partner,
    orguser,
    org,
    refreshPartner,
    location,
    history,
    match: { url },
  } = props;
  const {
    bdManager,
    name,
    settings,
    slug,
    isConnected,
    isSynced,
    bdManagerAtPartner,
    domain,
    partnerOrg,
    salesCollateralLinks,
  } = partner;
  const searchRaw = location ? location.search : {};
  const query = queryString.parse(searchRaw, { ignoreQueryPrefix: true });
  const canManage = !!(orguser && orguser.canManage);
  const onUpdateSettings = () => {
    const partnerAPISettings: PartnerApiSettings = defaultAllOnPartnerApiSettings();
    return updatePartnerSettings(partner.slug, partnerAPISettings).then(refreshPartner);
  };

  return (
    <div className="PartnerDetail" style={{ margin: '10px auto 40px auto' }}>
      <div className="px-4 pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center gap-20">
            <CompanyLogo
              size={84}
              logoUrl={partnerOrg?.logoUrl}
              name={name}
              domain={domain}
              className=""
            />
            <div className="d-flex flex-column gap-10">
              <div className="d-flex align-items-center gap-10">
                <h3 className="m-0 bold gray-700 fs-mask">{name}</h3>
                {!isSynced && <NotEnabledBadge />}
              </div>
              <div className="d-flex flex-row gap-10 flex-wrap">
                <SetBdManager bdManager={bdManager} partner={partner} onUpdate={refreshPartner} />
                {!!bdManagerAtPartner && (
                  <>
                    {' • '}
                    <span className="normal-text semi-bold gray-500">
                      Manager at Partner:{' '}
                      <span className="gray-700 fs-mask">{bdManagerAtPartner.fullName}</span>
                    </span>
                  </>
                )}
              </div>
              <div>
                <FontAwesomeIcon icon="inbox" className="mr-1" />
                <Link
                  className="normal-text"
                  to={{ pathname: '/requests/all', search: `?partner=${slug}` }}
                >
                  View Requests for Partner
                </Link>
              </div>
            </div>
          </div>
          <PartnerSettings
            partner={partner}
            org={org}
            refreshPartner={refreshPartner}
            orguser={orguser}
          />
        </div>
        {!isNil(salesCollateralLinks) && (
          <SalesCollateralLinkList
            canManage={orguser.capabilities.canManagePartnerships}
            links={salesCollateralLinks || []}
            partnerSlug={partner.slug}
            afterSubmit={refreshPartner}
          />
        )}
        <PartnerDescriptionAndModal partner={partner} />
      </div>

      {!orguser.capabilities.canAccessSeatedExperience || !org.isIntegrationSetup ? (
        <div className="d-flex flex-column my-3 mx-auto gap-20" style={{ maxWidth: 900 }}>
          <UpgradeBanner />
          <FinishSetupOverlay title="Map your partner populations to see overlaps">
            <UpgradeOverlay
              title={`Start collaborating with ${partner.name}`}
              explanation={`Work with ${partner.name} to close more deals faster.`}
            >
              <AccountCard
                isLoaded
                style={{}}
                account={Account.fromApi({
                  name: 'Pied Piper',
                  display_deal_amount: '$700,000',
                  status: 'COLD',
                  accountowners: [{ first_name: 'Richard', last_name: 'Hendricks' }],
                })}
              />
            </UpgradeOverlay>
          </FinishSetupOverlay>
        </div>
      ) : (
        <>
          {canManage && <Suggestions partner={partner} />}
          {isConnected && !settings.enableAccountMapping ? (
            <PseudoModal
              canManage={canManage}
              onClick={onUpdateSettings}
              bdManager={bdManager}
              partner={partner}
              org={org}
            />
          ) : (
            <PartnerAccounts
              partner={partner}
              orguser={orguser}
              query={query}
              url={url}
              history={history}
            />
          )}
        </>
      )}
    </div>
  );
}

type ContainerProps = {
  org: Org,
  orguser: OrgUser,
  match: {
    params: {
      partnerSlug: string,
    },
  },
  location: LocationT,
};

type State = {
  partner: Partner,
  isLoading: boolean,
  redirected: boolean,
};

class PartnerDetailContainer extends React.Component<ContainerProps, State> {
  state: State = { isLoading: true, partner: null, redirected: false };

  async componentDidMount() {
    await Promise.all([this.loadPartner()]);
  }

  componentDidUpdate(prevProps: ContainerProps) {
    const getPartnerSlug = (props) => get(props, 'match.params.partnerSlug');
    if (getPartnerSlug(this.props) !== getPartnerSlug(prevProps)) {
      this.loadPartner();
    }
  }

  loadPartner = async () => {
    const {
      match: {
        params: { partnerSlug },
      },
    } = this.props;
    fetchPartnerBySlug(partnerSlug)
      .then((partner) => {
        this.setState({ isLoading: false, partner });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, partner, redirected } = this.state;

    // Redirect to filtering by own accounts
    if (!redirected && this.props.location.search === '') {
      this.setState({ redirected: true });
      return (
        <Redirect
          to={`${this.props.location.pathname}?accountOwner=${
            this.props.orguser.role !== OrgUserRoles.BD ? this.props.orguser.id : 'all'
          }`}
        />
      );
    }

    if (isLoading) {
      return null;
    }

    if (partner && partner.name) {
      return (
        <>
          <PageHeadText>
            <Link to="/p">Partners</Link>
          </PageHeadText>
          <PartnerDetail {...this.props} partner={partner} refreshPartner={this.loadPartner} />
        </>
      );
    }
    return <h4 className="text-center">Partner Not Found</h4>;
  }
}

export default PartnerDetailContainer;
