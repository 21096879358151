// @flow

import * as React from 'react';

import { Partner, SuggestedAccountMap } from 'data/entities';
import { fetchPendingRequests } from 'data/repositories/accountMapping';
import { FadeInTransition } from 'utils/animate';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';

import LoadingRing from 'components/LoadingRing';

import MappingTableHeader from './components/MappingTableHeader';
import AccountMappingTable from './AccountMappingTable';

type Props = {
  rows: ?PaginatedResponse<SuggestedAccountMap>,
  nextRows: () => void,
  onChangeView: (viewName: string) => void,
  onUpdate: () => void,
  partner: Partner,
  loading: boolean,
};

function PendingRequests({
  rows,
  nextRows,
  onApprove,
  onChangeView,
  onUpdate,
  partner,
  loading,
}: Props) {
  const title = 'Pending Partner Approval';
  const subtitle = (
    <>
      Accounts you have approved. Pending mapping approval by <strong>{partner.name}</strong>
    </>
  );

  return (
    <div className="card card-condensed PartnerAccountMapping">
      <MappingTableHeader title={title} subtitle={subtitle} onChangeView={onChangeView} />
      <AccountMappingTable
        predictions={rows ? rows.results : []}
        hasNext={Boolean(rows ? rows.next : null)}
        nextAction={nextRows}
        onApprove={onApprove}
        onUpdate={onUpdate}
        partner={partner}
        isPending
      />
      <FadeInTransition in={loading}>
        <LoadingRing maxWidth="50px" className="m-3" />
      </FadeInTransition>
      <FadeInTransition in={!loading && !(rows && rows.results && rows.results.length)}>
        <h5 className="text-center">No pending approvals for partners</h5>
      </FadeInTransition>
    </div>
  );
}

type ContainerProps = {
  partnerSlug: string,
  accountSlug: string,
  onChangeView: () => void,
  partner: Partner,
};

type ContainerState = {
  rows: ?PaginatedResponse<SuggestedAccountMap>,
  loading: boolean,
};

class PendingRequestsContainer extends React.Component<ContainerProps, ContainerState> {
  state: ContainerState = {
    rows: null,
    loading: true,
  };

  componentDidMount() {
    const { partnerSlug } = this.props;
    if (partnerSlug) {
      this.loadPendingRequests();
    }
  }

  componentDidUpdate(prevProps) {
    const { partnerSlug, accountSlug } = this.props;
    if (prevProps.partnerSlug !== partnerSlug || prevProps.accountSlug !== accountSlug) {
      this.loadPendingRequests();
    }
  }

  nextPending = () => {
    const { rows } = this.state;
    const next = rows ? rows.next : null;
    if (!next) {
      return;
    }
    this.fetchPendingRequests(next)
      .then((res) => {
        this.setState((prev) => ({
          loading: false,
          rows: { ...res, results: prev.rows.results.concat(res.results) },
        }));
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  onUpdate = () => {
    // FIXME This is bad and you should feel bad
    // FIXME You can't just freely refresh the requests on update when there is pagination/ "load more" involved
    this.loadPendingRequests();
  };

  loadPendingRequests() {
    this.fetchPendingRequests()
      .then((res) => {
        this.setState({ loading: false, rows: res });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  fetchPendingRequests(nextUrl: ?string) {
    const { partnerSlug, accountSlug } = this.props;
    return fetchPendingRequests(partnerSlug, accountSlug, nextUrl).then(({ results, ...res }) => ({
      ...res,
      results: results.map(SuggestedAccountMap.fromPendingPartnerApprovalMap),
    }));
  }

  render() {
    const { partner, onChangeView } = this.props;
    const { rows, loading } = this.state;
    return (
      <PendingRequests
        rows={rows}
        nextRows={this.nextPending}
        onChangeView={onChangeView}
        onUpdate={this.onUpdate}
        partner={partner}
        loading={loading}
      />
    );
  }
}

export default PendingRequestsContainer;
