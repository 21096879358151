// @flow

import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Save scroll position for each component in session state.
const state = {};
export const saveState = (component: string, value: { window: number, container: number }) => {
  state[component] = value;
};
export const getState = (component: string) => state[component];

const useScrollRestore = (componentId: string) => {
  const history = useHistory();

  // Restore scroll position. If checkIfGoingBack is true, it will only restore if the last action was a POP.
  const restore = useCallback(
    (checkIfGoingBack?: boolean) => {
      const lastAction = history.action;
      if (getState(componentId) && (!checkIfGoingBack || lastAction === 'POP')) {
        const scrollY = getState(componentId);

        setTimeout(() => {
          window.scrollTo(0, scrollY.window);
          const pageContainer = document.getElementById('page-container');
          if (pageContainer) pageContainer.scrollTo(0, scrollY.container);
        }, 1);
      }
    },
    [componentId, history.action]
  );

  useEffect(() => {
    // Save scroll position on navigation.
    const unblock = history.block((location, action) => {
      saveState(componentId, {
        window: window.scrollY,
        container: document.getElementById('page-container')?.scrollTop ?? 0,
      });
      unblock();
      return true;
    });
    return () => unblock();
  }, [history, componentId]);

  return restore;
};

export default useScrollRestore;
