// @flow

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Org, OrgUser } from 'data/entities';
import { statusChoiceList, statusToUrlValue } from 'data/entities/bdrequest';

import BdRequestDetail from './BdRequestDetail';
import BdRequestList from './BdRequestList';
import NewBdRequestActivity from './NewBdRequest';
import NewQuestionBdRequest from './NewQuestionBdRequest';

type Props = {
  orguser: OrgUser,
  org: Org,
};

export default function BdRequests({ orguser, org }: Props) {
  const validUrlValues = statusChoiceList.map(statusToUrlValue); // List of valid values for status in URL
  return (
    <Switch>
      <Route
        path="/requests/log-activity"
        render={(props) => <NewBdRequestActivity {...props} showLogActivity />}
        exact
      />
      <Route
        path="/requests/new"
        component={org.settings.requestsWorkflow ? NewQuestionBdRequest : NewQuestionBdRequest}
        exact
      />
      <Route path={`/requests/(${validUrlValues.join('|')})`} component={BdRequestList} exact />
      <Route path="/requests/all" component={BdRequestList} exact />
      <Route
        path="/requests/:requestId"
        render={(props) => <BdRequestDetail {...props} orguser={orguser} org={org} />}
        exact
      />
    </Switch>
  );
}
