// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OrgUser from 'data/entities/orguser';

type Props = {
  loggedInOrguser: OrgUser,
  toolTipIdSuffix: string,
};

const SlackChannelMissing = ({ loggedInOrguser, toolTipIdSuffix }: Props): React.Node => {
  const ctaDisabled = !loggedInOrguser.canManage;
  const disablingStyle = ctaDisabled ? { pointerEvents: 'none' } : {};
  const btnClass = ctaDisabled ? 'disabled' : 'btn-secondary';
  const toolTipId = `updateCTA-${toolTipIdSuffix}`;

  return (
    <div className="d-flex align-items-center justify-content-between p-2 rounded gray-700 bg-light-orange">
      <div className="ml-2">
        <FontAwesomeIcon
          icon="triangle-exclamation"
          width={16}
          height={16}
          className="orange mr-2"
        />
        Slack channel associated with this partner has been deleted
      </div>
      {ctaDisabled && (
        <UncontrolledTooltip trigger="hover" placement="top" target={toolTipId}>
          Ask an admin to update Slack Channel for this partner
        </UncontrolledTooltip>
      )}
      <span id={toolTipId}>
        <Link
          style={disablingStyle}
          to="/slack-channels"
          className={`btn ${btnClass} gray-700 text-nowrap ml-2`}
        >
          Update
        </Link>
      </span>
    </div>
  );
};

export default SlackChannelMissing;
