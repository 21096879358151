// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash';

import { AccountSignals, ContactSignals, getSignal } from 'data/entities/signal';

import Pill from './Pill';

type Props = {
  signals: ?(AccountSignals | ContactSignals),
  compact?: boolean,
  includeCount?: boolean,
};

const Signals = ({ signals, compact, includeCount }: Props) => {
  if (!signals || Object.values(signals).every((s) => !s)) {
    return null;
  }

  return (
    <div className="d-flex flex-row align-items-center gap-5">
      {Object.keys(signals).map((key: string) => {
        const signal = getSignal(key);
        const value = signals[key];
        const hasSignal = !isNil(value) && value > 0;
        return (
          hasSignal && (
            <Pill
              key={key}
              label={compact ? undefined : signal.label}
              title={signal.label}
              color={signal.color}
              extra={includeCount ? value : undefined}
              icon={<FontAwesomeIcon icon={signal.icon} />}
            />
          )
        );
      })}
    </div>
  );
};

Signals.defaultProps = {
  compact: false,
  includeCount: false,
};

export default Signals;
