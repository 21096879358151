// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account, Opportunity } from 'data/entities';
import { INTEGRATION_LABELS_MAP } from 'data/entities/chug';

type Props = {
  entity: Account | Opportunity,
  text?: ?string,
};

const CrmEntityUrl = ({ entity, text }: Props) => {
  if (!entity || !entity.crmUrl) {
    return null;
  }
  const { crmUrl, provider } = entity;
  const linkText = text || INTEGRATION_LABELS_MAP[provider];

  return (
    <a href={crmUrl} target="_blank" className="normal-text bold primary">
      <FontAwesomeIcon icon="external-link-alt" className="mr-1" /> {linkText}
    </a>
  );
};

CrmEntityUrl.defaultProps = {
  text: null,
};

export default CrmEntityUrl;
