// @flow

import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import LogoCrossbeam from 'components/LogoCrossbeam';

type DropdownItemProps = {
  title: string,
  description: string,
  icon: any,
  onClick?: () => void,
};

const DropdownItem = ({ title, description, icon, onClick }: DropdownItemProps) => (
  <div
    tabIndex={0}
    role="button"
    className="header-menu-item d-flex flex-row gap-10 align-items-center my-1 p-1"
    onClick={onClick}
    onKeyPress={onClick}
  >
    <button
      type="button"
      className="btn btn-secondary pointer-events-none p-0"
      style={{ width: 40, height: 40 }}
    >
      {icon}
    </button>
    <div className="d-flex flex-column gap-5">
      <span className="normal-text bold gray-700 text-nowrap">{title}</span>
      <span className="small-text semi-bold gray-400 text-nowrap">{description}</span>
    </div>
  </div>
);

DropdownItem.defaultProps = {
  onClick: () => {},
};

type AppSwitcherProps = {
  loggedInOrg: Org,
};

const AppSwitcher = ({ loggedInOrg }: AppSwitcherProps) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((o) => !o);
  };
  if (!loggedInOrg) {
    return null;
  }

  return (
    <Dropdown className="pull-right" isOpen={open} toggle={toggle}>
      <DropdownToggle className="p-1" color="secondary">
        <LogoCrossbeam text={false} style={{ width: 24 }} />
      </DropdownToggle>
      <DropdownMenu className="profile-dropdown pb-2" right style={{ width: 250 }}>
        <span className="normal-text disable-selection semi-bold black">Navigate to</span>
        <hr className="my-1 border-white" />
        <a
          href={`${process.env.REACT_APP_CROSSBEAM_URL}/switch-org?organization_id=${loggedInOrg.crossbeamOrgId}`}
        >
          <DropdownItem
            title="Crossbeam"
            description={(process.env.REACT_APP_CROSSBEAM_URL || '').replace('https://', '')}
            icon={<LogoCrossbeam text={false} style={{ width: 24 }} />}
          />
        </a>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withOrguser(AppSwitcher);
