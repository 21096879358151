/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */
// @flow

import { OrgUserNameOnly } from './orguser';

export class SlackChannel {
  id: string;

  name: string;

  channelId: string;

  isDeleted: boolean;

  isExtShared: boolean;

  isPendingExtShared: boolean;

  isPrivate: boolean;

  isDeleted: boolean;

  static fromApi = (d: any): SlackChannel => {
    const sc = new SlackChannel();

    sc.id = d.id;
    sc.name = d.name;
    sc.channelId = d.channel_id;
    sc.isDeleted = d.is_deleted;
    sc.isExtShared = d.is_ext_shared;
    sc.isPendingExtShared = d.is_pending_ext_shared;
    sc.isPrivate = d.is_private;
    sc.isDeleted = d.is_deleted;

    return sc;
  };
}

export class CoSellingSlackConfig {
  id: string;

  slackChannel: ?SlackChannel;

  requestsWeeklyGoal: number;

  excludedOrgusers: OrgUserNameOnly[];

  createdAt: Date;

  updatedAt: Date;

  lastSentAt: ?Date;

  static fromApi = (d: any): CoSellingSlackConfig => {
    const c = new CoSellingSlackConfig();

    c.id = d.id;
    c.slackChannel = d.slack_channel && SlackChannel.fromApi(d.slack_channel);
    c.requestsWeeklyGoal = d.requests_weekly_goal;
    c.excludedOrgusers = d.excluded_orgusers.map((o: any) => OrgUserNameOnly.fromApi(o));
    c.createdAt = d.created_at && new Date(d.created_at);
    c.updatedAt = d.updated_at && new Date(d.updated_at);
    c.lastSentAt = d.last_sent_at && new Date(d.last_sent_at);

    return c;
  };
}
