// @flow
import { OrgUserNameOnly } from './orguser';
import { Partner } from './partner';

// eslint-disable-next-line import/prefer-default-export
export class BdRequestQuestion {
  id: string;

  text: string;

  createdBy: OrgUserNameOnly;

  editedBy: ?OrgUserNameOnly;

  createdAt: Date;

  updatedAt: Date;

  isForAllPartners: boolean;

  isDefault: boolean;

  forPartners: Array<Partner>;

  static fromApi = (data: any): BdRequestQuestion => {
    const q = new BdRequestQuestion();
    q.id = data.id;
    q.text = data.text;
    q.createdBy = data.created_by && OrgUserNameOnly.fromApi(data.created_by);
    q.editedBy = data.edited_by && OrgUserNameOnly.fromApi(data.edited_by);
    q.createdAt = data.created_at && new Date(data.created_at);
    q.updatedAt = data.updated_at && new Date(data.updated_at);
    q.isForAllPartners = data.is_for_all_partners;
    q.isDefault = data.is_default;
    q.forPartners = data.for_partners && data.for_partners.map(Partner.fromApi);
    return q;
  };
}
