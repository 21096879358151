// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import { debounce } from 'lodash';

import { Org, OrgUser, Partner } from 'data/entities';
import { ExclusivePartnerFilters } from 'data/entities/partner';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

import PaginationButtons from 'components/PaginationButtons';
import { UpgradeBanner, UpgradeOverlay } from 'components/SeatlessExperience';
import FinishSetupOverlay from 'components/SeatlessExperience/FinishSetupOverlay';
import withPartners from 'components/withPartners';

import Loading from './Loading';
import PartnerCard from './PartnerCard';

type Props = {
  partners: Partner[],
  isLoading: boolean,
  onUpdate: () => {},
  onNextPartners: () => {},
  onPreviousPartners: () => {},
  onFilterPartners: (status: ?string, search: ?string) => void,
  orguser: OrgUser,
  loggedInOrg: Org,
};

function PartnerList({
  partners,
  onNextPartners,
  onPreviousPartners,
  onFilterPartners,
  isLoading,
  onUpdate,
  orguser,
  loggedInOrg,
}: Props) {
  const [{ status, search }, setParams] = useParams({ status: null, search: null });

  const onSearch = debounce((value: string) => setParams({ status, search: value }), 500);

  React.useEffect(() => {
    onFilterPartners(status, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, search]);

  const hasAccess =
    orguser.capabilities.canAccessSeatedExperience && loggedInOrg.isIntegrationSetup;

  return (
    <div className="w-100 d-flex flex-column gap-30">
      <div className="d-flex flex-md-row flex-column justify-content-center gap-10">
        <div className="d-flex flex-row flex-fill justify-content-center gap-10">
          <h3 className="m-0 flex-fill">
            {status === ExclusivePartnerFilters.SYNC_ENABLED
              ? 'Enabled Partners'
              : 'Not Enabled Partners'}
          </h3>
          {hasAccess && (
            <Input
              style={{ maxWidth: '350px' }}
              type="text"
              name="search"
              placeholder="🔎  Search partners by name"
              defaultValue={search}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          {hasAccess && orguser.canManage && (
            <Link to="/crossbeam">
              <button className="btn btn-primary" type="button">
                Manage Partners
              </button>
            </Link>
          )}
        </div>
      </div>
      <UpgradeBanner />
      {status === ExclusivePartnerFilters.SYNC_DISABLED && (
        <div className="px-3 py-2 bg-light-blue rounded d-flex flex-column gap-5">
          <span className="large-text bold gray-700">
            You are seeing partners that have messaged you but their data is not enabled on
            Crossbeam for Sales.
          </span>
          {orguser.canManage && (
            <span className="normal-text semi-bold gray-600">
              <Link to="/crossbeam" className="bold blue">
                Manage Partners
              </Link>{' '}
              that your team should see data from.
            </span>
          )}
        </div>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!partners || partners.length < 1 ? (
            <div className="text-center">
              <h4>No partners found!</h4>
            </div>
          ) : (
            <>
              <div className="d-flex flex-column align-items-center">
                <div className="w-100 row">
                  {partners &&
                    partners.map((p) => (
                      <div className="col-md-6" key={p.slug}>
                        <PartnerCard canInvite={orguser && orguser.canManage} partner={p} />
                      </div>
                    ))}
                </div>
                <PaginationButtons onPrevious={onPreviousPartners} onNext={onNextPartners} />
              </div>
              <UpgradeOverlay
                title="Get Crossbeam Crossbeam for Sales to see all your partners"
                explanation="You are seeing only the partners that messaged you."
              />
              <FinishSetupOverlay title="Choose partners your team can co-sell with" />
            </>
          )}
        </>
      )}
    </div>
  );
}

const mutualOnly = false;

export default withPartners(withOrguser(PartnerList), mutualOnly);
