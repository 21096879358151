// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { deleteList } from 'data/repositories/lists';

import Modal from 'components/Modal';

type Props = {
  open: boolean,
  toggle: () => void,
  listId: string,
};

const DeleteList = ({ open, toggle, listId }: Props) => {
  const history = useHistory();

  const doDelete = () =>
    deleteList(listId).then(() => {
      history.push('/lists');
    });

  return (
    <>
      <Modal
        isOpen={open}
        toggle={toggle}
        title={<span className="red">Delete List</span>}
        footer={
          <>
            <Button color="danger" onClick={doDelete}>
              Yes, DELETE
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </>
        }
        centered
      >
        <span className="semi-bold">Are you sure you want to delete this list?</span>
        <span className="bold">You cannot undo this action.</span>
      </Modal>
    </>
  );
};

export default DeleteList;
