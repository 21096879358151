// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';

import { Org } from 'data/entities';
import completeSignup from 'data/repositories/signup';

import CompanyLogo from 'components/CompanyLogo';
import LogoCrossbeam from 'components/LogoCrossbeam';
import CompanyDescription from 'components/SignUp/CompanyDescription';

type Props = {
  org: Org,
  updateOrg: (org: Org) => void,
};

type State = {
  submitting: boolean,
  error: ?boolean,
  errors: any,
  description: string,
};

class SignupForm extends React.Component<Props, State> {
  state: State = {
    submitting: false,
    error: null,
    description: '',
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.submitting === true) {
      return;
    }
    this.setState({ submitting: true, errors: null }, this.submitDetails);
  };

  submitDetails = () => {
    const { updateOrg } = this.props;
    const { description } = this.state;
    return completeSignup(description)
      .then(({ org: newOrg, orguser }) => {
        updateOrg(newOrg, orguser, '/onboarding?skip=true');
      })
      .catch((error) => {
        if (error.json && (error.json.url || error.json.name)) {
          this.setState({ submitting: false, errors: { domain: error.json.url, ...error.json } });
        } else {
          this.setState({ submitting: false, error: true, errors: error });
        }
      });
  };

  onDescriptionChange = (event) => {
    const description = event.target.value || '';
    const stateUpdate = { description };
    return this.setState(stateUpdate);
  };

  render() {
    const { submitting, errors, error, description } = this.state;
    const { existingOrgMustCompleteSignup, org, orguser } = this.props;
    if (org && !existingOrgMustCompleteSignup(orguser, org)) {
      // They've already completed signup -- make them check details and go on to onboarding!
      return <Redirect to="/complete-signup/details" />;
    }
    if (error === true) {
      if (errors && errors.json && errors.json.detail === 'Already setup') {
        // User tried to save org, but it is already setup
        // Forward to detail page
        return <Redirect to="/complete-signup/details" />;
      }
      // Server returned expected error, express contact page
      return (
        <div>
          <h4>Failed to complete signup.</h4>
        </div>
      );
    }

    return (
      <FormGroup>
        <Form onSubmit={this.onSubmit} style={{ width: 400 }}>
          <br />
          <span className="normal-text semi-bold gray-600" style={{ lineHeight: 2 }}>
            Company
          </span>
          <div className="d-flex flex-row align-items-center gap-10">
            <CompanyLogo
              name={org.name}
              domain={org.url}
              logoUrl={org.logoUrl}
              size={28}
              className=""
            />
            <span className="normal-text semi-bold gray-600">{org.name}</span>
          </div>
          <br />
          <CompanyDescription
            onChange={this.onDescriptionChange}
            description={description}
            errors={errors}
          />
          <Button
            type="submit"
            name="save"
            className="btn btn-primary w-100 text-white bg-crossbeam-blue px-5 mt-3"
            disabled={submitting}
          >
            <LogoCrossbeam text={false} className="mr-2" height={18} width={18} />
            Enable Crossbeam for Sales
          </Button>
        </Form>
      </FormGroup>
    );
  }
}

export default SignupForm;
