// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PersonAvatar from 'components/PersonAvatar';

import InviteUsers from './InviteUsers';

type Props = {
  firstName: string,
  lastName: string,
  avatarImage: string,
  email: string,
  overlapsCount?: ?number,
};

const SuggestionCard = ({ firstName, lastName, avatarImage, email, overlapsCount }: Props) => (
  <div
    className="card card-body m-0 d-flex flex-row align-items-center gap-10"
    style={{ maxWidth: '400px', width: '400px' }}
  >
    <PersonAvatar
      firstName={firstName}
      lastName={lastName}
      avatar={avatarImage}
      size={48}
      noMargin
    />
    <div className="flex-fill d-flex flex-column">
      <span className="large-text bold gray-700 text-truncate" style={{ maxWidth: 170 }}>
        {firstName} {lastName}
      </span>
      {overlapsCount && (
        <span className="normal-text semi-bold gray-500">{overlapsCount} overlaps</span>
      )}
    </div>
    <InviteUsers defaultEmails={[email]}>
      <button type="button" className="btn btn-secondary">
        <FontAwesomeIcon icon="plus" className="mr-2" />
        Invite
      </button>
    </InviteUsers>
  </div>
);

SuggestionCard.defaultProps = {
  overlapsCount: null,
};

export default SuggestionCard;
