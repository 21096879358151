// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';

import type { MessageUpdate } from 'views/BdRequests/NewBdRequest/NewBdRequestForm';
import NewBdRequestForm from 'views/BdRequests/NewBdRequest/NewBdRequestForm';

type Props = {
  discard: () => void,
  onUpdate: (update: MessageUpdate) => void,
  show: boolean,
  message: {
    accountSlug: ?string,
    partnerSlug: ?string,
    partnerAccountId: ?string,
    source: ?string,
    toOrgUserId: ?string,
  } & MessageUpdate,
};

const Message = ({ message, onUpdate, show, discard }: Props) => {
  const history = useHistory();

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <NewBdRequestForm
        history={history}
        inMessaging
        message={message}
        discard={discard}
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default Message;
