// @flow

import * as React from 'react';

const NoShare = () => (
  <svg width="25" height="20" viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 5C25 6.37891 23.8789 7.5 22.5 7.5C21.1211 7.5 20 6.37891 20 5C20 3.62109 21.1211 2.5 22.5 2.5C23.8789 2.5 25 3.62109 25 5Z"
      fillOpacity="0.4"
    />
    <path
      d="M11.25 2.5C11.25 3.87891 10.1289 5 8.75 5C7.37109 5 6.25 3.87891 6.25 2.5C6.25 1.12109 7.37109 0 8.75 0C10.1289 0 11.25 1.12109 11.25 2.5Z"
      fillOpacity="0.4"
    />
    <path
      d="M25 17.5C25 18.8789 23.8789 20 22.5 20C21.1211 20 20 18.8789 20 17.5C20 16.1211 21.1211 15 22.5 15C23.8789 15 25 16.1211 25 17.5Z"
      fillOpacity="0.4"
    />
    <path
      d="M0 11.875C0 10.4961 1.12109 9.375 2.5 9.375C3.87891 9.375 5 10.4961 5 11.875C5 13.2539 3.87891 14.375 2.5 14.375C1.12109 14.375 0 13.2539 0 11.875Z"
      fillOpacity="0.4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.125 16.25C15.5412 16.25 17.5 14.2912 17.5 11.875C17.5 9.45875 15.5412 7.5 13.125 7.5C10.7088 7.5 8.75 9.45875 8.75 11.875C8.75 14.2912 10.7088 16.25 13.125 16.25ZM13.125 13.75C14.1605 13.75 15 12.9105 15 11.875C15 10.8395 14.1605 10 13.125 10C12.0895 10 11.25 10.8395 11.25 11.875C11.25 12.9105 12.0895 13.75 13.125 13.75Z"
    />
  </svg>
);

export default NoShare;
