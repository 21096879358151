// @flow

import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { filter, find, map } from 'lodash';

import { getStatusStr, partnerRawStatuses, rawStatuses } from 'data/entities/status';
import type { Option } from 'data/repositories/accounts';

const OPTIONS: Option[] = rawStatuses.map((s) => ({
  name: getStatusStr(s),
  value: s,
}));

const PARTNER_OPTIONS: Option[] = partnerRawStatuses.map((s) => ({
  name: getStatusStr(s),
  value: s,
}));

type Props = {
  status: ?(Option[]),
  onChange: (options: ?(Option[])) => void,
  own?: boolean,
};

export const parseStatus = (value: string): string[] =>
  filter(
    map(value || [], (status) => find(OPTIONS, { value: status })),
    (val) => !!val
  );

function AccountStatusSelector({ status, onChange, own, ...props }: Props) {
  return (
    <Typeahead
      id="select-status"
      labelKey="name"
      onChange={onChange}
      options={own ? OPTIONS : PARTNER_OPTIONS}
      selected={status}
      placeholder="Account Status"
      bsSize="sm"
      className="mr-2"
      multiple
      clearButton
      changeOnClear
      {...props}
    />
  );
}
AccountStatusSelector.defaultProps = {
  own: true,
};
export default AccountStatusSelector;
