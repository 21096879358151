// @flow

import * as React from 'react';

import withOrguser from 'contexts/withOrguser';

import SlackIntegrationButton from 'components/SlackIntegrationButton';

function SlackIntegration() {
  return (
    <div className="SetupSlackIntegration row justify-content-md-center mt-5">
      <div className="col-xl-6">
        <h3>Setup Slack</h3>
        <p className="fs-15">Connect with Slack to get the most out of Crossbeam for Sales!</p>
        <ul className="fs-15 mb-3">
          <li>
            <code>/partners</code> slash command to lookup partners and accounts on the fly
          </li>
          <li>Alerts for bd and sales team members</li>
        </ul>
        <div className="d-flex justify-content-center">
          <SlackIntegrationButton />
        </div>
      </div>
    </div>
  );
}

export default withOrguser(SlackIntegration);
