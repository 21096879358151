// @flow

import * as React from 'react';

import { useTitleEffect } from 'utils/setTitle';
import type { LocationT } from 'sharedTypes/reactRouter';

import LoginForm from 'views/Auth/LoginForm';
import SignupForm from 'views/Auth/SignupForm';
import Logo from 'components/Logo';

import Footer from './Footer';

type Props = {
  location: LocationT,
};

export default function AuthBasic(props: Props) {
  useTitleEffect('Join');
  const {
    location: { state: locationState },
    doLogin,
  } = props;
  const { redirect, email } = locationState || {};
  let redirectFrom = null;
  const queryParams = new URLSearchParams(props.location.search);
  const isSignup = queryParams.get('action') === 'signup';
  const acceptedInvite = queryParams.get('inviteid');
  if (redirect && redirect.pathname) {
    const { search, pathname } = redirect;
    redirectFrom = `${pathname}${search || ''}`;
  }
  const onDoLogin = () => doLogin(true, redirectFrom || '/');

  return (
    <div style={{ marginTop: '10em' }}>
      <Logo height={30} className="my-5 mx-auto d-block" />
      <br />
      {isSignup ? (
        <SignupForm acceptedInvite={acceptedInvite} email={email} />
      ) : (
        <LoginForm acceptedInvite={acceptedInvite} doLogin={onDoLogin} email={email} />
      )}
      <hr />
      <Footer />
    </div>
  );
}
