// @flow

import * as React from 'react';

import { Account } from 'data/entities';
import type { AccountPartnerList } from 'data/entities/account';
import { getStatusComponent } from 'data/entities/status';

import CompanyLogo from 'components/CompanyLogo';
import Modal from 'components/Modal';
import NewBdRequestButton from 'components/NewBdRequestButton';

type Props = {
  account: Account,
  accountPartners: AccountPartnerList[],
  isOpen: boolean,
  toggle: () => void,
  accountOwnerId: string,
};

const AccountCard = ({
  accountId,
  accountPartner,
  accountOwnerId,
}: {
  accountId: string,
  accountPartner: AccountPartnerList,
  accountOwnerId: string,
}) => (
  <div className="mb-0">
    <div className="d-flex flex-row align-items-center">
      <CompanyLogo
        name={accountPartner.ownAccount.name}
        domain={accountPartner.ownAccount.website}
        size={45}
      />
      <div className="d-flex flex-column flex-fill">
        <div className="bold large-text text-black">{accountPartner.ownAccount.name}</div>
        <div>{getStatusComponent(accountPartner.ownAccount.statusRaw, false)}</div>
      </div>
      <NewBdRequestButton
        accountSlug={accountPartner.ownAccount.slug}
        partnerSlug={accountPartner.partner?.slug}
        partnerAccountId={accountId}
        toOrgUser={accountOwnerId}
      />
    </div>
  </div>
);

const MessageModal = ({ account, accountPartners, isOpen, toggle, accountOwnerId }: Props) => (
  <Modal isOpen={isOpen} toggle={toggle} title={`Connect with Partner about ${account.name}`}>
    <div className="mt-3 d-flex flex-column gap-15">
      {accountPartners.map((accountPartner: AccountPartnerList) => (
        <AccountCard
          key={accountPartner.id}
          accountId={account.id}
          accountPartner={accountPartner}
          accountOwnerId={accountOwnerId}
        />
      ))}
    </div>
  </Modal>
);

export default MessageModal;
