// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ShareAllIcon from 'assets/img/ShareAll';

type Props = {
  partnerSlug: string,
  compact?: boolean,
  cogless?: boolean,
};

const MappingBanner = ({ partnerSlug, compact, cogless }: Props) => (
  <div
    className={`bg-primary-lighter rounded ${
      compact ? 'px-4 py-2' : 'p-3 px-5'
    } d-flex flex-row align-items-center gap-15`}
    style={{ width: '470px' }}
  >
    <div className="icon-primary flex-fill">
      <ShareAllIcon />
    </div>
    <span className="normal-text bold gray-600">
      Share profiles and overlapping accounts, account statuses, and contacts with your partner.
      {cogless ? (
        <></>
      ) : (
        <Link to={`/p/${partnerSlug}/mapping-settings`} className="ml-1 text-muted small-text">
          <FontAwesomeIcon icon="cog" />
        </Link>
      )}
    </span>
  </div>
);

MappingBanner.defaultProps = {
  compact: false,
  cogless: false,
};

export default MappingBanner;
