// @flow

import type { ConfigSet } from 'data/entities/chug';
import Segment from 'data/entities/segment';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

const fetchSegments = (page: ?number, limit: ?number): Promise<PaginatedResponse<Segment>> => {
  const url = `${orgUrlBase()}/segments${
    page && limit ? `?offset=${(page - 1) * limit}&limit=${limit}` : ''
  }`;
  return authFetch(url).then(mapPaginatedResponse(Segment.fromApi));
};

const fetchSegment = (id: string): Promise<Segment> => {
  const url = `${orgUrlBase()}/segments/${id}`;
  return authFetch(url).then(Segment.fromApi);
};

const editSegment = (
  id: string,
  name: string,
  description: string,
  conditions: ConfigSet
): Promise<any> => {
  const body = {
    id,
    name,
    description,
    conditions,
  };
  const url = `${orgUrlBase()}/segments/${id}`;
  return authFetch(url, { method: 'PATCH', body });
};

const createSegment = (name: string, description: string, conditions: ConfigSet): Promise<any> => {
  const body = {
    name,
    description,
    conditions,
  };
  const url = `${orgUrlBase()}/segments`;
  return authFetch(url, { method: 'POST', body });
};

export { createSegment, editSegment, fetchSegment, fetchSegments };
