// @flow

import React, { useCallback } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { UncontrolledPopover } from 'reactstrap';
import { debounce } from 'lodash';

import { Org } from 'data/entities';
import filterEmoji from 'data/repositories/filterEmoji';
import { bdRequestMentionableUsers } from 'data/repositories/orgusers';
import withOrguser from 'contexts/withOrguser';

import textAreaStyle from '../AddBdRequestComment/textAreaStyle';

const neverMatchingRegex = /($a)/;

type TalkingPointProps = {
  talkingPoint: string,
  answer: string,
  setAnswer: (answer: string) => void,
  partnerId: string,
  partnerAccountId: string,
};
const TalkingPoint = ({
  talkingPoint,
  answer,
  setAnswer,
  partnerId,
  partnerAccountId,
}: TalkingPointProps) => {
  const queryEmojis = (query, callback) => {
    const emojis = filterEmoji(query);
    callback(
      emojis.map(({ title, symbol }) => ({
        id: `${symbol}`,
        display: `${symbol} :${title}:`,
      }))
    );
  };

  const queryUsers = (query, callback) => {
    const queryTooLong = query && query.length > 25;
    if (!query || query.trim() === '' || queryTooLong) {
      return;
    }
    bdRequestMentionableUsers(query, undefined, partnerAccountId, partnerId).then((users) => {
      callback(
        users.results.map(({ fullName, id, orgName }) => ({
          id,
          display: orgName ? `${fullName} (${orgName})` : fullName,
        }))
      );
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQueryUsers = useCallback(debounce(queryUsers, 200), [partnerAccountId, partnerId]);

  return (
    <div className="d-flex flex-column gap-5">
      <span className="large-text semi-bold gray-700">{talkingPoint}</span>
      <MentionsInput
        id="text"
        name="text"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Start writing..."
        style={{ ...textAreaStyle, minHeight: '6em' }}
        className="form-control w-full h-full"
        rows={8}
        autoFocus
        allowSpaceInQuery
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger="@"
          data={debouncedQueryUsers}
          displayTransform={(id, display) => `@${display}`}
        />
        <Mention trigger=":" markup="__id__" regex={neverMatchingRegex} data={queryEmojis} />
      </MentionsInput>
    </div>
  );
};

type Props = {
  answers: string[],
  setAnswers: (answers: string[]) => void,
  active: boolean,
  back: () => void,
  next: () => void,
  reset: () => void,
  partnerId: string,
  partnerAccountId: string,
  loggedInOrg: Org,
  inAccountDetail?: boolean,
};

const TalkingPoints = ({
  answers,
  setAnswers,
  active,
  back,
  next,
  reset,
  partnerId,
  partnerAccountId,
  loggedInOrg,
  inAccountDetail,
}: Props) => {
  const { talkingPoints } = loggedInOrg.settings.requestsWorkflowSettings;

  if (!active)
    return (
      <div className="card flex-grow-0 m-0 bg-gray-100 no-shadow p-3 d-flex flex-column gap-10">
        <div className="d-flex flex-row align-items-center gap-10">
          <div
            className="rounded-circle d-flex align-items-center justify-content-center small-text disable-selection bg-gray-200 gray-500"
            style={{ width: 28, height: 28 }}
          >
            {inAccountDetail ? 3 : 2}
          </div>
          <span className="large-text semi-bold gray-600">
            Fill in the blank about your account
          </span>
        </div>
      </div>
    );

  return (
    <div className="card flex-grow-0 no-shadow p-3 d-flex flex-column gap-10">
      <div className="d-flex flex-row align-items-center gap-10">
        <div
          className="rounded-circle d-flex align-items-center justify-content-center small-text disable-selection text-white bg-blue"
          style={{ width: 28, height: 28 }}
        >
          {inAccountDetail ? 3 : 2}
        </div>
        <span className="large-text bold gray-700">Fill in the blank about your account</span>
      </div>
      {talkingPoints.map((tp, i) => (
        <TalkingPoint
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          talkingPoint={tp}
          answer={answers[i]}
          setAnswer={(answer) => {
            const newAnswers = [...answers];
            newAnswers[i] = answer;
            setAnswers(newAnswers);
          }}
          partnerId={partnerId}
          partnerAccountId={partnerAccountId}
        />
      ))}
      <div className="d-flex flex-row align-items-center gap-15 mt-2">
        <UncontrolledPopover
          className="PopoverCustom"
          innerClassName="card-body px-3"
          target="reset-form"
          trigger="click"
          delay={{ show: 100, hide: 200 }}
        >
          <div className="d-flex flex-column gap-15" style={{ maxWidth: 250 }}>
            <span className="normal-text bold gray-700">
              Are you sure that you want to cancel and reset this request?
            </span>
            <div>
              <button type="button" className="btn btn-danger" onClick={reset}>
                Confirm
              </button>
            </div>
          </div>
        </UncontrolledPopover>
        <button id="reset-form" type="button" className="btn btn-secondary">
          Cancel
        </button>

        <div className="flex-1" />
        <button type="button" className="btn btn-secondary" onClick={back}>
          Back
        </button>
        <button type="button" className="btn btn-primary" onClick={next}>
          Preview your message
        </button>
      </div>
    </div>
  );
};

TalkingPoints.defaultProps = {
  inAccountDetail: false,
};

export default withOrguser(TalkingPoints);
