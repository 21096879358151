// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, BdRequestRedirect, Org } from 'data/entities';
import { SharedConversation } from 'data/entities/bdrequest';
import { fetchBdRequest, getSharedConversation } from 'data/repositories/bdrequests';

import LoadingRing from 'components/LoadingRing';

import BasicInfo from './BasicInfo';
import Comments from './Comments';
import DeleteBdRequest from './DeleteBdRequest';
import MarkCompleted from './MarkCompleted';
import Participants from './Participants';
import PartnerAccountInfo from './PartnerAccountInfo';
import PreviousConversations from './PreviousConversations';

type Props = {
  org: Org,
};

const BdRequestDetail = ({ org }: Props) => {
  const { requestId } = useParams();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [request, setRequest] = useState<BdRequest | null>(null);
  const [sharedConversation, setSharedConversation] = useState<SharedConversation | null>(null);

  const onRequestUpdate = useCallback(
    (showLoading: boolean = false) => {
      if (showLoading) setLoadingRequest(true);
      fetchBdRequest(requestId)
        .then((bd: BdRequest | BdRequestRedirect) => {
          if (bd instanceof BdRequestRedirect) {
            window.location.href = `/requests/${bd.ownBdRequestId}?login-org=${bd.orgUuid}`;
          } else {
            setLoadingRequest(false);
            setRequest(bd);

            // Get shared conversation after we are certain we are not going to
            // be redirected
            getSharedConversation(requestId).then((sc) => {
              setSharedConversation(sc);
            });
          }
        })
        .catch((error) => {
          setLoadingRequest(false);
        });
    },
    [requestId]
  );

  useEffect(() => {
    onRequestUpdate(true);
  }, [onRequestUpdate, requestId]);

  const isSlackThread = useMemo(() => request?.slackThreadUrl || request?.isOffsite, [request]);

  return !loadingRequest ? (
    <>
      {request ? (
        <div className="page-top-margin mb-4 BdRequestContainer d-flex flex-row">
          <div className="flex-fill d-flex flex-column">
            <div className="BdRequestHeader d-flex flex-row align-items-center gap-15">
              <div
                className="flex-fill d-flex flex-row align-items-center gap-10"
                style={{ width: '0px' }}
              >
                <FontAwesomeIcon icon="hashtag" size="lg" className="primary" />
                <h5 className="bold gray-700 text-truncate fs-mask">{request.title}</h5>
              </div>
              {!isSlackThread && <Participants request={request} onUpdate={onRequestUpdate} />}
              <MarkCompleted request={request} onUpdate={onRequestUpdate} />
            </div>
            <Comments
              request={request}
              sharedConversation={sharedConversation}
              onUpdate={onRequestUpdate}
            />
          </div>
          <div className="BdRequestInformation d-flex flex-column">
            <BasicInfo onRequestUpdate={onRequestUpdate} request={request} />
            {request.isShared && request.account && request.partner && (
              <PartnerAccountInfo
                sharedConversation={sharedConversation}
                onRequestUpdate={onRequestUpdate}
                request={request}
              />
            )}
            <PreviousConversations request={request} />
            {(!isSlackThread || (isSlackThread && org.id === request.createdBy.orgId)) && (
              <DeleteBdRequest requestId={request.id} />
            )}
          </div>
        </div>
      ) : (
        <div className="mt-5 text-center">
          <h6>Request Not Found</h6>
          <Link to="/requests">View All Requests</Link>
        </div>
      )}
    </>
  ) : (
    <LoadingRing className="mt-5" maxWidth="60px" text="Loading Request..." />
  );
};

export default BdRequestDetail;
