// @flow

import * as React from 'react';

import { Org } from 'data/entities';
import authFetch from 'authFetch/authFetch';
import type { LocationT } from 'sharedTypes/reactRouter';

import OauthCallbackValidation from 'views/Oauth/OauthCallbackValidation';

function exchangeCRMCode(provider, code, inviteCode) {
  const body = { code, accepted_invite_code: inviteCode, provider };
  return authFetch('auth/exchange-crm-sso-code', { method: 'POST', body }).then((resp) => {
    const { org, ...rest } = resp;
    return {
      org: Org.fromApi(resp.org),
      ...rest,
    };
  });
}

type Props = {
  location: LocationT,
  doLogin: (doOnboarding: ?boolean) => {},
  provider: 'HUBSPOT' | 'SALESFORCE',
};

export default function CRMLoginCallback(props: Props) {
  const { location, doLogin, provider } = props;
  const validateCall = (code: string, inviteCode: string) =>
    exchangeCRMCode(provider, code, inviteCode);
  return (
    <OauthCallbackValidation location={location} doLogin={doLogin} validationCall={validateCall} />
  );
}
