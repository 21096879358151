/* eslint-disable max-classes-per-file */
// @flow

import { map, uniqueId } from 'lodash';

import { newObjectWithApiValues } from 'data/entities/utils';
import { camelizeKeys } from 'utils/nodash';

import { CROSSBEAM, CSV, HUBSPOT, SALESFORCE } from './integrationProviders';
import { OrgUserAdminOnly } from './orguser';

export const STRING_TYPE = 'string';
export const TEXT_AREA = 'textarea';
export const BOOLEAN = 'boolean';
export const PICK_LIST = 'picklist';

const fieldTypesMap = {
  [STRING_TYPE]: STRING_TYPE,
  [TEXT_AREA]: STRING_TYPE,
  [BOOLEAN]: BOOLEAN,
  [PICK_LIST]: PICK_LIST,
};

type fieldType = $Keys<typeof fieldTypesMap>;

class ChugSchemaOption {
  value: string;

  label: string;

  static fromApi: (data: any) => ChugSchemaOption;
}

ChugSchemaOption.fromApi = (data: any): ChugSchemaOption => {
  const e = new ChugSchemaOption();
  e.value = data.value;
  e.label = data.label;
  return e;
};

type ChugEntityFieldData = {
  name: string,
  label: string,
  is_synced: boolean,
  is_used: boolean,
  is_required: boolean,
  is_selected: boolean,
  type: fieldType,
  picklist_values: ChugSchemaOption[],
};

class ChugEntityField {
  name: string;

  label: string;

  isSynced: boolean;

  isUsed: boolean;

  isRequired: boolean;

  isSelected: boolean;

  type: fieldType;

  picklistValues: ChugSchemaOption[];

  static fromApi: (data: ChugEntityFieldData) => ChugEntityField;

  get isSelectable(): boolean {
    const IS_CHUG_ENTITY_FIELD_SELECTED = (field: ChugEntityField) =>
      field.isRequired || field.isUsed || field.isSelected;
    return IS_CHUG_ENTITY_FIELD_SELECTED(this);
  }

  get isPendingSync(): boolean {
    return this.isSelected && !this.isSynced;
  }
}

ChugEntityField.fromApi = (data: ChugEntityFieldData): ChugEntityField => {
  const ef = new ChugEntityField();
  ef.name = data.name;
  ef.label = data.label;
  ef.isSynced = data.is_synced;
  ef.isUsed = data.is_used;
  ef.isRequired = data.is_required;
  ef.isSelected = data.is_selected;
  ef.type = data.type;
  ef.picklistValues = map(data.picklist_values, ChugSchemaOption.fromApi);
  return ef;
};

export type ChugEntityData = {
  fields: ChugEntityFieldData[],
  integration_provider: string,
  id: string,
  name: string,
};

class ChugEntity {
  fields: ChugEntityField[];

  integrationProvider: string;

  id: string;

  name: string;

  static fromApi: (data: ChugEntityData) => ChugEntity;
}

ChugEntity.fromApi = (data: ChugEntityData): ChugEntity => {
  const e = new ChugEntity();
  e.integrationProvider = data.integration_provider;
  e.id = data.id;
  // e.fields = orderBy(data.fields.map(ChugEntityField.fromApi), 'label');
  e.fields = data.fields.map(ChugEntityField.fromApi);
  e.name = data.name;
  return e;
};

export const TRUE_SELECTED = 'true';
export const FALSE_SELECTED = 'false';

type combineType = 'ALL' | 'ANY';

export const OPERATOR_IN = 'IN';
const OPERATOR_NOT_IN = 'NOT_IN';

export const operatorMap = {
  [OPERATOR_IN]: 'is',
  [OPERATOR_NOT_IN]: 'is not',
};

export const booleanOperatorMap = {
  [OPERATOR_IN]: 'equals',
};

type operatorType = $Keys<typeof operatorMap>;

class ChugSchema {
  name: string;

  type: fieldType;

  label: string;

  picklistValues: ChugSchemaOption[];

  isSynced: boolean;

  static fromApi: (data: any) => ChugSchema;
}

ChugSchema.fromApi = (data: any): ChugSchema => {
  const e = new ChugSchema();
  e.name = data.name;
  e.type = data.type;
  e.label = data.label;
  e.picklistValues = map(data.picklist_values, ChugSchemaOption.fromApi);
  e.isSynced = data.is_synced;
  return e;
};

class LabeledValue {
  label: string;

  value: any;

  constructor(value: any, label: string) {
    this.value = value;
    this.label = label;
  }

  static fromApi: (data: any) => LabeledValue;
}

LabeledValue.fromApi = (data: any): LabeledValue => new LabeledValue(data.value, data.label);

class ConfigFieldMultiValue {
  operator: operatorType;

  values: LabeledValue[];

  name: string;

  id: string;

  constructor() {
    this.id = uniqueId('config-field');
  }

  get isEmpty() {
    return !this.name && !this.operator && (!this.values || this.values.length === 0);
  }

  static fromApi: (data: any) => ConfigFieldMultiValue;
}
ConfigFieldMultiValue.fromApi = (data: any): ConfigFieldMultiValue => {
  const e = new ConfigFieldMultiValue();
  if (!data) {
    return e;
  }
  e.name = data.name;
  e.operator = data.operator;
  e.values = data.values && data.values.map(LabeledValue.fromApi);
  return e;
};

class ConfigFieldGroup {
  combineRule: combineType;

  items: ConfigFieldMultiValue[];

  id: string;

  constructor() {
    this.id = uniqueId('config-field');
    this.items = [new ConfigFieldMultiValue()];
  }

  static fromApi: (data: any) => ConfigFieldGroup;
}

ConfigFieldGroup.fromApi = (data: any): ConfigFieldGroup => {
  const e = new ConfigFieldGroup();
  e.combineRule = data.combine_rule;
  e.items = data.items.map(ConfigFieldMultiValue.fromApi);
  return e;
};

class ConfigSet {
  items: ConfigFieldGroup[];

  combineRule: combineType;

  constructor() {
    this.items = [new ConfigFieldGroup()];
  }

  static fromApi: (data: any) => ConfigSet;
}

ConfigSet.fromApi = (data: any): ConfigSet => {
  const e = new ConfigSet();
  e.combineRule = data.combine_rule;
  e.items = data.items.map(ConfigFieldGroup.fromApi);
  return e;
};

export const ACTIVE_STATUS = 'ACTIVE';
export const INACTIVE_STATUS = 'INACTIVE';
export const INVALID_CREDENTIALS_STATUS = 'INVALID_CREDENTIALS';
export const NEW_STATUS = 'NEW';
export const SCHEMA_SYNCED_STATUS = 'SCHEMA_SYNCED';
export const UNAUTHORIZED_CREDENTIALS_STATUS = 'UNAUTHORIZED_CREDENTIALS';
const INACTIVE_STATUSES = [
  INACTIVE_STATUS,
  INVALID_CREDENTIALS_STATUS,
  UNAUTHORIZED_CREDENTIALS_STATUS,
];
export const REQUIRES_CREDENTIALS_STATUSES = [
  UNAUTHORIZED_CREDENTIALS_STATUS,
  INVALID_CREDENTIALS_STATUS,
];
export const UNAUTHORIZED_INTEGRATION_MESSAGE =
  'Missing expected API permissions. Please have an admin user integrate.';
export const INTEGRATION_ICONS_MAP = {
  // FontAwesome icons
  [SALESFORCE]: ['fab', 'salesforce'],
  [CSV]: 'file-csv',
  [HUBSPOT]: ['fab', 'hubspot'],
  [CROSSBEAM]: 'question',
};

export const INTEGRATION_LABELS_MAP = {
  [SALESFORCE]: 'Salesforce',
  [CSV]: 'CSV',
  [HUBSPOT]: 'HubSpot',
  [CROSSBEAM]: 'Crossbeam',
};
export const ACCOUNT_MODEL = 'ACCOUNT';
export const OPPORTUNITY_MODEL = 'OPPORTUNITY';
export const ORGUSER_MODEL = 'ORGUSER';

export const INTEGRATIONS_TO_ENTITY_NAME_MAP = {
  [SALESFORCE]: {
    [ACCOUNT_MODEL]: 'Account',
    [OPPORTUNITY_MODEL]: 'Opportunity',
    [ORGUSER_MODEL]: 'User',
  },
  [HUBSPOT]: {
    [ACCOUNT_MODEL]: 'Company',
    [OPPORTUNITY_MODEL]: 'Deal',
    [ORGUSER_MODEL]: 'Owner',
  },
  [CROSSBEAM]: {
    [ACCOUNT_MODEL]: 'Account',
    [OPPORTUNITY_MODEL]: 'Opportunity',
    [ORGUSER_MODEL]: 'User',
  },
};

class IntegrationCapabilities {
  hasAccountSchema: boolean;

  canSetCustomerAccounts: boolean;

  canSetExcludedAccounts: boolean;

  canSetChurnedAccounts: boolean;

  canSetTargetAccounts: boolean;

  canSetEntitiesFields: boolean;

  canSync: boolean;

  canSyncEntities: boolean;

  static fromApi(data: any): IntegrationCapabilities {
    return newObjectWithApiValues(IntegrationCapabilities, data);
  }
}

class Integration {
  status: string;

  provider: string;

  orguser: ?OrgUserAdminOnly;

  capabilities: IntegrationCapabilities;

  get isActive() {
    return !INACTIVE_STATUSES.includes(this.status);
  }

  get requiresOnboarding(): boolean {
    // "INACTIVE" status is used to denote organizations that specifically want their integration
    // turned off, so we don't need to send them through onboarding at this time.

    return ![ACTIVE_STATUS, INACTIVE_STATUS].includes(this.status);
  }

  get isCSV() {
    return this.provider === CSV;
  }

  static fromApi: (data: any) => Integration;
}

Integration.fromApi = (data: any): Integration => {
  const e = new Integration();
  e.status = data.status;
  e.provider = data.provider;
  e.orguser = data.orguser ? OrgUserAdminOnly.fromApi(data.orguser) : null;
  e.capabilities = IntegrationCapabilities.fromApi(data.capabilities);
  return e;
};

class CorrectBulkImport {
  syncjobId: string;

  status: string;

  errors: any;

  invalidCount: number;

  processed: number;

  static fromApi: (data: any) => CorrectBulkImport;
}

CorrectBulkImport.fromApi = (data: any): CorrectBulkImport => {
  const e = new CorrectBulkImport();
  e.syncjobId = data.syncjob_id;
  e.errors = data.errors && camelizeKeys(data.errors);
  e.invalidCount = data.invalid_count;
  e.processed = data.processed;
  return e;
};

export type { operatorType };

export {
  ChugEntity,
  ChugEntityField,
  ChugSchema,
  ConfigFieldGroup,
  ConfigFieldMultiValue,
  ConfigSet,
  CorrectBulkImport,
  Integration,
  LabeledValue,
};
