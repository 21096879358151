// @flow

import * as React from 'react';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Partner } from 'data/entities';

type Props = {
  partner: ?Partner,
  canManage: boolean,
};

export default function ConversationSettingsAlert({ partner, canManage }: Props) {
  if (!partner) {
    return null;
  }
  const directSendDisabled =
    !canManage &&
    partner &&
    partner.settings.conversationSettings.enabledDirectConversations === false;
  const alertBody = directSendDisabled ? (
    <>
      Direct conversations are disabled for {partner.name}. <br />
      This will go to your partner manager, first.
    </>
  ) : (
    !!partner &&
    !canManage &&
    partner.settingsByPartner?.conversationSettings.enabledDirectConversations === false && (
      <>
        {partner.name} has direct conversations disabled. The partner manager from their team can
        loop in their sellers
      </>
    )
  );

  return alertBody ? (
    <div className="p-3">
      <Alert color="warning" className="d-flex align-items-center mb-0">
        <div className="mr-3">
          <FontAwesomeIcon icon="lock" />
        </div>{' '}
        <div>{alertBody}</div>
      </Alert>
    </div>
  ) : null;
}
