// @flow

import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { get, isNil } from 'lodash';

import { OrgUser } from 'data/entities';
import OrgUserWithOrg from 'data/entities/orguserWithOrg';
import { myOrgusers } from 'data/repositories/orgusers';
import type { LocationT } from 'sharedTypes/reactRouter';

import BareLayout from 'layouts/BareLayout';
import { getExtensionTokenAndPush } from 'views/ConnectExtension/ConnectExtension';
import CompanyLogo from 'components/CompanyLogo';
import FullPageLoadingRing from 'components/FullPageLoadingRing';
import Logo from 'components/Logo';

type Props = {
  doLogin: ?(loginOrgUUID: string, doOnboarding: ?boolean) => Promise<Any>,
  doAuthenticate: (
    doOnboarding: ?boolean,
    historyPushArgs: ?string,
    asOrguser: ?string
  ) => Promise<{ success: boolean, orguser: OrgUser }>,
  location: LocationT,
};

const MyOrgUsers = ({ doLogin, doAuthenticate, location }: Props) => {
  const [orgusers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setOrgUsers([]);
    myOrgusers()
      .then((ou) => {
        setOrgUsers(ou.results);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  // If there is a redirect specified and only one orguser, go straight to that user.
  const redirectFrom = get(location, 'state.redirectFrom');
  let redirectTo;
  if (redirectFrom && redirectFrom.pathname) {
    const { pathname, search } = redirectFrom;
    redirectTo = `${pathname}${search || ''}`;
  } else {
    redirectTo = null;
  }

  const switchOrguser = (orgUUID, orguserId) => {
    // Do the full login, used when we are showing the org picker in the No
    // Access case
    if (!isNil(doLogin)) {
      doLogin(orgUUID, true);
    } else {
      // This occurs when we are switching orgs from the org picker route
      doAuthenticate(true, redirectTo, orguserId).then(({ success, orguser }) => {
        if (success && !orguser.isGhost && orguser.hasChromeExtension)
          getExtensionTokenAndPush().catch(() => {});
      });
    }
  };

  if (loading) {
    return <FullPageLoadingRing text="Logging in..." />;
  }

  return (
    <BareLayout centered>
      <Logo height={50} className="m-2" />
      <h4 className="mb-4 text-black bold">Select an organization to continue</h4>
      <div className="d-flex flex-column gap-10" style={{ maxWidth: '550px' }}>
        {orgusers.map((orguser: OrgUserWithOrg, index) => {
          const { org } = orguser;
          return (
            <React.Fragment key={orguser.id}>
              <Button
                onClick={() => switchOrguser(org.sourceUuid, orguser.id)}
                value={orguser.id}
                className="card-hoverable m-0 py-2"
                color="link"
                block
              >
                <div className="d-flex flex-row gap-15 align-items-center">
                  <CompanyLogo
                    name={org.name}
                    logoUrl={org.logoUrl}
                    domain={org.url}
                    size={48}
                    className=""
                  />
                  <div className="d-flex flex-column align-items-start">
                    <span className="large-text bold text-black">
                      {org.name && org.name.length ? org.name : '(Incomplete Setup)'}
                    </span>
                    {orguser.sourceFullName && (
                      <div className="d-flex flex-row gap-10 align-items-center">
                        <span className="large-text bold gray-500" style={{ flex: '1 0 auto' }}>
                          {orguser.sourceFullName}
                        </span>
                        <h3 className="m-0 p-0 lh-10 gray-500">·</h3>
                        <span className="large-text semi-bold gray-500 text-truncate">
                          {orguser.email}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Button>
              {index !== orgusers.length - 1 && <hr className="w-100 m-0" />}
            </React.Fragment>
          );
        })}
      </div>
    </BareLayout>
  );
};

export default MyOrgUsers;
