// @flow

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { BdRequest, OrgUser } from 'data/entities';
import { SharedConversation } from 'data/entities/bdrequest';
import { Contact } from 'data/entities/contact';
import { fetchContacts } from 'data/repositories/contact';
import withOrguser from 'contexts/withOrguser';
import useOrgProvider from 'utils/useOrgProvider';

import PartnerDetailHoverCard from 'views/PartnerDetail/PartnerDetailHoverCard';
import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import PersonAvatar from 'components/PersonAvatar';

import InfoTable from './InfoTable';
import PartnerInfo from './PartnerInfo';

type Props = {
  request: BdRequest,
  sharedConversation: SharedConversation | null,
  onRequestUpdate: () => void,
  loggedInOrguser: OrgUser,
};

const PartnerAccountInfo = ({
  sharedConversation,
  request,
  onRequestUpdate,
  loggedInOrguser,
}: Props) => {
  const { isProviderCrossbeam } = useOrgProvider();
  const loading = !sharedConversation;

  const contactsRef = useRef(null);
  const [contactsCount, setContactsCount] = useState(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loadingContacts, setLoadingContacts] = useState(false);

  useEffect(() => {
    // Load first 3 contacts.
    if (loggedInOrguser.capabilities.canAccessSeatedExperience) {
      setLoadingContacts(true);
      fetchContacts(request.account.slug, {
        partnerSlug: [request.partner.slug],
        page: 0,
        limit: 3,
      })
        .then((n) => {
          setLoadingContacts(false);
          setContacts(n.results);
          setContactsCount(n.count);
        })
        .catch((error) => {
          setLoadingContacts(false);
        });
    }
  }, [loggedInOrguser, request]);

  return sharedConversation && sharedConversation.partnerAccount ? (
    <div className="py-4 px-3 d-flex flex-column gap-20">
      <PartnerInfo request={request} />
      <InfoTable
        table={[
          [
            'Account',
            loading ? (
              <LoadingRing maxWidth="20px" />
            ) : (
              <div className="d-flex flex-row align-items-center gap-10">
                <PartnerDetailHoverCard partner={request.partner}>
                  <CompanyLogo
                    size={20}
                    name={sharedConversation.partnerAccount.name}
                    domain={sharedConversation.partnerAccount.website}
                    className=""
                  />
                </PartnerDetailHoverCard>
                <span className="normal-text bold gray-700">
                  {sharedConversation.partnerAccount.name}
                </span>
              </div>
            ),
          ],
          [
            'Status',
            loading ? <LoadingRing maxWidth="20px" /> : sharedConversation.partnerAccount.status,
          ],
          ...(!isProviderCrossbeam
            ? [
                [
                  'Contacts',
                  loadingContacts ? (
                    <LoadingRing maxWidth="20px" />
                  ) : (
                    <div ref={contactsRef} className="d-flex flex-row align-items-center gap-5">
                      <div className="d-flex flex-row align-items-center">
                        {contacts.map((contact, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div style={index !== 0 ? { marginLeft: '-8px' } : {}} key={index}>
                            <PersonAvatar firstName={contact.fullNames[0]} noMargin size={20} />
                          </div>
                        ))}
                      </div>
                      <Link
                        to={`/a/${request.account.slug}/contacts?partner=${request.partner.slug}`}
                        className="small-text bold primary"
                      >{`${contactsCount || 0} contact${contactsCount === 1 ? '' : 's'}`}</Link>
                    </div>
                  ),
                ],
              ]
            : []),
        ]}
      />
    </div>
  ) : null;
};

export default withOrguser(PartnerAccountInfo);
