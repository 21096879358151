// @flow

import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';

import { isAbortError } from 'authFetch/utils';

/** Capture Sentry Error
 * Send error to sentry.
 * @param {*} error
 * @returns {boolean} Error handled
 */
const captureSentryError = (error: any): boolean => {
  if (
    !isNil(error) &&
    !isAbortError(error) &&
    (error.response ? ![404, 403].includes(error.response.status) : true)
  ) {
    Sentry.captureException(error);
    return true;
  }
  return false;
};

export default captureSentryError;
