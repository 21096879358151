// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser, Partner } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import CompanyLogo from 'components/CompanyLogo';
import NotEnabledBadge from 'components/NotEnabledBadge';

type Props = {
  loggedInOrguser: OrgUser,
  partner: Partner,
};

function PartnerName({ name, logoUrl, domain, isConnectedPartner, children }) {
  return (
    <div className="d-flex align-items-center mb-1">
      <CompanyLogo name={name} logoUrl={logoUrl} domain={domain} size={48} />
      <div>
        <div className="d-flex align-items-center gap-10">
          <div className="bold large-text gray-700 fs-mask">{name}</div>
          {!isConnectedPartner && <NotEnabledBadge iconOnly />}
        </div>
        {children}
      </div>
    </div>
  );
}

function PartnerActionArea({ loggedInOrguser, partner }: Props): React.Node {
  const { id, isSynced } = partner;

  if (!isSynced && loggedInOrguser.canManage) {
    return (
      <Link to={`/crossbeam?partner=${id}&action=enable`} disabled>
        <button className="btn btn-secondary" type="button">
          Enable data
        </button>
      </Link>
    );
  }

  return null;
}

const PartnerCard = ({ loggedInOrguser, partner }: Props) => {
  if (!partner) {
    return null;
  }
  const { name, partnerOrg, slug, bdManager, isSynced, domain } = partner;
  const className = 'card card-hoverable mb-4';

  return (
    <div className={className}>
      <Link to={`/p/${slug}`} className="flex-fill" disabled>
        <div className="d-flex flex-row align-items-center card-body gap-5">
          <div className="flex-fill">
            <PartnerName
              name={name}
              logoUrl={partnerOrg.logoUrl}
              isConnectedPartner={isSynced}
              domain={domain}
            >
              {bdManager ? (
                <div className="semi-bold normal-text blue fs-mask">{bdManager.fullName}</div>
              ) : (
                <div className="semi-bold normal-text gray-400">
                  Not Assigned
                  <FontAwesomeIcon icon="pencil" className="ml-1" size="sm" />
                </div>
              )}
            </PartnerName>
          </div>
          <PartnerActionArea loggedInOrguser={loggedInOrguser} partner={partner} />
        </div>
      </Link>
    </div>
  );
};

export default withOrguser(PartnerCard);
