// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import uniqueId from 'lodash/uniqueId';

import { AccountOwner, Org, Partner, PartnerAccount } from 'data/entities';
import { PartnerDetailAccountPartner } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';

import ListPopup from 'views/Lists/ListPopup';
import AccountStatusAlertTooltip from 'components/AccountStatusAlertTooltip';
import NewBdRequestButton from 'components/NewBdRequestButton';
import Pill from 'components/Pill';
import Target from 'assets/img/target';

type Props = {
  loggedInOrg: Org,
  accountPartner: PartnerDetailAccountPartner,
  partner: Partner,
};

type AccountInfoProps = {
  orgId: string,
  isConnected?: boolean,
  account: PartnerAccount,
  accountOwners: AccountOwner[],
  isTarget: boolean,
  isTargetValue?: ?string,
  highlighted?: boolean,
  requestCountLink?: ?React.Node,
  accountPartnerId?: ?string,
};

export function AccountInformation(props: AccountInfoProps) {
  const {
    orgId,
    isConnected,
    account,
    isTarget,
    accountOwners,
    highlighted,
    requestCountLink,
    isTargetValue,
    accountPartnerId,
  } = props;
  const { name, statusRaw, status, slug, website } = account || {};
  // Used to represent account info (name, status, account owners) for org or partners account.
  const className = classNames('PartnerRow__AccountInformation flex-1 py-3 d-flex', {
    AccountInformation__highlighted: highlighted,
  });
  const toolTipId =
    isTargetValue && `tooltip-${account.id}-is-target-value${uniqueId('account-target')}`;
  return (
    <div className={className}>
      <div className="flex-fill d-flex align-items-center">
        <div className="mr-3 d-flex flex-column align-items-center">
          {!!account.statusAlertText && (
            <div>
              <AccountStatusAlertTooltip account={account} skipRightPad />
            </div>
          )}
          {!!requestCountLink && <div>{requestCountLink}</div>}
        </div>
        <div className="flex-fill">
          <div className="d-flex flex-row gap-5">
            {isTarget && (
              <span id={toolTipId}>
                <Target width="18" disabled={!isTarget} className="mr-2" />
              </span>
            )}
            {isTarget && isTargetValue && (
              <UncontrolledTooltip placement="top" target={toolTipId} delay={{ show: 100 }}>
                {isTargetValue}
              </UncontrolledTooltip>
            )}
            {slug ? (
              <Link to={`/a/${slug}`} className="fs-mask">
                {name}
              </Link>
            ) : (
              <span className="fs-mask">{name}</span>
            )}
            {website ? (
              <span className="text-muted small-text font-heading normal fs-mask">
                {`(${website})`}
              </span>
            ) : null}
          </div>
          <div className="d-flex flex-wrap mt-1 align-items-center">
            {statusRaw && <div className="pr-3">{status}</div>}
            {Boolean(accountOwners && accountOwners.length) &&
              uniqBy(accountOwners, 'orguserId')
                .map((ao) => (
                  <AccountOwnerItem
                    isConnected={isConnected}
                    orgId={orgId}
                    accountOwner={ao}
                    key={ao.orguserId}
                  />
                ))
                .reduce((prev, curr, i) => [
                  prev,
                  <div className="px-2" key={`sep-${curr.key}`}>
                    •
                  </div>,
                  curr,
                ])}
          </div>
        </div>
        {accountPartnerId && (
          <div className="mr-3">
            <ListPopup accountPartnerId={accountPartnerId} />
          </div>
        )}
      </div>
    </div>
  );
}

AccountInformation.defaultProps = {
  isConnected: false,
  isTargetValue: null,
  highlighted: false,
  requestCountLink: null,
  accountPartnerId: null,
};

export const requestBtnWidth = '90px';

function PartnerRow(props: Props) {
  const {
    loggedInOrg,
    partner,
    accountPartner: {
      id,
      account,
      partnerAccount,
      partnerAccountOwners,
      accountOwners,
      isMyTarget,
      isMyTargetValue,
      isPartnerTarget,
      requestCount,
    },
  } = props;
  const requestCountLink = requestCount && (
    <Link to={`/requests/all?account=${account.slug}&partner=${partner.slug}`}>
      <Pill label={requestCount} />
    </Link>
  );
  return (
    <div key={account.name} className="PartnerRow d-flex px-2">
      <div className="mr-3 align-items-center d-flex flex-column justify-content-center">
        <NewBdRequestButton
          extraClasses="btn-xs mt-2"
          style={{ minWidth: requestBtnWidth }}
          accountSlug={account.slug}
          partnerSlug={partner?.slug}
          partnerAccountId={partnerAccount.id}
          toOrgUser={partnerAccountOwners[0] ? partnerAccountOwners[0].orguserId : undefined}
        />
      </div>
      <AccountInformation
        orgId={loggedInOrg.id}
        account={account}
        isTarget={isMyTarget}
        isTargetValue={isMyTargetValue}
        accountOwners={accountOwners}
        requestCountLink={requestCountLink}
        isConnected
      />
      <AccountInformation
        accountPartnerId={id}
        orgId={partner?.partnerOrg?.id}
        account={partnerAccount}
        isTarget={isPartnerTarget}
        accountOwners={partnerAccountOwners}
        isConnected={partner.isConnected}
        highlighted
      />
    </div>
  );
}

export default withOrguser(PartnerRow);

type AccountOwnerProps = {
  isConnected: boolean,
  orgId: string,
  accountOwner: AccountOwner,
};

const AccountOwnerItem = ({ orgId, isConnected, accountOwner: ao }: AccountOwnerProps) =>
  isConnected ? (
    <Link key={ao.email} to={`/network/oid/${ao.orguserId}`} className="fs-13 fs-mask">
      {ao.fullName}
    </Link>
  ) : (
    <div className="fs-13 fs-mask">{ao.fullName}</div>
  );
