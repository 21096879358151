// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account, AccountOwner, Org, OrgUser } from 'data/entities';
import { fetchAccountBySlug } from 'data/repositories/accounts';
import { fetchContacts } from 'data/repositories/contact';
import withOrguser from 'contexts/withOrguser';

import AccountHiddenIcon from 'components/AccountSettings/AccountHiddenIcon';
import AccountStatusAlertTooltip from 'components/AccountStatusAlertTooltip';
import CompanyLogo from 'components/CompanyLogo';
import HorizontalScroll from 'components/HorizontalScroll';
import LoadingRing from 'components/LoadingRing';
import Pill from 'components/Pill';
import FinishSetupOverlay from 'components/SeatlessExperience/FinishSetupOverlay';

import ContactCard from './ContactCard';
import Content from './Content';
import Suggestions from './Suggestions';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  match: { params: { accountSlug: string } },
};

const RequestAccountDetail = ({
  loggedInOrg,
  loggedInOrg: { isIntegrationSetup },
  loggedInOrguser,
  loggedInOrguser: { capabilities },
  match: {
    params: { accountSlug },
  },
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [account, setAccount] = useState<Account | null>(null);

  const loadAccount = useCallback(() => {
    setLoading(true);
    setError(false);
    setAccount(null);
    fetchAccountBySlug(accountSlug)
      .then((a) => {
        setLoading(false);
        setError(false);
        setAccount(a);
      })
      .catch((err) => {
        setLoading(false);
        setError(!!err);
      });
  }, [accountSlug]);

  const [contacts, setContacts] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(true);

  const loadContacts = useCallback(() => {
    setLoadingContacts(true);
    setContacts([]);
    fetchContacts(accountSlug, {
      offset: 0,
      limit: 10,
    })
      .then((results) => {
        setContacts(results.results);
        setLoadingContacts(false);
      })
      .catch((err) => {
        setLoadingContacts(false);
      });
  }, [accountSlug]);

  useEffect(() => {
    loadAccount();
    // loadContacts();
  }, [loadAccount, loadContacts, accountSlug]);

  if (error) {
    return (
      <div className="m-5 text-center">
        <h6>This account is not available</h6>
        <p className="mb-3 normal-text semi-bold gray-500">
          The link may be broken, or the account may have been removed.
        </p>
      </div>
    );
  }

  if (loading || !account) {
    return <LoadingRing className="m-5" />;
  }

  const { accountOwners, contactsCount } = account;

  return (
    <div className="d-flex flex-column px-3">
      <div className="w-100 d-flex flex-row gap-20 pb-5 page-top-padding">
        <CompanyLogo size={80} name={account.name} domain={account.website} className="" />
        <div className="d-flex flex-column flex-fill gap-10">
          <div className="d-flex flex-row align-items-center gap-5">
            <h3 className="m-0 bold gray-700 fs-mask">{account.name}</h3>
            <AccountHiddenIcon account={account} />
            <AccountStatusAlertTooltip account={account} withExpires />
            <div>{account.status}</div>
            {account.isTarget && (
              <Pill
                icon={<FontAwesomeIcon icon="bullseye" width="14" height="14" />}
                color="gray"
                label={account.isTargetValue}
              />
            )}
          </div>
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="normal-text semi-bold gray-500">Owners:</span>
            {accountOwners?.map((owner: AccountOwner, index) => (
              <React.Fragment key={owner.id}>
                <Link
                  to={`/network/oid/${owner.orguserId}`}
                  className="normal-text semi-bold gray-700 btn-link fs-mask"
                >
                  {owner.fullName}
                </Link>
                {index !== accountOwners.length - 1 && <h3 className="m-0 p-0 lh-10">·</h3>}
              </React.Fragment>
            ))}
          </div>
          <div>
            <a
              href={`https://${account.website}`}
              target="_blank"
              rel="noopener noreferrer"
              className="normal-text semi-bold gray-700 fs-mask"
            >
              {account.website}
            </a>
          </div>
        </div>
        <div>
          <Link
            to={{ pathname: '/requests/all', search: `?account=${account.slug}` }}
            className="btn btn-secondary gray-700"
          >
            <FontAwesomeIcon icon="comment-dots" className="mr-2" />
            View Requests
          </Link>
        </div>
      </div>
      {loggedInOrguser.canManage && <Suggestions accountOwners={account?.accountOwners} />}
      {false && (
        <>
          <div className="mb-2 mt-3 d-flex flex-row align-items-center gap-15">
            <h5 className="m-0 bold gray-700">Contacts</h5>
            <Link
              className="normal-text bold"
              to={`/a/${account.slug}/contacts`}
            >{`See All (${contactsCount})`}</Link>
          </div>
          {loadingContacts ? (
            <LoadingRing maxWidth="50px" />
          ) : (
            <>
              {!contacts.length ? (
                <div className="text-center">
                  <h6>No contacts found.</h6>
                </div>
              ) : (
                <HorizontalScroll>
                  {contacts.map((contact, index) => (
                    <ContactCard
                      itemId={index}
                      key={contact.email}
                      accountSlug={accountSlug}
                      contact={contact}
                    />
                  ))}
                  <Link
                    className="normal-text bold card m-0 card-body h-100 align-items-center justify-content-center"
                    style={{ maxWidth: '200px', width: '200px' }}
                    to={`/a/${account.slug}/contacts`}
                  >
                    {`See All (${contactsCount})`}
                  </Link>
                </HorizontalScroll>
              )}
            </>
          )}
        </>
      )}
      {isIntegrationSetup ? (
        <Content account={account} />
      ) : (
        <FinishSetupOverlay title="Map your partner populations to see overlaps and message partners" />
      )}
    </div>
  );
};

export default withOrguser(RequestAccountDetail);
