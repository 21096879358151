// @flow

import * as React from 'react';

import { Org, OrgUser } from 'data/entities';

import BareLayout from 'layouts/BareLayout';
import SlackIntegrationButton from 'components/SlackIntegrationButton';

type Props = {
  org: Org,
  orguser: OrgUser,
  done: () => void,
};

const Header = () => (
  <div className="pt-5 text-center">
    <div className="pt-5">
      <h3>Connect Slack</h3>
      <h5>Connect with Slack to get the most out of Crossbeam for Sales!</h5>
    </div>
  </div>
);

export default function SetupSlack({ org, orguser, done }: Props) {
  if (org.hasSlackIntegration || !orguser.capabilities.canAccessSeatedExperience) {
    done();
    return null;
  }
  return (
    <BareLayout centered afterPageContainer={<Header />}>
      <div className="SetupSlackIntegration" style={{ maxWidth: '600px' }}>
        <div className="d-flex justify-content-center">
          <SlackIntegrationButton />
        </div>
        <br />
        <br />
        <ul className="fs-14">
          <li>
            <code>/partners</code> slash command to lookup partners and accounts on the fly
          </li>
          <li>Alerts for your sales team and partner managers</li>
          <li>Crossbeam for Sales conversations in shared Slack channels</li>
        </ul>
        <div className="mt-3 text-center">
          <button className="btn btn-sm btn-link" type="button" onClick={done}>
            Skip
          </button>
        </div>
      </div>
    </BareLayout>
  );
}
