// @flow

import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  INVALID_CREDENTIALS_STATUS,
  NEW_STATUS,
  SCHEMA_SYNCED_STATUS,
  UNAUTHORIZED_CREDENTIALS_STATUS,
  UNAUTHORIZED_INTEGRATION_MESSAGE,
} from 'data/entities/chug';
import Org from 'data/entities/org';
import OrgUser, { OrgUserRoles } from 'data/entities/orguser';
import User from 'data/entities/user';
import withOrguser from 'contexts/withOrguser';

import AdminRoutes from 'layouts/AdminRoutes';
import BaseContainer from 'layouts/BaseLayout/BaseContainer';
import PrimaryLayoutFooter from 'layouts/PrimaryLayout/PrimaryLayoutFooter';
import PrimaryLayoutHeader from 'layouts/PrimaryLayout/PrimaryLayoutHeader';
import { navigatorDefaultUrl } from 'layouts/PrimaryLayout/PrimaryNavbar';
import AccountDetail from 'views/AccountDetail';
import AccountList from 'views/AccountList';
import BdRequests from 'views/BdRequests';
import ConnectExtension from 'views/ConnectExtension';
import GetStarted from 'views/GetStarted';
import InviteAccepted from 'views/InviteAccepted';
import Network from 'views/Network';
import NetworkProfile from 'views/NetworkProfile';
import PartnerDetail from 'views/PartnerDetail';
import Settings from 'views/Settings';
import SlackIntegration from 'views/SlackIntegration/SlackIntegration';
import FinishSetup from 'components/SeatlessExperience/FinishSetup';
import SigninWithSlackButton from 'components/SigninWithSlackButton';

import Contact from './Contact';
import ContactsList from './ContactsList';
import DealNavigator from './DealNavigator';
import Lists from './Lists';
import RequestAccountDetail from './RequestAccountDetail';

const RenderRequests = (props): React.Node => {
  const { orguser } = props;
  const params = `?forOrguser=${orguser.id}`;
  const urlPath = `/requests/requested${params}`;
  return <Redirect {...props} to={urlPath} />;
};

const RenderRoot = (props): React.Node => {
  const { orguser } = props;
  return orguser.role === OrgUserRoles.BD || !orguser.capabilities.canAccessSeatedExperience ? (
    <RenderRequests {...props} />
  ) : (
    <Redirect {...props} to={navigatorDefaultUrl()} />
  );
};

// Each route is currently getting orguser/org past along by default. Not all actually need it.
// TODO : Switch to using withOrguser in lower level components, and don't pass it along here.
type RouteProps = {
  orguser: OrgUser,
  org: Org,
  user: User,
};

type RouteDef = {
  path: string | string[],
  component: React.ComponentType<RouteProps>,
  exact: boolean,
};

function WithSearch({ orguser, org, user }: RouteProps) {
  const withSearchRoutes: RouteDef[] = [
    {
      path: '/',
      component: RenderRoot,
      exact: true,
    },
    {
      path: '/getting-started',
      component: GetStarted,
      exact: true,
    },
    {
      path: '/navigator',
      component: DealNavigator,
      exact: true,
    },
    {
      path: '/a',
      component: AccountList,
      exact: true,
    },
    {
      path: '/a/:accountSlug',
      component: org.settings.requestsWorkflow ? RequestAccountDetail : AccountDetail,
      exact: true,
    },
    {
      path: '/a/:accountSlug/contacts',
      component: ContactsList,
      exact: true,
    },
    {
      path: '/a/:accountSlug/contacts/:email',
      component: Contact,
      exact: true,
    },
    {
      path: '/p',
      component: () => <Redirect to="/network/p?status=sync_enabled" />,
      exact: true,
    },
    {
      path: ['/network', '/network/', '/network/p'],
      component: Network,
      exact: true,
    },
    {
      path: '/network/oid/:id',
      component: NetworkProfile,
      exact: false,
    },
    {
      path: '/p/:partnerSlug',
      component: PartnerDetail,
      exact: false,
    },
    {
      path: '/invite-accepted/:inviteId',
      component: InviteAccepted,
      exact: true,
    },
    {
      path: '/slack',
      component: SlackIntegration,
      exact: true,
    },
    {
      path: '/requests',
      component: RenderRequests,
      exact: true,
    },
    {
      path: '/requests/',
      component: BdRequests,
      exact: false,
    },
    {
      path: '/settings',
      component: Settings,
      exact: false,
    },
    {
      path: '/admin',
      component: AdminRoutes,
      exact: false,
    },
    {
      path: '/connect-extension',
      component: ConnectExtension,
      exact: true,
    },
    {
      path: '/lists',
      component: Lists,
      exact: false,
    },
  ];

  return (
    <div>
      {withSearchRoutes.map((r) => (
        <Route
          key={r.path}
          exact={r.exact}
          path={r.path}
          render={(props) => <r.component {...props} orguser={orguser} org={org} user={user} />}
        />
      ))}
    </div>
  );
}

type UserAlertProps = {
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
};
function UserAlerts(props: UserAlertProps) {
  const { loggedInOrguser, loggedInOrg } = props;
  const [showSlackAlertState, setShowSlackAlertState] = React.useState(true);
  if (!(loggedInOrg && loggedInOrguser)) {
    return null;
  }

  const { hasSlackIntegration, hasIntegration, integrationStatus } = loggedInOrg;
  const { hasSlackIdentity, isGhost } = loggedInOrguser;
  const showSlackAlert = hasSlackIntegration && !hasSlackIdentity;
  const showSyncIntegrationAlert = [NEW_STATUS, SCHEMA_SYNCED_STATUS, null].includes(
    integrationStatus
  );

  const IncompleteOnboardingAlert = ({ text }) => (
    <div className="bg-light-orange orange p-3 d-flex align-items-center justify-content-center">
      <div
        className="flex-fill d-flex flex-row align-items-center gap-10"
        style={{ maxWidth: 1250 }}
      >
        <FontAwesomeIcon icon="exclamation-circle" className="orange" size="2x" />
        <div className="flex-fill d-flex flex-column">
          <span className="large-text bold gray-700">{text}</span>
          <span className="normal-text semi-bold gray-600">
            You are one step away from giving your sales team superpowers
          </span>
        </div>
        <FinishSetup />
      </div>
    </div>
  );
  let incompleteOnboardingText = null;
  if (!hasIntegration || integrationStatus === INVALID_CREDENTIALS_STATUS) {
    incompleteOnboardingText = 'Reconnect with Crossbeam';
  }
  if (integrationStatus === UNAUTHORIZED_CREDENTIALS_STATUS) {
    incompleteOnboardingText = UNAUTHORIZED_INTEGRATION_MESSAGE;
  }
  if (showSyncIntegrationAlert || !loggedInOrg.isIntegrationSetup) {
    incompleteOnboardingText = 'Additional information required';
  }
  if (incompleteOnboardingText && loggedInOrguser.capabilities.canAccessSeatedExperience) {
    return <IncompleteOnboardingAlert text={incompleteOnboardingText} />;
  }
  if (
    !isGhost &&
    showSlackAlert &&
    localStorage.getItem('hideSlackAlert') !== 'true' &&
    showSlackAlertState
  ) {
    return (
      <div
        className="bg-light-orange d-flex flex-row align-items-center justify-content-center gap-15"
        style={{ height: '60px' }}
      >
        <div className="w-25" />
        <span className="large-text bold black disable-selection">
          <span className="orange">Missing: </span>Connect your Slack Identity
        </span>
        <SigninWithSlackButton />
        <div className="w-25 text-center">
          <button
            onClick={() => {
              localStorage.setItem('hideSlackAlert', 'true');
              setShowSlackAlertState(false);
            }}
            className="btn btn-link btn-xs"
            type="button"
          >
            <FontAwesomeIcon size="lg" icon="times" className="gray-600" />
          </button>
        </div>
      </div>
    );
  }
  return null;
}

const UserAlertsContainer = withOrguser(UserAlerts);

type Props = {
  orguser: ?OrgUser,
  org: ?Org,
  user: ?User,
};

export default function Platform({ orguser, org, user }: Props) {
  const slackNag = <UserAlertsContainer />;
  return (
    <BaseContainer
      className="Platform"
      header={<PrimaryLayoutHeader />}
      footer={<PrimaryLayoutFooter />}
      afterPageContainer={slackNag}
    >
      <div className="container">
        <Route
          path="/"
          render={(props) => <WithSearch {...props} orguser={orguser} org={org} user={user} />}
        />
      </div>
    </BaseContainer>
  );
}
