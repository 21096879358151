// @flow
import { reduce } from 'lodash';
import { camelCase, snakeCase } from 'lodash/string';

const isNone = (i: any) => i === undefined || i === null;

const isFalsey = (i: any) =>
  isNone(i) || ((typeof i === 'string' || Array.isArray(i)) && i.length === 0);

function skipNull<T: { [string]: any } | any[]>(object: T): T {
  return Array.isArray(object) ? skipNullArr(object) : skipNullObj(object);
}

function skipNullArr(arr: any[], filterFalsey: boolean = false): any[] {
  // Return array minus null keys
  if (!arr) {
    return arr;
  }
  // Default is more strict, a null check. Optionally, looser falsey check
  const skipFn = filterFalsey ? isFalsey : isNone;
  return arr.filter((i) => !skipFn(i));
}

function skipNullObj(object: {}): {} {
  // Return an object minus null keys
  if (!object) {
    return {};
  }
  return Object.keys(object).reduce((newObj, k) => {
    const val = object[k];
    if (!isNone(val)) {
      newObj[k] = val;
    }
    return newObj;
  }, {});
}

function capitalize(text: string): string {
  if (!text || !text.length) {
    return '';
  }
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

function camelizeKeys(obj: any): any {
  // Camelize all top level keys of an object
  return (
    obj && Object.keys(obj).reduce((newObj, key) => ({ ...newObj, [camelCase(key)]: obj[key] }), {})
  );
}

const settingsToSnakeCase = (settings: any): any => {
  const dictToLower = (value): any =>
    reduce(
      value,
      (ac, valueI, key) => {
        ac[snakeCase(key)] = typeof valueI === 'object' && valueI ? dictToLower(valueI) : valueI;
        return ac;
      },
      {}
    );

  return dictToLower(settings);
};

export {
  camelizeKeys,
  capitalize,
  isNone,
  settingsToSnakeCase,
  skipNull,
  skipNullArr,
  skipNullObj,
};
