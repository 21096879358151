// @flow

const styles = {
  minHeight: '6em',

  control: {
    fontWeight: 'normal',
  },

  highlighter: {
    overflow: 'hidden',
  },

  input: {
    margin: 0,
  },

  '&singleLine': {
    control: {
      display: 'inline-block',

      width: 130,
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },

    input: {
      padding: 1,

      border: '2px inset',
    },
  },

  '&multiLine': {
    control: {},

    highlighter: {
      padding: 10,
      lineHeight: '22px',
    },

    input: {
      padding: 10,
      minHeight: 30,
      outline: 0,
      border: 0,
      lineHeight: '22px',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      fontSize: 14,
      borderRadius: 4,
      border: '1px solid #d1dae9',
    },

    item: {
      padding: '5px 15px',
      borderRadius: 4,

      '&focused': {
        color: '#ffffff',
        backgroundColor: '#0079ff',
      },
    },
  },
};

export default styles;
