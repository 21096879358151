// isProductionBuild is true for BOTH staging and production. It is about how it is built, only.
export const isProductionBuild = process.env.NODE_ENV === 'production';

export const isLocalDev = !isProductionBuild;

export const environmentName = process.env.REACT_APP_ENVIRONMENT;

export const isProduction = ['production', 'sales-prod'].includes(environmentName);

export const isStagingOrLocal =
  (!isProduction && environmentName === 'cross-staging') || isLocalDev;

// Only the production environment supports Slack v2 permissions
export const useSlackV2oAuth = true;

export const chromeExtensionID = 'lkknjlkjicihkocknknaaoannijcieph';
export const xbChromeExtensionURL =
  'https://chromewebstore.google.com/detail/crossbeam-copilot-for-chr/helpjbehgodflkpbifaolbjmkjffmeop';

export const PARTNER_IMPORT_LIMIT = 60;
