// @flow

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import type { User } from 'data/entities';
import { Org, OrgUser } from 'data/entities';
import cbLogin from 'data/repositories/auth';
import { getCrossbeamStatus } from 'data/repositories/crossbeam';
import { UserContext } from 'contexts';
import { BentoLoaderProvider } from 'contexts/BentoLoaderContext';
import { OrgUserContextProvider } from 'contexts/OrgUserContext';
import cacheBuster from 'utils/cacheBuster';
import { isLocalDev } from 'utils/environment';
import ScrollToTop from 'utils/ScrollToTop';
import SyncIFrame from 'utils/SyncIframe';
import { identifyOrgUsers } from 'utils/tracking';
import authenticate from 'authFetch/authenticate';
import { ErrorResponse } from 'authFetch/entities';
import { setAuthToken } from 'authFetch/utils';
import type { LocationT, RouterHistoryT } from 'sharedTypes/reactRouter';

import Auth from 'views/Auth';
import AuthBasic from 'views/AuthBasic';
import BdRequestQuestions from 'views/BdRequestQuestions';
import CompleteProfile from 'views/CompleteProfile';
import CompleteSignup from 'views/CompleteSignup';
import ImportPartners from 'views/Crossbeam/ImportPartners';
import StandardPopulations from 'views/Crossbeam/StandardPopulations';
import EmailVerification from 'views/EmailVerification';
import ForgetPassword from 'views/ForgetPassword';
import HandleInvite from 'views/HandleInvite';
import Logout from 'views/Logout';
import MyOrgUsers from 'views/MyOrgUsers';
import NoAccess from 'views/NoAccess';
import CrossbeamData from 'views/Oauth/CrossbeamData';
import SalesforceData from 'views/Oauth/SalesforceData';
import SalesforceDataSuccess from 'views/Oauth/SalesforceDataSuccess';
import SalesforceLogin from 'views/Oauth/SalesforceLogin';
import SlackIntegration from 'views/Oauth/SlackIntegration';
import SlackLogin from 'views/Oauth/SlackLogin';
import WorkOSCallback from 'views/Oauth/WorkOSCallback';
import Onboarding from 'views/Onboarding';
import Platform from 'views/Platform';
import ResetPassword from 'views/ResetPassword';
import PartnerSlackChannels from 'views/SlackIntegration/PartnerSlackChannels';
import SlackSignIn from 'views/SlackSignIn';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import BentoSideBar from 'components/BentoSideBar';
import FullPageLoadingRing from 'components/FullPageLoadingRing';
import Messaging from 'components/Messaging';

// Must be imported to initialize font-awesome.
import 'assets/icons';

import { SlackChannelProvider } from './contexts/SlackChannelContext';
import { isInIframe } from './utils/useInIframe';
import MasterRecordRedirect from './views/MasterRecordRedirect';
import CrossbeamDataRedirect from './views/Oauth/CrossbeamDataRedirect';
import HubspotIntegration from './views/Oauth/HubspotIntegration';

/*
   To start, load routes and high level User handling here
 */

const logPageview = () => {
  if (!isLocalDev && window?.analytics) {
    window.analytics.page();
  }
};

type State = {
  orguser: ?OrgUser,
  user: ?User,
  org: ?Org,
  isAuthenticating: boolean,
  noAccess: boolean,
  isAuthenticated: boolean,
  showOrgPicker: boolean,
};

const nullState = {
  user: null,
  orguser: null,
  isAuthenticating: true,
  noAccess: false,
  isAuthenticated: false,
  org: null,
  showOrgPicker: false,
};

type Props = {
  history: RouterHistoryT,
  location: LocationT,
};

const NO_ACCESS_ERRORS = ['No Crossbeam for Sales access.', 'No Sales Edge access.'];

class App extends React.Component<Props, State> {
  state: State = {
    ...nullState,
  };

  componentDidMount() {
    // Check if in iframe.
    document.body?.classList.add(isInIframe() ? 'in-iframe' : 'regular-theme');

    // Check if there is a new version available.
    cacheBuster();
    const queryParams = new URLSearchParams(window.location.search);
    const loginOrg = queryParams.get('login-org') || localStorage.getItem('login-org');

    const parent = this;
    // Check crossbeam API status first for maintenance
    getCrossbeamStatus()
      .then((crossbeamStatus) => {
        if (crossbeamStatus?.API.isInMaintenance) {
          window.location.replace(process.env.REACT_APP_CROSSBEAM_URL);
        } else {
          parent.doLogin(loginOrg);
        }
      })
      .catch(() => parent.doLogin(loginOrg));
  }

  doLogin = (loginOrgUUID, doOnboarding = false) => {
    const parent = this;
    cbLogin(loginOrgUUID)
      .then((data) => {
        const { token } = data;
        setAuthToken(token);
        parent.doAuthenticate(doOnboarding);
        return { token };
      })
      .catch((errors: ErrorResponse) => {
        parent.authenticationFailed(
          errors.json?.detail && NO_ACCESS_ERRORS.includes(errors.json?.detail)
        );
      });
  };

  updateOrg = (org: Org, orguser: OrgUser, redirect: ?string = null) => {
    const asOrguserId = orguser ? orguser.id : undefined;
    this.setState({ org }, () => this.doAuthenticate(true, redirect, asOrguserId));
  };

  updateUser = (user: User): Promise<void> =>
    new Promise((resolve) => {
      this.setState({ user }, () => resolve());
    });

  doAuthenticate = (
    doOnboarding: boolean = false,
    historyPushArgs: ?string = null,
    asOrguser: ?string = null
  ) => {
    const {
      location: { pathname },
      history,
    } = this.props;
    // Returns a promise that always resolves, but with { success: true/false }
    // It "always resolves" to avoid unhandled promise rejection, since we most first this off without waiting for it.
    return new Promise((resolve) => {
      this.setState({ isAuthenticating: true }, () =>
        authenticate(asOrguser)
          .then(({ user, org, orguser, intercomHash }) => {
            const authState = user ? { isAuthenticated: true, isAuthenticating: false } : {};
            const done = () => {
              if (historyPushArgs && !(historyPushArgs === '/' && doOnboarding)) {
                // Redirect root url to onboarding check if user has canManagePartnerships cap
                this.props.history.replace(historyPushArgs);
              } else if (doOnboarding || pathname === '/') {
                this.props.history.replace('/');
              }
              this.setState({ ...authState, showOrgPicker: false });
              resolve({ success: true, user, org, orguser });
            };
            // Identify user to Segment, Sentry
            const shouldTrack = identifyOrgUsers(orguser, org, intercomHash);
            if (shouldTrack) {
              history.listen(logPageview);
              logPageview();
            }
            // Save logged in org
            localStorage.setItem('login-org', org.sourceUuid);
            this.setState({ user, org, orguser, showOrgPicker: false }, done);
          })
          .catch(() => {
            this.authenticationFailed();
            resolve({ success: false });
          })
      );
    });
  };

  authenticationFailed = (noAccess: boolean = false) => {
    this.setState({ ...nullState, isAuthenticating: false, showOrgPicker: false, noAccess });
  };

  render() {
    const {
      user,
      org,
      orguser,
      isAuthenticated,
      isAuthenticating,
      showOrgPicker,
      noAccess,
    } = this.state;
    const authProps = { user, isAuthenticated, isAuthenticating, org, orguser };

    if (isAuthenticating) {
      return <FullPageLoadingRing text="Logging in..." />;
    }

    if (showOrgPicker) {
      return <MyOrgUsers doLogin={this.doLogin} />;
    }

    if (noAccess) {
      return (
        <NoAccess
          onClick={() => {
            this.setState({ noAccess: false, showOrgPicker: true });
          }}
        />
      );
    }
    const AuthenticatedCurry = (props) => (
      <AuthenticatedRoute
        user={user}
        isLoading={isAuthenticating}
        org={org}
        orguser={orguser}
        {...props}
      />
    );
    return (
      <UserContext.Provider value={{ user, updateUser: this.updateUser }}>
        <OrgUserContextProvider org={org} orguser={orguser}>
          <SlackChannelProvider orguser={orguser}>
            <BentoSideBar />
            {isAuthenticated && orguser && !org?.settings?.requestsWorkflow && (
              <Messaging {...authProps} />
            )}
            <BentoLoaderProvider org={org} orguser={orguser}>
              <ScrollToTop />
              <SyncIFrame />
              <Switch>
                <Route
                  path="/login"
                  exact
                  render={(props) => <Auth {...props} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/join"
                  exact
                  render={(props) => <AuthBasic doLogin={this.doAuthenticate} {...props} />}
                />
                <Route
                  path="/email/verify/:token"
                  exact
                  render={(props) => (
                    <EmailVerification
                      doLogin={this.doAuthenticate}
                      {...props}
                      isAuthenticated={isAuthenticated}
                    />
                  )}
                />
                <Route
                  path="/forget-password"
                  exact
                  render={(props) => (
                    <ForgetPassword {...props} isAuthenticated={isAuthenticated} />
                  )}
                />
                <Route
                  path="/password-reset"
                  exact
                  render={(props) => <ResetPassword {...props} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/oauth/salesforce-login"
                  exact
                  component={(props) => (
                    <SalesforceLogin doLogin={this.doAuthenticate} {...props} />
                  )}
                />
                <Route
                  path="/oauth/sso-login"
                  exact
                  component={(props) => <WorkOSCallback doLogin={this.doAuthenticate} {...props} />}
                />
                <Route
                  path="/invite/:inviteId"
                  render={(props) => <HandleInvite org={org} orguser={orguser} {...props} />}
                />
                <Route
                  path="/logout"
                  exact
                  render={(props) => <Logout onLogout={this.authenticationFailed} {...props} />}
                />
                {/* Cover in authentication */}
                <AuthenticatedCurry
                  path="/my-orgs"
                  render={(props) => <MyOrgUsers {...props} doLogin={this.doLogin} />}
                  isSignup
                  exact
                />
                <AuthenticatedCurry
                  path="/complete-signup"
                  render={(props) => <CompleteSignup updateOrg={this.updateOrg} {...props} />}
                  isSignup
                />
                <AuthenticatedCurry
                  path="/complete-profile"
                  render={(props) => <CompleteProfile {...props} />}
                  isSignup
                  exact
                />
                <AuthenticatedCurry
                  path="/onboarding"
                  render={(props) => <Onboarding doLogin={this.doAuthenticate} {...props} />}
                />
                <AuthenticatedCurry
                  path="/slack-sign-in"
                  exact
                  render={(props) => <SlackSignIn org={org} orguser={orguser} {...props} />}
                />
                <AuthenticatedCurry
                  path="/oauth/salesforce-data"
                  exact
                  render={(props) => <SalesforceData doLogin={this.doAuthenticate} {...props} />}
                />
                <AuthenticatedCurry
                  path="/oauth/salesforce-data-success"
                  exact
                  component={SalesforceDataSuccess}
                />
                <AuthenticatedCurry
                  path="/oauth/slack-integration"
                  exact
                  component={SlackIntegration}
                />
                <AuthenticatedCurry
                  path="/oauth/hubspot-integration"
                  exact
                  component={HubspotIntegration}
                />
                <AuthenticatedCurry path="/oauth/slack-login" exact component={SlackLogin} />
                <AuthenticatedCurry
                  path="/oauth/crossbeam-data"
                  exact
                  render={(props) => (
                    <CrossbeamDataRedirect doLogin={this.doAuthenticate} {...props} />
                  )}
                />
                <AuthenticatedCurry
                  path="/oauth/crossbeam-data/received"
                  exact
                  render={(props) => <CrossbeamData doLogin={this.doAuthenticate} {...props} />}
                />
                <AuthenticatedCurry
                  path="/oauth/crossbeam-populations"
                  exact
                  render={(props) => <StandardPopulations {...props} />}
                />
                <AuthenticatedCurry
                  path="/crossbeam"
                  exact
                  render={(props) => <ImportPartners {...props} />}
                />
                <AuthenticatedCurry
                  path="/co-selling-templates"
                  exact
                  render={(props) => <BdRequestQuestions {...props} />}
                />
                <AuthenticatedCurry
                  path="/slack-channels"
                  exact
                  render={(props) => <PartnerSlackChannels {...props} />}
                />
                <AuthenticatedCurry
                  path="/crossbeam-core/account/:masterRecordUUID"
                  exact
                  render={(props) => <MasterRecordRedirect {...props} />}
                />
                {AuthenticatedCurry({ path: '/', render: () => <Platform {...authProps} /> })}
              </Switch>
            </BentoLoaderProvider>
          </SlackChannelProvider>
        </OrgUserContextProvider>
      </UserContext.Provider>
    );
  }
}

export default Sentry.withProfiler(App);
