// @flow

class PartnerOrg {
  id: string;

  name: string;

  description: string;

  slug: string;

  pseudoOrgForPartnerId: ?string;

  logoUrl: ?string;

  logoAvatar: ?string;

  domain: string;

  isConnected: ?boolean;

  partnerSlug: ?string;

  static fromApi: (data: any, slug?: string) => PartnerOrg;
}

PartnerOrg.fromApi = (data: any, slug?: string): PartnerOrg => {
  const po = new PartnerOrg();
  po.id = data.id;
  po.name = data.name;
  po.slug = slug || data.slug; // We need to comprehensively remove slug from partnerOrg
  po.pseudoOrgForPartnerId = data.pseudo_org_for_partner_id;
  po.description = data.description;
  po.logoUrl = data.logo_url;
  po.logoAvatar = data.logo_avatar;
  po.domain = data.domain;
  po.isConnected = data.is_connected;
  po.partnerSlug = data.partner_slug;
  return po;
};

export default PartnerOrg;
