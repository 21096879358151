// @flow

import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';

import { Org, Partner, SuggestedAccountMap } from 'data/entities';
import { OrgUserContext } from 'contexts/OrgUserContext';
import { FadeInTransition } from 'utils/animate';

import PredictionRow from 'views/AccountMapping/components/PredictionRow';
import InfoTip from 'components/InfoTip';

type Props = {
  org: Org,
  predictions: SuggestedAccountMap[],
  hasNext: ?boolean,
  nextAction: () => void,
  onApprove: (map: SuggestedAccountMap, accountId: number, asTarget: boolean) => Promise,
  isRequests?: boolean,
  isPending?: boolean,
  onUpdate?: () => void,
  onDecline?: (SuggestedAccountMap) => void,
  partner: Partner,
};

const targetIdMaker = (id, modifier) => `${id}-${modifier}`;

function AccountMappingTable(props: Props) {
  const {
    predictions: preds,
    hasNext,
    nextAction,
    onApprove,
    isRequests,
    isPending,
    onUpdate,
    org,
    partner,
    onDecline,
  } = props;
  const predictions = preds && preds.length ? preds : [];
  return (
    <div className="AccountMappingTable">
      <table className="table table-responsive-lg table-condensed table-striped table-borderless">
        <thead>
          <tr>
            <th>
              <em>&quot;Your Account&quot;</em> is <em>&quot;Partner Account&quot;</em>
              &nbsp;
              <InfoTip targetId={targetIdMaker('accounts', isRequests)}>
                Your account and accounts from your partner’s CRM that may map with your account.{' '}
                <br />
                Choose the correct Partner Account and <strong>Approve</strong> it.
              </InfoTip>
            </th>
            <th>
              {!isPending && (
                <>
                  Approve &nbsp;
                  <InfoTip targetId={targetIdMaker('approve', isRequests)}>
                    <>
                      Approve mapping your account with the selected partner account. <br />
                      Once also approved by your partner you will see each others{' '}
                      <strong>account status</strong> and <strong>account owners.</strong>
                    </>
                  </InfoTip>
                </>
              )}
            </th>
            <th className="text-center">Partner Visible Info</th>
          </tr>
        </thead>
        <tbody>
          <TransitionGroup component={null}>
            {predictions.map((i) => (
              <FadeInTransition key={i.account.name + i.partnerOrg.name}>
                <PredictionRow
                  prediction={i}
                  onApprove={onApprove}
                  isPending={isPending}
                  onUpdate={onUpdate}
                  org={org}
                  partner={partner}
                  onDecline={onDecline}
                />
              </FadeInTransition>
            ))}
          </TransitionGroup>
        </tbody>
      </table>
      {hasNext && (
        <button
          onClick={nextAction}
          className="btn btn-default btn-block mt-3"
          type="button"
          style={{ width: 'calc(100% - 10px)', margin: '0 auto' }}
        >
          Load More
        </button>
      )}
    </div>
  );
}

AccountMappingTable.defaultProps = {
  isRequests: false,
  isPending: false,
  onUpdate: () => {},
  onDecline: () => {},
};

export default function AccountMappingTableContainer(props) {
  return (
    <OrgUserContext.Consumer>
      {({ org }) => <AccountMappingTable org={org} {...props} />}
    </OrgUserContext.Consumer>
  );
}
