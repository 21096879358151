// @flow

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org } from 'data/entities';
import { updateRequestWorkflowSettings } from 'data/repositories/orgs';
import withOrguser from 'contexts/withOrguser';

import BareLayout from 'layouts/BareLayout';

import { Step } from './constants';
import Questions from './Questions';
import Steps from './Steps';
import TalkingPoints from './TalkingPoints';

type Props = { onboarding?: () => void, loggedInOrg: Org, refreshOrg: () => void };

const BdRequestQuestions = ({ onboarding, loggedInOrg, refreshOrg }: Props) => {
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(Step.QUESTIONS);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);

  const [talkingPoints, setTalkingPoints] = useState<string[]>(
    loggedInOrg.settings.requestsWorkflowSettings.talkingPoints
  );
  const [allowCustomQuestions, setAllowCustomQuestions] = useState(
    loggedInOrg.settings.requestsWorkflowSettings.allowCustomQuestions
  );
  const [hasQuestions, setHasQuestions] = useState(false);

  const nextStep = () => {
    // Go to next step
    if (activeStep === Step.QUESTIONS) {
      setActiveStep(Step.TALKING_POINTS);
      return;
    }

    // Save settings
    setSubmitting(true);
    setErrors(null);
    updateRequestWorkflowSettings(allowCustomQuestions, talkingPoints)
      .then(() => {
        refreshOrg();
        if (onboarding) {
          onboarding();
        } else {
          history.push('/settings/');
        }
      })
      .catch((e) => {
        setErrors(e.json?.requestsWorkflowSettings ?? null);
        setSubmitting(false);
      });
  };

  const hasEmptyTalkingPoints =
    activeStep === Step.TALKING_POINTS && talkingPoints.some((point) => point.trim() === '');
  const hasDuplicateTalkingPoints =
    new Set(talkingPoints.map((t) => t.toLowerCase())).size !== talkingPoints.length;
  const hasQuestionsForAll = allowCustomQuestions || hasQuestions;
  const canProceed =
    hasQuestionsForAll &&
    (activeStep === Step.QUESTIONS ||
      (talkingPoints.length > 0 && !hasEmptyTalkingPoints && !hasDuplicateTalkingPoints));

  return (
    <>
      <BareLayout centered>
        <div className="d-flex flex-column align-items-center gap-30" style={{ minHeight: '95vh' }}>
          <div className="text-center">
            <span className="large-text bold gray-500">
              Help your sales team effectively co-sell with partners
            </span>
            <h3 className="text-black bold mb-0">
              Create your sales team&apos;s Co-Selling Templates
            </h3>
          </div>
          <div
            className="d-flex flex-column align-items-center gap-30 flex-1"
            style={{ width: 650 }}
          >
            {/* Help link */}
            {false && (
              <div className="w-100 d-flex flex-row align-items-center gap-5 blue">
                <FontAwesomeIcon icon={['far', 'question-circle']} />
                <a
                  href="https://crossbeam.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="normal-text bold blue text-decoration-underline"
                >
                  How do I correctly setup my sales team&apos;s Co-Selling process?
                </a>
              </div>
            )}

            <Steps activeStep={activeStep} setActiveStep={setActiveStep} />

            {activeStep === Step.QUESTIONS && (
              <Questions
                allowCustomQuestions={allowCustomQuestions}
                setAllowCustomQuestions={setAllowCustomQuestions}
                setHasQuestions={setHasQuestions}
              />
            )}
            {activeStep === Step.TALKING_POINTS && (
              <TalkingPoints
                talkingPoints={talkingPoints}
                setTalkingPoints={setTalkingPoints}
                errors={errors?.talking_points ?? null}
                hasDuplicates={hasDuplicateTalkingPoints}
                hasEmpty={hasEmptyTalkingPoints}
              />
            )}
          </div>

          {/* Footer */}
          <div className="crossbeam-footer text-center">
            <div className="d-flex flex-row align-items-center gap-20" style={{ width: 800 }}>
              {!hasQuestionsForAll && (
                <span className="normal-text semi-bold orange">
                  Add at least one question allowed for all partners or allow custom questions.
                </span>
              )}
              {activeStep === Step.TALKING_POINTS && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setActiveStep(Step.QUESTIONS)}
                >
                  Go back
                </button>
              )}
              <div className="flex-fill" />
              <button
                type="button"
                className="btn btn-primary"
                onClick={nextStep}
                disabled={!canProceed || submitting}
              >
                {activeStep === Step.QUESTIONS || onboarding ? 'Continue' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      </BareLayout>
    </>
  );
};

BdRequestQuestions.defaultProps = {
  onboarding: null,
};

export default withOrguser(BdRequestQuestions);
