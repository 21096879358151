// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ListType, { ListAccountPartner } from 'data/entities/lists';
import { fetchList, fetchListAccountPartners, toggleListFavorite } from 'data/repositories/lists';

import AccountPartnerCard from 'views/RequestAccountDetail/AccountPartnerCard';
import CompanyLogo from 'components/CompanyLogo';
import Copy from 'components/Copy';
import LoadingRing from 'components/LoadingRing';
import PersonAvatar from 'components/PersonAvatar';

import DeleteList from './DeleteList';
import EditListModal from './EditListModal';

const List = () => {
  const history = useHistory();
  const { id } = useParams();

  const [list, setList] = useState<ListType | null>(null);
  const [loadingList, setLoadingList] = useState(true);
  const [accountPartners, setAccountPartners] = useState<ListAccountPartner[]>([]);
  const groupedAccounts = useMemo(() => {
    const grouped = {};
    accountPartners.forEach((ap) => {
      const { account } = ap;
      if (!grouped[account.slug]) {
        grouped[account.slug] = [ap];
      } else {
        grouped[account.slug].push(ap);
      }
    });

    return Object.values(grouped);
  }, [accountPartners]);

  const partnersByAccounts = useMemo(
    () =>
      groupedAccounts.map((aps) => {
        const groupedByPartner = {};
        aps.forEach((ap) => {
          const { partner } = ap;
          if (!groupedByPartner[partner.slug]) {
            groupedByPartner[partner.slug] = [ap];
          } else {
            groupedByPartner[partner.slug].push(ap);
          }
        });
        return Object.values(groupedByPartner);
      }),
    [groupedAccounts]
  );
  const [loadingAccountPartners, setLoadingAccountPartners] = useState(true);

  const getList = useCallback(() => {
    setLoadingList(true);
    fetchList(id)
      .then((l) => {
        setLoadingList(false);
        setList(l);
      })
      .catch((error) => {
        setLoadingList(false);
      });
  }, [id]);

  useEffect(() => {
    getList();

    setLoadingAccountPartners(true);
    fetchListAccountPartners(id)
      .then(({ results }) => {
        setLoadingAccountPartners(false);
        setAccountPartners(results);
      })
      .catch((error) => {
        setLoadingAccountPartners(false);
      });
  }, [id, getList]);

  const [submitting, setSubmitting] = useState(false);
  const toggleFavorite = () => {
    if (!submitting && list) {
      setSubmitting(true);
      toggleListFavorite(id, !list.isFavorite)
        .then(() => {
          setSubmitting(false);
          setList((l) => ({ ...l, isFavorite: !l.isFavorite }));
        })
        .catch((error) => {
          setSubmitting(false);
        });
    }
  };

  const [editModalOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = () => setEditModalOpen((s) => !s);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalOpen((s) => !s);

  if (!loadingList && !list) {
    return (
      <div className="m-5 text-center">
        <h6>This list is not available</h6>
        <p className="mb-3 normal-text semi-bold gray-500">
          The link may be broken, or the list may have been removed.
        </p>
        <button className="btn btn-primary btn-sm" type="button" onClick={history.goBack}>
          Go back
        </button>
      </div>
    );
  }

  if (loadingList) {
    return <LoadingRing className="m-5" maxWidth="50px" />;
  }

  return (
    <>
      <EditListModal
        open={editModalOpen}
        toggle={toggleEditModal}
        list={list}
        onUpdate={() => getList()}
      />
      <DeleteList open={deleteModalOpen} toggle={toggleDeleteModal} listId={list?.id} />
      <div className="w-100 d-flex flex-column gap-10 page-top-margin">
        <div className="d-flex flex-row align-items-center gap-10">
          <h3 className="m-0 flex-fill bold gray-700 text-truncate">{list?.name}</h3>
          <button
            type="button"
            className="btn btn-secondary px-2"
            disabled={submitting}
            onClick={toggleFavorite}
          >
            <FontAwesomeIcon
              cursor="pointer"
              icon={{ prefix: list?.isFavorite ? 'fas' : 'far', iconName: 'star' }}
              className={list?.isFavorite ? 'yellow' : 'gray-600'}
              title="Toggle favorite"
            />
          </button>
          <Copy text={window.location} title="Copy Link" secondary showTitle />
          <UncontrolledDropdown>
            <DropdownToggle color="secondary">
              <FontAwesomeIcon icon="ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu right style={{ width: 150 }}>
              <DropdownItem onClick={toggleEditModal}>
                <FontAwesomeIcon icon="pencil" className="mr-2" />
                Edit
              </DropdownItem>
              <DropdownItem onClick={toggleDeleteModal} className="red">
                <FontAwesomeIcon icon="trash-alt" className="mr-2" />
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {list?.description && <span className="large-text bold gray-500">{list.description}</span>}
        <div className="d-flex flex-row align-items-center gap-30">
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="normal-text semi-bold gray-500">Assigned To:</span>
            <PersonAvatar
              firstName={list?.assignedTo.firstName}
              lastName={list?.assignedTo.lastName}
              avatar={list?.assignedTo.avatarImage}
              size={28}
            />
            <Link
              to={`/network/oid/${list?.assignedTo.id || ''}`}
              className="normal-text bold gray-700"
            >
              {list?.assignedTo.fullName}
            </Link>
          </div>
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="normal-text semi-bold gray-500">Created By:</span>
            <PersonAvatar
              firstName={list?.createdBy.firstName}
              lastName={list?.createdBy.lastName}
              avatar={list?.createdBy.avatarImage}
              size={28}
            />
            <Link
              to={`/network/oid/${list?.createdBy.id || ''}`}
              className="normal-text bold gray-700"
            >
              {list?.createdBy.fullName}
            </Link>
          </div>
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="normal-text semi-bold gray-500">Created On:</span>
            <span className="normal-text bold gray-700">
              {list?.createdAt.toLocaleDateString()}
            </span>
          </div>
        </div>
        <div className="mt-3 d-flex flex-column gap-30">
          {loadingAccountPartners ? (
            <LoadingRing className="m-5" maxWidth="50px" />
          ) : (
            <>
              {!groupedAccounts.length ? (
                <div className="m-5 text-center">
                  <h5 className="m-0 text-center bold black">No Items added to this lists</h5>
                  <span className="text-center normal-text semi-bold gray-500">
                    Mappings that you add to this list will show here.
                  </span>
                </div>
              ) : (
                groupedAccounts.map((ap, index) => {
                  const accountPartner = ap[0];
                  return (
                    <div className="d-flex flex-column gap-15" key={accountPartner.id}>
                      {(index === 0 ||
                        accountPartners[index - 1].account.slug !==
                          accountPartner.account.slug) && (
                        <div className="d-flex flex-row align-items-center gap-15">
                          <CompanyLogo
                            name={accountPartner.account.name}
                            domain={accountPartner.account.website}
                            size={48}
                            className=""
                          />
                          <div className="flex-fill d-flex flex-column">
                            <Link
                              to={`/a/${accountPartner.account.slug}`}
                              className="large-text bold gray-700"
                            >
                              {accountPartner.account.name}
                            </Link>
                            <a
                              href={accountPartner.account.website}
                              target="_blank"
                              className="small-text bold gray-500"
                            >
                              {accountPartner.account.website}
                            </a>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="small-text semi-bold gray-500 mr-2">
                              Account Owners:
                            </span>
                            {!!accountPartner.accountOwners &&
                              accountPartner.accountOwners.slice(0, 3).map((owner, i) => (
                                <React.Fragment key={owner.email}>
                                  {i !== 0 && <h3 className="mx-1 my-0 p-0 lh-10">·</h3>}
                                  <Link
                                    to={`/network/oid/${owner.orguserId}`}
                                    className="small-text bold blue"
                                  >
                                    {owner.fullName}
                                  </Link>
                                </React.Fragment>
                              ))}
                          </div>
                        </div>
                      )}
                      {partnersByAccounts[index].map((partnerAccounts) => (
                        <AccountPartnerCard
                          list={list}
                          isLoaded
                          account={accountPartner.account}
                          accountPartners={partnerAccounts}
                          style={{ width: '100%' }}
                          key={`${accountPartner.account.slug}-${partnerAccounts[0].partner.slug}`}
                          onDelete={(accountPartnerId) =>
                            setAccountPartners((a) => a.filter((v) => v.id !== accountPartnerId))
                          }
                        />
                      ))}
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default List;
