// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pill from './Pill';

const TargetLabel = ({ isMyTarget, isPartnerTarget }) => {
  let color;
  let text;
  switch ([Number(isMyTarget), Number(isPartnerTarget)].join('')) {
    case '01':
      color = 'gray';
      text = 'Partner';
      break;
    default:
      return null;
  }
  return (
    <Pill
      icon={<FontAwesomeIcon icon="bullseye" width="14" height="14" />}
      color={color}
      label={text}
    />
  );
};

export default TargetLabel;
