// @flow

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, withRouter } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import FieldErrors from 'components/FieldErrors';

import authFetch from '../../authFetch/authFetch';
import { ErrorResponse } from '../../authFetch/entities';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

type Props = {
  email: ?string,
  acceptedInvite: ?string,
};

type State = {
  submitting: boolean,
  email: string,
  first_name: string,
  last_name: string,
  password: string,
  ErrorMessage: { [key: string]: string },
  success_message: ?string,
  captcha: ?string,
  acceptedInvite: ?string,
};

class SignupForm extends React.Component<Props, State> {
  recaptchaRef: typeof ReCAPTCHA;

  constructor(props: Props) {
    super(props);
    this.state = {
      submitting: false,
      email: this.props.email || '',
      first_name: '',
      last_name: '',
      password: '',
      captcha: null,
      acceptedInvite: this.props.acceptedInvite,
      ErrorMessage: {},
      success_message: null,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount = () => {
    this.recaptchaRef.current.execute();
  };

  onInputchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onCaptchaChange = (CaptchaValue) => {
    if (CaptchaValue === null) {
      this.recaptchaRef.current.execute();
      return;
    }
    this.setState({
      captcha: CaptchaValue,
    });
  };

  onSignup = (e) => {
    e.preventDefault();
    const body: {
      email: string,
      password: string,
      first_name: string,
      last_name: string,
      captcha: any,
      accepted_invite_code?: string,
    } = {
      email: this.state.email,
      password: this.state.password,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      captcha: this.state.captcha,
    };
    if (this.state.acceptedInvite) {
      body.accepted_invite_code = this.state.acceptedInvite;
    }
    const register = () =>
      authFetch('auth/user/register/', { method: 'POST', body })
        .then(() => {
          this.setState({
            submitting: false,
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            ErrorMessage: {},
            success_message:
              'Success! Check your inbox and click the link in the verification email to get started.',
          });
          return {};
        })
        .catch(({ json }: ErrorResponse) => {
          this.recaptchaRef.current.reset();
          let newJson: { [key: string]: string } = json;
          if (json.nonFieldErrors) {
            newJson = json.nonFieldErrors;
          } else if (json.detail) {
            newJson = [json.detail];
          } else if (json.captcha) {
            newJson = json.captcha;
          }
          this.setState({
            submitting: false,
            ErrorMessage: newJson,
            captcha: null,
          });
          this.recaptchaRef.current.execute();
          return null;
        });
    this.setState(
      {
        submitting: true,
        ErrorMessage: {},
      },
      register
    );
  };

  RenderForm = () => {
    const { captcha, submitting, acceptedInvite } = this.state;
    const disableSubmitButton = captcha === null || submitting;
    const tooltipId = 'hidden-tooltip';
    if (!this.state.success_message) {
      return (
        <form onSubmit={this.onSignup}>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              className={`form-control ${this.state.ErrorMessage.firstName ? 'is-invalid' : ''}`}
              id="first_name"
              aria-describedby="emailHelp"
              onChange={this.onInputchange}
              value={this.state.first_name}
              placeholder="Enter your first name"
              disabled={submitting}
            />
            <FieldErrors errors={this.state.ErrorMessage.firstName} color="danger" />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              className={`form-control ${this.state.ErrorMessage.lastName ? 'is-invalid' : ''}`}
              id="last_name"
              name="last_name"
              aria-describedby="emailHelp"
              onChange={this.onInputchange}
              value={this.state.last_name}
              placeholder="Enter your last name"
              disabled={submitting}
            />
            <FieldErrors errors={this.state.ErrorMessage.lastName} color="danger" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className={`form-control ${this.state.ErrorMessage.email ? 'is-invalid' : ''}`}
              id="email"
              name="email"
              aria-describedby="emailHelp"
              onChange={this.onInputchange}
              value={this.state.email}
              placeholder="Enter your email"
              disabled={submitting}
            />
            <FieldErrors errors={this.state.ErrorMessage.email} color="danger" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className={`form-control ${this.state.ErrorMessage.password ? 'is-invalid' : ''}`}
              id="password"
              name="password"
              aria-describedby="emailHelp"
              onChange={this.onInputchange}
              value={this.state.password}
              placeholder="Enter your password"
              disabled={submitting}
            />
            <FieldErrors errors={this.state.ErrorMessage.password} color="danger" />
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                ref={this.recaptchaRef}
                size="invisible"
                onChange={this.onCaptchaChange}
              />
            </div>
          </div>
          <div className="form-group">
            {disableSubmitButton && (
              <UncontrolledTooltip placement="top" target={tooltipId}>
                Loading captcha...
              </UncontrolledTooltip>
            )}
            <span id={tooltipId}>
              <button
                disabled={disableSubmitButton}
                style={{ pointerEvents: disableSubmitButton ? 'none' : '' }}
                type="submit"
                className="btn btn-primary btn-lg text-center font-weight-bold btn-block mb-3"
              >
                {submitting ? 'Signing up...' : 'Signup'}
              </button>
            </span>
            <span className="font-weight-bold">
              Already have an account?
              <Link
                to={acceptedInvite ? `/invite/${acceptedInvite}` : '/login'}
                className="alert-link"
              >
                {' '}
                Log In
              </Link>
            </span>
          </div>
        </form>
      );
    }
    return <span />;
  };

  render() {
    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h3 className="mt-2 mb-4 text-center">Join Crossbeam for Sales</h3>
              <FieldErrors errors={this.state.ErrorMessage} color="danger" />
              {this.state.success_message ? (
                <div className="alert alert-success fs-16" role="alert">
                  {this.state.success_message}
                </div>
              ) : (
                ''
              )}
              {this.RenderForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignupForm);
