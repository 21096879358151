// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import BackgroundEffect from './assets/BackgroundEffect';
import UpgradeButton from './UpgradeButton';

type Props = {
  loggedInOrguser: OrgUser,
  mini?: boolean,
};

const UnlockBanner = ({ loggedInOrguser: { capabilities }, mini }: Props) =>
  capabilities.viewOnly && (
    <div
      className={`relative overflow-hidden bg-light-purple ${
        mini ? 'px-3 py-2' : 'px-4 py-4'
      } rounded d-flex flex-row align-items-center gap-15`}
    >
      <div className="flex-fill d-flex flex-column">
        <div className="d-flex flex-row align-items-center gap-10">
          <span className={`${mini ? 'normal' : 'large'}-text bold gray-700`}>
            Unlock access to Co-Selling Automation
          </span>
          <FontAwesomeIcon icon={['fad', 'wand-magic-sparkles']} className="purple" size="lg" />
        </div>
        {!mini && (
          <span className="normal-text semi-bold gray-600">
            Upgrade to Co-Seller seat to start co-selling with your partners
          </span>
        )}
      </div>
      <UpgradeButton />
      {capabilities.canManageBilling && !mini && (
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <BackgroundEffect />
        </div>
      )}
    </div>
  );

UnlockBanner.defaultProps = {
  mini: false,
};

export default withOrguser(UnlockBanner);
