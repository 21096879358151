// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

import { Partner } from 'data/entities';

type Props = {
  partners: Partner[],
};

export default function AllRequestsNullState({ partners }: Props) {
  return (
    <div className="AllRequestsNullState card">
      <div className="card-body">
        <img
          src="/assets/img/icons/bellempty-1.png"
          width="150"
          alt=""
          style={{ margin: '20px' }}
        />
        <div className="semi-bold fs-15">
          You&apos;ve approved all your pending account mapping requests!
        </div>
        <h6>Choose a partner to continue mapping</h6>
        <UncontrolledButtonDropdown className="mt-3">
          <DropdownToggle size="lg" color="primary" caret>
            Choose a Partner
          </DropdownToggle>
          <DropdownMenu>
            {partners &&
              partners.map(({ name, slug, mappingUrl }) => (
                <DropdownItem tag={Link} to={mappingUrl} key={slug}>
                  {name}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </div>
  );
}
