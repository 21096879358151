// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone } from 'lodash';

import { Org, OrgUser } from 'data/entities';
import { updateInviteSlackUsersSetting } from 'data/repositories/orgs';
import { revokeSlackAccess } from 'data/repositories/slack';
import withOrguser from 'contexts/withOrguser';

import Modal from 'components/Modal';
import SlackIntegrationButton from 'components/SlackIntegrationButton';
import SlackLogo from 'components/SlackLogo';

import CoSellingSlackConfigModal from './CoSellingSlackConfigModal';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  setOrg: (org: Org) => void,
};

const getCurrentInviteSetting = (org: Org) => clone(org.settings.inviteUsersToSlackChannel);

const SlackIntegration = ({ loggedInOrg, setOrg, loggedInOrguser }: Props) => {
  const { hasSlackIntegration, slackIntegration, settings } = loggedInOrg;

  const [error, setError] = useState<?string>(null);
  const [revokeAccessModalOpen, setRevokeAccessModalOpen] = useState<boolean>(false);
  const [inviteUsersToSlackChannel, setInviteUsersToSlackChannel] = useState(
    getCurrentInviteSetting(loggedInOrg)
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const toggleRevokeAccessModal = () => setRevokeAccessModalOpen(!revokeAccessModalOpen);
  const [revokingAccess, setRevokingAccess] = useState(false);

  const updateConfig = (e: InputEvent) => {
    const { checked } = e.target;
    setInviteUsersToSlackChannel(checked);
    setIsUpdating(true);
    setError(null);
    updateInviteSlackUsersSetting(checked)
      .then((o) => setOrg(o))
      .catch((err) => {
        setError('There was an error trying to save your changes. Please try again.');
        setInviteUsersToSlackChannel(!checked);
      })
      .finally(() => setIsUpdating(false));
  };

  const revokeAccess = () => {
    setRevokingAccess(true);
    revokeSlackAccess()
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setRevokingAccess(false);
      });
  };

  return (
    <>
      <div className="card card-body py-4">
        <div className="d-flex flex-row align-items-center gap-50">
          <div className="flex-fill d-flex flex-row align-items-center gap-15">
            <div className="rounded bg-white border-light p-2">
              <SlackLogo height={30} width={30} />
            </div>
            <div className="d-flex flex-column">
              <span className="large-text bold gray-700">Slack</span>
              {hasSlackIntegration && slackIntegration ? (
                <>
                  <span className="normal-text semi-bold gray-500">
                    <FontAwesomeIcon icon="check" width="14" height="14" className="mr-2 green" />
                    Connected to your Slack organization.
                  </span>
                  <span className="normal-text semi-bold gray-500">
                    <FontAwesomeIcon icon="link" width="14" height="14" className="mr-2" />
                    Slack Domain {slackIntegration.domain || slackIntegration.url}
                  </span>
                  <span className="normal-text semi-bold gray-500">
                    <FontAwesomeIcon icon="user-group" width="14" height="14" className="mr-2" />
                    Slack Team ID {slackIntegration.teamId}
                  </span>
                </>
              ) : (
                <div className="d-flex flex-row align-items-center gap-5 normal-text semi-bold gray-500">
                  Get notifications & use the <code className="rounded px-1 mt-1">/partners</code>{' '}
                  command
                </div>
              )}
            </div>
          </div>

          {hasSlackIntegration ? (
            <div className="d-flex flex-row gap-10">
              {settings.conversationsOnSharedSlackChannels && (
                <Link to="/slack-channels" className="btn btn-secondary">
                  Manage Slack Shared Channels
                </Link>
              )}
              <UncontrolledDropdown>
                <DropdownToggle color="link">
                  <FontAwesomeIcon icon="ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="red" onClick={toggleRevokeAccessModal}>
                    <FontAwesomeIcon icon="xmark" className="mr-2" />
                    Revoke access
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ) : (
            loggedInOrg.isIntegrationSetup && <SlackIntegrationButton />
          )}
        </div>
        {hasSlackIntegration && (
          <>
            <div className="bg-background-body p-3 rounded d-flex flex-row mt-3 align-items-center justify-content-between">
              <div className="d-flex flex-column">
                <span className="large-text bold gray-700">
                  Auto-add requesters to Shared Slack Channels
                </span>
                Automatically add your team members to Shared Slack Channels when they send a
                request
                {error && (
                  <div className="mt-1 red">
                    <FontAwesomeIcon className="mr-1" icon="fa-regular fa-circle-exclamation" />
                    {error}
                  </div>
                )}
              </div>
              <CustomInput
                id="inviteUsersToSlackChannel"
                type="switch"
                disabled={isUpdating}
                name="inviteUsersToSlackChannel"
                onChange={updateConfig}
                checked={inviteUsersToSlackChannel}
              />
            </div>
            {loggedInOrg.settings.publicDisplaysOfCoSelling && <CoSellingSlackConfigModal />}
          </>
        )}
      </div>
      <Modal
        isOpen={revokeAccessModalOpen}
        toggle={toggleRevokeAccessModal}
        title={<span className="red">Remove Slack access to Crossbeam for Sales</span>}
        footer={
          <>
            <Button color="secondary" onClick={toggleRevokeAccessModal} disabled={revokingAccess}>
              Cancel
            </Button>
            <Button color="danger" onClick={revokeAccess} disabled={revokingAccess}>
              Revoke
            </Button>
          </>
        }
        centered
      >
        <span className="large-text bold gray-700">
          Are you sure that you want to revoke Slack access to Crossbeam for Sales?
        </span>
        <span className="normal-text semi-bold gray-400">
          Revoking Slack access to Crossbeam for Sales will stop all Slack updates for your whole
          organization.
        </span>
      </Modal>
    </>
  );
};

export default withOrguser(SlackIntegration);
