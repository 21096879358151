import * as React from 'react';

import { OrgUserContext } from 'contexts/OrgUserContext';

export default function withOrguser(WrappedComponent) {
  return (props) => (
    <OrgUserContext.Consumer>
      {({ orguser, org, refreshOrgUser, refreshOrg, setOrgUser, setOrg, lastIntegration }) => (
        <WrappedComponent
          loggedInOrguser={orguser}
          loggedInOrg={org}
          refreshOrg={refreshOrg}
          refreshOrgUser={refreshOrgUser}
          setOrgUser={setOrgUser}
          setOrg={setOrg}
          lastIntegration={lastIntegration}
          {...props}
        />
      )}
    </OrgUserContext.Consumer>
  );
}
