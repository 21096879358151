// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import { xbChromeExtensionURL } from 'utils/environment';
import useInIframe from 'utils/useInIframe';

import HeaderContainer from 'layouts/BaseLayout/HeaderContainer';
import HeaderLogo from 'layouts/BaseLayout/HeaderLogo';
import InfoTip from 'components/InfoTip';
import UnisearchalNavigation from 'components/UnisearchalNavigation';

import AppSwitcher from './AppSwitcher';
import CoSellingHealth from './CoSellingHealth';
import HelpMenu from './HelpMenu';
import MagicBellTray from './MagicBellTray';
import PrimaryNavbar from './PrimaryNavbar';
import UserMenu from './UserMenu';

type Props = {
  loggedInOrguser: OrgUser,
};

const PrimaryLayoutHeader = ({ loggedInOrguser }: Props) => {
  const canManage = !!loggedInOrguser && loggedInOrguser.canManage;
  const isInIframe = useInIframe();

  if (isInIframe) {
    return null;
  }

  return (
    <HeaderContainer>
      <div className="d-flex align-items-center gap-15">
        <HeaderLogo />
        {loggedInOrguser.capabilities.canAccessSeatedExperience ? (
          <>
            <UnisearchalNavigation
              style={{
                width: '250px',
                margin: '2em auto',
              }}
              className="header-unisearchal"
            />
            <PrimaryNavbar />
          </>
        ) : (
          <span className="reply-only-pill">
            <FontAwesomeIcon icon="comment-dots" />
            Reply Only
            <InfoTip targetId="reply-only">
              You will only see open messages from your partners.
            </InfoTip>
          </span>
        )}
      </div>
      <div className="d-flex align-items-center gap-20">
        {canManage && <CoSellingHealth />}
        {loggedInOrguser.capabilities.canAccessSeatedExperience && canManage && (
          <Link
            type="button"
            className="btn btn-secondary text-center d-xl-block d-none"
            to="/admin/users"
          >
            <FontAwesomeIcon icon="plus" className="mr-2" />
            Invite
          </Link>
        )}
        {!canManage &&
          !loggedInOrguser.hasChromeExtension &&
          loggedInOrguser.capabilities.canAccessSeatedExperience && (
            <a
              className="btn btn-link text-primary p-0 d-flex flex-row align-items-center gap-5 d-xl-block d-none"
              href={xbChromeExtensionURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon width={16} icon="arrow-down-from-line" />
              Get Chrome Extension
            </a>
          )}
        <HelpMenu />
        <MagicBellTray />
        <UserMenu />
        <div className="bg-gray-200" style={{ width: 1, height: 36 }} />
        <AppSwitcher />
      </div>
    </HeaderContainer>
  );
};

export default withOrguser(PrimaryLayoutHeader);
