// @flow

import React from 'react';
import { Route } from 'react-router-dom';

import UserManagement from 'views/Users/UserManagement';

const Users = () => (
  <div className="page-top-padding">
    <Route path="/admin/users" component={UserManagement} exact />
  </div>
);

export default Users;
