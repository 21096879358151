// @flow

import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from 'data/entities/lists';
import { addToList, deleteFromList } from 'data/repositories/lists';

import PersonAvatar from 'components/PersonAvatar';

type Props = {
  list: List,
  accountPartnerId: string,
  onUpdate: (listId: string, containsMappingId: boolean) => void,
};

const ListCardPopup = ({ list, accountPartnerId, onUpdate }: Props) => {
  const { name, description, assignedTo, id, containsMappingId } = list || {};
  const [hovering, setHovering] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const createdRef = useRef();
  const history = useHistory();

  const toggleSaved = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const action = containsMappingId ? deleteFromList : addToList;
    setSubmitting(true);
    action(id, accountPartnerId)
      .then((l) => {
        setSubmitting(false);
        onUpdate(id, !containsMappingId);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const linkTo = `/lists/${id}`;

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyPress={() => history.push(linkTo)}
      onClick={() => history.push(linkTo)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className="rounded card-hoverable gap-20 py-2 px-3 pr-4 d-flex flex-row align-items-center gap-15"
    >
      <div className="flex-fill d-flex flex-column gap-5" style={{ width: '0px' }}>
        <Link
          to={linkTo}
          className="bold large-text text-truncate gray-700 mw-100"
          style={{ width: 'min-content' }}
        >
          {name}
        </Link>
        <span className="normal-text semi-bold gray-500 text-truncate">{description}</span>
      </div>
      {hovering ? (
        <button
          type="button"
          disabled={submitting}
          className={`btn btn-${containsMappingId ? 'secondary' : 'primary'} btn-sm`}
          onClick={toggleSaved}
        >
          {containsMappingId ? 'Remove' : 'Save'}
        </button>
      ) : (
        containsMappingId && <FontAwesomeIcon icon="check" className="green" size="xl" />
      )}
      <div ref={createdRef}>
        <PersonAvatar
          firstName={assignedTo.firstName}
          lastName={assignedTo.lastName}
          avatar={assignedTo.avatarImage}
          size={28}
        />
      </div>
      <UncontrolledTooltip placement="right" boundariesElement={document.body} target={createdRef}>
        Created by {assignedTo.fullName}
      </UncontrolledTooltip>
    </div>
  );
};

export default ListCardPopup;
