// @flow
import * as React from 'react';

import { Account } from 'data/entities';
import type { SearchIndexItem } from 'data/repositories/search';

import DisplayEdit from 'components/DisplayEdit';
import Unisearchal from 'components/Unisearchal';

import CompanyLogo from './CompanyLogo';

type Props = {
  currentAccount: ?Account,
  onUpdate: () => {},
};

function AccountDisplayEdit({ currentAccount, onUpdate }: Props) {
  const onSave = (newAccount: Account): Promise<any> => {
    if (!newAccount || (currentAccount && currentAccount.slug === newAccount && newAccount.slug)) {
      return Promise.resolve();
    }
    onUpdate(newAccount);
    return Promise.resolve();
  };

  let existingValue: ?SearchIndexItem = null;
  if (currentAccount) {
    existingValue = { slug: currentAccount.slug, kind: 'account', name: currentAccount.name };
  }

  return (
    <DisplayEdit
      searchComponent={Unisearchal}
      existingValue={existingValue}
      onSave={onSave}
      extraSearchProps={{ entityKind: 'account', stripUnisearchalClassName: true }}
      placeholder="Search for account..."
      emptyLabel="No matching accounts found"
      focusOnEdit
    >
      {currentAccount ? (
        <>
          <CompanyLogo
            name={currentAccount.name}
            domain={currentAccount.website}
            size={20}
            className="mr-2"
          />
          {currentAccount.name}
        </>
      ) : (
        '(none)'
      )}
    </DisplayEdit>
  );
}

export default AccountDisplayEdit;
