// @flow

/*
Component with typeahead that can be used to display an entity and then edit it.
 */

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';

import { OrgUser } from 'data/entities';

import InfoTip from 'components/InfoTip';

type Props = {
  searchComponent: React.Element,
  existingValue: any,
  onSave: (val: any) => Promise<any>,
  placeholder?: string,
  emptyLabel?: string,
  style?: any,
  extraSearchProps?: { [string]: any },
  lockMessage?: string,
  preMessage?: string,
  focusOnEdit?: boolean,
  bsSize?: string,
  defaultEditing?: boolean,
  saveOnSelect?: boolean,
};

type State = {
  loading: boolean,
  editing: boolean,
  targetId: string,
  newValue: any,
};

class DisplayEdit extends React.Component<Props, State> {
  state: State = {
    loading: false,
    editing: this.props.defaultEditing || false,
    newValue: null,
    targetId: uniqueId('diaply-edit'),
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  doEdit = () => {
    this.setState({ editing: true }, () => {
      const { focusOnEdit } = this.props;
      if (focusOnEdit) {
        if (this.inputRef.current) {
          try {
            this.inputRef.current.focus();
          } catch (error) {
            // Can't focus elemnt
          }
        }
      }
    });
  };

  stopEdit = () => {
    this.setState({ editing: false, newValue: null });
  };

  onSelectUser = (orguser: OrgUser) => {
    this.setState({ newValue: orguser });
    const { saveOnSelect } = this.props;
    if (saveOnSelect) {
      this.onSave();
    }
  };

  onSave = () => {
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true }, this.doOnSave);
  };

  doOnSave() {
    const { onSave } = this.props;
    const { newValue } = this.state;
    const completeState = { loading: false, editing: false };
    onSave(newValue).then(() => {
      this.setState(completeState);
    });
  }

  render() {
    const {
      existingValue,
      placeholder,
      emptyLabel,
      style,
      extraSearchProps,
      searchComponent: SearchComponent,
      children,
      lockMessage,
      preMessage,
      focusOnEdit,
      bsSize,
    } = this.props;
    const { editing, loading, newValue, targetId } = this.state;

    const defaultSelected = existingValue ? [existingValue] : [];
    const otherProps = { ...extraSearchProps };
    if (focusOnEdit) {
      otherProps.ref = this.inputRef;
    }

    if (lockMessage) {
      return (
        <>
          {children}{' '}
          <InfoTip targetId={targetId} icon="lock" className="fs-11 text-muted">
            {lockMessage}
          </InfoTip>
        </>
      );
    }

    return (
      <div style={style}>
        {editing ? (
          <div className="d-flex flex-wrap">
            {preMessage && <p>{preMessage}</p>}
            <SearchComponent
              onChange={this.onSelectUser}
              bsSize={bsSize}
              placeholder={placeholder}
              emptyLabel={emptyLabel}
              clearButton={false}
              style={{ flex: 1 }}
              defaultSelected={defaultSelected}
              currentValue={newValue}
              {...otherProps}
            />
            <button
              type="button"
              className="btn btn-link btn-sm px-2 text-success"
              onClick={this.onSave}
              disabled={loading}
            >
              <FontAwesomeIcon icon="check" />
            </button>
            <button
              type="button"
              className="btn btn-link btn-sm px-2 text-danger"
              onClick={this.stopEdit}
              disabled={loading}
            >
              <FontAwesomeIcon icon="times" />
            </button>
          </div>
        ) : (
          <>
            {children}{' '}
            <button
              type="button"
              className="btn btn-link btn-xs"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={this.doEdit}
            >
              <FontAwesomeIcon icon="pencil-alt" className="primary" />
            </button>
          </>
        )}
      </div>
    );
  }
}

DisplayEdit.defaultProps = {
  placeholder: 'Search...',
  emptyLabel: 'No matches found',
  style: {},
  extraSearchProps: {},
  lockMessage: null,
  preMessage: null,
  focusOnEdit: false,
  bsSize: 'small',
  defaultEditing: false,
  saveOnSelect: false,
};

export default DisplayEdit;
