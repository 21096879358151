// @flow

import * as React from 'react';

import { Partner } from 'data/entities';
import type { ExclusivePartnerFilter } from 'data/entities/partner';
import { fetchPaginatedPartners } from 'data/repositories/partners';
import { isAbortError } from 'authFetch/utils';

type State = {
  partners: Partner[],
  pageUrl: ?string,
  next: ?string,
  previous: ?string,
  isLoading: boolean,
  isLoadingCount: boolean,
};

export default function withPartners(WrappedComponent, mutualOnly = false) {
  class HOC extends React.Component<null, State> {
    state: State = {
      partners: [],
      partnersCount: null,
      next: null,
      previous: null,
      isLoading: true,
      isLoadingCount: true,
      pageUrl: null,
    };

    componentDidMount() {
      this.loadPartners();
    }

    loadPartners = (
      status: ?ExclusivePartnerFilter = null,
      search: ?string = null,
      pageUrl: ?string = null
    ) => {
      this.setState({ isLoading: true });
      fetchPaginatedPartners(mutualOnly, status, search, pageUrl)
        .then(({ results, next, previous }) => {
          this.setState({ partners: results, next, previous, pageUrl, isLoading: false });
        })
        .catch((error) => {
          if (!isAbortError(error)) {
            // Propagate the error up to the component using this
            throw error;
          }
        });
    };

    onUpdate = () => {
      const { pageUrl } = this.state;
      return this.loadPartners(null, null, pageUrl);
    };

    render() {
      const { partners, isLoading, partnersCount, isLoadingCount, next, previous } = this.state;

      const onNextPartners = next ? () => this.loadPartners(null, null, next) : null;
      const onPreviousPartners = previous ? () => this.loadPartners(null, null, previous) : null;
      const onFilterPartners = (status: ?string, search: ?string) =>
        this.loadPartners(status, search);

      return (
        <WrappedComponent
          partnersCount={partnersCount}
          partners={partners}
          onNextPartners={onNextPartners}
          onPreviousPartners={onPreviousPartners}
          onFilterPartners={onFilterPartners}
          isLoading={isLoading}
          isLoadingCount={isLoadingCount}
          onUpdate={this.onUpdate}
          {...this.props}
        />
      );
    }
  }

  return (props) => <HOC {...props} />;
}
