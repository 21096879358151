// @flow

import * as React from 'react';

import { Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import EmailNotifications from './EmailNotifications';
import SlackNotifications from './SlackNotifications';

type Props = {
  loggedInOrg: Org,
};

const Notifications = ({ loggedInOrg }: Props) => (
  <div className="d-flex flex-column">
    <h5 className="mt-4 mb-3 bold gray-700">Notifications</h5>

    {loggedInOrg.hasSlackIntegration && <SlackNotifications />}

    <EmailNotifications />
  </div>
);

export default withOrguser(Notifications);
