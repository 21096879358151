// @flow

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Contact, ContactConnection } from 'data/entities/contact';
import { CONTACT_CARD_SOURCE } from 'data/entities/tracking';
import { fetchContactConnections } from 'data/repositories/contact';

import Copy from 'components/Copy';
import LastActivity from 'components/LastActivity';
import LoadingRing from 'components/LoadingRing';
import Messaged from 'components/Messaged';
import NewBdRequestButton from 'components/NewBdRequestButton';
import PersonAvatar from 'components/PersonAvatar';
import Signals from 'components/Signals';

type Props = {
  accountSlug: string,
  contact: Contact,
};

const ContactCard = ({ accountSlug, contact }: Props) => {
  const { email, fullNames, titles, dealOwners, sharedConnectionsCount } = contact;

  const to = `/a/${accountSlug}/contacts/${email}`;

  const tooltip = useRef(null);
  const [showConnections, setShowConnections] = useState(false);
  const toggleConnections = () => setShowConnections((s) => !s);
  const [loadingConnections, setLoadingConnections] = useState(true);
  const [connections, setConnections] = useState<ContactConnection[]>([]);

  useEffect(() => {
    if (showConnections) {
      setConnections([]);
      setLoadingConnections(true);
      fetchContactConnections(accountSlug, email, 3)
        .then((c) => {
          setLoadingConnections(false);
          setConnections(c.results);
        })
        .catch((err) => {
          setLoadingConnections(false);
        });
    }
  }, [showConnections, accountSlug, email]);

  return (
    <div className="card m-0 h-100" style={{ maxWidth: '400px', width: '400px' }}>
      <div className="d-flex flex-column card-body px-3 py-2 gap-20">
        <div className="d-flex flex-row align-items-center gap-10">
          <div className="d-flex flex-column flex-fill no-overflow">
            <div className="d-flex flex-row align-items-center gap-10">
              <Link to={to} className="bold large-text text-truncate gray-700">
                {fullNames[0]}
              </Link>
              <Signals signals={contact.signals} compact />
            </div>
            <span className="large-text semi-bold gray-500 text-truncate">{titles[0]}</span>
          </div>
          <div className="text-right align-self-start">
            <Copy text={email} title="Copy Email" showTitle />
          </div>
        </div>
        <div className="flex-fill d-flex flex-row gap-5">
          <div
            ref={tooltip}
            className="align-self-end cursor-pointer d-flex flex-row align-items-center account-card-partners small-text semi-bold gray-700 gap-5"
          >
            <div className="d-flex flex-row align-items-center">
              {dealOwners.slice(0, 3).map((dealOwner, index) => (
                <div style={index !== 0 ? { marginLeft: '-8px' } : {}} key={dealOwner.name}>
                  <PersonAvatar
                    firstName={dealOwner.firstName}
                    lastName={dealOwner.lastName}
                    noMargin
                    size={20}
                    avatar={dealOwner.avatarImage}
                  />
                </div>
              ))}
            </div>
            <span>{`${sharedConnectionsCount} shared connection${
              sharedConnectionsCount === 1 ? '' : 's'
            }`}</span>
          </div>
          <Popover
            boundariesElement={document.body}
            isOpen={showConnections}
            className="PopoverCustom"
            toggle={toggleConnections}
            innerClassName="card-body px-4"
            placement="bottom"
            target={tooltip}
            trigger="click"
            delay={{ show: 100, hide: 200 }}
          >
            {loadingConnections ? (
              <LoadingRing className="m-4" maxWidth="50px" />
            ) : (
              <div className="d-flex flex-column gap-15">
                <div className="d-flex flex-column gap-25">
                  {connections.map((connection: ContactConnection) => {
                    const {
                      lastActivity,
                      owner,
                      accounts: connectionAccounts,
                      signals,
                    } = connection;
                    const tooltipTarget = `message-connection-${owner.id}`;

                    return (
                      <div className="d-flex flex-row align-items-center gap-10" key={owner.id}>
                        {connectionAccounts.length === 1 ? (
                          <NewBdRequestButton
                            accountSlug={accountSlug}
                            partnerAccountId={connectionAccounts[0].id}
                            partnerSlug={connectionAccounts[0].partnerSlug}
                            toOrgUser={owner.id}
                            apiSource={CONTACT_CARD_SOURCE}
                            compact
                          />
                        ) : (
                          <>
                            <button
                              id={tooltipTarget}
                              type="submit"
                              className="btn btn-primary btn-sm px-2"
                            >
                              <FontAwesomeIcon icon="comment-dots" size="lg" />
                            </button>
                            <UncontrolledPopover
                              className="PopoverCustom"
                              innerClassName="card-body px-3"
                              placement="bottom"
                              target={tooltipTarget}
                              trigger="click"
                              delay={{ show: 100, hide: 200 }}
                            >
                              <div className="d-flex flex-column gap-20">
                                <span className="large-text bold gray-700">
                                  Choose a partner account:
                                </span>
                                {connectionAccounts.map((account) => (
                                  <div className="d-flex flex-row align-items-center gap-10">
                                    <NewBdRequestButton
                                      accountSlug={accountSlug}
                                      partnerAccountId={account.id}
                                      partnerSlug={account.partnerSlug}
                                      toOrgUser={owner.id}
                                      apiSource={CONTACT_CARD_SOURCE}
                                      compact
                                    />
                                    <span className="larget-text bold gray-700 text-truncate">
                                      {account.name}
                                    </span>
                                    <Messaged
                                      messaged={account.messaged}
                                      accountSlug={accountSlug}
                                      partnerSlug={account.partnerSlug}
                                    />
                                  </div>
                                ))}
                              </div>
                            </UncontrolledPopover>
                          </>
                        )}
                        <PersonAvatar
                          firstName={owner.firstName}
                          lastName={owner.lastName}
                          avatar={owner.avatarImage}
                          org={owner.partnerOrg}
                          size={48}
                          noMargin
                        />
                        <div className="d-flex flex-column flex-fill" style={{ maxWidth: '400px' }}>
                          <div className="d-flex flex-row align-items-center gap-10">
                            <div className="flex-fill d-flex flex-row align-items-center gap-5">
                              <Link
                                to={`/network/oid/${owner.id}`}
                                className="bold large-text text-black text-truncate"
                              >
                                {owner.name}
                              </Link>
                              <Messaged
                                messaged={
                                  connectionAccounts.length === 1 && connectionAccounts[0].messaged
                                }
                                accountSlug={accountSlug}
                                partnerSlug={connectionAccounts[0].partnerSlug}
                              />
                            </div>
                            {lastActivity && <LastActivity lastActiveAt={lastActivity} />}
                          </div>
                          <div className="d-flex flex-row align-items-center gap-10">
                            <span className="flex-fill normal-text semi-bold gray-500 text-truncate">
                              {owner.title}
                            </span>
                            <Signals signals={signals} compact />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {sharedConnectionsCount > 3 && (
                  <div className="text-center">
                    <Link className="normal-text bold" to={to}>
                      Show More
                    </Link>
                  </div>
                )}
              </div>
            )}
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
