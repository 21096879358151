// @flow

import * as React from 'react';

const ShareAll = () => (
  <svg width="25" height="21" viewBox="0 0 25 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 8C23.8789 8 25 6.87891 25 5.5C25 4.12109 23.8789 3 22.5 3C21.1211 3 20 4.12109 20 5.5C20 6.87891 21.1211 8 22.5 8ZM2.5 9.875C1.12109 9.875 0 10.9961 0 12.375C0 13.7539 1.12109 14.875 2.5 14.875C3.87891 14.875 5 13.7539 5 12.375C5 10.9961 3.87891 9.875 2.5 9.875ZM20.0625 8.42188L19.3125 7.42188L17.4375 8.82812L18.1875 9.82812L20.0625 8.42188ZM22.5 15.5C21.9375 15.5 21.4219 15.6953 21.0039 16.0078L17.2539 13.7578C17.4023 13.3203 17.5 12.8594 17.5 12.3711C17.5 9.95312 15.543 7.99609 13.125 7.99609C12.7969 7.99609 12.4766 8.03906 12.1719 8.10938L10.5781 4.69531C10.9922 4.25 11.25 3.65625 11.25 3C11.25 1.62109 10.1289 0.5 8.75 0.5C7.37109 0.5 6.25 1.62109 6.25 3C6.25 4.37891 7.37109 5.5 8.75 5.5C8.79297 5.5 8.83203 5.48828 8.875 5.48828L10.4766 8.91797C9.43359 9.71484 8.75 10.9609 8.75 12.375C8.75 14.793 10.707 16.75 13.125 16.75C14.3789 16.75 15.5 16.2148 16.2969 15.3711L20.0391 17.6172C20.0195 17.7422 20 17.8711 20 18C20 19.3789 21.1211 20.5 22.5 20.5C23.8789 20.5 25 19.3789 25 18C25 16.6211 23.8789 15.5 22.5 15.5ZM13.125 14.25C12.0898 14.25 11.25 13.4102 11.25 12.375C11.25 11.3398 12.0898 10.5 13.125 10.5C14.1602 10.5 15 11.3398 15 12.375C15 13.4102 14.1602 14.25 13.125 14.25ZM5.9375 13H7.8125V11.75H5.9375V13Z" />
  </svg>
);

export default ShareAll;
