// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, map } from 'lodash';

import type { Account } from 'data/entities';
import { AccountPartnerList } from 'data/entities/account';
import { activeOppRaw, coldRaw, getStatusComponent, wonRaw } from 'data/entities/status';

import AccountInformation from 'components/AccountInformation';
import CompanyLogo from 'components/CompanyLogo';
import NewBdRequestButton from 'components/NewBdRequestButton';
import Pill from 'components/Pill';

const STATUSES = [wonRaw, activeOppRaw, coldRaw];
const MAX_PARTNERS = 2;

type PartnerListDetailProps = {
  style: { [key: string]: string },
  accountPartners: AccountPartnerList[],
  accountSlug: string,
  disablePopover?: boolean,
};

export function PartnerListDetail({
  style,
  accountPartners,
  accountSlug,
  disablePopover,
}: PartnerListDetailProps) {
  const fAccountPartner = accountPartners[0];
  const { partner, id } = fAccountPartner;
  const tooltipId = `tooltip-${id}-${partner.slug}`;
  const partnerLogo = (size: number) => (
    <CompanyLogo
      name={partner.name}
      domain={partner.domain}
      logoUrl={partner.logoUrl}
      className="mr-1"
      size={size}
    />
  );
  return (
    <>
      <div id={tooltipId} style={style} className="cursor">
        {partnerLogo(32)}
      </div>
      <UncontrolledPopover
        innerClassName="card-body PopoverCustom px-4"
        placement="top"
        target={tooltipId}
        trigger="hover"
        disabled={disablePopover}
        delay={{ show: 100, hide: 200 }}
      >
        <div className="text-center mb-4 mt-2">
          <div className="d-flex justify-content-center align-items-center mb-1">
            {partnerLogo(30)}
            <Link to={`/p/${partner.slug}`} className="fs-16 ml-2">
              {partner.name}{' '}
              {partner.isConnected && (
                <FontAwesomeIcon icon="check-circle" className="text-primary small ml-2" />
              )}
            </Link>
          </div>
          <div className="text-center">
            <FontAwesomeIcon icon="inbox" className="mr-1" />
            <Link
              className="fs-13"
              to={{
                pathname: '/requests/all',
                search: `?partner=${partner.slug}&account=${accountSlug}`,
              }}
            >
              Partner requests for account
            </Link>
          </div>
        </div>
        {accountPartners.map((accountPartner) => (
          <div
            key={accountPartner.id}
            className="d-flex text-center align-items-center mb-2 gap-10"
          >
            <NewBdRequestButton
              accountSlug={accountSlug}
              partnerSlug={accountPartner.partner?.slug}
              partnerAccountId={accountPartner.partnerAccount.id}
            />
            <Pill
              color={accountPartner.requestCount === 0 ? 'red' : 'gray'}
              className="mx-2"
              label={accountPartner.requestCount}
            />
            <span>{accountPartner.partnerAccount.name}</span>
          </div>
        ))}
      </UncontrolledPopover>
    </>
  );
}

PartnerListDetail.defaultProps = {
  disablePopover: false,
};

type AccountPartnerGroupProps = {
  status: string,
  accountPartners: AccountPartnerList[],
  accountSlug: string,
};

function AccountPartnerGroup({ status, accountPartners, accountSlug }: AccountPartnerGroupProps) {
  const byPartner = groupBy(accountPartners, 'partner.slug');
  const partnerSlugs = Object.keys(byPartner);
  const leftCount = partnerSlugs.length - MAX_PARTNERS;
  const selectedPartnerSlugs = Object.keys(byPartner).slice(0, MAX_PARTNERS);
  const hasResults = !!(selectedPartnerSlugs && selectedPartnerSlugs.length);
  return (
    <div className="col align-self-center">
      <div className="d-flex justify-content-center">
        {hasResults ? (
          map(selectedPartnerSlugs, (partnerSlug) => (
            <PartnerListDetail
              accountPartners={byPartner[partnerSlug]}
              key={partnerSlug}
              accountSlug={accountSlug}
            />
          ))
        ) : (
          <div className="small-text field-name text-center" style={{ height: '38px' }}>
            {' '}
          </div>
        )}
        {leftCount > 0 && <span className="ml-1 bold  align-self-center">+{leftCount}</span>}
      </div>
      <div className="d-flex align-items-baseline justify-content-center mt-1 AccountRowGroup__status">
        <span className="mr-1 bold">{byPartner.length}</span>
        {getStatusComponent(status, false)}
      </div>
    </div>
  );
}

type Props = {
  account: Account,
};

export default function AccountRow({ account }: Props) {
  const { accountPartners } = account;
  const byStatus = groupBy(accountPartners, 'partnerAccount.statusRaw');
  return (
    <div className="PartnerRow d-flex px-2 py-3 row">
      <AccountInformation account={account} className="col-5 align-items-center d-flex" />
      {STATUSES.map((status) => (
        <AccountPartnerGroup
          status={status}
          accountPartners={byStatus[status] || []}
          key={status}
          accountSlug={account.slug}
        />
      ))}
    </div>
  );
}
