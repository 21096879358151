// @flow

import React from 'react';

import CompanyLogo from 'components/CompanyLogo';
import LoadingScreen from 'components/LoadingScreen';

const classes = 'rounded bg-gray-100';

const FakeNetworkCard = () => (
  <div className="col-md-6">
    <div className="card mb-4">
      <div className="loading-animation d-flex flex-row align-items-center card-body gap-15">
        <CompanyLogo size={65} name="" className="align-self-center" />
        <div className="flex-fill d-flex flex-column gap-5">
          <div className={classes} style={{ width: 150, height: 20 }} />
          <div className={classes} style={{ width: 100, height: 16 }} />
          <div className={classes} style={{ width: 100, height: 16 }} />
        </div>
      </div>
    </div>
  </div>
);

const Loading = () => (
  <LoadingScreen>
    <div className="w-100 row align-self-center">
      {Array.from({ length: 15 }, (x, i) => i).map((index) => (
        <FakeNetworkCard key={index} />
      ))}
    </div>
  </LoadingScreen>
);
export default Loading;
