// @flow

import { getStatusComponent } from 'data/entities/status';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';

export type SearchIndexItem = {
  id: string,
  kind: 'account' | 'partner' | 'orguser',
  name: string,
  slug?: string,
  status?: string,
  orgUserFirstName?: string,
  orgUserLastName?: string,
  domain?: string,
  isConnected?: string,
  titleShow?: string,
  avatarUrlShow?: string,
  logoUrl?: string | null,
};

export type SearchIndex = {
  [string]: SearchIndexItem,
};

const parseDataToSearchIndex = (data) =>
  data.results.map(
    ({
      display_name: name,
      kind,
      slug,
      id,
      orguser_first_name: orgUserFirstName,
      orguser_last_name: orgUserLastName,
      status_show: status,
      domain_show: domain,
      is_connected: isConnected,
      avatar_url_show: avatarUrlShow,
      title_show: titleShow,
      org_logo_url: logoUrl,
    }) => ({
      name,
      kind,
      slug,
      id,
      orgUserFirstName,
      orgUserLastName,
      status: status && getStatusComponent(status, false),
      domain,
      isConnected,
      titleShow,
      avatarUrlShow,
      logoUrl,
    })
  );

export default function fetchUnisearchalResults(
  query: ?string,
  entityKind: ?string = null
): Promise<SearchIndexItem[]> {
  // Search through names of partners and accounts
  return authFetch(`${orgUrlBase()}/unisearch`, {
    queryParams: { entity: entityKind || undefined, q: query || undefined },
  }).then(parseDataToSearchIndex);
}
