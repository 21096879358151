// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { AccountOwner, PartnerAccount, PartnerBasic } from 'data/entities';
import { ACCOUNT_OWNER_CARD } from 'data/entities/tracking';

import LastActivity from 'components/LastActivity';
import Messaged from 'components/Messaged';
import NewBdRequestButton from 'components/NewBdRequestButton';
import PersonAvatar from 'components/PersonAvatar';
import Signals from 'components/Signals';

type Props = {
  accountSlug: string,
  partner: PartnerBasic,
  partnerAccount: PartnerAccount,
  accountOwner: AccountOwner,
  compact?: boolean,
};

const AccountOwnerCard = ({
  accountSlug,
  partner,
  partnerAccount,
  accountOwner,
  compact,
}: Props) => {
  const {
    orguserId,
    avatarImage,
    fullName,
    firstName,
    lastName,
    title,
    lastActivityAt,
    signals,
    messaged,
  } = accountOwner;

  const requestButton = (
    <div>
      <NewBdRequestButton
        accountSlug={accountSlug}
        partnerSlug={partner.slug}
        partnerAccountId={partnerAccount.id}
        toOrgUser={accountOwner.orguserId}
        compact={compact}
        apiSource={ACCOUNT_OWNER_CARD}
      />
    </div>
  );

  const messagedIndicator = (
    <Messaged messaged={messaged} accountSlug={accountSlug} partnerSlug={partner.slug} showText />
  );

  const content = (
    <div className="d-flex flex-row gap-15 align-items-center" style={{ minHeight: '60px' }}>
      <div className="d-flex flex-row align-items-center gap-15">
        {compact && requestButton}
        <PersonAvatar
          firstName={firstName}
          lastName={lastName}
          org={partner}
          avatar={avatarImage}
          size={48}
          noMargin
        />
      </div>
      <div className="flex-fill d-flex flex-column">
        <div className="d-flex flex-row align-items-center gap-5">
          <Link
            to={`/network/oid/${orguserId}`}
            className="normal-text bold gray-700 btn-link text-truncate fs-mask"
          >
            {fullName}
          </Link>
          <Signals signals={signals} compact />
        </div>
        {title && (
          <span className="normal-text semi-bold gray-500 text-truncate fs-mask">{title}</span>
        )}
        {lastActivityAt && (
          <div className="d-flex flex-row align-items-center gap-5">
            <span className="small-text semi-bold gray-500">Activity:</span>
            <LastActivity lastActiveAt={lastActivityAt} />
          </div>
        )}
      </div>
      {compact && messagedIndicator}
    </div>
  );

  return compact ? (
    content
  ) : (
    <div className="d-flex gap-15 flex-column">
      {content}
      <div className="d-flex flex-row align-items-center gap-15">
        {requestButton}
        {messagedIndicator}
      </div>
    </div>
  );
};

AccountOwnerCard.defaultProps = {
  compact: false,
};

export default AccountOwnerCard;
