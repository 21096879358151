import * as React from 'react';

const AlertIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1614_31441)">
      <path
        d="M31.5063 2.56061L19.6313 14.4356C19.8875 14.9187 20 15.4437 20 16C20 18.2094 18.2094 20 16 20C13.7906 20 12 18.2062 12 16C12 13.7906 13.7906 12 16 12C16.5543 12 17.0819 12.1132 17.5619 12.317L29.4369 0.441986C30.0228 -0.143951 30.9719 -0.143951 31.5581 0.441986C32.1444 1.02792 32.1438 1.97436 31.5063 2.56061Z"
        fill="#ED3652"
      />
      <path
        opacity="0.4"
        d="M28 16C28 21.8144 23.8425 26.675 18.3438 27.7687C18.0187 28.7687 17.1063 29.5 16 29.5C14.8938 29.5 13.9819 28.7663 13.6544 27.7663C9.09438 26.8588 5.52312 23.3569 4.36062 18.8725C3.29812 18.5812 2.5 17.6562 2.5 16.5C2.5 15.4194 3.19438 14.5194 4.15438 14.1706C5.04 8.41875 10.0063 4 16 4C18.7687 4 21.3069 4.95875 23.3375 6.54187L26.1825 3.69688C23.4125 1.39875 19.875 0 16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C24.8375 32 32 24.8375 32 16H28ZM6.36875 18.5375C7.32 22.1475 10.2069 24.9625 13.875 25.7625C14.3062 25.0188 15.0813 24.5 16 24.5C16.9188 24.5 17.6919 25.0216 18.1256 25.7625C22.625 24.7875 26 20.7938 26 16H22C22 19.3088 19.3088 22 16 22C12.6919 22 10 19.3088 10 16C10 12.6912 12.6919 10 16 10C17.1094 10 18.1294 10.3306 19.0187 10.86L21.9106 7.96812C20.2563 6.7375 18.2188 6 16 6C11.05 6 6.96875 9.60625 6.16875 14.3313C6.95 14.75 7.5 15.55 7.5 16.5C7.5 17.3625 7.03125 18.0875 6.36875 18.5375Z"
        fill="#ED3652"
      />
    </g>
    <defs>
      <clipPath id="clip0_1614_31441">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertIcon;
