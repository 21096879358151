// @flow

import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { find, map } from 'lodash';

import { PartnerAccount } from 'data/entities';
import { listPartnerMappedAccountsByAccount } from 'data/repositories/accounts';

type Props = {
  partnerAccountId: ?string,
  partnerSlug: ?string,
  accountSlug: ?string,
  isEditable: ?boolean,
  onPartnerAccountChange: (partnerAccount: ?PartnerAccount) => void,
  // Used by parent component to determine if shared conversation is possible
  onHasResults: (hasResults: boolean) => void,
  isVisible: ?boolean,
};

function PartnerAccountSelection(props: Props) {
  const {
    partnerSlug,
    accountSlug,
    partnerAccountId,
    onHasResults,
    onPartnerAccountChange,
    isEditable,
    isVisible,
  } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [results, setResults] = React.useState([]);

  React.useEffect(() => {
    if (partnerSlug && accountSlug) {
      setIsLoading(true);
      listPartnerMappedAccountsByAccount(partnerSlug, accountSlug, partnerAccountId)
        .then((acctResults) => {
          setIsLoading(false);
          setResults(acctResults);
          onHasResults && onHasResults(acctResults.length > 0);
          let partnerAccount = null;
          if (acctResults.length === 1) {
            partnerAccount = acctResults[0].partnerAccount;
          } else if (partnerAccountId && acctResults.length > 1) {
            const found = find(acctResults, (a) => a?.partnerAccount?.id === partnerAccountId);
            if (found) {
              partnerAccount = found;
            }
          }
          onPartnerAccountChange(partnerAccount);
        })
        .catch((error) => {
          setIsLoading(false);
          onHasResults && onHasResults(false);
          onPartnerAccountChange(null);
        });
    } else {
      onHasResults && onHasResults(false);
    }
  }, [partnerSlug, accountSlug, partnerAccountId, onHasResults, onPartnerAccountChange]);

  const selectPartnerAccount = (event: SyntheticEvent<HTMLInputElement>) => {
    const selectedPartnerAccountId = event.currentTarget.value;
    const found = find(results, (a) => a?.partnerAccount?.id === selectedPartnerAccountId);
    onPartnerAccountChange(found.partnerAccount);
  };

  const showMissingSelect = !partnerSlug || !accountSlug || (results && results.length === 0);
  const optionMessage =
    results && results.length === 0 ? 'No results found.' : 'Select a partner and account first.';
  const currentOption = find(map(results, 'partnerAccount'), {
    id: partnerAccountId,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <FormGroup className="form-group border-bottom m-0 p-2 pr-4">
      <div className="row align-items-center ">
        <Label className="col-form-label col-sm-2 text-right">Partner Account:</Label>
        <div className="col-sm-9">
          {isLoading && <input placeholder="Loading..." className="form-control" disabled />}
          {showMissingSelect && (
            <select className="custom-select custom-select-md form-control full-width" disabled>
              <option>{optionMessage}</option>
            </select>
          )}
          {!showMissingSelect && isEditable ? (
            <select
              className="custom-select custom-select-md form-control full-width mr-2"
              name="partnerAccountId"
              value={partnerAccountId || ''}
              onChange={selectPartnerAccount}
            >
              <option value="" disabled>
                Select one
              </option>
              {map(map(results, 'partnerAccount'), (_account) => (
                <option key={_account.id} value={_account.id}>
                  {_account.name} {_account.statusRaw && `(${_account.statusStr})`}
                </option>
              ))}
            </select>
          ) : (
            currentOption && (
              <span>
                {currentOption.name} {currentOption.statusRaw && currentOption.status}
              </span>
            )
          )}
        </div>
      </div>
    </FormGroup>
  );
}

export default PartnerAccountSelection;
