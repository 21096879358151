// @flow

import React, { useRef } from 'react';
import { UncontrolledPopover } from 'reactstrap';

import { Account, Opportunity as OpportunityType } from 'data/entities';

import CrmEntityUrl from 'components/CrmEntityUrl';
import DisplayEditOpportunity from 'components/DisplayEditOpportunity';

import InfoTable from './InfoTable';

type Props = {
  opportunity: OpportunityType,
  requestId: string,
  onUpdate: () => void,
  lockMessage: ?string,
  account: ?Account,
};

const Opportunity = ({
  opportunity,
  requestId,
  onUpdate,
  lockMessage,
  account,
  ...props
}: Props) => {
  const ref = useRef(null);

  return (
    <>
      <div ref={ref}>
        <DisplayEditOpportunity
          lockMessage={lockMessage}
          opportunity={opportunity}
          account={account}
          requestId={requestId}
          onUpdate={onUpdate}
        />
      </div>
      {opportunity && (
        <UncontrolledPopover
          className="PopoverCustom"
          innerClassName="card-body px-3"
          placement="auto"
          boundariesElement={document.body}
          target={ref}
          trigger="hover"
          delay={{ show: 100, hide: 200 }}
          hideArrow
        >
          <div className="d-flex flex-column gap-10">
            <span className="large-text bold gray-700">{opportunity.name}</span>
            <InfoTable
              table={[
                ['Stage:', opportunity.stage ? opportunity.stage : 'n/a'],
                [
                  'Close Date:',
                  opportunity.closeDate ? opportunity.closeDate.toLocaleDateString() : 'n/a',
                ],
                ['Amount:', opportunity.displayAmount || 'n/a'],
              ]}
            />
            <CrmEntityUrl entity={opportunity} text="Open in CRM" />
          </div>
        </UncontrolledPopover>
      )}
    </>
  );
};

export default Opportunity;
