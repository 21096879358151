// @flow

import * as React from 'react';

import type { User } from 'data/entities';

type ContextUser = { user: ?User, updateUser: (user: User) => void };
const defaultContext: ContextUser = {
  user: null,
  updateUser: () => {},
};
export default React.createContext<ContextUser>(defaultContext);
