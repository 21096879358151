// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledPopover } from 'reactstrap';
import { isNil, uniqueId } from 'lodash';

import { Partner } from 'data/entities';

import CompanyLogo from 'components/CompanyLogo';
import PersonAvatar from 'components/PersonAvatar';
import SalesCollateralLinkList from 'components/SalesCollateralLinkList';

type Props = {
  children: any,
  partner: Partner,
  noClickables?: boolean,
};

const PartnerDetailHoverCard = ({ children, partner, noClickables }: Props) => {
  if (
    !partner ||
    (!partner.description && !partner.bdManagerAtPartner && !partner.salesCollateralLinks?.length)
  ) {
    return children;
  }

  const targetId = uniqueId('partner-detail-hover-card-');

  return (
    <>
      <div id={targetId}>{children}</div>
      <UncontrolledPopover
        className="PopoverCustom"
        innerClassName="card-body px-3"
        placement="bottom-start"
        trigger="hover"
        delay={{ show: 200, hide: 200 }}
        hideArrow
        target={targetId}
      >
        <div className="d-flex flex-row gap-15" style={{ width: 400 }}>
          <CompanyLogo
            size={48}
            name={partner.name}
            domain={partner.domain}
            logoUrl={partner.logoUrl}
            className=""
          />
          <div className="d-flex flex-column gap-5 flex-fill">
            <div className="d-flex flex-row align-items-center gap-10">
              <Link to={`/p/${partner.slug}`} className="large-text bold gray-700">
                {partner.name}
              </Link>
            </div>
            {partner.bdManagerAtPartner && (
              <div className="d-flex flex-row align-items-center gap-5">
                <PersonAvatar
                  firstName={partner.bdManagerAtPartner.firstName}
                  lastName={partner.bdManagerAtPartner.lastName}
                  avatar={partner.bdManagerAtPartner.avatarImage}
                  size={20}
                  noMargin
                />
                <Link
                  to={`/network/oid/${partner.bdManagerAtPartner.id}`}
                  className="normal-text bold gray-700"
                >
                  {partner.bdManagerAtPartner.fullName}
                </Link>
                {partner.bdManagerAtPartner.title && (
                  <span className="semi-bold gray-500">{`(${partner.bdManagerAtPartner.title})`}</span>
                )}
              </div>
            )}
            <span>{partner.description}</span>
            {!isNil(partner.salesCollateralLinks) && (
              <SalesCollateralLinkList
                canManage={false}
                links={partner.salesCollateralLinks || []}
                partnerSlug={partner.slug}
                afterSubmit={() => undefined}
                limit={3}
                noClickables={noClickables}
              />
            )}
          </div>
        </div>
      </UncontrolledPopover>
    </>
  );
};

PartnerDetailHoverCard.defaultProps = {
  noClickables: false,
};

export default PartnerDetailHoverCard;
