// @flow

import React from 'react';

type Props = { text: string };

const Section = ({ text }: Props) => (
  <div className="text-center p-2 normal-text bold gray-700">{text}</div>
);

export default Section;
