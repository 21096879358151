import * as React from 'react';

import { OrgUserContext } from 'contexts/OrgUserContext';

export default function requireUserCanManage(WrappedComponent) {
  return (props) => (
    <OrgUserContext.Consumer>
      {({ orguser }) => (orguser && orguser.canManage ? <WrappedComponent {...props} /> : null)}
    </OrgUserContext.Consumer>
  );
}
