// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  acceptedInviteCode: ?string,
};

function RenderCreateAccountOrJoinWaitList(props: Props) {
  const { acceptedInviteCode } = props;
  return (
    <Link
      to={`/join?${acceptedInviteCode ? `inviteid=${acceptedInviteCode}` : ''}&action=signup`}
      className="alert-link align-middle"
    >
      <span className="font-weight-bold ">Create an account</span>
    </Link>
  );
}

export default function ForgotPasswordSignupButton(props: Props) {
  const { acceptedInviteCode } = props;
  return (
    <div>
      <RenderCreateAccountOrJoinWaitList acceptedInviteCode={acceptedInviteCode} />
      <br />
      <Link to="/forget-password" className="alert-link align-middle">
        <span className="font-weight-bold ">Forgot your password?</span>
      </Link>
    </div>
  );
}
