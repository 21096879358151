/* eslint-disable max-classes-per-file */
// @flow

import Account from './account';
import { AccountOwner } from './partner';
import PartnerOrg from './partnerOrg';

// ---- Account mapping predictions

class SuggestedPartnerAccount {
  name: string;

  id: number;

  similarity: ?number;

  status: ?string;

  isTarget: ?boolean;

  static fromApi: (data: any, isTarget: boolean) => SuggestedPartnerAccount;
}

SuggestedPartnerAccount.fromApi = (
  data: any,
  isTarget: boolean = false
): SuggestedPartnerAccount => {
  const m = new SuggestedPartnerAccount();
  m.name = data.name;
  m.id = data.id;
  m.similarity = data.similarity;
  m.status = data.status;
  m.isTarget = isTarget;
  Object.assign(m, data);
  return m;
};

// ==== Partner Approved account mapping
// Also used more generally for not-fully approved account partners

class PartnerApprovedMap {
  id: string;

  account: Account;

  partnerOrg: PartnerOrg;

  partnerAccount: SuggestedPartnerAccount;

  accountOwners: AccountOwner[];

  static fromApi: (data: any) => PartnerApprovedMap;
}

PartnerApprovedMap.fromApi = (data: any): PartnerApprovedMap => {
  const s = new PartnerApprovedMap();
  s.id = data.id;
  s.account = Account.fromApi(data.account);
  s.partnerOrg = PartnerOrg.fromApi(data.partner_org);
  s.partnerAccount = SuggestedPartnerAccount.fromApi(data.partner_account, data.is_partner_target);
  s.accountOwners = data.account_owners && data.account_owners.map(AccountOwner.fromApi);
  return s;
};

// ==== Pending Partner approval account mapping

class PendingPartnerApprovalMap {
  id: string;

  account: Account;

  partnerOrg: PartnerOrg;

  partnerAccount: SuggestedPartnerAccount;

  isMyTarget: boolean;

  accountOwners: AccountOwner[];

  static fromApi: (data: any) => PendingPartnerApprovalMap;
}

PendingPartnerApprovalMap.fromApi = (data: any): PendingPartnerApprovalMap => {
  const s = new PendingPartnerApprovalMap();
  s.account = Account.fromApi(data.account);
  s.partnerOrg = PartnerOrg.fromApi(data.partner_org);
  s.partnerAccount = SuggestedPartnerAccount.fromApi(data.partner_account);
  s.accountOwners = data.account_owners && data.account_owners.map(AccountOwner.fromApi);
  s.isMyTarget = data.is_target;
  return s;
};

// === SuggestAccountMap

class SuggestedAccountMap {
  // constructor(account: Account, partnerOrg: PartnerOrg, partnerAccounts: SuggestedPartnerAccount[]) {
  //   this.account = account
  // }
  account: Account;

  partnerOrg: PartnerOrg;

  partnerAccounts: SuggestedPartnerAccount[];

  partnerApproved: ?boolean;

  accountOwners: AccountOwner[];

  isMyTarget: ?boolean;

  id: ?string;

  static fromApi: (data: any) => SuggestedAccountMap;

  static fromPendingPartnerApprovalMap: (pam: PendingPartnerApprovalMap) => SuggestedAccountMap;

  static fromPartnerApprovedMap: (pam: PartnerApprovedMap) => SuggestedAccountMap;
}

SuggestedAccountMap.fromApi = (data: any): SuggestedAccountMap => {
  const s = new SuggestedAccountMap();
  s.partnerOrg = PartnerOrg.fromApi(data.partner_org);
  s.account = Account.fromApi(data.account);
  s.partnerAccounts = data.partner_accounts.map(SuggestedPartnerAccount.fromApi);
  s.accountOwners = data.account_owners && data.account_owners.map(AccountOwner.fromApi);
  return s;
};

SuggestedAccountMap.fromPendingPartnerApprovalMap = (
  pam: PendingPartnerApprovalMap
): SuggestedAccountMap => {
  const s = new SuggestedAccountMap();
  s.id = pam.id;
  s.account = pam.account;
  s.partnerOrg = pam.partnerOrg;
  s.partnerAccounts = [pam.partnerAccount];
  s.isMyTarget = pam.isMyTarget;
  s.accountOwners = pam.accountOwners;
  return s;
};

SuggestedAccountMap.fromPartnerApprovedMap = (pam: PartnerApprovedMap): SuggestedAccountMap => {
  const s = new SuggestedAccountMap();
  s.id = pam.id;
  s.account = pam.account;
  s.partnerOrg = pam.partnerOrg;
  s.partnerAccounts = [pam.partnerAccount];
  s.partnerApproved = true;
  s.accountOwners = pam.accountOwners;
  return s;
};

export {
  PartnerApprovedMap,
  PendingPartnerApprovalMap,
  SuggestedAccountMap,
  SuggestedPartnerAccount,
};
