// @flow
import * as React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import type { SalesCollateralLink } from 'data/entities';
import { addSalesCollateralLink, removeSalesCollateralLink } from 'data/repositories/partners';

import type { ErrorValue } from 'components/FieldErrors';
import FieldErrors from 'components/FieldErrors';

import AddSalesCollateralLinkModal from './AddSalesCollateralLinkModal';

type RemoveLinkProps = {
  salesCollateralLinkId: string,
  removeLink: (string) => Promise<void>,
  submitting: boolean,
};
const RemoveLink = ({ salesCollateralLinkId, submitting, removeLink }: RemoveLinkProps) => (
  <Button
    className="ml-1 p-0 blue"
    size="sm"
    color="link"
    onClick={() => removeLink(salesCollateralLinkId)}
    disabled={submitting}
  >
    <FontAwesomeIcon icon="xmark" />
  </Button>
);

type SalesCollateralLinkItemProps = {
  link: SalesCollateralLink,
  removeLink: (string) => Promise<void>,
  submitting: boolean,
  canManage: boolean,
};

const MAX_TITLE_DISPLAY_LENGTH = 40;

const SalesCollateralLinkItem = ({
  link: { id, url, title },
  removeLink,
  submitting,
  canManage,
}: SalesCollateralLinkItemProps) => {
  const hasLongTitle = title && title.length > MAX_TITLE_DISPLAY_LENGTH;
  const elemId = `scl-${id}`;
  return (
    <div className="pill pill-blue">
      <Button
        size="sm"
        className="p-0 blue overflow-ellipsis"
        color="link"
        disabled={submitting}
        style={{ maxWidth: `${MAX_TITLE_DISPLAY_LENGTH}ch` }}
        target="_blank"
        id={elemId}
        href={url}
      >
        <FontAwesomeIcon className="fa-light fa-sharp mr-1" icon="link" />
        {hasLongTitle && (
          <UncontrolledTooltip target={elemId} placement="top">
            {title}
          </UncontrolledTooltip>
        )}
        {title}
      </Button>
      {canManage && (
        <RemoveLink removeLink={removeLink} salesCollateralLinkId={id} submitting={submitting} />
      )}
    </div>
  );
};

type AddLinkProps = {
  toggleModal: () => void,
  modalOpen: boolean,
  addLink: (string, string) => Promise<void>,
  submitting: boolean,
  errors: ErrorValue,
};
const AddLink = ({
  toggleModal,
  modalOpen,
  addLink,
  submitting,
  errors,
}: AddLinkProps): React$Node => (
  <>
    <div className="pill pill-blue">
      <Button color="link" size="sm" className="p-0 blue" onClick={toggleModal}>
        <FontAwesomeIcon className="fa-light fa-sharp mr-1" icon="plus" />
        Add collateral links
      </Button>
    </div>
    <AddSalesCollateralLinkModal
      submitting={submitting}
      errors={errors}
      isOpen={modalOpen}
      toggle={toggleModal}
      onSubmit={addLink}
    />
  </>
);

type SalesCollateralLinkListProps = {
  partnerSlug: string,
  links: SalesCollateralLink[],
  afterSubmit: () => void,
  canManage: boolean,
  limit: number,
  noClickables: boolean,
};
const SalesCollateralLinkList = ({
  partnerSlug,
  links,
  afterSubmit,
  canManage,
  limit,
  noClickables,
}: SalesCollateralLinkListProps): React$Node => {
  const [submitting, setSubmitting] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteErrors, setDeleteErrors] = React.useState(null);
  const [addErrors, setAddErrors] = React.useState(null);
  const [localLimit, setLocalLimit] = React.useState(limit);
  const toggleModal = () => setModalOpen(!modalOpen);
  const addLink = (title: string, linkUrl: string): Promise<void> => {
    setSubmitting(true);
    setAddErrors(null);
    return addSalesCollateralLink(partnerSlug, title, linkUrl)
      .then(() => {
        afterSubmit();
        toggleModal();
      })
      .catch((error) => setAddErrors(error.json))
      .finally(() => setSubmitting(false));
  };
  const removeLink = (salesCollateralLinkId: string): Promise<void> => {
    setSubmitting(true);
    setDeleteErrors(null);
    return removeSalesCollateralLink(partnerSlug, salesCollateralLinkId)
      .then(afterSubmit)
      .catch((error) => setDeleteErrors(error.json))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="mt-2">
      <div className="d-flex flex-row align-items-center gap-10 flex-wrap">
        {(localLimit === 0 ? links : links.slice(0, localLimit)).map((link) => (
          <SalesCollateralLinkItem
            key={link.id}
            link={link}
            removeLink={removeLink}
            submitting={submitting}
            canManage={canManage}
          />
        ))}
        {canManage && !limit && (
          <AddLink
            submitting={submitting}
            toggleModal={toggleModal}
            errors={addErrors}
            modalOpen={modalOpen}
            addLink={addLink}
          />
        )}
        {/* If rendered as part of deal nav, do not show "Load More" as clicks misfire */}
        {localLimit !== 0 && links?.length > localLimit && !noClickables && (
          <a onClick={() => setLocalLimit(0)} href="#">
            Load More...
          </a>
        )}
      </div>
      <div className="d-flex justify-content-around">
        <FieldErrors errors={deleteErrors && get(deleteErrors, 'nonFieldErrors')} />
      </div>
    </div>
  );
};

export default SalesCollateralLinkList;
