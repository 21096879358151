// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { BdRequest, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import PartnerDetailHoverCard from 'views/PartnerDetail/PartnerDetailHoverCard';
import CompanyLogo from 'components/CompanyLogo';
import DeactivatedBadge from 'components/DeactivatedBadge';
import NotEnabledBadge from 'components/NotEnabledBadge';
import PartnerEditSearch from 'components/PartnerEditSearch';

type Props = {
  loggedInOrguser: OrgUser,
  request: BdRequest,
  onUpdate?: () => void,
};

const PartnerInfo = ({ loggedInOrguser, request, onUpdate }: Props) => {
  const { partner, attribution, isShared, id } = request;
  const lockMessage = attribution ? 'Cannot update this field on attributed request' : null;

  return (
    <PartnerDetailHoverCard partner={partner}>
      <div className="d-flex flex-row align-items-center gap-10">
        {partner && (
          <CompanyLogo
            size={20}
            name={partner.name}
            domain={partner.domain}
            logoUrl={partner.logoUrl}
            className=""
          />
        )}
        {isShared ? (
          <>
            <div className="flex-fill">
              <Link
                to={`/p/${partner.slug}`}
                className={`${isShared ? 'large' : 'normal'}-text bold gray-700`}
              >
                {partner.name}
              </Link>
            </div>
            {!request.isPartnerSyncEnabled && !partner.isDeleted && <NotEnabledBadge />}
            {partner.isDeleted && <DeactivatedBadge />}
          </>
        ) : (
          <PartnerEditSearch
            requestId={id}
            currentPartner={partner}
            onUpdate={() => onUpdate && onUpdate()}
            lockMessage={lockMessage}
          />
        )}
      </div>
    </PartnerDetailHoverCard>
  );
};

PartnerInfo.defaultProps = {
  onUpdate: () => {},
};

export default withOrguser(PartnerInfo);
