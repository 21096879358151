// @flow

import React from 'react';

import LoadingScreen from 'components/LoadingScreen';

const classes = 'rounded bg-gray-100';

const FakeQuestionCard = () => (
  <div className="card mb-2">
    <div className="loading-animation d-flex flex-row align-items-center card-body m-0 pl-4 py-3 gap-20">
      <div className="flex-fill d-flex flex-column gap-5">
        <div className={classes} style={{ width: 250, height: 20 }} />
        <div className={classes} style={{ width: 350, height: 20 }} />
      </div>
    </div>
  </div>
);

const LoadingQuestions = () => (
  <LoadingScreen title="We are loading all your questions.">
    <div className="w-100 row align-self-center">
      {Array.from({ length: 5 }, (x, i) => i).map((index) => (
        <FakeQuestionCard key={index} />
      ))}
    </div>
  </LoadingScreen>
);

export default LoadingQuestions;
