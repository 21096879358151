// @flow

import { isNil } from 'lodash';

import { Contact, ContactConnection } from 'data/entities/contact';
import stringifyOptions from 'utils/stringifyOptions';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

type ContactOptions = {
  inContacts?: ?boolean,
  isPrimary?: ?boolean,
  partnerSlug?: ?(string[]),
  search?: ?string,
  keyword?: ?(string[]),
  signal?: ?(string[]),
  dealStatus?: ?string,
  offset?: number,
  limit?: number,
};

const fetchContacts = (
  accountSlug: string,
  options: ?ContactOptions
): Promise<PaginatedResponse<Contact>> => {
  const { inContacts, isPrimary, partnerSlug, search, keyword, signal, dealStatus, offset, limit } =
    options || {};
  const params = [
    ['in_contacts', inContacts],
    ['is_primary', isPrimary],
    ['partner_slug', partnerSlug],
    ['name', search],
    ['keyword', keyword],
    ['status', dealStatus],
    ...(signal ? signal.map((v) => [v, true]) : []),
  ];
  if (!isNil(offset) && !isNil(limit)) {
    params.push(['offset', offset]);
    params.push(['limit', limit]);
  }
  const url = `${orgUrlBase()}/accounts/${accountSlug}/contacts${stringifyOptions(params)}`;
  return authFetch(url).then(mapPaginatedResponse(Contact.fromApi));
};

const fetchContact = (accountSlug: string, id: string): Promise<Contact> => {
  const url = `${orgUrlBase()}/accounts/${accountSlug}/contacts/${id}`;
  return authFetch(url).then(Contact.fromApi);
};

const fetchContactConnections = (
  accountSlug: string,
  id: string,
  limit: ?number
): Promise<PaginatedResponse<ContactConnection>> => {
  let url = `${orgUrlBase()}/accounts/${accountSlug}/contacts/${id}/shared-connections`;
  if (limit) {
    url += `?limit=${limit}`;
  }
  return authFetch(url).then(mapPaginatedResponse(ContactConnection.fromApi));
};

export { fetchContact, fetchContactConnections, fetchContacts };
