// @flow

import * as React from 'react';

type Props = {
  title: string,
  subtitle?: string,
};

const Header = ({ title, subtitle }: Props) => (
  <div className="py-5">
    <div className="d-flex justify-content-center">
      <div className="ml-4">
        <h3 className="text-center">{title}</h3>
        <h5 className="text-center">{subtitle}</h5>
      </div>
    </div>
  </div>
);

Header.defaultProps = {
  subtitle: "You're one step closer to leaving spreadsheets behind!",
};

export default Header;
