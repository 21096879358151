// @flow

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteRequestComment } from 'data/repositories/bdrequests';

type Props = {
  requestId: string,
  id: string,
  onDelete: (id: string) => void,
};

const classes = 'disable-selection small-text bold red';

const DeleteComment = ({ requestId, id, onDelete }: Props) => {
  const [prompt, setPrompt] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onConfirmDelete = (event) => {
    event.preventDefault();
    setSubmitting(true);
    deleteRequestComment(requestId, id)
      .then(() => onDelete(id))
      .catch((error) => {
        setSubmitting(true);
      });
  };

  const onCancelDelete = (event) => {
    event.preventDefault();
    setPrompt(false);
  };

  if (submitting) {
    return <span className={classes}>Deleting...</span>;
  }

  if (prompt) {
    return (
      <span className={classes}>
        Confirm delete:{' '}
        <a href="#" onClick={onConfirmDelete}>
          Yes
        </a>
        {' • '}
        <a href="#" onClick={onCancelDelete}>
          No
        </a>
      </span>
    );
  }
  return (
    <FontAwesomeIcon
      className="cursor-pointer red"
      size="xs"
      icon="trash-alt"
      onClick={(event) => {
        event.preventDefault();
        setPrompt(true);
      }}
    />
  );
};

export default DeleteComment;
