// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { BdRequest, Org, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import AccountEditSearch from 'components/AccountEditSearch';

import AttributionSwitch from './AttributionSwitch';
import CRMActivityLink from './CRMActivityLink';
import InfoTable from './InfoTable';
import Opportunity from './Opportunity';
import PartnerInfo from './PartnerInfo';
import SlackChannelInfo from './SlackChannelInfo';
import StatusSwitch from './StatusSwitch';

type Props = {
  request: BdRequest,
  onRequestUpdate: () => void,
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const BasicInfo = ({ request, onRequestUpdate, loggedInOrg, loggedInOrguser }: Props) => {
  const lockMessage = request.attribution ? 'Cannot update this field on attributed request' : null;
  const attributionEnabled =
    loggedInOrg && loggedInOrg.settings.attributionEnabled && request.partner;

  const completedRow =
    request.completedAt && request.completedBy
      ? [
          'Completed',
          <>
            {request.completedAt.toLocaleDateString()} by{' '}
            <Link to={`/network/oid/${request.completedBy.id}`} className="bold gray-700">
              {request.completedBy.fullName}
            </Link>
          </>,
        ]
      : [];

  return (
    <div className="py-4 px-3 d-flex flex-column gap-20">
      <div className="d-flex flex-row align-items-center">
        <span className="flex-fill large-text bold gray-700">Basic Info</span>
        {!request.partner?.isDeleted && (
          <StatusSwitch request={request} onUpdate={onRequestUpdate} />
        )}
      </div>
      <InfoTable
        table={[
          [
            'Your Account',
            <AccountEditSearch
              requestId={request.id}
              onUpdate={onRequestUpdate}
              currentAccount={request.account}
              lockMessage={lockMessage}
              disabled={!loggedInOrguser.capabilities.canAccessSeatedExperience}
            />,
          ],
          ...(loggedInOrguser.capabilities.canAccessSeatedExperience
            ? [
                [
                  'Opportunity',
                  <Opportunity
                    lockMessage={lockMessage}
                    opportunity={request.opportunity}
                    account={request.account}
                    requestId={request.id}
                    onUpdate={onRequestUpdate}
                  />,
                ],
              ]
            : []),
          [
            ...(request.partner
              ? [
                  [
                    'Slack',
                    <SlackChannelInfo
                      request={request}
                      orguser={loggedInOrguser}
                      org={loggedInOrg}
                    />,
                  ],
                ]
              : []),
          ],
          ...(attributionEnabled
            ? [['Attribution', <AttributionSwitch request={request} onUpdate={onRequestUpdate} />]]
            : []),
          [
            'Created',
            <>
              {request.createdAt.toLocaleDateString()} by{' '}
              <Link to={`/network/oid/${request.createdBy.id}`} className="bold gray-700">
                {request.createdBy.fullName}
              </Link>
            </>,
          ],
          completedRow,
          ...((request.isCompleted || loggedInOrg.settings?.unfilteredTaskLogging) &&
          request.partner &&
          loggedInOrg.settings?.logSalesforceTasks
            ? [['Activity', <CRMActivityLink request={request} />]]
            : []),
          ...(!request.isShared || request.partner?.isConnected === false || request.pendingApproval
            ? [['Partner', <PartnerInfo onUpdate={onRequestUpdate} request={request} />]]
            : []),
        ]}
      />
    </div>
  );
};

export default withOrguser(BasicInfo);
