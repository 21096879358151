// @flow

import React from 'react';

type Props = {
  table: [any, any][],
};

const InfoTable = ({ table }: Props) => (
  <table style={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}>
    <tbody>
      {table.map((field) => (
        <tr key={field[0]}>
          <td valign="top" className="normal-text semi-bold gray-600 text-nowrap" width="110px">
            {field[0]}
          </td>
          <td className="normal-text semi-bold gray-700 fs-mask">{field[1]}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default InfoTable;
