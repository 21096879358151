// @flow

import { PartnerOrgUser } from 'data/entities/partner';
import PartnerOrg from 'data/entities/partnerOrg';

class NetworkProfile extends PartnerOrgUser {
  overlapsCount: ?number;

  org: PartnerOrg;

  static fromApi = (d: any): NetworkProfile => {
    const p = PartnerOrgUser.fromApi(d);
    p.overlapsCount = d.overlaps_count;
    p.org = PartnerOrg.fromApi(d.org);
    return p;
  };
}

export default NetworkProfile;
