// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import { uniqBy, uniqueId } from 'lodash';

import CompanyLogo from 'components/CompanyLogo';
import Target from 'assets/img/target';

type Props = {
  account: Account,
  className: ?string,
};

export default function AccountInformation(props: Props) {
  const { account, className } = props;
  const {
    slug,
    name,
    websiteNormalized,
    status,
    accountOwners,
    displayDealAmount,
    isTarget,
    isTargetValue,
  } = account || {};
  // Used to represent account info (name, status, account owners) for org or partners account.
  const classes = classNames(className);
  const toolTipId =
    isTargetValue && `tooltip-${account.id}-is-target-value${uniqueId('account-target')}`;
  return (
    <div className={classes}>
      <div className="d-flex align-items-center">
        <div>
          <CompanyLogo name={name} domain={websiteNormalized} />
        </div>
        <div>
          <div className="d-flex">
            {isTarget && (
              <span id={toolTipId}>
                <Target width="18" disabled={!isTarget} className="mr-2" />
              </span>
            )}
            {isTarget && isTargetValue && (
              <UncontrolledTooltip placement="top" target={toolTipId} delay={{ show: 100 }}>
                {isTargetValue}
              </UncontrolledTooltip>
            )}
            {slug ? <Link to={`/a/${slug}`}>{name}</Link> : name}
            <span className="px-2">{status}</span>
          </div>
          <div className="d-flex flex-wrap mt-1 align-items-center">
            {displayDealAmount && (
              <span className="fs-12">
                Open Deals: <span className="pr-3 bold">{displayDealAmount}</span>
              </span>
            )}
            {Boolean(accountOwners && accountOwners.length) &&
              uniqBy(accountOwners, 'orguserId')
                .map((ao) => (
                  <div key={ao.id} className="fs-12">
                    {ao.fullName}
                  </div>
                ))
                .reduce((prev, curr, i) => [
                  prev,
                  <div className="px-2" key={`sep-${curr.key}`}>
                    •
                  </div>,
                  curr,
                ])}
          </div>
        </div>
      </div>
    </div>
  );
}
