// @flow

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser, Partner } from 'data/entities';
import Org from 'data/entities/org';
import { hideCrossbeamPartner } from 'data/repositories/crossbeam';

import Modal from 'components/Modal';

import PartnerConversationsSettingsModal from './PartnerConversationsSettingsModal';
import PartnerRequestWorkflowSettings from './PartnerRequestWorkflowSettings';

type Props = {
  partner: Partner,
  refreshPartner: () => void,
  orguser: OrgUser,
  org: Org,
};

const PartnerSettings = ({ partner, orguser, org, refreshPartner }: Props) => {
  const history = useHistory();

  const [hideIsOpen, setHideIsOpen] = useState(false);
  const toggleHide = () => setHideIsOpen(!hideIsOpen);
  const [hideSubmitting, setHideSubmitting] = useState(false);

  const [requestsSettingsOpen, setRequestsSettingsOpen] = useState(false);
  const toggleRequestsSettings = () => setRequestsSettingsOpen(!requestsSettingsOpen);

  const hidePartner = () => {
    setHideSubmitting(true);
    hideCrossbeamPartner(partner.id)
      .then(() => history.push('/network/p?status=sync_enabled'))
      .catch(() => {
        setHideSubmitting(false);
      });
  };

  if (orguser && !orguser.canManage) {
    return null;
  }

  return (
    <>
      <div className="d-flex flex-row gap-10">
        <PartnerConversationsSettingsModal partner={partner} refreshPartner={refreshPartner} />
        {partner.isSynced && (
          <div className="d-flex flex-row align-items-center gap-10">
            <UncontrolledDropdown>
              <DropdownToggle color="secondary">
                <FontAwesomeIcon icon="ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu right>
                {org.settings.requestsWorkflow && (
                  <DropdownItem onClick={toggleRequestsSettings}>
                    <FontAwesomeIcon icon="book" className="mr-2" />
                    Partner Co-Selling Templates
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => history.push(`/crossbeam?partner=${partner.id}`)}>
                  <FontAwesomeIcon icon="cog" className="mr-2" />
                  Configure Partner Settings
                </DropdownItem>
                <DropdownItem onClick={toggleHide}>
                  <FontAwesomeIcon icon="eye-slash" className="mr-2" />
                  Hide partner data
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>

      <Modal
        isOpen={hideIsOpen}
        toggle={toggleHide}
        title="Hide Partner Data"
        footer={
          <>
            <Button disabled={hideSubmitting} color="secondary" onClick={toggleHide}>
              Cancel
            </Button>
            <Button color="danger" disabled={hideSubmitting} onClick={() => hidePartner()}>
              Confirm
            </Button>
          </>
        }
      >
        <div className="d-flex flex-column gap-10">
          <span className="large-text bold gray-700">
            Hiding this partner will hide all their overlaps from your team
          </span>
          <span className="normal-text semi-bold gray-600">
            Please note that after you hide this partner you will be able to show their accounts
            again later and will not lose any of your conversations.
          </span>
        </div>
      </Modal>

      <PartnerRequestWorkflowSettings
        open={requestsSettingsOpen}
        toggle={toggleRequestsSettings}
        partner={partner}
        refreshPartner={refreshPartner}
      />
    </>
  );
};

export default PartnerSettings;
