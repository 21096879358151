// @flow

import * as React from 'react';
import { useEffect, useState } from 'react';

import { Org, Partner } from 'data/entities';
import { fetchOwnAccountOwners } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';

import SuggestionCard from 'views/Users/SuggestionCard';
import HorizontalScroll from 'components/HorizontalScroll';
import LoadingRing from 'components/LoadingRing';

type Props = {
  partner: Partner,
  loggedInOrg: Org,
};

const Suggestions = ({ partner, loggedInOrg }: Props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSuggestions([]);
    fetchOwnAccountOwners(partner.slug, true)
      .then(({ results }) => {
        setLoading(false);
        setSuggestions(results);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [partner.slug]);

  return (
    (suggestions.length || loading) && (
      <div className="mb-4 d-flex flex-column gap-15">
        <h5 className="m-0 bold gray-700">{loggedInOrg.name} account owners you should invite:</h5>
        {loading ? (
          <LoadingRing maxWidth="50px" />
        ) : (
          <HorizontalScroll>
            {suggestions.map((ao, index) => (
              <SuggestionCard
                itemId={index}
                key={ao.email}
                firstName={ao.firstName}
                lastName={ao.lastName}
                avatarImage={ao.avatarImage}
                email={ao.email}
              />
            ))}
          </HorizontalScroll>
        )}
      </div>
    )
  );
};

export default withOrguser(Suggestions);
