import * as React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { cantMap, pending, unmapped } from '../constants';

function ViewSwitchDropdown({ onChangeView, children }) {
  function onSelectView(e) {
    onChangeView(e.target.value);
  }
  return (
    <UncontrolledDropdown className="ViewSwitchDropdown">
      <DropdownToggle className="ViewSwitchDropdown_toggle-btn" color="link">
        {children} <img src="/assets/img/icons/circle-dwn-btn.svg" alt="Account Mapping Menu" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onSelectView} value={unmapped}>
          Unapproved Accounts
        </DropdownItem>
        <DropdownItem onClick={onSelectView} value={pending}>
          Pending Partner Approval
        </DropdownItem>
        <DropdownItem onClick={onSelectView} value={cantMap}>
          Potential Account Mappings
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default function MappingTableHeader({ title, subtitle, onChangeView }) {
  return (
    <div className="card-header px-4" style={{ background: '#f0f8ff' }}>
      <h5 className="semi-bold mb-0" style={{ color: '#65717b' }}>
        {onChangeView ? (
          <ViewSwitchDropdown onChangeView={onChangeView}>{title}</ViewSwitchDropdown>
        ) : (
          title
        )}
      </h5>
      <div className="mb-3" style={{ color: '#7799b6' }}>
        {subtitle}
      </div>
    </div>
  );
}
