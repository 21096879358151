// @flow

import * as React from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, forEach, isEqual, pick, reduce } from 'lodash';

import { OrgUser } from 'data/entities';
import { fetchAccountBySlug } from 'data/repositories/accounts';
import { BdRequestFilters } from 'data/repositories/bdrequests';
import { fetchOrgUser } from 'data/repositories/orgusers';
import { fetchPartnerBySlug } from 'data/repositories/partners';
import type { SearchIndexItem } from 'data/repositories/search';
import { OrgUserContext } from 'contexts';

import AttributionSelection from 'views/BdRequests/components/AttributionSelection';
import Pill from 'components/Pill';
import Unisearchal from 'components/Unisearchal';
import UserSearch from 'components/UserSearch/UserSearch';

const ACCOUNT = 'account';
const ASSIGNEE = 'assignee';
const PARTNER = 'partner';
const ORGUSER = 'orguser';
const OUTCOME = 'outcome';
const ATTRIBUTED_AS = 'attributedAs';
const CREATED_AT_GTE = 'createdAtGte';
const CREATED_AT_LTE = 'createdAtLte';

type Props = {
  filters: BdRequestFilters,
  onUpdateFilters: (filters: BdRequestFilters) => void,
};

type State = {
  isOpen: boolean,
  account: [SearchIndexItem],
  partner: [SearchIndexItem],
  assignee: [OrgUser],
  orguser: [OrgUser],
  outcome: string,
  attributedAs: string,
  createdAtGte: string,
  createdAtLte: string,
};

const FILTER_KEYS_ARRAY = [ACCOUNT, PARTNER, ASSIGNEE, ORGUSER];
const FILTER_KEYS_TRIVIAL = [OUTCOME, ATTRIBUTED_AS, CREATED_AT_GTE, CREATED_AT_LTE];
const FILTER_KEYS = FILTER_KEYS_ARRAY.concat(FILTER_KEYS_TRIVIAL);

const INDEX_ITEM_FIELDS = ['id', 'name', 'slug'];

export default class FilterDropdown extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      ...reduce(
        FILTER_KEYS_ARRAY,
        (state, key) => {
          state[key] = [];
          return state;
        },
        {}
      ),
      ...reduce(
        FILTER_KEYS_TRIVIAL,
        (state, key) => {
          state[key] = null;
          return state;
        },
        {}
      ),
    };
  }

  componentDidUpdate(prevProps) {
    const current = pick(this.props.filters, FILTER_KEYS);
    const previous = pick(prevProps.filters, FILTER_KEYS);
    if (!isEqual(current, previous)) {
      this.loadEntities();
    }
  }

  loadUserToState = async (field, value) => {
    try {
      const entity = await fetchOrgUser(value);
      this.setEntity(field, entity);
    } catch (error) {
      this.onSelectFilter(field, null);
    }
  };

  loadPartnerToState = async (field, value) => {
    try {
      const entity = await fetchPartnerBySlug(value);
      this.setEntity(field, { kind: PARTNER, ...pick(entity, INDEX_ITEM_FIELDS) });
    } catch (error) {
      this.onSelectFilter(field, null);
    }
  };

  loadAccountToState = async (field, value) => {
    try {
      const entity = await fetchAccountBySlug(value);
      this.setEntity(field, { kind: ACCOUNT, ...pick(entity, INDEX_ITEM_FIELDS) });
    } catch (error) {
      this.onSelectFilter(field, null);
    }
  };

  loadEntity = async (field, value) => {
    switch (field) {
      case ASSIGNEE:
      case ORGUSER:
        this.loadUserToState(field, value);
        break;
      case PARTNER:
        this.loadPartnerToState(field, value);
        break;
      case ACCOUNT:
        this.loadAccountToState(field, value);
        break;
      default:
        break;
    }
  };

  loadEntities = () => {
    const current = pick(this.props.filters, FILTER_KEYS);
    forEach(current, (value, key) => {
      if (value) {
        if (
          FILTER_KEYS_TRIVIAL.includes(key) &&
          (this.state[key] === null || this.state[key] !== value)
        ) {
          this.setState({ [key]: value });
        } else if (
          FILTER_KEYS_ARRAY.includes(key) &&
          (this.state[key].length === 0 || this.state[key][0].id !== value)
        ) {
          this.loadEntity(key, value);
        }
      }
    });
  };

  onToggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };
  // We should do this wherever the onUpdateFilters is defined

  onSelectFilter = (field, value) => {
    const { filters, onUpdateFilters } = this.props;
    const newFilters = cloneDeep(filters);
    newFilters[field] = value;
    onUpdateFilters(newFilters);
  };

  setEntity = (field, entity) => {
    this.setState({ [field]: entity ? [entity] : [] });
  };

  onUserSearch = (field: string) => (result: ?OrgUser) => {
    this.setEntity(field, result);
    this.onSelectFilter(field, result ? result.id : null);
  };

  onUnisearchalChange = (field: string): ((SearchIndexItem) => void) => (val: ?SearchIndexItem) => {
    this.setEntity(field, val);
    this.onSelectFilter(field, val ? val.slug : null);
  };

  onOutcomeChange = (val: string) => {
    this.setState({ [OUTCOME]: val || null });
    this.onSelectFilter(OUTCOME, val || null);
  };

  onInputChange = (field: string) => (event: ?string) => {
    const val = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({ [field]: val || null });
    this.onSelectFilter(field, val || null);
  };

  clearFilters = () => {
    const { filters, onUpdateFilters } = this.props;
    const newFilters = cloneDeep(filters);
    forEach(FILTER_KEYS, (field) => {
      if (newFilters[field]) {
        delete newFilters[field];
        this.setEntity(field, null);
      }
    });
    onUpdateFilters(newFilters);
  };

  totalFilters = () => {
    const { filters } = this.props;
    return reduce(
      FILTER_KEYS,
      (total, key) => {
        if (filters[key]) {
          return total + 1;
        }
        return total;
      },
      0
    );
  };

  render() {
    const {
      isOpen,
      account,
      partner,
      assignee,
      orguser,
      attributedAs,
      createdAtGte,
      createdAtLte,
    } = this.state;
    const totalFilters = this.totalFilters();
    return (
      <Dropdown toggle={this.onToggle} isOpen={isOpen}>
        <DropdownToggle className="d-flex flex-row align-items-center gap-5">
          <FontAwesomeIcon icon="filter" />
          Filters
          {totalFilters > 0 && <Pill label={totalFilters} color="blue" />}
        </DropdownToggle>
        <DropdownMenu right container={document.body}>
          <div className="card-body">
            <Form>
              <FormGroup>
                <span className="normal-text semi-bold gray-700">Partner Manager Assignee</span>
                <UserSearch
                  onChange={this.onUserSearch(ASSIGNEE)}
                  changeOnClear
                  placeholder="Search for a Partner Manager Assignee"
                  selected={assignee}
                />
              </FormGroup>
              <FormGroup>
                <span className="normal-text semi-bold gray-700">Sales Assignee</span>
                <UserSearch
                  onChange={this.onUserSearch(ORGUSER)}
                  changeOnClear
                  placeholder="Search for a Sales Assignee"
                  selected={orguser}
                />
              </FormGroup>
              <FormGroup>
                <span className="normal-text semi-bold gray-700">Partner</span>
                <Unisearchal
                  placeholder="Search for a partner..."
                  onChange={this.onUnisearchalChange(PARTNER)}
                  // bsSize={null}
                  onClear={this.onUnisearchalChange(PARTNER)}
                  entityKind={PARTNER}
                  selected={partner}
                  stripUnisearchalClassName
                />
              </FormGroup>
              <FormGroup>
                <span className="normal-text semi-bold gray-700">Account</span>
                <Unisearchal
                  placeholder="Search for an account..."
                  onChange={this.onUnisearchalChange(ACCOUNT)}
                  // bsSize={null}
                  onClear={this.onUnisearchalChange(ACCOUNT)}
                  entityKind={ACCOUNT}
                  selected={account}
                  stripUnisearchalClassName
                />
              </FormGroup>
              <div className="d-flex flex-row align-items-center gap-10">
                <FormGroup>
                  <span className="normal-text semi-bold gray-700">Created on start date</span>
                  <Input
                    onChange={this.onInputChange(CREATED_AT_GTE)}
                    defaultValue={createdAtGte}
                    placeholder="Start date"
                    type="date"
                  />
                </FormGroup>
                <FormGroup>
                  <span className="normal-text semi-bold gray-700">Created on end date</span>
                  <Input
                    onChange={this.onInputChange(CREATED_AT_LTE)}
                    defaultValue={createdAtLte}
                    placeholder="End date"
                    type="date"
                  />
                </FormGroup>
              </div>
              <OrgUserContext.Consumer>
                {({ org }) =>
                  org &&
                  org.settings.attributionEnabled && (
                    <FormGroup>
                      <span className="normal-text semi-bold gray-700">Attribution</span>
                      <AttributionSelection
                        onChange={this.onInputChange(ATTRIBUTED_AS)}
                        value={attributedAs}
                      />
                    </FormGroup>
                  )
                }
              </OrgUserContext.Consumer>
            </Form>
            <Button color="link" className="pl-1" onClick={this.clearFilters}>
              <FontAwesomeIcon icon="times-circle" /> Clear Filters
            </Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
