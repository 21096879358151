// @flow

import * as React from 'react';
import { FormText } from 'reactstrap';
import classNames from 'classnames';

import FieldErrors from 'components/FieldErrors';

type Props = {
  children: React.Node,
  label?: ?string,
  formText?: ?React.Node,
  errors: ?string | ?(string[]),
};

const defaultProps = {
  formText: null,
  label: null,
};

function FormGroup({ label, children, formText, errors }: Props) {
  return (
    <div className="form-group NewBdRequest__formgroup">
      <div className="row align-items-center ">
        {!!label && (
          <label htmlFor="partnerId" className="col-form-label col-sm-2 text-right">
            {label}
          </label>
        )}
        <div className={classNames(label ? 'col-sm-10' : 'col-sm-12 d-flex')}>{children}</div>
      </div>
      {formText && (
        <FormText color="muted" className="text-right">
          {formText}
        </FormText>
      )}
      <FieldErrors errors={errors} />
    </div>
  );
}

FormGroup.defaultProps = defaultProps;

export default FormGroup;
