// @flow
import { skipNullArr } from 'utils/nodash';

export interface IMinimalUser {
  id: string;

  firstName: string;

  lastName: string;
}

class MinimalUser implements IMinimalUser {
  id: string;

  firstName: string;

  lastName: string;

  get fullName(): string {
    return skipNullArr([this.firstName, this.lastName], true).join(' ');
  }

  get firstLastInitial(): string {
    return this.lastName ? `${this.firstName} ${this.lastName[0]}.` : this.firstName;
  }
}

export default MinimalUser;
