// @flow

import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';

import { BdRequest, Org, OrgUser } from 'data/entities';
import { OrgUserNameOnly } from 'data/entities/orguser';
import withOrguser from 'contexts/withOrguser';

import { formatMentions } from 'views/BdRequests/utils';
import Logo from 'components/Logo';
import PersonAvatar from 'components/PersonAvatar';
import SlackLogo from 'components/SlackLogo';

import DeleteComment from './DeleteComment';
import Mention from './Mention';

const CreatedAtWithTooltip = ({ createdAt }: { createdAt: Date }) => {
  const ref = useRef();
  return (
    <>
      <span className="small-text bold gray-500" ref={ref}>
        {moment(createdAt).format('h:mm a')}
      </span>
      <UncontrolledTooltip target={ref} delay={{ show: 250, hide: 250 }}>
        {moment(createdAt).toLocaleString()}
      </UncontrolledTooltip>
    </>
  );
};

type Props = {
  createdAt: Date,
  text: any,
  isUpdate: boolean,
  request: BdRequest,
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  id?: string,
  isInternal?: boolean,
  shortInternalIndicator?: boolean,
  orguser?: OrgUserNameOnly,
  isHiglighted?: boolean,
  controls?: boolean,
  onDelete?: (id: string) => void,
};

const Comment = ({
  createdAt,
  text,
  isUpdate,
  request,
  loggedInOrg,
  loggedInOrguser,
  id,
  isInternal,
  shortInternalIndicator,
  orguser,
  isHiglighted,
  controls,
  onDelete,
}: Props) => {
  const [showControls, setShowControls] = useState(false);

  const isSlackThread = useMemo(() => request?.slackThreadUrl, [request]);
  const { isOffsite } = request;
  const classes = classNames(
    'BdRequestComment d-flex flex-row gap-10',
    isHiglighted && 'BdRequestHighlightedComment',
    isInternal && 'bg-light-yellow'
  );

  return (
    <div
      className={classes}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      {isUpdate || !orguser ? (
        <div
          className="thumbnail-wrapper b-white rounded outline p-1"
          style={{ width: 36, minWidth: 36, height: 36 }}
        >
          {isSlackThread || isOffsite ? <SlackLogo /> : <Logo text={false} />}
        </div>
      ) : (
        <PersonAvatar
          firstName={orguser.firstName}
          lastName={orguser.lastName}
          org={{ name: orguser.orgName, domain: orguser.orgDomain, logoUrl: orguser.orgLogoUrl }}
          size={36}
          avatar={orguser.avatarImage}
          noMargin
        />
      )}
      <div className="flex-fill d-flex flex-column">
        <div className="d-flex flex-row align-items-center gap-10">
          {isUpdate || !orguser ? (
            <span className="large-text bold gray-700">Crossbeam for Sales Bot</span>
          ) : (
            <Link to={`/network/oid/${orguser.id}`} className="large-text bold gray-700 fs-mask">
              {orguser.fullName}
            </Link>
          )}
          <CreatedAtWithTooltip createdAt={createdAt} />
          {controls && showControls && id && onDelete && (
            <>
              <Link to={isHiglighted ? '#' : `#${id}`}>
                <FontAwesomeIcon
                  className="cursor-pointer gray-600 btn-link"
                  size="xs"
                  icon="link"
                />
              </Link>
              {/* User can only delete their own comment. */}
              {orguser &&
                loggedInOrguser.id === orguser.id &&
                (loggedInOrguser.isGhost || !isUpdate) && (
                  <DeleteComment requestId={request.id} id={id} onDelete={onDelete} />
                )}
            </>
          )}
          {isInternal && (
            <div className="flex-fill d-flex flex-row justify-content-end align-items-center gap-5 brown">
              <FontAwesomeIcon icon="lock" />
              <span className="small-text bold">
                Internal {!shortInternalIndicator && '(Only visible in your org)'}
              </span>
            </div>
          )}
        </div>
        <section className="normal-text semi-bold gray-700 fs-mask">
          {typeof text === 'string'
            ? formatMentions(text, (name, i, userId) => (
                <Mention text={name} to={`/network/oid/${userId}`} key={userId} />
              ))
            : text}
          {isUpdate && orguser && (
            <>
              {' '}
              by <Mention text={orguser.fullName} to={`/network/oid/${orguser.id}`} />
            </>
          )}
        </section>
      </div>
    </div>
  );
};

Comment.defaultProps = {
  id: null,
  isHiglighted: false,
  isInternal: false,
  shortInternalIndicator: false,
  orguser: null,
  controls: true,
  onDelete: () => {},
};

export default withOrguser(Comment);
