// @flow

import React from 'react';
import ReactTimeago from 'react-timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pill from './Pill';

type Props = {
  lastActiveAt: ?Date,
};

const LastActivity = ({ lastActiveAt }: Props) => {
  const currentDate = moment(new Date());
  // Last 31 days.
  const recentlyActive = currentDate.diff(moment(lastActiveAt || currentDate), 'days') <= 31;
  return (
    <Pill
      color={recentlyActive && lastActiveAt ? 'orange' : 'gray'}
      label={lastActiveAt ? <ReactTimeago date={lastActiveAt} live={false} /> : 'Not Available'}
      icon={recentlyActive && lastActiveAt ? <FontAwesomeIcon icon="fire" /> : undefined}
    />
  );
};

export default LastActivity;
