// @flow

import * as React from 'react';

import FullPageLoadingRing from 'components/FullPageLoadingRing';

// This page simply serves as a place we redirect to from oauth flows within a popup.
// The query params from this (in a popup) are ready by the page that initiated the oauth flow.
const CrossbeamDataRedirect = () => <FullPageLoadingRing text="Redirecting..." />;
export default CrossbeamDataRedirect;
