// @flow

import React from 'react';

import { COLUMN_WIDTH } from './constants';

const Header = () => (
  <div className="px-3 py-2 d-flex flex-row align-items-center custom-table-header small-text bold gray-600">
    <div style={{ width: COLUMN_WIDTH.icon }} />
    <div style={{ width: COLUMN_WIDTH.request }}>REQUEST</div>
    <div style={{ width: COLUMN_WIDTH.account }}>YOUR ACCOUNT</div>
    <div style={{ width: COLUMN_WIDTH.partnerManager }}>PARTNER MANAGER</div>
    <div style={{ width: COLUMN_WIDTH.created }}>CREATED</div>
    <div style={{ width: COLUMN_WIDTH.actions }}>ACTIONS</div>
  </div>
);

export default Header;
