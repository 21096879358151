// @flow

import { ConfigSet } from './chug';

class Segment {
  id: string;

  name: string;

  description: string;

  conditions: ConfigSet;

  createdAt: Date;

  updatedAt: Date;

  static fromApi = (d: any): Segment => {
    const s = new Segment();
    s.id = d.id;
    s.name = d.name;
    s.description = d.description;
    s.conditions = ConfigSet.fromApi(d.conditions);
    s.createdAt = d.created_at && new Date(d.created_at);
    s.updatedAt = d.updated_at && new Date(d.updated_at);
    return s;
  };
}

export default Segment;
