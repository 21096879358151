// @flow

import React, { useState } from 'react';
import classNames from 'classnames';

const getInitials = (fullName: string): string => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      // eslint-disable-next-line no-param-reassign
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

type Props = {
  name: string,
  logoUrl?: ?string,
  domain?: ?string,
  size?: number,
  className?: string,
};

const CompanyLogo = ({ domain, logoUrl, name, size, className }: Props) => {
  const [showDefault, setDefault] = useState(!domain && !logoUrl);

  const style = {
    minWidth: size,
    width: size,
    height: size,
    objectFit: 'contain',
  };
  const classes = classNames('thumbnail-wrapper b-white rounded outline', className);
  const minimumFontSize = 10;
  const fontSize = Math.max(Math.round(size * 0.4), minimumFontSize);

  if (showDefault) {
    return (
      <div className={classes} style={style}>
        <div
          style={{ backgroundColor: '#F4F5F7' }}
          className="text-primary d-flex align-items-center justify-content-center"
        >
          <span style={{ fontSize, lineHeight: 'normal', userSelect: 'none' }}>
            {getInitials(name)}
          </span>
        </div>
      </div>
    );
  }

  const src = logoUrl || `https://logo.clearbit.com/${domain}`;

  return (
    <img onError={() => setDefault(true)} className={classes} src={src} alt={name} style={style} />
  );
};

CompanyLogo.defaultProps = {
  logoUrl: null,
  domain: null,
  size: 40,
  className: 'mr-3',
};

export default CompanyLogo;
