// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, Partner } from 'data/entities';
import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { getBdRequestQuestions } from 'data/repositories/bdrequestQuestion';
import withOrguser from 'contexts/withOrguser';

import { MAX_TEXT_LENGTH } from 'views/BdRequestQuestions/constants';
import LoadingRing from 'components/LoadingRing';

type QuestionProps = {
  question: BdRequestQuestion,
  selected: boolean,
  onSelect: (selected: boolean) => void,
};
const Question = ({ question, selected, onSelect }: QuestionProps) => (
  <div
    tabIndex={0}
    role="button"
    onClick={() => onSelect(!selected)}
    onKeyDown={() => onSelect(!selected)}
    className="card card-hoverable w-max-content m-0 py-2 px-3 no-shadow d-flex flex-row align-items-center gap-10"
  >
    <input type="checkbox" checked={selected} readOnly />
    <span className="large-text bold gray-700 text-truncate">{question.text}</span>
  </div>
);

type CustomQuestionProps = {
  question: string,
  setQuestion: (q: string) => void,
};
const CustomQuestion = ({ question, setQuestion }: CustomQuestionProps) => (
  <div className="card w-max-content m-0 px-3 no-shadow d-flex flex-row align-items-center gap-10">
    <input type="checkbox" checked readOnly className="pointer-events-none" />
    <input
      type="text"
      className="py-2 border-0 h-100 w-100 large-text bold gray-700 "
      placeholder="Write your question"
      value={question}
      onChange={(e) => setQuestion(e.target.value)}
      maxLength={MAX_TEXT_LENGTH}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
    <button type="button" className="btn btn-link p-0" onClick={() => setQuestion('')}>
      <FontAwesomeIcon icon="xmark" className="gray-500" />
    </button>
  </div>
);

type Props = {
  partners: Partner[],
  selectedQuestions: BdRequestQuestion[],
  setSelectedQuestions: (q: BdRequestQuestion[]) => void,
  customQuestions: string[],
  setCustomQuestions: (q: string[]) => void,
  active: boolean,
  next: () => void,
  loggedInOrg: Org,
  inAccountDetail?: boolean,
};

const Questions = ({
  partners,
  selectedQuestions,
  setSelectedQuestions,
  customQuestions,
  setCustomQuestions,
  active,
  next,
  loggedInOrg,
  inAccountDetail,
}: Props) => {
  const [questions, setQuestions] = useState<BdRequestQuestion[]>([]);
  const [loading, setLoading] = useState(false);

  const canAskCustomQuestions =
    inAccountDetail || partners.every((p) => p.canBeAskedCustomQuestions);

  const forPartnersIds = useMemo(() => partners.map((p) => p.id), [partners]);
  const forParnterIdsDep = JSON.stringify(forPartnersIds);

  useEffect(() => {
    if (!active && inAccountDetail) return;
    setLoading(true);
    getBdRequestQuestions({
      forPartnersIds,
    })
      .then((q) => {
        setQuestions(q.results);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forParnterIdsDep]);

  if (!active) {
    const summary = selectedQuestions
      .map((q) => q.text)
      .concat(customQuestions)
      .join(' ');

    return (
      <div className="card flex-grow-0 m-0 bg-gray-100 no-shadow p-3 d-flex flex-column gap-10">
        <div className="d-flex flex-row align-items-center gap-10">
          <FontAwesomeIcon icon="circle-check" className="green" size="2x" />
          <span className="large-text bold gray-700 text-truncate">
            {!summary ? 'No questions selected' : summary}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="card flex-grow-0 m-0 no-shadow p-3 d-flex flex-column gap-10">
      <div className="d-flex flex-row align-items-center gap-10">
        <div
          className="rounded-circle d-flex align-items-center justify-content-center small-text disable-selection text-white bg-blue"
          style={{ width: 28, height: 28 }}
        >
          1
        </div>
        <span className="large-text bold gray-700">
          What would you like to ask your partner{inAccountDetail && 's'}?
        </span>
      </div>
      {inAccountDetail && (
        <div className="d-flex flex-row align-items-center gap-10 blue">
          <FontAwesomeIcon icon="info-circle" />
          <span className="normal-text semi-bold">
            Some questions are specific to a partner or group of partners.
          </span>
        </div>
      )}
      {!loading ? (
        <>
          <div
            className="d-flex flex-column gap-15"
            style={{ overflow: 'overlay', maxHeight: 400 }}
          >
            {questions.map((q) => (
              <Question
                key={q.id}
                question={q}
                selected={selectedQuestions.some((v) => v.id === q.id)}
                onSelect={(selected) =>
                  setSelectedQuestions(
                    selected
                      ? [...selectedQuestions, q]
                      : selectedQuestions.filter((v) => v.id !== q.id)
                  )
                }
              />
            ))}
            {customQuestions.map((q, i) => (
              <CustomQuestion
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                question={q}
                setQuestion={(newQ) => {
                  const newCustomQuestions = [...customQuestions];
                  if (!newQ) newCustomQuestions.splice(i, 1);
                  else newCustomQuestions[i] = newQ;
                  setCustomQuestions(newCustomQuestions);
                }}
              />
            ))}
            {canAskCustomQuestions && (
              <button
                type="button"
                className="card card-hoverable w-max-content m-0 py-2 px-3 no-shadow d-flex flex-row align-items-center gap-10"
                onClick={() => setCustomQuestions([...customQuestions, ''])}
              >
                <FontAwesomeIcon icon="plus" />
                <span className="large-text bold gray-700 text-truncate">
                  Add your own question
                </span>
              </button>
            )}
          </div>
          <hr className="m-0" />
          <div className="d-flex flex-row justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={next}
              disabled={
                (selectedQuestions.length === 0 && customQuestions.length === 0) ||
                customQuestions.some((q) => q.trim().length === 0)
              }
            >
              {inAccountDetail ? 'Choose partners' : 'Add context'}
            </button>
          </div>
        </>
      ) : (
        <LoadingRing className="m-5" />
      )}
    </div>
  );
};

Questions.defaultProps = {
  inAccountDetail: false,
};

export default withOrguser(Questions);
