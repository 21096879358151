// @flow

import * as React from 'react';
import { get } from 'lodash';
import queryString from 'qs';

import { SALESFORCE } from 'data/entities/integrationProviders';
import setTitle from 'utils/setTitle';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { LocationT } from 'sharedTypes/reactRouter';

import BareLayout from 'layouts/BareLayout';
import LoadingRing from 'components/LoadingRing';

import Header from './Header';

const genericErrorStr =
  'Failed to authenticate with Salesforce. Please try again. If this error persists contact us.';

type Props = {
  location: LocationT,
  doLogin: (onboarding: boolean) => void,
};

type State = {
  errorMsg: ?string,
  loading: boolean,
};

export default class SalesforceData extends React.Component<Props, State> {
  state: State = { errorMsg: null, loading: true };

  componentDidMount() {
    this.validateCode();
    setTitle('Connecting with Salesforce');
  }

  componentWillUnmount() {
    setTitle();
  }

  async validateCode() {
    const qs = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { code } = qs;
    let res;
    try {
      res = await authFetch(`${orgUrlBase()}/crm-integration`, {
        method: 'POST',
        body: { code, provider: SALESFORCE },
      });
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ errorMsg: get(err, 'json.detail', genericErrorStr), loading: false });
      return;
    }
    if (res.error) {
      this.setState({ errorMsg: res.error, loading: false });
    } else {
      this.props.doLogin(false, { pathname: '/onboarding', state: { isInitial: true } });
    }
  }

  render() {
    const { loading, errorMsg } = this.state;
    let main: React.Node;
    if (loading) {
      main = (
        <div className="mt-5">
          <LoadingRing text="Connecting..." />
        </div>
      );
    } else if (errorMsg) {
      main = (
        <div className="mt-5">
          <h5>{errorMsg}</h5>
        </div>
      );
    } else {
      main = (
        <div className="mt-5">
          <h5>{genericErrorStr}</h5>
        </div>
      );
    }
    return (
      <BareLayout
        centered
        afterPageContainer={<Header title="Securely Connecting to Salesforce" />}
      >
        {main}
      </BareLayout>
    );
  }
}
