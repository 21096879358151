// @flow

import { Org, OrgUser } from 'data/entities';
import User from 'data/entities/user';
import { skipNull } from 'utils/nodash';

import authFetch from './authFetch';
import { deleteOrg, deleteXBOrg, setAuthToken, setOrg, setXBOrg } from './utils';

type AuthenticateResponse = {
  user: any,
  org: Org,
  orguser: OrgUser,
  intercomHash: string,
  magicbellHash: string,
};

export default function authenticate(asOrguser: ?string): Promise<AuthenticateResponse> {
  // Call after API token is set to fetch user / org info
  const body = asOrguser ? { as_orguser_id: asOrguser } : undefined;
  return authFetch('auth/authenticate/', { method: 'POST', body }).then(
    ({
      user: userRaw,
      org: orgRaw,
      orguser: orguserRaw,
      intercom_hash: intercomHash,
      magicbell_hash: magicbellHash,
      token,
    }) => {
      const org = orgRaw && Org.fromApi(orgRaw);
      const orguser = orguserRaw && OrgUser.fromApi(orguserRaw, magicbellHash);
      const user = userRaw && User.fromApi(userRaw);
      if (org) {
        setOrg(org.id);
        setXBOrg(org.sourceUuid);
      } else {
        deleteOrg();
        deleteXBOrg();
      }
      setAuthToken(token);
      return { user, org, orguser, intercomHash, magicbellHash };
    }
  );
}

export function SSOAuthorizationURL({
  email,
  provider,
  state,
}: {
  email: string,
  provider?: string,
  state: any,
}): Promise<any> {
  const body = skipNull({ email, state, provider });
  return authFetch('auth/sso-authorization-url', { method: 'POST', body }).then(
    ({ authorization_url: authorizationUrl }) => authorizationUrl
  );
}

export function SSOAuthorizationURLForEmail(email: string, state: any): Promise<any> {
  return SSOAuthorizationURL({ email, state });
}
