// @flow

import React from 'react';

import CompanyLogo from 'components/CompanyLogo';
import LoadingScreen from 'components/LoadingScreen';

const classes = 'rounded bg-gray-100';

const FakePartnerCard = () => (
  <tr className="custom-table-row loading-animation">
    <td className="custom-table-cell d-flex flex-row align-items-center gap-20">
      <CompanyLogo size={48} name="" className="align-self-center" />
      <div className="flex-fill d-flex flex-column gap-5">
        <div className={classes} style={{ width: 250, height: 20 }} />
        <div className={classes} style={{ width: 150, height: 16 }} />
      </div>
    </td>
    <td className="custom-table-cell">
      <div className={classes} style={{ width: 250, height: 30 }} />
    </td>
  </tr>
);

const LoadingPartnerChannels = () => (
  <LoadingScreen title="We are loading all your partners.">
    <div className="w-100 row align-self-center card card-body p-0">
      <table className="custom-table">
        <thead className="custom-table-header small-text bold gray-600">
          <tr>
            <td className="custom-table-cell" width="100%">
              PARTNER
            </td>
            <td className="custom-table-cell text-nowrap">PARTNER SLACK CHANNEL</td>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 5 }, (x, i) => i).map((index) => (
            <FakePartnerCard key={index} />
          ))}
        </tbody>
      </table>
    </div>
  </LoadingScreen>
);

export default LoadingPartnerChannels;
