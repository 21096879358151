// @flow

import * as React from 'react';
import { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pill from './Pill';

type Props = {
  iconOnly?: boolean,
};

const NotEnabledBadge = ({ iconOnly }: Props) => {
  const ref = useRef();
  return (
    <>
      <div ref={ref}>
        {iconOnly ? (
          <FontAwesomeIcon icon="info-circle" className="blue" />
        ) : (
          <Pill
            label="Not Enabled"
            icon={<FontAwesomeIcon icon="info-circle" />}
            className="normal-text disable-selection"
            color="blue"
          />
        )}
      </div>
      <UncontrolledTooltip placement="top" target={ref} delay={{ show: 50, hide: 50 }}>
        This partner&apos;s data is not enabled in Crossbeam for Sales.
        <br /> Your team will not see data for this partner.
      </UncontrolledTooltip>
    </>
  );
};

NotEnabledBadge.defaultProps = {
  iconOnly: false,
};

export default NotEnabledBadge;
