// @flow

import React, { useEffect, useRef, useState } from 'react';
import { useParams as useRouterParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import { debounce, trim } from 'lodash';

import { Account } from 'data/entities';
import { Contact } from 'data/entities/contact';
import { fetchAccountBySlug } from 'data/repositories/accounts';
import { fetchContacts } from 'data/repositories/contact';
import useParams from 'utils/useParams';

import LoadingRing from 'components/LoadingRing';

import ContactCard from './ContactCard';
import Sidebar from './Filters';

const ContactsList = () => {
  const { accountSlug }: { [key: string]: string } = useRouterParams();
  const [
    { search, inContacts, keyword: keywordRaw, partner: partnerRaw, signal: signalRaw, dealStatus },
    setParams,
  ] = useParams();

  // Keep inner search state, so we can reset it if the search parameter changes.
  const searchRef = useRef<React.Ref>(null);
  useEffect(() => {
    if (searchRef.current) searchRef.current.value = search || '';
  }, [search]);
  const onSearch = debounce(
    (value: string) => setParams({ search: trim(value, ',&?') }, false),
    500
  );

  const [loadingAccount, setLoadingAccount] = useState(true);
  const [account, setAccount] = useState<Account>(null);
  useEffect(() => {
    setAccount(null);
    setLoadingAccount(true);
    fetchAccountBySlug(accountSlug)
      .then((a) => {
        setLoadingAccount(false);
        setAccount(a);
      })
      .catch((err) => {
        setLoadingAccount(false);
      });
  }, [accountSlug]);

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Arrays don't work as dependencies for useEffect, so we stringify it.
  const keyword = keywordRaw ? [].concat(keywordRaw) : [];
  const keywordDependency = JSON.stringify(keyword);
  const partner = partnerRaw ? [].concat(partnerRaw) : [];
  const partnerDependency = JSON.stringify(partner);
  const signal = signalRaw ? [].concat(signalRaw) : [];
  const signalDependency = JSON.stringify(signal);
  useEffect(() => {
    setContacts([]);
    setLoading(true);
    fetchContacts(accountSlug, {
      search,
      inContacts,
      keyword,
      partnerSlug: partner,
      signal,
      dealStatus,
    })
      .then((results) => {
        setContacts(results.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountSlug,
    search,
    inContacts,
    dealStatus,
    keywordDependency,
    partnerDependency,
    signalDependency,
  ]);

  return (
    <div className="w-100 d-flex flex-row my-5 gap-30">
      <Sidebar account={account} loadingAccount={loadingAccount} />
      <div className="flex-1">
        <div className="w-100 d-flex flex-column gap-30">
          <div className="d-flex flex-md-row flex-column justify-content-center gap-10">
            <div className="d-flex flex-row flex-fill justify-content-center gap-10">
              <h3 className="m-0 flex-fill">Contacts</h3>
              <Input
                innerRef={searchRef}
                style={{ maxWidth: '350px' }}
                type="text"
                name="search"
                placeholder="🔎  Search contacts by name"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          {loading ? (
            <LoadingRing className="m-5" />
          ) : (
            <>
              {!contacts.length ? (
                <div className="text-center">
                  <h6>No connections yet.</h6>
                  <p className="mb-3 normal-text semi-bold gray-500">
                    Once you connect with other people you will see them here.
                  </p>
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center">
                  <div className="w-100 row">
                    {contacts &&
                      account &&
                      contacts.map((contact: Contact) => (
                        <ContactCard
                          key={contact.email}
                          accountSlug={accountSlug}
                          accountDomain={account.website}
                          contact={contact}
                        />
                      ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactsList;
