// @flow

import { PartnerOrgUser } from './partner';

class Opportunity {
  id: string;

  name: string;

  stage: string;

  isClosed: boolean;

  isWon: boolean;

  closeDate: Date;

  amount: number;

  displayAmount: string;

  owner: PartnerOrgUser;

  label: string;

  salesforceUrl: string;

  crmUrl: string;

  provider: string;

  type: string;

  sourceType: ?string;

  static fromApi = (d: any): Opportunity => {
    const o = new Opportunity();
    o.id = d.id;
    o.name = d.name;
    o.stage = d.stage;
    o.isClosed = d.is_closed;
    o.isWon = d.is_won;
    o.closeDate = d.close_date && new Date(d.close_date);
    o.amount = d.amount;
    o.owner = d.owner && PartnerOrgUser.fromApi(d.owner);
    o.salesforceUrl = d.salesforce_url;
    o.displayAmount = d.display_amount;
    o.label = d.label;
    o.crmUrl = d.crm_url;
    o.provider = d.provider;
    o.type = d.type;
    o.sourceType = d.source_type;
    return o;
  };
}

export default Opportunity;
