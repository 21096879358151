import queryString from 'qs';

import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';

const genericErrorStr =
  'Failed to setup Hubspot Oauth. Please try again. If this error persists contact us.';

export default function validateCode(location) {
  const { search, pathname } = location;
  const qs = queryString.parse(search, { ignoreQueryPrefix: true });
  const { code, state } = qs;
  const endpoint = 'hubspot-integration';
  const redirectUri = `${window.location.origin}${pathname}`;
  return authFetch(`${orgUrlBase()}/notify/${endpoint}`, {
    method: 'POST',
    body: { code, state, redirect_uri: redirectUri },
  }).catch((err) => {
    throw err.json && err.json.detail ? err.json.detail : genericErrorStr;
  });
}
