// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

type Props = {
  loggedInOrguser: OrgUser,
  request: BdRequest,
  mini?: boolean,
};

const AssignPartnerManager = ({
  loggedInOrguser: { capabilities, isCrossbeamAdmin },
  request: { partner },
  mini,
}: Props) => {
  if (!capabilities.canManagePartnerships || !isCrossbeamAdmin) {
    return null;
  }

  return (
    <>
      <div
        className={`${
          mini ? 'py-1 m-1' : 'py-3'
        } rounded px-3 bg-light-blue d-flex flex-row align-items-center gap-10`}
      >
        <FontAwesomeIcon icon="info-circle" className="blue" size={mini ? 'lg' : '2x'} />
        <div className="flex-fill d-flex flex-column gap-5">
          <span className="large-text bold gray-700">
            Crossbeam Admin users will receive messages for {partner.name}
          </span>
          <span className="normal-text semi-bold gray-600">
            Assign a specific Partner Manager to receive {partner.name} messages instead.
          </span>
        </div>
        <Link to={`/p/${partner.slug}`} className="btn btn-primary text-nowrap">
          Assign Partner Manager
        </Link>
      </div>
    </>
  );
};

AssignPartnerManager.defaultProps = {
  mini: false,
};

export default withOrguser(AssignPartnerManager);
