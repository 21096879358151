// @flow

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import Notifications from './Notifications';
import Organization from './Organization';
import Sidebar from './Sidebar';

type Props = {
  loggedInOrguser: OrgUser,
};

const ListsRouter = ({ loggedInOrguser: { canManage } }: Props) => (
  <div className="w-100 d-flex flex-row mb-5 gap-30">
    <Sidebar />
    <div className="flex-1" style={{ width: '0px' }}>
      <Switch>
        <Route
          path={['/settings', '/settings/']}
          exact
          component={canManage ? Organization : () => <Redirect to="/settings/notifications" />}
        />
        <Route path="/settings/notifications" component={Notifications} />
      </Switch>
    </div>
  </div>
);

export default withOrguser(ListsRouter);
