// @flow

import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get, startCase } from 'lodash';

import { OrgUser } from 'data/entities';
import { updateMeOrgUser } from 'data/repositories/orgusers';
import withOrguser from 'contexts/withOrguser';
import { settingsToSnakeCase } from 'utils/nodash';

import FieldErrors from 'components/FieldErrors';

const EMAIL_SETTINGS = 'emailSettings';
const getEmailSettings = (orguser: OrgUser) =>
  cloneDeep(get(orguser, `settings.${EMAIL_SETTINGS}`, {}));

const NOTIFICATION_CHANNEL_SETTINGS = 'notificationChannelSettings';
const getNotificationChannelSettings = (orguser: OrgUser) =>
  cloneDeep(get(orguser, `settings.${NOTIFICATION_CHANNEL_SETTINGS}`, {}));

const EMAIL_ENABLED = 'emailEnabled';
const getEmailEnabled = (orguser: OrgUser) =>
  cloneDeep(get(orguser, `settings.${NOTIFICATION_CHANNEL_SETTINGS}.${EMAIL_ENABLED}`, false));

type Props = {
  loggedInOrguser: OrgUser,
  setOrgUser: (orguser: OrgUser) => void,
};

const EmailNotifications = ({ loggedInOrguser, setOrgUser }: Props) => {
  const [emailSettings, setEmailSettings] = useState(getEmailSettings(loggedInOrguser));
  const [emailEnabled, setEmailEnabled] = useState(getEmailEnabled(loggedInOrguser));

  const [errors, setErrors] = useState(null);

  const updateConfig = (field: string, value: any) => {
    setErrors(null);
    updateMeOrgUser({
      settings: settingsToSnakeCase({ ...loggedInOrguser.settings, [field]: value }),
    })
      .then((orguser) => {
        setErrors(null);
        setOrgUser(orguser);
      })
      .catch((error) => {
        setErrors(error.json || {});
      });
  };

  const updateEmailSettings = (e: InputEvent) => {
    const { name, checked } = e.target;
    const newSettings = { ...emailSettings, [name]: checked };
    setEmailSettings(newSettings);
    updateConfig(EMAIL_SETTINGS, newSettings);
  };

  const updateEmailEnabled = (e: InputEvent) => {
    const { name, checked } = e.target;
    setEmailEnabled(checked);
    updateConfig(NOTIFICATION_CHANNEL_SETTINGS, {
      ...getNotificationChannelSettings(loggedInOrguser),
      [name]: checked,
    });
  };

  return (
    <div className="card card-body d-flex flex-column py-4 gap-30">
      <div className="d-flex flex-row align-items-center gap-50">
        <div className="flex-fill d-flex flex-row align-items-center gap-15">
          <div className="rounded bg-white border-light p-2">
            <FontAwesomeIcon className="blue" icon="envelope" style={{ width: 30, height: 30 }} />
          </div>
          <div className="d-flex flex-column">
            <span className="large-text bold gray-700">Notifications</span>
            <div className="d-flex flex-row align-items-center gap-5 normal-text semi-bold gray-500">
              Get real-time updates
            </div>
          </div>
        </div>
        <div>
          <CustomInput
            id={EMAIL_ENABLED}
            type="switch"
            name={EMAIL_ENABLED}
            onChange={updateEmailEnabled}
            checked={emailEnabled}
          />
        </div>
      </div>
      <FieldErrors errors={errors && errors.nonFieldErrors} />
      <div className="d-flex flex-column">
        {Object.keys(emailSettings).map((field, index) => (
          <div className="d-flex flex-column" key={field}>
            {index !== 0 && <hr className="w-100 my-3" />}
            <div className="d-flex flex-row align-items-center">
              <span className="normal-text bold gray-700 flex-fill">
                {startCase(field).replace('Email', '')}
              </span>
              <CustomInput
                id={field}
                type="switch"
                name={field}
                onChange={updateEmailSettings}
                checked={emailSettings[field]}
                disabled={!emailEnabled}
              />
            </div>
            <FieldErrors errors={errors && errors.settings && errors.settings[field]} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withOrguser(EmailNotifications);
