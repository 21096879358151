/* eslint-disable no-nested-ternary */
import * as React from 'react';

const HealthIndicator = ({ color, ...props }) => (
  <svg
    width="69"
    height="68"
    viewBox="0 0 69 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="3" y="3" width="62" height="62" rx="31" fill="white" />
    <rect x="3" y="3" width="62" height="62" rx="31" fill="url(#paint0_radial_977_1479)" />
    <g clipPath="url(#clip0_977_1479)">
      <path
        d="M35.0768 38.5009V24.3127C35.0768 23.7241 34.5891 23.2363 34.0004 23.2363C33.4118 23.2363 32.924 23.7241 32.924 24.3127V38.5009C31.6714 38.9466 30.7712 40.1323 30.7712 41.537C30.7712 43.3197 32.2176 44.7661 34.0004 44.7661C35.7832 44.7661 37.2296 43.3197 37.2296 41.537C37.2296 40.1289 36.3281 38.9449 35.0768 38.5009Z"
        fill={color === 'red' ? '#E01939' : color === 'yellow' ? '#FF833D' : '#18A576'}
      />
      <path
        opacity="0.4"
        d="M41.5362 35.514V24.3128C41.5362 20.1505 38.1644 16.7781 34.062 16.7781C29.9596 16.7781 26.4667 20.1505 26.4667 24.3128V35.514C25.1381 37.179 24.314 39.2477 24.314 41.535C24.314 46.8833 28.6532 51.162 34.0015 51.162C39.3498 51.162 43.689 46.8833 43.689 41.535C43.689 39.2477 42.8682 37.1689 41.5362 35.514ZM34.0015 46.917C31.0326 46.917 28.6195 44.5038 28.6195 41.5956C28.6195 39.8801 29.4436 38.3072 30.7723 37.3068V24.37C30.7723 22.5872 32.2187 21.1408 34.0015 21.1408C35.7842 21.1408 37.2306 22.5872 37.2306 24.37V37.3068C38.5593 38.2991 39.3834 39.8801 39.3834 41.5956C39.3834 44.5018 36.9683 46.917 34.0015 46.917Z"
        fill={color === 'red' ? '#E01939' : color === 'yellow' ? '#FF833D' : '#18A576'}
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_977_1479"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34.0557 -4.70064) rotate(90) scale(43.3506 84.9165)"
      >
        <stop
          stopColor={color === 'red' ? '#F3A3B0' : color === 'yellow' ? '#F9C6A9' : '#ABFCE1'}
        />
        <stop offset="1" stopColor="white" stopOpacity="0" />
        <stop
          offset="1"
          stopColor={color === 'red' ? '#F3A3B0' : color === 'yellow' ? '#F9C6A9' : '#ABFCE1'}
          stopOpacity="0"
        />
      </radialGradient>
      <clipPath id="clip0_977_1479">
        <rect
          width="34.4444"
          height="34.4444"
          fill="white"
          transform="translate(16.7778 16.7778)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HealthIndicator;
