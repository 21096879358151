// @flow

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import requireUserCanManage from 'auth/requireUserCanManage';

import AccountMapping from 'views/AccountMapping';
import SlackIntegration from 'views/SlackIntegration';
import Users from 'views/Users';

const AdminRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/admin/account-mapping" component={AccountMapping} />
      <Route path="/admin/users" component={Users} />
      <Route exact path="/admin/slack-integration" component={SlackIntegration} />
    </Switch>
  </div>
);

export default requireUserCanManage(AdminRoutes);
