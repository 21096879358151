// @flow

import queryString from 'qs';

import { BdRequest, BdRequestRedirect, RequestComment } from 'data/entities';
import type { StatusType, urgencyType } from 'data/entities/bdrequest';
import {
  CrossbeamRecipient,
  SharedConversation,
  statusChoices,
  statusInUrlToBackendValue,
} from 'data/entities/bdrequest';
import OrgUser from 'data/entities/orguser';
import type { NewComment } from 'data/entities/requestcomment';
import { skipNull } from 'utils/nodash';
import type { InitOptions } from 'authFetch/authFetch';
import authFetch from 'authFetch/authFetch';
import { ErrorResponse } from 'authFetch/entities';
import { AbortFetch, orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

const DEFAULT_LIMIT = 20;

export class BdRequestFilters {
  constructor(
    currentOrguser: ?OrgUser,
    status: ?string = null,
    sortBy: ?string = null,
    requestedBy: ?string = null,
    assignee: ?string = null,
    partner: ?string = null,
    account: ?string = null,
    following: ?boolean = null,
    forOrguser: ?string = null,
    offset: ?number = null,
    limit: ?number = null,
    orguser: ?string = null,
    urgency: ?string = null,
    attributedAs: ?string = null,
    outcome: ?string = null,
    createdAtGte: ?string = null,
    createdAtLte: ?string = null,
    missingReply: ?boolean = null,
    missingPartnerReply: ?boolean = null,
    needsAction: ?boolean = null
  ) {
    this.currentOrguser = currentOrguser;
    this.status = status ? statusInUrlToBackendValue(status) : null;
    this.sortBy = sortBy;
    this.requestedBy = requestedBy;
    this.assignee = assignee;
    this.partner = partner;
    this.following = following;
    this.forOrguser = forOrguser;
    this.account = account;
    this.offset = offset || 0;
    this.limit = limit || DEFAULT_LIMIT;
    this.orguser = orguser;
    this.urgency = urgency;
    this.attributedAs = attributedAs;
    this.outcome = outcome;
    this.createdAtGte = createdAtGte;
    this.createdAtLte = createdAtLte;
    this.missingReply = missingReply;
    this.missingPartnerReply = missingPartnerReply;
    this.needsAction = needsAction;
  }

  currentOrguser: OrgUser;

  status: ?string;

  requestedBy: ?string;

  assignee: ?string;

  partner: ?string;

  sortBy: ?string;

  following: ?boolean;

  forOrguser: ?string;

  offset: ?number;

  limit: ?number;

  account: ?string;

  orguser: ?string;

  urgency: ?string;

  attributedAs: ?string;

  outcome: ?string;

  createdAtGte: ?string;

  createdAtLte: ?string;

  missingReply: ?boolean;

  missingPartnerReply: ?boolean;

  needsAction: ?boolean;

  static fromUrlParams(
    orguser: OrgUser,
    status: string,
    params: { [key: string]: string | boolean }
  ): BdRequestFilters {
    return Object.keys(params).reduce((bdf, key: string) => {
      // Populate new BdRequestFilters object from url query params
      if (key === 'following' && params[key] === 'true') {
        params[key] = true;
      }
      bdf[key] = params[key];
      return bdf;
    }, new BdRequestFilters(orguser, status));
  }

  get limitInt() {
    return this.limit ? parseInt(this.limit, 10) : DEFAULT_LIMIT;
  }

  get offsetInt() {
    return this.offset ? parseInt(this.offset, 10) : 0;
  }

  get isForOrguserRequestsOnly() {
    // Return if the filters are only for the logged in users current requests
    return (
      this.status === statusChoices.REQUESTED &&
      !!this.currentOrguser &&
      this.currentOrguser.id === this.forOrguser
    );
  }

  backendQueryParams(skipStatus: boolean = false) {
    // Convert url and query param values to backend filters
    const filters = {
      status: this.status,
      orguser: this.orguser,
      assignee: this.assignee,
      partner: this.partner,
      following: this.following,
      forOrguser: this.forOrguser,
      ordering: this.sortBy,
      offset: this.offset,
      limit: this.limit,
      account: this.account,
      urgency: this.urgency,
      attributedAs: this.attributedAs,
      outcome: this.outcome,
      created_at__gte: this.createdAtGte,
      created_at__lte: this.createdAtLte,
      missing_reply: this.missingReply,
      missing_partner_reply: this.missingPartnerReply,
      needsAction: this.needsAction,
      isOffsite: this.isOffsite,
    };
    // Return all values that aren't falsey
    filters.status = skipStatus ? null : filters.status;
    return skipNull(filters);
  }

  incrementOffset = () => {
    this.offset = this.offsetInt + DEFAULT_LIMIT;
  };

  decrementOffset = () => {
    const nextOffset = this.offsetInt - DEFAULT_LIMIT;
    this.offset = nextOffset >= 0 ? nextOffset : 0;
  };

  appUrlParams() {
    const noNulls = skipNull(this);
    // If there is no offset, don't bother to add it to the url params
    if (!this.offsetInt || this.offsetInt === 0) {
      delete noNulls.offset;
    }
    // If there is no limit or no offset, don't bother adding limit either.
    if (!this.limit || !noNulls.offset) {
      delete noNulls.limit;
    }
    // Status is handled separately by react router links
    delete noNulls.status;
    // currentOrguser is an object not trivial
    delete noNulls.currentOrguser;
    delete noNulls.needsAction;
    return noNulls;
  }

  appUrlParamStr() {
    return queryString.stringify(this.appUrlParams());
  }
}

const abortOpenCounts = new AbortFetch();

export function fetchOpenBdRequestCounts(filters: ?BdRequestFilters = null): Promise<any> {
  let url = `${orgUrlBase()}/bdrequests/open-counts`;
  const filterParams = filters ? filters.backendQueryParams(true) : {};
  const queryStr = queryString.stringify(filterParams);
  url = `${url}?${queryStr}`;
  abortOpenCounts.abort();
  const options = {
    signal: abortOpenCounts.signal,
  };
  return authFetch(url, options);
}

export function fetchBdRequests(
  filters: ?BdRequestFilters = null,
  pageUrl: ?string = null
): Promise<PaginatedResponse<BdRequest>> {
  let url = `${orgUrlBase()}/bdrequests`;
  if (pageUrl) {
    url = pageUrl;
  } else {
    const filterParams = filters ? filters.backendQueryParams() : {};
    const queryStr = queryString.stringify(filterParams);
    url = `${url}?${queryStr}`;
  }
  return authFetch(url).then(mapPaginatedResponse(BdRequest.fromApi));
}

export function exportBdRequests(filters: ?BdRequestFilters = null) {
  let url = `${orgUrlBase()}/bdrequests/export`;
  const filterParams = filters ? filters.backendQueryParams() : {};
  const queryStr = queryString.stringify(filterParams);
  url = `${url}?${queryStr}`;
  return authFetch(url);
}

export function fetchPreviousConversations(
  filters: ?BdRequestFilters = null,
  pageUrl: ?string = null
): Promise<PaginatedResponse<BdRequest>> {
  let url = `${orgUrlBase()}/bdrequests`;
  if (pageUrl) {
    url = pageUrl;
  } else {
    const filterParams = filters ? filters.backendQueryParams() : {};
    const queryStr = queryString.stringify(filterParams);
    url = `${url}?${queryStr}`;
  }
  return authFetch(url).then(mapPaginatedResponse(BdRequest.fromApi));
}

export function fetchBdRequest(requestId: string): Promise<BdRequest | BdRequestRedirect> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  return authFetch(url).then((d) => {
    if (d.id) {
      return BdRequest.fromApi(d);
    }

    return BdRequestRedirect.fromApi(d);
  });
}

export function deleteBdRequest(requestId: string): Promise<any> {
  const options = { method: 'DELETE' };
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  return authFetch(url, options);
}

export function fetchIsFollowingBdRequest(requestId: string): Promise<boolean> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/following`;
  return authFetch(url).then(({ is_following: isFollowing }) => isFollowing);
}

export function followBdRequest(requestId: string): Promise<boolean> {
  const options = { method: 'POST' };
  const url = `${orgUrlBase()}/bdrequests/${requestId}/following`;
  return authFetch(url, options).then(() => true);
}

export function stopFollowingBdRequest(requestId: string): Promise<boolean> {
  const options = { method: 'DELETE' };
  const url = `${orgUrlBase()}/bdrequests/${requestId}/following`;
  return authFetch(url, options).then(() => false);
}

export function addBdRequestParticipant(requestId: string, orguserId: string): Promise<boolean> {
  const options = { method: 'POST', body: { id: orguserId } };
  const url = `${orgUrlBase()}/bdrequests/${requestId}/participants`;
  return authFetch(url, options).then(() => true);
}

export function createBdRequest(
  accountId: ?string,
  partnerId: ?string,
  opportunityId: ?string,
  summary: ?string,
  status: ?string,
  outcome: ?string,
  urgency: ?urgencyType,
  attributeAs: ?string,
  messageObj: ?NewComment,
  partnerAccountId: ?string,
  source: ?string,
  toOrgUserIds: ?(string[]) = null,
  partnerAccountOwnerToId?: string,
  questionIds: string[],
  customQuestions: string[],
  talkingPointAnswers: string[]
) {
  const preparedOppoId = opportunityId || null; // Don't want an empty string slippping through
  const preparedOutcome = outcome || null;
  const message = messageObj && { text: messageObj.text, is_shared: messageObj.isShared };
  const body = skipNull({
    account_id: accountId,
    partner_id: partnerId,
    opportunity_id: preparedOppoId,
    summary,
    status,
    outcome: preparedOutcome,
    urgency,
    attribute_as: attributeAs,
    message,
    partner_account_id: partnerAccountId,
    source,
    add_participant_ids: toOrgUserIds,
    partner_account_owner_to_id: partnerAccountOwnerToId,
    questions_to_ask_ids: questionIds || [],
  });
  const options = { method: 'POST', body };
  return authFetch(`${orgUrlBase()}/bdrequests`, options)
    .then(BdRequest.fromApi)
    .catch(({ json }: ErrorResponse) => {
      throw json;
    });
}

export function updateBdRequestStatus(requestId: string, status: StatusType): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/update-status`;
  const options: InitOptions = { method: 'POST', body: { status } };
  return authFetch(url, options);
}

export function updateBdRequestAssignee(requestId: string, assignee: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  const options: InitOptions = { method: 'PATCH', body: { assignee } };
  return authFetch(url, options);
}

export function updateBdRequestRequester(requestId: string, requester: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  const options: InitOptions = { method: 'PATCH', body: { requester } };
  return authFetch(url, options);
}

export function updateBdRequestPartner(requestId: string, partner: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  const options: InitOptions = { method: 'PATCH', body: { partner } };
  return authFetch(url, options);
}

export function updateBdRequestAccount(
  requestId: string,
  account: string,
  opportunityId: ?string
): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/update-account`;
  const body: { account: string, opportunity_id?: string } = { account };
  if (opportunityId) {
    body.opportunity_id = opportunityId;
  }
  const options: InitOptions = { method: 'POST', body };
  return authFetch(url, options);
}

export function updateBdRequestOutcome(requestId: string, outcome: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  const options: InitOptions = { method: 'PATCH', body: { outcome } };
  return authFetch(url, options);
}

export function updateBdRequesUrgency(requestId: string, urgency: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  const options: InitOptions = { method: 'PATCH', body: { urgency } };
  return authFetch(url, options);
}

export function updateBdRequestOpportunity(requestId: string, opportunityId: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}`;
  const options: InitOptions = {
    method: 'PATCH',
    body: { opportunity_id: opportunityId },
  };
  return authFetch(url, options);
}

export function fetchAttributionOptions(opportunityId: string): Promise<string[]> {
  const url = `${orgUrlBase()}/opportunities/${opportunityId}/attribution-options`;
  const options: InitOptions = {
    method: 'GET',
  };
  return authFetch(url, options);
}

export function setBdRequestAttribution(
  requestId: string,
  attributeAs: string | null
): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/set-attribution`;
  const options: InitOptions = {
    method: 'POST',
    body: { attribute_as: attributeAs },
  };
  return authFetch(url, options);
}

export function fetchRequestComments(
  bdrequestId: string
): Promise<PaginatedResponse<RequestComment>> {
  const url = `${orgUrlBase()}/bdrequests/${bdrequestId}/comments`;
  return authFetch(url).then(mapPaginatedResponse(RequestComment.fromApi));
}

export function addRequestComment(
  bdrequestId: string,
  text: string,
  isShared: boolean = false
): Promise<RequestComment> {
  const url = `${orgUrlBase()}/bdrequests/${bdrequestId}/comments`;
  return authFetch(url, { method: 'POST', body: { text, is_shared: isShared } }).then(
    RequestComment.fromApi
  );
}

export function deleteRequestComment(requestId: string, commentId: string): Promise<any> {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/comments/${commentId}`;
  return authFetch(url, { method: 'DELETE' });
}

export function getSharedConversation(bdrequestId: string) {
  const url = `${orgUrlBase()}/bdrequests/${bdrequestId}/shared-conversation`;
  return authFetch(url).then(SharedConversation.fromApi);
}

export function setupSharedConversation(
  bdrequestId: string,
  partnerAccountId: ?string,
  messageText: string
) {
  const url = `${orgUrlBase()}/bdrequests/${bdrequestId}/shared-conversation`;
  const message = { text: messageText };
  const body = { partner_account_id: partnerAccountId || undefined, message };
  return authFetch(url, { method: 'POST', body }).then(SharedConversation.fromApi);
}

export function getAssistiveAccountPartners(partnerId: string) {
  const url = `${orgUrlBase()}/assistive-account-partners?partner_id=${partnerId}`;
  return authFetch(url).then((result) => result);
}

export const getCrossbeamPartnerManagers = (partnerSlug: string) => {
  const url = `${orgUrlBase()}/partners/${partnerSlug}/list-crossbeam-partner-managers`;
  return authFetch(url).then((recipients) => recipients.map(CrossbeamRecipient.fromApi));
};

export const connectCrossbeamPartner = (partnerSlug: string, userId: Number) => {
  const url = `${orgUrlBase()}/partners/${partnerSlug}/connect-crossbeam-partner`;
  const body = { user_id: userId };
  return authFetch(url, { method: 'POST', body });
};

export const approveBdRequest = (requestId: string, approved: boolean, message?: string) => {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/approve`;
  const body = { approved };
  if (message && !approved) {
    body.message = { text: message };
  }
  return authFetch(url, { method: 'POST', body });
};

export const publishToSlack = (requestId: string) => {
  const url = `${orgUrlBase()}/bdrequests/${requestId}/slack-publish`;
  return authFetch(url, { method: 'POST' });
};

export function createQuestionsBdRequest(
  accountId: string,
  partnerId: string,
  partnerAccountId: string,
  text: string,
  questionIds: string[],
  talkingPointAnswers: string[],
  customQuestions?: string[],
  source: ?string,
  useMessage?: boolean = false
) {
  const body = skipNull({
    account_id: accountId,
    partner_id: partnerId,
    message: { text, is_shared: true },
    partner_account_id: partnerAccountId,
    questions_to_ask_ids: questionIds,
    talking_points_answers: talkingPointAnswers,
    custom_questions_to_ask: customQuestions,
    source,
  });

  return authFetch(`${orgUrlBase()}/bdrequests?use_message=${useMessage}`, {
    method: 'POST',
    body,
  }).then(BdRequest.fromApi);
}
