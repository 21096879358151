// @flow

import React, { useRef } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import List from 'react-virtualized/dist/commonjs/List';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';

type Render = ({
  key: string,
  index: number,
  column: ?number,
  style: ?React.CSSProperties,
  data: any,
  isLoaded: boolean,
  refreshSize: () => void,
}) => React.Component;

type LoadMoreRows = (page: number, limit: number) => Promise<any>;

type Props = {
  count: number,
  minHeight: number,
  loadedAll: boolean,
  list: Array<any>,
  loadMoreRows: LoadMoreRows,
  onScroll?: (scrollTop: number) => void,
  render: Render,
  columns?: number,
  gap?: number,
  showFakeRows?: boolean,
};

// Should match API pagination limit!
const paginationLimit = 50;

const InfiniteList = ({
  count,
  minHeight,
  loadedAll,
  render,
  loadMoreRows,
  onScroll,
  list,
  columns = 1,
  gap,
  showFakeRows,
  ...rest
}: Props) => {
  const lastRequestedIndex = useRef(0);
  const isGrid = columns > 1;
  const innerList = isGrid
    ? list.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / columns);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, [])
    : list;
  const isRowLoaded = (index) => !!innerList[index];
  const listCount = innerList.length;

  // If we know the total count use it, otherwise expect there is N rows more until we have loaded everything.
  const innerCount =
    Math.ceil(count / columns) ||
    (loadedAll ? listCount : listCount + (showFakeRows ? paginationLimit : 0));

  const innerLoadMoreRows = ({ startIndex, stopIndex }) => {
    const offset = startIndex * columns + paginationLimit;
    if (!loadedAll && lastRequestedIndex.current < offset) {
      // InfiniteLoader will sometimes try to split into smaller chunks
      // This makes it fetch the entire next "page"
      // e.g. fetched offset 50, and then requests offset 51 -> go for 100 instead
      const newOffset = lastRequestedIndex.current + paginationLimit;
      loadMoreRows(newOffset, paginationLimit);
      lastRequestedIndex.current = newOffset;
    }
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight,
  });

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => isRowLoaded(index)}
      loadMoreRows={innerLoadMoreRows}
      rowCount={innerCount}
      minimumBatchSize={paginationLimit}
      {...rest}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller
          onScroll={({ scrollTop }) => onScroll && onScroll(scrollTop)}
          scrollElement={document.getElementById('page-container')}
        >
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  autoHeight
                  height={height || 0}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  width={width}
                  deferredMeasurementCache={cache}
                  ref={registerChild}
                  rowHeight={cache.rowHeight}
                  onRowsRendered={onRowsRendered}
                  rowCount={innerCount}
                  rowRenderer={({ key, index, style, parent }) => {
                    const row = innerList[index] || [];
                    return (
                      <CellMeasurer
                        cache={cache}
                        columnIndex={0}
                        rowIndex={index}
                        {...{ key, parent }}
                      >
                        {({ measure, registerChild: registerChildInner }) =>
                          isGrid ? (
                            <div ref={registerChildInner} className="d-flex flex-row" style={style}>
                              {row.map((data, columnIndex) => (
                                <div
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={columnIndex}
                                  className="h-100 flex-even"
                                  style={{
                                    paddingBottom: index !== innerCount - 1 ? gap : 0,
                                    paddingRight:
                                      columnIndex === 0 && row.length !== 1
                                        ? Math.round(gap / 2)
                                        : 0,
                                    paddingLeft:
                                      columnIndex === row.length - 1 && row.length !== 1
                                        ? Math.round(gap / 2)
                                        : 0,
                                  }}
                                >
                                  {render({
                                    key,
                                    index,
                                    column: columnIndex,
                                    data,
                                    isLoaded: isRowLoaded(index),
                                    refreshSize: measure,
                                    style: {},
                                  })}
                                </div>
                              ))}
                            </div>
                          ) : (
                            render({
                              key,
                              index,
                              data: row,
                              style: {
                                ...style,
                                paddingBottom: index !== innerCount - 1 ? gap : 0,
                              },
                              isLoaded: isRowLoaded(index),
                              refreshSize: measure,
                            })
                          )
                        }
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
};

InfiniteList.defaultProps = { columns: 1, gap: 20, onScroll: () => {}, showFakeRows: true };

export default InfiniteList;
