// @flow

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FieldErrors from 'components/FieldErrors';

import { MAX_TALKING_POINTS, MAX_TEXT_LENGTH } from './constants';

type Props = {
  talkingPoints: string[],
  setTalkingPoints: (talkingPoints: string[]) => void,
  errors: string[] | null,
  hasDuplicates: boolean,
  hasEmpty: boolean,
};

const TalkingPoints = ({
  talkingPoints,
  setTalkingPoints,
  errors,
  hasDuplicates,
  hasEmpty,
}: Props) => {
  const addTalkingPoint = () => {
    setTalkingPoints([...talkingPoints, '']);
  };

  const reachedTalkingPointLimit = talkingPoints.length >= MAX_TALKING_POINTS;

  return (
    <div className="w-100 d-flex flex-column align-items-center gap-10">
      <div className="w-100 d-flex flex-column">
        <h5 className="m-0 bold gray-700 flex-fill">
          What should your sales team inform the partner about?
        </h5>
        <span className="normal-text semi-bold gray-400">
          * We suggest adding 2-4 steps that your sales team should provide context for.
        </span>
      </div>

      <FieldErrors errors={errors} noMargin className="w-100" />

      {(hasDuplicates || hasEmpty) && (
        <div className="w-100 d-flex flex-row align-items-center gap-5 orange">
          <FontAwesomeIcon icon={['far', 'exclamation-circle']} />
          <span className="normal-text semi-bold">
            You can&apos;t have {hasEmpty ? 'empty' : 'duplicate'} talking points!
          </span>
        </div>
      )}

      <div
        className="w-100 px-3 py-2 rounded d-flex flex-column gap-10"
        style={{ background: '#EFF3F9' }}
      >
        {talkingPoints.length > 0 ? (
          <>
            <span className="large-text semi-bold gray-700">
              Fill in the blank about your account:
            </span>
            {talkingPoints.map((talkingPoint, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="card card-body no-shadow m-0 px-3 py-2 d-flex flex-row" key={index}>
                <input
                  type="text"
                  className="border-0 w-100"
                  placeholder="Start typing..."
                  value={talkingPoint}
                  onChange={(e) => {
                    const newTalkingPoints = [...talkingPoints];
                    newTalkingPoints[index] = e.target.value;
                    setTalkingPoints(newTalkingPoints);
                  }}
                  maxLength={MAX_TEXT_LENGTH}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={index === talkingPoints.length - 1 && talkingPoint === ''}
                />
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => {
                    const newTalkingPoints = [...talkingPoints];
                    newTalkingPoints.splice(index, 1);
                    setTalkingPoints(newTalkingPoints);
                  }}
                >
                  <FontAwesomeIcon icon="trash-alt" className="red" />
                </button>
              </div>
            ))}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <div tabIndex={0} id="add-talking-point" style={{ width: 'max-content' }}>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={addTalkingPoint}
                disabled={reachedTalkingPointLimit}
                style={{ pointerEvents: reachedTalkingPointLimit && 'none' }}
              >
                <FontAwesomeIcon icon="plus" className="mr-2" />
                Add
              </button>

              {reachedTalkingPointLimit && (
                <UncontrolledTooltip placement="right" target="add-talking-point">
                  You reached the maximum of {MAX_TALKING_POINTS} context steps. Remove some of them
                  to add more.
                </UncontrolledTooltip>
              )}
            </div>
          </>
        ) : (
          <div className="d-flex flex-column align-items-center text-center p-4">
            <span className="large-text bold gray-700">
              Add a context question for your sales team
            </span>
            <span className="normal-text semi-bold gray-500">
              Context questions help guide your team what information to provide to partners when
              asking question about their accounts.
            </span>
            <button type="button" className="btn btn-primary mt-2" onClick={addTalkingPoint}>
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TalkingPoints;
