// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import BaseOverlay from './BaseOverlay';
import UpgradeButton from './UpgradeButton';

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  title: string,
  explanation: string,
  children: any,
};

const ViewerOverlay = ({
  loggedInOrg: { isIntegrationSetup },
  loggedInOrguser: { capabilities },
  title,
  explanation,
  children,
}: Props) => (
  <BaseOverlay
    title={title}
    explanation={explanation}
    condition={capabilities.viewOnly && isIntegrationSetup}
    icon={<FontAwesomeIcon icon={['fad', 'wand-magic-sparkles']} className="purple" size="2x" />}
    extra={<UpgradeButton />}
  >
    {children}
  </BaseOverlay>
);

export default withOrguser(ViewerOverlay);
