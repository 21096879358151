// @flow

import authFetch from 'authFetch/authFetch';

export default function cbLogin(loginOrg: ?string = null) {
  return authFetch('auth/crossbeam-login', {
    method: 'POST',
    credentials: 'include',
    body: { target_organization_uuid: loginOrg },
  });
}
