// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BdRequest, Org } from 'data/entities';
import { fetchBdRequest } from 'data/repositories/bdrequests';

import LoadingRing from 'components/LoadingRing';

import Comments from '../BdRequestDetail/Comments';

type Props = {
  requestId: string,
  org: Org,
};

const BdRequestMini = ({ requestId, org }: Props) => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [request, setRequest] = useState<BdRequest | null>(null);

  const onRequestUpdate = useCallback(
    (showLoading?: boolean = false) => {
      if (showLoading) setLoadingRequest(true);
      fetchBdRequest(requestId)
        .then((bd) => {
          setLoadingRequest(false);
          setRequest(bd);
        })
        .catch((error) => {
          setLoadingRequest(false);
        });
    },
    [requestId]
  );

  useEffect(() => {
    onRequestUpdate(true);
  }, [onRequestUpdate, requestId]);

  return !loadingRequest ? (
    <>
      {request ? (
        <div className="d-flex flex-column flex-fill" style={{ height: '0px' }}>
          <Comments mini request={request} onUpdate={onRequestUpdate} />
        </div>
      ) : (
        <div className="mt-5 text-center">
          <h6>Request Not Found</h6>
          <Link to="/requests">View All Requests</Link>
        </div>
      )}
    </>
  ) : (
    <LoadingRing className="mt-5" maxWidth="60px" text="Loading Request..." />
  );
};

export default BdRequestMini;
