// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import queryString from 'qs';

import { Partner } from 'data/entities';
import { fetchPartnerBySlug } from 'data/repositories/partners';
import type { SearchIndexItem } from 'data/repositories/search';
import { OrgUserContext } from 'contexts/OrgUserContext';
import type { LocationT } from 'sharedTypes/reactRouter';

import PartnerSettings from 'views/AccountMapping/PartnerSettings';
import LoadingRing from 'components/LoadingRing';
import withPartners from 'components/withPartners';

import AccountMappingResults from './AccountMappingResults';
import PartnerSelector from './PartnerSelector';

function AccountMapping({
  onChange,
  hasFilters,
  partners,
  filters,
  allRequestsView,
  partner,
  onApprove,
  onDecline,
  org,
  refreshPartner,
  orguser,
}) {
  const { partnerSlug } = filters || {};
  return (
    <div className="AccountMapping mt-4">
      <div className="row my-3">
        <div className="col-lg-3">
          <PartnerSelector partners={partners} partnerSlug={partnerSlug} />
        </div>
        <div className="col-lg-9">
          {hasFilters && partner && (
            <div className="d-flex my-5">
              <div>
                <Link to={`/p/${partner.slug}`} className="btn btn-success btn-lg mr-2">
                  » View Mapped Accounts
                </Link>
              </div>
              <PartnerSettings
                partner={partner}
                org={org}
                refreshPartner={refreshPartner}
                orguser={orguser}
              />
            </div>
          )}

          {Boolean((partner && partner.settings.enableAccountMapping) || !hasFilters) &&
            (!hasFilters ||
            (partner.settingsByPartner && partner.settingsByPartner.enableAccountMapping) ? (
              <AccountMappingResults
                onChange={onChange}
                hasFilters={hasFilters}
                partners={partners}
                filters={filters || {}}
                allRequestsView={allRequestsView}
                partner={partner}
                onApprove={onApprove}
                onDecline={onDecline}
              />
            ) : (
              <LoadingRing
                text={`Waiting for ${partner.name} to connect teams...`}
                maxWidth="50px"
                className="mt-5"
              />
            ))}
        </div>
      </div>
    </div>
  );
}

type ContainerProps = {
  location: LocationT,
  partners: Partner[],
  onUpdate: () => {}, // Update partners
};

function AccountMappingContainer(props: ContainerProps) {
  const { partners, location, onUpdate: onUpdatePartners } = props;
  const decrementPartnerRequests = () => {};
  const [filters, setFilters] = React.useState(null);
  // const [filters, setFilters] = React.useState<Filters>(null);
  // const [allRequestsView, setAllRequestsView] = React.useState(false);
  const [allRequestsView, setAllRequestsView] = React.useState(false);
  // To trigger refresh of partner info we increment a counter that useEffect watches below
  const [refreshPartnerCounter, setRefreshPartnerCounter] = React.useState(0);
  const refreshPartner = () => {
    setRefreshPartnerCounter((count) => count + 1);
    onUpdatePartners();
  };
  // const [partner, setPartner] = React.useState<Partner>(null);
  const [partner, setPartner] = React.useState(null);
  const { search } = location || {};

  React.useEffect(() => {
    const qs = queryString.parse(search, { ignoreQueryPrefix: true });
    const accountSlug = qs.account || null;
    const partnerSlug = qs.partner || null;
    setAllRequestsView(!accountSlug && !partnerSlug);
    setFilters({ accountSlug, partnerSlug });
  }, [search]);

  React.useEffect(() => {
    let ok = true;
    if (!filters) {
      return () => {
        ok = false;
      };
    }
    const { partnerSlug } = filters;
    if (partnerSlug) {
      fetchPartnerBySlug(partnerSlug)
        .then((partnerResp) => ok && setPartner(partnerResp))
        .catch((err) => {
          if (err && err.response && err.response.status === 404) {
            setPartner(null);
          }
        });
    }
    return () => {
      ok = false;
    };
  }, [filters, refreshPartnerCounter]);

  const setFilter = (val: ?SearchIndexItem) => {
    // This will be an object from unisearchal, kind, name, slug
    const accountSlug = val && val.kind === 'account' ? val.slug : null;
    const nextFilter = { accountSlug };
    if (isEqual(filters, nextFilter)) {
      return;
    }
    setFilters((currFilters) => ({ ...currFilters, accountSlug }));
  };

  const hasFilters = filters && (filters.accountSlug || filters.partnerSlug);

  return (
    <OrgUserContext.Consumer>
      {({ org, orguser }) => (
        <AccountMapping
          onChange={setFilter}
          hasFilters={hasFilters}
          partners={partners}
          filters={filters || {}}
          allRequestsView={allRequestsView}
          partner={partner}
          onApprove={decrementPartnerRequests}
          onDecline={decrementPartnerRequests}
          org={org}
          orguser={orguser}
          refreshPartner={refreshPartner}
        />
      )}
    </OrgUserContext.Consumer>
  );
}

const mutualOnly = true;

export default withPartners(AccountMappingContainer, mutualOnly);
