import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import UserInactive from 'views/Auth/UserInactive';
import { mustCompleteProfile } from 'views/CompleteProfile/CompleteProfile';
import SeatlessBlacklist from 'views/SeatlessBlacklist';

const loginPath = '/login';
const completeProfilePath = '/complete-profile';

export default function AuthenticatedRoute(props) {
  const { isLoading, loader, user, org, orguser, isSignup, ...routeProps } = props;
  const { location, path } = routeProps;

  function doRoute() {
    // Follow the route as intended
    return <Route {...routeProps} />;
  }

  function doRedirect(redirectTo, redirectFrom) {
    // -- Do a redirect unless the redirect is to this route.
    const to = {
      pathname: redirectTo,
      state: { redirectFrom },
    };
    if (location.pathname === redirectTo) {
      return doRoute();
    }
    return (
      <Route
        path={path}
        location={location}
        render={() => <Redirect to={to} from={redirectFrom} />}
      />
    );
  }

  // -- Loading
  if (isLoading) {
    // A "loader" component can be displayed while loading user info.
    return <Route path={path} location={location} render={() => loader || <div />} />;
  }

  // -- No user, must register/login
  if (!user) {
    return doRedirect(loginPath, location);
  }

  // check if User dont have firstname or lastname needs to complete login
  const requireProfile = mustCompleteProfile(user);
  if (requireProfile && !isSignup && location.pathname !== completeProfilePath) {
    return doRedirect(completeProfilePath, location);
  }

  // -- Orguser is not active or there is no role.
  if (!orguser.isActive || !orguser.role) {
    return <Route component={UserInactive} />;
  }

  // -- Allow paths that have any of the following roots.
  const ALLOWED_SEATLESS_ROOTS = [
    '/navigator',
    '/requests',
    '/p',
    '/settings',
    '/crossbeam',
    '/oauth',
    `/network/oid/${orguser.id}`,
    '/my-orgs',
    '/complete-profile',
  ];

  // -- Allow only exact paths.
  const ALLOWED_SEATLESS_PATHS = ['/', '/lists', '/network/p', '/admin/users'];

  // -- Disallow only exact paths.
  const DISALLOWED_SEATLESS_PATHS = ['/requests/new', '/requests/log-activity'];

  // -- If the user is seatless, we need to check if they are allowed to access the path.
  if (
    !orguser.capabilities.canAccessSeatedExperience &&
    (DISALLOWED_SEATLESS_PATHS.some((p) => location.pathname === p) ||
      !(
        ALLOWED_SEATLESS_ROOTS.some((p) => location.pathname.startsWith(p)) ||
        ALLOWED_SEATLESS_PATHS.some((p) => location.pathname === p)
      ))
  ) {
    return <Route component={SeatlessBlacklist} />;
  }

  // -- Follow the route as desired
  return <Route {...routeProps} />;
}
