// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Lists from './Lists';
import Sidebar from './Sidebar';

const ListsRouter = () => (
  <div className="w-100 d-flex flex-row mb-5 gap-30">
    <Sidebar />
    <div className="flex-1" style={{ width: '0px' }}>
      <Switch>
        <Route path={['/lists', '/lists/']} exact component={Lists} />
        <Route path="/lists/:id" component={List} />
      </Switch>
    </div>
  </div>
);

export default ListsRouter;
