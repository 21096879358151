// @flow

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequest, Org } from 'data/entities';
import { statusChoices } from 'data/entities/bdrequest';
import { updateBdRequestStatus } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';

import CompletionModal from './CompletionModal';

const Icon = () => <FontAwesomeIcon icon="check" className="mr-2" />;

type Props = {
  request: BdRequest,
  onUpdate: () => void,
  loggedInOrg: Org,
};

const MarkCompleted = ({ request, onUpdate, loggedInOrg }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const completed = request.status === statusChoices.COMPLETED;
  const attributionEnabled = loggedInOrg && loggedInOrg.settings.attributionEnabled;

  const markComplete = () => {
    setIsSubmitting(true);
    updateBdRequestStatus(request.id, statusChoices.COMPLETED)
      .then(() => {
        onUpdate();
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
      });
  };

  return completed ? (
    <button type="button" className="BdRequestStatusButton BdRequestStatusButtonCompleted" disabled>
      <Icon />
      Completed
    </button>
  ) : (
    <>
      <button
        onClick={() => (attributionEnabled ? setShowModal(true) : markComplete())}
        type="button"
        className="BdRequestStatusButton"
        style={{ width: 180 }}
      >
        {isSubmitting ? (
          <LoadingRing maxWidth="20px" />
        ) : (
          <>
            <Icon /> Mark as complete
          </>
        )}
      </button>
      {attributionEnabled && (
        <CompletionModal
          request={request}
          onUpdate={onUpdate}
          open={showModal}
          toggle={() => setShowModal((o) => !o)}
        />
      )}
    </>
  );
};

export default withOrguser(MarkCompleted);
