// @flow

import React from 'react';

import LoadingRing from 'components/LoadingRing';

type Props = {
  onPrevious: () => void,
  onNext: () => void,
  className?: string,
  page?: ?number,
  loading?: boolean,
};

const PaginationButtons = ({ onPrevious, onNext, className, page, loading }: Props) => {
  const pageCount = page ? <div>{page}</div> : '|';
  return (
    <div className={className || ''}>
      <div className="d-flex align-items-center gap-10">
        <button
          type="button"
          className="btn"
          onClick={onPrevious}
          disabled={!onPrevious || loading}
        >
          Previous
        </button>
        {pageCount}
        <button type="button" className="btn" onClick={onNext} disabled={!onNext || loading}>
          Next
        </button>
        {loading && <LoadingRing maxWidth="20px" className="inline" />}
      </div>
    </div>
  );
};

PaginationButtons.defaultProps = {
  className: '',
  page: null,
  loading: false,
};

export default PaginationButtons;
