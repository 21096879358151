// @flow

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import Modal from 'components/Modal';
import ProfileAvatarSettings from 'components/ProfileUserSettings/ProfileAvatarSettings';
import ProfileFormSettings from 'components/ProfileUserSettings/ProfileFormSettings';

type Props = {
  loggedInOrguser: OrgUser,
};

function ProfileUserSettings({ loggedInOrguser }: Props) {
  const history = useHistory();
  const { pathname } = history.location || {};

  const profilePath = `/network/oid/${loggedInOrguser.id}`;
  const editPath = `${profilePath}/edit-profile`;

  const isOpen = pathname === editPath;
  const toggle = () => history.replace(isOpen ? profilePath : editPath);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} title="Profile Settings">
        <ProfileAvatarSettings />
        <ProfileFormSettings onUpdated={toggle} />
      </Modal>
      <button onClick={toggle} className="btn btn-secondary" type="button">
        <FontAwesomeIcon icon="pencil-alt" className="mr-2" />
        Edit Profile
      </button>
    </>
  );
}

export default withOrguser(ProfileUserSettings);
