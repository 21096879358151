// @flow

import { PartnerApprovedMap, SuggestedAccountMap } from 'data/entities';
import { PendingPartnerApprovalMap } from 'data/entities/accountMapping';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

function urlWithParams(url, accountSlug: ?string, partnerSlug: ?string) {
  const params = [];

  if (accountSlug) {
    params.push(`account_slug=${accountSlug}`);
  }
  if (partnerSlug) {
    params.push(`partner_slug=${partnerSlug}`);
  }

  return `${url}?${params.join('&')}`;
}

export function fetchAccountMappingPredictions(
  partnerSlug: ?string,
  accountSlug: ?string,
  nextUrl: ?string
): Promise<PaginatedResponse<SuggestedAccountMap>> {
  const url =
    nextUrl ||
    urlWithParams(`${orgUrlBase()}/mappy/account-predictions/`, accountSlug, partnerSlug);
  return authFetch(url).then(mapPaginatedResponse(SuggestedAccountMap.fromApi));
}

export function fetchPartnerApprovedMapping(
  partnerSlug: ?string,
  accountSlug: ?string,
  nextUrl: ?string
): Promise<PaginatedResponse<PartnerApprovedMap>> {
  const url =
    nextUrl || urlWithParams(`${orgUrlBase()}/mappy/partner-approved/`, accountSlug, partnerSlug);
  return authFetch(url).then(mapPaginatedResponse(PartnerApprovedMap.fromApi));
}

export function fetchUnapprovedAccountPartners(
  partnerSlug: ?string,
  accountSlug: ?string,
  nextUrl: ?string
): Promise<PaginatedResponse<PartnerApprovedMap>> {
  const url =
    nextUrl ||
    urlWithParams(`${orgUrlBase()}/mappy/unapproved-account-partners/`, accountSlug, partnerSlug);
  return authFetch(url).then(mapPaginatedResponse(PartnerApprovedMap.fromApi));
}

export function fetchPendingRequests(
  partnerSlug: ?string,
  accountSlug: ?string,
  nextUrl: ?string
): Promise<PaginatedResponse<PendingPartnerApprovalMap>> {
  const url =
    nextUrl || urlWithParams(`${orgUrlBase()}/mappy/pending-requests/`, accountSlug, partnerSlug);
  return authFetch(url).then(mapPaginatedResponse(PendingPartnerApprovalMap.fromApi));
}

export function approveAccountMapping(
  accountSlug: string,
  partnerAccountId: number,
  isTarget: boolean = false
): Promise<any> {
  const url = `${orgUrlBase()}/mappy/partner-approved/`;
  return authFetch(url, {
    method: 'POST',
    body: { account_slug: accountSlug, partner_account_id: partnerAccountId, is_target: isTarget },
  });
}

export function approveAllAccountMappingPartnerRequests(partnerId: string): Promise<any> {
  const url = `${orgUrlBase()}/mappy/partner-approved/accept-all-requests/`;
  return authFetch(url, { method: 'POST', body: { partner_id: partnerId } });
}
