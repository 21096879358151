// @flow

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

type StringObject = { [key: string]: string | number };

/** Set URL Parameters */
const setParams = (history: History, oldParams: StringObject) => (
  params: StringObject,
  clear: ?boolean = true
) => {
  const newParams = clear ? params : { ...oldParams, ...params };
  // If clear is false, keep already set parameters.
  history.replace({
    pathname: history.location.pathname,
    search: `?${Object.keys(newParams)
      .filter(
        (key) =>
          newParams[key] !== undefined &&
          newParams[key] !== null &&
          newParams[key] !== '' &&
          newParams[key].length !== 0
      )
      .map((key) => `${key}=${newParams[key]}`)
      .join('&')}`,
  });
};

/** URL Parameters
 *
 * Returns an object of all URL parameters.
 * @returns {Object.<string, (string|number)>} Parameters
 */
const useParams = (defaultParams: StringObject) => {
  const history: History = useHistory();

  const params = {};
  new URLSearchParams(history.location.search).forEach((value, key) => {
    params[key] = value.includes(',') ? value.split(',') : value;
  });
  const setParamsWithHistory = setParams(history, params);

  // If there are default params set them.
  // Keep already set params.
  useEffect(() => {
    if (defaultParams && Object.keys(defaultParams).length) {
      setParamsWithHistory({ ...defaultParams, ...params }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [params, setParamsWithHistory];
};

export default useParams;
