// @flow

import * as React from 'react';
import { matchPath, withRouter } from 'react-router-dom';

import { skipNull } from 'utils/nodash';
import type { LocationT } from 'sharedTypes/reactRouter';

const EXEMPT_PATH_NAMES = [
  '/login',
  '/logout',
  '/forget-password',
  '/onboarding',
  '/complete-signup',
  '/complete-signup/details',
  '/complete-profile',
  '/oauth/salesforce-data',
  '/oauth/salesforce-data-success',
  '/oauth/salesforce-login',
  '/oauth/slack-integration',
  '/oauth/sso-login',
  '/oauth/hubspot-login',
  '/email/verify/:token',
  '/invite/:inviteId',
];

type Props = {
  location: LocationT,
};

function BentoSideBar({ location }: Props) {
  const res = skipNull(
    EXEMPT_PATH_NAMES.map((path) => matchPath(location.pathname, { path, exact: true }))
  );
  const show = !(res && res.length > 0);

  if (!show) {
    return null;
  }
  return <bento-sidebar />;
}

export default withRouter(BentoSideBar);
