// @flow

import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { debounce, get, isEqual } from 'lodash';
import queryString from 'qs';

import type { Account } from 'data/entities';
import { fetchPaginatedAccounts } from 'data/repositories/accounts';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import LoadingRing from 'components/LoadingRing';
import BasePaginationButtons from 'components/PaginationButtons';

import AccountRow from './AccountRow';
import AccountsListFilters from './AccountsListFilters';

const LIMIT = 20;

type AccountsTableProps = {
  accounts: Account[],
  isLoading: boolean,
};

function AccountsTable({ accounts, isLoading }: AccountsTableProps) {
  if (isLoading) {
    return <LoadingRing maxWidth="50px" className="my-3" />;
  }
  if (!accounts || accounts.length === 0) {
    return (
      <div className="text-center my-5">
        <h6>No overlapping accounts found. Try adjusting the filters.</h6>
      </div>
    );
  }
  return (
    <div>
      {accounts.map((a) => (
        <AccountRow account={a} key={a.id} />
      ))}
    </div>
  );
}

type State = {
  results: Account[],
  isLoading: boolean,
  next: ?string,
  previous: ?string,
  isLoadingPagination: boolean,
  currentFilters: any,
  isLoadingFilters: boolean,
};

type Props = {
  query: any,
  url: string,
  history: RouterHistoryT,
};

class AccountLists extends React.Component<Props, State> {
  state = {
    results: [],
    isLoading: true,
    currentFilters: {},
    next: null,
    previous: null,
    isLoadingPagination: true,
    isLoadingFilters: true,
  };

  componentDidMount() {
    this.debouncedUpdate = debounce(this.loadAccounts, 300);
    this.debouncedUpdate();
  }

  componentDidUpdate(prevProps: ContainerProps) {
    if (!isEqual(get(prevProps, 'query'), get(this.props, 'query'))) {
      const { results } = this.state;
      const hasAccounts = results && results.length;
      const nextState = !hasAccounts
        ? { isLoading: true, isLoadingFilters: true, isLoadingPagination: true }
        : { isLoadingFilters: true, isLoadingPagination: true };
      this.setState(nextState, () => this.debouncedUpdate());
    }
  }

  debouncedUpdate: (Props) => void = () => {};

  changePage = (page: string) => {
    const { url, query, history } = this.props;
    const { limit, offset } = queryString.parse(page, { ignoreQueryPrefix: true });
    const queryStr = queryString.stringify(
      { ...query, limit, offset },
      { skipNulls: true, encode: false, arrayFormat: 'brackets' }
    );
    const goto = queryStr ? `${url}?${queryStr}` : url;
    history.push(goto);
  };

  PaginationButtons = () => {
    const { currentFilters, next, previous, isLoadingPagination } = this.state;
    const onNext = next
      ? () => {
          this.setState({ isLoadingPagination: true }, () => {
            this.changePage(next);
          });
        }
      : null;
    const onPrevious = previous
      ? () => {
          this.setState({ isLoadingPagination: true }, () => {
            this.changePage(previous);
          });
        }
      : null;
    return (
      <BasePaginationButtons
        onNext={onNext}
        onPrevious={onPrevious}
        page={
          currentFilters.offsetInt / (currentFilters.limitInt || currentFilters.offsetInt || 1) + 1
        }
        isLoading={isLoadingPagination}
      />
    );
  };

  loadAccounts = (pageUrl: ?string) => {
    const { query } = this.props;
    fetchPaginatedAccounts({ limit: LIMIT, ...query })
      .then(({ results: accounts, next, previous }) => {
        this.setState({
          results: accounts,
          next,
          previous,
          isLoading: false,
          isLoadingPagination: false,
          isLoadingFilters: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isLoadingPagination: false,
          isLoadingFilters: false,
        });
      });
  };

  render() {
    const { results, isLoading, isLoadingFilters } = this.state;
    const { query, history, url } = this.props;
    const countDisplay = isLoadingFilters ? (
      <LoadingRing maxWidth="20px" className="inline" />
    ) : null;
    return (
      <>
        <div className="d-flex align-items-center mb-0 mt-4">
          <h5 className="mr-2">Accounts with Partner Overlap {countDisplay}</h5>
        </div>
        <div className="card pb-3 mt-2">
          <div className="card-body p-4">
            <AccountsListFilters query={query} history={history} url={url} />
            <Row className="pt-3">
              <Col xs={4}>
                <div className="large-text bold">Account</div>
              </Col>
              <Col xs={8}>
                <div className="large-text bold">
                  Partner Overlap{' '}
                  <span className="fs-14 font-weight-normal text-muted">
                    <em>All partners overlapping with the matching accounts</em>
                  </span>
                </div>
              </Col>
            </Row>
            <AccountsTable accounts={results} isLoading={isLoading} />
            <div className="pull-right mt-2">{this.PaginationButtons()}</div>
          </div>
        </div>
      </>
    );
  }
}

function AccountListContainer(props) {
  const {
    location,
    match: { url },
  } = props;
  const query = queryString.parse(location ? location.search : {}, {
    ignoreQueryPrefix: true,
  });
  return <AccountLists {...props} query={query} url={url} />;
}

export default AccountListContainer;
