// @flow

import * as React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Partner } from 'data/entities';
import { setPartnerDescription } from 'data/repositories/partners';

import Modal from 'components/Modal';

type Props = {
  partner: Partner,
};

const PartnerDescriptionAndModal = ({ partner }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [updatedDescription, setUpdatedDescription] = useState(partner.description);
  const [savedDescription, setSavedDescription] = useState(partner.description);
  const [submitting, setSubmitting] = useState(false);

  const onSave = () => {
    setSubmitting(true);
    setPartnerDescription(partner.slug, updatedDescription || '')
      .then(() => {
        setSubmitting(false);
        setSavedDescription(updatedDescription);
        toggle();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="mt-3">
      {savedDescription ? (
        <>
          {savedDescription}
          <div className="mt-2" style={{ color: '#4380AA', fontWeight: 'bold' }}>
            Edit
            <button type="button" className="btn btn-link blue m-0 p-0" onClick={toggle}>
              <FontAwesomeIcon icon="pencil" className="ml-2" size="sm" />
            </button>
          </div>
        </>
      ) : (
        <>
          <span>No partner description added</span>

          <button type="button" className="btn btn-link blue m-0 p-0" onClick={toggle}>
            <FontAwesomeIcon icon="pencil" className="ml-2" size="sm" />
          </button>
        </>
      )}

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title="Partner Description"
        footer={
          <>
            <button
              type="button"
              disabled={submitting}
              className="btn btn-secondary"
              onClick={toggle}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={submitting}
              onClick={onSave}
              className="btn btn-primary text-center"
            >
              Save
            </button>
          </>
        }
      >
        <span>Description Text</span>
        <textarea
          placeholder="Add a description..."
          onChange={(e) => {
            const { value } = e.target;
            setUpdatedDescription(value);
          }}
          value={updatedDescription}
          style={{
            height: 168,
            border: '1px solid #DDE5F0',
            paddingLeft: 10,
            paddingTop: 8,
          }}
        />
      </Modal>
    </div>
  );
};

export default PartnerDescriptionAndModal;
