// @flow

import * as React from 'react';

import { Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';

import AccountData from './AccountData';
import BdRequestQuestions from './BdRequestQuestions';
import SlackIntegration from './SlackIntegration';

type Props = {
  loggedInOrg: Org,
};

const Organization = ({ loggedInOrg }: Props) => (
  <div className="d-flex flex-column">
    <h5 className="mt-4 bold gray-700">Partner & Account Data</h5>

    <AccountData />

    <h5 className="mt-3 mb-3 bold gray-700">Integrations</h5>
    <SlackIntegration />

    {loggedInOrg.settings.requestsWorkflow && (
      <>
        <h5 className="mt-3 mb-3 bold gray-700">Co-Selling Templates</h5>
        <BdRequestQuestions />
      </>
    )}
  </div>
);

export default withOrguser(Organization);
