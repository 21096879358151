import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/* Scroll to top on path */
const ScrollToTop = () => {
  const { location } = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    const pageContainer = document.getElementById('page-container');
    if (pageContainer) {
      pageContainer.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;
