// @flow

import { isNil, omitBy } from 'lodash';
import queryString from 'qs';

import { OrgUser } from 'data/entities';
import { OrgUserNameOnly } from 'data/entities/orguser';
import OrgUserWithOrg from 'data/entities/orguserWithOrg';
import { settingsToSnakeCase, skipNull } from 'utils/nodash';
import stringifyOptions from 'utils/stringifyOptions';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

type OrgUsersOptions = {
  isActive?: ?boolean,
  search?: ?string,
  role?: ?string,
  includeOverlaps?: ?boolean,
  needInvite?: ?boolean,
  recentlyCreated?: ?boolean,
  hasOverlaps?: ?boolean,
  canManagePartnerships?: ?boolean,
};

export const fetchOrgusers = (options: ?OrgUsersOptions): Promise<PaginatedResponse<OrgUser>> => {
  const {
    isActive,
    search,
    role,
    includeOverlaps,
    needInvite,
    recentlyCreated,
    hasOverlaps,
    canManagePartnerships,
    isAccountOwner,
  } = options || {};
  const params = [
    ['active', isActive],
    ['search', search],
    ['role', role],
    ['include_overlaps', includeOverlaps],
    ['need_invite', needInvite],
    ['recently_created', recentlyCreated],
    ['has_overlaps', hasOverlaps],
    ['capability', canManagePartnerships ? 'can_manage_partnerships' : null],
    ['is_account_owner', isAccountOwner],
  ];
  const url = `${orgUrlBase()}/orgusers${stringifyOptions(params)}`;
  return authFetch(url, { credentials: 'include' }).then(mapPaginatedResponse(OrgUser.fromApi));
};

export function bdRequestMentionableUsers(
  search: ?string,
  bdRequestId: ?string,
  partnerAccountId: ?string,
  partnerId: ?string
): Promise<PaginatedResponse<OrgUserNameOnly>> {
  const params = {
    search,
    bdrequest_id: bdRequestId,
    partner_account_id: partnerAccountId,
    partner_id: partnerId,
  };
  const qs = queryString.stringify(skipNull(params));
  const url = `${orgUrlBase()}/bdrequests/mentionable-users?${qs}`;
  return authFetch(url, { credentials: 'include' }).then(
    mapPaginatedResponse(OrgUserNameOnly.fromApi)
  );
}

export function fetchCountWithUser(): Promise<number> {
  return authFetch(`${orgUrlBase()}/orgusers/count-with-user`).then(({ count }) => count);
}

export function fetchNextOrgusers(nextUrl: string) {
  return authFetch(nextUrl, { credentials: 'include' }).then(mapPaginatedResponse(OrgUser.fromApi));
}

export function fetchOrgUser(orguserId: string): Promise<OrgUser> {
  const url = `${orgUrlBase()}/orgusers/${orguserId}`;
  return authFetch(url, { credentials: 'include' }).then(OrgUser.fromApi);
}

export function updateMeOrgUser(settings: { settings?: any }): Promise<OrgUser> {
  // Get rid of empty strings here
  const body = omitBy(settings, isNil);
  return authFetch('orgusers/me', { method: 'PATCH', credentials: 'include', body }).then(
    OrgUser.fromApi
  );
}

export function getMeOrgUser(): Promise<OrgUser> {
  const url = `${orgUrlBase()}/orgusers/me`;
  return authFetch(url, { method: 'GET' }).then(OrgUser.fromApi);
}

export function updateOrguser(
  orguserId: string,
  settings: {
    role: ?string,
    hidden?: boolean,
    email?: string,
    isActive?: boolean,
    sendInvite: boolean,
  }
): Promise<OrgUser> {
  const url = `${orgUrlBase()}/orgusers/${orguserId}`;
  // Get rid of empty strings here
  const role = settings.role || null;
  const payload = {
    ...settingsToSnakeCase(settings),
    role,
  };
  const body = omitBy(payload, isNil);
  return authFetch(url, { method: 'PATCH', credentials: 'include', body }).then(OrgUser.fromApi);
}

export type CreateUserProps = {
  email: string,
  role: string,
};

export function createOrguser(userProps: CreateUserProps): Promise<OrgUser> {
  const url = `${orgUrlBase()}/orgusers`;
  // Get rid of empty strings here
  const { email, role } = userProps;
  const payload = { email, role };
  const body = omitBy(payload, isNil);
  return authFetch(url, { method: 'POST', body, credentials: 'include' }).then(OrgUser.fromApi);
}

export function searchPartnerAccountOwners(
  slug: string,
  search: string
): Promise<{ results: OrgUser[] }> {
  const params = { search };
  const qs = queryString.stringify(params);
  const url = `${orgUrlBase()}/partners/${slug}/partner-account-owners?${qs}`;
  return authFetch(url).then((values) => ({ results: values && values.map(OrgUser.fromApi) }));
}

export function fetchPartnerAccountOwner(slug: string, id: string): Promise<OrgUser> {
  const url = `${orgUrlBase()}/partners/${slug}/partner-account-owners?id=${id}`;
  return authFetch(url).then(OrgUser.fromApi);
}

export function myOrgusers(): Promise<PaginatedResponse<OrgUserWithOrg>> {
  // Return orgusers associated with your identity
  return authFetch('my-orgusers').then(mapPaginatedResponse(OrgUserWithOrg.fromApi));
}
