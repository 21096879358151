// @flow

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import itlySdk from 'itly';

import { Org, OrgUser } from 'data/entities';
import { environmentName, isLocalDev, isProduction } from 'utils/environment';
import { enableFullStory } from 'utils/fullstory';

export function initializeTrackingLibraries() {
  // Utilized in the index.js to initialize the tracking libraries
  Sentry.init({
    dsn: 'https://49e99db458a34d689d3e5bf592e16d7c@o230132.ingest.sentry.io/4504123665416192',
    environment: environmentName,
    enabled: isProduction,
    tracesSampleRate: 0.1,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'sales.crossbeam.com',
          'sales.cb.c7m.io',
          'sales.crossbeam-dev.com',
          /^\//,
        ],
      }),
    ],
    ignoreErrors: [
      'timeout of 0ms exceeded',
      'Network Error',
      'Non-Error exception captured',
      'Non-Error promise rejection',
    ],
    denyUrls: [/assets\.trybento\.co/i],
  });

  const itlyLoadOptions = {
    disabled: isLocalDev,
    environment: isProduction ? 'production' : environmentName, // itly cares about 'production' specifically
    destinations: {
      segment: {
        // We add full story ourselves, and don't use the one connected to Segment
        integrations: { FullStory: false, Fullstory: false, 'Fullstory (Actions)': false },
      },
    },
  };
  itlySdk.load(itlyLoadOptions);
}

export function identifyOrgUsers(orguser: OrgUser, org: Org, intercomHash: string): boolean {
  // Utilized in App.jsx to identify the orguser, when they become known or updated
  if (isLocalDev || !orguser) {
    return false;
  }
  const { id, email, crossbeamId } = orguser;
  // Identify Sentry user
  Sentry.setUser({ id, username: email });

  if (!orguser.isGhost) {
    // Register Segment identity and group (skip for "ghost" users)
    itlySdk.identify(
      crossbeamId,
      { email },
      {
        segment: {
          // We add full story ourselves, and don't use the one connected to Segment
          integrations: { FullStory: false, Fullstory: false, 'Fullstory (Actions)': false },
        },
      }
    );
    // Initialize FullStory with user
    enableFullStory(orguser, org);
    return true;
  }
  return false;
}
