// @flow

import MinimalUser from './minimalUser';

class User extends MinimalUser {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  isActive: boolean;

  isOrgMember: boolean;

  avatarImage: string;

  hasPartnerInvites: boolean;

  static fromApi = (d: any): User => {
    const u = new User();
    u.id = d.id;
    u.firstName = d.first_name;
    u.lastName = d.last_name;
    u.email = d.email;
    u.isActive = d.is_active;
    u.isOrgMember = d.is_org_member;
    u.avatarImage = d.avatar_image;
    u.hasPartnerInvites = d.has_partner_invites;

    return u;
  };
}

export default User;
