// @flow

import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import stringifyOptions from 'utils/stringifyOptions';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

type BdRequestQuestionOptions = {
  search?: string,
  forPartnersIds?: string[],
  excludePartnerIds?: string[],
};

export const getBdRequestQuestions = (
  options?: BdRequestQuestionOptions
): Promise<PaginatedResponse<BdRequestQuestion>> => {
  const { search, forPartnersIds, excludePartnerIds } = options || {};
  const params = [
    ['q', search],
    ['for_partners_ids', forPartnersIds],
    ['exclude_partner_ids', excludePartnerIds],
  ];

  const url = `${orgUrlBase()}/bdrequests-questions${stringifyOptions(params)}`;
  return authFetch(url).then(mapPaginatedResponse(BdRequestQuestion.fromApi));
};

export const updateRequestWorkflowSettings = (
  allowCustomQuestions: boolean,
  talkingPoints: string[]
) => {
  const url = `${orgUrlBase()}/bdrequests-questions`;
  const body = { allow_custom_questions: allowCustomQuestions, talking_points: talkingPoints };

  return authFetch(url, { method: 'PATCH', body });
};

export const createBdRequestQuestion = (
  text: string,
  isForAllPartners: boolean,
  forPartnersIds?: string[]
) => {
  const url = `${orgUrlBase()}/bdrequests-questions`;
  const body = { text, is_for_all_partners: isForAllPartners, for_partners_ids: forPartnersIds };

  return authFetch(url, { method: 'POST', body }).then(BdRequestQuestion.fromApi);
};

export const updateBdRequestQuestion = (
  id: string,
  text: string,
  isForAllPartners: boolean,
  forPartnersIds?: string[]
) => {
  const url = `${orgUrlBase()}/bdrequests-questions/${id}`;
  const body = { text, is_for_all_partners: isForAllPartners, for_partners_ids: forPartnersIds };

  return authFetch(url, { method: 'PATCH', body }).then(BdRequestQuestion.fromApi);
};

export const deleteBdRequestQuestion = (id: string) => {
  const url = `${orgUrlBase()}/bdrequests-questions/${id}`;

  return authFetch(url, { method: 'DELETE' });
};

export const getBdRequestMessageSummary = (
  partnerName: string,
  questions: string[],
  customQuestions: string[],
  answers: string[]
): Promise<string> => {
  const url = `${orgUrlBase()}/bdrequests/parse-message-for-request-workflow`;

  const body = {
    partner_name: partnerName,
    questions_text: questions,
    custom_questions_text: customQuestions,
    talking_points_answers: answers,
  };

  return authFetch(url, { method: 'POST', body }).then((response) => response?.text);
};
