// @flow

import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { Org } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import NewBdRequestForm from 'views/BdRequests/NewBdRequest/NewBdRequestForm';

type Props = {
  history: RouterHistoryT,
  showLogActivity?: boolean,
  loggedInOrg: Org,
};

const NewBdRequestActivity = ({ history, showLogActivity, loggedInOrg }: Props) => (
  <div className="NewBdRequest mt-3 row justify-content-md-center">
    <div className="col-lg-7 col-md-9 col-sm-11 mt-4">
      <Card>
        <CardBody>
          <NewBdRequestForm history={history} logActivity={showLogActivity} />
        </CardBody>
      </Card>
    </div>
  </div>
);

NewBdRequestActivity.defaultProps = {
  showLogActivity: false,
};

export default withOrguser(NewBdRequestActivity);
