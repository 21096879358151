// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account, Opportunity } from 'data/entities';
import { updateBdRequestAccount } from 'data/repositories/bdrequests';

import FieldErrors from 'components/FieldErrors';
import InfoTip from 'components/InfoTip';
import Modal from 'components/Modal';
import Unisearchal from 'components/Unisearchal';

import CompanyLogo from './CompanyLogo';
import OpportunitySearch from './OpportunitySearch';

type Props = {
  requestId: string,
  currentAccount: Account,
  onUpdate: () => void,
  disabled?: boolean,
};

type State = {
  showSelectOpportunity: boolean,
  opportunityId: string,
  errors: any,
  loading: boolean,
  selectedAccount: Account,
};

class AccountEditSearch extends React.Component<Props, State> {
  state = {
    showSelectOpportunity: false,
    opportunityId: '',
    errors: {},
    selectedAccount: null,
    loading: false,
  };

  onSelectAccount = (newAccount: Account) => {
    const { currentAccount } = this.props;
    if (currentAccount && currentAccount.slug === newAccount && newAccount.slug) {
      return;
    }
    this.setState({ selectedAccount: newAccount });
  };

  onSelectOpportunity = (opportunity: Opportunity) => {
    this.setState({ opportunityId: opportunity?.id });
  };

  triggerUpdate = () => {
    const { requestId, onUpdate } = this.props;
    const { selectedAccount, opportunityId } = this.state;
    this.setState({ loading: true });
    return updateBdRequestAccount(requestId, selectedAccount.slug, opportunityId)
      .then(() => {
        onUpdate();
        this.closeModal();
        this.setState({ loading: false });
      })
      .catch((errors) => {
        this.setState({ errors: errors.json });
        this.setState({ loading: false });
      });
  };

  closeModal = () => {
    this.setState({
      showSelectOpportunity: false,
      opportunityId: '',
      errors: {},
      selectedAccount: null,
    });
  };

  toggleModal = () => {
    this.setState(({ showSelectOpportunity: prevValue }) => ({
      showSelectOpportunity: !prevValue,
    }));
  };

  ErrorsForField = ({ field }) => {
    const { errors } = this.state;
    return <FieldErrors errors={errors && errors[field]} />;
  };

  render() {
    const { currentAccount, lockMessage, requestId, disabled } = this.props;
    const { selectedAccount, showSelectOpportunity, loading } = this.state;
    const displayValue = currentAccount ? currentAccount.name : '(none)';
    if (lockMessage) {
      return (
        <div className="fs-mask">
          {displayValue}{' '}
          <InfoTip targetId="accountEdit" icon="lock" className="fs-11 text-muted">
            {lockMessage}
          </InfoTip>
        </div>
      );
    }
    return (
      <>
        <Modal
          isOpen={showSelectOpportunity}
          onClosed={this.closeModal}
          toggle={this.toggleModal}
          title="Change account"
          footer={
            <>
              <Button color="primary" onClick={this.triggerUpdate} disabled={loading}>
                Save
              </Button>
              <Button color="secondary" onClick={this.toggleModal} disabled={loading}>
                Cancel
              </Button>
            </>
          }
          centered
        >
          <Form>
            <FormGroup>
              <Label>Account</Label>
              <Unisearchal
                onChange={this.onSelectAccount}
                bsSize="small"
                placeholder="Search for account..."
                emptyLabel="No matching accounts found"
                currentValue={[selectedAccount]}
                entityKind="account"
                stripUnisearchalClassName
              />
              <this.ErrorsForField fieldName="account" />
            </FormGroup>
            <FormGroup>
              <Label>Opportunity</Label>
              <OpportunitySearch
                onChange={this.onSelectOpportunity}
                requestId={requestId}
                account={selectedAccount}
                changeOnClear
              />
              <this.ErrorsForField field="opportunityId" />
            </FormGroup>
          </Form>
        </Modal>
        {!!(currentAccount && currentAccount.dashboardUrl) && (
          <>
            <CompanyLogo
              size={20}
              domain={currentAccount.website}
              name={currentAccount.name}
              className="mr-2"
            />
            <Link to={currentAccount.dashboardUrl} className="fs-mask">
              {currentAccount.name}
            </Link>
          </>
        )}
        {!disabled && (
          <button type="button" className="btn btn-link btn-xs" onClick={this.toggleModal}>
            <FontAwesomeIcon icon="pencil-alt" className="primary" />
          </button>
        )}
      </>
    );
  }
}

AccountEditSearch.defaultProps = {
  disabled: false,
};

export default AccountEditSearch;
