// @flow

type FetchResponse = Response;

class ErrorResponse {
  constructor(response: FetchResponse, json: ?{ [string]: any } = null) {
    const defaultJson = { nonFieldErrors: ['Unknown error occurred'] };
    this.response = response;
    this.json = json || defaultJson;
  }

  json: any;

  response: FetchResponse;
}

export type { FetchResponse };
export { ErrorResponse };
