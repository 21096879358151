// @flow

import React, { useEffect, useState } from 'react';
import { isNil } from 'lodash';

import type { Account } from 'data/entities';
import { activeOppRaw, getStatusComponent, wonRaw } from 'data/entities/status';
import {
  AccountDetailAccountPartner,
  fetchAccountPartnersByAccountSlug,
} from 'data/repositories/accounts';

import InfiniteList from 'components/InfiniteList';
import LoadingRing from 'components/LoadingRing';

import AccountPartnerCard from './AccountPartnerCard';

type Props = {
  account: Account,
  setIsScrolling: (isScrolling: boolean) => void,
};

const AccountPartners = ({ account, setIsScrolling }: Props) => {
  const [accountPartners, setAccountPartners] = useState<AccountDetailAccountPartner[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAccountPartners([]);
    setLoading(true);
    setLoadedAll(false);
    fetchAccountPartnersByAccountSlug(account.slug, {
      offset: 0,
      limit: 50,
    })
      .then((results) => {
        setAccountPartners(results.results);
        if (isNil(results.next)) {
          setLoadedAll(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [account.slug]);

  const loadMoreRows = (offset, limit) => {
    if (offset === 0 || loadedAll) {
      return null;
    }
    return fetchAccountPartnersByAccountSlug(account.slug, {
      offset,
      limit,
    }).then((ap) => {
      setAccountPartners((s) => s.concat(ap.results));
      if (isNil(ap.next)) {
        setLoadedAll(true);
      }
    });
  };

  return loading ? (
    <LoadingRing className="m-5" />
  ) : (
    <>
      {!accountPartners.length ? (
        <p className="m-3 align-items-center justify-content-center normal-text semi-bold gray-500 d-flex flex-row gap-5">
          No overlaps with partner {getStatusComponent(wonRaw, false, true)} or{' '}
          {getStatusComponent(activeOppRaw, false, true)}
        </p>
      ) : (
        <InfiniteList
          list={accountPartners}
          loadMoreRows={loadMoreRows}
          minHeight={90}
          loadedAll={loadedAll}
          columns={2}
          onScroll={(scrollTop: number) => {
            setIsScrolling(scrollTop !== 0);
          }}
          render={({ key, index, style, data, isLoaded }) => (
            <AccountPartnerCard
              isLoaded={isLoaded}
              key={key}
              account={account}
              accountPartner={data}
            />
          )}
        />
      )}
    </>
  );
};

export default AccountPartners;
