// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';

import UserHiddenIcon from 'views/Users/UserHiddenIcon';
import PersonAvatar from 'components/PersonAvatar';
import Pill from 'components/Pill';

type Props = {
  user: OrgUser,
  org: Org,
  onModify: () => void,
};

const RoleDisplay = ({ user }: { user: OrgUser }) => (
  <span className="normal-text semi-bold gray-700">
    {user.isActive ? (
      <span className="semi-bold gray-700">{user.roleDisplay ?? 'No Access'}</span>
    ) : (
      <span className="bold red">Deactivated</span>
    )}
  </span>
);

const BooleanPill = ({ condition, label }: { condition: boolean, label: string }) =>
  condition ? (
    <Pill
      icon={<FontAwesomeIcon icon="check" size="lg" />}
      className="d-flex align-items-end p-x-1"
      color="green"
      label={label}
    />
  ) : (
    <Pill
      icon={<FontAwesomeIcon icon="times" size="lg" />}
      className="d-flex align-items-end p-x-1"
      color="orange"
      label={`Not ${label}`}
    />
  );

const pluralizeWithCount = (count: number, singular: string) => {
  const word = count === 1 ? singular : `${singular}s`;
  return `${count} ${word}`;
};

const ManageUserTableRow = ({ user, onModify, org }: Props) => (
  <tr className="custom-table-row">
    <td className="custom-table-cell d-flex flex-row align-items-center gap-20" width="60%">
      <div className="d-flex flex-row align-items-center gap-10">
        <div>
          <PersonAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            avatar={user.avatarImage}
            size={48}
            noMargin
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center gap-5">
            <span className="large-text bold gray-700">{user.fullName}</span>
            <UserHiddenIcon user={user} />
          </div>
          <RoleDisplay user={user} />
          <span className="normal-text semi-bold gray-500">{user.email}</span>
        </div>
      </div>
    </td>
    <td className="custom-table-cell normal-text">
      <div className="d-flex flex-row align-items-center gap-10">
        {pluralizeWithCount(user.recentRequestCount, 'request')}
      </div>
    </td>
    <td className="custom-table-cell normal-text">
      <div className="d-flex flex-row align-items-center gap-10">
        <BooleanPill condition={user.hasChromeExtension} label="Installed" />
      </div>
    </td>
    <td className="custom-table-cell normal-text">
      <div className="d-flex flex-row align-items-center gap-10">
        {org.hasSlackIntegration ? (
          <BooleanPill condition={user.hasSlackIdentity} label="Connected" />
        ) : (
          <Link className="blue" to="/settings/">
            Install Slack App
          </Link>
        )}
      </div>
    </td>
  </tr>
);

export default ManageUserTableRow;
