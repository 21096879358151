// @flow

import React from 'react';

type Props = {
  text?: ?string,
  maxWidth?: string,
  className?: string,
};

const LoadingRing = ({ text, maxWidth, className }: Props) => {
  const classes = `LoadingRing text-center ${className || ''}`;
  return (
    <div className={classes}>
      <img
        className="image-responsive-height"
        style={{ maxWidth }}
        src="/assets/img/loading.gif"
        alt="Progress"
      />
      {text && <h5 className="text-center">{text}</h5>}
    </div>
  );
};

LoadingRing.defaultProps = {
  text: null,
  maxWidth: '100px',
  className: '',
};

export default LoadingRing;
