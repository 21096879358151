// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Account, AccountOwner, OrgUser } from 'data/entities';
import type { AccountPartnerBase } from 'data/entities/account';
import List, { ListAccountPartner } from 'data/entities/lists';
import { ACCOUNT_OWNER_CARD } from 'data/entities/tracking';
import type { AccountDetailAccountPartner } from 'data/repositories/accounts';
import withOrguser from 'contexts/withOrguser';

import DeleteFromList from 'views/Lists/DeleteFromList';
import CompanyLogo from 'components/CompanyLogo';
import ConnectedPartnerBadge from 'components/ConnectedPartnerBadge';
import LoadingRing from 'components/LoadingRing';
import NewBdRequestButton from 'components/NewBdRequestButton';
import Signals from 'components/Signals';
import TargetLabel from 'components/TargetLabel';

import ListPopup from '../Lists/ListPopup';

import AccountOwnerCard from './AccountOwnerCard';
import PartnersModal from './PartnersModal';

type Props = {
  account: Account | AccountPartnerBase,
  isLoaded: boolean,
  accountPartner: AccountDetailAccountPartner | ListAccountPartner,
  list?: List,
  onDelete?: (accountPartnerId: string) => void,
  noShadow?: boolean,
  loggedInOrguser: OrgUser,
};

const AccountPartnerCard = ({
  account,
  isLoaded,
  accountPartner,
  list,
  onDelete,
  noShadow,
  loggedInOrguser: { capabilities },
}: Props) => {
  const {
    partner,
    partnerAccount,
    isMyTarget,
    isPartnerTarget,
    signals,
    partnerAccountOwners,
  } = accountPartner;
  const hasAccountOwners = partnerAccountOwners && partnerAccountOwners.length !== 0;
  const [viewMore, setViewMore] = useState(false);

  return (
    <>
      <PartnersModal
        account={account}
        accountPartner={accountPartner}
        isOpen={viewMore}
        toggle={() => setViewMore((v) => !v)}
      />
      <div className={classNames('h-100 card m-0', noShadow && 'no-shadow')}>
        {isLoaded ? (
          <div className="d-flex flex-column card-body gap-20">
            <div className="d-flex flex-row align-items-center gap-20">
              <CompanyLogo
                className="m-0"
                size={48}
                name={partner.name}
                domain={partner.domain}
                logoUrl={partner.logoUrl}
              />
              <div className="d-flex flex-column gap-5 flex-fill">
                <div className="d-flex flex-row align-items-center gap-10">
                  <Link to={`/p/${partner.slug}`} className="large-text bold gray-700 btn-link">
                    {partner.name}
                  </Link>
                  {partner.isConnected && <ConnectedPartnerBadge iconOnly />}
                  <span className="normal-text semi-bold gray-500 ml-1">Account:</span>
                  <span className="normal-text semi-bold gray-700 fs-mask">
                    {partnerAccount.name}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center gap-5">
                  {partnerAccount.status}
                  <TargetLabel isMyTarget={isMyTarget} isPartnerTarget={isPartnerTarget} />
                </div>
              </div>
              {capabilities.canAccessSeatedExperience && (
                <>
                  {list && onDelete ? (
                    <DeleteFromList
                      onDelete={onDelete}
                      list={list}
                      accountPartnerId={accountPartner.id}
                    />
                  ) : (
                    <ListPopup
                      accountPartnerId={accountPartner.id}
                      disabled={capabilities.viewOnly}
                    />
                  )}
                </>
              )}
            </div>
            <Signals signals={signals} />
            <div className="card no-shadow m-0 card-body gap-20">
              {hasAccountOwners ? (
                <div className="gap-20" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  {partnerAccountOwners.slice(0, 2).map((accountOwner: AccountOwner) => (
                    <AccountOwnerCard
                      accountSlug={account.slug}
                      partner={partner}
                      partnerAccount={partnerAccount}
                      accountOwner={accountOwner}
                      key={accountOwner.id}
                    />
                  ))}
                </div>
              ) : (
                <div className="m-auto d-flex flex-column text-center justify-content-center">
                  <span className="normal-text semi-bold gray-500 mb-2">
                    No partner account owner available.
                  </span>
                  <div>
                    <NewBdRequestButton
                      accountSlug={account.slug}
                      partnerSlug={partner.slug}
                      partnerAccountId={partnerAccount.id}
                      apiSource={ACCOUNT_OWNER_CARD}
                      disabled={capabilities.viewOnly}
                      text="Message their Partner manager"
                    />
                  </div>
                </div>
              )}
              {hasAccountOwners && partnerAccountOwners.length - 2 > 0 && (
                <button
                  className="p-0 btn btn-link text-primary"
                  type="button"
                  // eslint-disable-next-line no-alert
                  onClick={() => setViewMore(true)}
                >{`Load more (${Math.max(partnerAccountOwners.length - 2, 0)})`}</button>
              )}
            </div>
          </div>
        ) : (
          <div className="m-0 card h-100 d-flex justify-content-center">
            <LoadingRing maxWidth="50px" />
          </div>
        )}
      </div>
    </>
  );
};

AccountPartnerCard.defaultProps = {
  list: null,
  onDelete: () => {},
  noShadow: false,
};

export default withOrguser(AccountPartnerCard);
