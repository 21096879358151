// @flow
import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

export type ErrorValue = Object | Array<any> | string;

type Props = {
  errors: null | ErrorValue,
  color?: string,
  noMargin?: boolean,
  className?: string,
};

const FieldErrors = ({ errors, color, noMargin, className }: Props) => {
  if (isNil(errors)) {
    return null;
  }

  const classes = classNames(
    'alert',
    `alert-${color || ''}`,
    noMargin ? 'm-0' : 'mt-1 mb-2',
    className
  );
  if (typeof errors === 'string') {
    return <div className={classes}>{errors}</div>;
  }
  if (Array.isArray(errors)) {
    return (
      <>
        {errors &&
          errors.map((e) => (
            <div className={classes} key={e}>
              {e}
            </div>
          ))}
      </>
    );
  }
  return null;
};

FieldErrors.defaultProps = {
  color: 'warning',
  noMargin: false,
  className: '',
};

export default FieldErrors;
