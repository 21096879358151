// @flow

import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get } from 'lodash';

import { Org, OrgUser } from 'data/entities';
import { updateMeOrgUser } from 'data/repositories/orgusers';
import withOrguser from 'contexts/withOrguser';
import { settingsToSnakeCase } from 'utils/nodash';

import FieldErrors from 'components/FieldErrors';
import SigninWithSlackButton from 'components/SigninWithSlackButton';
import SlackIntegrationButton from 'components/SlackIntegrationButton';
import SlackLogo from 'components/SlackLogo';

const NOTIFICATION_CHANNEL_SETTINGS = 'notificationChannelSettings';
const getNotificationChannelSettings = (orguser: OrgUser) =>
  cloneDeep(get(orguser, `settings.${NOTIFICATION_CHANNEL_SETTINGS}`, {}));

const SLACK_ENABLED = 'slackEnabled';
const getSlackEnabled = (orguser: OrgUser) =>
  cloneDeep(get(orguser, `settings.${NOTIFICATION_CHANNEL_SETTINGS}.${SLACK_ENABLED}`, false));

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  setOrgUser: (orguser: OrgUser) => void,
};

const SlackNotifications = ({ loggedInOrg, loggedInOrguser, setOrgUser }: Props) => {
  const [slackEnabled, setSlackEnabled] = useState(getSlackEnabled(loggedInOrguser));
  const { hasSlackIntegration } = loggedInOrg;
  const { hasSlackIdentity, canManage } = loggedInOrguser;

  const [errors, setErrors] = useState(null);

  const updateConfig = (e: InputEvent) => {
    const { name, checked } = e.target;
    setSlackEnabled(checked);

    setErrors(null);
    updateMeOrgUser({
      settings: settingsToSnakeCase({
        ...loggedInOrguser.settings,
        [NOTIFICATION_CHANNEL_SETTINGS]: {
          ...getNotificationChannelSettings(loggedInOrguser),
          [name]: checked,
        },
      }),
    })
      .then((orguser) => {
        setErrors(null);
        setOrgUser(orguser);
      })
      .catch((error) => {
        setErrors(error.json || {});
      });
  };

  return (
    <div className="card card-body d-flex flex-column py-4 gap-30">
      <div className="d-flex flex-row align-items-center gap-50">
        <div className="flex-fill d-flex flex-row align-items-center gap-15">
          <div className="rounded bg-white border-light p-2">
            <SlackLogo height={30} width={30} />
          </div>
          <div className="d-flex flex-column">
            <span className="large-text bold gray-700">Slack</span>
            <div className="d-flex flex-row align-items-center gap-5 normal-text semi-bold gray-500">
              Sign in with Slack to get notifications & use the{' '}
              <code className="rounded px-1 mt-1">/partners</code> command!
            </div>
          </div>
        </div>
        {hasSlackIntegration ? (
          <>
            {hasSlackIdentity ? (
              <CustomInput
                id={SLACK_ENABLED}
                type="switch"
                name={SLACK_ENABLED}
                onChange={updateConfig}
                checked={slackEnabled}
              />
            ) : (
              <SigninWithSlackButton />
            )}
          </>
        ) : (
          <>
            {canManage ? (
              <SlackIntegrationButton />
            ) : (
              <span className="normal-text semi-bold orange">
                <FontAwesomeIcon icon="info-circle" className="mr-2" />
                Not installed for your organization
              </span>
            )}
          </>
        )}
      </div>
      <FieldErrors errors={errors && errors.nonFieldErrors} />
    </div>
  );
};

export default withOrguser(SlackNotifications);
