// @flow

import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequestFilters, exportBdRequests } from 'data/repositories/bdrequests';

type Props = {
  filters: BdRequestFilters,
};

const DotMenu = ({ filters }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const exportData = () => {
    exportBdRequests(filters).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      const name = ['sales-edge-messaging-report'];
      if (filters.status) {
        name.push(`-${filters.status.toLowerCase()}`);
      }
      name.push(`-${new Date().toISOString().slice(0, 10)}`);
      name.push('.csv');
      link.setAttribute('download', name.join(''));
      link.click();
    });
  };

  return (
    <Dropdown toggle={toggle} isOpen={open}>
      <DropdownToggle>
        <FontAwesomeIcon icon="ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={exportData}>
          <FontAwesomeIcon icon="file-export" className="mr-2" />
          Export Data
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DotMenu;
