// @flow
import type { Node } from 'react';
import React from 'react';
import { Modal as ModalComponent, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
  isOpen: boolean,
  toggle: () => void,
  title: Node,
  children: Node,
  size?: 'sm' | 'lg' | 'xl',
  width?: number,
  centered?: boolean,
  footer?: Node,
  onClosed?: () => void,
};

const Modal = ({
  isOpen,
  toggle,
  title,
  children,
  size,
  width,
  centered,
  footer,
  onClosed,
}: Props) => (
  <ModalComponent
    autoFocus
    isOpen={isOpen}
    toggle={toggle}
    onClosed={onClosed}
    size={size}
    centered={centered}
    style={width && { minWidth: width }}
  >
    <ModalHeader
      className="px-3 pb-3 pt-1"
      style={{ borderBottom: '1px solid #DDE5F0' }}
      toggle={toggle}
    >
      {title}
    </ModalHeader>
    <ModalBody style={{ padding: '25px 20px' }}>
      <div className="d-flex flex-column">{children}</div>
    </ModalBody>
    {footer && (
      <ModalFooter className="p-2" style={{ borderTop: '1px solid #DDE5F0' }}>
        {footer}
      </ModalFooter>
    )}
  </ModalComponent>
);

Modal.defaultProps = {
  footer: null,
  size: 'xl',
  width: null,
  centered: false,
  onClosed: () => {},
};

export default Modal;
