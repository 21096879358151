// @flow

import { useEffect, useState } from 'react';

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const useInIframe = () => {
  const [inIframe, setInIframe] = useState(false);
  useEffect(() => {
    setInIframe(isInIframe());
  }, []);
  return inIframe;
};

export default useInIframe;
