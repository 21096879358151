// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { AccountOwner, OrgUser } from 'data/entities';
import { fetchAccountBySlug } from 'data/repositories/accounts';
import { fetchContacts } from 'data/repositories/contact';
import withOrguser from 'contexts/withOrguser';
import useOrgProvider from 'utils/useOrgProvider';

import AccountHiddenIcon from 'components/AccountSettings/AccountHiddenIcon';
import AccountStatusAlertTooltip from 'components/AccountStatusAlertTooltip';
import CompanyLogo from 'components/CompanyLogo';
import HorizontalScroll from 'components/HorizontalScroll';
import LoadingRing from 'components/LoadingRing';
import Pill from 'components/Pill';

import AccountPartners from './AccountPartners';
import ContactCard from './ContactCard';
import Suggestions from './Suggestions';

type Props = {
  loggedInOrguser: OrgUser,
  match: { params: { accountSlug: string } },
};

const AccountDetail = (props: Props) => {
  const {
    loggedInOrguser,
    match: {
      params: { accountSlug },
    },
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [account, setAccount] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const { isProviderCrossbeam } = useOrgProvider();

  const loadAccount = useCallback(() => {
    setLoading(true);
    setError(false);
    setAccount(null);
    fetchAccountBySlug(accountSlug)
      .then((a) => {
        setLoading(false);
        setError(false);
        setAccount(a);
      })
      .catch((err) => {
        setLoading(false);
        setError(!!err);
      });
  }, [accountSlug]);

  const [contacts, setContacts] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(true);

  const loadContacts = useCallback(() => {
    setLoadingContacts(true);
    setContacts([]);
    fetchContacts(accountSlug, {
      offset: 0,
      limit: 10,
    })
      .then((results) => {
        setContacts(results.results);
        setLoadingContacts(false);
      })
      .catch((err) => {
        setLoadingContacts(false);
      });
  }, [accountSlug]);

  useEffect(() => {
    loadAccount();
    loadContacts();
  }, [loadAccount, loadContacts, accountSlug]);

  if (error) {
    return (
      <div className="m-5 text-center">
        <h6>This account is not available</h6>
        <p className="mb-3 normal-text semi-bold gray-500">
          The link may be broken, or the account may have been removed.
        </p>
      </div>
    );
  }

  if (loading || !account) {
    return <LoadingRing className="m-5" />;
  }

  const { accountOwners, contactsCount } = account;

  return (
    <div className="d-flex flex-column mx-3">
      <div className="sticky w-100 mb-4" style={{ top: '0px', minHeight: '120px' }}>
        <div
          className={`w-100 bg-background-body d-flex flex-row ${
            isScrolling ? 'gap-10' : 'gap-20'
          } pb-2 page-top-padding`}
        >
          <CompanyLogo
            size={isScrolling ? 35 : 80}
            name={account.name}
            domain={account.website}
            className=""
          />
          <div className="d-flex flex-column flex-fill gap-10">
            <div className="d-flex flex-row align-items-center gap-5">
              <h3 className="m-0 bold gray-700 fs-mask">{account.name}</h3>
              <AccountHiddenIcon account={account} />
              <AccountStatusAlertTooltip account={account} withExpires />
              <div>{account.status}</div>
              {account.isTarget && (
                <Pill
                  icon={<FontAwesomeIcon icon="bullseye" width="14" height="14" />}
                  color="gray"
                  label={account.isTargetValue}
                />
              )}
            </div>
            {!isScrolling && (
              <>
                <div className="d-flex flex-row align-items-center gap-10">
                  <span className="normal-text semi-bold gray-500">Owners:</span>
                  {accountOwners.map((owner: AccountOwner, index) => (
                    <React.Fragment key={owner.id}>
                      <Link
                        to={`/network/oid/${owner.orguserId}`}
                        className="normal-text semi-bold gray-700 btn-link fs-mask"
                      >
                        {owner.fullName}
                      </Link>
                      {index !== accountOwners.length - 1 && <h3 className="m-0 p-0 lh-10">·</h3>}
                    </React.Fragment>
                  ))}
                </div>
                <div>
                  <a
                    href={`https://${account.website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="normal-text semi-bold gray-700 fs-mask"
                  >
                    {account.website}
                  </a>
                </div>
              </>
            )}
          </div>
          <div>
            <div className="d-flex flex-row gap-15">
              <Link
                to={{ pathname: '/requests/all', search: `?account=${account.slug}` }}
                className="btn btn-secondary"
              >
                <span className="gray-700">
                  <FontAwesomeIcon icon="comment-dots" className="mr-2" />
                  View Requests
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {loggedInOrguser.canManage && <Suggestions accountOwners={account?.accountOwners} />}
      {!isProviderCrossbeam && (
        <>
          <div className="mb-2 mt-3 d-flex flex-row align-items-center gap-15">
            <h5 className="m-0 bold gray-700">Contacts</h5>
            <Link
              className="normal-text bold"
              to={`/a/${account.slug}/contacts`}
            >{`See All (${contactsCount})`}</Link>
          </div>
          {loadingContacts ? (
            <LoadingRing maxWidth="50px" />
          ) : (
            <>
              {!contacts.length ? (
                <div className="text-center">
                  <h6>No contacts found.</h6>
                </div>
              ) : (
                <HorizontalScroll>
                  {contacts.map((contact, index) => (
                    <ContactCard
                      itemId={index}
                      key={contact.email}
                      accountSlug={accountSlug}
                      contact={contact}
                    />
                  ))}
                  <Link
                    className="normal-text bold card m-0 card-body h-100 align-items-center justify-content-center"
                    style={{ maxWidth: '200px', width: '200px' }}
                    to={`/a/${account.slug}/contacts`}
                  >
                    {`See All (${contactsCount})`}
                  </Link>
                </HorizontalScroll>
              )}
            </>
          )}
        </>
      )}
      <h5 className="m-0 mb-2 mt-4 bold gray-700">Partners</h5>
      <AccountPartners
        account={account}
        setIsScrolling={(newScrolling: boolean) => {
          !isScrolling && newScrolling
            ? setIsScrolling(true)
            : isScrolling && !newScrolling && setIsScrolling(false);
        }}
      />
    </div>
  );
};

export default withOrguser(AccountDetail);
