// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

import Segment from 'data/entities/segment';
import type { Option } from 'data/repositories/accounts';
import { fetchSegments } from 'data/repositories/segment';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';

type Props = {
  segment: ?(Option[]),
  onChange: (options: ?(Option[])) => void,
};

function SegmentSelector({ segment, onChange, history, ...props }: Props) {
  const [segments, setSegments] = useState<PaginatedResponse<Segment>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchSegments()
      .then((results) => {
        setSegments(results);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const options = useMemo<Option[]>(
    () =>
      (segments.results || []).map((segmentObj: Segment) => ({
        name: segmentObj.name,
        value: segmentObj.id,
        description: segmentObj.description,
      })),
    [segments]
  );

  const customMenuItem = (option, { text }, index) => (
    <>
      <Highlighter search={text}>{option.name}</Highlighter>
      {option.description && option.description !== '' && (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', color: '#6C727F' }}>
          {option.description}
        </div>
      )}
    </>
  );

  return (
    <Typeahead
      renderMenuItemChildren={customMenuItem}
      id="select-segment"
      labelKey="name"
      onChange={onChange}
      options={options}
      selected={[...options].filter((x) => [].concat(segment).includes(x.value))}
      placeholder="🔎  Search for segments..."
      bsSize="sm"
      multiple
      isLoading={loading}
      clearButton
      changeOnClear
      {...props}
    />
  );
}

export default SegmentSelector;
