/* eslint-disable max-classes-per-file */
// @flow

import { Partner } from '.';

const UNDER_MAINTENANCE = 'under_maintenance';
const WEB_APP_NAME_STARTS_WITH = 'Web App (';
const API_NAME_STARTS_WITH = 'API (';
const BACKGROUND_PROCESSING_NAME = 'Background Processing';
const SALES_EDGE_WEB_NAME = 'Sales Edge Web';
const SALES_EDGE_API_NAME = 'Sales Edge API';
const CROSSBEAM_FOR_SALES_WEB_NAME = 'Crossbeam For Sales Web';
const CROSSBEAM_FOR_SALES_API_NAME = 'Crossbeam For Sales API';

export class CrossbeamPopulation {
  id: string;

  isStandard: boolean;

  name: ?string;

  status: ?string;

  static fromApi = (d: any): CrossbeamPopulation => {
    const cp = new CrossbeamPopulation();
    cp.id = d.id;
    cp.isStandard = d.is_standard;
    cp.name = d.name;
    cp.status = d.status;
    return cp;
  };
}

export class CrossbeamIntegration {
  provider: string;

  status: string;

  lastSyncAt: ?Date;

  partnersLastSyncAt: ?Date;

  partnersUnclassifiedPopulations: number;

  orgUuid: string;

  canChangeOrgId: boolean;

  populations: CrossbeamPopulation[];

  static fromApi = (d: any): CrossbeamIntegration => {
    const ci = new CrossbeamIntegration();
    if (d.integration) {
      ci.provider = d.integration.provider;
      ci.status = d.integration.status;
      ci.lastSyncAt = d.integration.last_sync_at && new Date(d.integration.last_sync_at);
      ci.partnersLastSyncAt =
        d.integration.partners_last_sync_at && new Date(d.integration.partners_last_sync_at);
      ci.partnersUnclassifiedPopulations = d.integration.partners_unclassified_populations;
      ci.orgUuid = d.integration.org_uuid;
      ci.canChangeOrgId = d.integration.can_change_org_id;
      ci.populations =
        d.integration.populations && d.integration.populations.map(CrossbeamPopulation.fromApi);
    }
    return ci;
  };
}

export class CrossbeamOrg {
  name: string;

  logoUrl: ?string;

  clearbitDomain: string;

  id: string;

  domain: string;

  static fromApi = (d: any): CrossbeamOrg => {
    const co = new CrossbeamOrg();
    co.name = d.name;
    co.logoUrl = d.logo_url;
    co.clearbitDomain = d.clearbit_domain;
    co.id = d.id;
    co.domain = d.domain;
    return co;
  };
}

export class CrossbeamPartner {
  name: string;

  logoUrl: ?string;

  clearbitDomain: string;

  id: string;

  domain: string;

  url: string;

  populations: CrossbeamPopulation[];

  partner: ?Partner;

  syncEnabled: boolean;

  lastSyncAt: ?Date;

  unclassifiedPopulations: number;

  imported: boolean;

  connected: boolean;

  offlinePartner: boolean;

  static fromApi = (d: any): CrossbeamPartner => {
    const cp = new CrossbeamPartner();
    cp.name = d.name;
    cp.logoUrl = d.logo_url;
    cp.clearbitDomain = d.clearbit_domain;
    cp.id = d.id;
    cp.domain = d.domain;
    cp.url = d.url;
    cp.populations = d.populations && d.populations.map(CrossbeamPopulation.fromApi);
    cp.partner = d.partner && Partner.fromApi(d.partner);
    cp.syncEnabled = d.sync_enabled;
    cp.lastSyncAt = d.last_sync_at && new Date(d.last_sync_at);
    cp.unclassifiedPopulations = d.unclassified_populations;
    cp.connected = cp.partner && cp.partner.isConnected;
    cp.imported = d.sync_enabled && !!cp.partner?.partnerOrg?.pseudoOrgForPartnerId;
    cp.offlinePartner = d.offline_partner || false;
    return cp;
  };
}

export class Component {
  id: string;

  name: string;

  status: string;

  description: string;

  showcase: boolean;

  startDate: Date;

  createdAt: Date;

  updatedAt: Date;

  position: number;

  static fromApi = (d: any): Component => {
    const c = new Component();
    c.id = d.id;
    c.name = d.name;
    c.status = d.status;
    c.description = d.description;
    c.showcase = d.showcase;
    c.startDate = new Date(d.start_date);
    c.createdAt = new Date(d.created_at);
    c.updatedAt = new Date(d.updated_at);
    c.position = d.position;
    return c;
  };

  get isInMaintenance(): boolean {
    return this.status === UNDER_MAINTENANCE;
  }
}

export class CrossbeamStatusComponents {
  webApp: Component;

  API: Component;

  background: Component;

  crossbeamForSalesAPI: Component;

  crossbeamForSalesWebApp: Component;

  static fromApi = (d: any): CrossbeamStatusComponents => {
    const csc = new CrossbeamStatusComponents();
    const components: Component[] = (d.components || []).map(Component.fromApi);
    csc.webApp = components.find((c) => c.name.includes(WEB_APP_NAME_STARTS_WITH));
    csc.API = components.find((c) => c.name.includes(API_NAME_STARTS_WITH));
    csc.background = components.find((c) => c === BACKGROUND_PROCESSING_NAME);
    csc.crossbeamForSalesAPI = components.find((c) =>
      [CROSSBEAM_FOR_SALES_WEB_NAME, SALES_EDGE_WEB_NAME].includes(c)
    );
    csc.crossbeamForSalesWebApp = components.find((c) =>
      [CROSSBEAM_FOR_SALES_API_NAME, SALES_EDGE_API_NAME].includes(c)
    );
    return csc;
  };
}

export class CrossbeamSource {
  properties: null;

  schema: string;

  feedID: number;

  table: string;

  fields: {
    mdmProperty: null | string,
    nickname: string,
    isSortable: boolean,
    isFilterable: boolean,
    isPrimaryKey: boolean,
    pgDataType: null | string,
    column: string,
    isVisible: boolean,
    copiesSourceFieldID: null,
    syncingDisabledReason: null | string,
    id: number,
    firstProcessedAt: null,
    relationshipID: null,
    dataType: null | string,
    sourceID: number,
    displayName: string,
  }[];

  errorMessage: null;

  isBaseTable: boolean;

  datasetName: null | string;

  status: null | string;

  id: number;

  mdmType: string;

  syncEnabled: boolean;

  relationships: any[];

  createdAt: string;

  static fromApi = (d: any): CrossbeamSource => {
    const cs = new CrossbeamSource();
    cs.properties = d.properties;
    cs.schema = d.schema;
    cs.feedID = d.feed_id;
    cs.table = d.table;
    cs.fields = d.fields;
    cs.errorMessage = d.error_message;
    cs.isBaseTable = d.is_base_table;
    cs.datasetName = d.dataset_name;
    cs.status = d.status;
    cs.id = d.id;
    cs.mdmType = d.mdm_type;
    cs.syncEnabled = d.sync_enabled;
    cs.relationships = d.relationships;
    cs.createdAt = d.created_at;
    return cs;
  };
}

export class CrossbeamProfile {
  firstName: string;

  lastName: string;

  pronouns: string;

  department: string;

  jobTitle: string;

  static fromApi = (d: any): CrossbeamProfile => {
    const cp = new CrossbeamProfile();
    cp.firstName = d.first_name;
    cp.lastName = d.last_name;
    cp.pronouns = d.pronouns;
    cp.department = d.department;
    cp.jobTitle = d.job_title;
    return cp;
  };
}
