// @flow

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Account, Org, OrgUser, Partner, PartnerAccount } from 'data/entities';
import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { fetchAccountBySlug, listPartnerMappedAccountsByAccount } from 'data/repositories/accounts';
import { fetchPartnerBySlug } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

import { navigatorDefaultUrl } from 'layouts/PrimaryLayout/PrimaryNavbar';
import LoadingRing from 'components/LoadingRing';
import ViewerOverlay from 'components/SeatlessExperience/ViewerOverlay';
import SlackChannelMissing from 'components/SlackChannelMissing';

import Information from './Information';
import Questions from './Questions';
import Summary from './Summary';
import TalkingPoints from './TalkingPoints';
import { processRequestParams } from './utils';

export const Step = {
  QUESTIONS: 0,
  TALKING_POINTS: 1,
  PREVIEW: 2,
};

export type RequestParams = {
  account: string,
  partner: string,
  partnerAccountId: string,
  xbRecordId: string,
  xbPartnerOrgId: string,
  xbPartnerRecordId: string,
  source: string,
};

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const NewQuestionBdRequest = ({ loggedInOrg, loggedInOrguser }: Props) => {
  const [params]: [RequestParams] = useParams({});

  const [partner, setPartner] = useState<Partner | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [partnerAccount, setPartnerAccount] = useState<PartnerAccount | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    processRequestParams(params)
      .then((processedParams) => {
        // If any of the params are null, we can't proceed.
        if (Object.values(processedParams).some((v) => v === null)) {
          setLoading(false);
          setError(true);
          return;
        }

        const { partnerSlug, accountSlug, partnerAccountId } = processedParams;
        const promises = [];
        promises.push(fetchPartnerBySlug(partnerSlug));
        promises.push(fetchAccountBySlug(accountSlug));
        promises.push(listPartnerMappedAccountsByAccount(partnerSlug, accountSlug));

        Promise.all(promises).then((results) => {
          const [p, a, pA] = results;
          const partnerAccounts = pA.map((pa) => pa.partnerAccount);
          if (partnerAccounts.length === 0) {
            setLoading(false);
            setError(true);
            return;
          }

          setPartner(p);
          setAccount(a);
          setPartnerAccount(
            partnerAccounts.find((pa) => pa.id === partnerAccountId) || partnerAccounts[0]
          );
          setLoading(false);
        });
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeStep, setActiveStep] = useState(Step.QUESTIONS);
  const [selectedQuestions, setSelectedQuestions] = useState<BdRequestQuestion[]>([]);
  const [customQuestions, setCustomQuestions] = useState<string[]>([]);
  const [answers, setAnswers] = useState<string[]>(
    loggedInOrg.settings.requestsWorkflowSettings.talkingPoints.map((tp) => '')
  );

  const reset = () => {
    setActiveStep(Step.QUESTIONS);
    setSelectedQuestions([]);
    setCustomQuestions([]);
    setAnswers(loggedInOrg.settings.requestsWorkflowSettings.talkingPoints.map((tp) => ''));
  };

  if (loggedInOrguser.capabilities.viewOnly) {
    return (
      <ViewerOverlay
        title="Upgrade to Co-Seller to get intel from partners"
        explanation="Your current plan does not include this feature."
      >
        <div className="card card-body p-3 flex-grow-0 mx-auto mt-4 col-lg-7 col-md-9 col-sm-11">
          <div className="d-flex flex-column gap-15">
            <span className="large-text bold gray-700">Request to Partner</span>
            <div
              className="w-100 d-flex flex-row gap-10 align-items-center p-2"
              style={{ borderBottom: '1px solid #DDE5F0' }}
            >
              <span className="normal-text semi-bold gray-400">Partner:</span>
              Dunder Mifflin
            </div>
          </div>
        </div>
      </ViewerOverlay>
    );
  }

  if (activeStep === Step.PREVIEW) {
    return (
      <Summary
        partners={[partner]}
        account={account}
        partnerAccounts={[partnerAccount]}
        selectedQuestions={selectedQuestions}
        customQuestions={customQuestions}
        answers={answers}
        edit={() => setActiveStep(Step.TALKING_POINTS)}
        reset={reset}
      />
    );
  }

  return (
    <div className="mx-auto mt-4 col-lg-7 col-md-9 col-sm-11 card card-body p-3">
      {!loading ? (
        <>
          {!error && partner && account && partnerAccount ? (
            <div className="d-flex flex-column gap-15">
              <span className="large-text bold gray-700">
                Request to {partner.name} about {account.name}
              </span>
              <Information account={account} partner={partner} partnerAccount={partnerAccount} />
              {partner?.slackChannel?.isDeleted && (
                <SlackChannelMissing
                  loggedInOrguser={loggedInOrguser}
                  toolTipIdSuffix="bdrequest"
                />
              )}
              <Questions
                partners={[partner]}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                customQuestions={customQuestions}
                setCustomQuestions={setCustomQuestions}
                active={activeStep === Step.QUESTIONS}
                next={() => setActiveStep(Step.TALKING_POINTS)}
              />
              <TalkingPoints
                answers={answers}
                setAnswers={setAnswers}
                active={activeStep === Step.TALKING_POINTS}
                back={() => setActiveStep(Step.QUESTIONS)}
                next={() => setActiveStep(Step.PREVIEW)}
                reset={reset}
                partnerId={partner.id}
                partnerAccountId={partnerAccount.id}
              />
            </div>
          ) : (
            <div className="d-flex flex-column gap-5 align-items-center p-2">
              <FontAwesomeIcon icon="chain-broken" size="2x" />
              <h3 className="m-0 mt-2 bold gray-700">The mapping doesn&apos;t exist</h3>
              <div className="large-text semi-bold gray-600 text-center">
                The mapping you are trying to message your partner doesn&apos;t exist in Crossbeam
                for Sales.
              </div>
              <Link to={navigatorDefaultUrl()} className="mt-4 btn btn-primary">
                Go to Navigator
              </Link>
            </div>
          )}
        </>
      ) : (
        <LoadingRing className="m-5" />
      )}
    </div>
  );
};

export default withOrguser(NewQuestionBdRequest);
