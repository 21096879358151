// @flow

import * as React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string,
  iconOnly?: boolean,
  small?: boolean,
};

const ConnectedPartnerBadge = ({ className, small, iconOnly }: Props) => (
  <div className={classNames('small', className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${small ? 15 : 20}px`}
      height={`${small ? 15 : 20}px`}
      fill="#042C59"
      viewBox="0 0 20 20"
    >
      <path d="M20 10c0-1.47-.93-2.73-2.23-3.22a3.44 3.44 0 0 0-4.55-4.55 3.44 3.44 0 0 0-6.44 0 3.44 3.44 0 0 0-4.55 4.55 3.44 3.44 0 0 0 0 6.44 3.44 3.44 0 0 0 4.55 4.55 3.44 3.44 0 0 0 6.44 0 3.43 3.43 0 0 0 4.55-4.55A3.44 3.44 0 0 0 20 10Zm-5.66-1.73-5.12 5.08a.43.43 0 0 1-.6 0l-2.96-2.98a.43.43 0 0 1 0-.61l1.02-1.01a.43.43 0 0 1 .6 0l1.65 1.66 3.8-3.77a.43.43 0 0 1 .6 0l1.02 1.02c.16.17.16.44 0 .61Z" />
    </svg>
    {!iconOnly && <span className="v-align-middle ml-1 text-primary">Connected</span>}
  </div>
);

ConnectedPartnerBadge.defaultProps = {
  className: '',
  iconOnly: false,
  small: false,
};

export default ConnectedPartnerBadge;
