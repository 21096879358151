import * as React from 'react';

const AttributeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="32" height="32" viewBox="0 0 32 32">
    <path
      fill="#F4CD00"
      d="M31.017 10.32a1.2 1.2 0 0 0-1.696.063l-4.353 4.688-1.92-1.92a1.2 1.2 0 1 0-1.696 1.697l2.8 2.8c.225.227.53.352.85.352h.021c.327-.006.637-.144.858-.383l5.2-5.6c.45-.487.42-1.247-.064-1.697Z"
    />
    <path
      fill="#F4CD00"
      d="M14.735 18.2h-5.07A8.666 8.666 0 0 0 1 26.865c0 .96.776 1.735 1.733 1.735h18.932c.96 0 1.735-.775 1.735-1.735a8.666 8.666 0 0 0-8.665-8.665ZM12.2 15.8a6.4 6.4 0 1 0 0-12.8 6.4 6.4 0 0 0 0 12.8Z"
      opacity=".4"
    />
  </svg>
);

export default AttributeIcon;
