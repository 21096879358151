// @flow

import * as React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUserNameOnly } from 'data/entities/orguser';

import CompanyLogo from 'components/CompanyLogo';

type Props = {
  orguser: OrgUserNameOnly,
  isPartnerManager: boolean,
};

export default function UserItemToken({ orguser, isPartnerManager }: Props) {
  const { fullName, orgName, orgDomain, orgLogoUrl } = orguser;
  let response = fullName;
  const targetId = isPartnerManager ? `partner-manager-${orguser.id}` : null;
  if (orgName) {
    response = (
      <>
        {response}{' '}
        <CompanyLogo
          name={orgName}
          domain={orgDomain}
          logoUrl={orgLogoUrl}
          size={14}
          className="thumbnail-wrapper float-none"
        />
      </>
    );
  }
  if (targetId) {
    response = (
      <>
        <FontAwesomeIcon icon="handshake" id={targetId} className="small-text" /> {response}{' '}
        <UncontrolledTooltip target={targetId}>
          Partner Manager (Automatically added)
        </UncontrolledTooltip>
      </>
    );
  }
  return <div className="d-flex align-items-center gap-5">{response}</div>;
}
