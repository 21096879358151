import * as React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AccountHiddenIcon({ account }) {
  const { hidden, excluded } = account;
  if (!(hidden || excluded)) {
    return null;
  }
  const messageHidden = 'Hidden from Account Mapping';
  const messageExcluded = 'Excluded from mapping by filter settings';
  return (
    <div className="AccountHiddenIcon">
      <span id={`hidden-tooltip-${account.slug}`}>
        <FontAwesomeIcon icon="eye-slash" />
      </span>

      <UncontrolledTooltip placement="right" target={`hidden-tooltip-${account.slug}`}>
        {excluded ? messageExcluded : messageHidden}
      </UncontrolledTooltip>
    </div>
  );
}
