// @flow
import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { BdRequest } from 'data/entities';
import {
  WithSlackChannelRefreshButton,
  WithSlackChannelSelector,
} from 'contexts/SlackChannelContext';

type NumberCircleProps = {
  active: boolean,
  step: number,
  complete: boolean,
};

const NumberCircle = (props: NumberCircleProps) => {
  const { active, step, complete } = props;
  let circleColor;
  if (complete) {
    circleColor = '#E3F5ED';
  } else {
    circleColor = '#EFF3F9';
  }

  let textColor;
  if (complete) {
    textColor = '#E3F5ED';
  } else {
    textColor = '#304055';
  }

  const numberStyle = {
    color: active ? '#304055' : '#304055',
    textAlign: 'center',
    fontVariantNumeric: 'lining-nums tabular-nums',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '1.28px',
    textTransform: 'uppercase',
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <circle cx="18" cy="18" r="18" fill={circleColor} />
      {complete ? (
        <text x="18" y="23" textAnchor="middle" style={{ color: textColor }}>
          ✓
        </text>
      ) : (
        <text
          x="18"
          y="23"
          textAnchor="middle"
          style={{
            ...numberStyle,
            fill: textColor,
          }}
        >
          {step}
        </text>
      )}
    </svg>
  );
};

interface PublishToSlackWorkflowProps {
  request: BdRequest;
  slackIsSetup: boolean;
  canSetupSlack: boolean;
  channelIsSetup: boolean;
}

const PublishToSlackWorkflow = (props: PublishToSlackWorkflowProps) => {
  const { request, slackIsSetup, canSetupSlack, channelIsSetup } = props;

  const determineActiveStep = (localSlackIsSetup) => {
    // Sales Users only get step 2
    if (!localSlackIsSetup) {
      return 1;
    }
    return 2;
  };

  const [activeStep] = useState(determineActiveStep(slackIsSetup));

  const disabledButtonStyle = {
    borderRadius: '8px',
    border: '1px solid var(--variables-neutral-gray-200, #DDE5F0)',
    opacity: '0.5',
    backgroundColor: '#FFFFFF',
    color: '#333333',
  };

  const disabledButtonTextStyle = {
    color: 'var(--Gray-700, #181E2D)',
    fontVariantNumeric: 'lining-nums tabular-nums',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  };

  const handleButtonClick = (step) => {
    localStorage.setItem('SlackSetupModal', request.id);
    if (step === 1) {
      window.location.href = '/slack';
    }
  };

  const stepData = [
    {
      description: 'Install Crossbeam for Sales Application for your Slack org',
      action: (index) => (
        <Button
          color="primary"
          disabled={activeStep !== index + 1}
          style={
            activeStep !== index + 1
              ? disabledButtonStyle
              : {
                  width: 167,
                  height: 39,
                  radius: 8,
                  backgroundColor: '#002047',
                  fontSize: 16,
                }
          }
          onClick={() => handleButtonClick(index + 1)}
        >
          <span style={activeStep !== index + 1 ? disabledButtonTextStyle : {}}>
            Install Slack App
          </span>
        </Button>
      ),
      complete: slackIsSetup,
    },
    {
      description: 'Add a shared Slack channel for your partner',
      action: () => (
        <div className="row">
          <div className="col-7">
            <WithSlackChannelSelector
              partnerId={request.partner.id}
              autoSave
              disabled={!slackIsSetup || !canSetupSlack}
            />
          </div>
          <div className="col-5">
            <WithSlackChannelRefreshButton
              text="Refresh Channels"
              height="35px"
              disabled={!slackIsSetup || !canSetupSlack}
              style={!slackIsSetup || !canSetupSlack ? disabledButtonStyle : {}}
            />
          </div>
        </div>
      ),
      complete: channelIsSetup,
    },
  ];
  return (
    <div>
      <div className="semi-bold mb-3">
        Complete the following steps to continue this conversation on Slack:
      </div>

      {stepData.map((step, index) => (
        <div
          className="d-flex align-items-start"
          style={{
            borderTop: step === '1' ? 'none' : '1px solid var(--neutral-border-card, #DDE5F0)',
            paddingTop: '24px',
            paddingBottom: '24px',
          }}
        >
          <NumberCircle active={activeStep === step} step={index + 1} complete={step.complete} />
          <div style={{ flex: 1, marginLeft: '16px' }}>
            <div className="mt-1" style={{ color: activeStep === index + 1 ? '#304055' : '#ccc' }}>
              {step.description}
            </div>
            <div className="mt-2">{step.action(index)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PublishToSlackWorkflow;
