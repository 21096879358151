// @flow

import * as React from 'react';

import Org from 'data/entities/org';

const logoAvatarClassName = (partner) => {
  let className = 'Org__logoAvatar_text';
  if (partner) {
    className = `${className} Org__logoAvatar_text_partner`;
  }
  return className;
};

// const LogoAvatarImg = ({ org }: { org: Org }): React.Element => (
//   // If a logoAvatar exists, this will return an img with proper src, alt, and classes
//   <img
//     src={org.logoAvatar}
//     className="PredictionRow__logo Org__logoAvatar"
//     alt={org.name}
//   />
// );

export default function LogoAvatar({ org, partner }: { org: Org, partner: boolean }) {
  return (
    <div className={logoAvatarClassName(partner)} title={org ? org.name : ''}>
      {org && org.name ? org.name.slice(0, 2) : '  '}
    </div>
  );
  // If a logoAvatar exists, this will return an img with proper src, alt, and classes
  // if (org && org.logoAvatar) {
  //   return <LogoAvatarImg org={org} />;
  // }
  // return (
  //   <div className={logoAvatarClassName(partner)}>
  //     {(org && org.name) ? org.name.slice(0, 2) : '  '}
  //   </div>
  // );
}
