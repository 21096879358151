// @flow

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  messaged: boolean,
  partnerSlug: string,
  accountSlug: string,
  showText?: boolean,
};

const Messaged = ({ messaged, partnerSlug, accountSlug, showText }: Props) => {
  const ref = useRef(null);
  if (!messaged) {
    return null;
  }
  return (
    <Link
      to={`/requests/requested?partner=${partnerSlug}&account=${accountSlug}`}
      className="d-flex flex-row align-items-center gap-5"
    >
      <FontAwesomeIcon forwardedRef={ref} icon="message-check" className="green" />
      {showText ? (
        <span className="small-text semi-bold green disable-selection">Messaged</span>
      ) : (
        <UncontrolledTooltip target={ref}>Messaged</UncontrolledTooltip>
      )}
    </Link>
  );
};

Messaged.defaultProps = {
  showText: false,
};

export default Messaged;
