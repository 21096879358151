// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';

import authFetch from '../../authFetch/authFetch';
import { ErrorResponse } from '../../authFetch/entities';

type Props = {
  location?: Object,
  match?: Object,
};

export default class ResetPasswordForm extends React.Component {
  constructor(props: Props) {
    super(props);
    this.query = new URLSearchParams(props.location.search);
    this.state = {
      email: this.query.get('user'),
      token: this.query.get('token'),
      password: '',
      confirm_password: '',
      ErrorMessage: {},
      success_message: '',
      invalid_session: false,
      validating_session: true,
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.ResetPassword = this.ResetPassword.bind(this);
    this.CheckSession();
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  CheckSession() {
    authFetch(`auth/recover/password/reset/?email=${this.state.email}&token=${this.state.token}`, {
      method: 'GET',
    })
      .then(() => {
        this.setState({
          validating_session: false,
        });
        return {};
      })
      .catch(({ json }: ErrorResponse) => {
        this.setState({
          validating_session: false,
          invalid_session: true,
          ErrorMessage: json,
        });
      });
  }

  ResetPassword(e) {
    e.preventDefault();
    this.setState({
      ErrorMessage: {},
      success_message: '',
    });
    if (!this.IsPasswordValid()) {
      return;
    }
    const body = {
      email: this.state.email,
      token: this.state.token,
      password: this.state.password,
    };
    authFetch('auth/recover/password/reset/', { method: 'POST', body })
      .then(() => {
        this.setState({
          success_message: 'Password changed successfully, you can login with your new password ',
        });
      })
      .catch(({ json }: ErrorResponse) => {
        this.setState({
          ErrorMessage: json,
        });
      });
  }

  IsPasswordValid() {
    let StateUpdateDict = {};
    let IsPasswordValid = true;
    if (this.state.password === '') {
      IsPasswordValid = false;
      StateUpdateDict = {
        ErrorMessage: {
          password: 'Password is not allowed to be empty',
        },
      };
    } else if (this.state.password !== this.state.confirm_password) {
      IsPasswordValid = false;
      StateUpdateDict = {
        ErrorMessage: {
          confirm_password: 'Password does not match',
        },
      };
    }
    this.setState(StateUpdateDict);
    return IsPasswordValid;
  }

  RenderResetPasswordForm() {
    if (!this.state.success_message && !this.state.invalid_session) {
      return (
        <form onSubmit={this.ResetPassword}>
          <div className="form-group">
            <label htmlFor="email">New Password</label>
            <input
              type="password"
              className={`form-control ${this.state.ErrorMessage.password ? 'is-invalid' : ''}`}
              id="password"
              name="password"
              value={this.state.password}
              onChange={this.onInputchange}
              aria-describedby="emailHelp"
              placeholder="Enter your new password"
            />
            <FieldErrors errors={this.state.ErrorMessage.password} color="danger" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Confirm new password</label>
            <input
              type="password"
              className={`form-control ${
                this.state.ErrorMessage.confirm_password ? 'is-invalid' : ''
              }`}
              id="confirm_password"
              name="confirm_password"
              value={this.state.confirm_password}
              onChange={this.onInputchange}
              aria-describedby="emailHelp"
              placeholder="Confirm your new password"
            />
            <FieldErrors errors={this.state.ErrorMessage.confirm_password} color="danger" />
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 text-left" />
              <div className="col-md-8 text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg text-bold text-center font-weight-bold"
                >
                  Reset password
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    }
    return <span />;
  }

  render() {
    if (this.state.validating_session) {
      return <LoadingRing text="Validating Session..." />;
    }
    return (
      <div className="row text-center">
        <div className="col-md-4" />
        <div className="col-md-4 text-left">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
              {this.state.success_message ? (
                <>
                  <div className="alert alert-success font-weight-bolder" role="alert">
                    {this.state.success_message}
                  </div>
                  <Link to="/join">Login</Link>
                </>
              ) : (
                ''
              )}
              <FieldErrors errors={this.state.ErrorMessage} color="danger" />
              {this.state.ErrorMessage[0] ? (
                <div className="alert alert-danger font-weight-bolder" role="alert">
                  {this.state.ErrorMessage[0]}
                </div>
              ) : (
                ''
              )}
              {this.RenderResetPasswordForm()}
            </div>
            <div className="col-md-1" />
          </div>
        </div>
        <div className="col-md-4" />
      </div>
    );
  }
}
