// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { fetchLists } from 'data/repositories/lists';

import LoadingRing from 'components/LoadingRing';

import CreateListModal from './CreateListModal';
import ListCardPopup from './ListCardPopup';

type Props = {
  accountPartnerId: string,
  disabled?: boolean,
};

const ListPopup = ({ accountPartnerId, disabled }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((o) => !o);

  const [search, setSearch] = useState('');
  const onSearch = debounce(setSearch, 500);

  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLists = useCallback(() => {
    setLoading(true);
    fetchLists({ search: search === '' ? undefined : search, containsMappingId: accountPartnerId })
      .then(({ results }) => {
        setLoading(false);
        setLists(results);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [search, accountPartnerId]);

  useEffect(() => {
    if (open) {
      getLists();
    }
  }, [open, getLists]);

  const [isCreateOpen, setCreateOpen] = useState(false);
  const toggleCreate = () => setCreateOpen((o) => !o);

  return (
    <>
      <CreateListModal open={isCreateOpen} toggle={toggleCreate} onUpdate={() => toggle()} />
      <Dropdown id={`list-popup-${accountPartnerId}`} isOpen={open} toggle={toggle}>
        <DropdownToggle className="px-2 py-1" color="link" disabled={disabled}>
          <svg height={14} viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M.87.825a.87.87 0 1 0 0 1.738h10.44a.869.869 0 1 0 0-1.738H.87Z"
              fill="#181E2D"
            />
            <path
              d="M0 12.92c0-.48.39-.87.87-.87h6.94a.87.87 0 0 1 0 1.74H.87a.87.87 0 0 1-.87-.87ZM0 7.3c0-.48.39-.87.87-.87h4.86a.87.87 0 0 1 0 1.74H.87A.87.87 0 0 1 0 7.3ZM12.857 11.643A.855.855 0 0 1 12 12.5a.855.855 0 0 1-.857-.857V9.357H8.857A.855.855 0 0 1 8 8.5c0-.475.382-.857.857-.857h2.286V5.357c0-.475.382-.857.857-.857s.857.382.857.857v2.286h2.286c.475 0 .857.382.857.857a.855.855 0 0 1-.857.857h-2.286v2.286Z"
              fill="#181E2D"
            />
          </svg>
        </DropdownToggle>
        <DropdownMenu
          right
          className="p-0 above-modal"
          container="body"
          style={{ width: 400, height: 300 }}
        >
          <div className="d-flex flex-column h-100">
            <Input
              className="m-2 w-auto"
              type="text"
              name="search"
              placeholder="🔎  Search lists"
              onChange={(e) => onSearch(e.target.value)}
            />
            <div className="px-2 flex-fill d-flex flex-column" style={{ overflow: 'auto' }}>
              {loading ? (
                <LoadingRing maxWidth="30px" className="m-5" />
              ) : (
                <>
                  {!lists.length ? (
                    <div className="text-center m-5">
                      <h6>No lists found.</h6>
                    </div>
                  ) : (
                    lists.map((list) => (
                      <ListCardPopup
                        key={list.id}
                        list={list}
                        accountPartnerId={accountPartnerId}
                        onUpdate={(id: string, containsMappingId: boolean) =>
                          containsMappingId
                            ? toggle()
                            : setLists((ls) =>
                                ls.map((l) =>
                                  l.id === id
                                    ? Object.defineProperty(l, 'containsMappingId', {
                                        value: containsMappingId,
                                      })
                                    : l
                                )
                              )
                        }
                      />
                    ))
                  )}
                </>
              )}
            </div>
            <div
              className="p-2 d-flex flex-row align-items-center justify-content-between"
              style={{ borderTop: '1px solid #E5E7EB' }}
            >
              <button type="button" onClick={toggleCreate} className="btn btn-link text-center">
                <FontAwesomeIcon icon="plus" className="mr-2" />
                Create new list
              </button>
              <Link className="normal-text bold px-3" to="/lists">
                See All Lists
              </Link>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ListPopup.defaultProps = {
  disabled: false,
};

export default ListPopup;
