// @flow

import * as React from 'react';
import classNames from 'classnames';

import { Org, OrgUser, Partner } from 'data/entities';

import FakeCard from 'views/PartnerDetail/FakeCard';
import MappingBanner from 'components/MappingBanner';
import PartnershipIcons from 'components/PartnershipIcons';

function BackgroundGradient() {
  return (
    <div
      style={{
        position: 'absolute',
        height: '600px',
        width: '100%',
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
        top: '220px',
      }}
    />
  );
}

type Props = {
  canManage: boolean,
  onClick: () => void,
  bdManager: OrgUser,
  partner: Partner,
  org: Org,
};

export default function PseudoModal(props: Props) {
  const { canManage, onClick, bdManager, partner, org } = props;
  return (
    <div>
      <FakeCard marginTop="100px" />
      <FakeCard />
      <FakeCard />
      <FakeCard />
      <BackgroundGradient />
      <div
        style={{
          position: 'absolute',
          width: 650,
          background: 'rgba(255, 255, 255, 1)',
          border: '1px solid #e5e7eb',
          left: 0,
          right: 0,
          top: '200px',
          margin: 'auto',
          padding: '50px',
          textAlign: 'center',
          boxShadow: '0px 2px 6px 0px #00000013',
        }}
        className={classNames('mappingSetup')}
      >
        <PartnershipIcons
          partnerOrgName={partner.name}
          partnerOrgDomain={partner.domain}
          userOrgName={org.name}
          userOrgDomain={org.url}
        />
        <h5 className="mt-5">Let&rsquo;s connect your sales teams!</h5>
        {canManage ? (
          <>
            <p className="normal-text gray-500 ">
              Just a few more clicks to increase
              <br /> your sales team network.
            </p>
            <button type="submit" onClick={onClick} className="btn btn-primary mt-2 btn-lg">
              Connect Teams
            </button>
            <div className="d-flex mt-5 justify-content-center">
              <MappingBanner partnerSlug={partner.slug} />
            </div>
          </>
        ) : (
          <div className="d-flex mt-5 justify-content-center">
            Waiting for the partner manager on your team ({bdManager.fullName}) to connect your
            teams
          </div>
        )}
      </div>
    </div>
  );
}
