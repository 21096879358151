// @flow

import React from 'react';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { get } from 'lodash';

import type { ErrorValue } from 'components/FieldErrors';
import FieldErrors from 'components/FieldErrors';

type Props = {
  isOpen: boolean,
  submitting: boolean,
  toggle: () => void,
  onSubmit: (string, string) => Promise<void>,
  errors: ErrorValue,
};

type FormState = {
  title: string,
  url: string,
};

const initialFormState: FormState = {
  title: '',
  url: '',
};
const AddSalesCollateralLinkModal = ({
  isOpen,
  toggle,
  onSubmit,
  submitting,
  errors,
}: Props): React$Node => {
  const [formState, setFormState] = React.useState<FormState>(initialFormState);
  const submitAction = (e: Event) => {
    e.preventDefault();
    onSubmit(formState.title, formState.url);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={() => setFormState(initialFormState)}
    >
      <Form onSubmit={submitAction}>
        <ModalHeader
          className="px-3 pb-3 pt-1"
          style={{ borderBottom: '1px solid #DDE5F0' }}
          toggle={toggle}
        >
          Add a link
        </ModalHeader>
        <ModalBody style={{ padding: '25px 20px' }}>
          <FieldErrors errors={errors && get(errors, 'nonFieldErrors')} />
          <div className="mb-4">
            <Label for="title">Title</Label>
            <Input
              value={formState.title}
              disabled={submitting}
              onChange={(e) => {
                const { value } = e.target;
                setFormState((s) => ({ ...s, title: value }));
              }}
              placeholder="Title"
              maxLength={254}
              name="title"
              type="text"
              required
            />
            <FieldErrors errors={errors && get(errors, 'title')} />
          </div>
          <div className="mb-4">
            <Label for="url">URL</Label>
            <Input
              value={formState.url}
              disabled={submitting}
              onChange={(e) => {
                const { value } = e.target;
                setFormState((s) => ({ ...s, url: value }));
              }}
              placeholder="URL"
              maxLength={254}
              name="url"
              type="url"
              required
            />
            <FieldErrors errors={errors && get(errors, 'url')} />
          </div>
        </ModalBody>
        <ModalFooter className="p-2" style={{ borderTop: '1px solid #DDE5F0' }}>
          <Button color="secondary" disabled={submitting} onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" disabled={submitting} type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddSalesCollateralLinkModal;
