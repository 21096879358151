// @flow

import * as React from 'react';

import { Org, OrgUser } from 'data/entities';

import BareLayout from 'layouts/BareLayout';
import SigninWithSlackButton from 'components/SigninWithSlackButton';
import SlackIntegrationButton from 'components/SlackIntegrationButton';

type Props = {
  org: Org,
  orguser: OrgUser,
};

export default function SlackSignIn({ org, orguser }: Props) {
  // FIXME This should not be possible if an integration does not exist for the org.
  if (!(org && org.hasSlackIntegration)) {
    return (
      <BareLayout centered>
        <div style={{ maxWidth: '600px' }}>
          <h5 className="pb-4">Your org has not configured the Slack integration yet.</h5>
          {orguser.canManage && (
            <div className="d-flex justify-content-center">
              <SlackIntegrationButton />
            </div>
          )}
        </div>
      </BareLayout>
    );
  }
  return (
    <BareLayout centered>
      <div style={{ maxWidth: '600px' }}>
        <h3 className="mt-5">Connect your Crossbeam for Sales identity + Slack identity</h3>
        <h5 className="pb-4">
          Sign in with Slack so you can use the <code>/partners</code> command in your team&apos;s
          Slack, and get alerts on deals closed by partners.
        </h5>
        {org.hasSlackIntegration && orguser.hasSlackIdentity && (
          <div className="alert alert-warning">
            Looks like you&apos;ve already connected your Slack. You should only need to do this
            again if you&apos;re having issues.
          </div>
        )}
        <SigninWithSlackButton />
      </div>
    </BareLayout>
  );
}
