// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Input } from 'reactstrap';
import { isNil } from 'lodash';

import type { Account } from 'data/entities';
import { AccountPartnerList } from 'data/entities/account';
import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { statusDefinitions } from 'data/entities/status';
import {
  AccountDetailAccountPartner,
  fetchAccountPartnersByAccountSlug,
} from 'data/repositories/accounts';
import { isAbortError } from 'authFetch/utils';

import InfiniteList from 'components/InfiniteList';
import LoadingRing from 'components/LoadingRing';

import AccountPartnerCard from './AccountPartnerCard';

type Props = {
  account: Account,
  selectedPartners: AccountPartnerList[],
  setSelectedPartners: (partners: AccountPartnerList[]) => void,
  questions: BdRequestQuestion[],
  hasCustomQuestions: boolean,
  canSelect: boolean,
};

const AccountPartners = ({
  account,
  selectedPartners,
  setSelectedPartners,
  questions,
  hasCustomQuestions,
  canSelect,
}: Props) => {
  const [accountPartners, setAccountPartners] = useState<AccountDetailAccountPartner[]>([]);
  const groupedPartners = useMemo(() => {
    const grouped = {};
    accountPartners
      .sort((a, b) => a.partnerAccount.name.localeCompare(b.partnerAccount.name))
      .forEach((ap) => {
        const { partner } = ap;
        if (!grouped[partner.slug]) {
          grouped[partner.slug] = [ap];
        } else {
          grouped[partner.slug].push(ap);
        }
      });
    return Object.values(grouped);
  }, [accountPartners]);

  const [loadedAll, setLoadedAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<string>('all');

  const query = useMemo(
    () => ({
      partnerAccountStatus: status === 'all' ? undefined : status,
      search: search || undefined,
      questionsToAskIds: questions.map((q) => q.id),
      partnerCanBeAskedCustomQuestions: hasCustomQuestions ? true : undefined,
    }),
    [status, search, questions, hasCustomQuestions]
  );

  useEffect(() => {
    setLoading(true);
    setLoadedAll(false);
    fetchAccountPartnersByAccountSlug(account.slug, {
      offset: 0,
      limit: 50,
      ...query,
    })
      .then((results) => {
        setAccountPartners(results.results);
        if (isNil(results.next)) {
          setLoadedAll(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (!isAbortError(err)) {
          setLoading(false);
        }
      });
  }, [account.slug, query]);

  const loadMoreRows = (offset, limit) => {
    if (offset === 0 || loadedAll) {
      return null;
    }
    return fetchAccountPartnersByAccountSlug(account.slug, {
      offset,
      limit,
      ...query,
    }).then((ap) => {
      setAccountPartners((s) => s.concat(ap.results));
      if (isNil(ap.next)) {
        setLoadedAll(true);
      }
    });
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center gap-10">
        <h5 className="m-0 bold gray-700 flex-1">Partners</h5>
        {loading && <LoadingRing maxWidth="30px" />}
        <select
          value={status || 'all'}
          className="w-auto form-control uppercase form-control-md"
          onChange={(e) => {
            const { value } = e.target;
            setStatus(value);
          }}
        >
          <option value="all">All account statuses</option>
          {Object.keys(statusDefinitions).map((key) => {
            const { key: statusKey, label } = statusDefinitions[key];
            return (
              <option key={statusKey} value={statusKey}>
                {label}
              </option>
            );
          })}
        </select>
        <Input
          style={{ width: '300px' }}
          type="text"
          name="search"
          placeholder="🔎  Search partners by name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div>
        {!groupedPartners.length ? (
          <>
            {loading ? (
              <LoadingRing maxWidth="30px" className="m-5" />
            ) : (
              <p className="m-3 align-items-center justify-content-center normal-text semi-bold gray-500 d-flex flex-row gap-5">
                No partners match the selected parameters.
              </p>
            )}
          </>
        ) : (
          <InfiniteList
            count={0}
            list={groupedPartners}
            loadMoreRows={loadMoreRows}
            minHeight={100}
            loadedAll={loadedAll}
            showFakeRows={false}
            render={({ key, index, style, data, isLoaded, refreshSize }) => (
              <AccountPartnerCard
                key={key}
                isLoaded={isLoaded}
                account={account}
                accountPartners={data}
                selectedPartners={selectedPartners}
                onSelect={setSelectedPartners}
                canSelect={canSelect}
                style={style}
                disabled={loading}
                refreshSize={refreshSize}
                inAccountDetail
                partnerDetailCard
              />
            )}
          />
        )}
      </div>
    </>
  );
};

export default AccountPartners;
