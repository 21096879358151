// @flow

import React, { useEffect, useState } from 'react';

import { fetchCrossbeamPartners } from 'data/repositories/crossbeam';
import useParams from 'utils/useParams';

import BareLayout from 'layouts/BareLayout';

import type { CrossbeamPartner } from '../../data/entities/crossbeam';

import ConfirmImport from './ConfirmImport';
import DefinePopulations from './DefinePopulations';
import LoadingPartners from './LoadingPartners';
import SelectPartners from './SelectPartners';

const stages = {
  selectPartners: 'selectPartners',
  definePopulations: 'definePopulations',
  confirmImport: 'confirmImport',
};

type Props = { onboarding?: () => void };

const ImportPartners = ({ onboarding }: Props) => {
  const [{ partner: managePartnerId, action }] = useParams({});
  const [stage, setStage] = useState(stages.selectPartners);

  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);

  const updateStage = (newStage) => {
    setStage(newStage);
    // Reset scroll position
    const container = document.getElementById('page-container');
    if (container) container.scrollTop = 0;
  };

  useEffect(() => {
    setLoading(true);
    fetchCrossbeamPartners()
      .then((cp) => {
        setLoading(false);

        // remove offline partners from the following screen
        const onlinePartners: CrossbeamPartner[] = cp.filter((p) => !p.offlinePartner);
        // Sort in the order of needs classification -> imported
        onlinePartners.sort(
          (a, b) =>
            // $FlowIgnore
            a.name.localeCompare(b.name) || a.imported - b.imported
        );

        setPartners(onlinePartners);

        // Select all already imported partners.
        const importedPartnerIDs = onlinePartners.filter((p) => p.imported).map((p) => p.id);
        setSelectedPartners(importedPartnerIDs);

        // If everyone has been imported or we are managing a single partner, go to the next stage.
        if (managePartnerId) {
          const cbId = onlinePartners.find((p) => p.partner?.id === managePartnerId).id;
          if (action === 'enable') {
            setSelectedPartners((sp) => (sp.includes(cbId) ? sp : [...sp, cbId]));
          }
          updateStage(stages.definePopulations);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [managePartnerId, action]);

  return (
    <BareLayout centered>
      <div className="d-flex flex-column align-items-center gap-30" style={{ minHeight: '95vh' }}>
        <div className="text-center">
          <span className="large-text bold gray-500">Configure the data your team can use</span>
          <h3 className="text-black bold mb-0">
            {(() => {
              switch (stage) {
                case stages.selectPartners:
                  return 'Select the partners your sales team works with';
                case stages.definePopulations:
                  return 'Select an account status for each partner custom population';
                default:
                  return 'Here are the partners you are importing and their populations';
              }
            })()}
          </h3>
        </div>
        {loading ? (
          <div style={{ width: '650px' }}>
            <LoadingPartners />
          </div>
        ) : (
          (() => {
            switch (stage) {
              case stages.selectPartners:
                return (
                  <SelectPartners
                    partners={partners}
                    selectedPartners={selectedPartners}
                    setSelectedPartners={setSelectedPartners}
                    next={() => updateStage(stages.definePopulations)}
                  />
                );
              case stages.definePopulations:
                return (
                  <DefinePopulations
                    selectedPartners={partners.filter((partner) =>
                      selectedPartners.includes(partner.id)
                    )}
                    updatePartner={(newPartner) =>
                      setPartners((p) =>
                        p.map((partner) => (partner.id === newPartner.id ? newPartner : partner))
                      )
                    }
                    back={() => updateStage(stages.selectPartners)}
                    next={() => updateStage(stages.confirmImport)}
                  />
                );
              default:
                return (
                  <ConfirmImport
                    selectedPartners={partners.filter((partner) =>
                      selectedPartners.includes(partner.id)
                    )}
                    back={() => updateStage(stages.definePopulations)}
                    onboarding={onboarding}
                  />
                );
            }
          })()
        )}
      </div>
    </BareLayout>
  );
};

ImportPartners.defaultProps = {
  onboarding: null,
};

export default ImportPartners;
