// @flow

import * as React from 'react';

import { PartnerInvite } from 'data/entities';
import authFetch from 'authFetch/authFetch';
import type { LocationT } from 'sharedTypes/reactRouter';

import OauthCallbackValidation from 'views/Oauth/OauthCallbackValidation';

function validateSSOCode(code, inviteCode) {
  const body = { code, accepted_invite_code: inviteCode };
  return authFetch('auth/verify-sso-code', { method: 'POST', body }).then((resp) => {
    const { accepted_invite: acceptedInviteData, token } = resp;
    const acceptedInvite = acceptedInviteData ? PartnerInvite.fromApi(acceptedInviteData) : null;
    return {
      acceptedInvite,
      token,
    };
  });
}

type Props = {
  location: LocationT,
  doLogin: (doOnboarding: ?boolean) => {},
};

export default function WorkOSCallback(props: Props) {
  const { location, doLogin } = props;
  return (
    <OauthCallbackValidation
      location={location}
      doLogin={doLogin}
      validationCall={validateSSOCode}
    />
  );
}
