/* eslint-disable max-classes-per-file */
// @flow

import { isNil } from 'lodash';
import queryString from 'qs';

import { Account, AccountOwner, PartnerAccount, PartnerBasic, PartnerOrg } from 'data/entities';
import Opportunity from 'data/entities/opportunity';
import type { PartnerOrgData } from 'data/entities/partnerOrg';
import { AccountSignals } from 'data/entities/signal';
import { skipNull } from 'utils/nodash';
import stringifyOptions from 'utils/stringifyOptions';
import authFetch from 'authFetch/authFetch';
import { AbortFetch, orgUrlBase } from 'authFetch/utils';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import { mapPaginatedResponse } from 'sharedTypes/paginatedResponse';

export class AccountDetailAccountPartner {
  id: string;

  partner: PartnerBasic;

  partnerOrg: PartnerOrg;

  partnerAccount: PartnerAccount;

  isPartnerTarget: boolean;

  isMyTarget: boolean;

  isPartnerPinned: boolean;

  isMyPinned: boolean;

  partnerAccountOwners: AccountOwner[];

  requestCount: ?number;

  signals: ?AccountSignals;

  static fromApi: (data: any) => AccountDetailAccountPartner;
}

AccountDetailAccountPartner.fromApi = (data: any) => {
  const {
    id,
    partner,
    partner_account: partnerAccount,
    partner_accountowners: partnerAccountOwners,
    is_my_target: isMyTarget,
    is_partner_target: isPartnerTarget,
    is_my_pinned: isMyPinned,
    is_partner_pinned: isPartnerPinned,
    request_count: requestCount,
    signals,
  } = data;
  const inst = new AccountDetailAccountPartner();

  inst.id = id;
  inst.partner = PartnerBasic.fromApi(partner);
  inst.partnerAccount = PartnerAccount.fromApi(partnerAccount);
  inst.partnerAccountOwners =
    partnerAccountOwners && partnerAccountOwners.map(AccountOwner.fromApi);
  inst.isMyTarget = isMyTarget;
  inst.isPartnerTarget = isPartnerTarget;
  inst.isMyPinned = isMyPinned;
  inst.isPartnerPinned = isPartnerPinned;
  inst.requestCount = requestCount;
  inst.signals = signals ? AccountSignals.fromApi(signals) : null;
  return inst;
};

export type PaginatedAccountResponse = {
  results: Account[],
  next: ?string,
  previous: ?string,
};

export type Option = {
  name: string,
  value: string,
};

export type AccountListQuery = {
  partner?: string[],
  status?: string[],
  segment?: string[],
  accountOwner?: string,
  accountName?: string,
  relevance?: string,
  limit?: number,
  partnerStatus?: string[],
  isTarget?: boolean,
  isTargetValue?: string,
  noPartnerActivityIn?: string,
  largestDeals?: boolean,
  offset?: string,
  limit?: string,
};

const abortAccountsList = new AbortFetch();

export function fetchPaginatedAccounts(
  query: ?AccountListQuery = {}
): Promise<PaginatedAccountResponse> {
  const getUrl = () => {
    const {
      partner,
      status: queryStatus,
      segment: querySegment,
      partnerStatus: queryPartnerStatus,
      accountOwner,
      accountName,
      relevance,
      isTarget,
      isTargetValue,
      noPartnerActivityIn,
      largestDeals,
      offset,
      limit,
    } = query || {};
    const status = queryStatus || [];
    const segment = querySegment || [];
    const partnerStatus = queryPartnerStatus || [];
    const params = {
      partner,
      status,
      segment,
      account_owner: accountOwner,
      account_name: accountName,
      relevance,
      partner_status: partnerStatus,
      is_target: isTarget || null,
      is_target_value: isTargetValue,
      no_partner_activity_in: noPartnerActivityIn,
      largest_deals: largestDeals || null,
      limit: limit || null,
      offset: offset || null,
    };
    const qp = queryString.stringify(params, {
      skipNulls: true,
      encode: true,
      arrayFormat: 'brackets',
    });
    return `${orgUrlBase()}/accounts?${qp}`;
  };
  const url = getUrl();
  abortAccountsList.abort();
  const options = {
    signal: abortAccountsList.signal,
  };
  return authFetch(url, options).then(mapPaginatedResponse(Account.fromApi));
}

type AccountsOptions = {
  search: ?string,
  relevance: ?string,
  status: ?(string[]),
  owner: ?string,
  segment: ?(string[]),
  partnerStatus: ?(string[]),
  partner: ?(string[]),
  noPartnerActivityIn: ?number,
  signal: ?(string[]),
  isTarget: ?boolean,
  isTargetValue: ?string,
  hasContacts: ?boolean,
  offset: ?number,
  limit: ?number,
};

export const fetchAccounts = (options: ?AccountsOptions): Promise<PaginatedResponse<Account>> => {
  const {
    search,
    relevance,
    status,
    owner,
    segment,
    partnerStatus,
    partner,
    noPartnerActivityIn,
    signal,
    isTarget,
    isTargetValue,
    hasContacts,
    offset,
    limit,
  } = options || {};
  let params = [
    ['account_name', search],
    ['relevance', relevance],
    ['status', status],
    ['account_owner', owner],
    ['segment', segment],
    ['partner_status', partnerStatus],
    ['partner', partner],
    ['no_partner_activity_in', noPartnerActivityIn],
    ['is_target', isTarget],
    ['is_target_value', isTargetValue],
    ['has_contacts', hasContacts],
    ...signal.map((v) => [v, true]),
  ];
  if (!isNil(offset) && !isNil(limit)) {
    params = params.concat([
      ['offset', offset],
      ['limit', limit],
    ]);
  }
  abortAccountsList.abort();
  const url = `${orgUrlBase()}/accounts${stringifyOptions(params)}`;
  return authFetch(url, {
    signal: abortAccountsList.signal,
  }).then(mapPaginatedResponse(Account.fromApi));
};

type AccountIdResponse = {
  id: string,
  slug: string,
};

export function fetchAccountIdsByXBRecordId(recordId: string): Promise<AccountIdResponse> {
  const url = `${orgUrlBase()}/accounts-from-xb-record-id/${recordId}`;
  return authFetch(url);
}

export function fetchAccountBySlug(slug: string): Promise<Account> {
  const url = `${orgUrlBase()}/accounts/${slug}`;
  return authFetch(url).then(Account.fromApi);
}

export function fetchAccountOpportunities(
  accountSlug: string,
  forBdRequest: ?boolean,
  search: ?string = null
): Promise<PaginatedResponse<Opportunity>> {
  const params = {
    search,
    for_bdrequest: forBdRequest,
  };
  const qs = queryString.stringify(skipNull(params));
  const url = `${orgUrlBase()}/accounts/${accountSlug}/opportunities?${qs}`;
  return authFetch(url).then(mapPaginatedResponse(Opportunity.fromApi));
}

type AccountPartnersOptions = {
  offset: ?number,
  limit: ?number,
  partnerAccountStatus?: string,
  search?: string,
  questionsToAskIds?: string[],
  partnerCanBeAskedCustomQuestions?: boolean,
};

const abortAccountPartnersSignal = new AbortFetch();

export const fetchAccountPartnersByAccountSlug = (
  slug,
  options: ?AccountPartnersOptions
): Promise<PaginatedResponse<AccountDetailAccountPartner>> => {
  const {
    offset,
    limit,
    partnerAccountStatus,
    search,
    questionsToAskIds,
    partnerCanBeAskedCustomQuestions,
  } = options || {};
  let params = [];
  if (!isNil(offset) && !isNil(limit)) {
    params = params.concat([
      ['offset', offset],
      ['limit', limit],
      ['paginate', true],
    ]);
  }
  if (!isNil(partnerAccountStatus)) {
    params.push(['partner_account_status', partnerAccountStatus]);
  }
  if (!isNil(search)) {
    params.push(['partner_q', search]);
  }
  if (!isNil(questionsToAskIds)) {
    params.push(['questions_to_ask_ids', questionsToAskIds]);
  }
  if (!isNil(partnerCanBeAskedCustomQuestions)) {
    params.push(['partner_can_be_asked_custom_questions', partnerCanBeAskedCustomQuestions]);
  }
  abortAccountPartnersSignal.abort();
  const url = `${orgUrlBase()}/accounts/${slug}/account-partners${stringifyOptions(params)}`;
  return authFetch(url, {
    signal: abortAccountPartnersSignal.signal,
  }).then(mapPaginatedResponse(AccountDetailAccountPartner.fromApi));
};

export function fetchAccountChildren(slug: string): Promise<PaginatedAccountResponse> {
  const url = `${orgUrlBase()}/accounts/${slug}/children`;
  return authFetch(url).then(mapPaginatedResponse(Account.fromApi));
}

//---
// Latest Accounts Closed

type LatestAccountsData = {
  account: Account,
  partner_org: PartnerOrgData,
};

export class LatestAccountPartner {
  account: Account;

  partnerOrg: PartnerOrg;

  static fromApi: (data: LatestAccountsData) => LatestAccountPartner;
}

LatestAccountPartner.fromApi = (data: LatestAccountsData): LatestAccountPartner => {
  const ap = new LatestAccountPartner();
  ap.account = Account.fromApi(data.account);
  ap.partnerOrg = PartnerOrg.fromApi(data.partner_org);
  return ap;
};

export function latestClosedAccounts(): Promise<LatestAccountPartner[]> {
  const url = `${orgUrlBase()}/accounts/latest_closed`;
  return authFetch(url).then((data) => data.results.map(LatestAccountPartner.fromApi));
}

//---
// Account updates

const hiddenUrl = (slug: string) => `${orgUrlBase()}/accounts/${slug}/hidden`;

export function hideAccount(slug: string) {
  return authFetch(hiddenUrl(slug), { method: 'POST' });
}

export function unHideAccount(slug: string) {
  return authFetch(hiddenUrl(slug), { method: 'DELETE' });
}

export function setAccountStatusAlert(slug: string, text: string, expiresAt: Date): Promise<any> {
  const body = { text, expires_at: expiresAt || null };
  const url = `${orgUrlBase()}/accounts/${slug}/status-alert`;
  return authFetch(url, { method: 'POST', body });
}

export function listPartnerMappedAccountsByAccount(
  slug: string,
  accountSlug: string,
  partnerAccountId: ?string,
  xbPartnerAccountId: ?string
): Promise<AccountDetailAccountPartner[]> {
  const url = `${orgUrlBase()}/accounts/${accountSlug}/account-partners`;
  const options = {
    queryParams: {
      partner_account_id: partnerAccountId,
      partner_slug: slug,
      xb_partner_account_id: xbPartnerAccountId,
    },
  };
  return authFetch(url, options).then((res) => res && res.map(AccountDetailAccountPartner.fromApi));
}
