// @flow
import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { uniqBy } from 'lodash';

import { Partner } from 'data/entities';
import fetchUnisearchalResults from 'data/repositories/search';

import CompanyLogo from 'components/CompanyLogo';

type Props = {
  selected: Partner[] | null | void,
  initalSelected: Partner[] | null | void,
  onSelect: (partners: Partner[]) => void,
  disabled: boolean,
};

const PartnersSelector = ({ initalSelected, selected, onSelect, disabled }: Props) => {
  const [options, setOptions] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(false);

  useEffect(() => {
    setOptions([]);
  }, [initalSelected]);

  const onSearch = (query: string) => {
    setLoadingPartners(true);
    fetchUnisearchalResults(query, 'partner').then((results) => {
      setOptions((o) => uniqBy(o.concat(results), 'id'));
      setLoadingPartners(false);
    });
  };

  const customMenuItem = (option, { text }) => (
    <div className="d-flex flex-row align-items-center gap-5">
      <CompanyLogo
        domain={option.domain}
        size={20}
        className=""
        logoUrl={option.logoUrl}
        name={option.name}
      />
      <Highlighter search={text}>{option.name}</Highlighter>
    </div>
  );

  return (
    <AsyncTypeahead
      labelKey={(option) => option.name}
      id="partners-selector"
      options={options ?? []}
      placeholder="Select Partners..."
      onChange={(s: Partner[]) => onSelect(s)}
      selected={selected ?? []}
      isLoading={loadingPartners}
      onSearch={onSearch}
      emptyLabel="No partners found."
      renderMenuItemChildren={customMenuItem}
      disabled={disabled}
      size="sm"
      minLength={1}
      render
      clearButton
      ignoreDiacritics
      multiple
      useCache
    />
  );
};

export default PartnersSelector;
