// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BdRequestFilters } from 'data/repositories/bdrequests';

const DEFAULT_SORT_KEY = '-created_at';

const sortKeyText = {
  '-created_at': 'Open (Recent)',
  created_at: 'Open (Oldest)',
  '-account__deal_amount': 'Deal Size (Highest)',
};

type Props = {
  filters: BdRequestFilters,
  onUpdateFilters: (filters: BdRequestFilters) => void,
};

type State = {
  isOpen: boolean,
};

class SortingDropdown extends React.Component<Props, State> {
  state = { isOpen: false };

  onToggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  onSortBy = (sortKey: ?string) =>
    // Return a function that can be called on sort
    () => {
      const { filters, onUpdateFilters } = this.props;
      filters.sortBy = sortKey;
      onUpdateFilters(filters);
    };

  dropdownButton = ({ sortKey }: { sortKey: string }) => {
    const {
      filters: { sortBy },
    } = this.props;
    const isActive = sortKey === sortBy || (!sortBy && sortKey === DEFAULT_SORT_KEY);
    const className = isActive ? 'bg-light-blue blue' : '';
    // If it's active, then clicking should clear the sort
    const sortArg = isActive ? null : sortKey;
    const onClick = this.onSortBy(sortArg);
    const btnTxt = sortKeyText[sortKey];
    return (
      <DropdownItem className={className} onClick={onClick}>
        {btnTxt}
      </DropdownItem>
    );
  };

  render() {
    const {
      filters: { sortBy },
    } = this.props;
    const { isOpen } = this.state;
    const DropdownButton = this.dropdownButton;
    const sortTxt = sortBy ? sortKeyText[sortBy] : sortKeyText[DEFAULT_SORT_KEY];
    return (
      <Dropdown toggle={this.onToggle} isOpen={isOpen}>
        <DropdownToggle>
          {sortTxt}
          <FontAwesomeIcon icon="chevron-down" className="ml-2" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownButton sortKey="-created_at" />
          <DropdownButton sortKey="created_at" />
          <DropdownButton sortKey="-account__deal_amount" />
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withRouter(SortingDropdown);
